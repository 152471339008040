import { PERMISSIONS } from 'shared/constants'
import { useCheckCommonPermissions, useCheckPermissions } from 'shared/useCheckPermissions'

export const useWbsPermits = (projectId?: string) => {
	const checkPermissions = useCheckPermissions()
	const checkPermissionsCommon = useCheckCommonPermissions()
	return {
		canView: typeof projectId === 'string' ? checkPermissions([PERMISSIONS.WbsView, PERMISSIONS.Admin], projectId) : checkPermissionsCommon([PERMISSIONS.WbsView, PERMISSIONS.Admin]),
		canEdit: typeof projectId === 'string' ? checkPermissions([PERMISSIONS.WbsCreator, PERMISSIONS.Admin], projectId) : checkPermissionsCommon([PERMISSIONS.WbsCreator, PERMISSIONS.Admin]),
		canDelete: typeof projectId === 'string' ? checkPermissions([PERMISSIONS.WbsDelete, PERMISSIONS.Admin], projectId) : checkPermissionsCommon([PERMISSIONS.WbsDelete, PERMISSIONS.Admin]),
		canExport: typeof projectId === 'string' ? checkPermissions([PERMISSIONS.ExportWbsToExcel, PERMISSIONS.Admin], projectId) : checkPermissionsCommon([PERMISSIONS.ExportWbsToExcel, PERMISSIONS.Admin]),
		canImport: typeof projectId === 'string' ? checkPermissions([PERMISSIONS.ImportWbsFromExcel, PERMISSIONS.Admin], projectId) : checkPermissionsCommon([PERMISSIONS.ImportWbsFromExcel, PERMISSIONS.Admin]),
		canSetDP: typeof projectId === 'string' ? checkPermissions([PERMISSIONS.WbsDp, PERMISSIONS.Admin], projectId) : checkPermissionsCommon([PERMISSIONS.WbsDp, PERMISSIONS.Admin]),
		canSetSDU: typeof projectId === 'string' ? checkPermissions([PERMISSIONS.WbsSdu, PERMISSIONS.Admin], projectId) : checkPermissionsCommon([PERMISSIONS.WbsSdu, PERMISSIONS.Admin]),
		canSetDZ: typeof projectId === 'string' ? checkPermissions([PERMISSIONS.WbsDzMtr, PERMISSIONS.Admin], projectId) : checkPermissionsCommon([PERMISSIONS.WbsDzMtr, PERMISSIONS.Admin]),
		canSetDZSmr: typeof projectId === 'string' ? checkPermissions([PERMISSIONS.WbsDzSmr, PERMISSIONS.Admin], projectId) : checkPermissionsCommon([PERMISSIONS.WbsDzSmr, PERMISSIONS.Admin]),
		canChangeReady: typeof projectId === 'string' ? checkPermissions([PERMISSIONS.WbsChangeReady, PERMISSIONS.Admin], projectId) : checkPermissionsCommon([PERMISSIONS.WbsChangeReady, PERMISSIONS.Admin]),
		isOuter: typeof projectId === 'string' ? checkPermissions([PERMISSIONS.WbsNoteContractor, PERMISSIONS.Admin], projectId) : checkPermissionsCommon([PERMISSIONS.WbsNoteContractor, PERMISSIONS.Admin]),
		canLocalView: typeof projectId === 'string' ? checkPermissions([PERMISSIONS.LocalEstimateView, PERMISSIONS.Admin], projectId) : checkPermissionsCommon([PERMISSIONS.LocalEstimateView, PERMISSIONS.Admin]),
		canLocalEdit: typeof projectId === 'string' ? checkPermissions([
			PERMISSIONS.LocalEstimateCreate,
			PERMISSIONS.Admin
		], projectId) : checkPermissionsCommon([
			PERMISSIONS.LocalEstimateCreate,
			PERMISSIONS.Admin
		]),
		canLocalExternal: typeof projectId === 'string' ? checkPermissions([
			PERMISSIONS.EstimateExternal,
			PERMISSIONS.Admin
		], projectId) : checkPermissionsCommon([
			PERMISSIONS.EstimateExternal,
			PERMISSIONS.Admin
		]),
		canLocalDzMTR: typeof projectId === 'string' ? checkPermissions([PERMISSIONS.EstimateDzMtr, PERMISSIONS.Admin], projectId) : checkPermissionsCommon([PERMISSIONS.EstimateDzMtr, PERMISSIONS.Admin]),
		canLocalDzSMR: typeof projectId === 'string' ? checkPermissions([PERMISSIONS.EstimateDzSmr, PERMISSIONS.Admin], projectId) : checkPermissionsCommon([PERMISSIONS.EstimateDzSmr, PERMISSIONS.Admin]),
		canLocalSdu: typeof projectId === 'string' ? checkPermissions([PERMISSIONS.EstimateSDU, PERMISSIONS.Admin], projectId) : checkPermissionsCommon([PERMISSIONS.EstimateSDU, PERMISSIONS.Admin]),
		canLocalImport: typeof projectId === 'string' ? checkPermissions([
			PERMISSIONS.ImportEstimateCorrectionFromExcel,
			PERMISSIONS.Admin
		], projectId) : checkPermissionsCommon([
			PERMISSIONS.ImportEstimateCorrectionFromExcel,
			PERMISSIONS.Admin
		]),
		canLocalExport: typeof projectId === 'string' ? checkPermissions([
			PERMISSIONS.ExportEstimateCorrectionToExcel,
			PERMISSIONS.Admin
		], projectId) : checkPermissionsCommon([
			PERMISSIONS.ExportEstimateCorrectionToExcel,
			PERMISSIONS.Admin
		])
	}
}
