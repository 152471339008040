import { Col, Empty, Row } from 'antd'
import { FC } from 'react'
import { Doughnut, Pie } from 'react-chartjs-2'
import { useCountOrdinancesDashboard } from '../model/diagrams-query'

export const OrdinanceDashboard: FC = () => {
	const { data: ordinance } = useCountOrdinancesDashboard()
	const values = [
		ordinance?.countOrdinanceEliminated || null,
		ordinance?.countOrdinanceNonEliminated || null
	]
	const labels = [`Устранено`, 'Не устранено']
	const customLabels = labels.map((label, index) => `${label}: ${values[index] ?? 0}   `)
	const colors = ['#1677FF', '#8099A8']
	const config = {
		type: 'doughnut',
		data: {
			labels: customLabels,
			datasets: [
				{
					data: values,
					backgroundColor: colors,
					hoverBackgroundColor: colors,
					borderColor: ['white'],
					borderWidth: 3,
					datalabels: {
						color: '#FFFFFF',
						font: {
							size: 14,
							weight: 400,
							family: 'Segoe UI'
						},
						formatter: function (value: any) {
							let percentage = (value / values?.reduce((sum, value) => sum! + value!, 0)!) * 100
							if (percentage < 5) {
								return ''
							}
							return value
						}
					}
				}
			]
		},
		options: {
			circumference: 180,
			rotation: -90,
			maintainAspectRatio: false,
			plugins: {
				tooltip: {
					callbacks: {
						labelColor: function (context: any) {
							return {
								borderColor: colors[context.dataIndex],
								backgroundColor: colors[context.dataIndex],
								borderWidth: 3
							}
						},
						label: function (context: any) {
							let label = context.formattedValue?.trim() || ''
							return ' ' + label
						}
					}
				},
				legend: {
					labels: {
						padding: 8,
						boxWidth: 15,
						boxHeight: 15,
						fontFamily: 'Segoe UI',
						fontWeight: 'normal',
						fontSize: '14px',
						color: '#2C303D'
					},
					responsive: true,
					position: 'bottom' as const,
					title: {
						display: true,
						color: 'black'
					}
				}
			}
		}
	}

	return (
		<Row
			wrap={false}
			// gutter={12}
			style={{
				border: '1px solid #ddd',
				borderRadius: 8,
				padding: 8,
				background: 'white',
				height: '350px'
			}}
		>
			<Col
				span={24}
				style={{
					padding: '1rem',
					background: !(ordinance?.totalOrdinances! > 0) ? '#ffffff' : '#fefefe'
				}}
			>
				<Row>
					<Col>
						<span
							style={{
								fontFamily: 'Segoe UI',
								fontWeight: 'normal',
								fontSize: '22px',
								color: '#000000',
								marginRight: '8px'
							}}
						>
							Статусы предписания
						</span>
					</Col>
					<Col style={{ alignContent: 'center', marginTop: 5 }}>
						<span
							style={{
								fontFamily: 'Segoe UI',
								fontWeight: 400,
								fontSize: '14px',
								marginLeft: '5px',
								backgroundColor: '#EEEEEE',
								borderRadius: '21px',
								padding: '4px',
								color: '#2C303'
							}}
						>
							{ordinance?.totalOrdinances ?? 0}
						</span>
					</Col>
				</Row>
				<div style={{ height: 250, marginTop: 20 }}>
					{ordinance?.totalOrdinances! > 0 ? (
						<Doughnut {...config} />
					) : (
						<span
							style={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								height: '100%'
							}}
						>
							<Empty />
						</span>
					)}
				</div>
			</Col>
		</Row>
	)
}
