import { useQuery } from '@tanstack/react-query'
import { FineCardApi } from '../api/fine-card-api'
import { TFineCardType } from './fine-card-interface'
import { useFineState } from './fine-card-state'
import { useAppDispatch, useAppSelector } from 'hooks/appReduxHook'
import { useLocation, useNavigate } from 'react-router-dom'
import { setEnvironmentProject } from 'store/environmentSlice'
import { useOrdinanceFineState } from 'widgets/fine/model/fine-state'
import { Modal } from 'antd'
import { AxiosError } from 'axios'
import { IErrorDetail } from 'interfaces/IBase'

export const useFineCard = (type: TFineCardType) => {
	const dispatch = useAppDispatch()
	const navigate = useNavigate()
	const { edit, inner, setData } = useFineState()
	const { pathname } = useLocation()
	const { project, user } = useAppSelector(state => state.environment)
	const { setShowDrawer } = useOrdinanceFineState()
	const closeHandler = () => {
		navigate('/')
	}
	return useQuery({
		queryKey: ['fineCard' + type, edit, inner, type],
		queryFn: async () => {
			if (type === 'edit' && typeof edit === 'string') {
				const data = await FineCardApi.get(edit).then(data => data)
				setData(type, data)
				return data
			}
			if (type === 'inner' && typeof inner === 'string') {
				const data = await FineCardApi.get(inner).then(data => data)
				setData(type, data)
				return data
			}

			return null
		},
		onSuccess: data => {
			if (data?.project?.id && project?.id !== data?.project?.id) {
				if (user?.projects?.some(p => p.id === data?.project?.id)) {
					if (pathname.includes('fines/')) {
						dispatch(
							setEnvironmentProject({
								option: 'project',
								data: user.projects.find(p => p.id === data?.project?.id)!
							})
						)
					}
					data && setShowDrawer(true)
				} else {
					Modal.error({
						title: 'Ошибка доступа',
						content:
							'Внимание! Автоштраф не доступен по ссылке, поскольку у Вас не хватает прав. Обратитесь к Администратору',
						onOk: closeHandler,
						onCancel: closeHandler
					})
				}
			} else {
				data && setShowDrawer(true)
			}
		},
		onError: (err: AxiosError<IErrorDetail>) => {
			if (err.response?.status === 403) {
				Modal.error({
					title: 'Ошибка доступа',
					content:
						'Внимание! Автоштраф не доступен по ссылке, поскольку у Вас не хватает прав. Обратитесь к Администратору',
					onOk: closeHandler,
					onCancel: closeHandler
				})
			}
		},
		retry: (_, error) => (error.response?.status === 403 ? false : true)
	})
}

export const useFineCardAttachment = (id?: string) => {
	return useQuery({
		queryKey: ['fineCardAttachment', id],
		queryFn: async () => {
			return id ? await FineCardApi.getAttachments(id).then(data => data) : []
		},
		refetchOnWindowFocus: false
	})
}
export const useGetFineAttachment = (id?: string) => {
	const { setAttachment } = useFineState()
	return useQuery({
		queryKey: ['fineCardAttachmentToDownload', id],
		queryFn: async () => {
			if (id) {
				const attachment = await FineCardApi.getFineAttachment(id).then(data => data.attachments[0])
				setAttachment(attachment)
			}
			return null
		},
		refetchOnWindowFocus: false
	})
}
