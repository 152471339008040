import { useQueryClient } from '@tanstack/react-query'
import { Modal, Typography, message } from 'antd'
import { FC, useState } from 'react'
import { useLocalEstimateRegistryState } from '..'

const { Title } = Typography

interface IProps {}

export const LocalEstimateRemove: FC<IProps> = props => {
	const [isLoading, setIsLoading] = useState(false)
	const queryClient = useQueryClient()
	const { containerToRemove, setContainerToRemove, onRemove } = useLocalEstimateRegistryState()
	console.log('containerToRemove', containerToRemove)
	return (
		<Modal
			destroyOnClose={true}
			title={
				<Title level={5} type="danger">
					Внимание!
				</Title>
			}
			centered
			open={!!containerToRemove}
			zIndex={1001}
			okText={'Подтвердить'}
			cancelText="Отмена"
			okButtonProps={{
				danger: true,
				loading: isLoading
			}}
			onOk={async () => {
				if (containerToRemove) {
					setIsLoading(true)
					onRemove(containerToRemove.id, containerToRemove?.adjustmentId ? true : false)
						.then(() => {
							queryClient.invalidateQueries(['localEstimateRegistry'])
							setContainerToRemove(undefined)
							message.success(
								containerToRemove?.adjustmentId
									? 'Документ "Было/Стало успешно удалён"'
									: 'Локальная смета успешно удалёна'
							)
						})
						.catch(() =>
							message.error('Во время удаления произошла ошибка. Обратитесь к администратору')
						)
						.finally(() => setIsLoading(false))
				}
			}}
			onCancel={() => setContainerToRemove(undefined)}
		>
			Вы точно хотите удалить{' '}
			{containerToRemove?.adjustmentId ? 'документ "Было/Стало"' : 'Локальную смету'}{' '}
			<b>{containerToRemove?.name.toUpperCase()}</b>?
		</Modal>
	)
}
