import { IBaseTechnology } from 'interfaces/IBase'
import {
	IEstimatePosition,
	IEstimatePositionDto,
	IPropertyFilters
} from 'interfaces/IEstimatePosition'
import { IPagination } from 'interfaces/IPagination'
import { ITechnology } from 'interfaces/ITechnology'
import { API } from 'services/ApiService'

export interface IMaterial {
	additionalProperties?: any | null
	fullName: string
	id: string
	isDeleted: boolean
	isNominated: boolean
	isGroup: boolean
	measureUnit: IBaseTechnology | null
	name: string
	parentId: string | null
	typeMaterialId: string
}

export interface IHistoryData {
	id: string
	item: IHistoryDataItem
	date?: string
	action?: string
	beforeState?: string | number
	currentState?: string | number
	children?: IHistoryData[]
}

interface IHistoryDataItem {
	name: string
	type: 'redactor' | 'work' | 'workProperty' | 'material' | 'materialProperty'
}

export interface IAdditionalProperty {
	id: string
	name: string
	fullName: string
	isDeleted: boolean
	additionalPropertyId: string
}

export const EstimatePositionApi = {
	async getTechnologies(sectionId: string) {
		return await API<ITechnology[]>({
			url: `/v1/sections/${sectionId}/technologies`,
			method: 'get',
			params: {projectId: null}
		}).then(response => response.data)
	},
	async getSectionPropertyFilters(sectionId: string) {
		return await API<IPropertyFilters[]>({
			url: `/v1/sections/${sectionId}/filters`,
			method: 'get'
		}).then(async response => response.data)
	},
	async getTechnology(technologyId: string) {
		return await API<ITechnology>({
			url: `/v1/technologies/${technologyId}`,
			method: 'get',
			params: {projectId: null}
		}).then(response => response.data)
	},
	async getMaterials(typeMaterialId: string) {
		return await API<IMaterial[]>({
			url: `/v1/materials`,
			method: 'get',
			params: { typeMaterialId }
		}).then(response => response.data)
	},
	async saveStatement(
		estimatePositionDto: IEstimatePositionDto,
		method: string,
		isAdjustment?: boolean,
		type?: 'wbs' | 'local' 
	) {
		const url = isAdjustment ? 'adjustment/estimatePosition' : `estimatePositions/${type === 'wbs' ? 'container' : 'estimate'}`

		return await API({
			url: `/v1/${url}`,
			method: method,
			params: {
				projectId: null
			},
			data: estimatePositionDto
		}).then(response => response.data)
	},
	async getStatement(id: string, isAdjustment?: boolean, type?: 'wbs' | 'local') {
		return await API<IEstimatePosition>({
			url: `/v1/${isAdjustment === true ? 'adjustment/EstimatePosition' : type === 'wbs' ? 'estimatePositions/container' : 'estimatePositions/estimate'}/${id}`,
			method: 'get',
			params: {
				projectId: null
			}
		}).then(response => response.data)
	},
	async getHistory(id: string, type: 'wbs' | 'local') {
		return await API<IPagination<IHistoryData[]>>({
			url: `/v1/estimatePositions/${type === 'wbs' ? 'container' : 'estimate'}/${id}/logs`,
			method: 'get',
			params: {
				projectId: null
			}
		}).then(response => response.data)
	},
	async getAdditionalProperties(AdditionalPropertyId: string) {
		return await API<IAdditionalProperty[]>({
			url: `/v1/additionalPropertyValues`,
			method: 'get',
			params: { AdditionalPropertyId }
		}).then(response => response.data)
	}
}
