import { TableColumnsType } from 'antd'
import dayjs from 'dayjs'
import { TReport } from '../model/reports-schema'

export const reportColumns: TableColumnsType<TReport> = [
	{
		title: 'Код нарушения',
		dataIndex: 'issueCode',
		align: 'center',
		width: 100
	},
	{
		title: 'Описание нарушения',
		dataIndex: 'issueDescription',
		align: 'center',
		width: 300
	},
	{
		title: 'Подписание предписания',
		dataIndex: 'isOrdinanceAttachmentSign',
		align: 'center',
		width: 110,
		render: value => (value === 4 ? 'Да' : 'Нет')
	},
	{
		title:
			'Пункт и наименование нормативного документа РФ или иного документа, требования которого нарушены',
		dataIndex: 'issueActpoint',
		align: 'center',
		width: 150
	},
	{
		title: 'Локация',
		dataIndex: 'locationDescription',
		align: 'center',
		width: 300
	},
	{
		title: '№ предписания',
		dataIndex: 'ordinanceNumber',
		align: 'center',
		width: 150
	},
	{
		title: 'Дата выдачи',
		dataIndex: 'ordinanceCreatedAt',
		key: 'ordinanceCreatedAt',
		align: 'center',
		render: value => (value && dayjs(value).isValid() ? dayjs(value).format('DD.MM.YYYY') : ''),
		width: 150
	},
	{
		title: 'Требуемая дата устранения',
		dataIndex: 'ordinanceDateTo',
		key: 'ordinanceDateTo',
		align: 'center',
		render: value => (value && dayjs(value).isValid() ? dayjs(value).format('DD.MM.YYYY') : ''),
		width: 150
	},
	{
		title: 'Ответственная организация',
		dataIndex: 'contractor',
		align: 'center',
		width: 150
	},
	{
		title: 'Представитель ответственной организации',
		dataIndex: 'contactPerson',
		align: 'center',
		width: 150
	},
	{
		title: 'Проект',
		dataIndex: 'project',
		align: 'center',
		width: 150
	},
	{
		title: 'Представитель, выдавший предписание',
		dataIndex: 'ordinanceCreatedBy',
		align: 'center',
		width: 150
	},
	{
		title: 'Отметка об устранении',
		dataIndex: 'ordinanceStatus',
		align: 'center',
		width: 100
	},
	{
		title: 'Фактическая дата устранения',
		dataIndex: 'ordinanceClosedAt',
		align: 'center',
		render: value => (value && dayjs(value).isValid() ? dayjs(value).format('DD.MM.YYYY') : ''),
		width: 150
	},
	{
		title: 'Акт об устранении, номер и дата',
		dataIndex: 'eliminationAct',
		align: 'center',
		width: 250
	},
	{
		title: 'Подписание Акта об устранении',
		dataIndex: 'isEliminationActSign',
		align: 'center',
		render: value => (value === null ? null : value === 4 ? 'Да' : 'Нет'),
		width: 100
	},
	{
		title: 'Акт о неустранении, номер и дата',
		dataIndex: 'nonEliminationAct',
		align: 'center',
		width: 200
	},
	{
		title: 'Подписание Акта о неустранении',
		dataIndex: 'isNonEliminationActSign',
		align: 'center',
		render: value => (value === null ? null : value === 4 ? 'Да' : 'Нет'),
		width: 100
	},
	{
		title: 'Примечание',
		dataIndex: 'note',
		align: 'center',
		width: 150
	}
]
