import { CellContext, Row } from '@tanstack/react-table'
import { InputNumber, Tooltip } from 'antd'
import { useAppSelector } from 'hooks/appReduxHook'
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useWbsPermits } from 'widgets/wbs'
import { useShallow } from 'zustand/react/shallow'
import { useEstimateState } from '..'
import { useRoundedNumber } from '../model/estimate-helper'
import {
	IEstimatePosition,
	IEstimatePositionMaterial,
	IEstimateSection
} from '../model/estimate-schema'
import { CONTAINER_STATUS } from 'shared/constants'
import { useContainerVisas } from 'widgets/wbs/model/wbs-queries'
import { IStatusRouteItemVisa } from 'pages/unmodelPage/wbsContainer'
import { IBase } from 'interfaces/IBase'

interface IProps {
	record:
		| CellContext<IEstimatePosition | IEstimatePositionMaterial, any>
		| CellContext<IEstimatePosition, any>
		| CellContext<IEstimatePositionMaterial, any>
	cellType: 'amount' | 'priceMaterial' | 'priceService'
	parents: Row<IEstimateSection | IEstimatePosition | IEstimatePositionMaterial>[]
	visaIssued: boolean
}

export const EstimateVirtualizeCell: FC<IProps> = ({ record, cellType, parents, visaIssued }) => {
	const rowOriginal = useMemo(() => record.row.original, [record])
	const roundedNumber = useRoundedNumber()
	const inputRef = useRef<HTMLInputElement>(null)
	const { user: currentUser } = useAppSelector(state => state.environment)

	const { container, onSavePosition } = useEstimateState(
		useShallow(state => ({
			container: state.container,
			onSavePosition: state.onSavePosition
		}))
	)
	const initialValue = roundedNumber(record.cell.getValue()) as string
	const [cellValue, setCellValue] = useState<number | null>(
		+initialValue.replace(/,/, '.').replace(/\s+/g, '')
	)
	const [hasError, setHasError] = useState<string | undefined>()
	const wbsPermissions = useWbsPermits()

	const isAuthor = useMemo(
		() =>
			(currentUser?.isAdmin ||
				container?.author.id === currentUser?.id ||
				container?.redactors.some(r => r.id === currentUser?.id)) ??
			false,
		[]
	)

	const isRedactor = useMemo(
		() =>
			(currentUser?.isAdmin || container?.redactors.some(r => r.id === currentUser?.id)) ?? false,
		[]
	)

	const isValidateClosed = useMemo(
		() =>
			typeof container?.isLocalEstimate === 'undefined' ||
			(container.isLocalEstimate === false && rowOriginal.isClosed === false),
		[]
	)

	const validateCell = useCallback(
		(value: any) => {
			if (
				isValidateClosed &&
				cellType === 'amount' &&
				(value === null || value === 0 || value === undefined)
			) {
				setHasError('Кол-во не может быть пустым или равным 0')
			} else if (value === null && isValidateClosed) setHasError('Кол-во не может быть пустым')
			else if (Number.isNaN(value) && isValidateClosed) {
				setHasError('Может содержать только цифры')
			} else {
				setHasError(undefined)
			}
		},
		[cellValue]
	)

	useEffect(() => {
		validateCell(cellValue)
	}, [cellValue])

	const onSave = useCallback(
		(
			cellType: 'amount' | 'priceMaterial' | 'priceService',
			record:
				| CellContext<IEstimatePosition | IEstimatePositionMaterial, any>
				| CellContext<IEstimatePosition, any>
				| CellContext<IEstimatePositionMaterial, any>,
			parents: Row<IEstimateSection | IEstimatePosition | IEstimatePositionMaterial>[]
		) => {
			if (!hasError && cellValue !== null) {
				onSavePosition(cellValue, cellType, record, parents)
			} else {
				setCellValue(+initialValue.replace(/,/, '.').replace(/\s+/g, ''))
				setTimeout(() => {
					setHasError(undefined)
				}, 2000)
			}
		},
		[cellValue]
	)

	const isLocalEstimate = useMemo(() => typeof container?.isLocalEstimate !== 'undefined', [])
	const canEditCorrectLocalEstimate = useMemo(
		() => container?.isLocalEstimate === false && wbsPermissions.canLocalEdit,
		[]
	)

	const checkEditable = useCallback((cellType: 'amount' | 'priceMaterial' | 'priceService') => {
		switch (cellType) {
			case 'amount':
				return (
					((container?.status.name === CONTAINER_STATUS.new ||
						container?.status.name === CONTAINER_STATUS.preparation) &&
						((!isLocalEstimate && isAuthor && wbsPermissions.canEdit) ||
							(canEditCorrectLocalEstimate &&
								isAuthor &&
								rowOriginal.isClosed === false &&
								rowOriginal.isEditable === true))) ||
					currentUser?.isAdmin
				)
			case 'priceMaterial':
				if ('workName' in rowOriginal) {
					if (isLocalEstimate && container?.isLocalEstimate === true) return false
					if (
						isLocalEstimate &&
						container?.isLocalEstimate === false &&
						rowOriginal.confirmedVolumeAmount !== 0
					)
						return false
					return (
						(!isLocalEstimate &&
							rowOriginal.isFixedPriceMaterial === false &&
							wbsPermissions.canSetSDU) ||
						(rowOriginal.isFixedPriceMaterial === false && currentUser?.isAdmin)
					)
				}
				if ('materialName' in rowOriginal) {
					if (isLocalEstimate && container?.isLocalEstimate === true) return false
					if (
						isLocalEstimate &&
						container?.isLocalEstimate === false &&
						rowOriginal.confirmedVolumeAmount !== 0
					)
						return false
					return !isLocalEstimate && rowOriginal.isNominated
						? wbsPermissions.canSetDZ
						: rowOriginal.isNominated && isLocalEstimate
						? wbsPermissions.canLocalDzMTR || (wbsPermissions.canLocalExternal && isRedactor)
						: (wbsPermissions.canSetSDU && !isLocalEstimate) ||
						  (rowOriginal.isNominated === false && wbsPermissions.canLocalSdu && isLocalEstimate)
				}
				return false
			case 'priceService':
				if (isLocalEstimate && container?.isLocalEstimate === true) return false
				if (
					isLocalEstimate &&
					container?.isLocalEstimate === false &&
					rowOriginal.confirmedVolumeAmount !== 0
				)
					return false
				return (
					('workName' in rowOriginal && !isLocalEstimate && wbsPermissions.canSetSDU) ||
					('workName' in rowOriginal &&
						container?.isLocalEstimate === false &&
						wbsPermissions.canLocalSdu) ||
					currentUser?.isAdmin
				)
		}
	}, [])

	const isNotEditable = useMemo(() => {
		return (
			container?.ready ||
			rowOriginal.isClosed === true ||
			rowOriginal.isEditable === false ||
			container?.isLocalEstimate ||
			!checkEditable(cellType) ||
			((cellType === 'priceMaterial' || cellType === 'priceService') &&
				rowOriginal.confirmedVolumeAmount > 0)
		)
	}, [])

	return isNotEditable || visaIssued ? (
		<>{initialValue}</>
	) : (
		<Tooltip color="volcano" placement="left" title={hasError} open={!!hasError}>
			<InputNumber
				ref={inputRef}
				value={cellValue}
				keyboard={false}
				onKeyDown={event => {
					if (
						!/[0-9]|Backspace|Delete|ArrowLeft|ArrowRight|ArrowUp|ArrowDown|\.|\,/.test(event.key)
					)
						event.preventDefault()
				}}
				controls={false}
				changeOnWheel={false}
				decimalSeparator=","
				className="estimate-position-input"
				onChange={setCellValue}
				onFocus={() => {
					if (Number(initialValue) === 0) inputRef.current?.select()
				}}
				onBlur={e => {
					if (initialValue! !== e.currentTarget.value) onSave(cellType, record, parents)
				}}
				onPressEnter={() => inputRef.current?.blur()}
			/>
		</Tooltip>
	)
}
