import { useQueryClient } from '@tanstack/react-query'
import { Button, Card, Col, Descriptions, Form, FormInstance, notification, Row, Tag } from 'antd'
import { DescriptionsItemType } from 'antd/es/descriptions'
import dayjs from 'dayjs'
import { TWbsTaskForm } from 'entities/wbs-task/model/wbs-task-schema'
import { useAppSelector } from 'hooks/appReduxHook'
import { useWbsTaskState } from 'pages/wbs-tasks-page/model/wbs-tasks-state'
import type { CustomTagProps } from 'rc-select/lib/BaseSelect'
import { FC, useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { DATE_FORMAT, PERMISSIONS } from 'shared/constants'
import { WBS_TASK_STATUS } from 'shared/constants/wbs-task-types'
import { AbsFormInput } from 'shared/ui/abs-form-items/abs-form-input'
import { AbsFormSelect } from 'shared/ui/abs-form-items/abs-form-select'
import { AbsFormTreeSelect } from 'shared/ui/abs-form-items/abs-form-tree-select'
import { useCheckPermissions } from 'shared/useCheckPermissions'
import { useWbsTaskCreateDocument } from 'widgets/wbs-task-registry/api/wbs-task-registry-queries'
import { IWbsTaskCreateDocument } from 'widgets/wbs-task-registry/model/wbs-task-registry-interface'
import { flattenTree, markDisabledNodes } from 'widgets/wbs/model/wbs-helper'
import {
	useConstructionObjectById,
	useWbsTaskCard,
	useWbsTaskCardConstructionObjects,
	useWbsTaskCardContainers,
	useWbsTaskCardContracts,
	useWbsTaskCardEstimates,
	useWbsTaskCardPotentialAssignUsers,
	useWbsTaskCardType,
	useWbsTaskCardTypeSections,
	useWbsTaskCardWorkPackages
} from '../api/wbs-task-card-queries'
import { WBS_TASK_TYPE, WBS_TASK_TYPE_ENUM } from '../model/wbs-task-card-mock'
import { useWbsTaskCardState } from '../model/wbs-task-card-state'
import { WbsTaskModalAssign } from './wbs-task-modal-assign'
import { WbsTaskModal } from './wbs-task-modal-basis'
import { CopyToClipboard } from 'components/CopyToClipboard'
import { useUserAllInfo } from 'widgets/wbs/model/wbs-notification-query'
import { useWbsTaskVisas } from 'widgets/wbs-task-visas/api/wbs-task-visas-queries'

type TProps = {
	form: FormInstance
	formValues: TWbsTaskForm
	canEdit: boolean
}

export enum WBS_TASK {
	wbs,
	estimate,
	common
}

export const WbsTaskCardDetails: FC<TProps> = ({ form, formValues, canEdit }) => {
	const { currentTaskId } = useWbsTaskState()
	const { data: wbsTaskVisas, isFetching: isFetchingVisas } = useWbsTaskVisas(currentTaskId!)
	const { mutate: mutateCreateDocument } = useWbsTaskCreateDocument()
	const { data, isFetching } = useWbsTaskCard()
	const queryClient = useQueryClient()
	const checkPermissions = useCheckPermissions()
	const canEditAssignEconomy = checkPermissions(
		[PERMISSIONS.WbsTasksAssignedTo],
		data?.project?.id!
	)
	const canEditAssignDesign = checkPermissions(
		[PERMISSIONS.WbsTasksAssignedToDES],
		data?.project?.id!
	)
	const { user } = useAppSelector(state => state.environment)
	const { openModalAssign, constructionObjectName, setOpenModal, setOpenModalAssign } =
		useWbsTaskCardState()
	const constructionObjectField = Form.useWatch('constructionObjectId', form)
	const workPackageField = Form.useWatch('workPackageId', form)
	const typeSectionField = Form.useWatch('typeSectionId', form)
	const contractField = Form.useWatch('contractId', form)
	const projectField = Form.useWatch('projectId', form)
	const { data: constructionObjects, isFetching: constructionObjectsFetching } =
		useWbsTaskCardConstructionObjects()
	const transformedData = markDisabledNodes(constructionObjects || [])
	const { data: workPackages, isFetching: workPackagesFetching } = useWbsTaskCardWorkPackages()
	const { data: typeSections, isFetching: typeSectionsFetching } =
		useWbsTaskCardTypeSections(workPackageField)
	const { data: contracts, isFetching: contractsFetching } = useWbsTaskCardContracts()
	const { data: potentialAssignUsers, isFetching: potentialAssignUsersFetching } =
		useWbsTaskCardPotentialAssignUsers(
			data?.wbsTaskStatus?.id!,
			data?.project?.id! ? [data?.project?.id!] : [],
			data?.wbsTaskStatus?.id === WBS_TASK_STATUS.additionalRequestAuthorDP ? data?.id! : undefined
		)
	// const { data: estimates, isFetching: estimatesFetching } = useWbsTaskCardEstimates(
	// 	formValues?.contractId!,
	// 	formValues?.constructionObjectId!
	// )
	// const { data: containers, isFetching: containersFetching } = useWbsTaskCardContainers(
	// 	formValues?.typeSectionId!,
	// 	formValues?.constructionObjectId!
	// )
	const { data: wbsTaskType, isFetching: wbsTaskTypeFetching } = useWbsTaskCardType()
	const { data: constructionObjectById } = useConstructionObjectById(constructionObjectField)
	// const { data: projects, isFetching: isFetchingProjects } = useUserAllInfo()
	const [assigned, setAssigned] = useState<string[]>([])
	const canCreate =
		data?.wbsTaskStatus?.id === WBS_TASK_STATUS.inProgress &&
		wbsTaskVisas?.find(
			(v: any) =>
				v.name === 'DES' &&
				v.visas?.find((visa: any) => visa.name === 'Координатор ДЭС' && visa.visaState !== null)
		) &&
		data?.resultDocument === null &&
		(data.assignedUsers?.find(au => au?.id === user?.id) || user?.isAdmin) && // checkPermissions([PERMISSIONS.AdjustmentWbsCorrectionCreate], data?.project?.id!) &&
		((data?.wbsTaskType.id === WBS_TASK_TYPE.wbsAdjustment.id &&
			!!data?.container &&
			checkPermissions([PERMISSIONS.AdjustmentCreate], data?.project?.id!)) ||
			// checkPermissions([PERMISSIONS.AdjustmentEstimateCorrectionCreate], data?.project?.id!) &&
			(data?.wbsTaskType.id === WBS_TASK_TYPE.estimateAdjustment.id &&
				!!data?.estimate &&
				checkPermissions([PERMISSIONS.AdjustmentCreate], data?.project?.id!)) ||
			(data?.wbsTaskType.id === WBS_TASK_TYPE.new.id &&
				checkPermissions([PERMISSIONS.WbsCreator], data?.project?.id!)))

	useEffect(() => {
		setAssigned(data!?.assignedUsers?.map(a => a?.id!) ?? [])
	}, [data, openModalAssign])

	useEffect(() => {
		form.setFieldValue('assignedUsers', assigned)
	}, [assigned])

	const onAssignedSelect = (user: string) => {
		setAssigned(prev => [...prev, user])
	}

	const onAssignedDeselect = (user: string) => {
		if (assigned?.length > 1) {
			const users = assigned.filter(u => u !== user)
			setAssigned(users)
		}
	}

	const items: DescriptionsItemType[] = [
		{
			key: 'wbsTaskType',
			children: (
				<AbsFormSelect
					label="Тип заявки"
					name="wbsTaskTypeId"
					rules={[{ required: true }]}
					canEdit={canEdit}
					form={form}
					options={wbsTaskType}
					isLoading={wbsTaskTypeFetching}
					dataSourceValue={data?.wbsTaskType.name}
				/>
			)
		},
		{
			key: 'constructionObject',
			children: (
				<AbsFormTreeSelect
					label="Объект строительства"
					name="constructionObjectId"
					rules={[
						{ required: true }
						// () => ({
						// 	validator(_, value) {
						// 		if (value === data?.constructionObject?.id) {
						// 			const obj: any = data?.constructionObject
						// 			const isValid = obj.isAccessible === true
						// 			if (!isValid) {
						// 				return Promise.reject(
						// 					new Error(
						// 						'Объект строительства недоступен для выбора. Укажите другое значение.'
						// 					)
						// 				)
						// 			}
						// 		}
						// 		return Promise.resolve()
						// 	}
						// })
					]}
					canEdit={canEdit}
					form={form}
					options={transformedData}
					isLoading={constructionObjectsFetching}
					dataSourceValue={data?.constructionObject.name ?? constructionObjectName}
					isConstructionObject={true}
					formValues={formValues}
				/>
			)
		},
		{
			key: 'subProject',
			children: (
				<Form.Item label="Подпроект">
					<span className="app-form-text" style={{ color: 'gray' }}>
						{data?.wbsTaskStatus?.id === WBS_TASK_STATUS.done ||
						data?.wbsTaskStatus?.id === WBS_TASK_STATUS.docs ||
						data?.wbsTaskStatus?.id === WBS_TASK_STATUS.inProgress ||
						data?.wbsTaskStatus?.id === WBS_TASK_STATUS.new
							? data?.project1C?.name
							: constructionObjectField
							? constructionObjectById?.project1C?.name
							: null}
					</span>
				</Form.Item>
			)
		},
		{
			key: 'createdAt',
			children: (
				<Form.Item label="Дата создания">
					<span className="app-form-text">
						{dayjs(data?.createdAt).format(DATE_FORMAT.viewWithHours) ?? Date.now()}
					</span>
				</Form.Item>
			)
		},
		{
			key: 'author',
			children: (
				<Form.Item label="Автор">
					<span className="app-form-text">
						{data?.createdBy?.name ?? `${user?.firstName} ${user?.lastName}`}
					</span>
				</Form.Item>
			)
		},
		{
			key: 'workPackage',
			children: (
				<AbsFormSelect
					label="Пакет работ AbsPlan"
					name="workPackageId"
					rules={[{ required: true }]}
					canEdit={canEdit}
					form={form}
					options={workPackages}
					isLoading={workPackagesFetching}
					dataSourceValue={data?.workPackage.name}
				/>
			)
		},
		{
			key: 'typeSection',
			children: (
				<AbsFormSelect
					label="Раздел НСИ"
					name="typeSectionId"
					rules={[{ required: true }]}
					canEdit={canEdit}
					form={form}
					options={typeSections}
					isLoading={typeSectionsFetching}
					dataSourceValue={data?.typeSection.name}
				/>
			)
		},
		{
			key: 'title',
			children: (
				<AbsFormInput
					label="Наименование заявки"
					name="title"
					rules={[{ required: true }]}
					canEdit={canEdit}
					dataSourceValue={data?.title}
				/>
			)
		},
		{
			key: 'assignedUsers',
			children: (
				<Form.Item
					label="Исполнитель по текущему статусу"
					name={'assignedUsers'}
					className="wbsTaskFieldBasis"
					rules={[
						{ required: !!data && data.wbsTaskStatus.id !== WBS_TASK_STATUS.new ? true : false }
					]}
				>
					{!data || data?.wbsTaskStatus.id === WBS_TASK_STATUS.new ? (
						<span style={{ color: 'gray' }} className="app-form-text">
							Ответственный назначается в статусе ДП
						</span>
					) : (
						<>
							<span className="app-form-text" style={{ width: '100%' }}>
								{data?.assignedUsers?.map(a => (
									<Tag key={a?.id} style={{ fontSize: 14 }} bordered={false} color="blue">
										{a?.name}
									</Tag>
								)) ?? ''}
							</span>
							{((user?.isAdmin && !!data && data.wbsTaskStatus.id !== WBS_TASK_STATUS.new) ||
								(canEditAssignEconomy &&
									(data?.wbsTaskStatus.id === WBS_TASK_STATUS.docs ||
										data?.wbsTaskStatus.id === WBS_TASK_STATUS.additionalRequestAuthorDP ||
										data?.wbsTaskStatus.id === WBS_TASK_STATUS.additionalRequestDP)) ||
								(canEditAssignDesign && data?.wbsTaskStatus.id === WBS_TASK_STATUS.inProgress)) && (
								<Button
									onClick={setOpenModalAssign}
									disabled={
										data?.wbsTaskStatus.id === WBS_TASK_STATUS.done ||
										data?.wbsTaskStatus.id === WBS_TASK_STATUS.additionalRequestAuthor
									}
									style={{ marginRight: 5, marginTop: 5, width: 200 }}
								>
									Назначить ответственных
								</Button>
							)}
						</>
					)}
				</Form.Item>
			)
		},
		{
			key: 'instruction',
			children: (
				<AbsFormInput
					label="Поручение"
					name="instruction"
					canEdit={canEdit}
					isArea={true}
					rules={[{ required: true }]}
					dataSourceValue={data?.instruction}
				/>
			)
		},
		{
			key: 'project',
			children: (
				<AbsFormSelect
					label="Проект"
					name="projectId"
					rules={[{ required: true }]}
					canEdit={canEdit && !data?.id}
					form={form}
					options={user?.projects!}
					isLoading={false}
					dataSourceValue={data?.project?.name}
				/>
			)
		},
		{
			key: 'contract',
			children: (
				<AbsFormSelect
					label="Договор"
					name="contractId"
					rules={[{ required: formValues?.wbsTaskTypeId === WBS_TASK_TYPE.estimateAdjustment.id }]}
					canEdit={canEdit}
					form={form}
					options={contracts}
					isLoading={contractsFetching}
					dataSourceValue={data?.contract?.name}
				/>
			)
		},
		{
			key: 'wbs',
			children: (
				<Form.Item
					label="ВОР для корректировки"
					name={'containerLabel'}
					className="wbsTaskFieldBasis"
				>
					<NavLink
						to={`/unmodel/${
							data && data.wbsTaskStatus?.id === WBS_TASK_STATUS.done
								? data.container?.id
								: formValues?.containerId
						}`}
						target="_blank"
						style={{ marginLeft: 10 }}
					>
						{data && data.wbsTaskStatus?.id === WBS_TASK_STATUS.done
							? data.container?.name ?? ''
							: // : containers?.find(c => c.id === formValues?.containerId)
							  formValues?.containerLabel ?? ''}
					</NavLink>
					<Button
						onClick={setOpenModal}
						style={{ marginRight: 5, marginTop: 5, width: 200 }}
						disabled={
							constructionObjectField === undefined ||
							typeSectionField === undefined ||
							(!data && projectField === undefined) ||
							!canEdit
						}
					>
						Выбрать основание
					</Button>
				</Form.Item>
			)
		},
		{
			key: 'estimate',
			children: (
				<Form.Item
					label="ЛС для корректировки"
					name={'estimateLabel'}
					className="wbsTaskFieldBasis"
				>
					<NavLink
						to={`/local/${
							data && data.wbsTaskStatus?.id === WBS_TASK_STATUS.done
								? data.estimate?.containerId
								: formValues?.estimateId
						}`}
						target="_blank"
						style={{ marginLeft: 10 }}
					>
						{data && data.wbsTaskStatus?.id === WBS_TASK_STATUS.done
							? data.estimate?.name ?? ''
							: // : estimates?.find(e => e.containerId === formValues?.estimateId)
							  formValues?.estimateLabel ?? ''}
					</NavLink>
					<Button
						onClick={setOpenModal}
						style={{ marginRight: 5, marginTop: 5, width: 200 }}
						disabled={
							constructionObjectField === undefined ||
							typeSectionField === undefined ||
							contractField === undefined ||
							(!data && projectField === undefined) ||
							!canEdit
						}
					>
						Выбрать основание
					</Button>
				</Form.Item>
			)
		},
		{
			key: 'estimateValue',
			children: <Form.Item name={'estimateId'} style={{ display: 'none' }}></Form.Item>
		},
		{
			key: 'wbsValue',
			children: <Form.Item name={'containerId'} style={{ display: 'none' }}></Form.Item>
		},
		{
			key: 'resultDocument',
			children: (
				<Form.Item
					label={
						data?.wbsTaskType.id === WBS_TASK_TYPE.wbsAdjustment.id ||
						formValues?.wbsTaskTypeId! === WBS_TASK_TYPE.wbsAdjustment.id
							? 'БЫЛО-СТАЛО ВОР'
							: data?.wbsTaskType.id === WBS_TASK_TYPE.estimateAdjustment.id ||
							  formValues?.wbsTaskTypeId! === WBS_TASK_TYPE.estimateAdjustment.id
							? 'БЫЛО-СТАЛО ЛС'
							: 'ВОР'
					}
					className="wbsTaskFieldBasis"
				>
					<NavLink
						to={`/${
							data?.resultDocument?.wbsTaskType === WBS_TASK_TYPE_ENUM.wbsAdjustment
								? 'unmodel/adjustment/'
								: data?.resultDocument?.wbsTaskType === WBS_TASK_TYPE_ENUM.estimateAdjustment
								? 'local/adjustment/'
								: 'unmodel/'
						}${data?.resultDocument?.id}`}
						target="_blank"
						style={{ marginLeft: 10 }}
					>
						{data?.resultDocument?.name ? data?.resultDocument?.name : ''}
					</NavLink>
					{canCreate && (
						<Button
							style={{ marginRight: 5, marginTop: 5, width: 200 }}
							onClick={() => {
								const dto: IWbsTaskCreateDocument = {
									wbsTaskTypeId: data?.container?.id ?? data?.estimate?.containerId ?? null,
									type:
										data?.wbsTaskType?.id === WBS_TASK_TYPE.wbsAdjustment?.id
											? 'container'
											: data?.wbsTaskType?.id === WBS_TASK_TYPE.estimateAdjustment?.id
											? 'estimate'
											: 'newWbs',
									wbsTaskId: currentTaskId ?? data?.id!,
									name: data?.title!,
									typeSectionId: data?.typeSection?.id!,
									constructionObjectId: data?.constructionObject?.id!,
									projectId: data?.project?.id
								}
								mutateCreateDocument(
									{ dto },
									{
										onSuccess: () => {
											notification.success({
												message: 'Документ успешно создан',
												placement: 'topLeft'
											})
											queryClient.invalidateQueries({ queryKey: ['wbsTasks'] })
											queryClient.invalidateQueries(['wbsTasksDiagram'])
											queryClient.invalidateQueries({ queryKey: ['wbsTaskCard'] })
										},
										onError: (error: any) => {
											notification.error({
												message: 'Произошла ошибка во время создания',
												description: error?.response?.data?.detail,
												placement: 'topLeft'
											})
										}
									}
								)
							}}
						>
							Создать документ
						</Button>
					)}
				</Form.Item>
			)
		}
	]

	const wbsTaskFields: Record<WBS_TASK, string[]> = {
		[WBS_TASK.wbs]: [
			'project',
			'wbsTaskType',
			'title',
			'createdAt',
			'author',
			'constructionObject',
			'subProject',
			'workPackage',
			'typeSection',
			'instruction',
			'assignedUsers',
			'wbs',
			'resultDocument',
			'wbsValue'
		],

		[WBS_TASK.estimate]: [
			'project',
			'wbsTaskType',
			'title',
			'createdAt',
			'author',
			'constructionObject',
			'subProject',
			'workPackage',
			'typeSection',
			'instruction',
			'assignedUsers',
			'contract',
			'estimate',
			'resultDocument',
			'estimateValue'
		],
		[WBS_TASK.common]: [
			'project',
			'wbsTaskType',
			'title',
			'createdAt',
			'author',
			'constructionObject',
			'subProject',
			'workPackage',
			'typeSection',
			'instruction',
			'assignedUsers',
			'resultDocument'
		]
	}

	const mergedItems = () => {
		const wbsTaskItems = canEdit
			? wbsTaskFields[
					formValues?.wbsTaskTypeId === WBS_TASK_TYPE.wbsAdjustment.id
						? WBS_TASK.wbs
						: formValues?.wbsTaskTypeId === WBS_TASK_TYPE.estimateAdjustment.id
						? WBS_TASK.estimate
						: WBS_TASK.common
			  ].map(x => items.find(i => i.key === x)!)
			: wbsTaskFields[
					data?.wbsTaskType.id === WBS_TASK_TYPE.wbsAdjustment.id
						? WBS_TASK.wbs
						: data?.wbsTaskType.id === WBS_TASK_TYPE.estimateAdjustment.id
						? WBS_TASK.estimate
						: WBS_TASK.common
			  ].map(x => items.find(i => i.key === x)!)
		return wbsTaskItems
	}

	return (
		<>
			<WbsTaskModal
				isEstimate={
					formValues?.wbsTaskTypeId === WBS_TASK_TYPE.estimateAdjustment.id ? true : false
				}
				form={form}
				formValues={formValues}
				data={data!}
			/>
			<WbsTaskModalAssign
				// canEdit={canEditAssign}
				options={potentialAssignUsers}
				form={form}
				isLoading={potentialAssignUsersFetching}
				tagRender={tagRender}
				assigned={assigned}
				setAssigned={setAssigned}
				onAssignedSelect={onAssignedSelect}
				onAssignedDeselect={onAssignedDeselect}
			/>
			<Card
				title="Основные параметры"
				className="app-work-card"
				classNames={{ body: 'app-work-card__body' }}
			>
				<Descriptions
					items={mergedItems()}
					size="small"
					column={1}
					colon={false}
					labelStyle={{ display: 'none' }}
					className={'app-drawer-descriptions'}
					bordered
				/>
			</Card>
		</>
	)
}

const tagRender = (props: CustomTagProps) => {
	const { label, value, closable, onClose } = props
	const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
		event.preventDefault()
		event.stopPropagation()
	}
	return (
		<Tag
			color={'blue'}
			onMouseDown={onPreventMouseDown}
			closable={false}
			onClose={onClose}
			style={{ marginRight: 3 }}
		>
			{label}
		</Tag>
	)
}
