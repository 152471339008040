import { INonActualSection } from 'pages/unmodelPage/wbsContainer'
import { API } from 'services/ApiService'
import {
	IEstimatePosition,
	IEstimatePositionNoteDto,
	IEstimatePositionOrder,
	IEstimateSection,
	INoteDto
} from '..'

export const EstimateApi = {
	async getSections(containerId: string, type: 'wbs' | 'local') {
		const url = type === 'local' ? 'containerEstimates' : 'containers'
		return await API<IEstimateSection[]>({
			url: `/v1/${url}/${containerId}/sections`,
			method: 'get',
			params: {
				projectId: null
			}
		}).then(response => response.data)
	},

	async toggleReady(containerId: string, type: 'wbs' | 'local', ready: boolean) {
		const url = type === 'local' ? 'containerEstimates' : 'containers'
		return await API({
			url: `/v1/${url}/${containerId}/ready`,
			method: 'patch',
			params: { ready, projectId: null }
		}).then(response => response.status)
	},

	async getEstimatePositions(containerId: string, sectionId: string, type: 'wbs' | 'local') {
		const url = type === 'local' ? 'containerEstimates' : 'containers'
		return await API<IEstimatePosition[]>({
			url: `/v1/${url}/${containerId}/sections/${sectionId}/estimatePositions`,
			method: 'get',
			params: {
				projectId: null
			}
		}).then(response => response.data)
	},

	async changeOrder(data: IEstimatePositionOrder[], isAdjustment: boolean, type: 'wbs' | 'local') {
		return await API({
			url: `/v1/${isAdjustment === true ? 'adjustment' : `estimatePositions/${type === 'wbs' ? 'container' : 'estimate'}`}/order`,
			method: 'patch',
			data: data,
			params: {
				projectId: null
			}
		}).then(response => response)
	},

	async copyEstimatePosition(ids: string[], type: 'wbs' | 'local') {
		return await API<string[]>({
			url: `/v1/estimatePositions/${type === 'wbs' ? 'container' : 'estimate'}/copy`,
			method: 'post',
			params: {
				projectId: null
			},
			data: ids
		}).then(response => response.data)
	},

	async clearIsCopied(containerId: string, type: 'wbs' | 'local') {
		const url = type === 'local' ? 'containerEstimates' : 'containers'
		return await API({
			url: `/v1/${url}/${containerId}/clearIsCopied`,
			method: 'patch',
			params: {
				projectId: null
			}
		}).then(response => response.data)
	},

	async deleteEstimatePosition(ids: string[], type: 'wbs' | 'local') {
		return await API({
			url: `/v1/estimatePositions/${type === 'wbs' ? 'container' : 'estimate'}`,
			method: 'delete',
			params: {
				projectId: null
			},
			data: ids
		}).then(response => response.data)
	},

	async setEstimatePositionNote(dto: IEstimatePositionNoteDto, type: 'wbs' | 'local') {
		return await API({
			url: `/v1/estimatePositions/${type === 'wbs' ? 'container' : 'estimate'}/note`,
			method: 'patch',
			data: dto,
			params: {
				projectId: null
			}
		}).then(response => response.data)
	},

	async actualize(containerId: string) {
		return await API<boolean>({
			url: `/v1/containers/${containerId}/sections/actualize`,
			method: 'post',
			params: {
				projectId: null
			}
		}).then(response => response.data)
	},

	async getNonActualPositions(containerId: string) {
		return await API<INonActualSection[]>({
			url: `/v1/containers/${containerId}/sections/nonActualPositions`,
			method: 'get',
			params: {
				projectId: null
			}
		}).then(response => response.data)
	},
	async closedEstimatePosition(id: string, type: 'estimate' | 'adjustment', typeContainer: 'wbs' | 'local') {
		return await API({
			url: `/v1/${type === 'estimate' ? 'estimatePositions' : 'adjustment'}${type === 'estimate' ? `${typeContainer === 'wbs' ? '/container' : '/estimate'}` : ''}/${id}/closed`,
			method: 'patch',
			params: {
				projectId: null
			}
		}).then(response => response.data)
	},
	async actualizeEstimate(id: string) {
		return await API<boolean>({
			url: `/v1/containerEstimates/${id}/sections/actualize`,
			method: 'post',
			params: {
				projectId: null
			}
		}).then(response => response.data)
	},
	async setNote(dto: INoteDto, type: 'noteDp' | 'noteDz' | 'noteSdu') {
		return await API({
			url: `/v1/containerEstimates/estimatePositions/${type}`,
			method: 'patch',
			data: dto
		}).then(response => response.data)
	}
}
