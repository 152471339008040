import { API } from 'services/ApiService';
import { IEstimateComment } from '..';

export const EstimateCommentsApi = {
	async getAll(containerId: string, isCommentsClosed?: boolean, myComments?: boolean, type?: 'wbs' | 'local') {
		return await API<IEstimateComment[]>({
			url: `/v1/${type === 'wbs' ? 'containers' : 'containerEstimates'}/${containerId}/estimatePositionsComments`,
			method: 'get',
			params: { isCommentsClosed, myComments, projectId: null }
		}).then(async response => response.data)
	},

	async getAuthors(id: string, type: 'wbs' | 'local') {
		return await API<boolean>({
			url: `/v1/estimatePositions/${type === 'wbs' ? 'container' : 'estimate'}/${id}/comments/authorCommentsStatus`,
			method: 'get',
			params: {
				projectId: null
			}
		}).then(async response => response.data)
	}
}
