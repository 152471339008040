import { Checkbox, Popconfirm } from 'antd'
import { memo } from 'react'
import { useShallow } from 'zustand/react/shallow'

import modal from 'antd/es/modal'
import { AxiosError } from 'axios'
import { useAppSelector } from 'hooks/appReduxHook'
import { IErrorDetail } from 'interfaces/IBase'
import { CONTAINER_STATUS, PERMISSIONS } from 'shared/constants'
import { useCheckPermissions } from 'shared/useCheckPermissions'
import { adjustmentApi } from '../api/adjustment-api'
import {
	IAdjustmentPositionMaterial,
	IAdjustmentToggleIsNominatedSduDto
} from '../model/adjustment-schema'
import { useAdjustmentState } from '../model/adjustment-state'

interface IProps {
	record: IAdjustmentPositionMaterial
	version?: 'origin' | 'contractor' | 'sdu'
	visaIssued: boolean
}

export const AdjustmentNominatedCell = memo(function (params: IProps) {
	const { record, version, visaIssued } = params
	const { user } = useAppSelector(state => state.environment)
	const checkPermissions = useCheckPermissions()
	const { container, updateSections } = useAdjustmentState(
		useShallow(state => ({
			container: state.container,
			updateSections: state.updateSections
		}))
	)

	const onConfirm = async (values: any) => {
		const dto: IAdjustmentToggleIsNominatedSduDto = {
			estimatePositionMaterialId: record.id,
			isNominated: !record.isNominatedSdu
		}
		await adjustmentApi
			.toggleIsNominatedSdu(dto)
			.then(() => {
				updateSections(record.containerSectionId)
			})
			.catch((err: AxiosError<IErrorDetail>) =>
				modal.error({
					title: 'Произошла ошибка',
					content: err.response?.data.detail ?? 'Неизвестная ошибка. Обратитесь в службу поддержки'
				})
			)
	}

	// const canEdit = (): boolean => {
	// 	if (user?.isAdmin && !record.isClosed && !container?.ready) return true
	// 	if (
	// 		typeof container?.isLocalEstimate === 'undefined' &&
	// 		container?.status.name === 'PriceApproval' &&
	// 		checkPermissions([PERMISSIONS.AdjustmentChangeNominated]) &&
	// 		!record.isClosed &&
	// 		!record.isDeleted
	// 	) {
	// 		return true
	// 	}
	// 	if (
	// 		typeof container?.isLocalEstimate !== 'undefined' &&
	// 		container?.status.name === 'PriceApproval' &&
	// 		checkPermissions([PERMISSIONS.AdjustmentChangeNominated]) &&
	// 		!record.isClosed &&
	// 		record.isEditableNominated &&
	// 		!record.isDeleted
	// 	) {
	// 		return true
	// 	}
	// 	return false
	// }

	const canEdit =
		!container?.ready &&
		!record.isDeleted &&
		record.isClosed === false &&
		(typeof container?.isLocalEstimate === 'undefined' ||
			user?.isAdmin ||
			(record.isClosed === false &&
				record.isEditable === true &&
				container?.isLocalEstimate === false)) &&
		(user?.isAdmin ||
			(((container?.status.name === CONTAINER_STATUS.priceApproval &&
				typeof container?.isLocalEstimate === 'undefined') ||
				(container?.status.name === CONTAINER_STATUS.nmtr &&
					record.isEditableNominated === true &&
					container?.isLocalEstimate === false)) &&
				checkPermissions([PERMISSIONS.AdjustmentChangeNominated], container?.project?.id!)))

	return (
		<Popconfirm title="Изменить значение?" onConfirm={onConfirm}>
			<Checkbox checked={record.isNominatedSdu} disabled={!canEdit || visaIssued} />
		</Popconfirm>
	)
})
