import { CommentOutlined } from '@ant-design/icons'
import { Button, Tooltip } from 'antd'
import { memo } from 'react'
import { useSearchParams } from 'react-router-dom'
import { IEstimatePosition } from '..'

interface IProps {
	row: IEstimatePosition
}

const getCommentStatus = (isClosed: boolean, hasComments: boolean) => {
	switch (true) {
		case hasComments && isClosed === false:
			return '#ff3300'
		case hasComments && isClosed === true:
			return '#4a8a48'
		default:
			return 'gray'
	}
}

export const EstimatePositionChat = memo(function (params: IProps) {
	const [_, setSearchParams] = useSearchParams()
	const { row } = params
	return (
		<Tooltip title="Чат">
			<Button
				size="small"
				type="text"
				icon={
					<CommentOutlined
						style={{ color: getCommentStatus(row.isCommentsClosed, row.hasComments) }}
					/>
				}
				onClick={() => setSearchParams({ chat: row.id })}
			/>
		</Tooltip>
	)
})
