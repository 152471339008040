import { useQuery } from '@tanstack/react-query'
import { adjustmentApi } from 'widgets/adjustment'
import { WbsApi } from '..'

export const useConstructionObjects = () => {
	return useQuery({
		queryKey: ['constructionObjects'],
		queryFn: async () => {
			return await WbsApi.getConstructionObjects().then(data => data)
		},
		refetchOnWindowFocus: false
	})
}

export const useContainerVisas = (containerId?: string, isAdjustment?: boolean) => {
	return useQuery({
		queryKey: [isAdjustment ? 'adjustmentContainerVisas' : 'containerVisas', containerId],
		queryFn: async () => {
			if (containerId) {
				return isAdjustment
					? await adjustmentApi.getContainerVisas(containerId, 'wbs').then(data => data)
					: await WbsApi.getContainerVisas(containerId).then(data => data)
			}
			return null
		},
		refetchOnWindowFocus: false
	})
}

export const useContainerStatus = () => {
	return useQuery({
		queryKey: ['containerStatus'],
		queryFn: async () => {
			return await WbsApi.getContainerStatus().then(data => data)
		},
		refetchOnWindowFocus: false
	})
}
