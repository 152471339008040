import { useMutation, useQueryClient } from '@tanstack/react-query'
import { notification } from 'antd'
import { AxiosError } from 'axios'
import { IErrorDetail } from 'interfaces/IBase'
import { contractsGuideApi } from './contracts-guide-api'
import { TContractsGuideForm } from '../model/contracts-guide-schema'

export const useContractsGuideUpdate = () => {
	const queryClient = useQueryClient()
	return useMutation({
		mutationKey: ['contractsGuide', 'save'],
		mutationFn: async ({ id, dto }: { id: string; dto: TContractsGuideForm }) => {
			return contractsGuideApi.updateOrdinanceFine(id, dto)
		},
		onSuccess: () => {
			notification.success({
				message: 'Данные успешно сохранены ',
				placement: 'topLeft'
			})
			queryClient.invalidateQueries({ queryKey: ['contractsGuide'] })
		},
		onError: (error: AxiosError<IErrorDetail>) => {
			notification.error({
				message: `Во время сохранения произошла ошибка. Код ошибки: ${error.response?.status}`,
				description: error.response?.data?.detail,
				placement: 'topLeft'
			})
		}
	})
}
