import dayjs, { Dayjs } from 'dayjs'
import z from 'zod'

export const baseSchema = z.object({
	id: z.string().uuid(),
	name: z.string()
})

export type TBase = z.infer<typeof baseSchema>

export const baseIsDeletedSchema = baseSchema.extend({
	isDeleted: z.boolean()
})

export type TBaseIsDeleted = z.infer<typeof baseIsDeletedSchema>

export const baseIsOriginSchema = baseSchema.extend({
	isOrigin: z.boolean(),
	containerId: z.string().uuid().nullable()
})

export type TBaseIsOrigin = z.infer<typeof baseIsOriginSchema>

export const zodDay = z.custom<Dayjs>(val => val instanceof dayjs, 'Invalid date')
