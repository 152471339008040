import { useMutation, useQueryClient } from '@tanstack/react-query'
import { notification } from 'antd'
import { AxiosError } from 'axios'
import { IErrorDetail } from 'interfaces/IBase'
import { LocalEstimateContainerApi } from 'pages/unmodelPage/localEstimate/api/localEstimateApi'
import { WbsContainerApi } from 'pages/unmodelPage/wbsContainer'
import { adjustmentApi } from 'widgets/adjustment'
import { WbsApi } from './wbs-api'

type TSetVisaProps = {
	type: 'wbs' | 'local'
	isAdjustment?: boolean
	containerId: string
	itemId: string
}

type TUnsetVisaProps = {
	type: 'wbs' | 'local'
	isAdjustment?: boolean
	containerId: string
	itemId: string
	statusId: string
}

export const useSetVisaMutation = () => {
	const queryClient = useQueryClient()
	return useMutation({
		mutationKey: ['visa', 'set'],
		mutationFn: async (props: TSetVisaProps) => {
			const api = props.isAdjustment
				? adjustmentApi
				: props.type === 'local'
				? LocalEstimateContainerApi
				: WbsContainerApi
			await api.setVisaApprove(props.containerId, props.type, props.itemId)
		},
		onSuccess: () => {
			queryClient.invalidateQueries({
				predicate: query =>
					[
						'canVisaApprove',
						'localEstimateCanVisaApprove',
						'adjustmentContainer',
						'estimateContainer',
						'localEstimateRegistry',
						'wbsRegistry',
						'adjustmentLocalEstimateVisas',
						'adjustmentContainerVisas',
						'localEstimateVisas',
						'containerVisas'
					].includes(query.queryKey[0] as string)
			})
		}
	})
}

export const useUnsetVisaMutation = () => {
	const queryClient = useQueryClient()

	return useMutation({
		mutationKey: ['visa', 'unset'],
		mutationFn: async (props: TUnsetVisaProps) => {
			const api = props.isAdjustment
				? adjustmentApi
				: props.type === 'local'
				? LocalEstimateContainerApi
				: WbsContainerApi
			await api.unsetVisaApprove(props.containerId, props.type, props.itemId, props.statusId)
		},
		onSuccess: () => {
			notification.success({
				message: 'Виза отменена',
				placement: 'topLeft'
			})
			queryClient.invalidateQueries({
				predicate: query =>
					[
						'canVisaApprove',
						'localEstimateCanVisaApprove',
						'adjustmentContainer',
						'estimateContainer',
						'localEstimateRegistry',
						'wbsRegistry',
						'adjustmentLocalEstimateVisas',
						'adjustmentContainerVisas',
						'localEstimateVisas',
						'containerVisas'
					].includes(query.queryKey[0] as string)
			})
		},
		onError: (error: AxiosError<IErrorDetail>) => {
			notification.error({
				message: `Непредвиденная ошибка. Обратитесь к администратору. Код ошибки: ${error.response?.status}`,
				description: error.response?.data?.detail,
				placement: 'topLeft'
			})
		}
	})
}

export const useRollupStatus = () => {
	const queryClient = useQueryClient()
	return useMutation({
		mutationKey: ['estimateStatus', 'rollup'],
		mutationFn: async ({
			containerId,
			containerStatusId,
			type,
			isAdjustment
		}: {
			containerId: string
			containerStatusId: string
			type: 'wbs' | 'local'
			isAdjustment?: boolean
		}) => WbsApi.statusRollup(containerId, containerStatusId, type, isAdjustment),
		onSuccess: () => {
			notification.success({
				message: 'Статус изменён',
				placement: 'topLeft'
			})
			queryClient.invalidateQueries({
				predicate: query =>
					[
						'estimateContainer',
						'adjustmentContainer',
						'localEstimate',
						'containerVisas',
						'adjustmentContainerVisas',
						'adjustmentLocalEstimateVisas',
						'localEstimateVisas',
						'wbsRegistry'
					].includes(query.queryKey[0] as string)
			})
		},
		onError: (error: AxiosError<IErrorDetail>) => {
			notification.error({
				message: `Непредвиденная ошибка. Обратитесь к администратору. Код ошибки: ${error.response?.status}`,
				description: error.response?.data?.detail,
				placement: 'topLeft'
			})
		}
	})
}
