import { useQueryClient } from '@tanstack/react-query'
import { Button, Form, Table } from 'antd'
import { Color } from 'antd/es/color-picker'
import { WbsTaskStatusApi } from '../api/wbs-task-status-api'
import {
	IWbsTaskStatusRegistry,
	IWbsTaskVisa,
	IWbsTaskVisaWithParent
} from '../model/wbs-task-status-interface'
import { useWbsTaskStatuses } from '../model/wbs-task-status-queries'
import { useWbsTaskStatusState } from '../model/wbs-task-status-state'
import { WbsTaskStatusCell } from './wbs-task-status-cell'
import { useWbsTaskStatusColumns } from './wbs-task-status-columns'
import { WbsTaskVisaAdd } from './wbs-task-status-modal'
import { MenuOutlined } from '@ant-design/icons'
import { SortableContext, useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { DndContext, DragEndEvent } from '@dnd-kit/core'
import { SyntheticListenerMap } from '@dnd-kit/core/dist/hooks/utilities'

interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
	'data-row-key': string
}

// const RowLine = ({ children, ...props }: RowProps, disabled: boolean) => {
// 	const {
// 		attributes,
// 		listeners,
// 		setNodeRef,
// 		setActivatorNodeRef,
// 		transform,
// 		transition,
// 		isDragging
// 	} = useSortable({
// 		id: props['data-row-key']
// 	})

// 	const style: React.CSSProperties = {
// 		...props.style,
// 		transform: CSS.Transform.toString(transform && { ...transform, scaleY: 1 }),
// 		transition,
// 		...(isDragging ? { position: 'relative', zIndex: 9 } : {})
// 	}

// 	return (
// 		<tr {...props} ref={setNodeRef} style={style} {...attributes}>
// 			{Children!?.map(children, child => {
// 				if ((child as ReactElement).key === 'sort') {
// 					return cloneElement(child as React.ReactElement, {
// 						children: (
// 							<>
// 								<MenuOutlined
// 									ref={setActivatorNodeRef}
// 									style={{
// 										touchAction: 'none',
// 										cursor: disabled ? 'not-allowed' : 'move',
// 										color: disabled ? '#f2f2f2' : '#9e9e9e'
// 									}}
// 									{...listeners}
// 								/>
// 							</>
// 						)
// 					})
// 				}
// 				return child
// 			})}
// 		</tr>
// 	)
// }
interface RowContextProps {
	setActivatorNodeRef?: (element: HTMLElement | null) => void
	listeners?: SyntheticListenerMap
}

const RowContext = React.createContext<RowContextProps>({})

const DragHandle: React.FC = () => {
	const { setActivatorNodeRef, listeners } = useContext(RowContext)
	return (
		<Button
			type="text"
			size="small"
			icon={<MenuOutlined />}
			style={{ cursor: 'move' }}
			ref={setActivatorNodeRef}
			{...listeners}
		/>
	)
}

const RowLine: React.FC<RowProps> = props => {
	const {
		attributes,
		listeners,
		setNodeRef,
		setActivatorNodeRef,
		transform,
		transition,
		isDragging
	} = useSortable({ id: props['data-row-key'] })

	const style: React.CSSProperties = {
		...props.style,
		transform: CSS.Translate.toString(transform),
		transition,
		...(isDragging ? { position: 'relative', zIndex: 9999 } : {})
	}

	const contextValue = useMemo<RowContextProps>(
		() => ({ setActivatorNodeRef, listeners }),
		[setActivatorNodeRef, listeners]
	)

	return (
		<RowContext.Provider value={contextValue}>
			<tr {...props} ref={setNodeRef} style={style} {...attributes} />
		</RowContext.Provider>
	)
}

export const WbsTaskStatusRegistry = () => {
	const { editing: editingRegistry, setEditing } = useWbsTaskStatusState()
	const { data, isFetching } = useWbsTaskStatuses()
	const [wbsTaskStatusGuideForm] = Form.useForm()
	const queryClient = useQueryClient()
	const [dataSource, setDataSource] = useState<any[]>([])

	const onEdit = (record: IWbsTaskStatusRegistry | IWbsTaskVisa) => {
		if ('roles' in record) {
			wbsTaskStatusGuideForm.setFieldsValue({
				name: record.name,
				rolesId: record.roles?.flatMap(r => r.id),
				color: 'color' in record ? '#' + record.color : undefined
			})
		} else {
			wbsTaskStatusGuideForm.setFieldsValue({ name: record.description })
		}
		setEditing(record?.id!)
	}

	const removeVisaFromStatus = async (item: IWbsTaskVisaWithParent) => {
		const parent = data?.find(d => d.id === item?.parentId!)
		if (parent) {
			const dto = {
				name: parent.name!,
				description: parent.description ?? null,
				color: parent.color ?? null,
				order: parent.order,
				visasIds: (parent.children.filter(v => v.id !== item.id).flatMap(v => v.id) as any) ?? []
			}
			await WbsTaskStatusApi.updateStatus(item.parentId!, dto).then(() => {
				queryClient.invalidateQueries({
					queryKey: ['wbsTaskStatusGuide']
				})
				queryClient.invalidateQueries({ queryKey: ['wbsTaskVisas'] })
			})
		}
	}

	const onCancelEditing = () => {
		wbsTaskStatusGuideForm.resetFields()
		setEditing(null)
	}

	const onSave = async (record: IWbsTaskStatusRegistry | IWbsTaskVisaWithParent) => {
		const validate = await wbsTaskStatusGuideForm
			.validateFields()
			.then(() => true)
			.catch(() => false)
		if (validate) {
			const formData = wbsTaskStatusGuideForm.getFieldsValue()
			if ('roles' in record) {
				await WbsTaskStatusApi.updateVisa(record.id!, formData).then(() => {
					queryClient.invalidateQueries({
						queryKey: ['wbsTaskStatusGuide']
					})
					queryClient.invalidateQueries({ queryKey: ['wbsTaskVisas'] })
				})
			} else {
				const dto = {
					name: record.name!,
					description: formData.name!,
					color: formData.color ? (formData.color as Color).toHex()! : record.color,
					order: record.order!,
					visasIds: record.children.flatMap(v => v.id) as any
				}
				await WbsTaskStatusApi.updateStatus(record.id!, dto).then(() => {
					queryClient.invalidateQueries({
						queryKey: ['wbsTaskStatusGuide']
					})
					queryClient.invalidateQueries({ queryKey: ['wbsTaskVisas'] })
				})
			}
			onCancelEditing()
		}
	}

	const getTreeData = () => {
		return (
			data &&
			data?.map((status: any, statusIndex: number) => ({
				...status,
				key: `status-${statusIndex}`,
				title: status?.name || `Статус ${statusIndex + 1}`,
				data: {
					visaId: status.children?.map((c: any) => c.id),
					visaIdOrder: status.children?.map((c: any) => [c.id, c.order])
				},
				children: (status?.children || [])?.map((visa: any, visaIndex: number) => ({
					...visa,
					key: `status-${statusIndex}-visa-${visaIndex}`,
					title: visa?.name || `Виза ${visaIndex + 1}`
				}))
			}))
		)
	}

	useEffect(() => {
		data && setDataSource(getTreeData()!)
	}, [data])

	const onDragEnd = ({ active, over }: DragEndEvent) => {
		if (active.id !== over?.id) {
			const statusId = dataSource?.filter(record =>
				record?.data.visaId?.find((elem: any) => elem === active?.id)
			)[0].id
			const activeIdOrder = dataSource
				?.filter(record => record?.data.visaId?.find((elem: any) => elem === active?.id))[0]
				.data.visaIdOrder?.filter((v: any) => v[0] === active.id)[0][1]
			const overIdOrder = dataSource
				?.filter(record => record?.data.visaId?.find((elem: any) => elem === over?.id))[0]
				.data.visaIdOrder?.filter((v: any) => v[0] === over?.id)[0][1]

			const newDto = dataSource
				?.filter(record => record?.data.visaId?.find((elem: any) => elem === active?.id))[0]
				.children?.reduce((acc: any, item: any) => {
					const value = { id: item.id, orderPosition: item.order }
					return [...acc, value]
				}, [])
				?.map((elem: any) =>
					elem.id === active?.id
						? { ...elem, id: over?.id }
						: elem.id === over?.id
						? { ...elem, id: active?.id }
						: elem
				)

			if (!!statusId && typeof activeIdOrder === 'number' && typeof overIdOrder === 'number') {
				WbsTaskStatusApi.updateOrderVisa(statusId, newDto).then(() =>
					queryClient.invalidateQueries(['wbsTaskStatusGuide'])
				)
			}
		}
	}

	const columns = useWbsTaskStatusColumns({
		onEdit,
		onCancelEditing,
		removeVisaFromStatus,
		onSave,
		DragHandle
	})

	return (
		<div className="dashboard__block" style={{ borderRadius: '0 0 .8rem .8rem' }}>
			<WbsTaskVisaAdd />
			<Form form={wbsTaskStatusGuideForm} component={false}>
				<DndContext onDragEnd={onDragEnd}>
					<SortableContext
						items={dataSource?.map(d => d.key)}
						strategy={verticalListSortingStrategy}
					>
						<Table
							components={{
								body: {
									cell: WbsTaskStatusCell,
									row: (record: RowProps) => RowLine(record, false)
								}
							}}
							loading={isFetching}
							className="app-wbs-table app-wbs-table--on-top"
							sticky={{ offsetHeader: 0 }}
							bordered
							rowKey={row => row?.key!}
							dataSource={dataSource as any}
							columns={columns}
							pagination={false}
							size="small"
							onRow={row => ({
								style: { cursor: 'color' in row ? 'pointer' : 'default' }
							})}
							expandable={{
								showExpandColumn: false,
								columnWidth: 64,
								expandRowByClick: editingRegistry === null,
								rowExpandable: (row: any) => 'color' in row && !!row?.children?.length!
							}}
						/>
					</SortableContext>
				</DndContext>
			</Form>
		</div>
	)
}
