import { IBase } from 'interfaces/IBase'
import { IIssueType } from 'interfaces/IIssue'
import { API } from './ApiService'

export interface IWorkPackage extends IBase {
	issueTypes: IIssueType[]
	code?: number
	typeSections?: IBase[]
}

export const workPackageService = {
	async getList() {
		return await API<IWorkPackage[]>({
			url: `/v1/workPackage`,
			method: 'get'
		}).then(response => response.data)
	},
	async setIssueTypes(
		workPackageId: string,
		dto: { issueTypesId: string[]; typeSectionsId: string[] }
	) {
		return await API({
			url: `/v1/workPackage/${workPackageId}`,
			method: 'patch',
			data: { ...dto }
		}).then(response => response.data)
	}
}
