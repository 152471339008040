import { Button, Col, DatePicker, Drawer, Form, List, Row, Select, Typography } from 'antd'
import locale from 'antd/es/date-picker/locale/ru_RU'
import dayjs from 'dayjs'
import { useAppSelector } from 'hooks/appReduxHook'
import { useContractors } from 'hooks/usePermissions'
import { FC, useEffect } from 'react'
import { PERMISSIONS } from 'shared/constants'
import { useCheckPermissions } from 'shared/useCheckPermissions'
import { useDiagramsProjectState } from 'widgets/diagrams-project/model/diagrams-project-state'
import { IFilterItem, IOrdinanceFineFilters } from 'widgets/fine/model/fine-interface'
import { useAuthors, useContracts, useOrdinanceFineStatuses } from 'widgets/fine/model/fine-queries'
import { useOrdinanceFineState } from 'widgets/fine/model/fine-state'

const { Option } = Select
const { Text } = Typography

export const FineFilters: FC = () => {
	const checkPermissions = useCheckPermissions()
	const { show, filters, setShow, setOrdinanceFineFilters, onPagination } = useOrdinanceFineState()
	const { user: currentUser, project } = useAppSelector(state => state.environment)
	const [filtersForm] = Form.useForm()
	const { data: contracts } = useContracts()
	const { data: contractors } = useContractors()
	const { data: subContractors } = useContractors()
	const { data: ordinanceFineStatuses } = useOrdinanceFineStatuses()
	const { data: authors } = useAuthors()
	const allCompanies = checkPermissions([PERMISSIONS.AllCompanies], project?.id)
	const { authors: authorsMain } = useDiagramsProjectState()

	const filterList: IFilterItem[] = [
		{
			title: 'Статус',
			formItemName: 'ordinanceFineStatusId',
			list: ordinanceFineStatuses,
			date: false,
			disabled: false
		},
		{
			title: 'Повторный',
			formItemName: 'isRepeated',
			list: [
				{ label: 'Да', value: true },
				{ label: 'Нет', value: false }
			],
			date: false,
			disabled: false
		},
		{
			title: 'Дата создания',
			formItemName: 'createdAt',
			list: null,
			date: true,
			disabled: false
		},
		{
			title: 'Генподрядчик',
			formItemName: 'contractorIds',
			list: contractors,
			date: false,
			disabled: !allCompanies
		},
		{
			title: 'Субподрядчик',
			formItemName: 'subContractorIds',
			list: subContractors?.sort((a, b) => a.name.localeCompare(b.name)),
			date: false,
			disabled: !allCompanies
		},
		{
			title: 'Договор',
			formItemName: 'contractId',
			list: contracts,
			date: false,
			disabled: false
		},
		{
			title: 'Срок устранения нарушения после выдачи штрафа',
			formItemName: 'dateToFilter',
			list: null,
			date: true,
			disabled: false
		},
		{ title: 'Авторы', formItemName: 'createdByIds', list: authors, date: false, disabled: false }
	]

	useEffect(() => {
		if (!!authors && !!authorsMain && !!filters?.createdByIds) {
			const ownerIds = authors?.filter(o => authorsMain?.includes(o?.ownerId))?.map(o => o?.ownerId)
			const filtersFine = { ...filters, createdByIds: ownerIds as any }
			setOrdinanceFineFilters(filtersFine)
			filtersForm.setFieldsValue(filters)
		}
	}, [authorsMain, authors])

	filtersForm.setFieldsValue({ ...filters })

	const onSetFilters = (filtersFine: IOrdinanceFineFilters, show: boolean) => {
		if (filtersFine) {
			const filtersWithSearch = {
				...filters,
				ordinanceFineStatusId: filtersFine.ordinanceFineStatusId,
				isRepeated: filtersFine.isRepeated,
				createdTo:
					dayjs(filtersFine.createdAt!?.[1]).isValid() && filtersFine.createdAt
						? dayjs(filtersFine.createdAt!?.[1]).format('YYYY-MM-DD')
						: undefined,
				createdFrom:
					dayjs(filtersFine.createdAt!?.[0]).isValid() && filtersFine.createdAt
						? dayjs(filtersFine.createdAt!?.[0]).format('YYYY-MM-DD')
						: undefined,
				contractorIds: filtersFine.contractorIds,
				contractId: filtersFine.contractId,
				subContractorIds: filtersFine.subContractorIds,
				dateTo:
					dayjs(filtersFine.dateToFilter!?.[1]).isValid() && filtersFine.dateToFilter
						? dayjs(filtersFine.dateToFilter!?.[1]).format('YYYY-MM-DD')
						: undefined,
				dateFrom:
					dayjs(filtersFine.dateToFilter!?.[0]).isValid() && filtersFine.dateToFilter
						? dayjs(filtersFine.dateToFilter!?.[0]).format('YYYY-MM-DD')
						: undefined,
				createdByIds: filtersFine.createdByIds
			}
			setOrdinanceFineFilters(filtersWithSearch)
		}
		if (show) {
			setShow(false)
		}
		onPagination(1)
	}

	const onConfirmFilters = () => {
		onSetFilters(filtersForm.getFieldsValue(), true)
	}

	useEffect(() => {
		if (!allCompanies) {
			checkAllCompanies()
		}
	}, [show])

	const checkAllCompanies = () => {
		const cId = currentUser?.contractorId!
		filtersForm.setFieldValue('contractorIds', cId)
	}

	const onResetFilters = () => {
		filtersForm.resetFields()
		filtersForm.submit()
	}

	return (
		<Drawer
			open={show}
			onClose={() => setShow(false)}
			placement="left"
			title="Фильтры"
			className="at-drawer-left"
			footer={
				<Row gutter={8} justify="end">
					<Col>
						<Button ghost danger onClick={onResetFilters}>
							Сбросить
						</Button>
					</Col>
					<Col>
						<Button type="primary" htmlType="submit" onClick={() => filtersForm.submit()}>
							Применить
						</Button>
					</Col>
				</Row>
			}
		>
			<Form name="filtersForm" form={filtersForm} onFinish={onConfirmFilters}>
				<List
					// loading={isLoading}
					dataSource={filterList}
					renderItem={(item, index) => (
						<Row align="middle" gutter={8}>
							<Col style={{ flex: '0 0 100%' }}>
								<Text strong>{item.title}:</Text>
							</Col>
							<Col style={{ flex: '1 0 100%' }}>
								<Form.Item key={index} name={item.formItemName}>
									{!item.date ? (
										<Select
											// loading={isLoading}
											showSearch
											allowClear
											mode={
												item.formItemName === 'contractorIds' ||
												item.formItemName === 'subContractorIds' ||
												item.formItemName === 'createdByIds'
													? 'multiple'
													: undefined
											}
											optionFilterProp="children"
											disabled={item.disabled || item.list?.length === 0}
											filterSort={(optionA, optionB) =>
												(optionA?.label ?? '')
													.toString()
													.toLowerCase()
													.localeCompare((optionB?.label ?? '').toString().toLowerCase())
											}
											style={{ width: '100%' }}
											placeholder="Не выбран"
										>
											{item.formItemName === 'isRepeated'
												? item.list! &&
												  item.list!?.map((option, index) => (
														<Option
															key={option.id ? option.id : index}
															value={option.label === 'Да' ? true : false}
														>
															{option.label}
														</Option>
												  ))
												: item.list! &&
												  item.list!?.map((option, index) => (
														<Option
															key={option.id ? option.id : option.ownerId ? option.ownerId : index}
															value={
																option.id ? option.id : option.ownerId ? option.ownerId : index
															}
														>
															{option.name ? option.name : option}
														</Option>
												  ))}
										</Select>
									) : (
										<DatePicker.RangePicker
											locale={locale}
											style={{ width: '100%' }}
											format={'DD.MM.YYYY'}
										/>
									)}
								</Form.Item>
							</Col>
						</Row>
					)}
				/>
			</Form>
		</Drawer>
	)
}
