import { API } from 'services/ApiService'

export const wbsTaskVisasApi = {
	async get(id: string) {
		return await API({
			url: `/v1/wbsTask/${id}/visas`,
			method: 'get',
			params: {
				projectId: null
			}
		}).then(response => response.data)
	},
	async post(id: string, visaId?: string) {
		return await API({
			url: `/v1/wbsTask/${id}/approveDp`,
			method: 'post',
			params: { visaId, projectId: null }
		}).then(response => response.data)
	},
	async getCanVisaApprove(id: string) {
		return await API<string[]>({
			url: `/v1/wbsTask/${id}/approvalAvailable`,
			method: 'get',
			params: {
				projectId: null
			}
		}).then(response => response.data)
	},
	async setVisaApproveDp(id: string, visaId?: string) {
		return await API({
			url: `/v1/wbsTask/${id}/approveDp`,
			method: 'post',
			params: { visaId, projectId: null }
		}).then(response => response.data)
	},
	async setVisaApproveDes(id: string, visaId?: string) {
		return await API({
			url: `/v1/wbsTask/${id}/approveDes`,
			method: 'post',
			params: { visaId, projectId: null }
		}).then(response => response.data)
	},
	async setVisaApproveCoordinatorDP(id: string, visaId?: string) {
		return await API({
			url: `/v1/wbsTask/${id}/approveCoordinatorDP`,
			method: 'post',
			params: { visaId, projectId: null }
		}).then(response => response.data)
	},
	async setVisaApproveCoordinatorDES(id: string, visaId?: string) {
		return await API({
			url: `/v1/wbsTask/${id}/approveCoordinatorDES`,
			method: 'post',
			params: { visaId, projectId: null }
		}).then(response => response.data)
	},
	async unsetVisaApprove(id: string, visaId: string) {
		return await API({
			url: `/v1/wbsTask/${id}/unapprove`,
			method: 'post',
			params: { projectId: null },
			data: { visaId }
		}).then(response => response.data)
	},
	async statusRollup(id: string, wbsTaskStatusId: string) {
		return await API({
			url: `/v1/wbsTask/${id}/unApproveAdmin`,
			method: 'post',
			data: { wbsTaskStatusId }
		}).then(response => response.data)
	}
}
