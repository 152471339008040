import { useMutation, useQuery } from "@tanstack/react-query"
import { useReportState } from "../model/reports-state"
import { reportApi } from "./reports-api"
import { reportService } from "services/ReportService"
import { TReportForm } from "../model/reports-schema"

export const useReport = () => {
    const {filters, currentPage, setTotalItems, download} = useReportState()
    return useQuery({
        queryKey: ['report', filters, currentPage],
        queryFn: async () => {
            if (!download && filters) {
                const report = await reportApi.get(filters!, currentPage)
                setTotalItems(report.totalItems)
                return report.data
            }
        },
        select: data => data,
        refetchOnWindowFocus: false
    })
}

export const useReportIssueContractors = (projects?: string[]) => {
    return useQuery({
       queryKey: ['reportIssueContractors', projects],
        queryFn: async () => {
            return projects && projects?.length > 0
                ? await reportService.getContractors(projects)
                : []
        },
        select: data => data,
        refetchOnWindowFocus: false
    })
}

export const useReportIssueAuthors = (projects?: string[]) => {
    return useQuery({
        queryKey: ['reportIssueAuthors', projects],
		queryFn: async () => {
			return projects && projects?.length > 0
				? await reportService.getAuthors(projects)
				: []
		},
        select: data => data,
        refetchOnWindowFocus: false
    })
}

export const useReportExcel = () => {
    return useMutation(async (filters: TReportForm) => {
        return await reportApi.exportToExcel(filters)
    })
}
