import {
	Button,
	Card,
	Col,
	DatePicker,
	Descriptions,
	Form,
	Modal,
	Row,
	Select,
	message
} from 'antd'
import locale from 'antd/es/date-picker/locale/ru_RU'
import 'dayjs/locale/ru'
import { useAppSelector } from 'hooks/appReduxHook'
import { IConstructionObject } from 'interfaces/IConstructionObject'
import { IContract } from 'interfaces/IContract'
import { IContractor, IProject } from 'interfaces/IEnvironment'
import { IIssueStatus, IIssueSubType, IIssueType, IIssuesFilters } from 'interfaces/IIssue'
import { FC, useEffect, useState } from 'react'
import { getAuthors } from 'services/OrdinanceService'
import { IRegistryColumns, exportService } from 'services/exportService'
import { uniqueSet } from 'services/helperService'
import { PERMISSIONS } from 'shared/constants'
import { useCheckPermissions } from 'shared/useCheckPermissions'

interface IProps {
	show: boolean
	onClose: () => void
}

interface IFilterItem {
	title: string | React.ReactNode
	formItemName: string
	list:
		| IIssueStatus[]
		| IIssueType[]
		| IIssueSubType[]
		| IContract[]
		| IContractor[]
		| IConstructionObject[]
		| IProject[]
		| any[]
		| null
	date: boolean
	disabled: boolean
}

interface IXLSReport extends IIssuesFilters {
	columns: number[] | undefined
}

export const IssueReportModal: FC<IProps> = ({ show, onClose }) => {
	const checkPermissions = useCheckPermissions()
	const [registryColumns, setRegistryColumns] = useState<IRegistryColumns[]>([])
	const [issueReportForm] = Form.useForm()
	const mainContractor = Form.useWatch('contractorId', issueReportForm)
	const {
		project,
		user: currentUser,
		issueStatuses: statuses,
		issueTypes,
		issueSubTypes,
		contractors,
		contracts,
		allProjects
	} = useAppSelector(state => state.environment)
	const [subTypes, setSubTypes] = useState<IIssueSubType[]>(
		uniqueSet([...issueSubTypes!]!?.sort((a, b) => a.name.localeCompare(b.name))!, 'name')
	)
	const [subContractors, setSubContractors] = useState<IContractor[]>([])
	const [owners, setOwners] = useState<any[]>([])
	const allCompanies = checkPermissions([PERMISSIONS.AllCompanies], project?.id)
	const hasFilter = [
		{
			id: '0',
			name: 'Нет'
		},
		{
			id: '1',
			name: 'Есть'
		}
	]

	const filterList: IFilterItem[] = [
		{
			title: 'Статус',
			formItemName: 'status',
			list: [...statuses!]!?.filter(
				(status: IIssueStatus) =>
					status!?.identityName!?.includes('ready')! ||
					status!?.identityName!?.includes('closed')! ||
					status!?.identityName!?.includes('cancel')! ||
					status!?.identityName!?.includes('open')!
			),
			date: false,
			disabled: false
		},
		{
			title: 'Тип',
			formItemName: 'issueTypeId',
			list: issueTypes?.filter(t => !t.isDeleted) || [],
			date: false,
			disabled: false
		},
		{
			title: 'Подтип',
			formItemName: 'issueSubTypeId',
			list: subTypes?.filter(t => !t.isDeleted) || [],
			date: false,
			disabled: false
		},
		{
			title: 'Генподрядчик',
			formItemName: 'contractorId',
			list: [...contractors!]!?.sort((a, b) => a.name.localeCompare(b.name))!,
			date: false,
			disabled: !checkPermissions([PERMISSIONS.AllCompanies], project?.id)
		},
		{
			title: 'Субподрядчик',
			formItemName: 'subContractorId',
			list: subContractors!?.sort((a, b) => a.name.localeCompare(b.name))!,
			date: false,
			disabled: false
		},
		{
			title: 'Автор',
			formItemName: 'ownerId',
			list: owners,
			date: false,
			disabled: false
		},
		{ title: 'Дата создания', formItemName: 'createdAt', list: null, date: true, disabled: false },
		{ title: 'Срок погашения', formItemName: 'dueDate', list: null, date: true, disabled: false },
		{
			title: 'Наличие предписания',
			formItemName: 'hasOrdinance',
			list: hasFilter,
			date: false,
			disabled: false
		},
		{
			title: 'Фаза',
			formItemName: 'phase',
			list: allProjects.filter(el => el.id === project?.id).filter(el => el.phase),
			date: false,
			disabled: false
		},
		{
			title: 'Корпус',
			formItemName: 'building',
			list: allProjects.filter(el => el.id === project?.id).filter(el => el.building),
			date: false,
			disabled: false
		},
		{
			title: 'Руководитель строительства',
			formItemName: 'responsibleManager',
			list: allProjects
				.filter(el => el.id === project?.id)
				.filter(elem => elem.responsibleManager)
				.map(el => el.responsibleManager)!,
			date: false,
			disabled: false
		},
		{
			title: 'Наличие чек-листа',
			formItemName: 'hasChecklist',
			list: hasFilter,
			date: false,
			disabled: false
		}
	]

	const [filterItems, setFilterItems] = useState<IFilterItem[]>(filterList)

	useEffect(() => {
		if (!allCompanies) {
			checkAllCompanies()
		}
		if (show) {
			exportService.getColumns().then(data => setRegistryColumns(data))
		}
	}, [show])

	const getIssuesAuthors = async () => {
		if (typeof project?.id !== 'undefined')
			await getAuthors(project.id).then(response => {
				setOwners(
					response.map((owner: { ownerId: string; name: string }) => ({
						id: owner.ownerId,
						name: owner.name
					}))
				)
			})
	}

	const checkAllCompanies = () => {
		const cId = currentUser?.contractorId!
		issueReportForm.setFieldValue('contractorId', cId)
		onChangeFilter('contractorId', cId)
	}

	useEffect(() => {
		setFilterItems(filterList)
	}, [statuses, issueTypes, subTypes, contractors, subContractors, owners, allProjects])

	useEffect(() => {
		issueReportForm.resetFields()
		getIssuesAuthors()
	}, [project])

	useEffect(() => {
		getSubContractors(mainContractor!)
	}, [mainContractor])

	const onChangeFilter = (field: string, value: any) => {
		const filters: IIssuesFilters = issueReportForm.getFieldsValue()
		if (field === 'contractorId') {
			if (allCompanies) {
				issueReportForm.setFieldValue('subContractorId', undefined)
				issueReportForm.setFieldValue('constuctionObjectId', undefined)
			}
		}

		if (field === 'issueTypeId') {
			if (typeof filters.issueTypeId !== 'undefined') {
				const currentSubTypes = issueSubTypes!?.filter(subType => subType.issueTypeId === value)
				setSubTypes(currentSubTypes!?.sort((a, b) => a.name.localeCompare(b.name)))
			} else {
				setSubTypes(
					uniqueSet([...issueSubTypes!]!?.sort((a, b) => a.name.localeCompare(b.name))!, 'name')
				)
			}
		}
		if (field === 'status' && !!value.length === false) {
			issueReportForm.setFieldValue('status', undefined)
		}
	}

	const getSubContractors = (contractor: string | undefined) => {
		setSubContractors([])
		if (contractor !== undefined && contracts!.length > 0) {
			const objects: IContract[] = contractor
				? contracts!?.filter((contract: IContract) => contract!?.contractor!?.id! === contractor)
				: contracts!
			let subs: IContractor[] = []
			objects
				.flatMap((contract: IContract) => contract.subContractor)
				.forEach(obj => {
					if (obj !== null)
						if (!subs.some(item => item?.id! === obj?.id!)) {
							subs.push(obj)
						}
				})
			if (contractor) {
				const selected = contractors?.find(c => c.id === contractor)
				subs.unshift(selected!)
			}
			setSubContractors(subs!?.sort((a, b) => a.name.localeCompare(b.name)))
		}
	}
	const checkTypeName = (name: string) => {
		switch (name) {
			case 'Safety':
				return 'Безопасность'
			case 'Quality':
				return 'Качество'
			default:
				return name
		}
	}

	const onGetReport = async (values: IXLSReport) => {
		const { columns, ...filters } = values
		await exportService
			.exportIssuesToXLSX({ columns, filters })
			.then(response => {
				const href = URL.createObjectURL(response.data)
				const link = document.createElement('a')
				link.href = href
				link.setAttribute('download', 'Замечания')
				document.body.appendChild(link)
				link.click()
				document.body.removeChild(link)
				URL.revokeObjectURL(href)
			})
			.then(() => onModalClose())
			.catch(() => message.error('Во время загрузки файла произошла ошибка'))
	}

	const onModalClose = () => {
		issueReportForm.resetFields()
		onClose()
	}

	return (
		<Form
			name="issueReportForm"
			form={issueReportForm}
			onFinish={values => {
				onGetReport(values)
			}}
		>
			<Modal
				width="50vw"
				destroyOnClose
				open={show}
				onOk={() => issueReportForm.submit()}
				okText="Выгрузить"
				onCancel={onModalClose}
				title="Настройка выгрузки"
				centered
			>
				<Card className="at-workcard" title="Колонки">
					<Row gutter={8}>
						<Col flex={1}>
							<Form.Item name="columns">
								<Select
									allowClear
									maxTagCount="responsive"
									mode="multiple"
									options={registryColumns.map(r => ({
										key: r.number,
										value: r.number,
										label: r.columnNameRu
									}))}
								/>
							</Form.Item>
						</Col>
						<Col>
							<Button
								onClick={() =>
									issueReportForm.setFieldValue(
										'columns',
										registryColumns.map(r => r.number)
									)
								}
							>
								Выбрать все
							</Button>
						</Col>
					</Row>
				</Card>
				<Card className="at-workcard" title="Фильтры">
					<Descriptions
						layout="horizontal"
						column={12}
						bordered
						className="at-descriptions"
						labelStyle={{
							fontWeight: 'bold',
							width: '20%',
							alignItems: 'center',
							padding: '2px 8px'
						}}
						contentStyle={{
							width: '80%',
							padding: '0px 0px'
						}}
					>
						{filterItems.map((item, index) => (
							<Descriptions.Item label={item.title} span={12}>
								<Form.Item key={index} name={item.formItemName}>
									{!item.date ? (
										<Select
											mode={item.formItemName === 'status' ? 'multiple' : undefined}
											showSearch
											allowClear
											optionFilterProp="children"
											disabled={item.disabled || item.list!?.length === 0}
											filterSort={(optionA, optionB) =>
												(optionA?.label ?? '')
													.toString()
													.toLowerCase()
													.localeCompare((optionB?.label ?? '').toString().toLowerCase())
											}
											style={{ width: '100%' }}
											placeholder="Не выбран"
											onChange={value => onChangeFilter(item.formItemName, value)}
											options={item.list!?.map(option => ({
												key: option.id,
												value:
													item.formItemName === 'hasOrdinance' ||
													item.formItemName === 'hasChecklist'
														? option.id === '1'
															? true
															: false
														: option.id,
												label:
													item.formItemName === 'issueTypeId'
														? checkTypeName(option.name)
														: item.formItemName === 'phase'
														? option.phase
														: item.formItemName === 'building'
														? option.building
														: option.name
											}))}
										/>
									) : (
										<DatePicker
											locale={locale}
											style={{ width: '100%' }}
											format={'DD.MM.YYYY'}
											onChange={(date, dateSting) => onChangeFilter(item.formItemName, date!)}
										/>
									)}
								</Form.Item>
							</Descriptions.Item>
						))}
					</Descriptions>
				</Card>
			</Modal>
		</Form>
	)
}
