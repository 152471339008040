import { useQueryClient } from '@tanstack/react-query'
import { Tabs } from 'antd'
import { useAppSelector } from 'hooks/appReduxHook'
import { Tab } from 'rc-tabs/lib/interface'
import { FC, useEffect, useLayoutEffect, useState } from 'react'
import { PERMISSIONS } from 'shared/constants'
import { useCheckPermissions } from 'shared/useCheckPermissions'
import Checklists from './checklists'
import { PlannedDates } from './plannedDates'
import Templates from './templates'

type ITabs = 'checklistTab' | 'templateTab' | 'plannedDateTab'

const ChecklistsPage: FC = () => {
	const checkPermissions = useCheckPermissions()
	const queryClient = useQueryClient()
	const [activeKey, setActiveKey] = useState<ITabs>('checklistTab')
	const { project } = useAppSelector(state => state.environment)

	// const checkPermissions = (currentPermits: string[]) => {
	// 	for (let i = 0; i < currentPermits.length; i++) {
	// 		if (permissions?.some((el: IPermission) => el.name === currentPermits[i])) {
	// 			return true
	// 		}
	// 	}
	// 	return false
	// }

	const checklistsAccessPermissions = checkPermissions(
		[PERMISSIONS.ChecklistsUksNdc, PERMISSIONS.ChecklistsUkKc],
		project?.id
	)
	const templatesAccessPermissions = checkPermissions(
		[PERMISSIONS.ChecklistsTemplatesUksNdc, PERMISSIONS.ChecklistsTemplatesUkKc],
		project?.id
	)
	const plannedDatesAccessPermissions = checkPermissions(
		[PERMISSIONS.ChecklistsTemplatePlannedSystemDate],
		project?.id
	)

	const checkViewPermissions = (type: 'checklist' | 'template' | 'plannedDate') => {
		const UKS_NDC =
			type === 'checklist'
				? [PERMISSIONS.ChecklistsUksNdc]
				: [PERMISSIONS.ChecklistsTemplatesUksNdc]
		const UK_KC =
			type === 'checklist'
				? [PERMISSIONS.ChecklistsUkKc]
				: type === 'template'
				? [PERMISSIONS.ChecklistsTemplatesUkKc]
				: [PERMISSIONS.ChecklistsTemplatePlannedSystemDate]
		if (checkPermissions(UKS_NDC, project?.id) && checkPermissions(UK_KC, project?.id)) {
			return 2
		} else if (!checkPermissions(UKS_NDC, project?.id) && !checkPermissions(UK_KC, project?.id)) {
			return null
		} else if (checkPermissions(UKS_NDC, project?.id) && !checkPermissions(UK_KC, project?.id)) {
			return 0
		} else return 1
	}

	useLayoutEffect(() => {
		if (!checklistsAccessPermissions && templatesAccessPermissions) {
			setActiveKey('templateTab')
		} else if (!checklistsAccessPermissions && !templatesAccessPermissions) {
			setActiveKey('plannedDateTab')
		}
	}, [])

	const checkListTabs: Tab[] = [
		{
			key: 'checklistTab',
			label: 'Чек-листы',
			children: (
				<Checklists
					// checkPermissions={checkPermissions}
					checkViewPermissions={checkViewPermissions}
				/>
			),
			disabled: !checklistsAccessPermissions
		},
		{
			key: 'templateTab',
			label: 'Шаблоны',
			children: (
				<Templates
					// checkPermissions={checkPermissions}
					checkViewPermissions={checkViewPermissions}
				/>
			),
			disabled: !templatesAccessPermissions
		},
		{
			key: 'plannedDateTab',
			label: 'Плановая дата передачи системы',
			children: <PlannedDates />,
			disabled: !plannedDatesAccessPermissions
		}
	]

	useEffect(() => {
		if (activeKey === 'plannedDateTab')
			queryClient.invalidateQueries({ queryKey: ['templatesPlanedDate'] })
	}, [activeKey])

	return (
		<div className="dashboard__block">
			<Tabs
				style={{ width: '100%' }}
				defaultActiveKey="checklists"
				activeKey={activeKey}
				onChange={value => setActiveKey(value as ITabs)}
				size="large"
				type="line"
				tabBarGutter={16}
				items={checkListTabs}
			/>
		</div>
	)
}
export default ChecklistsPage
