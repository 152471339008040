import { useMutation } from '@tanstack/react-query'
import { notification } from 'antd'
import { AxiosError } from 'axios'
import { IErrorDetail } from 'interfaces/IBase'
import { confirmPolicy, getUserInfoForMainPage } from 'services/AuthService'
import { useViewerState } from 'viewer-state-new'

export const useViewerPolicyMutation = () => {
	const { setViewer } = useViewerState()
	return useMutation({
		mutationKey: ['viewer', 'logout'],
		mutationFn: async () => {
			return confirmPolicy()
		},
		onSuccess: async () => {
			await getUserInfoForMainPage().then(data => {
				setViewer({ ...data, policyConfirm: true })
			})
		},
		onError: (error: AxiosError<IErrorDetail>) => {
			notification.error({
				message: `Произошла ошибка. Код ошибки: ${error.response?.status}`,
				description: error.response?.data?.detail,
				placement: 'topLeft'
			})
		}
	})
}
