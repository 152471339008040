import { Input } from 'antd'
import { memo, useMemo } from 'react'
import { IEstimatePosition, IEstimatePositionMaterial, useEstimateState } from '..'
import { useShallow } from 'zustand/react/shallow'

interface IProps {
	row: IEstimatePosition | IEstimatePositionMaterial
	type: 'noteSDU' | 'noteDZ' | 'noteDP'
}
export const EstimateNoteCell = memo(function (params: IProps) {
	const { type, row } = params
	const { setNote } = useEstimateState(
		useShallow(state => ({
			setNote: state.setNote
		}))
	)
	const isArea = useMemo(() => type in row && row[type] && (row[type]! as string).length > 0, [])

	const attrQa = useMemo(
		() => `${row.id}_input${type}${'workName' in row ? 'Work' : 'Material'}`,
		[]
	)

	return isArea ? (
		<Input.TextArea
			onFocus={e => e.currentTarget.blur()}
			data-attr={attrQa}
			autoSize={false}
			style={{
				borderRadius: 0,
				maxHeight: 32,
				height: '100%',
				background: 'transparent',
				color: '#444',
				cursor: 'pointer',
				resize: 'none'
			}}
			value={row[type] ?? ''}
			onClick={() => setNote(row, type)}
		/>
	) : (
		<p
			data-attr={attrQa}
			style={{
				textOverflow: 'ellipsis',
				overflow: 'hidden',
				margin: 0,
				cursor: 'pointer'
			}}
			onClick={() => setNote(row, type)}
		>
			{'-'}
		</p>
	)
})
