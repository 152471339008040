import { create } from "zustand"
import { TReportForm } from "./reports-schema"

interface IReportState {
    filters?: TReportForm
    currentPage: number
    totalItems?: number
    download: boolean
    setFilters: ({projectIds, contractorId, ownerId, from, to}: TReportForm) => void
    onPagination: (currentPage: number) => void
	setTotalItems: (totalItems: number) => void
}

export const useReportState = create<IReportState>((set, get) => ({
    filters: undefined,
    currentPage: 1,
    totalItems: undefined,
    download: false,
    setFilters: (filters) =>  set(() => ({filters})),
    onPagination: currentPage => set(() => ({ currentPage })),
	setTotalItems: totalItems => set(() => ({ totalItems }))
}))