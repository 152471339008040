import { API } from "services/ApiService";
import { ICheckListCount, IContainerCount, IContractor, IContractorIdFilter, IContractorRating, IDateRangeFilter, IIssueCount, IIssueSubTypes, IOrdinanceCount, IOrdinanceFineCount, IOrdinanceFinePerMonthResponse, IProject, IProject1C, IProjectFilter } from "../model/diagrams-types";

export const diagramApi = {
  async getProjects1C(projectIds?: string[], contractorIds?: string[]) {
    return await API<IProject1C[]>({
      url: `/v1/diagrams/projects1C`,
      method: 'post',
      data: {projectIds: projectIds, contractorIds: contractorIds}
    }).then(response => response.data)
  },

  async getProjects(project1CIds?: string[], contractorIds?: string[]) {
    return await API<IProject[]>({
      url: `/v1/diagrams/projects`,
      method: 'post',
      data: {project1CIds: project1CIds, contractorIds: contractorIds}
    }).then((response) => response.data)
  },

  async getContractors(projectIds?: string[], project1CIds?: string[]) {
    return await API<IContractor[]>({
      url: '/v1/diagrams/contractors',
      method: 'post',
      data: {projectIds: projectIds, project1CIds: project1CIds}
    }).then(response => response.data)
  },

  async getCountContractors(params?: IDateRangeFilter & IProjectFilter & IContractorIdFilter) {
    return await API({
      url: '/v1/diagrams/countContractors',
      method: 'post',
      data: params
    }).then(response => response.data)
  },

  async getContractorsRating(params: IDateRangeFilter) {
    return await API<IContractorRating[]>({
      url: "/v1/diagrams/contractorsRating",
      method: "post",
      data: params
    }).then((response) => response.data)
  },

  async getCountContainers(params: IDateRangeFilter & IProjectFilter & IContractorIdFilter) {
    return await API<IContainerCount>({
      url: "/v1/diagrams/countContainers",
      method: "post",
      data: params
    }).then((response) => response.data)
  },

  async getCountIssues(params: IDateRangeFilter & IProjectFilter & IContractorIdFilter) {
    return await API<IIssueCount>({
      url: "/v1/diagrams/countIssues",
      method: "post",
      data: params
    }).then((response) => response.data)
  },

  async getCountOrdinances(params: IDateRangeFilter & IProjectFilter & IContractorIdFilter) {
    return await API<IOrdinanceCount>({
      url: "/v1/diagrams/countOrdinances",
      method: "post",
      data: params
    }).then((response) => response.data)
  },

  async getCountOrdinanceFines(params: IDateRangeFilter & IProjectFilter & IContractorIdFilter) {
    return await API<IOrdinanceFineCount>({
      url: "/v1/diagrams/countOrdinanceFines",
      method: "post",
      data: params
    }).then((response) => response.data)
  },

  async getCountCheckLists(params: IDateRangeFilter & IProjectFilter & IContractorIdFilter) {
    return await API<ICheckListCount>({
      url: "/v1/diagrams/countCheckLists",
      method: "post",
      data: params
    }).then((response) => response.data);
  },

  async getIssueSubTypes(params: IDateRangeFilter & IProjectFilter & IContractorIdFilter) {
    return await API<IIssueSubTypes[]>({
      url: "/v1/diagrams/issueSubTypes",
      method: "post",
      data: params
    }).then((response) => response.data);
  },

  async getContractorRatingToExcel(params: IDateRangeFilter) {
    return await API({
      url: '/v1/export/exportContractorRatingToExcel',
      method: 'get',
      responseType: 'blob',
      params
    }).then(response => response.data)
  },
  async getOrdinanceFinesPerMonth(params: IDateRangeFilter & IProjectFilter & IContractorIdFilter) {
    return await API<IOrdinanceFinePerMonthResponse>({
      url: "/v1/diagrams/countOrdinanceFinesPerMonth",
      method: "post",
      data: params
    }).then((response) => response.data)
  }
};
