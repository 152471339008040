import { Table } from 'antd'
import { ColumnsType } from 'antd/es/table'
import { NavLink } from 'react-router-dom'
import { MonitoringContainersMissingVisas } from '../api/monitoring-schema'
import { useContainersMissingVisas } from '../api/monitoring-queries'
import { PAGE_LIMIT } from 'shared/constants'
import { useMonitoringState } from '../model/monitoring-state'

export const ContainersMissingVisas = () => {
	const { data, isFetching } = useContainersMissingVisas()
	const { totalItems, currentPage, onPagination } = useMonitoringState()
	const columns: ColumnsType<MonitoringContainersMissingVisas> = [
		{
			dataIndex: 'containerId',
			title: 'Id контейнера',
			render: (_, record) => (
				<NavLink to={`/unmodel/${record.containerId}`} target="_blank">
					{record.containerId}
				</NavLink>
			)
		},
		{
			dataIndex: 'statusRu',
			title: 'Статус'
		},
		{
			dataIndex: 'missingVisa',
			title: 'Виза'
		},
		{
			dataIndex: 'typeRu',
			title: 'Тип документа'
		}
	]

	return (
		<div className="dashboard__block" style={{ borderRadius: '0 0 .8rem .8rem' }}>
			<Table
				loading={isFetching}
				className="app-table-types"
				rowKey={record => record.containerId}
				size="small"
				pagination={{
					hideOnSinglePage: true,
					total: totalItems,
					pageSize: PAGE_LIMIT,
					showSizeChanger: false,
					onChange: onPagination,
					current: currentPage
				}}
				bordered
				dataSource={data}
				columns={columns as any}
			/>
		</div>
	)
}
