import { useQuery } from '@tanstack/react-query'
import { issueApi } from '../api/issue-api'

export const useBuildingPermits = (currentProjectId: string) => {
	return useQuery({
		queryKey: ['issue', 'buildingPermits', currentProjectId],
		refetchOnWindowFocus: false,
		queryFn: async () => {
			return await issueApi.getBuildingPermit()
		},
		select: data => data.filter(d => !d.isDeleted)
	})
}

export const useIssueWorkPackages = () => {
	return useQuery({
		refetchOnWindowFocus: false,
		queryKey: ['issue', 'workPackages'],
		queryFn: async () => {
			return await issueApi.getWorkPackages()
		},
		select: data => data.map(d => ({ ...d, name: `${d.code} ${d.name}` }))
	})
}

export const useIssueTypes = () => {
	return useQuery({
		queryKey: ['issue', 'types'],
		refetchOnWindowFocus: false,
		queryFn: async () => {
			return await issueApi.getTypes()
		},
		select: data => data.filter(d => !d.isDeleted)
	})
}

export const useIssueSubTypes = (issueType?: string) => {
	return useQuery({
		queryKey: ['issue', 'subTypes', issueType],
		refetchOnWindowFocus: false,
		queryFn: async () => {
			return issueType ? await issueApi.getSubTypes(issueType) : null
		},
		select: data => data?.filter(d => !d.isDeleted)
	})
}

export const useIssueViolationTypes = (issueSubType?: string) => {
	return useQuery({
		queryKey: ['issue', 'violationTypes', issueSubType],
		refetchOnWindowFocus: false,
		queryFn: async () => {
			return issueSubType ? await issueApi.getViolationTypes(issueSubType) : null
		},
		select: data => data ?? [] //?.filter(d => !d.isDeleted)
	})
}

export const useIssueActPoints = ({
	violationTypeId,
	workPackageId,
	violationId
}: {
	violationTypeId?: string
	workPackageId?: string
	violationId?: string
}) => {
	return useQuery({
		queryKey: ['issue', 'actPoints', violationTypeId, workPackageId, violationId],
		refetchOnWindowFocus: false,
		queryFn: async () => {
			return workPackageId
				? await issueApi.getActPoints({ violationTypeId, workPackageId, violationId })
				: []
		}
	})
}

export const useIssueViolations = ({
	violationTypeId,
	workPackageId,
	actPointId
}: {
	violationTypeId?: string
	workPackageId?: string
	actPointId?: string
}) => {
	return useQuery({
		queryKey: ['issue', 'violations', violationTypeId, workPackageId, actPointId],
		refetchOnWindowFocus: false,
		queryFn: async ({ signal }) => {
			return workPackageId
				? await issueApi.getViolations({ signal, violationTypeId, workPackageId, actPointId })
				: []
		}
	})
}

export const useIssueSystems = () => {
	return useQuery({
		queryKey: ['issue', 'systems'],
		refetchOnWindowFocus: false,
		queryFn: async () => {
			return await issueApi.getSystems()
		},
		retry: 1
	})
}

export const useIssueContractors = () => {
	return useQuery({
		queryKey: ['issue', 'contractors'],
		refetchOnWindowFocus: false,
		queryFn: async () => {
			return await issueApi.getContractors()
		}
	})
}

export const useIssueContracts = (contractorId?: string, projectId?: string) => {
	return useQuery({
		queryKey: ['issue', 'contracts', contractorId],
		refetchOnWindowFocus: false,
		queryFn: async () => {
			return contractorId && projectId ? await issueApi.getContracts(contractorId, projectId) : []
		}
		// select: data => data.filter(d => d.mainContract === null)
	})
}

export const useIssueContactPersons = (contractorId?: string) => {
	return useQuery({
		queryKey: ['issue', 'contactPersons', contractorId],
		refetchOnWindowFocus: false,
		queryFn: async () => {
			return contractorId ? await issueApi.getContactPersons(contractorId) : []
		}
	})
}

export const usePotentialAuthors = (projectId: string) => {
	return useQuery({
		queryKey: ['issue', 'potentialAuthors', projectId],
		queryFn: async () => {
			return projectId ? issueApi.getPotentialAuthors(projectId) : []
		}
	})
}
