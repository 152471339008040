import { Button, Col, Empty, Row, Table } from 'antd'
import { useContractorRatingToExcel, useRatingDashboard } from '../model/diagrams-query'
import { DownloadOutlined } from '@ant-design/icons'
import { downloadDocument } from 'shared/helpers'
import { useCheckCommonPermissions, useCheckPermissions } from 'shared/useCheckPermissions'
import { PERMISSIONS } from 'shared/constants'
import dayjs from 'dayjs'

const ratingColumns = [
	{
		key: 'contractorName',
		title: 'Контрагент',
		width: 240,
		dataIndex: 'contractorName'
	},
	{
		key: 'projectName',
		title: 'Объект строительства',
		width: 127,
		dataIndex: 'projectName'
	},
	{
		key: 'countIssues',
		title: 'Замечания',
		width: 90,
		dataIndex: 'countIssues'
	},
	{
		key: 'countOrdinances',
		title: 'Предписания',
		width: 106,
		dataIndex: 'countOrdinances'
	},
	{
		key: 'countOrdinanceFines',
		title: 'Штрафы',
		width: 73,
		dataIndex: 'countOrdinanceFines'
	},
	{
		key: 'countProjects',
		title: 'Количество объектов строительства',
		width: 94,
		dataIndex: 'countProjects'
	}
]

export const RatingDashboard = () => {
	const { data: ratingData } = useRatingDashboard()
	const { mutate: exportContractorRatingToExcel } = useContractorRatingToExcel()
	const checkPermission = useCheckCommonPermissions()

	const handleExport = () => {
		exportContractorRatingToExcel(undefined, {
			onSuccess: data => {
				downloadDocument(data, `Рейтинг контрагентов ${dayjs().format('DD.MM.YYYY')}.xlsx`)
			}
		})
	}

	return (
		<Row
			// gutter={[16, 16]}
			style={{
				border: '1px solid #ddd',
				borderRadius: 8,
				padding: 8,
				background: 'white',
				height: '570px'
			}}
		>
			<Col
				span={24}
				style={{
					padding: '0.1rem 1rem 1rem',
					background: !ratingData! ? '#ffffff' : '#fefefe'
				}}
			>
				{ratingData! ? (
					<>
						<Table
							rowKey={(record, index) => index!}
							pagination={false}
							className="main-rating-table"
							rowClassName="main-rating-table-row"
							columns={ratingColumns}
							dataSource={ratingData.slice(0, 10) ?? []}
							size="small"
							bordered
							scroll={{
								x: 550
							}}
						/>
						{checkPermission([PERMISSIONS.AllCompanies]) && (
							<Col span={24}>
								<Button
									type="primary"
									icon={<DownloadOutlined />}
									onClick={handleExport}
									style={{
										marginTop: 16,
										marginLeft: 0,
										borderLeft: 0,
										backgroundColor: '#02152b'
									}}
								>
									Скачать рейтинг
								</Button>
							</Col>
						)}
					</>
				) : (
					<span
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							height: '100%'
						}}
					>
						<Empty />
					</span>
				)}
			</Col>
		</Row>
	)
}
