import { Form, Input, Select, Tag, TreeSelect } from 'antd'
import { IBase, IBaseConstructionObject, IBasePotentialRedactors } from 'interfaces/IBase'
import { IAppUser, IUserPermission } from 'interfaces/IUser'
import { FC, useEffect } from 'react'
import { CONTRACTORS, PERMISSIONS } from 'shared/constants'
import { useCheckPermissions } from 'shared/useCheckPermissions'
import { ILocalEstimate, useLocalEstimateRegistryState } from '..'
import { markDisabledNodes } from 'widgets/wbs/model/wbs-helper'
import { ConstructionObjectSelect } from 'widgets/wbs/ui/ConstructionObjectSelect'
import { useWatch } from 'antd/es/form/Form'
import { useAdjustmentPotentialRedactors } from 'widgets/adjustment/model/adjustment-query'
import { useLocalEstimatePotentialRedactors } from 'pages/unmodelPage/localEstimate/model/localEstimateQuery'

interface IProps extends React.HTMLAttributes<HTMLElement> {
	editing: boolean
	dataIndex: string
	title: any
	record: ILocalEstimate
	index: number
	potentialRedactors: IBasePotentialRedactors[]
	constructionObjects: IBaseConstructionObject[]
	user: IAppUser | null
	children: React.ReactNode
	permissions: IUserPermission[]
	currentUser: IAppUser | null
}

export const LocalEstimateRegistryCell: FC<IProps> = ({
	editing,
	dataIndex,
	title,
	record,
	children,
	// potentialRedactors,
	constructionObjects,
	user,
	index,
	permissions,
	currentUser,
	...restProps
}) => {
	const checkPermissions = useCheckPermissions()
	const { data: adjustmentPotentialRedactors } = useAdjustmentPotentialRedactors(
		record?.project?.id!,
		record?.adjustmentId ? 'local' : undefined
	)
	const { data: potentialRedactorsLocal } = useLocalEstimatePotentialRedactors(
		record?.project?.id!,
		!record?.adjustmentId ? 'local' : undefined
	)
	const potentialRedactors = !!record?.adjustmentId
		? adjustmentPotentialRedactors
		: potentialRedactorsLocal
	const canEdit = (type: 'redactors' | 'construction') => {
		if (user && record.author) {
			if (user.isAdmin) return true
			if (
				type === 'construction'
					? record.author.id === user.id &&
					  ((checkPermissions([PERMISSIONS.AllCompanies], record?.project?.id!) ||
							user.contractorId === CONTRACTORS.NDC) ??
							false)
					: record.author.id === user.id
			)
				return true
		}
		return false
	}

	const setNode = () => {
		switch (dataIndex) {
			case 'name':
				return (
					<Form.Item
						style={{ margin: 0 }}
						name={dataIndex}
						rules={[
							{
								required: true,
								message: 'Укажите название контейнера'
							},
							{
								min: 3,
								message: 'Не менее 3х символов'
							}
						]}
					>
						<Input />
					</Form.Item>
				)
			case 'redactors':
				return (
					<Form.Item name={dataIndex} style={{ margin: 0 }}>
						<Select
							maxTagCount={1}
							showSearch={true}
							optionFilterProp="children"
							filterOption={(input, option) =>
								(option?.label ?? '').toLowerCase().includes(input.toLowerCase())
							}
							filterSort={(optionA, optionB) =>
								(optionA?.label ?? '')
									.toLowerCase()
									.localeCompare((optionB?.label ?? '').toLowerCase())
							}
							disabled={!canEdit('redactors')}
							mode="multiple"
							style={{ width: '100%' }}
							allowClear
							value={potentialRedactors?.sort(
								(a, b) =>
									+b.isDeleted - +a.isDeleted ||
									+(b.id === currentUser?.id) - +(a.id === currentUser?.id) ||
									a?.name?.localeCompare(b?.name)
							)}
							tagRender={props => (
								<Tag closable={false} bordered={false}>
									{potentialRedactors?.find(p => p.name === props.label && p.isDeleted)
										? //  ||
										  // record.redactors
										  // 	.flatMap(elem => elem.id)
										  // 	.filter(item => !potentialRedactors?.flatMap(({ id }) => id).includes(item))
										  // 	.find(p => p === props.value)
										  'Пользователь был удален'
										: props.label}
								</Tag>
							)}
							options={potentialRedactors
								?.filter(
									pr =>
										(!pr.isDeleted && !record.redactors.find(r => r.name === pr.name)) ||
										record.redactors.find(r => r.name === pr.name)
								)
								.map(r => ({
									key: r.id,
									value: r.id,
									label: r.isDeleted ? 'Пользователь был удален' : r.name,
									disabled: r.isDeleted
								}))}
						/>
					</Form.Item>
				)
			case 'constructionObjectId':
				return (
					<ConstructionObjectSelect
						editing={editing}
						record={record}
						constructionObjects={constructionObjects}
						fieldName={dataIndex}
					/>
				)
		}
	}

	const editableNode = setNode()

	return (
		<td {...restProps}>
			<span className={'app-registry-col-' + dataIndex}>{editing ? editableNode : children}</span>
		</td>
	)
}
