import { IContainer } from 'interfaces/IContainer'
import { adjustmentApi } from 'widgets/adjustment'
import { create } from 'zustand'
import { IWbsCopyForm, IWbsRegistryFilters, WbsRegistryApi } from '..'

interface IWbsRegistryState {
	adjustmentRecord?: IContainer
	correctionRecord?: IContainer
	containerToCopy?: IContainer
	containerToRemove?: IContainer
	currentPage: number
	editableRow?: IContainer
	filters: IWbsRegistryFilters
	showFilters: boolean
	totalItems?: number
	sortState?: number
	recordSave?: IContainer | null
	onCopy: (containerId: string, formData: IWbsCopyForm) => Promise<number>
	onAdjustmentCreate: (containerId: string, name: string, projectId: string) => Promise<number>
	onCorrectionCreate: (containerId: string, name: string, isAdjustment: boolean) => Promise<number>
	onPagination: (currentPage: number) => void
	onRemove: (containerId: string, adjustment: boolean) => Promise<number>
	resetFilters: () => void
	setAdjustmentRecord: (adjustmentRecord: IContainer | undefined) => void
	setCorrectionRecord: (correctionRecord: IContainer | undefined) => void
	setContainerToCopy: (containerToCopy: IContainer | undefined) => void
	setContainerToRemove: (containerToRemove: IContainer | undefined) => void
	setEditableRow: (row: IContainer | undefined) => void
	setFilters: (filters: IWbsRegistryFilters) => void
	setSearch: (search: string) => void
	setShowFilters: (state: boolean) => void
	setTotalItems: (totalItems: number) => void
	setSortState: (sortState?: number) => void
}

const initial = {
	currentPage: 1,
	editableRowId: null,
	showFilters: false,
	sortState: undefined,
	recordSave: null,
	filters: {
		constructionObjectId: undefined,
		containerStatusId: undefined,
		createdDate: undefined,
		ready: undefined,
		search: undefined,
		typeSectionId: undefined,
		authorId: undefined,
		projectId: undefined
	}
}

export const useWbsRegistryState = create<IWbsRegistryState>((set, get) => ({
	...initial,
	onAdjustmentCreate: async (containerId, name, projectId) =>
		await adjustmentApi.create(containerId, name, 'wbs', projectId),
	onCorrectionCreate: async (containerId, name, isAdjustment) =>
		isAdjustment
			? await adjustmentApi.createCorrection(containerId, name, 'wbs')
			: await WbsRegistryApi.createCorrection(containerId, name),
	onCopy: async (containerId, formData) =>
		await WbsRegistryApi.copyContainer(containerId, formData),
	onPagination: currentPage => set(() => ({ currentPage })),
	onRemove: async (containerId, adjustment) => await WbsRegistryApi.removeContainer(containerId, adjustment),
	setAdjustmentRecord: adjustmentRecord => set(() => ({ adjustmentRecord })),
	setCorrectionRecord: correctionRecord => set(() => ({ correctionRecord })),
	setContainerToCopy: containerToCopy => set(() => ({ containerToCopy })),
	setContainerToRemove: containerToRemove => set(() => ({ containerToRemove })),
	setEditableRow: row => set(() => ({ editableRow: row })),
	setFilters: filters => {
		set(() => ({ filters: { ...filters, search: get().filters.search }, currentPage: 1 }))
	},
	setSortState: sortState => {
		set(() => ({ sortState }))
	},
	setSearch: search =>
		set(() => ({
			filters: { ...get().filters, search: search.length ? search : undefined },
			currentPage: 1
		})),
	setShowFilters: state => set(() => ({ showFilters: state })),
	setTotalItems: totalItems => set(() => ({ totalItems })),
	resetFilters: () =>
		set(() => ({
			filters: {
				authorId: undefined,
				constructionObjectId: undefined,
				containerStatusId: undefined,
				createdDate: undefined,
				ready: undefined,
				typeSectionId: undefined,
				projectId: undefined
			}
		}))
}))
