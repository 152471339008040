import { useQuery } from '@tanstack/react-query'
import { IIssue } from 'interfaces/IIssue'
import { getIssuesList } from 'services/IssuesService'
import { PERMISSIONS } from 'shared/constants'
import { useCheckPermissions } from 'shared/useCheckPermissions'
import { setTotalItems } from 'store/issueSlice'
import { useAppDispatch, useAppSelector } from './appReduxHook'
import { useViewerState } from 'viewer-state-new'

export const useIssues = () => {
	const projectId = useAppSelector(state => state.environment.project?.id)
	const checkPermissions = useCheckPermissions()
	// const { user } = useAppSelector(state => state.environment)
	const { viewer: user } = useViewerState()
	const allCompanies = checkPermissions([PERMISSIONS.AllCompanies], projectId)
	const filters = useAppSelector(state => state.issues.filters)
	const currentPage = useAppSelector(state => state.issues.listState.currentPage)
	const sort = useAppSelector(state => state.issues.sort)
	const dispatch = useAppDispatch()
	return useQuery<IIssue[]>({
		queryKey: ['issuesList', projectId, filters, currentPage, sort],
		queryFn: async () => {
			const issueList = await getIssuesList({
				sort,
				filters: {
					...filters,
					contractorIds: allCompanies ? filters.contractorIds : [user?.contractorId!],
					fixedOut:
						typeof filters.fixedOut !== 'undefined' ? Boolean(Number(filters.fixedOut)) : undefined
				},
				offset: currentPage! > 1 ? currentPage! * 50 - 50 : 0
			}).then(response => response)
			dispatch(setTotalItems(issueList.totalItems))
			return issueList.data
		},
		refetchOnWindowFocus: false,
		enabled: typeof projectId !== 'undefined'
	})
}
