import { BookOutlined, ProjectOutlined, QuestionCircleFilled } from '@ant-design/icons'
import { UserMenu } from 'UI/widgets/userMenu'
import { Button, Col, Flex, Row, Tooltip } from 'antd'
import { ReactComponent as ATLogo } from 'assets/at_logo_white.svg'
import { CopyToClipboard } from 'components/CopyToClipboard'
import { useAppSelector } from 'hooks/appReduxHook'
import { FC, useState } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import { useRegulatoryRequestState } from 'widgets/regulatory-request'
import WbsNotificationDrawer from 'widgets/wbs/ui/WbsNotificationDrawer'
import { YandexTrackerButton } from 'widgets/yandex-tracker/ui/yandex-tracker-button'
import { useShallow } from 'zustand/react/shallow'
import ATMenu from './ATMenu'
import EnvironmentConfig from './EnvironmentConfig'

const DashboardHeader: FC = () => {
	const { pathname } = useLocation()
	const environment = useAppSelector(state => state.environment)
	const [showEnvConfig, setShowEnvConfig] = useState(false)
	const RegulatoryRequestToggleShow = useRegulatoryRequestState(
		useShallow(state => state.toggleShow)
	)

	return (
		<>
			<EnvironmentConfig open={showEnvConfig} onClose={() => setShowEnvConfig(false)} />
			<Row className="dashboard__header" justify="space-between" align="middle">
				<Col flex={1}>
					<Row gutter={16} align="middle">
						<Col className="dashboard__header-logo">
							<NavLink to="/">
								<ATLogo />
							</NavLink>
						</Col>

						<Col>
							<Tooltip>
								<div>
									<ATMenu />
								</div>
							</Tooltip>
						</Col>
						{(pathname.includes('/checklist') || pathname.includes('/control')) && (
							<Col>
								<Flex gap={4} align="center">
									<Button
										style={{ color: '#fff' }}
										icon={<ProjectOutlined />}
										type="text"
										onClick={() => setShowEnvConfig(true)}
									>
										{environment.project ? environment.project.name : 'Выберите проект'}
									</Button>
									<CopyToClipboard
										ghost
										text={environment?.project?.name!}
										tooltip="Скопировать название проекта"
									/>
								</Flex>
							</Col>
						)}
					</Row>
				</Col>
				<Col>
					<Row gutter={16}>
						{(pathname.includes('/wbs-tasks') ||
							pathname.includes('/unmodel') ||
							pathname.includes('/local')) && (
							<Col>
								<WbsNotificationDrawer />
							</Col>
						)}
						{(pathname.slice(-7, pathname.length) === 'unmodel' ||
							pathname.slice(-5, pathname.length) === 'local') && (
							<Col>
								{/* <AsmRequestNci /> */}
								<Button type="primary" onClick={() => RegulatoryRequestToggleShow(true)}>
									Запрос в НСИ
								</Button>
							</Col>
						)}
						<Col>
							<NavLink to="/releases">
								<Tooltip title="История обновлений">
									<Button className="at-btn-menu" icon={<BookOutlined />} />
								</Tooltip>
							</NavLink>
						</Col>
						<Col>
							<YandexTrackerButton
								icon={<Button icon={<QuestionCircleFilled />} type="primary" />}
							/>
						</Col>
						<Col>
							<UserMenu />
						</Col>
					</Row>
				</Col>
			</Row>
		</>
	)
}

export default DashboardHeader
