// import { API } from 'services/ApiService'

import { ITypeSection } from 'interfaces/IContainer'
import { API } from 'services/ApiService'
import { IRegulatoryRequestForm } from '..'

export const RegulatoryRequestApi = {
	async send(dto: IRegulatoryRequestForm) {
		return await API({
			url: '/v1/containers/createRequestNCI',
			method: 'post',
			params: {
				projectId: null
			},
			data: dto
		}).then(async response => response.data)
	},
	async getTypeSection() {
		return await API<ITypeSection[]>({
			url: `/v1/typeSection`,
			method: 'get',
			params: {
				projectId: null
			}
		}).then(response => response.data)
	}
}
