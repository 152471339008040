import { FC, useState } from 'react'
import { Calendar, Card, Col, Modal, Row, Select, Tag, Tooltip } from 'antd'
import { LeftOutlined, MoreOutlined, RightOutlined } from '@ant-design/icons'
import type { Dayjs } from 'dayjs'
import dayjs from 'dayjs'
import 'dayjs/locale/ru'
import { useNavigate } from 'react-router-dom'

import { useCheckListsPlannedDate } from '../model/diagrams-project-query'
import { useDiagramsProjectState } from '../model/diagrams-project-state'
import { MONTHS, PERMISSIONS } from 'shared/constants'
import { TEMPLATE_TYPE } from 'shared/constants/checklist-types'
import { ICheckListItem } from '../model/diagrams-project-types'
import { useCheckPermissions } from 'shared/useCheckPermissions'

export const DiagramsProjectCalendar: FC = () => {
	const navigate = useNavigate()
	const checkPermissions = useCheckPermissions()
	const [open, setOpen] = useState<string | null>(null)
	const { plannedDate, projectId } = useDiagramsProjectState()
	const { data: checkListsData } = useCheckListsPlannedDate()

	const onChecklistClick = (item: ICheckListItem) => {
		if (checkPermissions([PERMISSIONS.ChecklistsUkKc, PERMISSIONS.ChecklistsUksNdc], projectId!)) {
			let pathPart = ''
			if (typeof item.templateType === 'number') {
				pathPart = Object.values(TEMPLATE_TYPE)[item.templateType] as string
			}
			navigate(`/checklist/${pathPart}/${item.id}`)
		} else {
			Modal.error({
				title: 'Ошибка доступа',
				content:
					'Внимание! Раздел не доступен, поскольку у Вас не хватает прав. Обратитесь к администратору.'
			})
		}
	}

	const renderCalendarHeader = ({
		value,
		onChange
	}: {
		value: Dayjs
		onChange: (val: Dayjs) => void
	}) => {
		const monthName = MONTHS[(value?.month() + 1) as keyof typeof MONTHS]
		const year = value?.year()
		const yearMonthText = `${monthName} ${year}`

		const handlePrevMonth = () => {
			onChange(value?.clone().subtract(1, 'month'))
		}
		const handleNextMonth = () => {
			onChange(value?.clone().add(1, 'month'))
		}

		return (
			<div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 10 }}>
				<LeftOutlined style={{ fontSize: 16, cursor: 'pointer' }} onClick={handlePrevMonth} />
				<div style={{ fontSize: 16, fontWeight: 500 }}>{yearMonthText}</div>
				<RightOutlined style={{ fontSize: 16, cursor: 'pointer' }} onClick={handleNextMonth} />
			</div>
		)
	}

	return (
		<Card
			style={{
				border: '1px solid #ddd',
				borderRadius: 8,
				padding: 2,
				background: 'white',
				height: '100%'
			}}
		>
			<Row gutter={16} style={{ height: '300px' }}>
				<Col span={9}>
					<Calendar
						style={{ flex: 1, paddingTop: 5 }}
						className="main-calendar"
						fullscreen={false}
						value={plannedDate!}
						onSelect={day => useDiagramsProjectState.setState({ plannedDate: day })}
						headerRender={renderCalendarHeader}
					/>
				</Col>
				<Col span={15} style={{ paddingLeft: '50px' }}>
					<span
						style={{
							fontFamily: 'Segoe UI',
							fontWeight: 'normal',
							fontSize: '22px',
							color: '#000000'
						}}
					>
						Приемка
					</span>
					{checkListsData?.length === 0 && <div>Нет чек-листов на эту дату</div>}

					<div style={{ maxHeight: '300px', overflowY: 'auto', marginTop: 10 }}>
						{checkListsData?.map(item => {
							const timeStr = dayjs(item.plannedDateTime).format('HH:mm')
							return (
								<>
									<Row key={item.id} style={{ marginBottom: 8 }}>
										<Col span={2}>
											<div style={{ color: 'grey' }}>{timeStr}</div>
										</Col>
										<Col span={22}>
											<Tooltip title={item.title.length > 25 ? item.title : null}>
												<div style={{ cursor: 'pointer' }} onClick={() => onChecklistClick(item)}>
													{item.title.length > 300 ? `${item.title.slice(0, 300)}...` : item.title}
												</div>
											</Tooltip>
											<span style={{ color: 'grey', marginRight: 5 }}>Ответственные:</span>
											{item?.potentialAssignedUsers!.length > 0 && (
												<Select
													popupClassName="app-selector-no-flow"
													showSearch={false}
													value={item
														?.potentialAssignedUsers!.sort((a, b) =>
															a?.fullName?.localeCompare(b?.fullName)
														)
														.map(x => x?.id)}
													tagRender={props => (
														<>
															<Tag
																color={props.isMaxTag === true ? '#bee2ff' : 'blue'}
																style={{ fontSize: 14, margin: 0, color: '#0071ca' }}
																bordered={false}
															>
																{props.isMaxTag === true
																	? String(props.label).slice(2, -4).concat('+')
																	: props.label}
															</Tag>
														</>
													)}
													maxTagCount={1}
													mode="multiple"
													variant="borderless"
													options={item?.potentialAssignedUsers!.map(x => ({
														key: x.id,
														value: x.id,
														label: x.fullName
													}))}
													suffixIcon={
														<MoreOutlined
															style={{ color: 'black', fontSize: 14 }}
															onClick={e => {
																e.stopPropagation()
																setOpen(prev => (prev === item.id ? null : item.id))
															}}
														/>
													}
													onDropdownVisibleChange={v => setOpen(v ? item.id : null)}
													open={open === item.id}
												/>
											)}
										</Col>
									</Row>
									<hr style={{ border: '1px solid #f0f0f0' }} />
								</>
							)
						})}
					</div>
				</Col>
			</Row>
		</Card>
	)
}
