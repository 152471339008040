import { Button, Card, Col, message, Row } from 'antd'
import { FC } from 'react'
import { Reports } from './reports'
import { ReportTable } from './reports-table'
import { useReportState } from '../model/reports-state'
import { DownloadOutlined } from '@ant-design/icons'
import {
	useReportExcel,
	useReportIssueAuthors,
	useReportIssueContractors
} from '../api/reports-query'
import { downloadDocument } from 'shared/helpers'
import dayjs from 'dayjs'

export const ReportsMain: FC = () => {
	const { filters } = useReportState()
	const { mutate: getReportExcel } = useReportExcel()
	const { data: contractors } = useReportIssueContractors(filters?.projectIds!)
	const { data: authors } = useReportIssueAuthors(filters?.projectIds!)

	const handleDownloadClick = () => {
		if (!filters) return

		getReportExcel(filters, {
			onSuccess: data => {
				const currentDate = dayjs().format('DD.MM.YYYY')

				let fileName = ''
				if (filters.type === 'issues') {
					const contractorObj = contractors?.find(c => c.id === filters.contractorId)
					fileName = `Реестр замечаний ${contractorObj?.name ?? ''} ${currentDate}.xlsx`
				} else if (filters.type === 'author') {
					const authorObj = authors?.find(a => a.ownerId === filters.ownerId)
					fileName = `Отчёт по автору ${authorObj?.name ?? ''} ${currentDate}.xlsx`
				}

				downloadDocument(data.data, fileName)
			},
			onError: () => {
				message.error('Во время загрузки файла произошла ошибка')
			}
		})
	}
	return (
		<Card>
			<Row justify="space-between">
				<Col>
					<Row gutter={8}>
						<Col>
							<Reports />
						</Col>
						<Col>
							<Button
								type={'default'}
								icon={<DownloadOutlined />}
								disabled={!filters}
								onClick={handleDownloadClick}
							>
								Скачать отчёт
							</Button>
						</Col>
					</Row>
				</Col>
			</Row>
			<ReportTable />
		</Card>
	)
}
