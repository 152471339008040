import {
	CopyOutlined,
	DeleteOutlined,
	InsertRowAboveOutlined,
	InsertRowBelowOutlined,
	LockOutlined,
	PlusSquareOutlined,
	UnlockOutlined
} from '@ant-design/icons'
import { Row } from '@tanstack/react-table'
import { Button, Dropdown } from 'antd'
import modal from 'antd/es/modal'
import { useCallback } from 'react'
import { moveRow } from 'shared/helpers'
import { useEstimatePositionState } from 'widgets/estimate-position-card'
import { IEstimatePosition, IEstimateSection, useEstimateState } from '..'

type TProps = {
	row: IEstimatePosition
	mainRow: Row<IEstimatePosition>
	canCreate: boolean
	canClose: boolean
	canDelete: boolean
}

export const EstimatePositionMenu = ({ mainRow, row, canCreate, canClose, canDelete }: TProps) => {
	const section = mainRow.getParentRow() as unknown as Row<IEstimateSection>
	const isSection = 'codifier' in section.original
	const { container, copyEstimatePosition, closedEstimatePosition, deleteEstimatePosition } =
		useEstimateState()
	const addEstimatePositionHandler = useCallback((direction: number) => {
		if (container && isSection) {
			useEstimatePositionState.setState({
				show: true,
				containerId: container.id,
				section: section.original,
				placement: {
					targetId: row.id,
					direction
				}
			})
		}
	}, [])

	const estimateItems =
		typeof container?.isLocalEstimate === 'undefined' ||
		(typeof container?.isLocalEstimate !== 'undefined' && 'isUnrelated' in row && row.isUnrelated)
			? [
					{
						key: 'above',
						label: 'Добавить до',
						icon: <InsertRowAboveOutlined />,
						disabled: !canCreate,
						//  ||
						// (moveRow(section.original.children, mainRow.index, 'up') &&
						// 	container?.isLocalEstimate === false)
						onClick: () => addEstimatePositionHandler(0)
					},
					{
						key: 'below',
						label: 'Добавить после',
						icon: <InsertRowBelowOutlined />,
						disabled: !canCreate,
						//  ||
						// (moveRow(section.original.children, mainRow.index, 'down') &&
						// 	container?.isLocalEstimate === false)
						onClick: () => addEstimatePositionHandler(1)
					},
					{
						key: 'copy',
						label: 'Копировать',
						icon: <CopyOutlined />,
						disabled: !canCreate || !!row.technologyIsDeleted,
						onClick: () =>
							modal.confirm({
								title: 'Скопировать?',
								content: 'Вы уверены, что хотите скопировать эту позицию сметы?',
								onOk: () =>
									copyEstimatePosition(
										[row.id],
										section.original.id,
										typeof container?.isLocalEstimate === 'undefined' ? 'wbs' : 'local'
									)
							})
					},
					{
						key: 'remove',
						label: 'Удалить',
						icon: <DeleteOutlined />,
						danger: true,
						disabled: !canDelete,
						onClick: () =>
							modal.confirm({
								title: 'Удалить?',
								content: 'Вы уверены, что хотите удалить эту позицию сметы?',
								onOk: () =>
									deleteEstimatePosition(
										[row.id],
										section.original.id,
										typeof container?.isLocalEstimate === 'undefined' ? 'wbs' : 'local'
									)
							})
					}
			  ]
			: [
					{
						key: 'above',
						label: 'Добавить до',
						icon: <InsertRowAboveOutlined />,
						disabled: !canCreate,
						//  ||
						// (moveRow(section.original.children, mainRow.index, 'up') &&
						// 	container?.isLocalEstimate === false)
						onClick: () => addEstimatePositionHandler(0)
					},
					{
						key: 'below',
						label: 'Добавить после',
						icon: <InsertRowBelowOutlined />,
						disabled: !canCreate,
						//  ||
						// (moveRow(section.original.children, mainRow.index, 'down') &&
						// 	container?.isLocalEstimate === false)
						onClick: () => addEstimatePositionHandler(1)
					},
					{
						key: 'copy',
						label: 'Копировать',
						icon: <CopyOutlined />,
						disabled: !canCreate || !!row.technologyIsDeleted,
						onClick: () =>
							modal.confirm({
								title: 'Скопировать?',
								content: 'Вы уверены, что хотите скопировать эту позицию сметы?',
								onOk: () =>
									copyEstimatePosition(
										[row.id],
										section.original.id,
										typeof container?.isLocalEstimate === 'undefined' ? 'wbs' : 'local'
									)
							})
					},
					{
						key: 'close',
						label: `${
							'isUnrelated' in row && !row.isUnrelated && !row.isClosed
								? 'Закрыть по подтвержденному объему'
								: 'isUnrelated' in row && !row.isUnrelated && !!row.isClosed
								? 'Отменить закрытие'
								: 'Закрыть'
						}`,
						icon: row.isClosed ? <LockOutlined /> : <UnlockOutlined />,
						disabled: !canClose,
						onClick: () =>
							modal.confirm({
								title: row.isClosed ? 'Отменить закрытие' : 'Закрыть?',
								content: `Вы уверены, что хотите ${
									'isUnrelated' in row && !row.isUnrelated && !row.isClosed
										? 'закрыть эту позицию сметы по подтвержденному объему'
										: 'отменить закрытие позиции сметы по подтвержденному объему'
								}?`,
								onOk: () =>
									closedEstimatePosition(
										row.id,
										section.original.id,
										row.isClosed!,
										typeof container?.isLocalEstimate === 'undefined' ? 'wbs' : 'local'
									)
							})
					},
					{
						key: 'remove',
						label: 'Удалить',
						icon: <DeleteOutlined />,
						danger: true,
						disabled:
							!canDelete ||
							(typeof container?.isLocalEstimate !== 'undefined' &&
								'isUnrelated' in row &&
								!row.isUnrelated),
						onClick: () =>
							modal.confirm({
								title: 'Удалить?',
								content: 'Вы уверены, что хотите удалить эту позицию сметы?',
								onOk: () =>
									deleteEstimatePosition(
										[row.id],
										section.original.id,
										typeof container?.isLocalEstimate === 'undefined' ? 'wbs' : 'local'
									)
							})
					}
			  ]

	return (
		<Dropdown
			overlayStyle={{ width: '256px' }}
			trigger={['click']}
			menu={{
				items: estimateItems
			}}
		>
			<Button
				data-attr="estimatePositionActions"
				type="text"
				size="small"
				icon={
					<PlusSquareOutlined
						style={{
							color: '#7445cc'
						}}
					/>
				}
				onClick={e => {
					e.stopPropagation()
				}}
			/>
		</Dropdown>
	)
}
