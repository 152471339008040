import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { INotificationDestinationOptions, IUserDto, IUserDtoProject } from 'interfaces/IUser'

interface IDtoAction {
	prop: string
	value:
		| string
		| Date
		| number
		| IUserDtoProject[]
		| boolean
		| null
		| INotificationDestinationOptions[]
}

const userInitialState: IUserDto = {
	id: '',
	destinationId: '',
	lastName: null,
	firstName: null,
	email: null,
	isAdmin: false,
	companyId: null,
	contractorId: null,
	contractor: null,
	secretWord: null,
	projects: null,
	notificationDestinationOption: [],
	policyConfirm: false
}

const userDtoKeys = Object.keys(userInitialState).map(key => key)

const userSlice = createSlice({
	name: 'user',
	initialState: {
		init: userInitialState,
		dto: userInitialState
	},
	reducers: {
		setUser(state, action: PayloadAction<IUserDto>) {
			state.dto = {
				...action.payload,
				companyId: null
			}
		},
		setUserValue(state, action: PayloadAction<IDtoAction>) {
			if (userDtoKeys.some(key => key === action.payload.prop)) {
				state.dto = {
					...state.dto,
					[action.payload.prop]: action.payload.value
				}
			}
		},
		resetUserState(state) {
			state.init = userInitialState
			state.dto = userInitialState
		}
	}
})

export const { setUser, setUserValue, resetUserState } = userSlice.actions

export default userSlice.reducer
