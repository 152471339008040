import { Empty, Table } from 'antd'
import { FC } from 'react'
import { useReport } from '../api/reports-query'
import { reportColumns } from './reports-columns'
import { PAGE_LIMIT } from 'shared/constants'
import { useReportState } from '../model/reports-state'

export const ReportTable: FC = () => {
	const { data: ReportsData } = useReport()
	const { totalItems, currentPage, onPagination } = useReportState()

	return (
		<>
			{!!ReportsData?.length ? (
				<Table
					rowKey={(record, index) => index!}
					bordered
					size="small"
					className="app-hoc-table app-report-table"
					scroll={{ y: '62vh', x: '10%' }}
					// rowClassName="app-hoc-row"
					dataSource={ReportsData}
					columns={reportColumns}
					pagination={{
						hideOnSinglePage: true,
						total: totalItems,
						pageSize: PAGE_LIMIT,
						showSizeChanger: false,
						onChange: onPagination,
						current: currentPage
					}}
					style={{ marginTop: '1rem' }}
					// sticky={{ offsetHeader: -16 }}
				/>
			) : (
				<Empty />
			)}
		</>
	)
}
