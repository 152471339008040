import { useQuery } from '@tanstack/react-query'
import modal from 'antd/es/modal'
import { AxiosError } from 'axios'
import { useAppDispatch, useAppSelector } from 'hooks/appReduxHook'
import { IErrorDetail } from 'interfaces/IBase'
import { useLocation, useNavigate } from 'react-router-dom'
import { setEnvironmentProject } from 'store/environmentSlice'
import { issueCardApi } from '..'
import { useIssueCardState } from './issue-card-state'
import { notification } from 'antd'

export const useIssueCard = () => {
	const navigate = useNavigate()
	const { pathname } = useLocation()
	const dispatch = useAppDispatch()
	const { project, user } = useAppSelector(state => state.environment)
	const { issueId, openCard, unsetIssue } = useIssueCardState()
	const closeHandler = () => {
		unsetIssue()
		if (typeof project?.id === 'undefined') navigate('/', { replace: true })
	}
	return useQuery({
		queryKey: ['issue', 'card', issueId],
		queryFn: async () => {
			if (issueId) return await issueCardApi.get(issueId).then(data => data)
			return null
		},
		select: data => data,
		onSuccess: data => {
			if (data?.projectId && project?.id !== data?.projectId) {
				if (user?.projects?.some(p => p.id === data?.projectId)) {
					if (pathname.includes('issues/')) {
						dispatch(
							setEnvironmentProject({
								option: 'project',
								data: user.projects.find(p => p.id === data?.projectId)!
							})
						)
					}
					data && openCard()
				} else {
					modal.error({
						title: 'Ошибка доступа',
						content:
							'Внимание! Замечание не доступно по ссылке, поскольку у Вас не хватает прав. Обратитесь к Администратору',
						onOk: closeHandler,
						onCancel: closeHandler
					})
				}
			} else {
				data && openCard()
			}
		},
		onError: (err: unknown) => {
			if (err instanceof AxiosError) {
				if (err.response?.status === 403) {
					modal.error({
						title: 'Ошибка доступа',
						content: err.response?.data?.detail,
						onOk: closeHandler,
						onCancel: closeHandler
					})
				}
			} else {
				notification.error({
					message: 'Произошла непредвиденная ошибка'
				})
			}
		},
		retry: (_, error) =>
			error instanceof AxiosError ? (error.response?.status === 403 ? false : true) : true,
		refetchOnWindowFocus: false,
		cacheTime: 10
	})
}

export const useIssueAttachments = () => {
	const project = useAppSelector(state => state.environment.project)
	const { issueId } = useIssueCardState()
	return useQuery({
		queryKey: ['issue', 'attachments', issueId],
		queryFn: async () => {
			if (issueId) return await issueCardApi.getAttachments(issueId).then(data => data)
			return null
		},
		select: data => data,
		refetchOnWindowFocus: false,
		enabled: typeof project?.id !== 'undefined'
	})
}
