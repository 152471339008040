import { IBase, IBasePotentialRedactors } from 'interfaces/IBase'
import { IPagination } from 'interfaces/IPagination'
import { IWbsContainerComments } from 'pages/unmodelPage/wbsContainerComments'
import { API } from 'services/ApiService'
import { TEstimateType } from 'shared/schema'
import {
	IEstimatePositionItemDto,
	IEstimatePositionOrder,
	IMovePosition,
	INonActualSection,
	ISectionToMove,
	IWbsContainer,
	IWbsContainerSectionDto,
	ImportFromExcel
} from '..'

export const WbsContainerApi = {
	async getContainer(id: string, type: 'wbs' | 'local') {
		const container = type === 'local' ? 'containerEstimates' : 'containers'
		return await API<IWbsContainer>({
			url: `/v1/${container}/${id}`,
			method: 'get',
			params: {
				projectId: null
			}
		}).then(response => response.data)
	},
	async getSections(containerId: string) {
		return await API<IWbsContainerSectionDto[]>({
			url: `/v1/containers/${containerId}/sections`,
			method: 'get'
		}).then(response => response.data)
	},
	async getSectionsToMove(containerId: string, workId: string) {
		return await API<ISectionToMove[]>({
			url: `/v1/containers/${containerId}/sections`,
			method: 'get',
			params: { workId }
		}).then(response => response.data)
	},
	async getSectionItems(sectionId: string, containerId: string) {
		return await API<IEstimatePositionItemDto[]>({
			url: `/v1/containers/${containerId}/sections/${sectionId}/estimatePositions`,
			method: 'get'
		}).then(response => response.data)
	},

	async setContainerReadyState(id: string, state: boolean) {
		return await API({
			url: `/v1/containers/${id}/ready`,
			method: 'patch',
			params: { ready: state }
		}).then(response => response.status)
	},
	async getSectionTechnologies(sectionId: string) {
		return await API({
			url: `/v1/sections/${sectionId}/technologies`,
			method: 'get',
			params: {projectId: null}
		}).then(response => response.data)
	},
	async deleteEstimatePositions(ids: string[], type: 'wbs' | 'local') {
		return await API({
			url: `/v1/estimatePositions/${type === 'wbs' ? 'container' : 'estimate'}`,
			method: 'delete',
			data: ids,
			params: {
				projectId: null
			}
		}).then(response => response.data)
	},
	async estimatePositionMoveTo(movePositions: IMovePosition[], type: 'wbs' | 'local') {
		return await API({
			url: `/v1/estimatePositions/${type === 'wbs' ? 'container' : 'estimate'}/move`,
			method: 'patch',
			data: movePositions,
			params: {
				projectId: null
			}
		}).then(response => response.status)
	},
	async setEstimatePositionOrder(data: IEstimatePositionOrder[], type: 'wbs' | 'local') {
		return await API({
			url: `/v1/estimatePositions/${type === 'wbs' ? 'container' : 'estimate'}/order`,
			method: 'patch',
			data: data,
			params: {
				projectId: null
			}
		}).then(async response => response)
	},
	async copyEstimatePosition(ids: string[]) {
		return await API({
			url: `/v1/estimatePositions/copy`,
			method: 'post',
			data: ids
		}).then(async response => response.data)
	},
	async checkContainer(id: string) {
		return await API<boolean>({
			url: `/v1/containers/checkContainer/${id}`,
			method: 'get'
		}).then(async response => response)
	},
	async exportToExcel(containerId: string, type: 'wbs' | 'local', withPrice?: boolean) {
		return await API({
			url: `/v1/export/${type === 'local' ? 'exportEstimateToExcel' : 'exportContainerToExcel'}`,
			method: 'get',
			params: { containerId, withPrice, projectId: null },
			responseType: 'blob'
		}).then(async response => response)
	},
	async clearIsCopied(containerId: string) {
		return await API({
			url: `/v1/containers/${containerId}/clearIsCopied`,
			method: 'patch'
		}).then(async response => response.status)
	},
	async getPotentialRedactors(projectId: string) {
		return await API<IBasePotentialRedactors[]>({
			url: `/v1/containers/potentialRedactors`,
			method: 'get',
			params: {
				projectId
			}
		}).then(async response => response.data)
	},
	async getContainersConstructionObjects() {
		return await API<IBase[]>({
			url: `/v1/containers/constructionObjects`,
			method: 'get',
			params: {
				projectId: null
			}
		}).then(async response => response.data)
	},
	async importFromExcel(file: FormData, containerId: string) {
		return await API({
			url: `/v1/import/previewFileVBS`,
			method: 'post',
			params: { containerId },
			data: file
		}).then(async response => response.data)
	},
	async importEstimatePosition(data: ImportFromExcel[]) {
		return await API({
			url: '/v1/estimatePositions/work',
			method: 'patch',
			data: data
		})
	},
	async getCanVisaApprove(containerId: string) {
		return await API<string[]>({
			url: `/v1/containers/${containerId}/approvalAvailable`,
			method: 'get',
			params: {
				projectId: null
			}
		}).then(response => response.data)
	},
	async setVisaApprove(containerId: string, type: TEstimateType, visaId?: string) {
		const url = type === 'local' ? 'containerEstimates' : 'containers'
		return await API({
			url: `/v1/${url}/${containerId}/approve`,
			method: 'post',
			params: { visaId, projectId: null }
		}).then(response => response.data)
	},
	async unsetVisaApprove(
		containerId: string,
		type: TEstimateType,
		visaId: string,
		containerStatusId: string
	) {
		const url = type === 'local' ? 'containerEstimates' : 'containers'
		return await API({
			url: `/v1/${url}/${containerId}/unapprove`,
			method: 'post',
			data: { visaId, containerStatusId, projectId: null }
		}).then(response => response.data)
	},
	async checkIsActual(containerId: string) {
		return await API<boolean>({
			url: `/v1/containers/${containerId}/sections/isActual`,
			method: 'get',
			params: {
				projectId: null
			}
		}).then(response => response.data)
	},

	async wbsActualize(containerId: string) {
		return await API<boolean>({
			url: `/v1/containers/${containerId}/sections/actualize`,
			method: 'post'
		}).then(response => response.data)
	},
	async wbsNonActualPositions(containerId: string) {
		return await API<INonActualSection[]>({
			url: `/v1/containers/${containerId}/sections/nonActualPositions`,
			method: 'get'
		}).then(response => response.data)
	},
	async getLocalEstimateContainerComments(
		containerId: string,
		isCommentsClosed?: boolean,
		myComments?: boolean
	) {
		return await API<IPagination<IWbsContainerComments[]>>({
			url: `/v1/containerEstimates/${containerId}/estimatePositionsComments`,
			method: 'get',
			params: { isCommentsClosed, myComments }
		}).then(async response => response.data)
	}
}
