import { ColumnsType } from 'antd/es/table'
import { Table } from 'antd/lib'
import { Badge, Button, Col, Empty, Modal, Row, Tooltip } from 'antd'
import { useDiagramsProjectState } from '../model/diagrams-project-state'
import { useIssues, useIssueSubTypesDashboard } from '../model/diagrams-project-query'
import { changeCurrentProject } from '../model/diagrams-project-helpers'
import { setIssueFilters } from 'store/issueSlice'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'hooks/appReduxHook'
import { useQueryClient } from '@tanstack/react-query'
import { useCheckPermissions } from 'shared/useCheckPermissions'
import { PERMISSIONS } from 'shared/constants'

export const DiagramsProjectIssueType = () => {
	const { projectId, contractorIds, authors } = useDiagramsProjectState()
	const { data: issueTypeData } = useIssueSubTypesDashboard()
	const navigate = useNavigate()
	const dispatch = useAppDispatch()
	const { user } = useAppSelector(state => state.environment)
	const queryClient = useQueryClient()
	const checkPermission = useCheckPermissions()
	const { data: issue } = useIssues()
	const labels = ['Устранено', 'Не устранено']
	const customLabels = labels.map((label, idx) => `${label}`)

	const handleIssueClick = (status: string[], issueSubTypeId: string) => {
		if (!checkPermission([PERMISSIONS.IssueView], projectId!)) {
			Modal.error({
				title: 'Ошибка доступа',
				content:
					'Внимание! Раздел не доступен, поскольку у Вас не хватает прав. Обратитесь к администратору.'
			})
			return
		}
		changeCurrentProject(projectId!, user, dispatch, queryClient)
		dispatch(
			setIssueFilters({
				filters: {
					contractorIds: contractorIds!,
					ownerIds: authors!,
					issueSubTypeId,
					status
				}
			})
		)
		navigate('/control/issues')
	}

	const columns: ColumnsType<any> = [
		{
			dataIndex: 'number',
			title: '',
			width: 38,
			render: (_, record) => record.code
		},
		{
			dataIndex: 'lable',
			title: '',
			width: 250
		},
		{
			dataIndex: 'fix',
			// title: 'Устранено',
			render: (_, record) =>
				record.issueFix > 0 ? (
					<div
						onClick={() => {
							handleIssueClick(['2d9df9da-5340-4f2a-ad30-77a24bb1bfea'], record.issueSubTypeId)
						}}
					>
						<span
							style={{ color: '#1677FF', fontSize: 14, fontWeight: 400, fontFamily: 'Segoe UI' }}
						>
							{record.issueFix}{' '}
						</span>
					</div>
				) : (
					''
				),
			width: 46,
			align: 'center'
		},
		{
			dataIndex: 'nofix',
			// title: 'Не устранено',
			render: (_, record) =>
				record.issueNoFix > 0 ? (
					<div
						onClick={() => {
							handleIssueClick(
								['4ae3200c-d2df-4c70-922a-780ea1575a35', '5758e9f3-b35b-4d97-8616-aa3bbb96131f'],
								record.issueSubTypeId
							)
						}}
					>
						<span
							style={{ color: '#FF4D4F', fontSize: 14, fontWeight: 400, fontFamily: 'Segoe UI' }}
						>
							{record.issueNoFix}{' '}
						</span>
					</div>
				) : (
					''
				),
			width: 46,
			align: 'center'
		},
		{
			dataIndex: 'sum',
			title: 'Сумма',
			width: 60,
			align: 'center',
			render: (_, record) => (
				<div
					className="issueTypeSum"
					style={{ fontSize: 14, fontWeight: 400, fontFamily: 'Segoe UI' }}
				>
					{record.issueFix + record.issueNoFix}
				</div>
			)
		},
		{
			dataIndex: 'diagram',
			render: (_, record) => {
				const fix = record.issueFix || 0
				const noFix = record.issueNoFix || 0
				const total = fix + noFix

				return (
					<div
						style={{
							display: 'flex',
							width: '100%',
							paddingLeft: '10px'
						}}
					>
						{total > 0 && (
							<Tooltip
								overlay={
									<p>
										{record.lable}
										<br />
										<Row align="middle">
											<div
												style={{
													width: '10px',
													height: '10px',
													backgroundColor: '#1677FF',
													marginRight: '5px'
												}}
											/>
											Устранено: {fix}
										</Row>
										<Row align="middle">
											<div
												style={{
													width: '10px',
													height: '10px',
													backgroundColor: '#FF4D4F',
													marginRight: '5px'
												}}
											/>
											Не устранено: {noFix}
										</Row>
									</p>
								}
							>
								<div
									style={{
										display: 'flex',
										width: '100%',
										height: '20px',
										borderRadius: '3px',
										overflow: 'hidden',
										paddingBottom: '0px'
									}}
								>
									{fix > 0 && (
										<div
											style={{
												width: `${(fix / total) * 100}%`,
												backgroundColor: '#1677FF',
												height: '20px',
												paddingBottom: '0px'
											}}
											onClick={() => {
												handleIssueClick(
													['2d9df9da-5340-4f2a-ad30-77a24bb1bfea'],
													record.issueSubTypeId
												)
											}}
										/>
									)}
									{noFix > 0 && (
										<div
											style={{
												width: `${(noFix / total) * 100}%`,
												backgroundColor: '#FF4D4F',
												height: '20px',
												paddingBottom: '0px'
											}}
											onClick={() => {
												handleIssueClick(
													[
														'4ae3200c-d2df-4c70-922a-780ea1575a35',
														'5758e9f3-b35b-4d97-8616-aa3bbb96131f'
													],
													record.issueSubTypeId
												)
											}}
										/>
									)}
								</div>
							</Tooltip>
						)}
					</div>
				)
			}
		}
	]

	const hasData =
		issueTypeData &&
		issueTypeData.length > 0 &&
		issueTypeData.some(item => item.issueFix > 0 || item.issueNoFix > 0)

	return (
		<Row
			// wrap={false}
			// gutter={12}
			style={{
				border: '1px solid #ddd',
				borderRadius: 8,
				padding: 20,
				background: 'white',
				height: '450px',
				position: 'relative'
			}}
		>
			<Col
				span={24}
				style={{
					// padding: '1rem',
					background: '#fefefe'
				}}
			>
				<div
					style={{
						fontFamily: 'Segoe UI',
						fontWeight: 'normal',
						fontSize: '22px',
						color: '#000000',
						paddingBottom: '20px'
					}}
				>
					Типы замечаний
				</div>
				{hasData ? (
					<Table
						rowKey={(record, index) => index!}
						pagination={false}
						className="main-issue-type-table"
						rowClassName="main-issue-type-table-row"
						columns={columns}
						dataSource={issueTypeData ?? []}
						size="small"
						bordered
						showHeader={false}
						scroll={{
							x: 450
						}}
					/>
				) : (
					<div
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							height: '400px',
							marginTop: '-90px'
						}}
					>
						<Empty />
					</div>
				)}
				<Col span={24} style={{ position: 'absolute', top: '95%', paddingLeft: 0 }}>
					<Row justify="start">
						{issue?.totalIssues! > 0 && (
							<div style={{ display: 'flex' }}>
								{customLabels.map((label, index) => (
									<div
										key={index}
										style={{ display: 'flex', alignItems: 'center', margin: '0 10px 0 0' }}
									>
										<div
											style={{
												width: '12px',
												height: '12px',
												backgroundColor: index === 0 ? '#1677FF' : '#FF4D4F',
												marginRight: '5px'
											}}
										/>
										<span
											style={{
												fontFamily: 'Segoe UI',
												fontWeight: 'normal',
												fontSize: '14px',
												color: '#2C303D'
											}}
										>
											{label}
										</span>
									</div>
								))}
							</div>
						)}
					</Row>
				</Col>
			</Col>
		</Row>
	)
}
