import { useCallback } from 'react';
import { useViewerState } from 'viewer-state-new';

export const useCheckPermissions = () => {
	const {viewer} = useViewerState()
	return useCallback(
		(permits: string[], projectId: string, every?: boolean) => {
			const permissions = viewer?.projects?.find(p => p?.id === projectId)?.permissions
			if (viewer?.isAdmin) return true
			else if (typeof permissions === 'undefined') return false
			else {
				return permissions
					? every
						? permits.every(check => typeof permissions?.find((p: any) => p?.name === check) !== 'undefined')
						: permits.some(check => typeof permissions?.find((p: any) => p?.name === check) !== 'undefined')
					: false
			}
		},
		[viewer]
	)
}

export const useCheckCommonPermissions = () => {
	const {viewer} = useViewerState()
	return useCallback(
		(permits: string[], every?: boolean) => {
			const permissions = viewer?.projects?.flatMap(p => p?.permissions)
			if (viewer?.isAdmin) return true
			else if (typeof permissions === 'undefined') return false
			else {
				return permissions
					? every
						? permits.every(check => typeof permissions?.find((p: any) => p?.name === check) !== 'undefined')
						: permits.some(check => typeof permissions?.find((p: any) => p?.name === check) !== 'undefined')
					: false
			}
		},
		[viewer]
	)
}