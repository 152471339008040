import { useMutation, useQuery } from '@tanstack/react-query'
import { useAppSelector } from 'hooks/appReduxHook'
import { WbsStatusReportApi } from '../api/wbs-status-report-api'
import { IWbsStatusReportFilters } from './wbs-status-report-interface'
import { useWbsStatusReportState } from './wbs-status-report-state'

export const useWbsStatusReportExcel = () => {
	return useMutation(async ({filters, type}:{filters: IWbsStatusReportFilters, type: string}) => {
		return await WbsStatusReportApi.getWbsStatusReportExcel(filters, type)
	})
}

// export const useWbsStatusReport = () => {

//     const { currentPage, setData } = useWbsStatusReportState()

//     return useMutation(async (filters: IWbsStatusReportFilters) => {
//         return await WbsStatusReportApi.getWbsStatusReport({
// 			settings: filters,
// 			limit: 50,
// 			offset: currentPage === 1 ? 0 : currentPage * 50 - 50
// 		})
//     }, {
//         onSuccess: (data) => setData(data.containers.data)
//     })
// }

export const useWbsStatusReportPagination = (filters: IWbsStatusReportFilters, type: string) => {
	const { project } = useAppSelector(state => state.environment)
	const { currentPage, setTotalItems, setTotalPages } = useWbsStatusReportState()
	return useQuery({
		queryKey: ['wbsStatusReportPagination', project?.id, filters, type],
		queryFn: async () => {
			const wbsStatusReportPagination = await WbsStatusReportApi.getWbsStatusReport({
				settings: filters,
				limit: 50,
				offset: currentPage === 1 ? 0 : currentPage * 50 - 50,
				type
			}).then(data => data)
			setTotalItems(wbsStatusReportPagination!.containers.totalItems)
			setTotalPages(wbsStatusReportPagination!.containers.totalPages)
			return wbsStatusReportPagination.containers.data
		},
		refetchOnWindowFocus: false
	})
}

// export const useAsmReport = (projectId: string) => {
// 	const project = useAppSelector(state => state.environment.project)
// 	const {projectReport} = useWbsStatusReportState()
// 	return useQuery({
// 		queryKey: ['asmReport', project?.id],
// 		queryFn: async () => {
// 			const asmList = await getContainers({
// 				project: projectReport!,
// 				projectId: projectId
// 			}).then(data => data.data)
// 			return asmList.data
// 		},
// 		refetchOnWindowFocus: false
// 	})
// }

export const useWbsContainersByProjects = (projectIds: string[], type: string) => {
	const project = useAppSelector(state => state.environment.project)
	const { projectReport } = useWbsStatusReportState()
	return useQuery({
		queryKey: ['wbsContainersByProjects', project?.id, projectReport, projectIds, type],
		queryFn: async () => {
			if (type) {
				const wbsContainersByProjects = await WbsStatusReportApi.getWbsContainersByProjects(
					projectIds,
					type
				).then(data => data)
				return wbsContainersByProjects
			} return null
		},
		refetchOnWindowFocus: false
	})
}
