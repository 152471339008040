import {
	CheckSquareFilled,
	CloseSquareFilled,
	DeleteOutlined,
	DollarOutlined,
	FilterOutlined,
	SyncOutlined
} from '@ant-design/icons'
import { useQueryClient } from '@tanstack/react-query'
import StatusIndicator from 'UI/statusIndicator'
import { Button, Col, Input, Popconfirm, Row, Table, Tooltip, message } from 'antd'
import type { ColumnType, ColumnsType } from 'antd/es/table'
import { AxiosError } from 'axios'
import ColumnConfig, { IColumns } from 'components/columnConfig'
import dayjs from 'dayjs'
import { useAppDispatch, useAppSelector } from 'hooks/appReduxHook'
import { useOrdinances } from 'hooks/useOrdinances'
import { IErrorDetail } from 'interfaces/IBase'
import { IOrdinance } from 'interfaces/IOrdinance'
import { FC, useEffect, useState } from 'react'
import { Resizable, ResizeCallbackData } from 'react-resizable'
import { useNavigate } from 'react-router-dom'
import { deleteOrdinance } from 'services/OrdinanceService'
import { setFilterOrdinance, setTotalPagesOrdinance } from 'store/ordinanceSlice'
import { setPageTitle } from 'store/rootSlice'
import OrdinanceDrawer from './ordinanceDrawer'
import OrdinanceFilters from './ordinanceFilters'

const { Search } = Input

const OrdinancePage: FC = () => {
	const navigate = useNavigate()
	const dispatch = useAppDispatch()
	const conf = localStorage.getItem('ordinancePageColumns')
	const ordinanceListState = useAppSelector(state => state.ordinance.listState)
	const { user: currentUser } = useAppSelector(state => state.environment)
	const [showOrdinanceDetail, setShowOrdinanceDetail] = useState(false)
	const [selectedOrdinance, setSelectedOrdinance] = useState<IOrdinance | null>(null)
	const [showOrdinanceFilter, setShowOrdinanceFilter] = useState(false)
	const ordinanceFilters = useAppSelector(state => state.ordinance.filters)
	const queryClient = useQueryClient()
	const { data: ordinanceList, isFetching } = useOrdinances()
	const [showColumns, setShowColumns] = useState<IColumns[]>([])
	const [ordinanceColumns, setOrdinanceColumns] = useState<ColumnsType<IOrdinance>>([
		{
			title: 'Статус',
			fixed: 'left',
			width: 100,
			render: (_, record) => (
				<Row gutter={8} wrap={false} align="middle">
					<Col>
						<StatusIndicator status={record.status!?.identityName} />
					</Col>
					<Col>{record.status ? record.status!?.name : 'Не устранено'}</Col>
				</Row>
			)
		},
		{
			title: 'Автоматически',
			dataIndex: 'isAutoCreated',
			width: 70,
			align: 'center',
			fixed: 'left',
			render: (_, record) =>
				record.isAutoCreated && (
					<Tooltip title="Создано автоматически">
						<b>А</b>
					</Tooltip>
				)
		},
		{
			title: '№',
			fixed: 'left',
			align: 'center',
			width: 100,
			render: (_, record) => (record.name !== null ? record.name : record.number)
		},
		{
			key: 'createdAt',
			title: 'Дата создания',
			fixed: 'left',
			width: 120,
			sorter: false,
			render: (_, record) => dayjs(record.createdAt).format('DD.MM.YYYY HH:mm')
		},
		{
			title: 'Генподрядчик',
			fixed: 'left',
			ellipsis: {
				showTitle: true
			},
			width: 100,
			render: (_, record) => record.contractor!?.name
		},
		{
			title: 'Согласовано',
			align: 'center',
			width: 70,
			render: (_, record) => {
				if (record.isApproved && !record.isDeleted) {
					return <CheckSquareFilled style={{ color: 'green' }} />
				} else if (!record.isApproved && record.isDeleted) {
					return <CloseSquareFilled style={{ color: 'red' }} />
				}
			}
		},
		{
			title: 'Штраф',
			align: 'center',
			width: 70,
			hidden: false,
			render: (_, record) => {
				if (record.ordinanceFinesCount > 0) {
					return <DollarOutlined style={{ fontSize: 20 }} />
				}
			}
		},
		{
			title: 'Наименование',
			ellipsis: {
				showTitle: true
			},
			width: 150,
			render: (_, record) =>
				`Предписание №${record.name !== null ? record.name : record.number} от ${dayjs(
					record.createdAt
				).format('DD.MM.YYYY')} .г`
		},

		{
			title: 'Субподрядчик',
			ellipsis: {
				showTitle: true
			},
			width: 100,
			render: (_, record) => (record.subContractor!?.name ? record.subContractor!?.name : '-')
		},
		{
			title: 'Контактное лицо',
			ellipsis: {
				showTitle: true
			},
			width: 100,
			render: (_, record) => record.contactPerson!?.name
		},
		{
			title: 'Договор',
			width: 100,
			ellipsis: {
				showTitle: true
			},
			render: (_, record) => record.contract!?.name
		},
		{
			title: 'Срок по предписанию',
			width: 128,
			render: (_, record) => dayjs(record.dateTo).format('DD.MM.YYYY')
		},
		{
			title: 'Автор',
			width: 128,
			render: (_, record) => record.createdBy!?.name!
		},
		{
			width: currentUser?.isAdmin ? 80 : 1,
			align: 'center',
			render: (_, record) =>
				currentUser?.isAdmin && (
					<Tooltip title="Удалить" placement="left">
						<Popconfirm
							title="Удалить?"
							placement="topRight"
							onConfirm={async () =>
								await deleteOrdinance(record!?.id!)
									.then(async () => {
										message.success(
											`Предписание №${
												record?.name ? record?.name! : record?.number!
											} успешно удалено`
										)
										queryClient.invalidateQueries(['ordinancesList'])
									})
									.catch((error: AxiosError<IErrorDetail>) => {
										message.error(error!?.response!?.data?.detail)
									})
							}
							okButtonProps={{ danger: true }}
							okText="Да"
							cancelText="Отмена"
						>
							<Button icon={<DeleteOutlined />} danger />
						</Popconfirm>
					</Tooltip>
				),
			fixed: 'right'
		}
	])

	const initColumns: IColumns[] = ordinanceColumns.map((item, index) => ({
		key: index.toString()!,
		title: item.title?.toString()!,
		fixed: typeof item.fixed !== 'undefined'
	}))

	useEffect(() => {
		dispatch(setPageTitle({ mainTitle: 'Предписания' }))
		if (conf === null) {
			setShowColumns(initColumns)
			localStorage.setItem('ordinancePageColumns', JSON.stringify(initColumns))
		} else {
			setShowColumns(JSON.parse(conf!))
		}
	}, [])

	const onColumnsConfig = (cols: IColumns[]) => {
		setShowColumns(cols)
	}

	const closeOrdinance = () => {
		setSelectedOrdinance(null)
		setShowOrdinanceDetail(false)
	}

	const onPagination = (page: number) => {
		dispatch(setTotalPagesOrdinance(page))
	}

	const handleResize =
		(index: number, col: ColumnType<IOrdinance>) =>
		(_: React.SyntheticEvent<Element>, { size }: ResizeCallbackData) => {
			if (!col.fixed) {
				const minWidth = 200
				const maxWidth = 1000
				let colWidth: number = size.width
				if (size.width <= minWidth) colWidth = minWidth
				else if (size.width >= maxWidth) colWidth = maxWidth
				else colWidth = size.width
				const newColumns = [...ordinanceColumns]
				newColumns[index] = {
					...newColumns[index],
					width: colWidth,
					ellipsis: colWidth < 300 ? true : false
				}
				setOrdinanceColumns(newColumns)
			}
		}

	const mergeColumns: any = ordinanceColumns
		.filter(col => showColumns.find(i => i.title === col.title) !== undefined)
		.map((col, index) => ({
			...col,
			onHeaderCell: (column: ColumnType<IOrdinance>) => ({
				width: (column as ColumnType<IOrdinance>).width,
				onResize: handleResize(index, col)
			})
		}))

	const onSearch = (searchValue: string | undefined) => {
		const filters = { ...ordinanceFilters, search: searchValue! }
		dispatch(setFilterOrdinance({ filters }))
		dispatch(setTotalPagesOrdinance(1))
	}

	return (
		<>
			{/* <OrdinanceCard /> */}
			<OrdinanceDrawer
				ordinanceId={selectedOrdinance?.id}
				open={showOrdinanceDetail}
				onClose={closeOrdinance}
			/>
			<OrdinanceFilters open={showOrdinanceFilter} onClose={() => setShowOrdinanceFilter(false)} />
			<div id="ordinances" className="dashboard__block">
				<Row id="ordinances-controls" justify="space-between" style={{ marginBottom: '1rem' }}>
					<Col>
						<Row gutter={8} justify="start">
							<Col>
								<ColumnConfig
									onChange={onColumnsConfig}
									columns={initColumns}
									localstorage={'ordinancePageColumns'}
								/>
							</Col>
							<Col>
								<Tooltip title="Обновить">
									<Button
										size="middle"
										onClick={() => queryClient.invalidateQueries(['ordinancesList'])}
										icon={<SyncOutlined spin={isFetching} rev={undefined} />}
									/>
								</Tooltip>
							</Col>
							<Col>
								<Tooltip title="Фильтры">
									<Button
										onClick={() => setShowOrdinanceFilter(true)}
										icon={<FilterOutlined rev={undefined} />}
										type={
											Object.entries(ordinanceFilters).some(
												x => x[0] !== 'search' && typeof x[1] !== 'undefined'
											)
												? 'primary'
												: 'default'
										}
									/>
								</Tooltip>
							</Col>
							<Col>
								<Search
									allowClear
									placeholder="Поиск..."
									defaultValue={ordinanceFilters.search}
									onSearch={value => onSearch(value)}
									style={{ width: 300 }}
								/>
							</Col>
						</Row>
					</Col>
				</Row>
				<div id="resizable-table">
					<Table
						size="small"
						loading={{ indicator: <SyncOutlined spin />, spinning: isFetching }}
						dataSource={ordinanceList}
						columns={mergeColumns}
						tableLayout="fixed"
						scroll={{ x: '100%', y: '60vh' }}
						components={{
							header: {
								cell: ResizableTitle
							}
						}}
						rowClassName={() => 'at-issue-row '}
						onRow={record => ({
							onDoubleClick: () => {
								navigate(`/control/ordinance/${record.id}`)
							}
						})}
						pagination={{
							current: ordinanceListState.currentPage,
							pageSize: 50,
							hideOnSinglePage: false,
							showSizeChanger: false,
							total: ordinanceListState.totalItems,
							onChange: onPagination
						}}
					/>
				</div>
			</div>
		</>
	)
}

const ResizableTitle = (
	props: React.HTMLAttributes<any> & {
		onResize: (e: React.SyntheticEvent<Element>, data: ResizeCallbackData) => void
		width: number
	}
) => {
	const { onResize, width, ...restProps } = props

	if (!width) {
		return <th {...restProps} />
	}

	return props.title !== 'undefined' ? (
		<Resizable
			width={width}
			height={0}
			handle={
				<span
					className="react-resizable-handle"
					onClick={e => {
						e.stopPropagation()
					}}
				/>
			}
			onResize={onResize}
			draggableOpts={{ enableUserSelectHack: false }}
		>
			<th {...restProps} />
		</Resizable>
	) : null
}

export default OrdinancePage
