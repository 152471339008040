import { useQuery } from '@tanstack/react-query'
import { useLocalEstimateState } from 'pages/unmodelPage/localEstimate/model/localEstimateState'
import { WbsContainerApi } from 'pages/unmodelPage/wbsContainer/api/wbsContainerApi'
import { useWbsContainerState } from 'pages/unmodelPage/wbsContainer/model/wbsContainerState'
import { useEstimatePositionCommentsState } from 'widgets/estimate-position-comments'
import { getWbsAuthorCommentStatus, getWbsContainerComments } from '../api/wbsContainerComments-api'

export const useWbsContainerComments = (filter?: boolean, filterMyComment?: boolean) => {
	const { container, showContainerCommentsDrawer } = useWbsContainerState()
	const { container: containerLocalEstimate } = useLocalEstimateState()
	return useQuery({
		queryKey: ['wbsContainerComments', container, containerLocalEstimate, showContainerCommentsDrawer, filter, filterMyComment],
		queryFn: async () => {
			if (container && showContainerCommentsDrawer) {
				return await getWbsContainerComments(container!.id, filter, filterMyComment).then(res => res)
			}
			if (containerLocalEstimate && showContainerCommentsDrawer) {
				return await WbsContainerApi.getLocalEstimateContainerComments(containerLocalEstimate!?.id, filter, filterMyComment).then(res => res.data)
			}
			return null
		},
		refetchOnWindowFocus: false
	})
}

export const useWbsAuthorCommentStatus = (id: string, type: 'wbs' | 'local') => {
	const { show, currentId } = useEstimatePositionCommentsState()
	const {show: showEstimate, currentId: currentIdEstimate } = useEstimatePositionCommentsState()
	return useQuery({
		queryKey: ['wbsAuthorCommentStatus', currentId, show, showEstimate, currentIdEstimate],
		queryFn: async () => {
			if (id) {
				return await getWbsAuthorCommentStatus(id!, type).then(res => res).catch(() => 'withoutButton')
			}
			return null
		},
		refetchOnWindowFocus: false
	})
}