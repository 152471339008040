import type { TableColumnsType } from 'antd'
import { Empty, Table } from 'antd'
import dayjs from 'dayjs'
import { FC } from 'react'
import { NavLink } from 'react-router-dom'
import { IWbsStatusReport } from '../model/wbs-status-report-interface'
import { useWbsStatusReportState } from '../model/wbs-status-report-state'
import { useAppSelector } from 'hooks/appReduxHook'

export const WbsStatusReportTable: FC = () => {
	const { currentPage, totalItems, onPagination, WbsStatusReportData, type } =
		useWbsStatusReportState()
	const project = useAppSelector(state => state.environment.project)

	const columns: TableColumnsType<IWbsStatusReport> = [
		{
			title: '№',
			dataIndex: 'number',
			key: 'number',
			width: 50
		},
		{
			title: 'Тип документа',
			dataIndex: 'typeDocument',
			key: 'typeDocument',
			width: 150
		},
		{
			title: 'Раздел НСИ',
			dataIndex: 'typeSection',
			key: 'typeSection',
			width: 180
		},
		{
			title: 'Наименование',
			dataIndex: 'name',
			key: 'name',
			width: 256,
			render: (value, record) => (
				<NavLink
					to={
						`${
							record?.typeDocument === 'ВОР' || record?.typeDocument === 'Корректировка ВОР'
								? '/unmodel/'
								: record?.typeDocument === 'Было-стало ВОР'
								? '/unmodel/adjustment/'
								: '/local/'
						}` + record?.containerId!
					}
					target="_blank"
				>
					{record?.name}
				</NavLink>
			)
		},
		{
			title: 'Объект строительства',
			dataIndex: 'constructionObject',
			key: 'constructionObject',
			width: 256
		},
		{
			title: 'Статус',
			dataIndex: 'status',
			key: 'status',
			width: 256
		},
		{
			title: 'Дата создания',
			dataIndex: 'createdDate',
			key: 'createdDate',
			width: 120,
			render: value => (value && dayjs(value).isValid() ? dayjs(value).format('DD.MM.YYYY') : '')
		},
		{
			title: 'Новый',
			children: [
				{
					title: 'Автор',
					dataIndex: ['statusNew', 'author'],
					key: 'statusNewAuthor',
					width: 150
				},
				{
					title: 'Дата перехода в статус/Дата визы ',
					dataIndex: ['statusNew', 'dataTransfer'],
					key: 'statusNewDataTransfer',
					width: 120,
					render: value =>
						value && dayjs(value).isValid() ? dayjs(value).format('DD.MM.YYYY') : ''
				},
				{
					title: 'Итоговое время согласования',
					dataIndex: ['statusNew', 'duration'],
					key: 'statusNewDuration',
					width: 150
				}
			]
		},
		{
			title: 'Подготовка',
			children: [
				{
					title: 'Автор',
					dataIndex: ['statusPreparation', 'author'],
					key: 'statusPreparationAuthor',
					width: 150
				},
				{
					title: 'Дата перехода в статус/Дата визы ',
					dataIndex: ['statusPreparation', 'dataTransfer'],
					key: 'statusPreparationDataTransfer',
					width: 120,
					render: value =>
						value && dayjs(value).isValid() ? dayjs(value).format('DD.MM.YYYY') : ''
				},
				{
					title: 'Итоговое время согласования',
					dataIndex: ['statusPreparation', 'duration'],
					key: 'statusPreparationDuration',
					width: 150
				}
			]
		},
		{
			title: type === '1' ? 'Оценка НДК (ДЗ МТР)' : 'Согласование структуры (ДЗ МТР)',
			children: [
				{
					title: 'Автор',
					dataIndex: [
						type === '1' ? 'statusPriceApprovalDzMTR' : 'statusStructureApprovalDzMTR',
						'author'
					],
					key: 'statusApprovalDzMTRAuthor',
					width: 150
				},
				{
					title: 'Дата перехода в статус/Дата визы ',
					dataIndex: [
						type === '1' ? 'statusPriceApprovalDzMTR' : 'statusStructureApprovalDzMTR',
						'dataTransfer'
					],
					key: 'statusApprovalDzMTRDataTransfer',
					width: 120,
					render: value =>
						value && dayjs(value).isValid() ? dayjs(value).format('DD.MM.YYYY') : ''
				},
				{
					title: 'Итоговое время согласования',
					dataIndex: [
						type === '1' ? 'statusPriceApprovalDzMTR' : 'statusStructureApprovalDzMTR',
						'duration'
					],
					key: 'statusApprovalDzMTRDuration',
					width: 150
				}
			]
		},
		{
			title: 'Согласование структуры (СДУ)',
			children: [
				{
					title: 'Автор',
					dataIndex: [
						type === '1' ? 'statusPriceApprovalSDU' : 'statusStructureApprovalSDU',
						'author'
					],
					key: 'statusApprovalSDUAuthor',
					width: 150
				},
				{
					title: 'Дата перехода в статус/Дата визы ',
					dataIndex: [
						type === '1' ? 'statusPriceApprovalSDU' : 'statusStructureApprovalSDU',
						'dataTransfer'
					],
					key: 'statusApprovalSDUDataTransfer',
					width: 120,
					render: value =>
						value && dayjs(value).isValid() ? dayjs(value).format('DD.MM.YYYY') : ''
				},
				{
					title: 'Итоговое время согласования',
					dataIndex: [
						type === '1' ? 'statusPriceApprovalSDU' : 'statusStructureApprovalSDU',
						'duration'
					],
					key: 'statusApprovalSDUDuration',
					width: 150
				}
			]
		},
		{
			title: 'ВОР согласован',
			key: 'statusPriceApprovalDzMTR',
			children: [
				{
					title: 'Автор',
					dataIndex: ['statusPriceApprovalDzMTR', 'author'],
					key: 'statusPriceApprovalDzMTRAuthor',
					width: 150
				},
				{
					title: 'Дата перехода в статус/Дата визы ',
					dataIndex: ['statusPriceApprovalDzMTR', 'dataTransfer'],
					key: 'statusPriceApprovalDzMTRDataTransfer',
					width: 120,
					render: value =>
						value && dayjs(value).isValid() ? dayjs(value).format('DD.MM.YYYY') : ''
				},
				{
					title: 'Итоговое время согласования',
					dataIndex: ['statusPriceApprovalDzMTR', 'duration'],
					key: 'statusPriceApprovalDzMTRDuration',
					width: 150
				}
			]
		},
		{
			title: 'Итоговое время согласования ВОР',
			dataIndex: 'approvalTimeVOR',
			key: 'approvalTimeVOR',
			width: 150
		},
		{
			title: type === '1' ? 'Согласован' : 'Завершена оценка НМТР. ВОР отправлен в ЕИС',
			children: [
				{
					title: 'Автор',
					dataIndex: ['statusApproved', 'author'],
					key: 'statusPriceApprovalDzMTRAuthor',
					width: 150
				},
				{
					title: 'Дата перехода в статус/Дата визы ',
					dataIndex: ['statusApproved', 'dataTransfer'],
					key: 'statusPriceApprovalDzMTRDataTransfer',
					width: 120,
					render: value =>
						value && dayjs(value).isValid() ? dayjs(value).format('DD.MM.YYYY') : ''
				},
				{
					title: 'Итоговое время согласования',
					dataIndex: ['statusApproved', 'duration'],
					key: 'statusPriceApprovalDzMTRDuration',
					width: 150
				}
			]
		},
		{
			title: 'Итого',
			dataIndex: 'totalTimeVOR',
			key: 'totalTimeVOR',
			width: 150
		},
		{
			title: 'Ссылка на задачу',
			dataIndex: 'container',
			key: 'container',
			render: (value, record) => (
				<NavLink
					to={
						`${
							record?.typeDocument === 'ВОР' || record?.typeDocument === 'Корректировка ВОР'
								? '/unmodel/'
								: record?.typeDocument === 'Было-стало ВОР'
								? '/unmodel/adjustment/'
								: '/local/'
						}` + record?.containerId!
					}
					target="_blank"
				>
					{record?.name}
				</NavLink>
			),
			width: 250
		}
	]

	return (
		<>
			{WbsStatusReportData?.length ? (
				<Table
					rowKey={record => record.containerId}
					columns={
						type === '1'
							? columns?.filter(c => c.key !== 'statusPriceApprovalDzMTR')
							: (columns as any)
					}
					bordered
					dataSource={WbsStatusReportData}
					style={{ marginTop: '1rem' }}
					scroll={{ y: '62vh', x: '10%' }}
					size="small"
					pagination={{
						current: currentPage,
						pageSize: 50,
						hideOnSinglePage: false,
						showSizeChanger: false,
						total: totalItems,
						onChange: onPagination
					}}
					className="app-wbs-status-table app-hoc-table"
					rowClassName="app-wbs-status-table-row"
				/>
			) : (
				<Empty />
			)}
		</>
	)
}
