import dayjs from 'dayjs'
import { IFile } from 'interfaces/IEnvironment'
import { IBuildingPermit, IContactPerson } from 'interfaces/IIssue'
import {
	IOrdinance,
	IOrdinanceDTO,
	IOrdinanceEntity,
	IOrdinanceFilters,
	IOrdinanceUpd,
	ISignDto,
	IValidateOrdinance
} from 'interfaces/IOrdinance'
import { IPagination } from 'interfaces/IPagination'
import { IFileAttachment } from 'interfaces/IPluginFiles'
import { API } from './ApiService'

export const getOrdinanceList = async ({
	filters,
	limit = 50,
	offset = 0
}: {
	filters: IOrdinanceFilters | undefined
	limit?: number
	offset?: number
}) => {
	if (filters?.dateTo !== undefined && filters?.dateTo !== null) {
		filters = {
			...filters,
			dateTo: dayjs(filters?.dateTo).format('YYYY-MM-DD')
		}
	}
	if (filters?.createdAt !== undefined && filters?.createdAt !== null) {
		filters = {
			...filters,
			createdAt: dayjs(filters?.createdAt).format('YYYY-MM-DD')
		}
	}
	if (filters?.isApproved === true) {
		filters = {
			...filters,
			isApproved: true,
			isDeleted: false
		}
	} else if (filters?.isApproved === false) {
		filters = {
			...filters,
			isApproved: false,
			isDeleted: true
		}
	}
	return await API<IPagination<IOrdinance[]>>({
		url: `/v1/ordinances`,
		method: 'get',
		params: { limit, offset, ...filters }
	}).then(async response => response.data)
}

export const getOrdinance = async (id: string) => {
	return await API<IOrdinanceEntity>({
		url: `/v1/ordinances/${id}`,
		method: 'get'
	}).then(async response => response.data)
}

export const addOrdinanceByIssues = async (dto: IOrdinanceDTO) => {
	return await API({
		url: `/v1/ordinances`,
		method: 'post',
		data: dto
	}).then(async response => response.status)
}

export const getContactPersonsList = async (contractorId: string) => {
	return await API<IContactPerson[]>({
		url: `/v1/contactPersons`,
		method: 'get',
		params: { contractorId }
	}).then(async response => response.data)
}

export const getBuildingPermitsList = async (Project1CId?: string | null, projectId?: string) => {
	return await API<IBuildingPermit[]>({
		url: `/v1/buildingPermits`,
		method: 'get',
		params: { project1CId: Project1CId, projectId }
	}).then(async response => response.data)
}

export const getFileList = async (issueId: string) => {
	return await API<IFile[]>({
		url: `/v1/issues/${issueId}/attachments`,
		method: 'get'
	}).then(async response => response.data)
}

export const getFile = async (fileId: string) => {
	return await API<Blob>({
		url: `/v1/files/${fileId}/download`,
		method: 'get',
		responseType: 'blob'
	}).then(async response => response.data)
}

export const setOrdinanceIsApprove = async (id: string, state: boolean) => {
	return await API({
		url: `/v1/ordinances/${id}/approve`,
		method: 'post',
		data: { isApproved: state }
	})
}

export const addOrdinanceAttachment = async (ordinanceId: string, body: FormData, conf: any) => {
	return await API({
		url: `/v1/ordinances/${ordinanceId}/attachments`,
		method: 'post',
		data: body,
		...conf
	}).then(async response => response.status)
}
export const getOrdinanceAttachment = async (ordinanceId: string) => {
	return await API<IFileAttachment[]>({
		url: `/v1/ordinances/${ordinanceId}/attachments`,
		method: 'get'
	}).then(async response => response.data)
}

export const deleteOrdinanceAttachment = async (ordinanceId: string, fileId: string) => {
	return await API({
		url: `/v1/ordinances/${ordinanceId}/attachments/${fileId}`,
		method: 'delete'
	}).then(async response => response)
}

export const getOrdinanceStatuses = async () => {
	return await API({
		url: `/v1/ordinanceStatuses`,
		method: 'get'
	}).then(async response => response.data)
}

export const ordinanceSigning = async (ordinanceId: string, fileId: string, signDto: ISignDto) => {
	return await API({
		url: `/v1/ordinances/${ordinanceId}/attachments/${fileId}/sign`,
		method: 'post',
		data: signDto
	}).then(async response => response)
}

interface IEliminationActData {
	issueIds: string[]
}

export const addAct = async (ordinanceId: string, dto: IEliminationActData | string[], act: 'eliminationAct' | 'nonEliminationAct' | 'repeatedNonEliminationAct') => {
	return await API({
		url: `/v1/ordinances/${ordinanceId}/${act === 'eliminationAct' ? 'attachments/eliminationAct' : act === 'nonEliminationAct' ? 'nonEliminationAct' : 'repeatedNonEliminationAct'}`,
		method: 'post',
		data: dto
	}).then(async response => response)
}

export const updateOrdinance = async (ordinanceId: string, updDto: IOrdinanceUpd) => {
	return await API({
		url: `/v1/ordinances/${ordinanceId}`,
		method: 'patch',
		data: updDto
	}).then(async response => response)
}

export const getAuthors = async (projectId?: string | null) => {
	return await API({
		url: `/v1/ordinances/get-authors/${projectId}`,
		method: 'get'
	}).then(async response => response.data)
}

export const deleteOrdinance = async (id: string) => {
	return await API({
		url: `/v1/ordinances/${id}`,
		method: 'delete'
	}).then(async response => response.data)
}

export const deleteOrdinancePrintFile = async (id: string) => {
	return await API({
		url: `/v1/ordinances/${id}/attachmentsOrdinances`,
		method: 'delete'
	}).then(async response => response.data)
}

export const deleteAct = async (OrdinanceId: string, actId: string) => {
	return await API({
		url: `/v1/ordinances/${OrdinanceId}/act/${actId}`,
		method: 'delete'
	}).then(async response => response.data)
}

export const validateOrdinance = async (id: string) => {
	return await API<IValidateOrdinance>({
		url: `/v1/ordinances/validateOrdinance/${id}`,
		method: 'get'
	}).then(response => response.data)
}

export const recreateOrdinanceFile = async (id: string, attachmentId: string) => {
	return await API({
		url: `/v1/ordinances/${id}/attachments/${attachmentId}/rebuildPrintedForm`,
		method: 'post'
	})
}