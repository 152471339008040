import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons'
import { Row } from '@tanstack/table-core'
import { Row as AntRow, Button, Col, Tooltip } from 'antd'
import { useAppSelector } from 'hooks/appReduxHook'
import { memo, useCallback, useMemo } from 'react'
import { PERMISSIONS } from 'shared/constants'
import { useCheckPermissions } from 'shared/useCheckPermissions'
import { IEstimatePosition, IEstimateSection, useEstimateState } from '..'

interface IProps {
	row: IEstimateSection | IEstimatePosition
	section: Row<IEstimateSection>
	isMultiply?: boolean
	isAuthor?: boolean
}

export const EstimatePositionMove = memo(function (params: IProps) {
	const { row, section, isMultiply, isAuthor } = params
	const { container, selectedRows, changePosition } = useEstimateState()
	const { user } = useAppSelector(state => state.environment)
	const checkPermissions = useCheckPermissions()

	const canNotMoveUp =
		// useMemo(
		// 	() =>
		container &&
		((isMultiply === false
			? 'order' in row && row.order === 1
			: Object.keys(selectedRows.rows).includes(section?.originalSubRows![0]?.id!)) ||
			(!(
				typeof container?.isLocalEstimate === 'undefined' &&
				(user?.isAdmin ||
					(checkPermissions([PERMISSIONS.WbsCreator], container?.project?.id!) && isAuthor))
			) &&
				!(
					container?.isLocalEstimate === false &&
					(user?.isAdmin ||
						(checkPermissions([PERMISSIONS.LocalEstimateCreate], container?.project?.id!) &&
							isAuthor))
				)))
	// 	[container]
	// )
	const canNotMoveDown =
		// useMemo(
		// 	() =>
		container &&
		(Object.keys(selectedRows.rows).includes(
			section?.originalSubRows![section?.originalSubRows?.length! - 1]?.id!
		) ||
			('order' in row && row.order === section.original.children?.length) ||
			(!(
				typeof container?.isLocalEstimate === 'undefined' &&
				(user?.isAdmin ||
					(checkPermissions([PERMISSIONS.WbsCreator], container?.project?.id!) && isAuthor))
			) &&
				!(
					container?.isLocalEstimate === false &&
					(user?.isAdmin ||
						(checkPermissions([PERMISSIONS.LocalEstimateCreate], container?.project?.id!) &&
							isAuthor))
				)))
	// 	[container]
	// )

	const changePositionHandler = useCallback(
		(direction: 'up' | 'down') => {
			return changePosition(
				isMultiply ? row.id : section.original.id,
				isMultiply ? Object.keys(selectedRows.rows) : [row.id],
				direction,
				typeof container?.isLocalEstimate === 'undefined' ? 'wbs' : 'local'
			)
		},
		[container]
	)

	return (
		<AntRow align="middle" wrap={false}>
			<Col>
				<Tooltip title="Поднять">
					<Button
						size="small"
						type="text"
						disabled={canNotMoveUp}
						icon={<CaretUpOutlined />}
						onClick={e => {
							e.stopPropagation()
							changePositionHandler('up')
						}}
					/>
				</Tooltip>
			</Col>
			<Col>
				<Tooltip title="Опустить">
					<Button
						size="small"
						type="text"
						disabled={canNotMoveDown}
						icon={<CaretDownOutlined />}
						onClick={e => {
							e.stopPropagation()
							changePositionHandler('down')
						}}
					/>
				</Tooltip>
			</Col>
		</AntRow>
	)
})
