import { API } from "services/ApiService"
import { IWbsContainersByProjects, IWbsStatusReportContainer, IWbsStatusReportFilters } from "../model/wbs-status-report-interface"

export const WbsStatusReportApi = {
    async getWbsStatusReport({
		settings, 
		limit = 50,
		offset = 0,
		type
	} : {
		settings: IWbsStatusReportFilters
		limit: number
		offset: number
		type?: string
		} ) {
		return await API<IWbsStatusReportContainer>({
			url: `/v1/reports/${type === '1' ? 'containerAdjustmentStatuses' : 'containerStatuses'}`,
			method: 'get',
			params: { offset, limit, ...settings }
		}).then(response => response.data)
	},
	async getWbsStatusReportExcel(settings: IWbsStatusReportFilters, type: string) {
		return await API<Blob>({
			url: `/v1/export/${type === '1' ? 'exportContainerAdjustmentStatusesToExcel' : 'exportContainerStatusesToExcel'}`,
			method: 'get',
			params: { ...settings },
			responseType: 'blob'
		}).then(response => response.data)
	},
	async getWbsContainersByProjects(projectIds: string[], type: string) {
		return await API<IWbsContainersByProjects[]>({
			url: `/v1/reports/${type === '1' ? 'getAdjustmentContainersByProjects' : 'getContainersByProjects'}`,
			method: 'post',
			params: {projectId: null},
			data: {projectIds}
		}).then(response => response.data)
	},
}
