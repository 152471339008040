import { IWbsCreate } from 'entities/wbs'
import { IBase, IBaseConstructionObject } from 'interfaces/IBase'
import { IStatusRouteItem } from 'pages/unmodelPage/wbsContainer'
import { API } from 'services/ApiService'

export const WbsApi = {
	async addContainer(newContainer: IWbsCreate) {
		return await API<IBase>({
			url: '/v1/containers',
			method: 'post',
			params: {
				projectId: null
			},
			data: newContainer
		}).then(async response => response.data)
	},
	async getConstructionObjects() {
		return await API<IBaseConstructionObject[]>({
			url: '/v1/constructionObjects',
			method: 'get',
			params: {
				projectId: null
			}
		}).then(async response => response.data)
	},
	async getContainerVisas(containerId: string) {
		return await API<IStatusRouteItem[]>({
			url: `v1/containers/${containerId}/visas`,
			method: 'get',
			params: {
				projectId: null
			}
		}).then(response => response.data)
	},
	async getContainerStatus() {
		return await API({
			url: `v1/containers/containerStatusForFilter`,
			method: 'get',
			params: {
				projectId: null
			}
		}).then(response => response.data)
	},

	async statusRollup(
		id: string,
		containerStatusId: string,
		type: 'wbs' | 'local',
		isAdjustment?: boolean
	) {
		const url = isAdjustment
			? `v1/adjustment/${type === 'local' ? 'estimate' : 'container'}/${id}/unApproveAdmin`
			: `v1/${type === 'local' ? 'containerEstimates' : 'containers'}/${id}/unApproveAdmin`

		return await API({
			url,
			method: 'post',
			params: {
				projectId: null
			},
			data: { containerStatusId }
		}).then(response => response.data)
	},

	async getWbsWorkPackages(projectId: string[]) {
		return await API({
			url: `v1/containers/workPackages`,
			method: 'post',
			data: {projectIds: projectId}
		}).then(response => response.data)
	}
}
