import { useQueryClient } from '@tanstack/react-query'
import { Button, Flex, Form, Select, message } from 'antd'
import { RcFile } from 'antd/es/upload'
import { FormInstance } from 'antd/lib'
import dayjs from 'dayjs'
import { useAppSelector } from 'hooks/appReduxHook'
import { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { accessVerification } from 'shared/accessVerification'
import { PERMISSIONS } from 'shared/constants'
import { getNearestWorkingDate } from 'shared/helpers'
import { ICancelOrdinanceFine, IOrdinanceFineStatuses } from 'widgets/fine/model/fine-interface'
import { FineCardApi } from '../api/fine-card-api'
import { IFine, TFineCardType } from '../model/fine-card-interface'
import { useFineState } from '../model/fine-card-state'
import { FineCardCancelationModal } from './FineCardCancelationModal'
import { useCheckPermissions } from 'shared/useCheckPermissions'
interface IProps {
	form: FormInstance
	type: TFineCardType
	fineStatuses: IOrdinanceFineStatuses[]
	isLoading: boolean
	data?: IFine
}

export const FineCardExtra: FC<IProps> = props => {
	const navigate = useNavigate()
	const queryClient = useQueryClient()
	const { setCancelationModalView, unset } = useFineState()
	const { user, project } = useAppSelector(state => state.environment)
	const checkPermission = useCheckPermissions()
	const current = props.data
	const OrdinanceFineToRetain = checkPermission([PERMISSIONS.OrdinanceFineToRetain], project?.id)
	const OrdinanceFineToCancel = checkPermission([PERMISSIONS.OrdinanceFineToCancel], project?.id)

	const onStatusChange = (statusId: string) => {
		const status = props.fineStatuses.find(s => s.id === statusId)?.identityName?.toLowerCase()
		if (current) {
			const currentStatus = props.fineStatuses
				.find(s => s.id === current.ordinanceFineStatus?.id)
				?.identityName?.toLowerCase()
			if (currentStatus === 'new' && status === 'canceled') {
				setCancelationModalView(true)
			}
		}
	}

	const onCancelationReception = async (values: ICancelOrdinanceFine) => {
		if (props.data?.id) {
			const formData = new FormData()
			formData.append('cancelationReason', values.cancelationReason)
			values!?.files!?.map(file => formData.append('files', file.originFileObj as RcFile))
			await FineCardApi.cancel(props.data.id, formData).then(() => {
				message.success('Данные обновлены')
				unset(props.type)
				queryClient.invalidateQueries({ queryKey: ['ordinanceFine'] })
				setCancelationModalView(false)
				navigate('/control/fines')
			})
		}
	}
	const onCancelationRejection = () => {
		props.form.setFieldValue('status', current?.ordinanceFineStatus?.id)
		setCancelationModalView(false)
	}
	console.log(
		'getNearestWorkingDate',
		dayjs(getNearestWorkingDate('view', dayjs(props.data?.createdAt)), 'DD.MM.YYYY').isBefore()
	)
	return (
		<>
			<FineCardCancelationModal onSave={onCancelationReception} onCancel={onCancelationRejection} />
			<Flex gap={8}>
				{props.type !== 'create' && (
					<Form.Item
						name="status"
						className="app-drawer-form-item app-drawer-form-item__status"
						label="Статус"
					>
						<Select
							style={{ width: 256 }}
							onChange={onStatusChange}
							options={props.fineStatuses.map(item => ({
								key: item.id,
								value: item.id,
								label: item.name,
								disabled:
									(item.identityName.toLowerCase() === 'new' && !user?.isAdmin) ||
									(item.identityName.toLowerCase() === 'collected' && !OrdinanceFineToRetain) ||
									(item.identityName.toLowerCase() === 'canceled' &&
										(dayjs(
											getNearestWorkingDate('view', dayjs(props.data?.createdAt)),
											'DD.MM.YYYY'
										).isBefore() ||
											!OrdinanceFineToCancel))
							}))}
						/>
					</Form.Item>
				)}
				<Button
					loading={props.isLoading}
					disabled={
						!(
							(current?.ordinanceFineStatus?.identityName === 'Collected' &&
								OrdinanceFineToRetain) ||
							(current?.ordinanceFineStatus?.identityName === 'Canceled' &&
								OrdinanceFineToCancel) ||
							checkPermission([PERMISSIONS.OrdinanceFineCreate], project?.id) ||
							user?.isAdmin
						)
					}
					htmlType="submit"
					onClick={() => props.form.submit()}
				>
					Сохранить
				</Button>
			</Flex>
		</>
	)
}
