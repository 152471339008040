import { DatabaseOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import { FC, useState } from 'react'
import ReportsModal from './reports-modal'

export const Reports: FC = () => {
	const [show, setShow] = useState(false)
	return (
		<>
			<ReportsModal show={show} onClose={() => setShow(false)} />
			<Button type={'primary'} icon={<DatabaseOutlined />} onClick={() => setShow(true)}>
				Создать отчёт
			</Button>
		</>
	)
}
