import { Card, Descriptions, Form, FormInstance } from 'antd'
import { DescriptionsItemType } from 'antd/es/descriptions'
import { IIssueCard } from 'entities/issues'
import { TIssueDetailForm } from 'entities/issues/model/issue-card-schema'
import { useAppSelector } from 'hooks/appReduxHook'
import { IContractor } from 'interfaces/IEnvironment'
import { uniqBy } from 'lodash'
import { FC, useEffect, useState } from 'react'
import { ISSUE_STATUS, ISSUE_TYPES } from 'shared/constants/issue-types'
import {
	useIssueContactPersons,
	useIssueContractors,
	useIssueContracts
} from '../model/issue-query'
import { IssueFormItem } from './IssueFormItem'
import { IssueFormSelectorItem } from './IssueFormSelectorItem'

interface IProps {
	canEdit: boolean
	dataSource?: IIssueCard | null
	formValues: TIssueDetailForm
	form: FormInstance<TIssueDetailForm>
}

export const IssueCardDetailsContractor: FC<IProps> = ({
	canEdit,
	dataSource,
	formValues,
	form
}) => {
	const currentProjectId = useAppSelector(state => state.environment.project?.id)
	const { data: contractors, isFetching: contractorsFetching } = useIssueContractors()
	const { data: contracts, isFetching: contractsFetching } = useIssueContracts(
		formValues?.contractorId,
		dataSource?.projectId ?? currentProjectId
	)

	const [subContractors, setSubContractors] = useState<IContractor[]>([])
	const { data: contactPersons, isFetching: contactPersonsFetching } = useIssueContactPersons(
		formValues?.contractorId
	)

	useEffect(() => {
		const elements = uniqBy(
			contracts
				?.filter(c => c.contractor?.id === formValues?.contractorId)
				?.flatMap(c => (c.subContractor === null ? c.contractor : c?.subContractor)),
			'id'
		)
		setSubContractors(elements[0] !== null ? elements : [])
	}, [formValues?.contractorId, contracts])

	useEffect(() => {
		if (subContractors.length === 1) form.setFieldValue('subContractor', subContractors[0].id)
	}, [subContractors])

	useEffect(() => {
		if (contracts?.length === 1) form.setFieldValue('contract', contracts[0].id)
	}, [contracts])

	const items: DescriptionsItemType[] = [
		{
			key: 'contractor',
			children: (
				<IssueFormSelectorItem
					canEdit={canEdit}
					options={contractors}
					label="Генподрядчик"
					name="contractorId"
					rules={[{ required: true, message: 'Укажите генподрядчика' }]}
					dataSourceValue={dataSource?.contractor?.name}
					isLoading={contractorsFetching}
					form={form}
				/>
			)
		},
		{
			key: 'subContractor',
			children: (
				<IssueFormSelectorItem
					disabled={typeof formValues?.contractorId === 'undefined' || subContractors?.length === 0}
					canEdit={canEdit}
					options={subContractors}
					label="Субподрядчик"
					name="subcontractorId"
					rules={[{ required: true, message: 'Укажите субподрядчика' }]}
					dataSourceValue={dataSource?.subcontractor?.name}
					isLoading={contractorsFetching}
					form={form}
				/>
			)
		},
		{
			key: 'contract',
			children: (
				<IssueFormSelectorItem
					disabled={typeof formValues?.contractorId === 'undefined' || contracts?.length === 0}
					canEdit={canEdit}
					options={contracts}
					label="Договор"
					name="contractId"
					rules={[
						{
							required: formValues?.issueTypeId !== ISSUE_TYPES.acceptance,
							message: 'Укажите договор'
						}
					]}
					dataSourceValue={dataSource?.contract?.name}
					isLoading={contractsFetching}
					form={form}
				/>
			)
		},
		{
			key: 'contactPerson',
			children: (
				<IssueFormSelectorItem
					disabled={typeof formValues?.contractorId === 'undefined' || contracts?.length === 0}
					canEdit={canEdit}
					options={contactPersons}
					label="Контактное лицо"
					name="contactPersonId"
					rules={[
						{
							required: formValues?.issueTypeId !== ISSUE_TYPES.acceptance,
							message: 'Укажите контактное лицо'
						}
					]}
					dataSourceValue={dataSource?.contactPerson?.name}
					isLoading={contactPersonsFetching}
					form={form}
				/>
			)
		},
		{
			key: 'response',
			children: dataSource?.id && (
				<Form.Item label="Ответ">
					<span className="app-form-text">
						{dataSource?.response?.answer ?? (
							<i>Ответственный за устранение пока не ответил на замечание</i>
						)}
					</span>
				</Form.Item>
			)
		},
		{
			key: 'reasonDescription',
			children: (form.getFieldValue('issueStatusId') === ISSUE_STATUS.cancel ||
				(dataSource?.cancellationReason?.reasonDescription !== null &&
					dataSource?.cancellationReason?.reasonDescription !== undefined)) && (
				<IssueFormItem
					canEdit={canEdit}
					label="Причина отмены"
					name="reasonDescription"
					dataSourceValue={dataSource?.cancellationReason?.reasonDescription}
					rules={[
						{
							required: true,
							message: 'Укажите причину отмены'
						}
					]}
				/>
			)
		}
	]
	return (
		<Card title="Подрядчик" className="app-work-card" classNames={{ body: 'app-work-card__body' }}>
			<Descriptions
				items={items}
				size="small"
				column={1}
				colon={false}
				labelStyle={{ display: 'none' }}
				className={'app-drawer-descriptions'}
				bordered
			/>
		</Card>
	)
}
