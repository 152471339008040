import { useAppSelector } from 'hooks/appReduxHook'
import { useCallback } from 'react'
import { PERMISSIONS } from 'shared/constants'
import { CHECKLIST_STATUS, TEMPLATE_TYPE } from 'shared/constants/checklist-types'
import { useCheckPermissions } from 'shared/useCheckPermissions'
import { useShallow } from 'zustand/react/shallow'
import { useChecklistCardState } from './checklist-card-state'

export const useCheckDisabledStatuses = (currentStatus?: CHECKLIST_STATUS) => {
	const { user: currentUser, project } = useAppSelector(state => state.environment)
	const { templateType, assignedTo, ownerId } = useChecklistCardState(
		useShallow(state => ({
			templateType: state.templateType,
			ownerId: state.initForm.ownerId,
			assignedTo: state.initForm.assignedTo
		}))
	)
	const checkPermissions = useCheckPermissions()

	return useCallback(
		(status: CHECKLIST_STATUS, isUserAssigned: boolean): boolean => {
			console.log(
				'isUserAssigned',
				assignedTo?.some(user => user === currentUser?.id)
			)
			switch (true) {
				case currentUser?.isAdmin:
					return false

				case status === CHECKLIST_STATUS.Canceled &&
					((templateType === TEMPLATE_TYPE.ndc &&
						checkPermissions(
							[
								PERMISSIONS.ChecklistsCancelUksNdc,
								PERMISSIONS.ChecklistsCreateUksNdc,
								PERMISSIONS.ChecklistsProcessingUksNdc
							],
							project?.id
						)) ||
						(templateType === TEMPLATE_TYPE.ukkc &&
							checkPermissions(
								[
									PERMISSIONS.ChecklistsCancelUkKc,
									PERMISSIONS.ChecklistsCreateUkKc,
									PERMISSIONS.ChecklistsProcessingUkKc
								],
								project?.id
							))) &&
					(currentUser?.id === ownerId ||
						isUserAssigned ||
						assignedTo?.some(user => user === currentUser?.id)):
					return false

				case status === CHECKLIST_STATUS.Warranty &&
					templateType === TEMPLATE_TYPE.ukkc &&
					checkPermissions([PERMISSIONS.ChecklistsAcceptedUnderWarranty], project?.id) &&
					(currentStatus === CHECKLIST_STATUS.New || currentStatus === CHECKLIST_STATUS.InProgress):
					return false

				default:
					return true
			}
		},
		[currentStatus]
	)
}

export const useChecklistCanProcessing = () => {
	const { user: currentUser, project } = useAppSelector(state => state.environment)
	const { templateType, assignedTo } = useChecklistCardState(
		useShallow(state => ({
			templateType: state.templateType,
			assignedTo: state.initForm.assignedTo
		}))
	)
	const checkPermissions = useCheckPermissions()
	return useCallback(
		() =>
			currentUser?.isAdmin ||
			(assignedTo?.includes(currentUser?.id!) &&
				((templateType === TEMPLATE_TYPE.ndc &&
					checkPermissions([PERMISSIONS.ChecklistsProcessingUksNdc], project?.id)) ||
					(templateType === TEMPLATE_TYPE.ukkc &&
						checkPermissions([PERMISSIONS.ChecklistsProcessingUkKc], project?.id)))),
		[templateType, assignedTo]
	)
}

type checklistField =
	| 'assignedTo'
	| 'capacity'
	| 'cipher'
	| 'commissionOrganization'
	| 'contractor'
	| 'description'
	| 'doorSections'
	| 'factDate'
	| 'location'
	| 'plannedDate'
	| 'subContractor'
	| 'title'
	| 'transferAct'
	| 'workPackage'

export const useCanChecklistEdit = () => {
	const { user: currentUser, project } = useAppSelector(state => state.environment)
	const { templateType, ownerId } = useChecklistCardState(
		useShallow(state => ({
			templateType: state.templateType,
			ownerId: state.initForm.ownerId
		}))
	)
	const checkPermissions = useCheckPermissions()
	return useCallback(
		(field: checklistField) => {
			switch (field) {
				case 'assignedTo':
					return (
						currentUser?.isAdmin ||
						currentUser?.id === ownerId ||
						(templateType === TEMPLATE_TYPE.ndc &&
							checkPermissions([PERMISSIONS.ChecklistsEditAssignedToUkNdk], project?.id)) ||
						(templateType === TEMPLATE_TYPE.ukkc &&
							checkPermissions([PERMISSIONS.ChecklistsEditAssignedToUkKc], project?.id))
					)

				case 'transferAct':
					return (
						currentUser?.isAdmin ||
						(currentUser?.id === ownerId &&
							checkPermissions([PERMISSIONS.CreatePrintFormChecklistUKKc], project?.id))
					)

				case 'plannedDate':
					return (
						currentUser?.isAdmin ||
						currentUser?.id === ownerId ||
						checkPermissions([PERMISSIONS.ChecklistPlanedDate], project?.id)
					)

				case 'factDate':
					return currentUser?.isAdmin ?? false

				case 'commissionOrganization':
				case 'doorSections':
				case 'cipher':
				case 'capacity':
				case 'contractor':
				case 'description':
				case 'location':
				case 'subContractor':
				case 'title':
				case 'workPackage':
					return (
						currentUser?.isAdmin ||
						(currentUser?.id === ownerId &&
							((templateType === TEMPLATE_TYPE.ndc &&
								checkPermissions([PERMISSIONS.ChecklistsEditUksNdc], project?.id)) ||
								(templateType === TEMPLATE_TYPE.ukkc &&
									checkPermissions([PERMISSIONS.ChecklistsEditUkKc], project?.id))))
					)
				default:
					return false
			}
		},
		[ownerId]
	)
}

export const useChecklistCanSave = () => {
	const { user: currentUser, project } = useAppSelector(state => state.environment)
	const { templateType, ownerId } = useChecklistCardState(
		useShallow(state => ({
			templateType: state.templateType,
			ownerId: state.initForm.ownerId
		}))
	)
	const checkPermissions = useCheckPermissions()
	return useCallback(
		() =>
			currentUser?.isAdmin ||
			(currentUser?.id === ownerId &&
				((templateType === TEMPLATE_TYPE.ndc &&
					checkPermissions(
						[
							PERMISSIONS.ChecklistsEditUksNdc,
							PERMISSIONS.ChecklistsEditAssignedToUkNdk,
							PERMISSIONS.ChecklistPlanedDate
						],
						project?.id
					)) ||
					(templateType === TEMPLATE_TYPE.ukkc &&
						checkPermissions(
							[
								PERMISSIONS.ChecklistsEditUkKc,
								PERMISSIONS.ChecklistsEditAssignedToUkKc,
								PERMISSIONS.ChecklistPlanedDate
							],
							project?.id
						)))) ||
			(templateType === TEMPLATE_TYPE.ndc &&
				checkPermissions(
					[PERMISSIONS.ChecklistsEditAssignedToUkNdk, PERMISSIONS.ChecklistPlanedDate],
					project?.id
				)) ||
			(templateType === TEMPLATE_TYPE.ukkc &&
				checkPermissions(
					[PERMISSIONS.ChecklistsEditAssignedToUkKc, PERMISSIONS.ChecklistPlanedDate],
					project?.id
				)),
		[templateType, currentUser, ownerId]
	)
}

export const useChecklistCanAddFiles = () => {
	const { user: currentUser, project } = useAppSelector(state => state.environment)
	const { templateType, initForm } = useChecklistCardState(
		useShallow(state => ({
			templateType: state.templateType,
			initForm: state.initForm
		}))
	)
	const checkPermissions = useCheckPermissions()

	const owner = currentUser?.id === initForm.ownerId
	const assigned = initForm.assignedTo?.some(x => x === currentUser?.id) ?? false

	return useCallback(
		() =>
			currentUser?.isAdmin ||
			(owner &&
				((templateType === TEMPLATE_TYPE.ndc &&
					checkPermissions([PERMISSIONS.AttachFilesForChecklistUksNdc], project?.id)) ||
					(templateType === TEMPLATE_TYPE.ukkc &&
						checkPermissions([PERMISSIONS.AttachFilesForChecklistUkKc], project?.id)))) ||
			(assigned &&
				((templateType === TEMPLATE_TYPE.ndc &&
					checkPermissions(
						[PERMISSIONS.AttachFilesForChecklistUksNdc, PERMISSIONS.ChecklistsProcessingUksNdc],
						project?.id,
						true
					)) ||
					(templateType === TEMPLATE_TYPE.ukkc &&
						checkPermissions(
							[PERMISSIONS.AttachFilesForChecklistUkKc, PERMISSIONS.ChecklistsProcessingUkKc],
							project?.id,
							true
						)))),

		[templateType, currentUser, initForm]
	)
}
