import axios, { AxiosError } from 'axios'
import { IProject } from 'interfaces/IEnvironment'
import IToken from 'interfaces/IToken'
import { AppExit } from './AuthService'

interface IFailedQueue {
	resolve: (reason?: any) => void
	reject: (value: IToken | PromiseLike<IToken> | AxiosError) => void
}

const checkLocation = (host: string) => {
	if (host.includes('localhost')) {
		return window.location.protocol + '//' + window.location.host.replace(':81', '') + ':5000'
	}
	return window.location.protocol + '//' + window.location.host + '/api'
}

export const BASEURL = checkLocation(window.location.host)

export const API = axios.create({
	baseURL: BASEURL,
	headers: {
		Accept: 'application/json'
	}
})

API.interceptors.request.use(
	async config => {
		const project = localStorage.getItem('project')
		const currentProject: IProject | null = project ? JSON.parse(project) : null
		const setProject =
			config.url?.includes('legged3-authenticate') ||
			config.url?.includes('issueTypes') ||
			config.url?.includes('v1/issueSubTypes') ||
			config.url?.includes('contracts') ||
			config.url?.includes('authors') ||
			// config.url?.includes('unmodel') ||
			// config.url?.includes('local') ||
			// config.url?.includes('adjustment') ||
			// config.url?.includes('wbs-tasks') ||
			(config.url?.includes('contractors') && config!?.params!?.projectId === null) ||
			(config.url?.includes('projects') && config!?.params!?.projectId === null) ||
			(config.url?.includes('buildingPermits') && config!?.params!?.project1CId === null) ||
			(config.url?.includes('contracts') && config!?.params!?.project1CId === null)
		if (!setProject && currentProject !== null) {
			config.params = {
				...config.params,
				projectId:
					typeof config.params!?.projectId! !== 'undefined'
						? config!?.params!?.projectId!
						: currentProject!?.id!
			}
		}
		const token = localStorage.getItem('absToken')
		const parsedToken: IToken | null = token ? JSON.parse(token) : null
		if (parsedToken !== null) {
			config.headers.Authorization = `Bearer ${parsedToken!?.accessToken!}`
		}

		config.paramsSerializer = {
			indexes: true
		}

		return config
	},
	error => {
		console.log('request:', error.response)
	}
)

let isRefreshing = false
let failedQueue: IFailedQueue[] = []

const processQueue = (error: AxiosError | null, token: IToken | null = null) => {
	failedQueue.forEach(prom => {
		if (error) {
			prom.reject(error)
		} else {
			prom.resolve(token)
		}
	})

	failedQueue = []
}

API.interceptors.response.use(
	function (response) {
		return response
	},
	async function (error) {
		const originalRequest = error.config
		if (error!?.response!?.status === 401 && !originalRequest!?._retry) {
			if (isRefreshing) {
				try {
					const token: IToken = await new Promise(function (resolve, reject) {
						failedQueue.push({ resolve, reject })
					})
					originalRequest.headers['Authorization'] = 'Bearer ' + token!?.accessToken!
					return await axios(originalRequest)
				} catch (err) {
					AppExit()
					return err
				}
			}

			originalRequest._retry = true
			isRefreshing = true

			const token = localStorage.getItem('absToken')
			const parsedToken: IToken = token && JSON.parse(token)

			return new Promise(function (resolve, reject) {
				axios({
					baseURL: BASEURL,
					url: '/v1/refresh-token',
					headers: {
						Accept: 'application/json',
						Authorization: `Bearer ${parsedToken.accessToken}`
					},
					method: 'post',
					data: { ...parsedToken }
				})
					.then(({ data }: { data: IToken }) => {
						localStorage.setItem('absToken', JSON.stringify(data))
						API.defaults.headers.common['Authorization'] = 'Bearer ' + data!?.accessToken!
						originalRequest.headers['Authorization'] = 'Bearer ' + data!?.accessToken!
						processQueue(null, data)
						resolve(axios(originalRequest))
					})
					.catch(err => {
						processQueue(err, null)
						reject(err)
						AppExit()
					})
					.then(() => {
						isRefreshing = false
					})
			})
		}

		return Promise.reject(error)
	}
)
