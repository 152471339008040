import { useQuery } from "@tanstack/react-query"
import { NotificationsApi } from "../api/wbs-notification-api"
import { useNotificationsState } from "./wbs-notification-state"
import { getUserInfoForMainPage } from "services/AuthService"

export const useNotificationsQuery = () => {
	const { limit, currentPage, setTotalItems, setTotalPages, filters } = useNotificationsState()

	return useQuery({
		queryKey: ['wbsNotifications', currentPage, filters],
		refetchOnWindowFocus: false,
		queryFn: async () => {
			const notifications = await NotificationsApi.get({
				filters,
                limit: limit,
				offset: currentPage === 1 ? 0 : currentPage * limit - limit
			})
			setTotalItems(notifications!.totalItems)
			setTotalPages(notifications!.totalPages)
				return notifications.data
		}
	})
}

export const useNotificationTypesListQuery = (notificationObjects?: number[]) => {
	return useQuery({
		queryKey: ['wbsNotificationTypesList', notificationObjects],
		refetchOnWindowFocus: false,
		queryFn: async () => {
			return await NotificationsApi.getNotificationTypesList(notificationObjects)
		}
	})
}

export const useWbsListQuery = (projectIds: string[]) => {
	return useQuery({
		queryKey: ['wbsList', projectIds],
		refetchOnWindowFocus: false,
		queryFn: async () => {
				if (projectIds) {
					return await NotificationsApi.getWbsList(projectIds)
				} else return null
		}
	})
}

export const useUserAllInfo = () => {
	return useQuery({
		queryKey: ['user', 'allInfo'],
		refetchOnWindowFocus: false,
		queryFn: async () => {
				return await getUserInfoForMainPage().then(data => data?.projects)
		},
	})
}
