import { create } from "zustand"
import { Author } from "./diagrams-project-types"
import type { Dayjs } from 'dayjs'
import { ICheckListCount, IContractor, IIssueCount, IIssueSubTypes, IOrdinanceCount } from "widgets/diagrams/model/diagrams-types"
import dayjs from "dayjs"

interface IState {
  authors: string[] | null
  projectId?: string | null
  contractorIds: string[] | null
  plannedDate: Dayjs
  ordinance: IOrdinanceCount | undefined
  checklistData: ICheckListCount | undefined
  issue: IIssueCount | undefined
  issueTypeData: IIssueSubTypes[] | undefined
  // isInitialLoad: boolean
}

interface IStateAction {
  setFilters: (filters: Partial<IState>) => void
}

const initialState: IState = {
  authors: null,
  projectId: null,
  contractorIds: null,
  plannedDate: dayjs(),
  ordinance: undefined,
  checklistData: undefined,
  issue: undefined,
  issueTypeData: undefined,
  // isInitialLoad: false
}

export const useDiagramsProjectState = create<IState & IStateAction>((set) => ({
  ...initialState,
  setFilters: (payload) => set((state) => ({ ...state, ...payload })),
}))
