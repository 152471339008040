import {
	AppstoreAddOutlined,
	CheckSquareOutlined,
	CopyOutlined,
	DeleteOutlined,
	ExportOutlined,
	PlusSquareOutlined
} from '@ant-design/icons'
import { Row } from '@tanstack/react-table'
import { Button, Dropdown, Tooltip } from 'antd'
import { useAppSelector } from 'hooks/appReduxHook'
import { FC, useMemo } from 'react'
import { findNestedObj } from 'shared/helpers'
import { useEstimatePositionState } from 'widgets/estimate-position-card'
import { useWbsPermits } from 'widgets/wbs'
import { useShallow } from 'zustand/react/shallow'
import { useEstimateState } from '..'
import { IEstimateSection } from '../model/estimate-schema'
import { CONTAINER_STATUS } from 'shared/constants'
import { useContainerVisas } from 'widgets/wbs/model/wbs-queries'
import { IStatusRouteItemVisa } from 'pages/unmodelPage/wbsContainer'
import { IBase } from 'interfaces/IBase'

interface IProps {
	row: Row<IEstimateSection>
	visaIssued: boolean
}

export const EstimatePositionSectionMenu: FC<IProps> = params => {
	const { row, visaIssued } = params
	const { user: currentUser } = useAppSelector(state => state.environment)
	const {
		container,
		selectedRows,
		removeSectionId,
		toRemove,
		showRemoveModal,
		expandAllChildren,
		selectAllChildren,
		copyEstimatePosition,
		updateSections,
		sections,
		canDelete
	} = useEstimateState(
		useShallow(state => ({
			container: state.container,
			selectedRows: state.selectedRows,
			removeSectionId: state.removeSectionId,
			toRemove: state.toRemove,
			showRemoveModal: state.showRemoveModal,
			expandAllChildren: state.expandAllChildren,
			selectAllChildren: state.selectAllChildren,
			copyEstimatePosition: state.copyEstimatePosition,
			updateSections: state.updateSections,
			sections: state.sections,
			canDelete: state.canDelete
		}))
	)
	const wbsPermissions = useWbsPermits(container?.project?.id)
	const isLocalEstimate = typeof container?.isLocalEstimate !== 'undefined'
	const canEditCorrectLocalEstimate =
		container?.isLocalEstimate === false && wbsPermissions.canLocalEdit

	const isAuthor =
		(currentUser?.isAdmin ||
			container?.author.id === currentUser?.id ||
			container?.redactors.some(r => r.id === currentUser?.id)) ??
		false

	const canEdit =
		currentUser?.isAdmin ||
		((container?.status.name === CONTAINER_STATUS.new ||
			container?.status.name === CONTAINER_STATUS.preparation) &&
			((!isLocalEstimate && isAuthor && wbsPermissions.canEdit) ||
				(canEditCorrectLocalEstimate && isAuthor)))

	const positionEstimates: any = findNestedObj<IEstimateSection>(
		sections,
		'id',
		'containerSectionId' in row.original ? row.original.containerSectionId : null
	)?.children

	return (
		<Dropdown
			disabled={!row.original.hasTechnologies && !row.original.hasPositions}
			overlayStyle={{ width: '256px' }}
			trigger={['click']}
			menu={{
				items: [
					{
						label: 'Добавить',
						key: 'add',
						icon: <AppstoreAddOutlined />,
						disabled:
							container?.ready ||
							container?.isLocalEstimate ||
							!canEdit ||
							row.original.hasTechnologies === false ||
							visaIssued,
						onClick: e => {
							e.domEvent.stopPropagation()
							e.domEvent.preventDefault()
							if (container) {
								useEstimatePositionState.setState({
									show: true,
									containerId: container.id,
									section: row.original
								})
							}
						}
					},
					{
						label: row.subRows.some(r => r.getIsSelected()) ? 'Снять выбор' : 'Выбрать всё',
						key: 'selectAll',
						icon: <CheckSquareOutlined />,
						disabled:
							!row.getIsExpanded() ||
							container?.ready ||
							container?.isLocalEstimate ||
							!canEdit ||
							!row.original.hasPositions ||
							visaIssued,
						onClick: e => {
							e.domEvent.stopPropagation()
							e.domEvent.preventDefault()
							selectAllChildren(row)
						}
					},
					{
						label: row.subRows.some(r => r.getIsExpanded()) ? 'Свернуть всё' : 'Развернуть всё',
						key: 'expand',
						icon: <ExportOutlined />,
						disabled: !row.getIsExpanded() || !row.original.hasPositions,
						onClick: e => {
							e.domEvent.stopPropagation()
							e.domEvent.preventDefault()
							expandAllChildren(row)
						}
					},
					{
						label: (
							<Tooltip
								zIndex={999999}
								placement="right"
								title={
									Object.keys(selectedRows).length === 0 && 'Выберете позиции сметы для удаления'
								}
							>
								Удалить
							</Tooltip>
						),
						key: 'delete',
						danger: true,
						disabled:
							container?.ready ||
							container?.isLocalEstimate ||
							!canEdit ||
							!(row.getIsSomeSelected() || row.getIsAllSubRowsSelected()) ||
							(canDelete === false && container?.isLocalEstimate === false) ||
							row.original.hasPositions === false ||
							visaIssued,
						icon: <DeleteOutlined />,
						onClick: e => {
							e.domEvent.stopPropagation()
							useEstimateState.setState({
								showRemoveModal: true,
								toRemove: selectedRows.rows,
								removeSectionId: row.original.id
							})
						}
					},
					{
						label: (
							<Tooltip
								zIndex={999999}
								placement="right"
								title={
									Object.keys(selectedRows).length === 0 && 'Выберете позиции сметы для копирования'
								}
							>
								Копировать
							</Tooltip>
						),
						key: 'copy',
						disabled:
							container?.ready ||
							container?.isLocalEstimate ||
							!canEdit ||
							!(row.getIsSomeSelected() || row.getIsAllSubRowsSelected()) ||
							row.original.hasTechnologies === false ||
							!!row.subRows?.find(
								r =>
									r.getIsSelected() &&
									'technologyIsDeleted' in r.original &&
									!!r.original.technologyIsDeleted
							) ||
							visaIssued,
						icon: <CopyOutlined />,
						onClick: e => {
							e.domEvent.stopPropagation()
							copyEstimatePosition(
								Object.keys(selectedRows.rows).map(s => s),
								row.original.id,
								typeof container?.isLocalEstimate === 'undefined' ? 'wbs' : 'local'
							)
						}
					}
				]
			}}
		>
			<Tooltip
				title={
					!row.original.hasTechnologies && !row.original.hasPositions && 'В разделе нет технологий'
				}
			>
				<Button
					data-attr="sectionActions"
					type="text"
					size="small"
					icon={
						<PlusSquareOutlined
							style={{
								color:
									row.original.hasTechnologies || row.original.hasPositions
										? '#0f8bfd'
										: 'lightgray'
							}}
						/>
					}
					onClick={e => {
						e.stopPropagation()
					}}
				/>
			</Tooltip>
		</Dropdown>
	)
}
