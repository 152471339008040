import { IBase } from 'interfaces/IBase'
import { create } from 'zustand'

interface IEstimatePositionCommentsState {
	show: boolean
	currentId: string | null
	sectionId: string | null
}

export interface IEstimatePositionComments {
	containerSectionId: string
	estimatePositionTitle: string
	estimatePositionsComments: IEstimatePositionComment[]
	mainAuthor?: IBase
	isCommentsClosed: boolean
	pathContainerSection?: { [x: string]: boolean }
}

export interface IEstimatePositionComment {
	id: string
	author: { id: string; name: string }
	appealTo: { id: string; name: string }
	createdAt: Date
	comment: string
}
export interface IEstimatePositionCommentsUser {
	id: string
	firstName: string
	lastName: string
	fullName: string
	closeComment: boolean
	roles: IRolesCommentsUser[]
}

export interface IRolesCommentsUser {
	id: string
	name: string
	prefix?: string
	color?: string
}
export interface IWbsCommentDto {
	comment: string
	appealTo: string
}

const initCommentsState = {
	show: false,
	currentId: null,
	sectionId: null
}

export const useEstimatePositionCommentsState = create<IEstimatePositionCommentsState>(set => ({
	...initCommentsState
}))
