import { DollarCircleOutlined } from '@ant-design/icons'
import { Button, Card, Col, Row } from 'antd'
import { FC, useState } from 'react'
import { PERMISSIONS } from 'shared/constants'
import { useCheckCommonPermissions } from 'shared/useCheckPermissions'
import { HOCFineReportCreateModal } from 'widgets/reports/HOCFineReport/ui/HOCFineReportCreateModal'
import { HOCReportCreate } from './hoc-report-create'
import { HOCReportTable } from './hoc-report-table'

interface IProps {}

export const HOCReport: FC<IProps> = () => {
	const checkPermissions = useCheckCommonPermissions()

	const [showHOCFineReportModal, setShowHOCFineReportModal] = useState(false)

	return (
		<>
			<HOCFineReportCreateModal
				show={showHOCFineReportModal}
				onClose={() => setShowHOCFineReportModal(false)}
				download
			/>
			<Card>
				<Row justify="space-between">
					<Col>
						<Row gutter={8}>
							<Col>
								<HOCReportCreate />
							</Col>
							<Col>
								<HOCReportCreate download />
							</Col>
							<Col>
								<Button
									icon={<DollarCircleOutlined />}
									disabled={!checkPermissions([PERMISSIONS.ReportHOCFine])}
									size="middle"
									onClick={() => setShowHOCFineReportModal(true)}
								>
									Отчет РСО/Штрафы
								</Button>
							</Col>
						</Row>
					</Col>
					{/* {!!data.length && (
						<Col>
							<Button danger onClick={() => dispatch(resetHocReport())}>
								Сбросить
							</Button>
						</Col>
					)} */}
				</Row>
				<HOCReportTable />
			</Card>
		</>
	)
}
