import { EditOutlined, SaveOutlined, StopOutlined } from '@ant-design/icons'
import { useQueryClient } from '@tanstack/react-query'
import { Button, Col, Form, message, Row, Select, Table, Tag, Tooltip } from 'antd'
import type { ColumnsType } from 'antd/lib/table'
import { AxiosError } from 'axios'
import { useAppSelector } from 'hooks/appReduxHook'
import { useAsmTypeSections } from 'hooks/useAsm'
import { useWorkPackages } from 'hooks/useWorkPackage'
import { IErrorDetail } from 'interfaces/IBase'
import { FC, useCallback, useState } from 'react'
import { IWorkPackage, workPackageService } from 'services/workPackageService'

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
	record: IWorkPackage
	dataIndex: string
	colType: 'issueTypes' | 'typeSections'
	editing: boolean
	children: React.ReactNode
}

const EditableCell: FC<EditableCellProps> = ({ colType, editing, children, ...restProps }) => {
	const { issueTypes } = useAppSelector(state => state.environment)
	const { data: typeSections } = useAsmTypeSections()
	return (
		<td {...restProps}>
			{editing ? (
				<Form.Item name={colType} style={{ margin: 0 }}>
					<Select
						mode="multiple"
						filterOption={(input, option) =>
							(option?.label ?? '').toLowerCase().includes(input.toLowerCase())
						}
						filterSort={(optionA, optionB) =>
							(optionA?.label ?? '')
								.toLowerCase()
								.localeCompare((optionB?.label ?? '').toLowerCase())
						}
						tagRender={item => (
							<Tag
								closable
								onClose={item.onClose}
								color={item.label === item.value ? 'red' : '#1677ff'}
								key={item.value}
							>
								{item.label === item.value ? 'Не актуальное значение' : item.label}
							</Tag>
						)}
						options={
							colType === 'issueTypes'
								? issueTypes?.map(o => ({
										key: o.id,
										value: o.id,
										label: o.name
								  }))
								: typeSections?.map(ts => ({
										key: ts.id,
										value: ts.id,
										label: ts.name
								  }))
						}
					/>
				</Form.Item>
			) : (
				children
			)}
		</td>
	)
}

export const WorkPackagesGuide: FC = () => {
	const { issueTypes } = useAppSelector(state => state.environment)
	const queryClient = useQueryClient()
	const { data, isFetching } = useWorkPackages()
	const [workPackageForm] = Form.useForm()
	const [editingKey, setEditingKey] = useState('')
	const isEditing = (record: IWorkPackage) => record.id === editingKey

	const isActual = useCallback(
		(itemId: string) => !!issueTypes?.find(type => type.id === itemId),
		[issueTypes]
	)

	const columns: ColumnsType<IWorkPackage> = [
		{
			dataIndex: 'code',
			title: '№',
			align: 'center',
			width: 24
		},
		{
			dataIndex: 'name',
			title: 'Наименование'
		},
		{
			dataIndex: 'issueTypes',
			title: 'Типы замечаний',
			width: 500,
			onCell: record => ({
				title: 'Типы замечаний',
				record,
				colType: 'issueTypes',
				editing: isEditing(record)
			}),
			render: (_, record) =>
				record.issueTypes
					.filter(it => !it.isDeleted)
					.map(it => (
						<Tag
							color={isActual(it.id) ? '#1677ff' : 'red'}
							style={{
								fontSize: 14,
								padding: 4,
								margin: 3,
								color: isActual(it.id) ? '#f0f0f0' : '#cf1322'
							}}
						>
							{it.name}
						</Tag>
					))
		},
		{
			dataIndex: 'typeSections',
			title: 'Виды разделов',
			width: 500,
			onCell: record => ({
				title: 'Виды разделов',
				record,
				colType: 'typeSections',
				editing: isEditing(record)
			}),
			render: (_, record) =>
				record.typeSections?.map(it => (
					<Tag
						color={'#1677ff'}
						style={{
							fontSize: 14,
							padding: 4,
							margin: 3,
							color: '#f0f0f0'
						}}
					>
						{it.name}
					</Tag>
				))
		},
		{
			width: 64,
			align: 'center',
			render: (_: any, record: IWorkPackage) => {
				const editable = isEditing(record)
				return editable ? (
					<Row gutter={2} wrap={false}>
						<Col>
							<Tooltip title="Сохранить">
								<Button
									icon={<SaveOutlined />}
									type="primary"
									onClick={() => save(record)}
									style={{ marginRight: 8 }}
								/>
							</Tooltip>
						</Col>
						<Col>
							<Tooltip title="Отмена">
								<Button
									icon={<StopOutlined />}
									onClick={() => cancel()}
									style={{ marginRight: 8 }}
								/>
							</Tooltip>
						</Col>
					</Row>
				) : (
					<Tooltip title="Редактировать">
						<Button
							icon={<EditOutlined />}
							disabled={editingKey !== ''}
							onClick={() => edit(record)}
						/>
					</Tooltip>
				)
			}
		}
	]

	const save = async (data: IWorkPackage) => {
		const dto = {
			issueTypesId: workPackageForm.getFieldValue('issueTypes'),
			typeSectionsId: workPackageForm.getFieldValue('typeSections')
		}
		await workPackageService
			.setIssueTypes(data.id, dto)
			.then(() => {
				queryClient.invalidateQueries({ queryKey: ['workPackages'] })
				cancel()
			})
			.catch((err: AxiosError<IErrorDetail>) =>
				message.error(err.response?.data.detail ?? 'Произошла ошибка.')
			)
	}

	const edit = (record: Partial<IWorkPackage>) => {
		workPackageForm.setFieldsValue({
			issueTypes: record.issueTypes?.flatMap(item => item.id),
			typeSections: record.typeSections?.flatMap(item => item.id)
		})
		setEditingKey(record.id!)
	}

	const cancel = () => {
		setEditingKey('')
	}

	return (
		<div className="dashboard__block" style={{ borderRadius: '0 0 .8rem .8rem' }}>
			<Form form={workPackageForm} component={false}>
				<Table
					dataSource={data}
					columns={columns}
					loading={isFetching}
					components={{
						body: {
							cell: EditableCell
						}
					}}
					className="app-table-types"
					rowKey="id"
					size="small"
					pagination={false}
					bordered
				/>
			</Form>
		</div>
	)
}
