import { CellContext, Row } from '@tanstack/react-table'
import { Checkbox, Popconfirm } from 'antd'
import { useAppSelector } from 'hooks/appReduxHook'
import { memo } from 'react'
import { useWbsPermits } from 'widgets/wbs'
import { useShallow } from 'zustand/react/shallow'
import {
	IEstimatePosition,
	IEstimatePositionMaterial,
	IEstimateSection,
	useEstimateState
} from '..'

interface IProps {
	record:
		| CellContext<IEstimatePosition | IEstimatePositionMaterial, any>
		| CellContext<IEstimatePosition, any>
		| CellContext<IEstimatePositionMaterial, any>
	parents: Row<IEstimateSection | IEstimatePosition | IEstimatePositionMaterial>[]
	visaIssued: boolean
}

export const EstimateNominatedCell = memo(function (params: IProps) {
	const { record, parents, visaIssued } = params
	const { user } = useAppSelector(state => state.environment)
	const { container, onSavePosition } = useEstimateState(
		useShallow(state => ({
			container: state.container,
			onSavePosition: state.onSavePosition
		}))
	)
	const wbsPermissions = useWbsPermits(container?.project?.id)

	const isLocalEstimate = typeof container?.isLocalEstimate !== 'undefined'
	const canEditCorrectLocalEstimate =
		container?.isLocalEstimate === false && wbsPermissions.canLocalEdit

	return (
		<Popconfirm
			title="Изменить значение?"
			onConfirm={() => {
				onSavePosition(!record.cell.getValue(), 'isNominated', record, parents)
			}}
		>
			<Checkbox
				checked={record.cell.getValue()}
				disabled={
					container?.ready ||
					(container?.status.name !== 'StructureApproval' && !user?.isAdmin) ||
					(isLocalEstimate === false && !user?.isAdmin && !wbsPermissions.canSetDZ) ||
					container?.isLocalEstimate === true ||
					(isLocalEstimate === true &&
						!user?.isAdmin &&
						!(wbsPermissions.canLocalDzMTR && record.row.original.isEditableNominated === true)) ||
					record.row.original.isClosed === true ||
					(record.row.original.isEditable === false && !user?.isAdmin) ||
					visaIssued
					// !(
					// 	(record.row.original.isEditable === false &&
					// 		record.row.original.isEditableNominated === false &&
					// 		user?.isAdmin) ||
					// 	((record.row.original.isEditable === true ||
					// 		record.row.original.isEditable === false) &&
					// 		record.row.original.isEditableNominated === true)
					// )
				}
			/>
		</Popconfirm>
	)
})
