import { UploadOutlined } from '@ant-design/icons'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import {
	Divider,
	Form,
	FormInstance,
	Image,
	Input,
	message,
	Modal,
	notification,
	Upload,
	UploadFile,
	UploadProps
} from 'antd'
import { RcFile } from 'antd/es/upload'
import { AxiosError } from 'axios'
import dayjs from 'dayjs'
import { IIssueCard, TIssueDetailForm, TIssueDto } from 'entities/issues/model/issue-card-schema'
import { useAppSelector } from 'hooks/appReduxHook'
import { IErrorDetail } from 'interfaces/IBase'
import _ from 'lodash'
import { FC, useEffect, useState } from 'react'
import { setReadyToInsepct as setReadyToInspect } from 'services/IssuesService'
import { DATE_FORMAT } from 'shared/constants'
import { ISSUE_STATUS, ISSUE_TYPES } from 'shared/constants/issue-types'
import { useShallow } from 'zustand/react/shallow'
import { issueCardApi } from '../api/issue-card-api'
import { useIssueCard } from '../model/issue-card-query'
import { useIssueCardState } from '../model/issue-card-state'

interface IProps {
	form: FormInstance<TIssueDetailForm>
	filesToUpload: UploadFile[]
	setFilesToUpload: (x: UploadFile[]) => void
	data: IIssueCard
}

type TIssueCommentForm = {
	comment: string
	attachments: UploadFile[]
}

const normFile = (e: any) => {
	if (Array.isArray(e)) {
		return e
	}
	return e?.fileList
}

const getBase64 = (file: RcFile): Promise<string> =>
	new Promise((resolve, reject) => {
		const reader = new FileReader()
		reader.readAsDataURL(file)
		reader.onload = () => resolve(reader.result as string)
		reader.onerror = error => reject(error)
	})

export const IssueCardChangeStatusModal: FC<IProps> = ({
	form,
	filesToUpload,
	setFilesToUpload,
	data
}) => {
	const queryClient = useQueryClient()
	const [issueCommentForm] = Form.useForm<TIssueCommentForm>()
	const [files, setFiles] = useState<UploadFile[]>([])
	const [isDisabled, setIsDisabled] = useState(true)
	const [comment, setComment] = useState<string>('')
	const [previewOpen, setPreviewOpen] = useState(false)
	const [previewImage, setPreviewImage] = useState('')
	const { project: currentProject } = useAppSelector(state => state.environment)
	const { issueId, commentModalType, initForm, setCommentModalType } = useIssueCardState(
		useShallow(state => ({
			issueId: state.issueId,
			commentModalType: state.commentModalType,
			initForm: state.initForm,
			setCommentModalType: state.setCommentModalType
		}))
	)
	const showError = _.debounce(() => {
		message.error('Общее количество файлов не может превышать 50')
	})
	const totalFiles = data?.attachments?.length! + files?.length + filesToUpload?.length

	const handleChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
		if (data?.attachments?.length! + newFileList?.length > 50) {
			showError()
		} else {
			setFiles(newFileList)
		}
	}

	const handlePreview = async (file: UploadFile) => {
		if (!file.url && !file.preview) {
			file.preview = await getBase64(file.originFileObj as RcFile)
		}

		setPreviewImage(file.url || (file.preview as string))
		setPreviewOpen(true)
	}

	const onAbort = () => {
		if (commentModalType === 'fromOpenToReady')
			form.setFieldValue('issueStatusId', ISSUE_STATUS.open)
		if (commentModalType === 'fromReadyToOpen')
			form.setFieldValue('issueStatusId', ISSUE_STATUS.ready)
		issueCommentForm.resetFields()
		setCommentModalType()
		setFilesToUpload([])
		setFiles([])
	}
	const props: UploadProps = {
		multiple: true,
		fileList: files,
		listType: 'picture-card',
		onChange: handleChange,
		onPreview: handlePreview,
		openFileDialogOnClick: !(totalFiles >= 50),
		beforeUpload: file => {
			const canUpload =
				!file.name.includes('jfif') &&
				(file.type === 'image/jpeg' ||
					file.type === 'image/jpg' ||
					file.type === 'image/png' ||
					file.type === 'application/pdf')
			if (!canUpload) {
				message.error({
					content: `${file.name} - тип файла не поддерживается`,
					style: { zIndex: 9999 }
				})
			}
			return canUpload ? false : Upload.LIST_IGNORE
		}
	}

	// const { mutate } = useMutation({
	// 	mutationKey: ['issue', 'comment'],
	// 	mutationFn: async (dto: FormData) => await issueApi.addComment(dto),
	// 	onSuccess: () => {
	// 		notification.success({
	// 			message: 'Комментарий успешно сохранен',
	// 			placement: 'topLeft'
	// 		})
	// 		queryClient.invalidateQueries({ queryKey: ['issue', 'card'] })
	// 		issueCommentForm.resetFields()
	// 		setCommentModalType()
	// 		setFiles([])
	// 	},
	// 	onError: (error: AxiosError<IErrorDetail>) => {
	// 		notification.error({
	// 			message: 'Произошла ошибка во время сохранения',
	// 			description: error.response?.data?.detail,
	// 			placement: 'topLeft'
	// 		})
	// 	}
	// })

	const { mutate: mutateReadyToInspect, isLoading } = useMutation({
		mutationKey: ['issue', 'readyToInspect'],
		mutationFn: async ({
			issueId,
			dto,
			dtoForUpdate
		}: {
			issueId: string
			dto: FormData
			dtoForUpdate: TIssueDto
		}) =>
			await setReadyToInspect(issueId, dto).then(
				() => initForm.issueStatusId === ISSUE_STATUS.ready && mutateUpdate(dtoForUpdate)
			),
		onSuccess: () => {
			notification.success({
				message: 'Комментарий успешно сохранен',
				placement: 'topLeft'
			})
			queryClient.invalidateQueries({ queryKey: ['issue', 'card'] })
			queryClient.invalidateQueries(['issuesList'])
			queryClient.invalidateQueries(['issue', 'attachments'])
			issueCommentForm.resetFields()
			setCommentModalType()
			setFiles([])
		},
		onError: (error: AxiosError<IErrorDetail>) => {
			notification.error({
				message: 'Произошла ошибка',
				description: error.response?.data?.detail,
				placement: 'topLeft'
			})
		}
	})

	const { mutate: mutateUpdate } = useMutation({
		mutationKey: ['issue', 'patch'],
		mutationFn: async (dto: TIssueDto) => await issueCardApi.save(dto, 'patch'),
		onSuccess: close => {
			notification.success({
				message: 'Замечание успешно сохранено',
				placement: 'topLeft'
			})
			queryClient.invalidateQueries(['issue', 'attachments'])
			queryClient.invalidateQueries(['issuesList'])
			queryClient.invalidateQueries({ queryKey: ['issue', 'card'] })
		},
		onError: (error: AxiosError<IErrorDetail>) => {
			notification.error({
				message: 'Произошла ошибка во время сохранения',
				description: error.response?.data?.detail,
				placement: 'topLeft'
			})
		}
	})

	const onSendCommentForm = (values: TIssueCommentForm) => {
		if (issueId) {
			const dto = new FormData()
			// dto.append('issueId', issueId)
			dto.append('comment', values.comment)
			dto.append('status', commentModalType === 'fromOpenToReady' ? 'ready_to_inspect' : 'open')
			files?.map(file => dto.append('files', file.originFileObj as RcFile))
			// mutate(dto)
			const dtoForUpdate = {
				...initForm,
				issueStatusId:
					commentModalType === 'fromOpenToReady' ? ISSUE_STATUS.ready : ISSUE_STATUS.open,
				id: issueId,
				dueDate: dayjs(initForm.dueDate).format(DATE_FORMAT.dto),
				projectId: initForm?.projectId ?? currentProject.id,
				responsibleManagerId:
					initForm?.responsibleManagerId ?? currentProject.responsibleManager?.id
			}
			// initForm.issueStatusId === ISSUE_STATUS.open
			// 	?
			mutateReadyToInspect({ issueId, dto, dtoForUpdate })
			// mutateUpdate(dtoForUpdate)
		}
	}

	useEffect(() => {
		setIsDisabled(
			comment?.length < 3 || initForm.issueTypeId !== ISSUE_TYPES.acceptance
				? (initForm.issueStatusId === ISSUE_STATUS.open &&
						(files?.length === 0 || comment?.length < 3)) ||
						(initForm.issueStatusId === ISSUE_STATUS.ready && comment?.length < 3)
				: false
		)
	}, [commentModalType, comment, files])

	return (
		<Modal
			destroyOnClose
			cancelText="Отмена"
			centered
			okText="Подтвердить"
			onOk={() => issueCommentForm.submit()}
			onCancel={onAbort}
			open={typeof commentModalType !== 'undefined'}
			title="Добавить комментарий"
			width="30vw"
			zIndex={1500}
			okButtonProps={{ disabled: isDisabled }}
			confirmLoading={isLoading}
		>
			<Form name="issueCommentForm" form={issueCommentForm} onFinish={onSendCommentForm}>
				<Form.Item name="comment">
					<Input.TextArea
						maxLength={250}
						placeholder="Ваш комментарий"
						showCount
						style={{ height: '120px', resize: 'none' }}
						onChange={value => setComment(value.currentTarget.value)}
					/>
				</Form.Item>
				<Divider />
				<div style={{ marginTop: '-2vh' }}>{`Доступно: ${50 - totalFiles}/50`}</div>
				<div style={{ maxHeight: '50vh', overflowY: 'auto', borderRadius: '8px !important' }}>
					<Upload {...props}>
						<div>
							<UploadOutlined />
							<div style={{ marginTop: 8 }}>Добавить</div>
						</div>
					</Upload>
				</div>
			</Form>
			{previewImage && (
				<Image
					wrapperStyle={{ display: 'none' }}
					preview={{
						visible: previewOpen,
						onVisibleChange: visible => setPreviewOpen(visible),
						afterOpenChange: visible => !visible && setPreviewImage('')
					}}
					src={previewImage}
				/>
			)}
		</Modal>
	)
}
