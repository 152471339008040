import { useQuery } from '@tanstack/react-query'
import { orgApi } from './org-api'

export const useOrgs = () => {
	return useQuery({
		queryKey: ['organizations'],
		queryFn: async () => {
			return await orgApi.getRegistry()
		},
		refetchOnWindowFocus: false
	})
}
