import { IAppUser } from 'interfaces/IUser'
import { FC, useState } from 'react'
import { UserMenuDrawer } from './userMenu.drawer'
import { UserMenuPopup } from './userMenu.popup'

export const UserMenu: FC = () => {
	const [showUserMenu, setShowUserMenu] = useState(false)
	const user = localStorage.getItem('viewer')
	const parsedUser: IAppUser | null =
		JSON.parse(user!).state?.viewer && JSON.parse(user!).state?.viewer !== undefined
			? JSON.parse(user!).state?.viewer
			: null

	return (
		<>
			<UserMenuDrawer
				open={showUserMenu}
				user={parsedUser!}
				onClose={() => setShowUserMenu(false)}
			/>
			<UserMenuPopup user={parsedUser!} onClick={() => setShowUserMenu(true)} />
		</>
	)
}
