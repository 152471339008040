import { API } from 'services/ApiService'
import { TBase } from 'shared/schemas'

export const orgApi = {
	async getRegistry() {
		return await API<TBase[]>({
			url: `/v1/orgs`,
			method: 'get'
		}).then(response => response.data)
	}
}
