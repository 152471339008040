import { Table } from 'antd'
import { useMissedStatuses } from '../api/monitoring-queries'
import { ColumnsType } from 'antd/es/table'
import { MonitoringMissedStatuses } from '../api/monitoring-schema'
import { NavLink } from 'react-router-dom'
import { useMonitoringState } from '../model/monitoring-state'
import { PAGE_LIMIT } from 'shared/constants'

export const MissedStatusesMonitoring = () => {
	const { data, isFetching } = useMissedStatuses()
	const { totalItems, currentPage, onPagination } = useMonitoringState()
	const columns: ColumnsType<MonitoringMissedStatuses> = [
		{
			dataIndex: 'containerId',
			title: 'Id контейнера',
			render: (_, record) => (
				<NavLink
					to={`/${record.type === 'Container' ? 'unmodel' : 'local'}/${record.containerId}`}
					target="_blank"
				>
					{record.containerId}
				</NavLink>
			)
		},
		{
			dataIndex: 'typeRu',
			title: 'Тип документа'
		},
		{
			dataIndex: 'missingStatusRu',
			title: 'Статус пропущенный'
		}
	]

	return (
		<div className="dashboard__block" style={{ borderRadius: '0 0 .8rem .8rem' }}>
			<Table
				loading={isFetching}
				className="app-table-types"
				rowKey={record => record.containerId}
				size="small"
				pagination={{
					hideOnSinglePage: true,
					total: totalItems,
					pageSize: PAGE_LIMIT,
					showSizeChanger: false,
					onChange: onPagination,
					current: currentPage
				}}
				bordered
				dataSource={data}
				columns={columns as any}
			/>
		</div>
	)
}
