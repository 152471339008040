import { RollbackOutlined } from '@ant-design/icons'
import { Button, Card, Col, Row } from 'antd'
import { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { TEstimateType } from 'shared/schema'
import { AdjustmentActions } from './AdjustmentActions'
import { AdjustmentColumnsConfig } from './AdjustmentColumnsConfig'
import { AdjustmentStatusLine } from './AdjustmentStatusLine'

interface IProps {
	type: TEstimateType
	visaIssued: boolean
}

export const AdjustmentHeader: FC<IProps> = ({ type, visaIssued }) => {
	const navigate = useNavigate()
	return (
		<Card id="app-estimateHeader" size="small">
			<Row align="middle" justify="space-between" gutter={16} wrap={false}>
				<Col>
					<Row align="middle" justify="space-between" gutter={16} wrap={false}>
						<Col>
							<AdjustmentColumnsConfig type={type} />
						</Col>
						<Col>
							<Button
								icon={<RollbackOutlined />}
								onClick={() => navigate(type === 'local' ? '/local' : '/unmodel')}
							>
								{`Назад к списку ${type === 'local' ? 'локальных смет' : 'ВОР'}`}
							</Button>
						</Col>
					</Row>
				</Col>
				<Col flex={1}>
					<AdjustmentStatusLine type={type} />
				</Col>
				{/* <Col>
					<EstimateRelatedDocumentsButton type={type} />
				</Col> */}
				<Col>
					<AdjustmentActions visaIssued={visaIssued} />
				</Col>
			</Row>
		</Card>
	)
}
