import { Form, TreeSelect } from 'antd'
import { IBaseConstructionObject } from 'interfaces/IBase'
import { IContainer } from 'interfaces/IContainer'
import { FC, Key, useEffect, useState } from 'react'
import { PERMISSIONS } from 'shared/constants'
import { useCheckPermissions } from 'shared/useCheckPermissions'
import { flattenTree, markDisabledNodes } from '../model/wbs-helper'

interface IProps {
	editing: boolean
	constructionObjects: IBaseConstructionObject[]
	record?: IContainer
	fieldName?: string
}

export const ConstructionObjectSelect: FC<IProps> = ({
	editing,
	constructionObjects,
	record,
	fieldName = 'constructionObjectId'
}) => {
	const [transformedData, setTransformedData] = useState<IBaseConstructionObject[]>([])
	const checkPermissions = useCheckPermissions()
	const canEdit =
		!record?.constructionObject || checkPermissions([PERMISSIONS.Admin], record?.project?.id!)

	// TODO Вынести отдельно для TreeSelect
	const [expandedKeys, setExpandedKeys] = useState<Key[]>(
		flattenTree(transformedData!)?.map((elem: any) => elem.id)
	)
	const [searchValue, setSearchValue] = useState()

	useEffect(() => {
		setExpandedKeys(flattenTree(transformedData!)?.map((elem: any) => elem.id))
	}, [searchValue, transformedData])

	const onSearch = (e: any) => {
		setSearchValue(e.target?.value)
		if (!e.target?.value) {
			setExpandedKeys(flattenTree(transformedData!)?.map((elem: any) => elem.id))
		}
	}
	const onExpand = (newKeys: Key[]) => {
		setExpandedKeys(newKeys)
	}
	//

	useEffect(() => {
		if (editing) {
			const data = markDisabledNodes(constructionObjects || []) as IBaseConstructionObject[]
			setTransformedData(data)
		} else {
			setTransformedData([])
		}
	}, [editing, constructionObjects])

	return (
		<Form.Item
			name={fieldName}
			style={{ margin: 0 }}
			validateFirst
			rules={[
				{
					required: true,
					message: 'Выберите объект строительства.'
				}
				// () => ({
				// 	validator(_, value) {
				// 		if (value === record?.constructionObject?.id) {
				// 			const obj: any = record?.constructionObject
				// 			const isValid = obj.isAccessible === true
				// 			if (!isValid) {
				// 				return Promise.reject(
				// 					new Error('Объект строительства недоступен для выбора. Укажите другое значение.')
				// 				)
				// 			}
				// 		}

				// 		return Promise.resolve()
				// 	}
				// })
			]}
		>
			<TreeSelect
				fieldNames={{ label: 'name', value: 'id', children: 'children' }}
				treeData={transformedData}
				dropdownStyle={{
					width: 500,
					maxHeight: '300px',
					overflow: 'auto'
				}}
				showSearch
				disabled={!canEdit}
				placeholder="Выберите объект строительства"
				allowClear
				treeDefaultExpandAll
				filterTreeNode={(input, treeNode) => {
					return treeNode.name?.toLowerCase()?.includes(input.toLowerCase())
				}}
				onSearch={onSearch}
				treeExpandedKeys={expandedKeys}
				onTreeExpand={onExpand}
				treeTitleRender={(node: any) => {
					if (!flattenTree(transformedData)?.find((t: any) => t.id === node.value)) {
						return node.name ?? record?.constructionObject?.name
					} else {
						return node.name
					}
				}}
			/>
		</Form.Item>
	)
}
