import { IPagination } from 'interfaces/IPagination'
import { API } from 'services/ApiService'
import { PAGE_LIMIT } from 'shared/constants'
import {
	MonitoringAutoOrdinances,
	MonitoringAutoOrdinancesNotApproved,
	MonitoringCheckListDueDateNotifications,
	MonitoringContainersMissingVisas,
	MonitoringContractsWithFines,
	MonitoringCreatedFines,
	MonitoringFilesStatuses,
	MonitoringFilters,
	MonitoringMissedStatuses,
	MonitoringNonEliminatedOrdinances,
	MonitoringReadyContainersButNotApproved,
	MonitoringReadyEstimatesButNotApproved,
	MonitoringUndeliveredEliminationActs,
	MonitoringUndeliveredNonEliminationActs,
	MonitoringUndeliveredOrdinances
} from './monitoring-schema'

export const MonitoringApi = {
	async getContractsWithFines() {
		return await API<MonitoringContractsWithFines[]>({
			url: '/v1/monitoring/getContractsWithFines',
			method: 'get'
		}).then(response => response.data)
	},
	async getUndeliveredNonEliminationActs(currentPage: number) {
		return await API<IPagination<MonitoringUndeliveredNonEliminationActs[]>>({
			url: '/v1/monitoring/getUndeliveredNonEliminationActs',
			method: 'get',
			params: {
				limit: PAGE_LIMIT,
				offset: currentPage === 1 ? 0 : currentPage * PAGE_LIMIT - PAGE_LIMIT
			}
		}).then(response => response.data)
	},
	async getUndeliveredOrdinances(currentPage: number) {
		return await API<IPagination<MonitoringUndeliveredOrdinances[]>>({
			url: '/v1/monitoring/getUndeliveredOrdinances',
			method: 'get',
			params: {
				limit: PAGE_LIMIT,
				offset: currentPage === 1 ? 0 : currentPage * PAGE_LIMIT - PAGE_LIMIT
			}
		}).then(response => response.data)
	},
	async getFilesStatuses(currentPage: number) {
		return await API<IPagination<MonitoringFilesStatuses[]>>({
			url: '/v1/monitoring/getFilesStatuses',
			method: 'get',
			params: {
				limit: PAGE_LIMIT,
				offset: currentPage === 1 ? 0 : currentPage * PAGE_LIMIT - PAGE_LIMIT
			}
		}).then(response => response.data)
	},
	async forwardingOrdinanceFile(ordinanceId: string, attachmentId: string) {
		return await API<IPagination<MonitoringFilesStatuses[]>>({
			url: `/v1/ordinances/${ordinanceId}/attachments/send`,
			method: 'post',
			data: { attachmentId }
		}).then(response => response.data)
	},
	async getUndeliveredEliminationActs(currentPage: number) {
		return await API<IPagination<MonitoringUndeliveredEliminationActs[]>>({
			url: '/v1/monitoring/getUndeliveredEliminationActs',
			method: 'get',
			params: {
				limit: PAGE_LIMIT,
				offset: currentPage === 1 ? 0 : currentPage * PAGE_LIMIT - PAGE_LIMIT
			}
		}).then(response => response.data)
	},
	async getNonEliminatedOrdinances() {
		return await API<MonitoringNonEliminatedOrdinances[]>({
			url: '/v1/monitoring/getNonEliminatedOrdinances',
			method: 'get'
		}).then(response => response.data)
	},
	async getAutoOrdinances(dueDate: string | null) {
		return await API<MonitoringAutoOrdinances[]>({
			url: '/v1/monitoring/getAutoOrdinances',
			method: 'get',
			params: { dueDate }
		}).then(response => response.data)
	},
	async getAutoOrdinancesNotApproved() {
		return await API<MonitoringAutoOrdinancesNotApproved[]>({
			url: '/v1/monitoring/getAutoOrdinancesNotApproved',
			method: 'get'
		}).then(response => response.data)
	},
	async getCheckListDueDateNotifications(currentPage: number) {
		return await API<IPagination<MonitoringCheckListDueDateNotifications[]>>({
			url: '/v1/monitoring/getCheckListDueDateNotifications',
			method: 'get',
			params: {
				limit: PAGE_LIMIT,
				offset: currentPage === 1 ? 0 : currentPage * PAGE_LIMIT - PAGE_LIMIT
			}
		}).then(response => response.data)
	},
	async getCreatedFines(currentPage: number, monitoringFilters: MonitoringFilters) {
		return await API<IPagination<MonitoringCreatedFines[]>>({
			url: '/v1/monitoring/getCreatedFines',
			method: 'get',
			params: {
				limit: PAGE_LIMIT,
				offset: currentPage === 1 ? 0 : currentPage * PAGE_LIMIT - PAGE_LIMIT,
				date: monitoringFilters?.date,
				comparisonOperator: monitoringFilters?.comparisonOperator
			}
		}).then(response => response.data)
	},
	async getMissedStatuses(currentPage: number) {
		return await API<IPagination<MonitoringMissedStatuses[]>>({
			url: '/v1/monitoring/getContainersMissingApprovals',
			method: 'get',
			params: {
				limit: PAGE_LIMIT,
				offset: currentPage === 1 ? 0 : currentPage * PAGE_LIMIT - PAGE_LIMIT
			}
		}).then(response => response.data)
	},
	async getReadyContainersButNotApprovedMonitoring(currentPage: number) {
		return await API<IPagination<MonitoringReadyContainersButNotApproved[]>>({
			url: '/v1/monitoring/getReadyContainersButNotApproved',
			method: 'get',
			params: {
				limit: PAGE_LIMIT,
				offset: currentPage === 1 ? 0 : currentPage * PAGE_LIMIT - PAGE_LIMIT
			}
		}).then(response => response.data)
	},
	async getReadyEstimatesButNotApprovedMonitoring(currentPage: number) {
		return await API<IPagination<MonitoringReadyEstimatesButNotApproved[]>>({
			url: '/v1/monitoring/getReadyEstimatesButNotApproved',
			method: 'get',
			params: {
				limit: PAGE_LIMIT,
				offset: currentPage === 1 ? 0 : currentPage * PAGE_LIMIT - PAGE_LIMIT
			}
		}).then(response => response.data)
	},
	async getContainersMissingVisas(currentPage: number) {
		return await API<IPagination<MonitoringContainersMissingVisas[]>>({
			url: '/v1/monitoring/getContainersMissingVisas',
			method: 'get',
			params: {
				limit: PAGE_LIMIT,
				offset: currentPage === 1 ? 0 : currentPage * PAGE_LIMIT - PAGE_LIMIT
			}
		}).then(response => response.data)
	}
}
