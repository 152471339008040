import { Button, Form, Input, Modal } from 'antd'
import { absRequiredMark } from 'features/abs-form'
import { useCallback, useState } from 'react'
import { validate as uuidValidate } from 'uuid'
import { useForwardingFile } from '../api/use-forwarding-file'

const defaultRule = { required: true, message: 'Поле не должно быть пустым' }

type TForwardingFileForm = {
	ordinanceId: string
	attachmentId: string
}

export const ForwardingFileModal = () => {
	const [showModal, setShowModal] = useState(false)
	const [forwardingFileForm] = Form.useForm<TForwardingFileForm>()
	const { mutate } = useForwardingFile()

	const onConfirmHandler = useCallback((values: TForwardingFileForm) => {
		mutate(values)
	}, [])

	const onCloseHandler = useCallback(() => {
		setShowModal(false)
		forwardingFileForm.resetFields()
	}, [])

	return (
		<>
			<Button type="primary" style={{ marginBottom: 16 }} onClick={() => setShowModal(true)}>
				Выгрузить вручную
			</Button>
			<Modal
				title="Выгрузка документа вручную"
				open={showModal}
				onOk={() => forwardingFileForm.submit()}
				okText="Выгрузить"
				onCancel={onCloseHandler}
			>
				<Form
					colon={false}
					labelCol={{ span: 7 }}
					labelAlign="left"
					requiredMark={absRequiredMark}
					name="forwardingFileForm"
					form={forwardingFileForm}
					onFinish={onConfirmHandler}
				>
					<Form.Item
						name={'ordinanceId'}
						label={'ID Предписания'}
						rules={[
							defaultRule,
							() => ({
								validator(_, value) {
									if (uuidValidate(value)) return Promise.resolve()
									return Promise.reject(new Error(`Значение не соответствует uuid формату.`))
								}
							})
						]}
					>
						<Input />
					</Form.Item>
					<Form.Item
						name={'attachmentId'}
						label={'ID файла'}
						rules={[
							defaultRule,
							() => ({
								validator(_, value) {
									if (uuidValidate(value)) return Promise.resolve()
									return Promise.reject(new Error(`Значение не соответствует uuid формату.`))
								}
							})
						]}
					>
						<Input />
					</Form.Item>
				</Form>
			</Modal>
		</>
	)
}
