import { FC, useState } from 'react'
import { DownloadOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import { TrafficLightDownloadModal } from './trafficLightDownload-modal'
import { useCheckCommonPermissions } from 'shared/useCheckPermissions'
import { PERMISSIONS } from 'shared/constants'

interface IProps {}

export const TrafficLightDownload: FC<IProps> = () => {
	const [showModal, setShowModal] = useState(false)
	const checkPermission = useCheckCommonPermissions()

	return (
		<>
			<TrafficLightDownloadModal show={showModal} onClose={() => setShowModal(false)} />
			<Button
				icon={<DownloadOutlined />}
				onClick={() => setShowModal(true)}
				disabled={!checkPermission([PERMISSIONS.ChecklistsReportLight])}
			>
				Выгрузить отчет
			</Button>
		</>
	)
}
