import { Col, Empty, Modal, Row } from 'antd'
import { Doughnut, Pie } from 'react-chartjs-2'
import { FC, useEffect } from 'react'
import { useDiagramsProjectState } from '../model/diagrams-project-state'
import { useNavigate } from 'react-router-dom'
import { useIssue } from 'widgets/issue/model/issue-queries'
import { useIssues } from '../model/diagrams-project-query'
import { useAppDispatch, useAppSelector } from 'hooks/appReduxHook'
import { useQueryClient } from '@tanstack/react-query'
import { getRelativePosition } from 'chart.js/helpers'
import { Chart } from 'chart.js'
import { changeCurrentProject } from '../model/diagrams-project-helpers'
import { setIssueFilters } from 'store/issueSlice'
import { PERMISSIONS } from 'shared/constants'
import { useCheckPermissions } from 'shared/useCheckPermissions'

export const DiagramsProjectIssue: FC = () => {
	const { data: issue } = useIssues()
	const { projectId, contractorIds, authors } = useDiagramsProjectState()
	const navigate = useNavigate()
	const dispatch = useAppDispatch()
	const { user } = useAppSelector(state => state.environment)
	const queryClient = useQueryClient()
	const checkPermissions = useCheckPermissions()

	const values = [
		issue?.openIssues || null,
		issue?.readyForControlIssues || null,
		issue?.closeIssues || null
	]
	const labels = ['Открыто', 'Готово к проверке', `Закрыто`]
	const customLabels = labels.map((label, index) => `${label}: ${values[index] ?? 0}`)
	const colors = ['#FFAD14', '#7A77D9', '#8099A8']
	const config = {
		type: 'doughnut',
		data: {
			labels: customLabels,
			datasets: [
				{
					data: values,
					backgroundColor: colors,
					hoverBackgroundColor: colors,
					borderColor: ['white'],
					borderWidth: 4,
					datalabels: {
						color: '#FFFFFF',
						font: {
							size: 14,
							weight: 400,
							family: 'Segoe UI'
						},
						formatter: function (value: any) {
							let percentage = (value / values?.reduce((sum, value) => sum! + value!, 0)!) * 100
							if (percentage < 5) {
								return ''
							}
							return value
						}
					}
				}
			]
		},
		options: {
			onHover: (event: any, elements: any) => {
				const canvasPosition: any = getRelativePosition(event, elements)
				const chart: Chart = canvasPosition.chart
				const points = chart.getElementsAtEventForMode(event, 'nearest', { intersect: true }, true)
				event.native.target.style.cursor = points[0] ? 'pointer' : 'default'
			},
			onClick: (event: any, elements: any) => {
				const canvasPosition: any = getRelativePosition(event, elements)
				const chart: Chart = canvasPosition.chart
				const points = chart.getElementsAtEventForMode(event, 'nearest', { intersect: true }, true)
				if (points[0]) {
					const point = points[0]
					if (!checkPermissions([PERMISSIONS.IssueView], projectId!)) {
						Modal.error({
							title: 'Ошибка доступа',
							content:
								'Внимание! Раздел не доступен, поскольку у Вас не хватает прав. Обратитесь к администратору.'
						})
						return
					}
					if (point.index === 0) {
						changeCurrentProject(projectId!, user, dispatch, queryClient)
						dispatch(
							setIssueFilters({
								filters: {
									contractorIds: contractorIds!,
									ownerIds: authors!,
									status: ['5758e9f3-b35b-4d97-8616-aa3bbb96131f']
								}
							})
						)
					} else if (point.index === 1) {
						changeCurrentProject(projectId!, user, dispatch, queryClient)
						dispatch(
							setIssueFilters({
								filters: {
									contractorIds: contractorIds!,
									ownerIds: authors!,
									status: ['2d9df9da-5340-4f2a-ad30-77a24bb1bfea']
								}
							})
						)
					} else if (point.index === 2) {
						changeCurrentProject(projectId!, user, dispatch, queryClient)
						dispatch(
							setIssueFilters({
								filters: {
									contractorIds: contractorIds!,
									ownerIds: authors!,
									status: ['4ae3200c-d2df-4c70-922a-780ea1575a35']
								}
							})
						)
					}
					navigate('/control/issues')
				}
			},
			circumference: 180,
			rotation: -90,
			maintainAspectRatio: false,
			plugins: {
				tooltip: {
					callbacks: {
						labelColor: function (context: any) {
							return {
								borderWidth: 3,
								borderColor: colors[context.dataIndex],
								backgroundColor: colors[context.dataIndex]
							}
						},
						label: function (context: any) {
							let label = context.formattedValue?.trim() || ''
							return ' ' + label
						}
					}
				},
				legend: {
					labels: {
						boxWidth: 15,
						boxHeight: 15,
						fontFamily: 'Segoe UI',
						fontWeight: 'normal',
						fontSize: '14px',
						color: '#2C303D'
					},
					responsive: true,
					position: 'right' as const,
					title: {
						display: true,
						color: 'black'
					}
				}
			}
		}
	}

	return (
		<Row
			// wrap={false}
			// gutter={12}
			style={{
				border: '1px solid #ddd',
				borderRadius: 8,
				padding: 8,
				background: 'white',
				height: '350px'
			}}
		>
			<Col
				span={24}
				style={{
					padding: '1rem',
					background: !(issue?.totalIssues! > 0) ? '#ffffff' : '#fefefe'
				}}
			>
				<Row>
					<Col>
						<span
							style={{
								fontFamily: 'Segoe UI',
								fontWeight: 'normal',
								fontSize: '22px',
								color: '#000000',
								marginRight: '8px'
							}}
						>
							Статусы замечаний
						</span>
					</Col>
					<Col style={{ alignContent: 'center', marginTop: 5 }}>
						<span
							style={{
								fontFamily: 'Segoe UI',
								fontWeight: 400,
								fontSize: '14px',
								marginLeft: '5px',
								backgroundColor: '#EEEEEE',
								borderRadius: '21px',
								padding: '4px',
								color: '#2C303',
								width: '43px',
								height: '20px'
							}}
						>
							{issue?.totalIssues ?? 0}
						</span>
					</Col>
				</Row>
				<div style={{ height: 250, marginTop: 20 }}>
					{issue?.totalIssues! > 0 ? (
						<Doughnut {...config} />
					) : (
						<span
							style={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								height: '100%'
							}}
						>
							<Empty />
						</span>
					)}
				</div>
			</Col>
		</Row>
	)
}
