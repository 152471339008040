import { PicCenterOutlined } from '@ant-design/icons'
import { Card, Col, Row, Tag } from 'antd'
import { FC } from 'react'
import { TChecklistStage } from '../model/checklist-card-schema'
import { ChecklistSections } from './checklist-sections'

type TProps = {
	disabled: boolean
	stage: TChecklistStage
}

export const ChecklistStage: FC<TProps> = ({ disabled, stage }) => {
	return (
		<Card
			title={
				<Row align="middle" gutter={16}>
					<Col>
						<PicCenterOutlined style={{ fontSize: 20, color: '#0061bf' }} />
					</Col>
					<Col>
						<h3 style={{ marginBottom: '4px' }}>Этап №{stage.order + 1}</h3>
					</Col>
				</Row>
			}
			extra={
				<Row align="middle" gutter={16} wrap={false}>
					<Col>
						<strong>Оплата этапа:</strong>
					</Col>
					<Col>
						<Tag color="#0061bf" style={{ fontSize: 16, padding: '4px 16px', borderRadius: 16 }}>
							{stage.percent}%
						</Tag>
					</Col>
				</Row>
			}
			className="app-work-card"
			classNames={{
				body: 'app-work-card__body',
				header: 'app-work-card__header app-work-card__header--stage'
			}}
		>
			<ChecklistSections sections={stage.sections} disabled={disabled} />
		</Card>
	)
}
