import {
	ClockCircleOutlined,
	CloseCircleOutlined,
	RollbackOutlined,
	SignatureOutlined
} from '@ant-design/icons'
import { useQueryClient } from '@tanstack/react-query'
import { Button, Card, Col, Descriptions, Modal, Popconfirm, Row, Space, Tag, Tooltip } from 'antd'
import modal from 'antd/es/modal'
import { AxiosError } from 'axios'
import dayjs from 'dayjs'
import { useAppSelector } from 'hooks/appReduxHook'
import { IErrorDetail } from 'interfaces/IBase'
import { IStatusRouteItemVisaState } from 'pages/unmodelPage/wbsContainer'
import { useWbsTaskState } from 'pages/wbs-tasks-page/model/wbs-tasks-state'
import { FC, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { CheckDeclension } from 'shared/helpers'
import { useWbsTaskCard } from 'widgets/wbs-task-card/api/wbs-task-card-queries'
import { wbsTaskVisasApi } from '../api/wbs-task-visas-api'
import { useCanVisaApprove, useWbsTaskVisas } from '../api/wbs-task-visas-queries'
import { useWbsTaskVisasState } from '../model/wbs-task-visas-state'
import { WBS_TASK_STATUS } from 'shared/constants/wbs-task-types'
import { PERMISSIONS } from 'shared/constants'
import { useCheckPermissions } from 'shared/useCheckPermissions'
import { useWbsTaskRollupStatus } from '../api/wbs-task-rollup-mutation'

interface IWbsTaskStatuses {
	id: string
	group: string
	title: string
	color: string
	order: number
	author?: string
	dateTransfer: Date | null
	duration?: string
	items: IWbsTaskStatusesItem[]
}

interface IWbsTaskStatusesItem {
	id: string
	title: string
	state: boolean
	approver: IStatusRouteItemVisaState | null
}

export const WbsTaskStatusModal: FC = () => {
	const { user } = useAppSelector(state => state.environment)
	const queryClient = useQueryClient()
	const { id: wbsTaskId } = useParams()
	const { currentTaskId } = useWbsTaskState()
	const { showModal, closeModal } = useWbsTaskVisasState()
	const { data: wbsTask, isFetching } = useWbsTaskCard()
	const { data: wbsTaskVisas, isFetching: isFetchingVisas } = useWbsTaskVisas(currentTaskId!)
	const { data: canApprove } = useCanVisaApprove(currentTaskId!)
	const [wbsTaskStatuses, setWbsTaskStatuses] = useState<IWbsTaskStatuses[]>([])
	const checkPermissions = useCheckPermissions()

	const canEditAssignEconomy = checkPermissions(
		[PERMISSIONS.WbsTasksAssignedTo],
		wbsTask?.project?.id!
	)
	const canEditAssignDesign = checkPermissions(
		[PERMISSIONS.WbsTasksAssignedToDES],
		wbsTask?.project?.id!
	)
	const { mutate: rollupStatusMutation } = useWbsTaskRollupStatus()

	useEffect(() => {
		setWbsTaskStatuses(
			wbsTaskVisas?.map((s: any) => ({
				id: s.id,
				group: s.name,
				title: s.description,
				color: s.color,
				order: s.order,
				author: s.author,
				duration: s.duration,
				dateTransfer: s.dateTransfer && dayjs(s.dateTransfer).isValid() ? s.dateTransfer : null,
				items:
					s.visas?.map((v: any) => ({
						id: v.id,
						title: v.name,
						state: v.visaState !== null,
						approver: v.visaState,
						duration: v.duration
					})) ?? []
			})) ?? []
		)
	}, [wbsTaskVisas])

	return (
		<Modal
			zIndex={1001}
			centered
			width={1000}
			footer={false}
			open={showModal}
			onCancel={closeModal}
			title="Статус согласования"
			destroyOnClose={true}
		>
			<Space
				direction="vertical"
				style={{ width: '100%', maxHeight: '80vh', overflow: 'scroll', padding: '1rem' }}
				size="large"
			>
				{wbsTaskStatuses
					.sort((a, b) => (b.group !== null ? 1 : 0) - (a.group !== null ? 1 : 0))
					.map((status, index) => (
						<Card
							extra={
								user?.isAdmin &&
								// type === 'wbs' &&
								status.id !== wbsTask?.wbsTaskStatus?.id &&
								wbsTaskStatuses.findIndex(s => s.id === wbsTask?.wbsTaskStatus.id) > index &&
								index !== 0 && (
									<Popconfirm
										zIndex={99999999}
										title="Откатить статус?"
										placement="left"
										icon={<RollbackOutlined style={{ color: 'red' }} />}
										onConfirm={() =>
											wbsTask &&
											rollupStatusMutation({
												wbsTaskId: wbsTask.id!,
												wbsTaskStatusId: status.id
											})
										}
										okText="Да"
										okButtonProps={{ danger: true }}
										cancelText="Нет"
									>
										<Button size="small" danger icon={<RollbackOutlined />} />
									</Popconfirm>
								)
							}
							title={
								<Row justify="space-between" gutter={16}>
									<Col flex={1}>{status.title}</Col>
									<Col>{status.author}</Col>
									{status.dateTransfer !== null && (
										<Col>{dayjs(status.dateTransfer).format('HH:mm DD.MM.YYYY')}</Col>
									)}
									{dayjs(status.dateTransfer).isValid() && (
										<Col>
											<Tooltip
												zIndex={999999}
												title={
													index === wbsTaskStatuses.length - 1
														? 'Общее время согласования'
														: 'Время нахождения в этом статусе'
												}
											>
												<Tag
													icon={<ClockCircleOutlined />}
													color={status.group === 'New' ? '#757575' : '#' + status.color}
												>
													{status?.duration !== undefined
														? status?.duration!?.slice(0, 5).includes('.')
															? `${status?.duration!?.split('.')[0]} ${CheckDeclension(
																	+status?.duration!?.split('.')[0],
																	['день', 'дня', 'дней']
															  )} ${status
																	?.duration!.split('.')[1]
																	?.slice(0, 2)} ${CheckDeclension(
																	+status?.duration!.split('.')[1]?.slice(0, 2),
																	['час', 'часа', 'часов']
															  )}`
															: status?.duration!?.slice(0, 2) !== '00'
															? `${status?.duration!?.slice(0, 2)} ${CheckDeclension(
																	+status?.duration!?.slice(0, 2),
																	['час', 'часа', 'часов']
															  )}`
															: 'менее часа'
														: null}
												</Tag>
											</Tooltip>
										</Col>
									)}
								</Row>
							}
							key={status.id}
							className="at-workcard"
							styles={{
								body: {
									display: !!status.items?.length ? 'block' : 'none'
								}
							}}
							style={{
								background: '#' + status.color + '10',
								border:
									status.id === wbsTask?.wbsTaskStatus?.id ||
									status.id === wbsTask?.wbsTaskStatus?.id
										? '2px solid #' + status.color
										: '1px solid #ddd'
							}}
						>
							{status.items?.length && (
								<Descriptions
									layout="horizontal"
									size="small"
									column={2}
									bordered
									className="at-descriptions"
									labelStyle={{
										width: '30%',
										fontWeight: 'bold',
										alignItems: 'center',
										padding: '4px 8px'
									}}
									contentStyle={{
										alignItems: 'center'
									}}
								>
									{status.items?.map((item, itemIndex) => (
										<Descriptions.Item key={item.id} label={item.title! + ':'} span={2}>
											<div
												style={{
													display: 'flex',
													alignItems: 'center',
													justifyContent: 'space-between'
												}}
											>
												{item.state ? (
													<>
														<Space direction="vertical" key={item.approver?.userId}>
															<b>{item.approver?.userFullName}</b>
															<Col>
																<i>
																	{dayjs(item.approver?.approveDate).isValid()
																		? dayjs(item.approver?.approveDate).format('HH:mm DD.MM.YYYY')
																		: '-'}
																</i>
																<Tag
																	icon={<ClockCircleOutlined />}
																	style={{ marginLeft: 10 }}
																	color={status.group === 'New' ? '#757575' : '#' + status.color}
																>
																	{item.approver?.duration!?.slice(0, 5).includes('.')
																		? `${item.approver?.duration!?.split('.')[0]} ${CheckDeclension(
																				+item.approver?.duration!?.split('.')[0],
																				['день', 'дня', 'дней']
																		  )} ${item.approver
																				?.duration!?.split('.')[1]
																				?.slice(0, 2)} ${CheckDeclension(
																				+item.approver?.duration!?.split('.')[1]?.slice(0, 2),
																				['час', 'часа', 'часов']
																		  )}`
																		: item.approver?.duration!?.slice(0, 2) !== '00'
																		? `${item.approver?.duration!?.slice(0, 2)} ${CheckDeclension(
																				+item.approver!?.duration!?.slice(0, 2),
																				['час', 'часа', 'часов']
																		  )}`
																		: 'менее часа'}
																</Tag>
															</Col>
														</Space>
														{status.id === wbsTask?.wbsTaskStatus?.id &&
															(item.approver?.userId === user?.id || user?.isAdmin) && (
																<Popconfirm
																	zIndex={9999999999999}
																	title="Снять визу?"
																	onConfirm={async () =>
																		await wbsTaskVisasApi
																			.unsetVisaApprove(currentTaskId!, item.id)
																			.then(() => {
																				queryClient.invalidateQueries(['wbsTaskVisas'])
																				queryClient.invalidateQueries(['wbsTaskCard'])
																				queryClient.invalidateQueries(['wbsTasks'])
																				queryClient.invalidateQueries(['wbsTasksDiagram'])
																			})
																			.catch((error: AxiosError<IErrorDetail>) =>
																				modal.error({
																					title: 'Произошла ошибка',
																					content:
																						error.response?.data?.detail ??
																						'Непредвиденная ошибка. Обратитесь к администратору'
																				})
																			)
																	}
																	okText="Подтвердить"
																	cancelText="Отмена"
																>
																	<Button danger ghost icon={<CloseCircleOutlined />}>
																		Отменить
																	</Button>
																</Popconfirm>
															)}
													</>
												) : (
													wbsTask?.wbsTaskStatus?.id === status.id &&
													(canApprove?.includes(item.id) || user?.isAdmin) && (
														<Tooltip
															title={
																status.items[itemIndex - 1]?.approver !== null &&
																item.title === 'ДЭС' &&
																wbsTask.resultDocument === null
																	? 'Для согласования необходимо создать документ'
																	: (item.title === 'Координатор ДП' ||
																			item.title === 'Координатор ДЭС') &&
																	  !wbsTask.assignedUsers?.length
																	? 'Для согласования необходимо назначить ответственных'
																	: ''
															}
														>
															<Button
																loading={isFetchingVisas}
																icon={<SignatureOutlined />}
																disabled={
																	!(
																		(itemIndex === 0 && item.approver === null) ||
																		status.items[itemIndex - 1].approver !== null
																	) ||
																	(item.title === 'ДЭС' &&
																		(!(
																			wbsTask.assignedUsers?.find(au => au?.id === user?.id) ||
																			user?.isAdmin
																		) ||
																			wbsTask.resultDocument === null)) ||
																	(item.title === 'ДП' &&
																		!wbsTask.assignedUsers?.find(au => au?.id === user?.id) &&
																		!user?.isAdmin) ||
																	(item.title === 'Автор' &&
																		wbsTask.createdBy.id !== user?.id &&
																		!user?.isAdmin) ||
																	((item.title === 'Координатор ДП' ||
																		item.title === 'Координатор ДЭС') &&
																		!wbsTask.assignedUsers?.length)
																}
																onClick={async () => {
																	;<>
																		{wbsTask.wbsTaskStatus.id === WBS_TASK_STATUS.docs ||
																		wbsTask.wbsTaskStatus.id ===
																			WBS_TASK_STATUS.additionalRequestAuthor ||
																		wbsTask.wbsTaskStatus.id ===
																			WBS_TASK_STATUS.additionalRequestAuthorDP ||
																		wbsTask.wbsTaskStatus.id === WBS_TASK_STATUS.additionalRequestDP
																			? (item.title === 'ДП' &&
																					(wbsTask.assignedUsers?.find(au => au?.id === user?.id) ||
																						user?.isAdmin)) ||
																			  (item.title === 'Автор' &&
																					(wbsTask.createdBy.id === user?.id || user?.isAdmin))
																				? await wbsTaskVisasApi
																						.setVisaApproveDp(currentTaskId!, item.id)
																						.then(() => {
																							queryClient.invalidateQueries(['wbsTaskVisas'])
																							queryClient.invalidateQueries(['wbsTaskCard'])
																							queryClient.invalidateQueries(['wbsTasks'])
																							queryClient.invalidateQueries(['wbsTasksDiagram'])
																						})
																						.catch((error: AxiosError<IErrorDetail>) =>
																							modal.error({
																								zIndex: 9999999999,
																								content: error.response?.data.detail,
																								centered: true,
																								onOk: () => {
																									closeModal()
																								}
																							})
																						)
																				: canEditAssignEconomy && !!wbsTask.assignedUsers?.length
																				? await wbsTaskVisasApi
																						.setVisaApproveCoordinatorDP(currentTaskId!, item.id)
																						.then(() => {
																							queryClient.invalidateQueries(['wbsTaskVisas'])
																							queryClient.invalidateQueries(['wbsTaskCard'])
																							queryClient.invalidateQueries(['wbsTasks'])
																							queryClient.invalidateQueries(['wbsTasksDiagram'])
																						})
																						.catch((error: AxiosError<IErrorDetail>) =>
																							modal.error({
																								zIndex: 9999999999,
																								content: error.response?.data.detail,
																								centered: true,
																								onOk: () => {
																									closeModal()
																								}
																							})
																						)
																				: false
																			: wbsTask.wbsTaskStatus.id === WBS_TASK_STATUS.inProgress
																			? item.title === 'ДЭС' &&
																			  (wbsTask.assignedUsers?.find(au => au?.id === user?.id) ||
																					user?.isAdmin) &&
																			  wbsTask.resultDocument !== null
																				? await wbsTaskVisasApi
																						.setVisaApproveDes(currentTaskId!, item.id)
																						.then(() => {
																							queryClient.invalidateQueries(['wbsTaskVisas'])
																							queryClient.invalidateQueries(['wbsTaskCard'])
																							queryClient.invalidateQueries(['wbsTasks'])
																							queryClient.invalidateQueries(['wbsTasksDiagram'])
																						})
																						.catch((error: AxiosError<IErrorDetail>) =>
																							modal.error({
																								zIndex: 9999999999,
																								content: error.response?.data.detail,
																								centered: true,
																								onOk: () => {
																									closeModal()
																								}
																							})
																						)
																				: canEditAssignDesign && !!wbsTask.assignedUsers?.length
																				? await wbsTaskVisasApi
																						.setVisaApproveCoordinatorDES(currentTaskId!, item.id)
																						.then(() => {
																							queryClient.invalidateQueries(['wbsTaskVisas'])
																							queryClient.invalidateQueries(['wbsTaskCard'])
																							queryClient.invalidateQueries(['wbsTasks'])
																							queryClient.invalidateQueries(['wbsTasksDiagram'])
																						})
																						.catch((error: AxiosError<IErrorDetail>) =>
																							modal.error({
																								zIndex: 9999999999,
																								content: error.response?.data.detail,
																								centered: true,
																								onOk: () => {
																									closeModal()
																								}
																							})
																						)
																				: false
																			: false}
																	</>
																}}
															>
																Согласовать
															</Button>
														</Tooltip>
													)
												)}
											</div>
										</Descriptions.Item>
									))}
								</Descriptions>
							)}
						</Card>
					))}
			</Space>
		</Modal>
	)
}
