import {
	FileExcelOutlined,
	FileGifOutlined,
	FileImageOutlined,
	FileJpgOutlined,
	FileOutlined,
	FilePdfOutlined,
	FilePptOutlined,
	FileTextOutlined,
	FileWordOutlined,
	FileZipOutlined
} from '@ant-design/icons'
import { FC, ReactNode } from 'react'

export const ImageIcons: FC<any> = (format: string) => {
	const imageIcons: Record<string, ReactNode> = {
		pdf: <FilePdfOutlined style={{ color: 'darkOrange', fontSize: 24 }} />,
		jpg: (
			<div className="issue-file-cursor">
				<FileJpgOutlined style={{ color: 'red', fontSize: 24 }} />
			</div>
		),
		png: (
			<div className="issue-file-cursor">
				<FileImageOutlined style={{ color: '#4173e6', fontSize: 24 }} />
			</div>
		),
		gif: <FileGifOutlined style={{ color: '#4173e6', fontSize: 24 }} />,
		xls: <FileExcelOutlined style={{ color: 'green', fontSize: 24 }} />,
		xlsx: <FileExcelOutlined style={{ color: 'green', fontSize: 24 }} />,
		ppt: <FilePptOutlined style={{ color: '#4173e6', fontSize: 24 }} />,
		pptx: <FilePptOutlined style={{ color: '#4173e6', fontSize: 24 }} />,
		txt: <FileTextOutlined style={{ color: '#4173e6', fontSize: 24 }} />,
		bmp: (
			<div className="issue-file-cursor">
				<FileImageOutlined style={{ color: '#4173e6', fontSize: 24 }} />
			</div>
		),
		dib: (
			<div className="issue-file-cursor">
				<FileImageOutlined style={{ color: '#4173e6', fontSize: 24 }} />
			</div>
		),
		rle: (
			<div className="issue-file-cursor">
				<FileImageOutlined style={{ color: '#4173e6', fontSize: 24 }} />
			</div>
		),
		tif: (
			<div className="issue-file-cursor">
				<FileImageOutlined style={{ color: '#4173e6', fontSize: 24 }} />
			</div>
		),
		ico: (
			<div className="issue-file-cursor">
				<FileImageOutlined style={{ color: '#4173e6', fontSize: 24 }} />
			</div>
		),
		wmf: (
			<div className="issue-file-cursor">
				<FileImageOutlined style={{ color: '#4173e6', fontSize: 24 }} />
			</div>
		),
		emf: (
			<div className="issue-file-cursor">
				<FileImageOutlined style={{ color: '#4173e6', fontSize: 24 }} />
			</div>
		),
		odt: (
			<div className="issue-file-cursor">
				<FileOutlined style={{ color: '#4173e6', fontSize: 24 }} />
			</div>
		),
		odf: (
			<div className="issue-file-cursor">
				<FileOutlined style={{ color: '#4173e6', fontSize: 24 }} />
			</div>
		),
		ods: (
			<div className="issue-file-cursor">
				<FileOutlined style={{ color: '#4173e6', fontSize: 24 }} />
			</div>
		),
		odp: (
			<div className="issue-file-cursor">
				<FileOutlined style={{ color: '#4173e6', fontSize: 24 }} />
			</div>
		),
		doc: <FileWordOutlined style={{ color: '#4173e6', fontSize: 24 }} />,
		docx: <FileWordOutlined style={{ color: '#4173e6', fontSize: 24 }} />,
		zip: <FileZipOutlined style={{ color: 'red', fontSize: 24 }} />,
		rar: (
			<div className="issue-file-cursor">
				<FileOutlined style={{ color: '#4173e6', fontSize: 24 }} />
			</div>
		),
		csv: (
			<div className="issue-file-cursor">
				<FileOutlined style={{ color: '#4173e6', fontSize: 24 }} />
			</div>
		),
		msg: (
			<div className="issue-file-cursor">
				<FileOutlined style={{ color: '#4173e6', fontSize: 24 }} />
			</div>
		)
	}
	return <>{imageIcons[format]}</>
}
