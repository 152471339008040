import { useMutation, useQueryClient } from '@tanstack/react-query'
import { quartzSettingsApi } from './quartz-settings-api'
import { TQuartzSettingsForm, TQuartzSettingsUpdateDto } from '../model/quartz-settings-schema'
import dayjs from 'dayjs'
import { DATE_FORMAT } from 'shared/constants'
import { notification } from 'antd'
import { AxiosError } from 'axios'
import { IErrorDetail } from 'interfaces/IBase'

export const useQuartzSettingsUpdate = () => {
	const queryClient = useQueryClient()
	return useMutation({
		mutationKey: ['quartzSettings', 'update'],
		mutationFn: async ({ id, dto }: { id: string; dto: TQuartzSettingsForm }) => {
			const mergeDto: TQuartzSettingsUpdateDto = {
				...dto,
				dateRestriction: dayjs(dto.dateRestriction).isValid()
					? dayjs(dto.dateRestriction).format(DATE_FORMAT.dtoWithHours)
					: null
			}
			quartzSettingsApi.update(id, mergeDto)
		},
		onSuccess: () => {
			notification.success({
				message: 'Алгоритм успешно обновлён',
				placement: 'topLeft'
			})
			setTimeout(() => {
				queryClient.invalidateQueries({ queryKey: ['quartzSettings'] })
			}, 300)
		},
		onError: (error: AxiosError<IErrorDetail>) => {
			notification.error({
				message: `Во время обновления произошла ошибка. Код ошибки: ${error.response?.status}`,
				description: error.response?.data?.detail,
				placement: 'topLeft'
			})
		}
	})
}
