import { useQuery } from '@tanstack/react-query'
import { uniqBy } from 'lodash'
import { getAuthors, getContainers, getTypeSection } from 'services/UmdService'
import { setTotalItems } from 'store/asmSlice'
import { useAppDispatch, useAppSelector } from './appReduxHook'

export const useAsm = (limit?: number) => {
	const project = useAppSelector(state => state.environment.project)
	const filters = useAppSelector(state => state.asm.filters)
	const currentPage = useAppSelector(state => state.asm.listState.currentPage)
	const sort = useAppSelector(state => state.asm.sort)
	const dispatch = useAppDispatch()
	return useQuery({
		queryKey: ['asm', project?.id, filters, currentPage, sort, limit],
		queryFn: async () => {
			const asmList = await getContainers({
				project: project!,
				filters: filters,
				offset: currentPage! > 1 ? currentPage! * 50 - 50 : 0,
				sortState: sort,
				limit
			}).then(data => data.data)
			dispatch(setTotalItems(asmList.totalItems))
			return asmList.data
		},
		refetchOnWindowFocus: false
	})
}

export const useAsmTypeSections = (workPackageId?: string) => {
	return useQuery({
		queryKey: ['asmTypeSections', workPackageId],
		queryFn: async () => {
			return await getTypeSection(workPackageId).then(data => data)
		},
		refetchOnWindowFocus: false
	})
}

export const useAsmAuthors = (projectId?: string[]) => {
	return useQuery({
		queryKey: ['asmAuthors', projectId],
		queryFn: async () => {
			// if (projectId) {
				const data = await getAuthors(projectId!).then(data => data.data)
				return uniqBy(data, 'authorId')
			// } return null
		},
		refetchOnWindowFocus: false
	})
}
