import { Card, Col, Row } from 'antd'
import { FC, useEffect } from 'react'
import { WbsStatusReportApi } from '../api/wbs-status-report-api'
import { useWbsStatusReportState } from '../model/wbs-status-report-state'
import { WbsStatusReportCreate } from './WbsStatusReportCreate'
import { WbsStatusReportTable } from './WbsStatusReportTable'

interface IProps {}

export const WbsStatusReport: FC<IProps> = () => {
	const { filters, currentPage, setData, WbsStatusReportData, type } = useWbsStatusReportState()

	useEffect(() => {
		if (WbsStatusReportData?.length) {
			WbsStatusReportApi.getWbsStatusReport({
				settings: filters,
				limit: 50,
				offset: currentPage === 1 ? 0 : currentPage * 50 - 50,
				type
			}).then(data => {
				setData(data.containers.data)
			})
		}
	}, [currentPage])

	return (
		<Card>
			<Row justify="space-between">
				<Col>
					<Row gutter={8}>
						<Col>
							<WbsStatusReportCreate />
						</Col>
					</Row>
				</Col>
			</Row>
			<WbsStatusReportTable />
		</Card>
	)
}
