import { Card, Col, Flex, Row, Space, Tabs } from 'antd'
import { useAppSelector } from 'hooks/appReduxHook'
import _ from 'lodash'
import { FC } from 'react'
import { PERMISSIONS } from 'shared/constants'
import { useCheckCommonPermissions } from 'shared/useCheckPermissions'
import { DiagramsFilter } from 'widgets/diagrams/ui/DiagramsFilters'
import { SummaryDashboard } from 'widgets/diagrams/ui/summary-dashboard'
import { ChecklistsDashboard } from 'widgets/diagrams/ui/checklists-dashboard'
import { ContainerDashboard } from 'widgets/diagrams/ui/containers-dashboard'
import { IssueDashboard } from 'widgets/diagrams/ui/issue-dashboard'
import { OrdinanceDashboard } from 'widgets/diagrams/ui/ordinance-dashboard'
import { OrdinanceEliminatedDashboard } from 'widgets/diagrams/ui/ordinance-eliminated-dashboard'
import { OrdinanceFinesDashboard } from 'widgets/diagrams/ui/ordinance-fines-dashboard'
import { RatingDashboard } from 'widgets/diagrams/ui/rating-dashboard'
import { WbsTaskDashboard } from 'widgets/diagrams/ui/wbs-task-dashboard'
import { WbsTaskCard } from 'widgets/wbs-task-card'
import TabPane from 'antd/es/tabs/TabPane'
import { HomeOutlined, StarOutlined } from '@ant-design/icons'
import { DiagramsProjectFilters } from 'widgets/diagrams-project/ui/diagrams-project-filters'
import { DiagramsProjectCalendar } from 'widgets/diagrams-project/ui/diagrams-project-calendar'
import { DiagramsProjectCharacteristics } from 'widgets/diagrams-project/ui/diagrams-project-characteristics'
import { DiagramsProjectOrdinanceFines } from 'widgets/diagrams-project/ui/diagrams-project-ordinance-fines'
import { DiagramsProjectIssueType } from 'widgets/diagrams-project/ui/diagrams-project-issue-type'
import { DiagramsProjectOrdinance } from 'widgets/diagrams-project/ui/diagrams=project-ordinance'
import { DiagramsProjectOrdinanceEliminated } from 'widgets/diagrams-project/ui/diagrams-project-ordinance-eliminated'
import { DiagramsProjectCheckLists } from 'widgets/diagrams-project/ui/diagrams-project-checklists'
import { DiagramsProjectIssue } from 'widgets/diagrams-project/ui/diagrams-project-issue'
import { IssueTypeTableDashboard } from 'widgets/diagrams/ui/issue-type-table-dashboard'
import { OrdinanceFinesMonthDashboard } from 'widgets/diagrams/ui/ordinance-fines-month-dashboard'

const HomePage: FC = () => {
	// const { data: viewer } = useViewer()
	// console.log('first', viewer)
	const checkPermission = useCheckCommonPermissions()

	const { contractors, project, user } = useAppSelector(state => state.environment)

	const showWidgets = {
		issue: checkPermission([PERMISSIONS.IssueView]),
		wbsTask: checkPermission([PERMISSIONS.WbsTasksView])
	}

	const mainContent =
		// showWidgets.issue || showWidgets.wbsTask ? (
		user?.isAdmin ? (
			<>
				<WbsTaskCard />
				<div className="dashboard__block">
					<Row align="stretch" gutter={[30, 30]}>
						{/* <Col span={24} style={{ marginLeft: '8px', fontSize: 32 }}>
							MyABS
						</Col> */}
						<Col span={24}>
							<DiagramsFilter />
						</Col>

						{showWidgets.issue && (
							<Col span={4}>
								<SummaryDashboard />
							</Col>
						)}
						{showWidgets.issue && (
							<Col span={10}>
								<IssueTypeTableDashboard />
							</Col>
						)}
						{showWidgets.issue && (
							<Col span={10}>
								<RatingDashboard />
							</Col>
						)}
						{showWidgets.issue && (
							<Col span={8}>
								<IssueDashboard />
							</Col>
						)}
						{showWidgets.issue && (
							<Col span={8}>
								<OrdinanceDashboard />
							</Col>
						)}
						{showWidgets.issue && (
							<Col span={8}>
								<OrdinanceEliminatedDashboard />
							</Col>
						)}
						{showWidgets.issue && (
							<Col span={8}>
								<ChecklistsDashboard />
							</Col>
						)}
						{showWidgets.issue && (
							<Col span={8}>
								<OrdinanceFinesDashboard />
							</Col>
						)}
						<Col span={8}>
							<ContainerDashboard />
						</Col>
						{showWidgets.issue && (
							<Col span={24}>
								<OrdinanceFinesMonthDashboard />
							</Col>
						)}
						{showWidgets.wbsTask && (
							<Col span={showWidgets.issue ? 24 : 16}>
								<WbsTaskDashboard />
							</Col>
						)}
					</Row>
				</div>
			</>
		) : (
			<Flex justify="center" align="center" style={{ width: '100%', height: '100%' }}>
				<div className="at-bg">
					<Card
						style={{
							backgroundColor: '#ffffff80',
							padding: '5rem',
							width: '100%',
							textAlign: 'center'
						}}
					>
						<Space direction="vertical" align="center">
							<h1 style={{ color: 'grey', textTransform: 'uppercase', margin: 0 }}>
								Добро пожаловать
							</h1>
							{!!contractors?.length === false && !!project && (
								<h3>В выбранном проекте нет компаний</h3>
							)}
						</Space>
					</Card>
				</div>
			</Flex>
		)

	return (
		<Tabs defaultActiveKey="1" type="card">
			<TabPane
				tab={
					<span>
						<HomeOutlined /> Главная страница
					</span>
				}
				key="1"
			>
				{mainContent}
			</TabPane>

			{showWidgets.issue && (
				<TabPane
					tab={
						<span>
							<StarOutlined /> Проекты
						</span>
					}
					key="2"
					disabled={!user?.isAdmin}
				>
					<div className="dashboard__block">
						<Row align="stretch" gutter={[30, 30]}>
							<Col span={24}>
								<DiagramsProjectFilters />
							</Col>
							<Col span={10}>
								<DiagramsProjectCharacteristics />
							</Col>
							<Col span={14}>
								<DiagramsProjectCalendar />
							</Col>
							<Col span={11}>
								<DiagramsProjectIssueType />
							</Col>
							<Col span={13}>
								<DiagramsProjectCheckLists />
							</Col>
							<Col span={12}>
								<DiagramsProjectOrdinance />
							</Col>
							<Col span={12}>
								<DiagramsProjectOrdinanceEliminated />
							</Col>
							<Col span={12}>
								<DiagramsProjectIssue />
							</Col>
							<Col span={12}>
								<DiagramsProjectOrdinanceFines />
							</Col>
						</Row>
					</div>
				</TabPane>
			)}
		</Tabs>
	)
}

export default HomePage
