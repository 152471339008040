import { Card, Descriptions, List } from 'antd'
import { DescriptionsItemType } from 'antd/es/descriptions'
import dayjs from 'dayjs'
import { useCallback, useState } from 'react'
import { DATE_FORMAT } from 'shared/constants'
import { useQuartzSettings } from '../api/use-quartz-settings'
import { TQuartzSettings } from '../model/quartz-settings-schema'
import { QuartzSettingsForm } from './quartz-settings-form'

export const QuartzSettings = () => {
	const { data, isFetching } = useQuartzSettings()
	const [selectedItem, setSelectedItem] = useState<TQuartzSettings | undefined>(undefined)
	const quartzItems = useCallback(
		(item: TQuartzSettings): DescriptionsItemType[] => {
			return [
				{
					key: 'description',
					label: 'Описание',
					children: item.description
				},
				{
					key: 'cron',
					label: 'Время запуска',
					children: item.cron
				},
				{
					key: 'dateRestriction',
					label: 'Дата включения',
					children: dayjs(item.dateRestriction).isValid()
						? dayjs(item.dateRestriction).format(DATE_FORMAT.view)
						: '-'
				}
			]
		},
		[data]
	)

	return (
		<div className="dashboard__block">
			<QuartzSettingsForm item={selectedItem} onClose={() => setSelectedItem(undefined)} />
			<List
				rowKey="id"
				dataSource={data?.sort(
					(a, b) =>
						(a.enabled === b.enabled ? 0 : a.enabled ? -1 : 1) ||
						dayjs(a.dateRestriction).diff(b.dateRestriction)
				)}
				loading={isFetching}
				grid={{
					column: 3,
					gutter: [16, 4]
				}}
				renderItem={item => (
					<List.Item
						colStyle={{ height: '100%' }}
						style={{ height: 'calc(100% - 1rem)' }}
						onClick={() => setSelectedItem(item)}
					>
						<Card
							title={item.name}
							extra={item.enabled && 'Активный'}
							hoverable={true}
							style={{
								background: item.enabled ? '#e5f1e8' : 'white',
								height: '100%'
							}}
						>
							<Descriptions bordered size="small" column={1} items={quartzItems(item)} />
						</Card>
					</List.Item>
				)}
			/>
		</div>
	)
}
