import { IPagination } from 'interfaces/IPagination'
import { API } from 'services/ApiService'
import {
	ICreateOrdinanceFine,
	IFineAuthors,
	IOrdinanceFineFilters,
	IOrdinanceFineStatuses,
	IUpdateOrdinanceFine
} from '../model/fine-interface'

export const ordinanceFineApi = {
	async get({
		filters,
		limit = 50,
		offset = 0
	}: {
		filters: IOrdinanceFineFilters
		limit?: number
		offset: number
	}) {
		return await API<IPagination<IOrdinanceFineFilters[]>>({
			url: '/v1/ordinanceFine',
			method: 'get',
			params: { offset, limit, ...filters }
		}).then(async response => response)
	},
	async getOne(ordinanceFineId: string) {
		return await API<IOrdinanceFineFilters>({
			url: `/v1/ordinanceFine/${ordinanceFineId}`,
			method: 'get'
		}).then(async response => response.data)
	},
	async getStatuses() {
		return await API<IOrdinanceFineStatuses[]>({
			url: '/v1/ordinanceFineStatus',
			method: 'get'
		}).then(response => response.data)
	},
	async updateCard(ordinanceFineId: string, dto: IUpdateOrdinanceFine) {
		return await API({
			url: `/v1/ordinanceFine/${ordinanceFineId}/update`,
			method: 'patch',
			data: dto
		}).then(async response => response.data)
	},
	async create(dto: ICreateOrdinanceFine) {
		return await API({
			url: `/v1/ordinanceFine`,
			method: 'post',
			data: dto
		}).then(response => response.status)
	},
	async cancel(ordinanceFineId: string, dto: FormData) {
		return await API({
			url: `/v1/ordinanceFine/${ordinanceFineId}/cancel`,
			method: 'patch',
			data: dto
		}).then(async response => response.data)
	},
	async attachment(ordinanceFineId: string) {
		return await API({
			url: `/v1/ordinanceFine/${ordinanceFineId}/attachment`,
			method: 'get'
		}).then(async response => response.data)
	},
	async getAuthors(projectId?: string) {
		return await API<IFineAuthors[]>({
			url: '/v1/ordinanceFine/authors',
			method: 'get',
			params: {projectId}
		}).then(response => response.data)
	}
}
