import { IBaseConstructionObject } from 'interfaces/IBase'
import { TBase } from 'shared/schemas'
import _ from 'lodash'

export const markDisabledNodes = (items: IBaseConstructionObject[] | TBase[]): IBaseConstructionObject[] | TBase[]=> {
	return filterArray(items)?.map((item: any) => {
		const disabled =
			item?.isAccessible === false
				return {
					...item,
					disabled,
					children: markDisabledNodes(item?.children)
				}
	})
}

export const filterArray = (items: IBaseConstructionObject[] | TBase[]): IBaseConstructionObject[] | TBase[] => {
	return items?.map((item: any) => {
	const filterChildren = item?.children ? filterArray(item?.children) : [];
    if (item?.isShow === true && item?.isDeleted === false) {
      return { ...item, children: filterChildren }
    } else if (filterChildren?.length > 0) {
      return { ...item, children: filterChildren }
    }
	return null
  })?.filter(elem => elem !== null)
}

export const flattenTree: any = (nodes: any[]) =>
		_.flatMapDeep(nodes, node =>
			node.children ? [_.omit(node, 'children'), ...flattenTree(node.children)] : node
		)