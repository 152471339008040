import { IBase, IBasePotentialRedactors } from 'interfaces/IBase'
import { IContainerUpdateDto } from 'interfaces/IContainer'
import { IEstimatePositionDto } from 'interfaces/IEstimatePosition'
import { IStatusRouteItem } from 'pages/unmodelPage/wbsContainer'
import { API } from 'services/ApiService'
import { TEstimateType } from 'shared/schema'
import { TAdjustmentExcel } from 'shared/schemas/adjustment-excel-schema'
import {
	IAdjustmentNote,
	IAdjustmentPosition,
	IAdjustmentPositionCommentsUpdate,
	IAdjustmentPositionSduUpdate,
	IAdjustmentSection,
	IAdjustmentToggleIsNominatedSduDto
} from '..'

const url = '/v1/adjustment'
enum ROUTE {
	wbs = 'container',
	local = 'estimate'
}

export const adjustmentApi = {
	async create(containerId: string, name: string, type: TEstimateType, projectId: string) {
		return await API({
			url: `${url}`,
			method: 'post',
			data: { id: containerId, name, isContainer: type === 'wbs', projectId }
		}).then(response => response.data)
	},

	async get(containerId: string, type: TEstimateType) {
		return await API({
			url: `${url}/${ROUTE[type]}/${containerId}`,
			method: 'get',
			params: {
				projectId: null
			}
		}).then(response => response.data)
	},

	async getSections(containerId: string, type: TEstimateType) {
		return await API<IAdjustmentSection[]>({
			url: `${url}/${ROUTE[type]}/${containerId}/sections`,
			method: 'get',
			params: {
				projectId: null
			}
		}).then(response => response.data)
	},

	async getSectionPositions(containerId: string, sectionId: string, type: TEstimateType) {
		return await API<IAdjustmentPosition[]>({
			url: `${url}/${ROUTE[type]}/${containerId}/sections/${sectionId}/estimatePositions`,
			method: 'get',
			params: {
				projectId: null
			}
		}).then(response => response.data)
	},

	async saveStatement(data: IEstimatePositionDto, method: string) {
		return await API({
			url: `${url}/estimatePosition`,
			method: method,
			params: {
				projectId: null
			},
			data
		}).then(response => response.data)
	},

	async saveStatementSdu(data: IAdjustmentPositionSduUpdate) {
		return await API({
			url: `${url}/estimatePositionPriceCorrection`,
			method: 'patch',
			params: {
				projectId: null
			},
			data
		}).then(response => response.data)
	},
	async saveStatementSduNominated(data: IAdjustmentPositionSduUpdate) {
		return await API({
			url: `${url}/estimatePositionPriceCorrectionIsNominated`,
			method: 'patch',
			params: {
				projectId: null
			},
			data
		}).then(response => response.data)
	},

	async saveStatementComments(data: IAdjustmentPositionCommentsUpdate, type: TEstimateType, justification: 'commentSdu' | 'justification') {
		return await API({
			url: `${url}/${ROUTE[type]}/${justification}`,
			method: 'patch',
			params: {
				projectId: null
			},
			data
		}).then(response => response.data)
	},

	async copyEstimatePosition(data: string[]) {
		return await API<string[]>({
			url: `${url}/estimatePosition/copy`,
			method: 'post',
			params: {
				projectId: null
			},
			data
		}).then(response => response.data)
	},

	async deleteEstimatePosition(data: string[]) {
		return await API({
			url: `${url}/estimatePosition`,
			method: 'delete',
			params: {
				projectId: null
			},
			data
		}).then(response => response.data)
	},

	async deleteForClosedEstimatePosition(id: string) {
		return await API({
			url: `${url}/${id}/closed`,
			params: {
				projectId: null
			},
			method: 'patch'
		}).then(response => response.data)
	},

	async toggleIsNominatedSdu(data: IAdjustmentToggleIsNominatedSduDto) {
		return await API({
			url: `${url}/estimatePositionMaterialIsNominated`,
			method: 'patch',
			params: {
				projectId: null
			},
			data
		}).then(response => response.data)
	},

	async getContainerVisas(containerId: string, type: TEstimateType) {
		return await API<IStatusRouteItem[]>({
			url: `${url}/${ROUTE[type]}/${containerId}/visas`,
			method: 'get',
			params: {
				projectId: null
			}
		}).then(response => response.data)
	},

	async setVisaApprove(containerId: string, type: TEstimateType, visaId?: string) {
		return await API({
			url: `${url}/${ROUTE[type]}/${containerId}/approve`,
			method: 'post',
			params: { visaId, projectId: null }
		}).then(response => response.data)
	},

	async unsetVisaApprove(
		containerId: string,
		type: TEstimateType,
		visaId: string
		// containerStatusId: string
	) {
		return await API({
			url: `${url}/${ROUTE[type]}/${containerId}/unapprove`,
			method: 'post',
			params: { projectId: null },
			data: { visaId }
		}).then(response => response.data)
	},

	async getCanVisaApprove(containerId: string, type: TEstimateType) {
		return await API<string[]>({
			url: `${url}/${ROUTE[type]}/${containerId}/approvalAvailable`,
			method: 'get',
			params: {
				projectId: null
			}
		}).then(response => response.data)
	},

	async createCorrection(containerId: string, name: string, type: TEstimateType) {
		return await API({
			url: `${url}/${ROUTE[type]}/${containerId}/correction`,
			method: 'post',
			params: {
				projectId: null
			},
			data: { name }
		}).then(response => response.status)
	},

	async savePriceSecond(data: IAdjustmentPositionSduUpdate) {
		return await API({
			url: `${url}/EstimatePositionPriceOuter`,
			method: 'patch',
			params: {
				projectId: null
			},
			data
		}).then(response => response.data)
	},
	async exportToExcel(adjustmentId: string, withPrice?: boolean) {
		return await API({
			url: `/v1/export/exportAdjustmentToExcel`,
			method: 'get',
			params: { adjustmentId, withPrice, projectId: null },
			responseType: 'blob'
		}).then(async response => response)
	},
	async updContainer(containerId: string, dto: IContainerUpdateDto) {
		return await API({
			url: `${url}/${containerId}`,
			method: 'patch',
			data: dto,
			params: {
				projectId: null
			}
		}).then(async response => response.data)
	},
	async exportAdjustmentToExcel({
		adjustmentId,
		withPrice
	}: {
		adjustmentId?: string
		withPrice: boolean
	}) {
		if (adjustmentId) {
			return await API<TAdjustmentExcel>({
				url: '/v1/export/exportAdjustmentToExcel',
				method: 'get',
				params: { adjustmentId, withPrice, consolidateConditionalFormatRanges: false, projectId: null }
			}).then(async response => response.data)
		}
	},

	async getPotentialRedactors(projectId: string) {
		return await API<IBasePotentialRedactors[]>({
			url: `${url}/potentialRedactors`,
			method: 'get',
			params: {
				projectId
			}
		}).then(response => response.data)
	},
	async actualize(id: string, type: TEstimateType) {
		return await API<boolean>({
			url: `/v1/adjustment/${ROUTE[type]}/${id}/sections/actualize`,
			method: 'post',
			params: {
				projectId: null
			}
		}).then(response => response.data)
	},
	async saveNote(
		data: IAdjustmentNote,
		typeContainer: TEstimateType,
		type: 'noteSdu' | 'noteDz' | 'noteDp'
	) {
		return await API({
			url: `${url}/${ROUTE[typeContainer]}/estimatePositions/${type}`,
			method: 'patch',
			data,
			params: {
				projectId: null
			}
		}).then(response => response.data)
	}
}
