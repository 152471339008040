import { useQuery } from '@tanstack/react-query'
import { quartzSettingsApi } from './quartz-settings-api'

export const useQuartzSettings = () => {
	return useQuery({
		queryKey: ['quartzSettings'],
		queryFn: async () => {
			return await quartzSettingsApi.getAll()
		},
		refetchOnWindowFocus: false,
		cacheTime: 0
	})
}
