import { SaveOutlined, SignatureOutlined } from '@ant-design/icons'
import { useQueryClient } from '@tanstack/react-query'
import { Button, Col, FormInstance, notification, Row, Select } from 'antd'
import { useWatch } from 'antd/es/form/Form'
import { stat } from 'fs'
import { useAppSelector } from 'hooks/appReduxHook'
import { FC } from 'react'
import { WBS_TASK_STATUS } from 'shared/constants/wbs-task-types'
import { IWbsTaskStatus } from 'widgets/wbs-task-registry/model/wbs-task-registry-interface'
import { useWbsTaskVisasApprove } from 'widgets/wbs-task-visas/api/wbs-task-visas-queries'
import { useWbsTaskVisasState } from 'widgets/wbs-task-visas/model/wbs-task-visas-state'
import { WbsTaskStatusModal } from 'widgets/wbs-task-visas/ui/wbs-task-visas-model'
import { useWbsTaskCard } from '../api/wbs-task-card-queries'

interface IProps {
	isNew: boolean
	canEdit: boolean
	canEditAttachment: boolean
	form: FormInstance
	isLoading: boolean
	status: IWbsTaskStatus
	wbsTaskId: string
}

export const WbsTaskCardHeader: FC<IProps> = ({
	isNew,
	canEdit,
	canEditAttachment,
	form,
	isLoading,
	status,
	wbsTaskId
}) => {
	const { user } = useAppSelector(state => state.environment)
	const { data } = useWbsTaskCard()
	const queryClient = useQueryClient()
	const assignedUsers = useWatch('assignedUsers', form)
	const { openModal } = useWbsTaskVisasState()
	const { mutate: mutateApprove } = useWbsTaskVisasApprove()
	const approval = async () => {
		if (wbsTaskId) {
			await mutateApprove(
				{ wbsTaskId },
				{
					onSuccess: () => {
						notification.success({
							message: 'Заявка отправлена на согласование',
							placement: 'topLeft'
						})
						queryClient.invalidateQueries(['wbsTasks'])
						queryClient.invalidateQueries(['wbsTasksDiagram'])
						queryClient.invalidateQueries(['wbsTaskCard'])
					},
					onError: (error: any) => {
						notification.error({
							message: 'Произошла ошибка во время отправки',
							description: error.response?.data?.detail,
							placement: 'topLeft'
						})
					}
				}
			)
		}
	}

	return (
		<>
			<WbsTaskStatusModal />
			<Row style={{ width: '480px' }} wrap={false} align="middle" gutter={16} justify="end">
				{!isNew && (
					<div
						style={{
							color: '#fff',
							whiteSpace: 'nowrap'
						}}
					>{`Статус : ${status ? status.description : 'Новый'}`}</div>
				)}
				{!isNew &&
					(status.name === 'New' ? (
						<Col>
							<Button
								icon={<SignatureOutlined />}
								onClick={async () => {
									await approval()
								}}
								ghost
								disabled={!((user?.isAdmin || data?.createdBy.id === user?.id) && canEdit)}
								style={{ borderColor: '#fff', color: '#fff', width: 230 }}
							>
								Отправить на согласование
							</Button>
						</Col>
					) : (
						<Col>
							<Button
								icon={<SignatureOutlined />}
								onClick={openModal}
								ghost
								style={{ borderColor: '#fff', color: '#fff', width: 190 }}
							>
								Статус согласования
							</Button>
						</Col>
					))}
				<Col>
					<Button
						htmlType="submit"
						icon={<SaveOutlined />}
						onClick={() => form.submit()}
						disabled={(!canEdit && !canEditAttachment) || status?.id === WBS_TASK_STATUS.done}
						loading={isLoading}
						ghost
						style={{ borderColor: '#fff', color: '#fff', width: 120 }}
					>
						Сохранить
					</Button>
				</Col>
			</Row>
		</>
	)
}
