import { Button, Modal } from 'antd'
import { useViewerPolicyMutation } from '../../entities/viewer/api/viewer-policy-mutate'
import { FC, useState } from 'react'
import { useViewerState } from 'viewer-state-new'

const CookiesModal: FC = () => {
	const [isModalOpen, setIsModalOpen] = useState(!localStorage.getItem('acceptCookies'))
	const viewer = useViewerState(state => state.viewer)
	const { mutate } = useViewerPolicyMutation()
	const handleSubmit = () => {
		localStorage.setItem('acceptCookies', 'true')
		if (viewer && !viewer?.policyConfirm) {
			mutate()
		} else {
			setIsModalOpen(false)
		}
	}

	return (
		<Modal
			title="Мы используем файлы cookie"
			open={isModalOpen || (viewer && !viewer?.policyConfirm)}
			onOk={handleSubmit}
			closeIcon={null}
			footer={[
				<Button key="submit" type="primary" onClick={handleSubmit}>
					Принять и закрыть
				</Button>
			]}
		>
			<p>
				Продолжая использовать сайт, вы соглашаетесь с{' '}
				<a download="Политика обработки файлов cookie.pdf" href="/files/policy_cookies.pdf">
					условиями использования файлов cookie
				</a>
				, и{' '}
				<a
					target="_blank"
					rel="noreferrer"
					download="Политика_ПД_ИГ АБСОЛЮТ.pdf"
					href="/files/policy.pdf"
				>
					политикой обработки персональных данных
				</a>
			</p>
		</Modal>
	)
}

export default CookiesModal
