import { Col, Empty, Modal, Row } from 'antd'
import {
	BarElement,
	CategoryScale,
	Chart as ChartJS,
	Legend,
	LinearScale,
	Title,
	Tooltip
} from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { FC } from 'react'
import { Bar } from 'react-chartjs-2'
import { useDiagramState } from 'widgets/diagrams/model/diagrams-state'
import { useDiagramsProjectState } from '../model/diagrams-project-state'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'hooks/appReduxHook'
import { useQueryClient } from '@tanstack/react-query'
import { getRelativePosition } from 'chart.js/helpers'
import { Chart } from 'chart.js/dist'
import { setCurrentChecklistFilters } from 'store/checklistsSlice'
import { changeCurrentProject } from '../model/diagrams-project-helpers'
import { useCountCheckLists } from '../model/diagrams-project-query'
import { PERMISSIONS } from 'shared/constants'
import { useCheckPermissions } from 'shared/useCheckPermissions'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ChartDataLabels)
interface IProps {}

export const DiagramsProjectCheckLists: FC<IProps> = props => {
	const { authors, projectId, contractorIds } = useDiagramsProjectState()
	const { data: checklistData } = useCountCheckLists()
	const navigate = useNavigate()
	const dispatch = useAppDispatch()
	const { user } = useAppSelector(state => state.environment)
	const queryClient = useQueryClient()
	const checkPermissions = useCheckPermissions()

	// const { data: checklistData } = useCountChecklistsDashboard()
	const newStatus = [
		checklistData!?.countCheckListsUksNdcNew,
		checklistData!?.countCheckListsUkKcNdcNew
	]
	const inProgressStatus = [
		checklistData!?.countCheckListsUksNdcInProgress,
		checklistData!?.countCheckListsUkKcInProgress
	]
	const warrantyStatus = [
		checklistData!?.countCheckListsUksNdcWarranty,
		checklistData!?.countCheckListsUkKcWarranty
	]
	const completedStatus = [
		checklistData!?.countCheckListsUksNdcCompleted,
		checklistData!?.countCheckListsUkKcCompleted
	]

	const uksNdc =
		checklistData!?.countCheckListsUksNdcNew +
		checklistData!?.countCheckListsUksNdcInProgress +
		checklistData!?.countCheckListsUksNdcWarranty +
		checklistData!?.countCheckListsUksNdcCompleted
	const ukKc =
		checklistData!?.countCheckListsUkKcNdcNew +
		checklistData!?.countCheckListsUkKcInProgress +
		checklistData!?.countCheckListsUkKcWarranty +
		checklistData!?.countCheckListsUkKcCompleted

	const labels = ['Приемка НДК', 'Приемка УК']
	const datasets = [
		{
			label: 'Новый',
			data: newStatus,
			hoverBackgroundColor: '#FFAD14',
			backgroundColor: '#FFAD14'
		},
		{
			label: 'В работе',
			data: inProgressStatus,
			hoverBackgroundColor: '#8099A8',
			backgroundColor: '#8099A8'
		},
		{
			label: 'Принято под гарантийные обязательства',
			data: warrantyStatus,
			hoverBackgroundColor: '#7A77D9',
			backgroundColor: '#7A77D9'
		},
		{
			label: 'Завершен',
			data: completedStatus,
			hoverBackgroundColor: '#1677FF',
			backgroundColor: '#1677FF'
		}
	]

	const datasetsNew: any[] = datasets.map(dataPoint => ({
		...dataPoint
	}))

	datasets[0].data?.forEach((_: any, datasetIndex: any) => {
		const all_i = datasets
			.map((d, dataIndex) => [d.data[datasetIndex], dataIndex])
			.sort(([data1], [data2]) => data1 - data2)
		all_i.forEach(([data, datasetIdx]) => {
			datasetsNew[datasetIdx].data[datasetIndex] = [0, data]
		})
	})

	const data = {
		labels: labels,
		datasets: datasets.map((d, i) => ({
			...d,
			datalabels: {
				anchor: 'end' as const,
				align: 'top' as const,
				offset: 3,
				formatter: (value: any[]) => (value?.[1]! > 0 ? value![1] : ''),
				color: '#000000',
				font: {
					size: 14,
					weight: 400,
					family: 'Segoe UI'
				}
			}
		}))
	}

	const config = {
		type: 'bar',
		data,
		width: '500%',
		height: '150px',
		options: {
			scales: {
				x: {
					grid: {
						drawBorder: true,
						drawTicks: false
					},
					ticks: {
						drawTicks: false,
						padding: 20,
						callback: function (value: any, index: any, values: any) {
							return (
								labels[value] + ': ' + `${(labels[value] === 'Приемка УК' ? ukKc : uksNdc) ?? 0}`
							)
						}
					}
				},
				y: {
					grid: {
						drawBorder: true,
						drawTicks: false
					}
				}
			},
			layout: {
				padding: {
					top: 20
				}
			},
			onHover: (event: any, elements: any) => {
				const canvasPosition: any = getRelativePosition(event, elements)
				const chart: Chart = canvasPosition.chart
				const points = chart.getElementsAtEventForMode(event, 'nearest', { intersect: true }, true)
				event.native.target.style.cursor = points[0] ? 'pointer' : 'default'
			},
			onClick: (event: any, elements: any) => {
				const canvasPosition: any = getRelativePosition(event, elements)
				const chart: Chart = canvasPosition.chart
				const points = chart.getElementsAtEventForMode(event, 'nearest', { intersect: true }, true)
				if (points[0]) {
					const point = points[0]
					if (
						!checkPermissions(
							[PERMISSIONS.ChecklistsUkKc, PERMISSIONS.ChecklistsUksNdc],
							projectId!
						)
					) {
						Modal.error({
							title: 'Ошибка доступа',
							content:
								'Внимание! Раздел не доступен, поскольку у Вас не хватает прав. Обратитесь к администратору.'
						})
						return
					}
					if (point.datasetIndex === 0) {
						changeCurrentProject(projectId!, user, dispatch, queryClient)
						dispatch(
							setCurrentChecklistFilters({
								filters: {
									ownerIds: authors!,
									status: [0],
									templateType: point.index === 0 ? 0 : 1,
									contractorIds: contractorIds!
								}
							})
						)
					} else if (point.datasetIndex === 1) {
						changeCurrentProject(projectId!, user, dispatch, queryClient)
						dispatch(
							setCurrentChecklistFilters({
								filters: {
									ownerIds: authors!,
									status: [1],
									templateType: point.index === 0 ? 0 : 1,
									contractorIds: contractorIds!
								}
							})
						)
					} else if (point.datasetIndex === 2) {
						changeCurrentProject(projectId!, user, dispatch, queryClient)
						dispatch(
							setCurrentChecklistFilters({
								filters: {
									ownerIds: authors!,
									status: [4],
									templateType: point.index === 0 ? 0 : 1,
									contractorIds: contractorIds!
								}
							})
						)
					} else if (point.datasetIndex === 3) {
						changeCurrentProject(projectId!, user, dispatch, queryClient)
						dispatch(
							setCurrentChecklistFilters({
								filters: {
									ownerIds: authors!,
									status: [2],
									templateType: point.index === 0 ? 0 : 1,
									contractorIds: contractorIds!
								}
							})
						)
					}
					navigate('/checklist')
				}
			},
			responsive: true,
			plugins: {
				legend: {
					labels: {
						boxWidth: 12,
						boxHeight: 12,
						padding: 15,
						fontFamily: 'Segoe UI',
						fontWeight: 'normal',
						fontSize: '14px',
						color: '#2C303D'
					},
					position: 'bottom' as const,
					display: true,
					onClick: (e: any) => null,
					title: {
						display: true,
						color: 'black'
					}
				},
				tooltip: {
					callbacks: {
						labelColor: function (context: any) {
							return {
								borderWidth: 3,
								borderColor: context.dataset.backgroundColor,
								backgroundColor: context.dataset.backgroundColor
							}
						},
						label: function (context: any) {
							let label = context.dataset.label || ''

							if (label) {
								label += ': '
							}
							if (context.parsed.y !== null) {
								label += context.parsed.y
							}
							return ' ' + label
						}
					}
				}
			}
		}
	}

	return (
		<Row
			wrap={false}
			// gutter={12}
			style={{
				border: '1px solid #ddd',
				borderRadius: 8,
				padding: 8,
				background: 'white',
				height: '450px'
				// width: '100%'
			}}
		>
			<Col
				span={24}
				style={{
					padding: '1rem',
					background: !(checklistData?.totalCheckLists! > 0) ? '#ffffff' : '#fefefe'
				}}
			>
				<Row>
					<Col>
						<span
							style={{
								fontFamily: 'Segoe UI',
								fontWeight: 'normal',
								fontSize: '22px',
								color: '#000000',
								marginRight: '8px'
							}}
						>
							Чек-листы
						</span>
					</Col>
					<Col style={{ alignContent: 'center', marginTop: 5 }}>
						<span
							style={{
								fontFamily: 'Segoe UI',
								fontWeight: 400,
								fontSize: '14px',
								marginLeft: '5px',
								backgroundColor: '#EEEEEE',
								borderRadius: '21px',
								padding: '4px',
								color: '#2C303',
								width: '43px',
								height: '20px'
							}}
						>
							{checklistData?.totalCheckLists ?? 0}
						</span>
					</Col>
				</Row>
				<div style={{ height: 300, marginTop: 20 }}>
					{checklistData?.totalCheckLists! > 0 ? (
						<Bar {...config} />
					) : (
						<span
							style={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								height: '100%'
							}}
						>
							<Empty />
						</span>
					)}
				</div>
			</Col>
		</Row>
	)
}
