import { Row } from '@tanstack/table-core'
import { Row as AntRow, Col, Tooltip } from 'antd'
import { useAppSelector } from 'hooks/appReduxHook'
import { memo, useCallback, useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useWbsPermits } from 'widgets/wbs'
import { useShallow } from 'zustand/react/shallow'
import {
	EstimatePositionChat,
	EstimatePositionMenu,
	EstimatePositionMove,
	IEstimatePosition,
	IEstimateSection,
	useEstimateState
} from '..'
import { CONTAINER_STATUS } from 'shared/constants'
import { useContainerVisas } from 'widgets/wbs/model/wbs-queries'
import { IStatusRouteItemVisa } from 'pages/unmodelPage/wbsContainer'
import { IBase } from 'interfaces/IBase'

interface IProps {
	row: IEstimatePosition
	callBack?: () => void
	mainRow?: Row<IEstimatePosition>
	visaIssued: boolean
}

export const EstimatePositionTitle = memo(function (params: IProps) {
	const [_, setSearchParams] = useSearchParams()

	const { row, callBack, mainRow, visaIssued } = params
	const parent: Row<IEstimateSection> | undefined = useMemo(
		() => mainRow?.getParentRow() as unknown as Row<IEstimateSection>,
		[]
	)
	const { user } = useAppSelector(state => state.environment)
	const { container } = useEstimateState(
		useShallow(state => ({
			container: state.container,
			sections: state.sections
		}))
	)
	const permits = useWbsPermits(container?.project?.id)
	const isAuthor = useMemo(
		() =>
			(user?.isAdmin ||
				container?.author.id === user?.id ||
				container?.redactors.some(r => r.id === user?.id)) ??
			false,
		[]
	)

	const isLocalEstimate = useMemo(() => typeof container?.isLocalEstimate !== 'undefined', [])
	const canEditCorrectLocalEstimate = useMemo(
		() => !container?.ready && container?.isLocalEstimate === false && permits.canLocalEdit,
		[container]
	)

	const canMove = useMemo(
		() =>
			!container?.ready && parent.original?.children
				? parent.original.children.length > 1 &&
				  (container?.status.name === CONTAINER_STATUS.new ||
						container?.status.name === CONTAINER_STATUS.preparation) &&
				  ((!isLocalEstimate && permits.canEdit && isAuthor) ||
						(canEditCorrectLocalEstimate && isAuthor)) &&
				  !visaIssued
				: false,
		[container]
	)

	const canCreate = useMemo(
		() =>
			!container?.ready &&
			'hasTechnologies' in parent?.original! &&
			parent?.original?.hasTechnologies === true &&
			(container?.status.name === CONTAINER_STATUS.new ||
				container?.status.name === CONTAINER_STATUS.preparation) &&
			((!isLocalEstimate && permits.canEdit && isAuthor) ||
				(canEditCorrectLocalEstimate && isAuthor)) &&
			!visaIssued,
		[container]
	)
	const canClose = useMemo(
		() =>
			!container?.ready &&
			canEditCorrectLocalEstimate &&
			isAuthor &&
			(container?.status.name === CONTAINER_STATUS.new ||
				container?.status.name === CONTAINER_STATUS.preparation) &&
			!visaIssued,
		[container]
	)
	const canDelete = useMemo(
		() =>
			!container?.ready &&
			(container?.status.name === CONTAINER_STATUS.new ||
				container?.status.name === CONTAINER_STATUS.preparation) &&
			((!isLocalEstimate && permits.canEdit && isAuthor) ||
				(canEditCorrectLocalEstimate && isAuthor)) &&
			!visaIssued,
		[container]
	)

	const title = useMemo(
		() => (
			<Tooltip
				rootClassName="tooltip-wide"
				title={`${row.order} - ${row.workName} ${row.technologyIsDeleted ? 'АРХИВ' : ''}`}
			>
				<div className="ellipsis-block">
					{`${row.order} - ${row.workName}`}
					{row.technologyIsDeleted && <span style={{ marginLeft: '8px' }}>АРХИВ</span>}
				</div>
			</Tooltip>
		),
		[row]
	)

	const toggleRow = useCallback(() => (callBack ? callBack() : null), [])

	const setToEdit = useCallback(() => {
		container && setSearchParams({ estimatePosition: row.id })
	}, [])

	return (
		<AntRow style={{ width: '100%' }} align="middle" justify="space-between" wrap={false}>
			<Col flex={1} onClick={toggleRow} onDoubleClick={setToEdit}>
				{title}
			</Col>
			<Col>
				<AntRow gutter={4} align="middle" wrap={false}>
					{!container?.ready && (
						<Col>
							<AntRow gutter={8} align="middle" wrap={false} className="app-wbs-move-controls">
								{canMove && (
									<Col>
										<EstimatePositionMove
											row={row}
											section={parent}
											isMultiply={false}
											isAuthor={isAuthor}
										/>
									</Col>
								)}
							</AntRow>
						</Col>
					)}
					<Col>
						<EstimatePositionChat row={row} />
					</Col>
					{mainRow && (
						<Col>
							<EstimatePositionMenu
								row={row}
								mainRow={mainRow}
								canCreate={canCreate!}
								canClose={canClose!}
								canDelete={canDelete!}
							/>
						</Col>
					)}
				</AntRow>
			</Col>
		</AntRow>
	)
})
