import { Button, Table } from 'antd'
import { ColumnsType } from 'antd/es/table'
import { FC, useCallback } from 'react'
import { NavLink } from 'react-router-dom'
import { PAGE_LIMIT } from 'shared/constants'
import { useMonitoringFilesStatuses } from '../api/monitoring-queries'
import { MonitoringFilesStatuses } from '../api/monitoring-schema'
import { useMonitoringState } from '../model/monitoring-state'
import { ReloadOutlined } from '@ant-design/icons'
import { useForwardingFile } from '../api/use-forwarding-file'
import { ForwardingFileModal } from './forwarding-file-modal'

export const FilesStatusesMonitoring: FC = () => {
	const { data, isFetching } = useMonitoringFilesStatuses()
	const { mutate } = useForwardingFile()
	const { totalItems, currentPage, onPagination } = useMonitoringState()

	const onForwardingFile = useCallback((record: MonitoringFilesStatuses) => {
		mutate({
			ordinanceId: record.ordinanceId,
			attachmentId: record.attachmentId
		})
	}, [])

	const columns: ColumnsType<MonitoringFilesStatuses> = [
		{
			dataIndex: 'ordinanceName',
			title: 'Номер предписания'
		},
		{
			dataIndex: 'ordinanceId',
			title: 'Id предписания',
			render: value => (
				<NavLink to={`/control/ordinance/${value}`} target="_blank">
					{value}
				</NavLink>
			)
		},
		{
			dataIndex: 'attachmentName',
			title: 'Файл'
		},
		{
			dataIndex: 'attachmentStatusId',
			title: 'Id статуса файла'
		},
		{
			dataIndex: 'attachmentTypeId',
			title: 'Id типа документа'
		},
		{
			dataIndex: 'projectName',
			title: 'Проект'
		},
		{
			dataIndex: 'projectId',
			title: 'Id проекта'
		},
		{
			key: 'control',
			width: 32,
			render: (_, record) => (
				<Button icon={<ReloadOutlined />} onClick={() => onForwardingFile(record)} />
			)
		}
	]

	return (
		<div className="dashboard__block" style={{ borderRadius: '0 0 .8rem .8rem' }}>
			<ForwardingFileModal />
			<Table
				loading={isFetching}
				className="app-table-types"
				rowKey="attachmentId"
				size="small"
				pagination={{
					hideOnSinglePage: true,
					total: totalItems,
					pageSize: PAGE_LIMIT,
					showSizeChanger: false,
					onChange: onPagination,
					current: currentPage
				}}
				bordered
				dataSource={data}
				columns={columns as any}
			/>
		</div>
	)
}
