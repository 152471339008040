import { useQueryClient } from '@tanstack/react-query'
import History from 'UI/history'
import { Button, Col, Drawer, Form, Modal, Row, Tabs, message } from 'antd'
import dayjs from 'dayjs'
import { useAppDispatch, useAppSelector } from 'hooks/appReduxHook'
import OrdinanceDrawer from 'pages/ordinancePage/ordinanceDrawer'
import { FC, useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { accessVerification } from 'shared/accessVerification'
import { DATE_FORMAT, PERMISSIONS } from 'shared/constants'
import { checkPermissionByProject, getNearestWorkingDate } from 'shared/helpers'
import { useCheckPermissions } from 'shared/useCheckPermissions'
import { setEnvironmentProject } from 'store/environmentSlice'
import { IFineForm } from 'widgets/fine/model/fine-interface'
import { useOrdinanceFineStatuses } from 'widgets/fine/model/fine-queries'
import { useOrdinanceFineState } from 'widgets/fine/model/fine-state'
import { IFine, TFineCardType } from '../model/fine-card-interface'
import { useFineCard, useGetFineAttachment } from '../model/fine-card-query'
import { useFineState } from '../model/fine-card-state'
import { FineCardAttachments } from './FineCardAttachments'
import { FineCardDetails } from './FineCardDetails'
import { FineCardExtra } from './FineCardExtra'
interface IProps {
	type: TFineCardType
	onUpdate?: () => void
}

enum FineStatuses {
	new = '10a2dcec-9ad0-4c1b-b38f-293a097b0c63',
	canceled = 'c3d93cdb-103e-4219-9771-af00ce8fdf5b',
	collected = '9dd3fa1f-f182-47be-bd63-3d2e7f5fcd0b',
	exhibited = 'dd2b7a56-4e21-4f4f-a59b-e983efbf61cd'
}

export const FineCard: FC<IProps> = props => {
	const checkPermissions = useCheckPermissions()
	const { setShowDrawer, showDrawer } = useOrdinanceFineState()
	const navigate = useNavigate()
	const { pathname } = useLocation()
	const dispatch = useAppDispatch()
	const { user, project } = useAppSelector(state => state.environment)
	const checkPermission = useCheckPermissions()
	const allCompanies = checkPermissions([PERMISSIONS.AllCompanies], project?.id)
	const { id: currentId } = useParams()
	const queryClient = useQueryClient()
	const [activeKey, setActiveKey] = useState('details')
	const [showOrdinance, setShowOrdinance] = useState(false)
	const { setId, unset, onSave, attachment, setAttachment, setCancelationModalView, ...data } =
		useFineState()
	const [fineForm] = Form.useForm<IFineForm>()
	const currentStatus = Form.useWatch<string | undefined>('status', fineForm)
	const { data: fineStatuses, isFetching } = useOrdinanceFineStatuses()
	useFineCard(props.type)
	useGetFineAttachment((data[props.type] as IFine)?.id)
	const OrdinanceFineToCancel = checkPermission([PERMISSIONS.OrdinanceFineToCancel], project?.id)

	useEffect(() => {
		fineForm.setFieldValue('status', (data[props.type] as IFine)?.ordinanceFineStatus?.id)
	}, [data[props.type]])

	const saveFine = async (values: IFineForm) => {
		await onSave(values, data[props.type] as IFine, (data[props.type] as IFine)?.id)
			.then(() => {
				message.success('Данные обновлены')
				queryClient.invalidateQueries({ queryKey: ['ordinanceFine'] })
				props?.onUpdate!()
				onClose()
			})
			.catch(error =>
				message.error(error.response.data.detail ?? 'Произошла ошибка во время сохранения')
			)
	}

	const onClose = () => {
		unset(props.type)
		setActiveKey('details')
		setAttachment(undefined)
		props.type === 'edit' && currentId && navigate('/control/fines')
	}

	useEffect(() => {
		currentId && pathname.includes('/control/fines') && setId('edit', currentId)
	}, [currentId])

	useEffect(() => {
		const fine = data[props.type] as IFine
		if (props.type === 'edit' && fine && fine.project?.id) {
			checkPermissionByProject(
				Object.keys(PERMISSIONS).findIndex(p => p === 'OrdinanceFine') + 1,
				fine?.project?.id
			).then(validate => {
				if (
					(!validate && pathname.includes('fines/')) ||
					(!allCompanies &&
						fine.contractor.id !== user?.contractorId &&
						pathname.includes('fines/'))
				) {
					setShowDrawer(false)
					Modal.warning({
						title: 'Внимание',
						content:
							'Автоштраф не доступен по ссылке, поскольку у Вас не хватает прав. Обратитесь к Администратору',
						onOk: () => navigate('/')
					})
				} else {
					const userProject = user?.projects?.find(p => p.id === fine?.project?.id)
					setShowDrawer(true)
					if (project?.id !== fine?.project?.id && userProject) {
						dispatch(
							setEnvironmentProject({
								option: 'project',
								data: userProject!
							})
						)
					}
				}
			})
		}
	}, [data[props.type]])

	return (
		<Form name="fineForm" form={fineForm} onFinish={saveFine}>
			<Drawer
				className={'app-drawer'}
				classNames={{
					header:
						'app-drawer-header app-drawer-header--' +
						(currentStatus
							? Object.entries(FineStatuses).find(x => x[1] === currentStatus)?.[0] ?? 'new'
							: 'new')
				}}
				// id={
				// 	`ordinance-fine-drawer-header--${currentStatus}`}
				styles={{
					mask: {
						background: props.type === 'inner' ? 'rgba(0,0,0,0.15)' : 'rgba(0,0,0,0.45)'
					}
				}}
				open={typeof data[props.type] === 'object' && showDrawer}
				onClose={onClose}
				width={window.outerWidth < 1300 ? '75vw' : '50vw'}
				title={
					props.type === 'create'
						? 'Новый штраф'
						: data[props.type] && (data[props.type] as IFine)?.name
						? `Штраф № ${(data[props.type] as IFine).name} от ${dayjs(
								(data?.[props.type] as IFine)?.createdAt
						  ).format(DATE_FORMAT.view)}`
						: `Штраф № ${(data[props.type] as IFine)?.number} от ${dayjs(
								(data?.[props.type] as IFine)?.createdAt
						  ).format(DATE_FORMAT.view)}`
				}
				extra={
					<FineCardExtra
						data={typeof data[props.type] === 'object' ? (data[props.type] as IFine) : undefined}
						form={fineForm}
						fineStatuses={fineStatuses ?? []}
						type={props.type}
						isLoading={isFetching}
					/>
				}
				footer={
					<Row align="middle" gutter={16}>
						{OrdinanceFineToCancel && !(props.type === 'create') && (
							<Col>
								<Button
									onClick={() => {
										setCancelationModalView(true)
									}}
									disabled={
										!(
											OrdinanceFineToCancel &&
											(data[props.type] as IFine)?.ordinanceFineStatus?.identityName !==
												'Canceled' &&
											!dayjs().isAfter(
												getNearestWorkingDate('dto', dayjs((data[props.type] as IFine)?.createdAt))

												// + dayjs((data[props.type] as IFine)?.createdAt).format('THH:mm:ss')
											)
										)
									}
								>
									Снять штраф
								</Button>
							</Col>
						)}
						<Col>
							<i>
								<span style={{ color: 'red' }}>*</span> Снять «Штраф» возможно в течении одного
								рабочего дня с момента формирования
							</i>
						</Col>
					</Row>
				}
			>
				{props.type === 'edit' && <FineCard type="inner" />}
				{(data[props.type] as IFine)?.ordinance?.id && showOrdinance && (
					<OrdinanceDrawer
						ordinanceId={(data[props.type] as IFine).ordinance.id}
						open={showOrdinance}
						onClose={() => setShowOrdinance(false)}
					/>
				)}
				<Tabs
					activeKey={activeKey}
					onChange={setActiveKey}
					tabBarGutter={16}
					items={[
						{
							label: 'Детали',
							key: 'details',
							children: (
								<FineCardDetails
									type={props.type}
									data={data[props.type]}
									onShowOrdinance={() => setShowOrdinance(true)}
								/>
							)
						},
						{
							label: 'Файлы',
							key: 'files',
							disabled: props.type === 'create',
							children: <FineCardAttachments fineId={(data[props.type] as IFine)?.id} />
						},
						{
							label: 'История',
							key: 'history',
							disabled: props.type === 'create',
							children: <History id={(data[props.type] as IFine)?.id} target={'ordinanceFine'} />
						}
					]}
				/>
			</Drawer>
		</Form>
	)
}
