import { Button, Col, DatePicker, Form, message, Modal, Row, Select, Typography } from 'antd'
import dayjs from 'dayjs'
import { FC, useEffect } from 'react'
import { ExportMobileReportToExcel } from '../api/mobile-report-api'
import { IMobileForm } from '../model/mobile-report-interface'
import { useMobileReportState } from '../model/mobile-report-state'
import { useAppSelector } from 'hooks/appReduxHook'
import { MONTHS } from 'shared/constants'

interface IProps {
	show: boolean
	onClose: () => void
}

const { Title } = Typography

export const MobileReportDownloadModal: FC<IProps> = ({ show, onClose }) => {
	const { isBlocked, filters, setFilters, setIsBlocked } = useMobileReportState()
	const [MobileForm] = Form.useForm<IMobileForm>()
	const projects = useAppSelector(state => state.environment.user?.projects)

	const onModalClose = () => {
		setIsBlocked(false)
		MobileForm.resetFields()
		onClose()
	}

	useEffect(() => {
		MobileForm.setFieldsValue({
			year: undefined,
			// projects: [project?.id],
			months: []
		})
		setFilters({
			year: filters?.year ?? +dayjs(new Date()).format('YYYY'),
			projectIds: filters?.projectIds!,
			months: filters?.months ?? []
		})
	}, [show])

	const onDownloadMobileReport = async () => {
		setIsBlocked(true)
		const data = MobileForm.getFieldsValue()
		const dto = {
			projectIds: data.projects,
			year: dayjs(data?.year!).isValid()
				? +dayjs(data.year).format('YYYY')
				: +dayjs(new Date()).format('YYYY'),
			months:
				data.year === undefined || data.year === null || data.months?.length === 0
					? []
					: data.months ?? []
		}

		await ExportMobileReportToExcel(dto)
			.then(response => {
				const href = window.URL.createObjectURL(new Blob([response.data]))
				const link = document.createElement('a')
				link.href = href
				link.setAttribute('download', 'Отчет по работе с мобильным приложением.xlsx')
				document.body.appendChild(link)
				link.click()
				document.body.removeChild(link)
				window.URL.revokeObjectURL(href)
				onModalClose()
			})
			.catch(() => message.error('Во время загрузки файла произошла ошибка'))
	}

	const setAllProjects = () => {
		MobileForm.setFieldValue(
			'projects',
			projects?.map(p => p.id)
		)
		MobileForm.validateFields()
	}

	const setAllMonths = () => {
		MobileForm.setFieldValue(
			'months',
			Object.entries(MONTHS)
				.filter(m => m[0] !== '0')
				.map((m: any) => (m[0] = +m[0]))
		)
		MobileForm.validateFields()
	}

	return (
		<Form
			form={MobileForm}
			name="mobileDownloadForm"
			onFinish={onDownloadMobileReport}
			disabled={isBlocked}
		>
			<Modal
				destroyOnClose
				onCancel={onClose}
				centered
				open={show}
				title="Скачать отчёт"
				okButtonProps={{ disabled: isBlocked }}
				footer={
					<Row justify="space-between">
						<Col>
							<Button danger disabled={isBlocked} onClick={onClose}>
								Отмена
							</Button>
						</Col>
						<Col>
							<Button disabled={isBlocked} type="primary" onClick={() => MobileForm.submit()}>
								Скачать
							</Button>
						</Col>
					</Row>
				}
			>
				<Row justify="center">
					<Col
						style={{
							display: 'flex',
							flexDirection: 'column',
							gap: '.5rem',
							padding: '1rem',
							width: '100%'
						}}
					>
						<Col>
							<Row align="middle" justify="space-between">
								<Col>
									<Title level={5}>Проекты:</Title>
								</Col>
								<Col>
									<Button type="link" size="small" onClick={setAllProjects}>
										Выбрать все
									</Button>
								</Col>
							</Row>
							<Form.Item
								name="projects"
								rules={[
									{
										required: true,
										message: 'Выберите хотя бы один проект'
									}
								]}
							>
								<Select
									mode="multiple"
									maxTagCount="responsive"
									allowClear
									style={{ width: '100%' }}
									options={projects?.map(p => ({
										key: p.id,
										label: p.name,
										value: p.id
									}))}
								/>
							</Form.Item>
						</Col>
						<Col>
							<Title level={5}>Год:</Title>
							<Form.Item
								name="year"
								rules={[
									{
										required: true,
										message: 'Выберите год'
									}
								]}
							>
								<DatePicker picker="year" format="YYYY" style={{ width: '100%' }} />
							</Form.Item>
						</Col>
						<Col>
							<Row align="middle" justify="space-between">
								<Col>
									<Title level={5}>Месяцы:</Title>
								</Col>
								<Col>
									<Button type="link" size="small" onClick={setAllMonths}>
										Выбрать все
									</Button>
								</Col>
							</Row>
							<Form.Item name="months">
								<Select
									mode="multiple"
									maxTagCount="responsive"
									allowClear
									style={{ width: '100%' }}
									options={(Object.entries(MONTHS) as any)
										.filter((month: any) => month[0] !== '0')
										.map((m: any) => ({
											key: m[0],
											label: m[1],
											value: +m[0]
										}))}
								/>
							</Form.Item>
						</Col>
					</Col>
				</Row>
			</Modal>
		</Form>
	)
}
