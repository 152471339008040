import { QuestionCircleOutlined } from '@ant-design/icons'
import { DatePicker, Flex, Form, Input, Modal, Switch, Tooltip } from 'antd'
import locale from 'antd/es/date-picker/locale/ru_RU'
import cron from 'cron-validate'
import dayjs from 'dayjs'
import { absRequiredMark } from 'features/abs-form'
import { useCallback, useEffect } from 'react'
import { DATE_FORMAT } from 'shared/constants'
import { useQuartzSettingsUpdate } from '../api/use-quartz-settings-update'
import { TQuartzSettings, TQuartzSettingsForm } from '../model/quartz-settings-schema'
const defaultRule = { required: true, message: 'Поле не должно быть пустым' }

type TProps = {
	item?: TQuartzSettings
	onClose: () => void
}

export const QuartzSettingsForm = ({ item, onClose }: TProps) => {
	const [form] = Form.useForm<TQuartzSettingsForm>()

	useEffect(() => {
		if (item)
			form.setFieldsValue({
				...item,
				dateRestriction:
					item && dayjs(item.dateRestriction).isValid() ? dayjs(item.dateRestriction) : null
			})
		else form.resetFields()
	}, [item])

	const { mutate } = useQuartzSettingsUpdate()

	const onUpdateHandler = useCallback(
		(dto: TQuartzSettingsForm) => {
			if (item) mutate({ id: item.id, dto }, { onSuccess: onClose })
		},
		[item]
	)

	return (
		<Modal
			title={item?.name}
			open={!!item}
			okText="Сохранить"
			onOk={() => form.submit()}
			onCancel={onClose}
		>
			<Form
				colon={false}
				labelCol={{ span: 7 }}
				labelAlign="left"
				name="quartzSettingsForm"
				form={form}
				requiredMark={absRequiredMark}
				onFinish={onUpdateHandler}
			>
				<Form.Item
					name="cron"
					label={
						<span>
							Время запуска{' '}
							<Tooltip
								title={
									<Flex vertical>
										<b>Настройка cron:</b>
										<span>00 18 13 ? * * * - раз в день, в 16:18</span>
										<span>0 0 0/1 * * ? * - раз в час</span>
										<span>0 0/10 * * * ? * - раз в 10 минут</span>
										<span>0 0/1 * * * ? * - раз в минут</span>
									</Flex>
								}
							>
								<QuestionCircleOutlined />
							</Tooltip>
						</span>
					}
					rules={[
						defaultRule,
						() => ({
							validator(_, value) {
								const res = cron(value, {
									override: {
										useSeconds: true,
										useYears: true,
										useBlankDay: true
									}
								})
								if (res.isValid()) return Promise.resolve()
								return Promise.reject(
									new Error(`Значение не соответствует cron формату. ${res?.error}`)
								)
							}
						})
					]}
				>
					<Input />
				</Form.Item>
				<Form.Item name="dateRestriction" label="Дата включения">
					<DatePicker
						locale={locale}
						allowClear
						style={{
							width: '100%'
						}}
						format={DATE_FORMAT.view}
						hideDisabledOptions
						// disabledDate={current => {
						// 	return current && current < dayjs().startOf('day')
						// }}
					/>
				</Form.Item>
				<Form.Item name="enabled" label="Включена" valuePropName="checked">
					<Switch size="small" />
				</Form.Item>
			</Form>
		</Modal>
	)
}
