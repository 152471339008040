import { useQuery } from '@tanstack/react-query'
import { contractsGuideApi } from './contracts-guide-api'
import { useContractsGuideState } from '../model/contracts-guide-state'

export const useContractsGuide = () => {
	const { currentPage, filters, setTotalItems } = useContractsGuideState()
	return useQuery({
		queryKey: ['contractsGuide', currentPage, filters],
		queryFn: async () => {
			return await contractsGuideApi.getRegistry({ currentPage, filters }).then(res => {
				setTotalItems(res.totalItems)
				return res
			})
		},
		select: res => res.data,
		refetchOnWindowFocus: false
	})
}

export const useGuideContractors = () => {
	return useQuery({
		queryKey: ['contractsGuide', 'contractors'],
		refetchOnWindowFocus: false,
		queryFn: async () => {
			return await contractsGuideApi.getContractors()
		}
	})
}

export const useGuideSubContractors = (contractorId?: string) => {
	return useQuery({
		queryKey: ['contractsGuide', 'subContractors', contractorId],
		refetchOnWindowFocus: false,
		queryFn: async () => {
			return contractorId ? await contractsGuideApi.getSubContractors(contractorId) : []
		}
	})
}

export const useGuideProjects = () => {
	return useQuery({
		queryKey: ['contractsGuide', 'projects'],
		refetchOnWindowFocus: false,
		queryFn: async () => {
			return await contractsGuideApi.getProjects()
		}
	})
}

export const useGuideProjects1c = () => {
	return useQuery({
		queryKey: ['contractsGuide', 'projects1c'],
		refetchOnWindowFocus: false,
		queryFn: async () => {
			return await contractsGuideApi.getProjects1c()
		}
	})
}

export const useGuideConstructionObjects = () => {
	return useQuery({
		queryKey: ['contractsGuide', 'constructionObjects'],
		refetchOnWindowFocus: false,
		queryFn: async () => {
			return await contractsGuideApi.getConstructionObjects()
		}
	})
}
