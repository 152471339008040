import {
	AppstoreFilled,
	AppstoreTwoTone,
	BuildTwoTone,
	FolderFilled,
	FolderOpenFilled
} from '@ant-design/icons'
import { CellContext, ColumnDef, Row } from '@tanstack/react-table'
import { Row as AntRow, Button, Checkbox, Col, Flex, Space, Tooltip } from 'antd'
import { useAppSelector } from 'hooks/appReduxHook'
import { useCallback, useMemo } from 'react'
import { useWbsPermits } from 'widgets/wbs'
import { useShallow } from 'zustand/react/shallow'
import {
	EstimateNominatedCell,
	EstimateNoteCell,
	EstimatePositionMaterialTitle,
	EstimatePositionTitle,
	EstimateSectionTitle,
	useEstimateState
} from '..'
import { RoundedNumber } from '../model/estimate-helper'
import {
	IEstimatePosition,
	IEstimatePositionMaterial,
	IEstimateSection
} from '../model/estimate-schema'
import { EstimateVirtualizeCell } from '../ui/EstimateVirtualizeCell'
import { CONTAINER_STATUS } from 'shared/constants'
import { useContainerVisas } from 'widgets/wbs/model/wbs-queries'
import { IStatusRouteItemVisa } from 'pages/unmodelPage/wbsContainer'
import { IBase } from 'interfaces/IBase'
import {
	useCanVisaApproveLocalEstimate,
	useContainerLocalEstimateVisas
} from 'pages/unmodelPage/localEstimate/model/localEstimateQuery'
import { useCanVisaApprove } from 'pages/unmodelPage/wbsContainer/model/wbsContainerQuery'

export const useEstimateRegistryColumns = () => {
	const { user } = useAppSelector(state => state.environment)
	const { container, data, setSelectedRows, setEstimatePositions } = useEstimateState(
		useShallow(state => ({
			container: state.container,
			data: state.sections,
			setEstimatePositions: state.setEstimatePositions,
			setSelectedRows: state.setSelectedRows
		}))
	)
	const permits = useWbsPermits(container?.project?.id)
	const isAuthor =
		(user?.isAdmin ||
			container?.author.id === user?.id ||
			container?.redactors.some(r => r.id === user?.id)) ??
		false

	const visasQuery =
		typeof container?.isLocalEstimate === 'undefined'
			? useContainerVisas!
			: useContainerLocalEstimateVisas!
	const { data: visas } = visasQuery(container?.id, false)
	const visasContainer = useMemo(
		() => visas?.find(v => v.id === container?.status.id)?.visas,
		[container, visas]
	)

	const { data: canVisaApprove } = useCanVisaApprove(
		typeof container?.isLocalEstimate === 'undefined' ? container?.id : undefined,
		false,
		typeof container?.isLocalEstimate === 'undefined' ? 'wbs' : undefined
	)

	const { data: canVisaApproveLocalEstimate } = useCanVisaApproveLocalEstimate(
		typeof container?.isLocalEstimate !== 'undefined' ? container?.id : undefined,
		false
	)

	const visaIssued = useMemo(
		() =>
			!!visasContainer?.find(
				v =>
					!!(
						typeof container?.isLocalEstimate === 'undefined'
							? canVisaApprove
							: canVisaApproveLocalEstimate
					)?.includes(v.id) && v.visaState !== null
			),
		[container, visas, canVisaApprove, canVisaApproveLocalEstimate, visasContainer]
	)

	const isLocalEstimate = typeof container?.isLocalEstimate !== 'undefined'
	const canEditCorrectLocalEstimate = container?.isLocalEstimate === false && permits.canLocalEdit

	const columns = useMemo<
		ColumnDef<IEstimateSection | IEstimatePosition | IEstimatePositionMaterial>[]
	>(
		() => [
			{
				accessorKey: 'name',
				header: row => (
					<AntRow
						align="middle"
						justify="space-between"
						style={{ width: '100%', justifyContent: 'space-between' }}
					>
						<Col>Наименование МТР, работ/услуг</Col>
						<Col>
							<Button
								type="link"
								onClick={() => {
									const expandedRows = row.table.getRowModel().flatRows.map(elem => {
										if (
											'codifier' in elem.original &&
											elem.original.hasPositions &&
											!elem.original.children
										) {
											setEstimatePositions(
												elem.original.id,
												undefined,
												!row.table.getIsSomeRowsExpanded()
											)
										}
										return (
											(('materialName' in elem.original && elem.original.materialName) ||
												('hasPositions' in elem.original && elem.original.hasPositions === true) ||
												('children' in elem.original && elem.original.children?.length)) &&
											elem.original.id
										)
									})
									// .filter(id => typeof id === 'string')
									const expandedMap = expandedRows.reduce((acc: any, elem: any) => {
										acc[elem] = true
										return acc
									}, {})
									row.table.setExpanded(row.table.getIsSomeRowsExpanded() ? {} : expandedMap)
								}}
							>
								{row.table.getIsSomeRowsExpanded() ? 'Свернуть' : 'Развернуть'}
							</Button>
						</Col>
					</AntRow>
				),
				size: 768,
				maxSize: 768,
				cell: ({ cell, row }) => {
					const record = row.original
					return (
						<Flex className="estimate-position-title" gap={8}>
							{'codifier' in record ? (
								row.getCanExpand() ? (
									row.getIsExpanded() ? (
										<FolderOpenFilled style={{ color: '#feb300' }} />
									) : (
										<FolderFilled style={{ color: '#feb300' }} />
									)
								) : (
									<FolderFilled style={{ color: '#ddd' }} />
								)
							) : 'workName' in record ? (
								<>
									{!container?.ready &&
										!((visaIssued && !user?.isAdmin) || container?.isLocalEstimate === true) &&
										(((container?.status.name === CONTAINER_STATUS.new ||
											container?.status.name === CONTAINER_STATUS.preparation) &&
											((!isLocalEstimate && isAuthor && permits.canEdit) ||
												(canEditCorrectLocalEstimate && isAuthor))) ||
											user?.isAdmin) && (
											<Checkbox
												{...{
													checked: row.getIsSelected(),
													disabled: !row.getCanSelect(),
													indeterminate: row.getIsSomeSelected()
												}}
												onChange={e => {
													row.getToggleSelectedHandler()(e)
													if ('workName' in row.original)
														setSelectedRows(row as Row<IEstimatePosition>)
												}}
											/>
										)}
									{'children' in record && !!record.children?.length ? (
										row.getIsExpanded() ? (
											<AppstoreTwoTone
												onClick={() => row.getToggleExpandedHandler()()}
												twoToneColor="#6fa9fa"
											/>
										) : (
											<AppstoreFilled
												onClick={() => row.getToggleExpandedHandler()()}
												style={{ color: '#6fa9fa' }}
											/>
										)
									) : (
										<AppstoreTwoTone twoToneColor="#9e9e9e" />
									)}
								</>
							) : (
								<BuildTwoTone twoToneColor="#5d9f6c" />
							)}

							{'codifier' in record ? (
								<EstimateSectionTitle
									row={row as Row<IEstimateSection>}
									callBack={() => getEstimatePositions(row)}
									visaIssued={(visaIssued && !user?.isAdmin) || container?.isLocalEstimate === true}
								/>
							) : 'workName' in record ? (
								<EstimatePositionTitle
									row={record as IEstimatePosition}
									mainRow={row as Row<IEstimatePosition>}
									visaIssued={(visaIssued && !user?.isAdmin) || container?.isLocalEstimate === true}
									// callBack={() => row.getToggleExpandedHandler()()}
								/>
							) : (
								<EstimatePositionMaterialTitle row={record as IEstimatePositionMaterial} />
							)}
						</Flex>
					)
				}
			},
			{
				accessorKey: 'measureUnit',
				header: 'Ед.изм',
				size: 100,
				cell: ({ cell, row }) =>
					'measureUnit' in row.original && <center>{row.original.measureUnit?.name}</center>
			},
			{
				accessorKey: 'amount',
				header: 'Кол-во',
				size: 140,
				cell: record =>
					!('codifier' in record.row.original) &&
					('workName' in record.row.original ||
						('materialName' in record.row.original && record.row.original.isExpandable)) ? (
						<EstimateVirtualizeCell
							record={record as CellContext<IEstimatePosition | IEstimatePositionMaterial, any>}
							cellType="amount"
							parents={record.row.getParentRows()}
							visaIssued={(visaIssued && !user?.isAdmin) || container?.isLocalEstimate === true}
						/>
					) : (
						RoundedNumber(record.cell.getValue())
					)
			},
			{
				accessorKey: 'rate',
				header: 'К-т нормы расхода',
				size: 130,
				cell: ({ cell, row }) => RoundedNumber(cell.getValue())
			},
			{
				accessorKey: 'amountWithRate',
				header: 'Кол-во с учётом к-та расхода',
				size: 140,
				cell: ({ cell, row }) => RoundedNumber(cell.getValue())
			},
			{
				header: 'Выполнено',
				size: 240,
				columns: [
					{
						accessorKey: 'confirmedVolumeAmount',
						header: 'Кол-во без НР',
						size: 120,
						cell: record => RoundedNumber(record.cell.getValue())
					},
					{
						accessorKey: 'confirmedVolumeSum',
						header: 'Всего стоимость, руб. с НДС',
						size: 120,
						cell: record => RoundedNumber(record.cell.getValue())
					}
				]
			},
			{
				header: 'Цена за ед.изм., руб. с НДС',
				size: 240,
				columns: [
					{
						accessorKey: 'priceMaterial',
						header: 'Материалы (МТР)',
						size: 120,
						cell: record =>
							('workName' in record.row.original && record.row.original.children?.length === 0) ||
							'materialName' in record.row.original ? (
								<EstimateVirtualizeCell
									record={record as CellContext<IEstimatePosition | IEstimatePositionMaterial, any>}
									cellType="priceMaterial"
									parents={record.row.getParentRows()}
									visaIssued={(visaIssued && !user?.isAdmin) || container?.isLocalEstimate === true}
								/>
							) : (
								RoundedNumber(record.cell.getValue())
							)
					},
					{
						accessorKey: 'priceService',
						header: 'СМР/услуги',
						size: 120,
						cell: record =>
							'workName' in record.row.original ? (
								<EstimateVirtualizeCell
									record={record as CellContext<IEstimatePosition, any>}
									cellType="priceService"
									parents={record.row.getParentRows()}
									visaIssued={(visaIssued && !user?.isAdmin) || container?.isLocalEstimate === true}
								/>
							) : null
					}
				]
			},
			{
				accessorKey: 'totalPrice',
				header: () => (
					<Space
						direction="vertical"
						size="small"
						style={{ rowGap: 0, lineHeight: '100% !important' }}
					>
						<p style={{ margin: 0 }}>Всего за единицу,</p>
						<p style={{ margin: 0, fontSize: 11, lineHeight: 1.2 }}>руб. с НДС</p>
					</Space>
				),
				size: 120,
				cell: ({ cell, row }) => RoundedNumber(cell.getValue())
			},
			{
				header: 'Стоимость за единицу, руб. с НДС',
				size: 300,
				columns: [
					{
						accessorKey: 'totalSumMaterials',
						header: 'Материалы (МТР)',
						size: 150,
						cell: ({ cell, row }) =>
							!('materialName' in row.original)
								? RoundedNumber(
										'workTotal' in row.original
											? row.original.workTotal?.totalSumMaterials
											: cell.getValue()
								  )
								: ''
					},
					{
						accessorKey: 'totalSumService',
						header: 'СМР/услуги',
						size: 150,
						cell: ({ cell, row }) =>
							RoundedNumber(
								'workTotal' in row.original
									? row.original.workTotal?.totalSumService
									: cell.getValue()
							)
					}
				]
			},
			{
				accessorKey: 'totalSum',
				header: () => (
					<Space
						direction="vertical"
						size="small"
						style={{ rowGap: 0, lineHeight: '100% !important' }}
					>
						<p style={{ margin: 0 }}>Всего стоимость</p>
						<p style={{ margin: 0, fontSize: 11, lineHeight: 1.2 }}>руб. с НДС</p>
					</Space>
				),
				size: 150,
				cell: ({ cell, row }) =>
					RoundedNumber(
						'workTotal' in row.original ? row.original.workTotal?.totalSum : cell.getValue()
					)
			},
			{
				accessorKey: 'noteSDU',
				header: 'Примечание СДУ',
				size: 180,
				cell: ({ cell, row }) =>
					!('codifier' in row.original) && <EstimateNoteCell row={row.original} type={'noteSDU'} />
			},
			{
				accessorKey: 'noteDZ',
				header: 'Примечание ДЗ',
				size: 180,
				cell: ({ cell, row }) =>
					!('codifier' in row.original) && <EstimateNoteCell row={row.original} type={'noteDZ'} />
			},
			{
				accessorKey: 'noteDP',
				header: 'Примечание УПВОР',
				size: 180,
				cell: ({ cell, row }) =>
					!('codifier' in row.original) && <EstimateNoteCell row={row.original} type={'noteDP'} />
			},
			{
				accessorKey: 'isNominated',
				header: () => <Tooltip title="Признак номинации">НМТР</Tooltip>,
				size: 80,
				cell: record =>
					'isNominated' in record.row.original && (
						<EstimateNominatedCell
							record={record as CellContext<IEstimatePosition | IEstimatePositionMaterial, any>}
							parents={record.row.getParentRows()}
							visaIssued={(visaIssued && !user?.isAdmin) || container?.isLocalEstimate === true}
						/>
					)
			}
		],
		[data, container]
	)
	const getEstimatePositions = useCallback(
		async (row: Row<IEstimateSection | IEstimatePosition | IEstimatePositionMaterial>) => {
			const record = row.original
			const parents = row.getParentRows()
			if (
				!row.getIsExpanded() &&
				'codifier' in record &&
				typeof record.children === 'undefined' &&
				record.hasPositions
			) {
				setEstimatePositions(record.id, parents)
			}

			return row.getToggleExpandedHandler()()
		},
		[]
	)
	return columns
}
