import { useQueryClient } from '@tanstack/react-query'
import { message, Modal, notification, Space } from 'antd'
import { AxiosError } from 'axios'
import { useAppDispatch, useAppSelector } from 'hooks/appReduxHook'
import { IStatusRouteItemVisa, useWbsContainerState } from 'pages/unmodelPage/wbsContainer'
import { memo, useEffect, useMemo } from 'react'
import { useParams } from 'react-router'
import { useNavigate } from 'react-router-dom'
import { CONTAINER_STATUS, PERMISSIONS } from 'shared/constants'
import { useCheckPermissions } from 'shared/useCheckPermissions'
import { EstimateComments } from 'widgets/estimate-comments'
import { EstimateRelatedDocuments } from 'widgets/estimate-creation-from-vor/ui/EstimateRelatedDocuments'
import { EstimatePosition } from 'widgets/estimate-position-card'
import { EstimatePositionCommentsView } from 'widgets/estimate-position-comments'
import { RegulatoryRequest } from 'widgets/regulatory-request'
import { useWbsPermits, WbsStatusModal } from 'widgets/wbs'
import {
	EstimateContainerHeader,
	EstimateHistory,
	EstimateNote,
	useEstimateContainerQuery,
	useEstimateState
} from '..'
import { EstimateApi } from '../api/estimate-api'
import { Estimate } from './Estimate'
import { EstimateRemoveModal } from './EstimateRemoveModal'
import { useContainerVisas } from 'widgets/wbs/model/wbs-queries'
import {
	useCanVisaApproveLocalEstimate,
	useContainerLocalEstimateVisas
} from 'pages/unmodelPage/localEstimate/model/localEstimateQuery'
import { IBase } from 'interfaces/IBase'
import { useCanVisaApprove } from 'pages/unmodelPage/wbsContainer/model/wbsContainerQuery'

interface IProps {
	type: 'wbs' | 'local'
}

export const EstimateContainer = memo(function (params: IProps) {
	const dispatch = useAppDispatch()
	const navigate = useNavigate()
	const checkPermissions = useCheckPermissions()
	const { user } = useAppSelector(state => state.environment)
	const queryClient = useQueryClient()
	const { data } = useEstimateContainerQuery(params.type)
	const { id: containerId } = useParams()
	const { setContainerType, container } = useEstimateState()
	const permits = useWbsPermits(container?.project?.id)
	const { setCurrentContainerId } = useWbsContainerState()

	const isAuthor =
		(user?.isAdmin ||
			container?.author.id === user?.id ||
			container?.redactors.some(r => r.id === user?.id)) ??
		false

	const visasQuery =
		typeof container?.isLocalEstimate === 'undefined'
			? useContainerVisas!
			: useContainerLocalEstimateVisas!
	const { data: visas } = visasQuery(container?.id, false)
	const visasContainer = useMemo(
		() => visas?.find(v => v.id === container?.status.id)?.visas,
		[container, visas]
	)

	const { data: canVisaApprove } = useCanVisaApprove(
		typeof container?.isLocalEstimate === 'undefined' ? container?.id : undefined,
		false,
		typeof container?.isLocalEstimate === 'undefined' ? 'wbs' : undefined
	)

	const { data: canVisaApproveLocalEstimate } = useCanVisaApproveLocalEstimate(
		typeof container?.isLocalEstimate !== 'undefined' ? container?.id : undefined,
		false
	)

	const visaIssued = useMemo(
		() =>
			!!visasContainer?.find(
				v =>
					!!(
						typeof container?.isLocalEstimate === 'undefined'
							? canVisaApprove
							: canVisaApproveLocalEstimate
					)?.includes(v.id) && v.visaState !== null
			),
		[container, visas, canVisaApprove, canVisaApproveLocalEstimate, visasContainer]
	)

	useEffect(() => {
		setContainerType(params.type)
		setCurrentContainerId(containerId)
	}, [params.type])

	useEffect(() => {
		if (data instanceof AxiosError) {
			Modal.error({
				title: 'Ошибка',
				content: data.response?.data.detail,
				okText: `Ок`,
				onOk: () => navigate('/')
			})
		}
		if (
			!(data instanceof AxiosError) &&
			data?.isActual === false &&
			params.type === 'wbs' &&
			!data.ready &&
			(data.status.name === CONTAINER_STATUS.new ||
				data.status.name === CONTAINER_STATUS.preparation)
			// 	 &&
			// data.projectId === project?.id
		) {
			if (checkPermissions([PERMISSIONS.WbsCreator], data?.project?.id!) && isAuthor) {
				Modal.confirm({
					title: 'Структура ВОР не актуальна',
					content: permits.canEdit
						? 'Для продолжения работы с ВОР необходимо актуализировать данные'
						: 'Для обновления структуры обратитесь к администратору или автору/редактору ВОР',

					okText: permits.canEdit ? 'Обновить' : 'Закрыть',
					onOk: async () =>
						permits.canEdit
							? await EstimateApi.actualize(data.id)
									.then(() => {
										message.success('Данные актуализированы')
										queryClient.invalidateQueries({ queryKey: ['estimateContainer'] })
										queryClient.invalidateQueries({ queryKey: ['estimate'] })
									})
									.catch(err => {
										message.error('Произошла ошибка, обратитесь к администратору')
										navigate('/unmodel')
									})
							: navigate('/unmodel'),
					cancelButtonProps: { style: { display: permits.canEdit ? 'inline' : 'none' } },
					onCancel: () => navigate('/unmodel')
				})
			} else {
				Modal.info({
					title:
						'Структура ВОР неактуальна. Для обновления структуры обратитесь к администратору или автору/редактору ВОР',
					okText: 'Закрыть',
					onOk: () => {
						navigate(`/unmodel`)
					}
				})
			}
		}
		// if (!(data instanceof AxiosError) && data && data.projectId !== project?.id) {
		// 	Modal.confirm({
		// 		title: 'Внимание',
		// 		content:
		// 			'Проект ВОР отличается от текущего. Для продолжения работы, требуется сменить текущий проект.',
		// 		okText: 'Сменить',
		// 		onOk: async () => changeCurrentProject(data.projectId),
		// 		onCancel: () => navigate('/unmodel')
		// 	})
		// }
	}, [data])

	useEffect(() => {
		if (
			!(data instanceof AxiosError) &&
			(data?.status?.name === CONTAINER_STATUS.new ||
				data?.status?.name === CONTAINER_STATUS.preparation) &&
			data?.isActual === false &&
			data?.ready === false &&
			data?.isLocalEstimate === false
			// &&
			// data.projectId === project?.id
		) {
			if (checkPermissions([PERMISSIONS.LocalEstimateCreate], data?.project?.id!) && isAuthor) {
				Modal.confirm({
					title:
						'Структура КЛС неактуальна. Для продолжения работы с документом необходимо актуализировать данные',
					okText: 'Обновить',
					cancelText: 'Отмена',
					onCancel: () => {
						navigate(`/local`)
					},
					onOk: () => {
						EstimateApi.actualizeEstimate(containerId!)
							.then(() => {
								queryClient.invalidateQueries({ queryKey: ['estimateContainer'] })
								queryClient.invalidateQueries({ queryKey: ['estimate'] })
							})
							.catch(error =>
								notification.error({
									message: 'Произошла ошибка',
									description: error?.data?.detail,
									placement: 'topLeft'
								})
							)
					}
				})
			} else {
				Modal.info({
					title:
						'Структура КЛС неактуальна. Для обновления структуры обратитесь к администратору или автору/редактору КЛС',
					okText: 'Закрыть',
					onOk: () => {
						navigate(`/local`)
					}
				})
			}
		}
	}, [data])

	return (
		<>
			<EstimateHistory type={params.type} />
			<WbsStatusModal type={params.type} />
			<EstimatePosition
				disabled={false}
				type={params.type}
				visaIssued={(visaIssued && !user?.isAdmin) || container?.isLocalEstimate === true}
			/>
			<EstimatePositionCommentsView type={params.type} />
			<EstimateComments type={params.type} />
			<EstimateNote
				visaIssued={(visaIssued && !user?.isAdmin) || container?.isLocalEstimate === true}
			/>
			<EstimateRemoveModal type={params.type} isAdjustment={false} />
			<RegulatoryRequest />
			<EstimateRelatedDocuments type={params.type} />
			{/* <EstimateNonActualModal /> */}
			<Space direction="vertical">
				<EstimateContainerHeader
					type={params.type}
					visaIssued={(visaIssued && !user?.isAdmin) || container?.isLocalEstimate === true}
				/>
				<Estimate type={params.type} />
			</Space>
		</>
	)
})
