import { Tabs, TabsProps } from 'antd'
import { useMemo } from 'react'
import { Monitoring } from 'widgets/monitoring'
import { QuartzSettings } from 'widgets/quartz-settings'

export const MonitoringPage = () => {
	const tabItems = useMemo<TabsProps['items']>(
		() => [
			{
				key: 'monitoring',
				label: 'Мониторинг',
				children: <Monitoring />
			},
			{
				key: 'quartzSettings',
				label: 'Алгоритмы',
				children: <QuartzSettings />
			}
		],
		[]
	)
	return <Tabs items={tabItems} />
}
