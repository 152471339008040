import { Col, Row, Tooltip } from 'antd'
import { memo, useMemo } from 'react'
import { IEstimatePositionMaterial } from '..'

interface IProps {
	row: IEstimatePositionMaterial
}

export const EstimatePositionMaterialTitle = memo(function (params: IProps) {
	const { row } = params
	const title = useMemo(
		() => (
			<Tooltip
				rootClassName="tooltip-wide"
				title={`${row.materialName}${row.materialComment ? `, ${row.materialComment}` : ''} ${
					row.materialIsDeleted ? 'АРХИВ' : ''
				}`}
			>
				<span>{`${row.materialName}${row.materialComment ? `, ${row.materialComment}` : ''}`}</span>
				{row.materialIsDeleted && <span style={{ marginLeft: '8px' }}>АРХИВ</span>}
			</Tooltip>
		),
		[]
	)
	return (
		<Row
			wrap={false}
			align="middle"
			justify="space-between"
			style={{
				width: '100%',
				height: '100%'
			}}
		>
			<Col className="ellipsis-block">{title}</Col>
		</Row>
	)
})
