import { useQuery } from "@tanstack/react-query"
import { WbsTaskStatusApi } from "../api/wbs-task-status-api"
import { useWbsTaskStatusState } from "./wbs-task-status-state"

export const useWbsTaskStatuses = () => {
	return useQuery({
		queryKey: ['wbsTaskStatusGuide'],
		queryFn: async () => {
			return await WbsTaskStatusApi.getRegistry().then(
				res =>
					res.data
						?.filter(x => !x.isDeleted)
						.map(d => ({ ...d, children: d.children.filter(x => !x.isDeleted) }))
						?.sort((a, b) => a.order - b.order) ?? []
			)
		},
		refetchOnWindowFocus: false
	})
}

export const useWbsTaskVisas = () => {
	const { addVisa } = useWbsTaskStatusState()
	return useQuery({
		queryKey: ['wbsTaskVisas', addVisa?.id],
		queryFn: async () => {
			return await WbsTaskStatusApi.getVisaRegistry(addVisa?.id!).then(res =>
				res.data.filter(d => !d.isDeleted)
			)
		},
		refetchOnWindowFocus: false
	})
}

export const useWbsTaskRoles = () => {
	return useQuery({
		queryKey: ['wbsTaskRoles'],
		queryFn: async () => {
			return await WbsTaskStatusApi.getRoles()
		},
		refetchOnWindowFocus: false
	})
}
