import { useQueryClient } from '@tanstack/react-query'
import { Modal, notification, Space } from 'antd'
import { IBase } from 'interfaces/IBase'
import {
	useCanVisaApproveLocalEstimate,
	useContainerLocalEstimateVisas
} from 'pages/unmodelPage/localEstimate/model/localEstimateQuery'
import { IStatusRouteItemVisa } from 'pages/unmodelPage/wbsContainer'
import { useCanVisaApprove } from 'pages/unmodelPage/wbsContainer/model/wbsContainerQuery'
import { FC, useEffect, useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { CONTAINER_STATUS, PERMISSIONS } from 'shared/constants'
import { TEstimateType } from 'shared/schema'
import { useCheckPermissions } from 'shared/useCheckPermissions'
import { useViewerState } from 'viewer-state-new'
import { adjustmentApi, useAdjustmentQuery } from 'widgets/adjustment'
import { AdjustmentComments } from 'widgets/adjustment-comments'
import { AdjustmentPositionCommentsView } from 'widgets/adjustment-position-comments'
import { useAdjustmentState } from 'widgets/adjustment/model/adjustment-state'
import { AdjustmentHeader } from 'widgets/adjustment/ui/AdjustmentHeader'
import { AdjustmentHistory } from 'widgets/adjustment/ui/AdjustmentHistory'
import { AdjustmentNote } from 'widgets/adjustment/ui/AdjustmentNote'
import { AdjustmentSections } from 'widgets/adjustment/ui/AdjustmentSections'
import { EstimateRelatedDocuments } from 'widgets/estimate-creation-from-vor/ui/EstimateRelatedDocuments'
import { EstimatePosition } from 'widgets/estimate-position-card'
import { EstimateRemoveModal } from 'widgets/estimate/ui/EstimateRemoveModal'
import { RegulatoryRequest } from 'widgets/regulatory-request'
import { WbsStatusModal } from 'widgets/wbs'
import { useContainerVisas } from 'widgets/wbs/model/wbs-queries'
import { useShallow } from 'zustand/react/shallow'

interface IProps {
	type: TEstimateType
}

export const AdjustmentPage: FC<IProps> = ({ type }) => {
	const { data, isSuccess } = useAdjustmentQuery(type)
	const { viewer: user } = useViewerState()

	const navigate = useNavigate()

	const queryClient = useQueryClient()
	const { id } = useParams()
	const { container } = useAdjustmentState(
		useShallow(state => ({
			container: state.container
		}))
	)
	const checkPermissions = useCheckPermissions()
	const isAuthor =
		(user?.isAdmin ||
			container?.author?.id === user?.id ||
			container?.redactors.some(r => r?.id === user?.id)) ??
		false
	const visasQuery =
		typeof container?.isLocalEstimate === 'undefined'
			? useContainerVisas
			: useContainerLocalEstimateVisas
	const { data: visas } = visasQuery(container?.id, true)
	const visasContainer = useMemo(
		() => visas?.find(v => v.id === container?.status.id)?.visas,
		[container, visas]
	)

	const { data: canVisaApprove } = useCanVisaApprove(
		typeof container?.isLocalEstimate === 'undefined' ? container?.id : undefined,
		true,
		typeof container?.isLocalEstimate === 'undefined' ? 'wbs' : undefined
	)

	const { data: canVisaApproveLocalEstimate } = useCanVisaApproveLocalEstimate(
		typeof container?.isLocalEstimate !== 'undefined' ? container?.id : undefined,
		true
	)

	const visaIssued = useMemo(
		() =>
			(typeof container?.isLocalEstimate === 'undefined'
				? canVisaApprove
				: canVisaApproveLocalEstimate) !== null &&
			!!visasContainer?.find(
				v =>
					!!(
						typeof container?.isLocalEstimate === 'undefined'
							? canVisaApprove
							: canVisaApproveLocalEstimate
					)?.includes(v.id) && v.visaState !== null
			),
		[container, visas, canVisaApprove, canVisaApproveLocalEstimate, visasContainer]
	)

	useEffect(() => {
		if (
			(data?.status?.name === CONTAINER_STATUS.new ||
				data?.status?.name === CONTAINER_STATUS.preparation) &&
			data?.isActual === false &&
			data?.ready === false
			// &&
			// data.projectId === project?.id
		) {
			if (checkPermissions([PERMISSIONS.AdjustmentCreate], data?.project?.id!) && isAuthor) {
				Modal.confirm({
					title:
						'Структура Было-Стало неактуальна. Для продолжения работы с документом необходимо актуализировать данные',
					okText: 'Обновить',
					cancelText: 'Отмена',
					onCancel: () => {
						navigate(`/${type === 'wbs' ? 'unmodel' : 'local'}`)
					},
					onOk: () => {
						adjustmentApi
							.actualize(id!, type)
							.then(() => {
								queryClient.invalidateQueries({ queryKey: ['adjustmentContainer'] })
								queryClient.invalidateQueries({ queryKey: ['adjustment'] })
							})
							.catch(error =>
								notification.error({
									message: 'Произошла ошибка',
									description: error?.data?.detail,
									placement: 'topLeft'
								})
							)
					}
				})
			} else {
				Modal.info({
					title:
						'Структура Было/стало неактуальна. Для обновления структуры обратитесь к администратору или автору/редактору Было-Стало',
					okText: 'Закрыть',
					onOk: () => {
						navigate(`/${type === 'wbs' ? 'unmodel' : 'local'}`)
					}
				})
			}
		}
	}, [data])

	return (
		<>
			<WbsStatusModal type={type} isAdjustment={true} />
			<EstimateRemoveModal type={type} isAdjustment={true} />
			<EstimatePosition
				disabled={false}
				type={type}
				isAdjustment={true}
				visaIssued={visaIssued && !user?.isAdmin}
			/>
			<EstimateRelatedDocuments type={type} />
			<AdjustmentHistory type={type} />
			<AdjustmentPositionCommentsView type={type} />
			<AdjustmentComments type={type} />
			<AdjustmentNote visaIssued={visaIssued && !user?.isAdmin} />
			<RegulatoryRequest isAdjustment={true} />
			<Space direction="vertical">
				<AdjustmentHeader type={type} visaIssued={visaIssued && !user?.isAdmin} />
				<AdjustmentSections type={type} />
			</Space>
		</>
	)
}
