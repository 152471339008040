import { useQuery } from '@tanstack/react-query'
import { EstimatePositionCommentsApi } from '../api/asmComments.api'
import { useEstimatePositionCommentsState } from './estimate-position-comments-state'

export const useEstimatePositionComments = (type: 'wbs' | 'local') => {
	const { currentId } = useEstimatePositionCommentsState()
	return useQuery({
		queryKey: ['estimatePosition', currentId],
		queryFn: async () => {
			if (currentId !== null) {
				return await EstimatePositionCommentsApi.getEPComments(currentId, type).catch(() => {
					return null
				})
			}
			return null
		},
		refetchOnWindowFocus: false
	})
}

export const useEstimatePositionCommentsUsers = (type: 'wbs' | 'local') => {
	const { currentId } = useEstimatePositionCommentsState()
	return useQuery({
		queryKey: ['estimatePositionUsers', currentId],
		queryFn: async () => {
			if (currentId !== null) {
				return await EstimatePositionCommentsApi.getEPCommentsUsers(currentId, type).catch(() => {
					return null
				})
			}
			return null
		},
		refetchOnWindowFocus: false
	})
}
