import { create } from "zustand";
import { ICheckListCount, IContainerCount, IContractor, IContractorRating, IIssueCount, IIssueSubTypes, IOrdinanceCount, IOrdinanceFineCount, IProject, IProject1C } from "./diagrams-types";
import { IBase } from "interfaces/IBase";

interface IDiagramState {
    to: string | null
    from: string | null
    project1CIds: string[] | null
    projectIds: string[] | null
    contractorIds: string[] | null
    contractorsData?: any
    issue?: IIssueCount
    ratingData?: IContractorRating[]
    issueTypeData?: IIssueSubTypes[]
    containerValues?: IContainerCount
    ordinance?: IOrdinanceCount
    checklistData?: ICheckListCount
    ordinanceFinesValues?: IOrdinanceFineCount
    allPeriod: boolean
    project1C?: IProject1C[]
    project?: IProject[]
    contractor?: IContractor[]
    permissions?: {id: number, name: string}[]
    setFilters: ({ to, from, project1CIds, projectIds, contractorIds, allPeriod }: { to?: string | null, from?: string | null, project1CIds?: string[] | null, projectIds?: string[] | null, contractorIds?: string[] | null, allPeriod?: boolean}) => void
}

export const useDiagramState = create<IDiagramState>((set, get) => ({
    to: null,
    from: null,
    project1CIds: null,
    projectIds: null,
    contractorIds: null,
    contractorsData: undefined,
    issue: undefined,
    ratingData: undefined,
    issueTypeData: undefined,
    containerValues: undefined,
    ordinance: undefined,
    checklistData: undefined,
    ordinanceFinesValues: undefined,
    allPeriod: false,
    project1C: undefined,
    project: undefined,
    contractor: undefined,
    permissions: undefined,
    // setFilters: (({ to, from, project1CIds, projectIds, contractorIds }) => set(() => (({ to, from, project1CIds, projectIds, contractorIds }))))
    setFilters: (payload) => set(state => ({ ...state, ...payload }))
}))