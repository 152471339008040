import { Col, Empty, Row } from 'antd'
import {
	BarElement,
	CategoryScale,
	Chart as ChartJS,
	Legend,
	LinearScale,
	Title,
	Tooltip
} from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { FC } from 'react'
import { Bar } from 'react-chartjs-2'
import { useOrdinanceFinesPerMonth } from '../model/diagrams-query'
import { Chart } from 'chart.js'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ChartDataLabels)

interface IProps {}

export enum FineStatuses {
	new = '10a2dcec-9ad0-4c1b-b38f-293a097b0c63',
	canceled = 'c3d93cdb-103e-4219-9771-af00ce8fdf5b',
	collected = '9dd3fa1f-f182-47be-bd63-3d2e7f5fcd0b',
	exhibited = 'dd2b7a56-4e21-4f4f-a59b-e983efbf61cd'
}

export const OrdinanceFinesMonthDashboard: FC<IProps> = () => {
	const { data: finesData } = useOrdinanceFinesPerMonth()

	const sixFinesData = finesData?.months?.slice(0, 6).reverse()

	const labels = sixFinesData?.map(m => m.nameRu)

	const dataPoints = [
		{
			label: 'Новый   ',
			data: sixFinesData?.map(m => m.countOrdinanceFinesNew),
			backgroundColor: '#FFAD14',
			borderWidth: 0,
			hoverBackgroundColor: '#FFAD14',
			borderColor: '#FFAD14'
		},
		{
			label: 'Снят   ',
			data: sixFinesData?.map(m => m.countOrdinanceFinesCanceled),
			backgroundColor: '#8099A8',
			borderWidth: 0,
			hoverBackgroundColor: '#8099A8',
			borderColor: '#8099A8'
		},
		{
			label: 'Выставлен   ',
			data: sixFinesData?.map(m => m.countOrdinanceFinesExhibited),
			backgroundColor: '#1677FF',
			borderWidth: 0,
			hoverBackgroundColor: '#1677FF',
			borderColor: '#1677FF'
		},
		{
			label: 'Удержан   ',
			data: sixFinesData?.map(m => m.countOrdinanceFinesCollected),
			backgroundColor: '#7A77D9',
			borderWidth: 0,
			hoverBackgroundColor: '#7A77D9',
			borderColor: '#7A77D9'
		}
	]

	const datasetsNew: any[] = dataPoints.map(dataPoint => ({
		...dataPoint
	}))

	dataPoints[0].data?.forEach((_: any, datasetIndex: any) => {
		const all_i = dataPoints
			.map((d, dataIndex) => [d?.data![datasetIndex], dataIndex])
			.sort(([data1], [data2]) => data1 - data2)
		all_i.forEach(([data, datasetIdx]) => {
			datasetsNew[datasetIdx].data[datasetIndex] = [0, data]
		})
	})

	const data = {
		labels,
		datasets: dataPoints.map(d => ({
			...d,
			datalabels: {
				anchor: 'end' as const,
				align: 'top' as const,
				offset: 3,
				formatter: (value: number[]) => (value[1] > 0 ? value[1] : ''),
				color: '#000000',
				font: {
					size: 14,
					weight: 400,
					family: 'Segoe UI'
				}
			}
		}))
	}

	const config = {
		type: 'bar',
		data,
		width: '1000%',
		height: '200%',
		options: {
			layout: {
				padding: {
					top: 20
				}
			},
			responsive: true,
			plugins: {
				legend: {
					display: false
					// 	onClick: (e: any) => null,
					// 	position: 'right' as const,
					// 	align: 'start' as const,
					// 	labels: {
					// 		padding: 12,
					// 		usePointStyle: true,
					// 		pointStyle: 'rect',
					// 		boxWidth: 12,
					// 		boxHeight: 12,
					// 		borderWidth: 3,
					// 		fontFamily: 'Segoe UI',
					// 		fontWeight: 'normal',
					// 		fontSize: '14px',
					// 		color: '#2C303D'
					// 	},
					// 	title: {
					// 		display: true,
					// 		color: 'black'
					// 	}
				},
				tooltip: {
					bodySpacing: 8,
					callbacks: {
						labelColor: function (context: any) {
							return {
								borderWidth: 3,
								borderColor: context.dataset.backgroundColor,
								backgroundColor: context.dataset.backgroundColor
							}
						},
						label: function (context: any) {
							let label = context.dataset.label.trim() || ''
							if (label) {
								label += ': '
							}
							if (context.parsed.y !== null) {
								label += context.parsed.y
							}
							return ' ' + label
						}
					}
				}
			},
			interaction: {
				mode: 'index' as const,
				intersect: false
			},
			scales: {
				y: {
					border: {
						display: false
					},
					grid: {
						drawTicks: false
					},
					ticks: {
						display: false
					}
				},
				x: {
					grid: {
						drawBorder: false,
						drawTicks: false
					},
					ticks: {
						padding: 20,
						callback: (value: any) => {
							const words = labels![value].split(/ |-/gm)
							let aux: string[] = []
							let concat: string[] = []
							for (let i = 0; i < words.length; i++) {
								concat.push(words[i])
								let join = concat.join(' ')
								if (join.length > 5) {
									aux.push(join)
									concat = []
								}
							}
							if (concat.length) {
								aux.push(concat.join(' ').trim())
							}
							return labels?.length! < 11 ? aux : aux[0].slice(0, 1) + '...'
						}
					}
				}
			}
		}
	}

	const hasData =
		finesData?.months &&
		sixFinesData?.some(
			month =>
				month.countOrdinanceFinesCollected > 0 ||
				month.countOrdinanceFinesExhibited > 0 ||
				month.countOrdinanceFinesCanceled > 0 ||
				month.countOrdinanceFinesNew > 0
		)

	return (
		<Row
			wrap={false}
			// gutter={12}
			style={{
				border: '1px solid #ddd',
				borderRadius: 8,
				padding: 8,
				background: 'white',
				height: '327px'
			}}
		>
			<Col span={24} style={{ padding: '1rem', background: '#fefefe' }}>
				<Row>
					<Col>
						<span
							style={{
								fontFamily: 'Segoe UI',
								fontWeight: 'normal',
								fontSize: '22px',
								color: '#000000',
								marginRight: '8px'
							}}
						>
							Штрафы по месяцам
						</span>
					</Col>
					<Col style={{ alignContent: 'center', marginTop: 5 }}>
						<span
							style={{
								fontFamily: 'Segoe UI',
								fontWeight: 400,
								fontSize: '14px',
								marginLeft: '5px',
								backgroundColor: '#EEEEEE',
								borderRadius: '21px',
								padding: '4px',
								color: '#2C303'
							}}
						>
							{finesData?.totalOrdinanceFines ?? 0}
						</span>
					</Col>
				</Row>
				{/* <div style={{ height: 200, marginLeft: 250, marginTop: 20 }}> */}
				{hasData ? (
					<Row>
						<Col style={{ height: 200, marginLeft: 250, marginTop: 20 }}>
							<Bar {...config} />
						</Col>
						<Col style={{ alignContent: 'center', marginLeft: '40px' }}>
							{dataPoints.map((label, index) => (
								<div
									key={index}
									style={{
										display: 'flex',
										alignItems: 'center',
										margin: '0 10px 0 0'
									}}
								>
									<div
										style={{
											width: '12px',
											height: '12px',
											backgroundColor:
												index === 0
													? '#FFAD14'
													: index === 1
													? '#8099A8'
													: index === 2
													? '#1677FF'
													: '#7A77D9',
											marginRight: '5px'
										}}
									/>
									<span
										style={{
											fontFamily: 'Segoe UI',
											fontWeight: 'normal',
											fontSize: '14px',
											color: '#2C303D'
										}}
									>
										{label.label}
									</span>
								</div>
							))}
						</Col>
					</Row>
				) : (
					<span
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							height: '100%'
						}}
					>
						<Empty />
					</span>
				)}
				{/* </div> */}
			</Col>
		</Row>
	)
}
