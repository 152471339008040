import { PlusSquareOutlined } from '@ant-design/icons'
import { useIsFetching, useMutation } from '@tanstack/react-query'
import { Button, Form, Input, Modal, Select, TreeSelect } from 'antd'
import { type IWbsCreate } from 'entities/wbs'

import { AxiosError } from 'axios'
import { useAsmTypeSections } from 'hooks/useAsm'
import { IErrorDetail } from 'interfaces/IBase'
import { FC, Key, useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useWorkPackages } from 'shared/api/shared-queries'
import { useUserAllInfo } from '../model/wbs-notification-query'
import { WbsApi, useWbsPermits } from '..'
import { flattenTree, markDisabledNodes } from '../model/wbs-helper'
import { useConstructionObjects } from '../model/wbs-queries'
import { useAppSelector } from 'hooks/appReduxHook'

interface IProps {
	show: boolean
	onClose: React.Dispatch<React.SetStateAction<boolean>>
}

export const WbsCreate: FC = () => {
	const [showModal, setShowModal] = useState(false)
	const canEdit = useWbsPermits().canEdit
	return (
		<>
			{canEdit && (
				<Button
					icon={<PlusSquareOutlined />}
					type="primary"
					onClick={() => setShowModal(true)}
					disabled={!canEdit}
				>
					Создать ВОР
				</Button>
			)}
			<WbsCreateModal show={showModal} onClose={setShowModal} />
		</>
	)
}

const WbsCreateModal: FC<IProps> = ({ show, onClose }) => {
	const navigate = useNavigate()
	const location = useLocation()
	const loading = useIsFetching()
	const [wbsCreateForm] = Form.useForm<IWbsCreate>()
	const formWorkPackageValue: string | undefined = Form.useWatch('workPackageId', wbsCreateForm)
	const hasWorkPackage = useMemo(
		() => typeof formWorkPackageValue !== 'undefined',
		[formWorkPackageValue]
	)
	const { data: workPackages } = useWorkPackages()
	const { data: wbsSectionTypes } = useAsmTypeSections(formWorkPackageValue)
	const { data: constructionObjects } = useConstructionObjects()
	const transformedData = markDisabledNodes(constructionObjects || [])
	const { user } = useAppSelector(state => state.environment)
	// const { data: projects } = useUserAllInfo()

	const wbsCreate = useMutation(async (newWbs: IWbsCreate) => {
		return await WbsApi.addContainer(newWbs)
			.then(data => {
				onModalClose()
				navigate(data.id + location.search)
			})
			.catch((error: AxiosError<IErrorDetail>) =>
				Modal.error({
					zIndex: 999999999999999,
					title: 'Ошибка при создании ВОР',
					content: error.response?.data.detail.split(',')[0],
					width: window.innerWidth - (window.innerWidth * 70) / 100,
					onOk: () => {
						navigate(
							error.response?.data.detail.split(',')[1]?.slice(0, 2) === 'id'
								? `/unmodel/${error.response?.data.detail.split(',')[1]?.slice(3)}`
								: '/unmodel'
						)
					}
				})
			)
	})

	const onModalClose = () => {
		wbsCreateForm.resetFields()
		onClose(false)
	}

	const onCreateContainer = async (data: IWbsCreate) => {
		wbsCreate.mutate({ ...data })
	}

	const handlerTrim = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (e.target.value.trim() === '') {
			wbsCreateForm.setFieldsValue({ name: '' })
		} else {
			wbsCreateForm.setFieldsValue({ name: e.target.value })
		}
	}

	// TODO Вынести отдельно для TreeSelect
	const [expandedKeys, setExpandedKeys] = useState<Key[]>(
		flattenTree(transformedData!)?.map((elem: any) => elem.id)
	)
	const [searchValue, setSearchValue] = useState()

	useEffect(() => {
		setExpandedKeys(flattenTree(transformedData!)?.map((elem: any) => elem.id))
	}, [searchValue, constructionObjects])

	const onSearch = (e: any) => {
		setSearchValue(e.target?.value)
		if (!e.target?.value) {
			setExpandedKeys(flattenTree(transformedData!)?.map((elem: any) => elem.id))
		}
	}
	const onExpand = (newKeys: Key[]) => {
		setExpandedKeys(newKeys)
	}
	//

	useEffect(() => {
		if (wbsSectionTypes?.length === 1) {
			wbsCreateForm.setFieldValue('typeSectionId', wbsSectionTypes[0].id)
			wbsCreateForm.validateFields(['typeSectionId'])
		}
	}, [wbsSectionTypes])

	const onFormChange = (changedValues: IWbsCreate) => {
		switch (Object.keys(changedValues)[0]) {
			case 'workPackageId':
				wbsCreateForm.resetFields(['typeSectionId'])
				wbsCreateForm.validateFields(['typeSectionId'])
				break
		}
	}

	return (
		<Modal
			destroyOnClose={true}
			confirmLoading={Boolean(loading)}
			title="Создать контейнер"
			open={show}
			onCancel={onModalClose}
			okText="Создать"
			onOk={() => wbsCreateForm.submit()}
			okButtonProps={{ htmlType: 'submit', icon: <PlusSquareOutlined /> }}
		>
			<Form
				layout="vertical"
				form={wbsCreateForm}
				name="addContainer"
				onFinish={onCreateContainer}
				onValuesChange={onFormChange}
			>
				<Form.Item
					label="Проект"
					name="projectId"
					rules={[
						{
							required: true,
							message: 'Выберите проект'
						}
					]}
				>
					<Select
						allowClear
						placeholder="Выберите проект"
						showSearch
						style={{ width: '100%' }}
						optionFilterProp="children"
						filterOption={(input, option) =>
							(option?.label ?? '').toLowerCase().includes(input.toLowerCase())
						}
						filterSort={(a, b) =>
							(a?.label ?? '').toLowerCase().localeCompare((b?.label ?? '').toLowerCase())
						}
						options={user?.projects?.map(p => ({
							key: p.id,
							label: p.name,
							value: p.id
						}))}
					/>
				</Form.Item>
				<Form.Item
					label="Наименование ВОР"
					name="name"
					validateDebounce={1000}
					rules={[
						{
							required: true,
							message: 'Укажите наименование ВОР'
						},
						{
							max: 255,
							message: 'Наименование не может содержать больше 255 символов'
						},
						{ min: 3, message: 'Наименование должно содержать не менее 3 символов' }
					]}
				>
					<Input
						style={{ width: '100%' }}
						placeholder="Укажите наименование ВОР"
						allowClear
						onChange={handlerTrim}
					/>
				</Form.Item>
				<Form.Item
					label="Пакет работ AbsPlan"
					name="workPackageId"
					rules={[
						{
							required: true,
							message: 'Выберите пакет работ AbsPlan'
						}
					]}
				>
					<Select
						allowClear
						placeholder="Выберите пакет работ AbsPlan"
						showSearch
						style={{ width: '100%' }}
						optionFilterProp="children"
						filterOption={(input, option) =>
							(option?.label ?? '').toLowerCase().includes(input.toLowerCase())
						}
						options={workPackages?.map(c => ({
							key: c.id,
							label: c.name,
							value: c.id
						}))}
					/>
				</Form.Item>
				<Form.Item
					label="Раздел НСИ"
					name="typeSectionId"
					rules={[
						{
							required: true,
							message: 'Выберите раздел НСИ'
						}
					]}
				>
					<Select
						disabled={!hasWorkPackage}
						allowClear
						placeholder="Выберите раздел НСИ"
						showSearch
						style={{ width: '100%' }}
						optionFilterProp="children"
						filterOption={(input, option) =>
							(option?.label ?? '').toLowerCase().includes(input.toLowerCase())
						}
						filterSort={(a, b) =>
							(a?.label ?? '').toLowerCase().localeCompare((b?.label ?? '').toLowerCase())
						}
						options={wbsSectionTypes
							?.filter(c => !c.isDeleted)
							.map(c => ({
								key: c.id,
								label: c.name,
								value: c.id
							}))}
					/>
				</Form.Item>
				<Form.Item
					label="Объект строительства"
					name="constructionObjectId"
					rules={[
						{
							required: true,
							message: 'Выберите объект строительства'
						}
					]}
				>
					<TreeSelect
						fieldNames={{ label: 'name', value: 'id', children: 'children' }}
						treeData={transformedData}
						showSearch
						placeholder="Выберите объект строительства"
						allowClear
						treeDefaultExpandAll
						onSearch={onSearch}
						treeExpandedKeys={expandedKeys}
						onTreeExpand={onExpand}
						filterTreeNode={(input, treeNode) => {
							return treeNode.name?.toLowerCase()?.includes(input.toLowerCase())
						}}
					/>
				</Form.Item>
			</Form>
		</Modal>
	)
}
