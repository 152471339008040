import { Col, Empty, Row, Table } from 'antd'
import { PAGE_LIMIT, PERMISSIONS } from 'shared/constants'
import { useWbsTaskDiagram } from '../model/diagrams-query'
import { useWbsRegistryColumns } from 'widgets/wbs-task-registry/ui/wbs-task-registry-columns'
import { useWbsTaskState } from 'pages/wbs-tasks-page/model/wbs-tasks-state'
import { useWbsTaskCardState } from 'widgets/wbs-task-card/model/wbs-task-card-state'
import { useDiagramState } from '../model/diagrams-state'
import { useAppSelector } from 'hooks/appReduxHook'

export const WbsTaskDashboard = () => {
	const columns = useWbsRegistryColumns()
	const { totalItems, currentPage, onPagination, setTask, setSortState, setType } =
		useWbsTaskState()
	const { setShowCard } = useWbsTaskCardState()
	const user = useAppSelector(state => state?.environment?.user)
	const permissions = user?.projects?.flatMap(p => p?.permissions)
	const issuePermission = !!permissions?.find((p: any) => p?.name === PERMISSIONS.IssueView)
	const wbsTaskPermission = !!permissions?.find((p: any) => p?.name === PERMISSIONS.WbsTasksView)
	const { data, isFetching } = useWbsTaskDiagram()

	return (
		<Row
			wrap={false}
			gutter={12}
			style={{
				border: '1px solid #ddd',
				borderRadius: 8,
				padding: 8,
				background: 'white',
				height: issuePermission ? '500px' : '750px'
			}}
		>
			<Col
				span={24}
				style={{
					padding: '1rem',
					background: !wbsTaskPermission ? '#ffffff' : '#fefefe'
				}}
			>
				<span
					style={{
						fontFamily: 'Segoe UI',
						fontWeight: 'normal',
						fontSize: '22px',
						color: '#000000'
					}}
				>
					Заявки
				</span>
				<div
					style={{
						height: issuePermission ? 400 : 550,
						marginTop: 20
					}}
				>
					{data && !!data?.length ? (
						<Table
							size="small"
							loading={isFetching}
							className="tableContainer"
							scroll={{
								x: 1000,
								y: issuePermission ? '300px' : '550px'
							}}
							style={{ height: '100%', maxHeight: '100%' }}
							showHeader={!!data?.length}
							sticky={{ offsetHeader: 0 }}
							rowKey="id"
							dataSource={data}
							columns={columns}
							onRow={record => ({
								onDoubleClick: () => {
									if (record?.id !== null) {
										setTask(record?.id!)
										setType('main')
										// setShowCard(true, 'main')
									}
								}
							})}
							pagination={{
								hideOnSinglePage: true,
								total: totalItems,
								pageSize: PAGE_LIMIT,
								showSizeChanger: false,
								onChange: onPagination,
								current: currentPage
							}}
							onChange={(pagination, filters, FilterValue) => {
								const columnName: any = {
									number: 2,
									wbsTaskStatus: 4,
									wbsTaskType: 0,
									createdAt: 6
								}

								const columnKey = (FilterValue as any).columnKey
								const order = sortVariation((FilterValue as any).order)
								const sortOrder =
									columnName.hasOwnProperty(columnKey) && order
										? columnName[columnKey] + (order === 'ASC' ? 0 : 1)
										: undefined
								setSortState(sortOrder)
							}}
						/>
					) : (
						<span
							style={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								height: '100%'
							}}
						>
							<Empty />
						</span>
					)}
				</div>
			</Col>
		</Row>
	)
}

const sortVariation = (order?: string) => {
	switch (order) {
		case 'ascend':
			return 'ASC'
		case 'descend':
			return 'DESC'
		default:
			return undefined
	}
}
