import { CheckSquareOutlined, DeleteOutlined } from '@ant-design/icons'
import {
	Badge,
	Card,
	Col,
	DatePicker,
	DatePickerProps,
	Descriptions,
	Form,
	Popconfirm,
	Row,
	Select,
	Tag,
	Tooltip,
	Typography,
	message
} from 'antd'
import Button from 'antd/es/button'
import locale from 'antd/es/date-picker/locale/ru_RU'
import dayjs from 'dayjs'
import { useAppDispatch, useAppSelector } from 'hooks/appReduxHook'
import { IContract } from 'interfaces/IContract'
import { IContactPerson, IIssue } from 'interfaces/IIssue'
import { IOrdinance, IOrdinanceAttachment } from 'interfaces/IOrdinance'
import SignModal from 'pages/ordinancePage/SignModal'
import { FC, useEffect, useState } from 'react'
import { downloadFile } from 'services/AdminService'
import { getContracts } from 'services/ContractsService'
import { getContactPersonsList, recreateOrdinanceFile } from 'services/OrdinanceService'
import { PERMISSIONS } from 'shared/constants'
import { useCheckPermissions } from 'shared/useCheckPermissions'
import { setDtoUpdValue } from 'store/ordinanceSlice'
import { YandexTrackerButton } from 'widgets/yandex-tracker/ui/yandex-tracker-button'
import { SignBlock } from '../SignBlock'

interface IOrdinanceDetailProps {
	ordinance: IOrdinance
	issues: IIssue[]
	contractorId: string | null
	canEdit: boolean
	canDeleteFiles: boolean
	onDeleteAttachment: (type: 'ordinance' | 'issue', id: string) => void
	onUpdate: () => void
}

const { Text } = Typography
const { Option } = Select
export const OrdinanceDetail: FC<IOrdinanceDetailProps> = ({
	ordinance,
	issues,
	contractorId,
	canEdit,
	canDeleteFiles,
	onUpdate,
	onDeleteAttachment
}) => {
	const checkApprove = (type: string) => {
		switch (type) {
			case 'tooltip':
				if (ordinance!?.isApproved! && !ordinance!?.isDeleted!) {
					return 'Согласовано'
				} else if (!ordinance!?.isApproved! && ordinance!?.isDeleted!) {
					return 'Не согласовано'
				} else {
					return undefined
				}
			case 'badge':
				if (ordinance!?.isApproved! && !ordinance!?.isDeleted!) {
					return 'green'
				} else if (!ordinance!?.isApproved! && ordinance!?.isDeleted!) {
					return 'red'
				} else {
					return undefined
				}
		}
	}

	return (
		<>
			<Card className="at-workcard">
				{ordinance!?.isApproved! || ordinance!?.isDeleted! ? (
					<Badge.Ribbon
						text={
							<Tooltip title={checkApprove('tooltip')}>
								<CheckSquareOutlined />
							</Tooltip>
						}
						color={checkApprove('badge')}
					>
						<OrdinanceDetailDescriptions
							ordinance={ordinance!}
							issues={issues}
							contractorId={contractorId}
							canEdit={canEdit}
							canDeleteFiles={canDeleteFiles}
							onOrdinanceUpdate={onUpdate}
							onDeleteAttachment={onDeleteAttachment}
						/>
					</Badge.Ribbon>
				) : (
					<OrdinanceDetailDescriptions
						ordinance={ordinance!}
						issues={issues}
						contractorId={contractorId}
						canEdit={canEdit}
						canDeleteFiles={canDeleteFiles}
						onOrdinanceUpdate={onUpdate}
						onDeleteAttachment={onDeleteAttachment}
					/>
				)}
			</Card>
		</>
	)
}

interface IOrdinanceDetailDescriptionsProps {
	ordinance: IOrdinance
	issues: IIssue[]
	contractorId: string | null
	canEdit: boolean
	canDeleteFiles: boolean
	onOrdinanceUpdate: () => void
	onDeleteAttachment: (type: 'ordinance' | 'issue', id: string) => void
}
const OrdinanceDetailDescriptions: FC<IOrdinanceDetailDescriptionsProps> = ({
	ordinance,
	issues,
	contractorId,
	canEdit,
	canDeleteFiles,
	onOrdinanceUpdate,
	onDeleteAttachment
}) => {
	const checkPermissions = useCheckPermissions()
	const dispatch = useAppDispatch()
	const [isLoading, setIsLoading] = useState(false)
	const {
		user: currentUser,
		project,
		buildingPermits,
		allProjects
	} = useAppSelector(state => state.environment)
	const [contracts, setContracts] = useState<IContract[]>([])
	const [contactPersons, setContactPersons] = useState<IContactPerson[]>([])
	const [signShow, setSignShow] = useState(false)
	const [ordinancePrintForm, setOrdinancePrintForm] = useState<IOrdinanceAttachment | null>(null)
	const [attachmentType, setAttachmentType] = useState(1)
	const canApprove = checkPermissions([PERMISSIONS.OrdinanceApproval], project?.id)

	useEffect(() => {
		const init = async () => {
			setIsLoading(true)
			await getContracts({
				contractorId: contractorId!,
				projectId: project?.id!
			}).then(data => setContracts(data))
			if (ordinance && ordinance!?.attachments!?.length !== 0) {
				const ordinanceFile = ordinance
					.attachments!?.filter(file => file.typeId === 1)
					.sort((a, b) => dayjs(b.uploadDate).unix() - dayjs(a.uploadDate).unix())[0]

				if (ordinanceFile) setOrdinancePrintForm(ordinanceFile)
			}

			if (canEdit) {
				getContactPersonsList(contractorId!).then(data => setContactPersons(data))
			}
			setTimeout(() => setIsLoading(false), 200)
		}
		init()
	}, [])

	const onSigning = (type: number) => {
		setAttachmentType(type)
		setSignShow(true)
	}

	const onPropChange = (prop: string, value: string) => {
		dispatch(setDtoUpdValue({ prop, value }))
	}

	const onOrdinanceTerm: DatePickerProps['onChange'] = date => {
		const dateTo = date!.format()
		dispatch(setDtoUpdValue({ prop: 'dateTo', value: dateTo }))
	}

	return (
		<>
			<SignModal
				ordinance={ordinance}
				show={signShow}
				attachmentType={attachmentType}
				onClose={() => setSignShow(false)}
				onConfirm={onOrdinanceUpdate}
			/>
			<Row
				justify="space-between"
				style={{
					marginBottom: 5,
					marginRight: ordinance!?.isApproved! || ordinance!?.isDeleted! ? 30 : 0
				}}
			>
				<Col>
					{ordinance.isAutoCreated && (
						<Tag style={{ fontSize: '14px' }} color="#feb300">
							Авто
						</Tag>
					)}
				</Col>
				<Col>
					<YandexTrackerButton text={'Сообщить об ошибке'} />
				</Col>
			</Row>
			<Descriptions
				layout="horizontal"
				column={12}
				bordered
				className="at-descriptions"
				labelStyle={{
					fontWeight: 'bold',
					width: '20%',
					alignItems: 'center',
					padding: '4px 8px'
				}}
				contentStyle={{
					width: '80%',
					padding: '4px 8px'
				}}
			>
				<Descriptions.Item label="Дата создания:" span={12}>
					<Text>{dayjs(ordinance!?.createdAt!).format('DD.MM.YYYY')}</Text>
				</Descriptions.Item>

				<Descriptions.Item label="Автор:" span={12}>
					<Text>{ordinance!?.createdBy!?.name!}</Text>
				</Descriptions.Item>

				<Descriptions.Item label="Проект:" span={12}>
					<Text>{ordinance!?.project!?.name}</Text>
				</Descriptions.Item>

				<Descriptions.Item label="Тип нарушения:" span={12}>
					<Text>{ordinance!?.violationType!?.name}</Text>
				</Descriptions.Item>

				<Descriptions.Item label="Генподрядчик" span={12}>
					<Text>{ordinance!?.contractor!?.name}</Text>
				</Descriptions.Item>

				<Descriptions.Item label="Субподрядчик" span={12}>
					<Text>{ordinance!?.subContractor!?.name}</Text>
				</Descriptions.Item>

				<Descriptions.Item
					label={<>Договор:{canEdit && <sup style={{ color: 'red' }}>*</sup>}</>}
					span={12}
				>
					{canEdit ? (
						<Form.Item
							name={['contract', 'id']}
							rules={[
								{
									required: true,
									message: 'Выберите договор'
								}
							]}
						>
							<Select
								loading={isLoading}
								showSearch
								optionFilterProp="children"
								style={{ width: '100%', marginLeft: -8 }}
								onChange={value => {
									onPropChange('contractId', value)
								}}
								options={contracts
									.filter(
										con =>
											// TODO: ID в строке, из-за фильтрации по основному договору
											con!?.contractor!?.id! === ordinance!?.contractor!?.id!
									)
									.map(contract => ({
										key: contract.id,
										value: contract.id,
										label: contract.name
									}))}
							/>
							{/* {[...contracts!]
									.filter(
										con =>
											con!?.contractor!?.id! === ordinance!?.contractor!?.id! &&
											con!?.mainContract === null
									)
									.map(contract => (
										<Option key={contract.id} value={contract.id}>
											{contract.name}
										</Option>
									))} */}
							{/* </Select> */}
						</Form.Item>
					) : (
						<Text>{ordinance!?.contract!?.name}</Text>
					)}
				</Descriptions.Item>

				<Descriptions.Item
					label={
						<>Разрешение на строительство:{canEdit && <sup style={{ color: 'red' }}>*</sup>}</>
					}
					span={12}
				>
					{canEdit ? (
						<Form.Item
							name={['buildingPermit', 'id']}
							// initialValue={ordinance!?.buildingPermit!?.id!}
							rules={[
								{
									required: true,
									message: 'Выберите разрешение на строительство'
								}
							]}
						>
							<Select
								style={{ width: '100%', marginLeft: -8 }}
								onChange={value => {
									onPropChange('buildingPermitId', value)
								}}
								showSearch
								optionFilterProp="children"
							>
								{buildingPermits!?.map(permit => (
									<Option key={permit.id} value={permit.id}>
										{permit.name}
									</Option>
								))}
							</Select>
						</Form.Item>
					) : (
						<Text>{ordinance!?.buildingPermit!?.name}</Text>
					)}
				</Descriptions.Item>

				<Descriptions.Item
					label={<>Контактное лицо:{canEdit && <sup style={{ color: 'red' }}>*</sup>}</>}
					span={12}
				>
					{canEdit ? (
						<Form.Item
							name={['contactPerson', 'id']}
							rules={[{ required: true, message: 'Выберите контактное лицо' }]}
						>
							<Select
								style={{ width: '100%', marginLeft: -8 }}
								onChange={value => {
									onPropChange('contactPersonId', value)
								}}
								showSearch
								optionFilterProp="children"
								disabled={contactPersons.length === 0}
							>
								{contactPersons.map(person => (
									<Option key={person.id} value={person.id}>
										{person.name}
									</Option>
								))}
							</Select>
						</Form.Item>
					) : (
						<Text>{ordinance!?.contactPerson!?.name}</Text>
					)}
				</Descriptions.Item>

				<Descriptions.Item
					label="Фаза"
					span={12}
					labelStyle={{
						fontWeight: 'bold'
					}}
				>
					<p style={{ paddingLeft: '8px', margin: 0 }}>
						{allProjects.find(ap => ap.id === project?.id)?.phase ?? '-'}
					</p>
				</Descriptions.Item>
				<Descriptions.Item
					label="Корпус"
					span={12}
					labelStyle={{
						fontWeight: 'bold'
					}}
				>
					<p style={{ paddingLeft: '8px', margin: 0 }}>
						{allProjects.find(ap => ap.id === project?.id)?.building ?? '-'}
					</p>
				</Descriptions.Item>
				<Descriptions.Item
					label="Руководитель строительства"
					span={12}
					labelStyle={{
						fontWeight: 'bold'
					}}
				>
					<p style={{ paddingLeft: '8px', margin: 0 }}>
						{allProjects.find(ap => ap.id === project?.id)?.responsibleManager?.name ?? '-'}
					</p>
				</Descriptions.Item>

				<Descriptions.Item
					label={<>Срок по предписанию:{canEdit && <sup style={{ color: 'red' }}>*</sup>}</>}
					span={12}
				>
					{canEdit ? (
						<Form.Item
							name="dateTo"
							rules={[{ required: true, message: 'Укажите срок по предписанию' }]}
							// initialValue={dayjs(ordinance!?.dateTo!)}
						>
							<DatePicker
								locale={locale}
								style={{ width: '100%', marginLeft: -8 }}
								format={'DD.MM.YYYY'}
								onChange={onOrdinanceTerm}
								disabledDate={current => {
									let pastDate = dayjs().format('YYYY-MM-DD')
									return current && current < dayjs(pastDate, 'YYYY-MM-DD')
								}}
							/>
						</Form.Item>
					) : (
						<Text>{dayjs(ordinance!?.dateTo!).format('DD.MM.YYYY')}</Text>
					)}
				</Descriptions.Item>

				<Descriptions.Item label="Выявленные нарушения:" span={12}>
					<span>
						{issues.length > 0 &&
							issues.map(issue => (
								<>
									<p>{issue.description!}</p>
									<p>{issue.violationMinDto?.name}</p>
								</>
							))}
					</span>
				</Descriptions.Item>

				<Descriptions.Item label="Файл предписания:" span={12}>
					{ordinancePrintForm !== null && (
						<Row align="middle" gutter={8}>
							<Col flex={1}>
								<Button
									disabled={
										!(
											(
												ordinancePrintForm.statusId === 4 ||
												(ordinancePrintForm.statusId === 5 &&
													(currentUser?.id === ordinance.createdBy.id || canApprove))
											)
											//  &&
											// ordinancePrintForm.contentType === 'application/zip'
										)
									}
									className="at-btn-wrap"
									style={{ padding: 0, margin: 0 }}
									type="link"
									onClick={() =>
										downloadFile(ordinancePrintForm!?.id)
											.then(response => {
												const href = URL.createObjectURL(response.data)
												const link = document.createElement('a')
												link.href = href
												link.setAttribute(
													'download',
													ordinancePrintForm!?.name + ordinancePrintForm.extension
												)
												document.body.appendChild(link)
												link.click()
												document.body.removeChild(link)
												URL.revokeObjectURL(href)
											})
											.catch(() => message.error('Во время загрузки файла произошла ошибка'))
									}
								>
									{ordinancePrintForm!?.name}
								</Button>
							</Col>
							{currentUser?.isAdmin && (
								<Col>
									<Button
										type="default"
										style={{ cursor: 'pointer', width: 150 }}
										disabled={false}
										onClick={() =>
											recreateOrdinanceFile(ordinance?.id, ordinancePrintForm?.id)
												.then(() => onOrdinanceUpdate())
												.catch(() =>
													message.error('Во время переформирования файла произошла ошибка')
												)
										}
									>
										Переформировать
									</Button>
								</Col>
							)}
							{ordinancePrintForm !== null &&
								((ordinancePrintForm.statusId === 5 &&
									(currentUser?.id === ordinance.createdBy.id || canApprove)) ||
									//  &&
									// ordinancePrintForm.contentType === 'application/zip'
									ordinancePrintForm.statusId === 3 ||
									ordinancePrintForm.statusId === 4) && (
									<Col>
										<SignBlock ordinance={ordinance} typeId={1} onClick={type => onSigning(type)} />
									</Col>
								)}
							{canDeleteFiles &&
								(ordinancePrintForm?.statusId === 3 ||
									(ordinancePrintForm?.statusId === 5 &&
										(currentUser?.id === ordinance.createdBy.id || canApprove))) && (
									//  &&
									// ordinancePrintForm.contentType === 'application/zip'
									<Col>
										<Popconfirm
											title="Удалить?"
											placement="topRight"
											onConfirm={() => onDeleteAttachment('ordinance', ordinancePrintForm?.id)}
											okButtonProps={{ danger: true }}
											okText="Да"
											cancelText="Отмена"
										>
											<Button icon={<DeleteOutlined />} danger />
										</Popconfirm>
									</Col>
								)}
						</Row>
					)}
				</Descriptions.Item>
			</Descriptions>
		</>
	)
}
