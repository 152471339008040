import { Card } from 'antd'
import { memo, useEffect } from 'react'
import { useEstimateQuery } from '..'
import { EstimateVirtualize } from './EstiamateVirtualize'
import { useSearchParams } from 'react-router-dom'
import { useEstimatePositionCommentsState } from 'widgets/estimate-position-comments'

export const Estimate = memo(function ({ type }: { type: 'wbs' | 'local' }) {
	useEstimateQuery(type)
	const [searchParams] = useSearchParams()
	const currentId = searchParams.get('chat')
	useEffect(() => {
		if (currentId)
			useEstimatePositionCommentsState.setState({
				show: true,
				currentId
			})
	}, [currentId])

	return (
		<Card size="small">
			<EstimateVirtualize type={type} />
		</Card>
	)
})
