import { Empty, Table, TableColumnsType } from 'antd'
import { FC } from 'react'
import { IMobileColumns } from '../model/mobile-report-interface'
import { useMobileReportState } from '../model/mobile-report-state'

export const MobileReport: FC = () => {
	const { currentPage, totalItems, mobileReport, onPagination } = useMobileReportState()

	const columns: TableColumnsType<IMobileColumns> = [
		{
			title: 'Проект',
			dataIndex: 'projectName',
			key: 'project',
			width: 250
		},
		{
			title: 'Период',
			dataIndex: 'period',
			key: 'period',
			width: 100
		},
		{
			title: 'Пользователи',
			dataIndex: 'userPercentage',
			key: 'userPercentage',
			width: 150,
			render: (_, record) =>
				record.userPercentage !== undefined ? `${record.userPercentage}%` : ''
		},
		{
			title: 'Замечания',
			dataIndex: 'issuePercentage',
			key: 'issuePercentage',
			width: 180,
			render: (_, record) => `${record.issuePercentage}%`
		},
		{
			title: 'Чек-листы',
			dataIndex: 'checkListPercentage',
			key: 'checkListPercentage',
			width: 180,
			render: (_, record) => `${record.checkListPercentage}%`
		},
		{
			title: 'Предписания',
			dataIndex: 'ordinancePercentage',
			key: 'ordinancePercentage',
			width: 180,
			render: (_, record) => `${record.ordinancePercentage}%`
		},
		{
			title: 'Штрафы',
			dataIndex: 'finePercentage',
			key: 'finePercentage',
			width: 180,
			render: (_, record) => `${record.finePercentage}%`
		}
	]

	return mobileReport?.length ? (
		<Table
			rowKey={record => record.id}
			columns={columns as any}
			bordered
			dataSource={mobileReport}
			style={{ marginTop: '1rem' }}
			scroll={{ y: '62vh', x: '10%' }}
			size="small"
			pagination={{
				current: currentPage,
				pageSize: 50,
				hideOnSinglePage: false,
				showSizeChanger: false,
				total: totalItems,
				onChange: onPagination
			}}
			className="app-hoc-table"
		/>
	) : (
		<Empty />
	)
}
