import { useQuery } from '@tanstack/react-query'
import { WbsApi } from './wbs-api'

export const useWbsWorkPackages = (projectId: string[]) => {
	return useQuery({
		queryKey: ['wbsWorkPackages', projectId],
		queryFn: async () => {
			return await WbsApi.getWbsWorkPackages(projectId!)
		},
		refetchOnWindowFocus: false,
		cacheTime: 0
	})
}
