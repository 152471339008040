import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons'
import { Row } from '@tanstack/table-core'
import { Row as AntRow, Button, Col, Tooltip } from 'antd'
import { useAppSelector } from 'hooks/appReduxHook'
import { memo, useCallback, useMemo } from 'react'
import { CONTAINER_STATUS, PERMISSIONS } from 'shared/constants'
import { useCheckPermissions } from 'shared/useCheckPermissions'
import { IAdjustmentPosition, IAdjustmentSection } from '../model/adjustment-schema'
import { useAdjustmentState } from '../model/adjustment-state'

interface IProps {
	row: IAdjustmentPosition | IAdjustmentSection
	section: Row<IAdjustmentSection>
	isMultiply?: boolean
	isAuthor?: boolean
}

export const AdjustmentPositionMove = memo(function (params: IProps) {
	const { section, isMultiply = false, row, isAuthor } = params
	const { user } = useAppSelector(state => state.environment)
	const { container, selectedRows, changePosition } = useAdjustmentState()
	const checkPermissions = useCheckPermissions()

	const canNotMoveUp =
		// useMemo(
		// () =>
		container &&
		((isMultiply === false
			? 'order' in row && row.order === 1
			: Object.keys(selectedRows.rows).includes(section?.originalSubRows![0]?.id!)) ||
			(!(
				typeof container?.isLocalEstimate === 'undefined' &&
				(user?.isAdmin ||
					((container?.status.name === CONTAINER_STATUS.new ||
						container?.status.name === CONTAINER_STATUS.preparation) &&
						checkPermissions([PERMISSIONS.AdjustmentCreate], container?.project?.id!) &&
						isAuthor))
			) &&
				!container?.isLocalEstimate === false))
	// 	[container]
	// )

	const canNotMoveDown =
		// useMemo(
		// () =>
		container &&
		(Object.keys(selectedRows.rows).includes(
			section?.originalSubRows![section?.originalSubRows?.length! - 1]?.id!
		) ||
			('order' in row && row.order === section.original.children?.length) ||
			(!(
				typeof container?.isLocalEstimate === 'undefined' &&
				(user?.isAdmin ||
					((container?.status.name === CONTAINER_STATUS.new ||
						container?.status.name === CONTAINER_STATUS.preparation) &&
						checkPermissions([PERMISSIONS.AdjustmentCreate], container?.project?.id!) &&
						isAuthor))
			) &&
				!container?.isLocalEstimate === false))
	// 	[container]
	// )

	const changePositionHandler = useCallback(
		(direction: 'up' | 'down') => {
			changePosition(
				isMultiply ? row.id : section.original.id,
				isMultiply ? Object.keys(selectedRows.rows) : [row.id],
				direction,
				typeof container?.isLocalEstimate === 'undefined' ? 'wbs' : 'local'
			)
		},
		[container]
	)

	return (
		<AntRow align="middle" wrap={false}>
			<Col>
				<Tooltip title="Поднять">
					<Button
						size="small"
						type="text"
						disabled={canNotMoveUp}
						icon={<CaretUpOutlined />}
						onClick={e => {
							e.stopPropagation()
							changePositionHandler('up')
						}}
					/>
				</Tooltip>
			</Col>
			<Col>
				<Tooltip title="Опустить">
					<Button
						size="small"
						type="text"
						disabled={canNotMoveDown}
						icon={<CaretDownOutlined />}
						onClick={e => {
							e.stopPropagation()
							changePositionHandler('down')
						}}
					/>
				</Tooltip>
			</Col>
		</AntRow>
	)
})
