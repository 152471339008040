import { Empty, Table } from 'antd'
import { ColumnsType } from 'antd/es/table'
import { useAppSelector } from 'hooks/appReduxHook'
import { IHOCReport } from 'interfaces/IReports'
import { FC, useEffect, useState } from 'react'

interface IProps {}

const columns: ColumnsType<IHOCReport> = [
	{
		dataIndex: 'responsibleManager',
		title: 'Ответственный'
	},
	{
		dataIndex: 'project',
		title: 'Проект'
	},
	{
		dataIndex: 'phase',
		align: 'center',
		title: 'Фаза',
		width: 128
	},
	{
		dataIndex: 'building',
		align: 'center',
		title: 'Корпус',
		width: 128
	},
	{
		dataIndex: 'ordinanceCount',
		align: 'center',
		title: 'Всего предписаний'
	},
	{
		title: 'Устранено',
		children: [
			{
				dataIndex: 'eliminatedTotal',
				align: 'center',
				title: 'Всего'
			},
			{
				dataIndex: 'eliminatedExpired',
				align: 'center',
				title: 'Просрочено'
			}
		]
	},
	{
		title: 'В работе (не устранено)',
		children: [
			{
				dataIndex: 'processTotal',
				align: 'center',
				title: 'Всего'
			},
			{
				dataIndex: 'processExpired',
				align: 'center',
				title: 'Просрочено'
			}
		]
	},
	{
		title: 'Штрафы',
		children: [
			{
				align: 'center',
				title: 'Всего',
				render: (_, record) =>
					'ordinancesFineQuantity' in record && typeof record.ordinancesFineQuantity === 'number'
						? record.ordinancesFineQuantity
						: record.ordinancesFineTotalQuantity
			},
			{
				align: 'center',
				title: 'Снято',
				render: (_, record) =>
					'ordinancesFineRemovedQuantity' in record &&
					typeof record.ordinancesFineRemovedQuantity === 'number'
						? record.ordinancesFineRemovedQuantity
						: record.ordinancesFineRemovedTotalQuantity
			}
		]
	},
	{
		dataIndex: 'completionPercent',
		align: 'center',
		title: '% выполнения в срок',
		render: value => (
			<span style={{ color: progressBarColor(value), fontWeight: 'bold' }}>{value}%</span>
		)
	}
]

export const HOCReportTable: FC<IProps> = () => {
	const data = useAppSelector(state => state.lightReport.hoc)
	const [expendedKeys, setExpendedKeys] = useState<string[]>([])
	const currentProject = useAppSelector(state => state.environment.project)

	useEffect(() => {
		const expendedDataKeys: string[] = []
		data.forEach(el => expendedDataKeys.push(el!.responsibleManager))
		setExpendedKeys(expendedDataKeys)
	}, [data])

	return (
		<>
			{!!data.length ? (
				<Table
					rowKey={(record, index) => record.responsibleManager}
					bordered
					size="small"
					className="app-hoc-table"
					rowClassName="app-hoc-row"
					dataSource={data}
					columns={columns}
					pagination={false}
					style={{ marginTop: '1rem' }}
					sticky={{ offsetHeader: -16 }}
					expandable={{
						defaultExpandAllRows: true,
						showExpandColumn: false,
						expandedRowKeys: expendedKeys
					}}
				/>
			) : (
				<Empty />
			)}
		</>
	)
}

const progressBarColor = (percent: number): string => {
	switch (true) {
		case percent >= 80:
			return 'green'
		case percent >= 60 && percent <= 79:
			return 'orange'
		default:
			return 'red'
	}
}
