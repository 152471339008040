import { IContract } from 'interfaces/IContract'
import { IContractor } from 'interfaces/IEnvironment'
import { ISignDto } from 'interfaces/IOrdinance'
import { API } from 'services/ApiService'
import { TEMPLATE_TYPE } from 'shared/constants/checklist-types'
import {
	TChecklist,
	TChecklistCreateDto,
	TChecklistPrepare,
	TChecklistUpdateDto
} from '../model/checklist-card-schema'

export const checklistCardApi = {
	async prepare(prepare: TChecklistPrepare) {
		return await API<TChecklist>({
			url: `/v1/checklists/${
				typeof prepare.templateType === 'number' && prepare.templateType === TEMPLATE_TYPE.ndc
					? 'ndc/'
					: ''
			}${prepare.templateId}/template`,
			method: 'get',
			params: { checklistId: prepare.checklistId }
		}).then(response => response.data)
	},

	async get(id: string, templateType: TEMPLATE_TYPE) {
		return await API<TChecklist>({
			url: `/v1/checklists/${
				typeof templateType === 'number' && templateType === TEMPLATE_TYPE.ndc ? 'ndc/' : ''
			}${id}`,
			method: 'get'
		}).then(response => response.data)
	},

	async getContractors() {
		return await API<IContractor[]>({
			url: `/v1/contractors`,
			method: 'get'
		}).then(response => response.data)
	},

	async getSubContractors(contractorId: string | string[], projectId: string) {
		return await API<IContractor[]>({
			url: `/v1/contractors/subContractors`,
			method: 'get',
			params: { contractorIds: typeof contractorId === 'string' ? [contractorId] : contractorId, projectId }
		}).then(response => response.data)
	},

	async getCommissioning(contractorId: string, projectId: string) {
		return await API<IContractor[]>({
			url: `/v1/contractors/commissioning`,
			method: 'get',
			params: { contractorId, projectId }
		}).then(response => response.data)
	},

	async getContracts(contractorId?: string, projectId?: string) {
		return await API<IContract[]>({
			url: `/v1/contracts`,
			method: 'get',
			params: { contractorId, projectId }
		}).then(response => response.data)
	},

	async getResponsibleUsers(templateType: TEMPLATE_TYPE, name?: string, projectId?: string) {
		return await API<IContractor[]>({
			url: `/v1/checklists/responsible-users`,
			method: 'get',
			params: { templateType, name, projectId }
		}).then(response => response.data)
	},

	async createAct(checkListId: string) {
		return await API({
			url: `/v1/checklists/${checkListId}/attachments/checklistsUkKc`,
			method: 'post'
		}).then(response => response.data)
	},

	async recreateAct(checkListId: string) {
		return await API({
			url: `/v1/checklists/${checkListId}/attachments/checklistsUkKc`,
			method: 'patch'
		}).then(response => response.data)
	},

	async signAct(checkListId: string, fileId: string, signDto: ISignDto['base64File']) {
		return await API({
			url: `/v1/checklists/${checkListId}/attachments/${fileId}/sign`,
			method: 'post',
			data: signDto
		}).then(response => response)
	},

	async setAnswer(id: string, answer: number | null) {
		return await API({
			url: `/v1/checklistItems/${id}`,
			method: 'patch',
			data: { answer }
		}).then(response => response.data)
	},

	async createChecklist(dto: TChecklistCreateDto) {
		return await API({
			url: `/v1/checklists`,
			method: 'post',
			data: [dto]
		}).then(response => response.data)
	},

	async updateChecklist(id: string, dto: TChecklistUpdateDto) {
		return await API({
			url: `/v1/checklists/${id}`,
			method: 'patch',
			data: dto
		}).then(response => response.data)
	},

	async setChecklistCancel(id: string) {
		return await API({
			url: `/v1/checklists/${id}/cancel`,
			method: 'patch'
		}).then(response => response.data)
	},

	async setUnderWarranty(id: string, data: FormData) {
		return await API({
			headers: { 'content-type': 'multipart/form-data' },
			url: `/v1/checklists/${id}/accepted-under-warranty`,
			method: 'patch',
			data
		}).then(response => response.data)
	},

	async assignIssue(itemId: string, issueId: string[]) {
		return await API({
			url: `/v1/checkListItems/${itemId}/issues/assign`,
			method: 'patch',
			data: issueId
		}).then(response => response.data)
	},

	async addDocumentationLink(itemId: string, documentation: string) {
		return await API({
			url: `/v1/checklistItems/${itemId}/documentation`,
			method: 'patch',
			data: { documentation }
		}).then(response => response)
	},

	async addFiles(itemId: string, data: FormData) {
		return await API({
			headers: { 'content-type': 'multipart/form-data' },
			url: `/v1/checklistItems/${itemId}/attachments`,
			method: 'post',
			data
		}).then(async response => response.data)
	},
	async removeFile(itemId: string, data: FormData) {
		return await API({
			headers: { 'content-type': 'multipart/form-data' },
			url: `/v1/checklistItems/${itemId}/attachments`,
			method: 'post',
			data
		}).then(async response => response.data)
	},

	async getItemFileList(itemId: string) {
		return await API({
			url: `/v1/checkListItems/${itemId}/attachments`,
			method: 'get'
		}).then(response => response.data)
	},

	async getFile(fileId: string) {
		return await API<Blob>({
			url: `/v1/files/${fileId}/download`,
			method: 'get',
			responseType: 'blob'
		}).then(response => response.data)
	}
}
