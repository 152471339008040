import {
	AppstoreFilled,
	AppstoreTwoTone,
	BuildTwoTone,
	FolderFilled,
	FolderOpenFilled
} from '@ant-design/icons'
import { CellContext, ColumnDef, Row } from '@tanstack/react-table'
import { Row as AntRow, Button, Checkbox, Col, Flex, Space, Tooltip } from 'antd'
import { useAppSelector } from 'hooks/appReduxHook'
import { useCallback, useMemo } from 'react'
import { CONTAINER_STATUS, PERMISSIONS } from 'shared/constants'
import { useCheckPermissions } from 'shared/useCheckPermissions'
import { RoundedNumber } from 'widgets/estimate/model/estimate-helper'
import { useShallow } from 'zustand/react/shallow'
import { IAdjustmentPosition, IAdjustmentPositionMaterial, IAdjustmentSection } from '..'
import { AdjustmentCell } from '../ui/AdjustmentCell'
import { AdjustmentIndentCell } from '../ui/AdjustmentIndentCell'
import { AdjustmentNominatedCell } from '../ui/AdjustmentNominatedCell'
import { AdjustmentNoteCell } from '../ui/AdjustmentNoteCell'
import { AdjustmentPositionMaterialTitle } from '../ui/AdjustmentPositionMaterialTitle'
import { AdjustmentPositionTitle } from '../ui/AdjustmentPositionTitle'
import { AdjustmentSectionTitle } from '../ui/AdjustmentSectionTitle'
import { useAdjustmentState } from './adjustment-state'
import { useContainerVisas } from 'widgets/wbs/model/wbs-queries'
import {
	useCanVisaApproveLocalEstimate,
	useContainerLocalEstimateVisas
} from 'pages/unmodelPage/localEstimate/model/localEstimateQuery'
import { IStatusRouteItemVisa } from 'pages/unmodelPage/wbsContainer'
import { IBase } from 'interfaces/IBase'
import { useCanVisaApprove } from 'pages/unmodelPage/wbsContainer/model/wbsContainerQuery'

export const useAdjustmentColumns = () => {
	const { user } = useAppSelector(state => state.environment)
	const checkPermissions = useCheckPermissions()

	const { container, data, setSelectedRows, setEstimatePositions } = useAdjustmentState(
		useShallow(state => ({
			container: state.container,
			data: state.sections,
			setEstimatePositions: state.setEstimatePositions,
			setSelectedRows: state.setSelectedRows
		}))
	)
	const isAuthor =
		(user?.isAdmin ||
			container?.author.id === user?.id ||
			container?.redactors.some(r => r.id === user?.id)) ??
		false

	const visasQuery =
		typeof container?.isLocalEstimate === 'undefined'
			? useContainerVisas!
			: useContainerLocalEstimateVisas!
	const { data: visas } = visasQuery(container?.id, true)
	const visasContainer = useMemo(
		() => visas?.find(v => v.id === container?.status.id)?.visas,
		[container, visas]
	)

	const { data: canVisaApprove } = useCanVisaApprove(
		typeof container?.isLocalEstimate === 'undefined' ? container?.id : undefined,
		true,
		typeof container?.isLocalEstimate === 'undefined' ? 'wbs' : undefined
	)

	const { data: canVisaApproveLocalEstimate } = useCanVisaApproveLocalEstimate(
		typeof container?.isLocalEstimate !== 'undefined' ? container?.id : undefined,
		true
	)

	const visaIssued = useMemo(
		() =>
			!!visasContainer?.find(
				v =>
					!!(
						typeof container?.isLocalEstimate === 'undefined'
							? canVisaApprove
							: canVisaApproveLocalEstimate
					)?.includes(v.id) && v.visaState !== null
			),
		[container, visas, canVisaApprove, canVisaApproveLocalEstimate, visasContainer]
	)

	const isLocalEstimate = typeof container?.isLocalEstimate !== 'undefined'
	const canEditCorrectLocalEstimate =
		container?.isLocalEstimate === false &&
		isAuthor &&
		checkPermissions([PERMISSIONS.AdjustmentCreate], container?.project?.id!) &&
		(user?.isAdmin ||
			container?.status.name === CONTAINER_STATUS.new ||
			container?.status.name === CONTAINER_STATUS.preparation)

	const columns = useMemo<
		ColumnDef<IAdjustmentSection | IAdjustmentPosition | IAdjustmentPositionMaterial>[]
	>(
		() => [
			{
				id: 'main',
				header: '',
				columns: [
					{
						id: 'name',
						accessorKey: 'name',
						header: row => (
							<AntRow
								align="middle"
								justify="space-between"
								style={{ width: '100%', justifyContent: 'space-between' }}
							>
								<Col>Наименование МТР, работ/услуг</Col>
								<Col>
									<Button
										type="link"
										// hidden={
										// 	!row.table.options.data.some(
										// 		elem => 'children' in elem && !!elem.children?.length
										// 	)
										// }
										onClick={() => {
											const expandedRows = row.table.getRowModel().flatRows.map(elem => {
												if (
													'codifier' in elem.original &&
													elem.original.hasPositions &&
													!elem.original.children
												) {
													setEstimatePositions(
														elem.original.id,
														undefined,
														!row.table.getIsSomeRowsExpanded()
													)
												}
												return (
													(('materialName' in elem.original && elem.original.materialName) ||
														('hasPositions' in elem.original &&
															elem.original.hasPositions === true) ||
														('children' in elem.original && elem.original.children?.length)) &&
													elem.original.id
												)
											})
											const expandedMap = expandedRows.reduce((acc: any, elem: any) => {
												acc[elem] = true
												return acc
											}, {})
											row.table.setExpanded(row.table.getIsSomeRowsExpanded() ? {} : expandedMap)
										}}
									>
										{row.table.getIsSomeRowsExpanded() ? 'Свернуть' : 'Развернуть'}
									</Button>
								</Col>
							</AntRow>
						),
						size: 768,
						maxSize: 768,
						cell: ({ cell, row }) => {
							const record = row.original
							return (
								<Flex className="estimate-position-title" gap={8}>
									{'codifier' in record ? (
										row.getCanExpand() ? (
											row.getIsExpanded() ? (
												<FolderOpenFilled style={{ color: '#feb300' }} />
											) : (
												<FolderFilled style={{ color: '#feb300' }} />
											)
										) : (
											<FolderFilled style={{ color: '#ddd' }} />
										)
									) : 'workName' in record ? (
										<>
											{!container?.ready &&
												!(visaIssued && !user?.isAdmin) &&
												((!isLocalEstimate &&
													isAuthor &&
													checkPermissions(
														[PERMISSIONS.AdjustmentCreate],
														container?.project?.id!
													) &&
													(user?.isAdmin ||
														container?.status.name === CONTAINER_STATUS.new ||
														container?.status.name === CONTAINER_STATUS.preparation)) ||
													canEditCorrectLocalEstimate) && (
													<Checkbox
														{...{
															checked: row.getIsSelected(),
															disabled: record.isDeleted || !row.getCanSelect(),
															indeterminate: row.getIsSomeSelected()
														}}
														onChange={e => {
															row.getToggleSelectedHandler()(e)
															if ('workName' in row.original)
																setSelectedRows(row as Row<IAdjustmentPosition>)
														}}
													/>
												)}
											{'children' in record && !!record.children?.length ? (
												row.getIsExpanded() ? (
													<AppstoreTwoTone
														onClick={() => row.getToggleExpandedHandler()()}
														twoToneColor="#6fa9fa"
													/>
												) : (
													<AppstoreFilled
														onClick={() => row.getToggleExpandedHandler()()}
														style={{ color: '#6fa9fa' }}
													/>
												)
											) : (
												<AppstoreTwoTone twoToneColor="#9e9e9e" />
											)}
										</>
									) : (
										<BuildTwoTone twoToneColor="#5d9f6c" />
									)}

									{'codifier' in record ? (
										<AdjustmentSectionTitle
											row={row as Row<IAdjustmentSection>}
											callBack={() => getEstimatePositions(row)}
											visaIssued={visaIssued && !user?.isAdmin}
										/>
									) : 'workName' in record ? (
										<AdjustmentPositionTitle
											row={record as IAdjustmentPosition}
											mainRow={row as Row<IAdjustmentPosition>}
											visaIssued={visaIssued && !user?.isAdmin}
											// callBack={() => row.getToggleExpandedHandler()()}
										/>
									) : (
										<AdjustmentPositionMaterialTitle row={record as IAdjustmentPositionMaterial} />
									)}
								</Flex>
							)
						}
					},
					{
						id: 'measureUnit',
						accessorKey: 'measureUnit',
						header: 'Ед.изм',
						size: 100,
						cell: ({ row }) =>
							'measureUnit' in row.original && <center>{row.original.measureUnit?.name}</center>
					}
				]
			},
			{
				id: 'origin',
				header: `${
					typeof container?.isLocalEstimate === 'undefined'
						? 'Первая версия'
						: 'Актуальная версия сметы к договору'
				}`,
				columns: [
					// {
					// 	id: 'measureUnit',
					// 	accessorKey: 'measureUnit',
					// 	header: 'Ед.изм',
					// 	size: 100,
					// 	cell: ({ row }) =>
					// 		'measureUnit' in row.original && <center>{row.original.measureUnit?.name}</center>
					// },
					{
						id: 'amount',
						accessorKey: 'amount',
						header: 'Кол-во',
						size: 140,
						cell: ({ cell, row }) =>
							!('codifier' in row.original) &&
							!row.original.isUnrelated &&
							RoundedNumber(cell.getValue())
					},
					{
						id: 'rate',
						accessorKey: 'rate',
						header: 'К-т нормы расхода',
						size: 130,
						cell: ({ cell, row }) =>
							!('codifier' in row.original) &&
							!row.original.isUnrelated &&
							RoundedNumber(cell.getValue())
					},
					{
						id: 'amountWithRate',
						accessorKey: 'amountWithRate',
						header: 'Кол-во с учётом к-та расхода',
						size: 140,
						cell: ({ cell, row }) =>
							!('codifier' in row.original) &&
							!row.original.isUnrelated &&
							RoundedNumber(cell.getValue())
					},

					{
						id: 'price',
						header: 'Цена за ед.изм., руб. с НДС',
						size: 240,
						columns: [
							{
								id: 'priceMaterial',
								accessorKey: 'priceMaterial',
								header: 'Материалы (МТР)',
								size: 120,
								cell: ({ cell, row }) =>
									!('codifier' in row.original) &&
									!row.original.isUnrelated &&
									RoundedNumber(cell.getValue())
							},
							{
								id: 'priceService',
								accessorKey: 'priceService',
								header: 'СМР/услуги',
								size: 120,
								cell: ({ cell, row }) =>
									!('codifier' in row.original) &&
									!row.original.isUnrelated &&
									RoundedNumber(cell.getValue())
							}
						]
					},
					{
						id: 'totalPrice',
						accessorKey: 'totalPrice',
						header: () => (
							<Space
								direction="vertical"
								size="small"
								style={{ rowGap: 0, lineHeight: '100% !important' }}
							>
								<p style={{ margin: 0 }}>Всего за единицу,</p>
								<p style={{ margin: 0, fontSize: 11, lineHeight: 1.2 }}>руб. с НДС</p>
							</Space>
						),
						size: 120,
						cell: ({ cell, row }) =>
							!('codifier' in row.original) &&
							!row.original.isUnrelated &&
							RoundedNumber(cell.getValue())
					},
					{
						id: 'total',
						header: 'Стоимость за единицу, руб. с НДС',
						size: 300,
						columns: [
							{
								id: 'totalSumMaterials',
								accessorKey: 'totalSumMaterials',
								header: 'Материалы (МТР)',
								size: 150,
								cell: ({ cell, row }) =>
									'codifier' in row.original ||
									('workName' in row.original && row.original.isUnrelated === false)
										? RoundedNumber(
												'workTotal' in row.original
													? row.original.workTotal?.totalSumMaterials
													: cell.getValue()
										  )
										: ''
							},
							{
								id: 'totalSumService',
								accessorKey: 'totalSumService',
								header: 'СМР/услуги',
								size: 150,
								cell: ({ cell, row }) =>
									'codifier' in row.original ||
									('workName' in row.original && row.original.isUnrelated === false)
										? RoundedNumber(
												'workTotal' in row.original
													? row.original.workTotal?.totalSumService
													: cell.getValue()
										  )
										: ''
							}
						]
					},
					{
						id: 'totalSum',
						accessorKey: 'totalSum',
						header: () => (
							<Space
								direction="vertical"
								size="small"
								style={{ rowGap: 0, lineHeight: '100% !important' }}
							>
								<p style={{ margin: 0 }}>Всего стоимость</p>
								<p style={{ margin: 0, fontSize: 11, lineHeight: 1.2 }}>руб. с НДС</p>
							</Space>
						),
						size: 150,
						cell: ({ cell, row }) =>
							'codifier' in row.original ||
							('workName' in row.original && row.original.isUnrelated === false)
								? RoundedNumber(
										'workTotal' in row.original ? row.original.workTotal?.totalSum : cell.getValue()
								  )
								: ''
					},
					{
						id: 'isNominated',
						accessorKey: 'isNominated',
						header: () => <Tooltip title="Признак номинации">НМТР</Tooltip>,
						size: 80,
						cell: ({ cell, row }) =>
							'isNominated' in row.original &&
							!row.original.isUnrelated && <Checkbox disabled checked={cell.getValue()} />
					}
				]
			},
			{
				id: 'adjustmentExcludeGroup',
				header: 'Исключить',
				size: 240,
				enableGrouping: true,
				columns: [
					{
						id: 'amountWithRateExclude',
						accessorKey: 'amountWithRateExclude',
						header: 'Кол-во с учетом к-та расхода',
						size: 120,
						cell: ({ row }) =>
							!('codifier' in row.original) && (
								<AdjustmentIndentCell
									type="exclude"
									subType={isLocalEstimate ? 'origin' : 'second'}
									cellType="amountWithRate"
									row={row as Row<IAdjustmentPosition | IAdjustmentPositionMaterial>}
								/>
							)
					},
					{
						id: 'priceMaterialExclude',
						accessorKey: 'priceMaterialExclude',
						header: 'Цена за ед. МТР, руб. с НДС',
						size: 120,
						cell: ({ row }) =>
							!('codifier' in row.original) && (
								<AdjustmentIndentCell
									type="exclude"
									subType={isLocalEstimate ? 'origin' : 'second'}
									cellType="priceMaterial"
									row={row as Row<IAdjustmentPosition | IAdjustmentPositionMaterial>}
								/>
							)
					},
					{
						id: 'priceServiceExclude',
						accessorKey: 'priceServiceExclude',
						header: 'Цена за ед. СМР, руб. с НДС',
						size: 120,
						cell: ({ row }) =>
							'workName' in row.original && (
								<AdjustmentIndentCell
									type="exclude"
									subType={isLocalEstimate ? 'origin' : 'second'}
									cellType="priceService"
									row={row as Row<IAdjustmentPosition | IAdjustmentPositionMaterial>}
								/>
							)
					},
					{
						id: 'totalSumExclude',
						accessorKey: 'totalSumExclude',
						header: 'Всего стоимость, руб. с НДС',
						size: 120,
						cell: ({ row }) =>
							!('codifier' in row.original) && (
								<AdjustmentIndentCell
									type="exclude"
									subType={isLocalEstimate ? 'origin' : 'second'}
									cellType="totalSum"
									row={row as Row<IAdjustmentPosition | IAdjustmentPositionMaterial>}
								/>
							)
					}
				]
			},
			{
				id: 'adjustmentIncludeGroup',
				header: 'Добавить',
				size: 240,
				columns: [
					{
						id: 'amountWithRateInclude',
						accessorKey: 'amountWithRateInclude',
						header: 'Кол-во с учетом к-та расхода',
						size: 120,
						cell: ({ row }) =>
							!('codifier' in row.original) && (
								<AdjustmentIndentCell
									type="include"
									subType={isLocalEstimate ? 'origin' : 'second'}
									cellType="amountWithRate"
									row={row as Row<IAdjustmentPosition | IAdjustmentPositionMaterial>}
								/>
							)
					},
					{
						id: 'priceMaterialInclude',
						accessorKey: 'priceMaterialInclude',
						header: 'Цена за ед. МТР, руб. с НДС',
						size: 120,
						cell: ({ row }) =>
							!('codifier' in row.original) && (
								<AdjustmentIndentCell
									type="include"
									subType={isLocalEstimate ? 'origin' : 'second'}
									cellType="priceMaterial"
									row={row as Row<IAdjustmentPosition | IAdjustmentPositionMaterial>}
								/>
							)
					},
					{
						id: 'priceServiceInclude',
						accessorKey: 'priceServiceInclude',
						header: 'Цена за ед. СМР, руб. с НДС',
						size: 120,
						cell: ({ row }) =>
							'workName' in row.original && (
								<AdjustmentIndentCell
									type="include"
									subType={isLocalEstimate ? 'origin' : 'second'}
									cellType="priceService"
									row={row as Row<IAdjustmentPosition | IAdjustmentPositionMaterial>}
								/>
							)
					},
					{
						id: 'totalSumInclude',
						accessorKey: 'totalSumInclude',
						header: 'Всего стоимость, руб. с НДС',
						size: 120,
						cell: ({ row }) =>
							!('codifier' in row.original) && (
								<AdjustmentIndentCell
									type="include"
									subType={isLocalEstimate ? 'origin' : 'second'}
									cellType="totalSum"
									row={row as Row<IAdjustmentPosition | IAdjustmentPositionMaterial>}
								/>
							)
					}
				]
			},
			{
				id: 'done',
				header: 'Выполнено',
				size: 240,
				columns: [
					{
						id: 'confirmedVolumeAmount',
						accessorKey: 'confirmedVolumeAmount',
						header: 'Кол-во без НР',
						size: 120,
						cell: ({ cell, row }) =>
							!('codifier' in row.original) &&
							!row.original.isUnrelated &&
							RoundedNumber(cell.getValue())
					},
					{
						id: 'confirmedVolumeSum',
						accessorKey: 'confirmedVolumeSum',
						header: 'Всего стоимость, руб. с НДС',
						size: 120,
						cell: ({ cell, row }) =>
							!('codifier' in row.original) &&
							!row.original.isUnrelated &&
							RoundedNumber(cell.getValue())
					}
				]
			},
			{
				id: 'second',
				header: 'Версия подрядчика',
				columns: [
					{
						id: 'amountSecond',
						accessorKey: 'amountSecond',
						header: 'Кол-во',
						size: 140,
						cell: record =>
							!('codifier' in record.row.original) &&
							!record.row.original.isDeleted &&
							(typeof container?.isLocalEstimate === 'undefined' ||
								(!record.row.original.isClosed &&
									record.row.original.isEditable &&
									container?.isLocalEstimate === false)) &&
							('workName' in record.row.original ||
								('materialName' in record.row.original && record.row.original.isExpandable)) ? (
								<AdjustmentCell
									record={
										record as CellContext<IAdjustmentPosition | IAdjustmentPositionMaterial, any>
									}
									cellType="amountSecond"
									parents={record.row.getParentRows()}
									visaIssued={visaIssued && !user?.isAdmin}
								/>
							) : (
								RoundedNumber(record.cell.getValue())
							)
					},
					{
						id: 'rateSecond',
						accessorKey: 'rateSecond',
						header: 'К-т нормы расхода',
						size: 130,
						cell: ({ cell, row }) => RoundedNumber(cell.getValue())
					},
					{
						id: 'amountWithRateSecond',
						accessorKey: 'amountWithRateSecond',
						header: 'Кол-во с учётом к-та расхода',
						size: 140,
						cell: ({ cell, row }) => RoundedNumber(cell.getValue())
					},
					{
						id: 'priceSecond',
						header: 'Цена за ед.изм., руб. с НДС',
						size: 240,
						columns: [
							{
								id: 'priceMaterialSecond',
								accessorKey: 'priceMaterialSecond',
								header: 'Материалы (МТР)',
								size: 120,
								cell: record =>
									(typeof container?.isLocalEstimate === 'undefined' &&
										'isClosed' in record.row.original &&
										record.row.original.isClosed &&
										'workName' in record.row.original &&
										!!record.row.original.children?.length) ||
									(typeof container?.isLocalEstimate !== 'undefined' &&
										'isClosed' in record.row.original &&
										record.row.original.isClosed &&
										record.row.original.amountSecond === 0 &&
										'workName' in record.row.original &&
										!!record.row.original.children?.length) ? (
										'n/a'
									) : ((typeof container?.isLocalEstimate === 'undefined' ||
											('isClosed' in record.row.original &&
												!record.row.original.isClosed &&
												record.row.original.isEditable &&
												container?.isLocalEstimate === false)) &&
											'workName' in record.row.original &&
											record.row.original.children?.length === 0 &&
											!record.row.original.isDeleted) ||
									  ('materialName' in record.row.original && !record.row.original.isDeleted) ? (
										<AdjustmentCell
											record={
												record as CellContext<
													IAdjustmentPosition | IAdjustmentPositionMaterial,
													any
												>
											}
											cellType="priceMaterialSecond"
											parents={record.row.getParentRows()}
											visaIssued={visaIssued && !user?.isAdmin}
										/>
									) : (
										RoundedNumber(record.cell.getValue())
									)
							},
							{
								id: 'priceServiceSecond',
								accessorKey: 'priceServiceSecond',
								header: 'СМР/услуги',
								size: 120,
								cell: record =>
									'workName' in record.row.original &&
									(typeof container?.isLocalEstimate === 'undefined' ||
										(!record.row.original.isClosed &&
											record.row.original.isEditable &&
											container?.isLocalEstimate === false)) &&
									!record.row.original.isDeleted ? (
										<AdjustmentCell
											record={record as CellContext<IAdjustmentPosition, any>}
											cellType="priceServiceSecond"
											parents={record.row.getParentRows()}
											visaIssued={visaIssued && !user?.isAdmin}
										/>
									) : (
										RoundedNumber(record.cell.getValue())
									)
							}
						]
					},
					{
						id: 'totalPriceSecond',
						accessorKey: 'totalPriceSecond',
						header: () => (
							<Space
								direction="vertical"
								size="small"
								style={{ rowGap: 0, lineHeight: '100% !important' }}
							>
								<p style={{ margin: 0 }}>Всего за единицу,</p>
								<p style={{ margin: 0, fontSize: 11, lineHeight: 1.2 }}>руб. с НДС</p>
							</Space>
						),
						size: 120,
						cell: ({ cell, row }) => RoundedNumber(cell.getValue())
					},
					{
						id: 'totalSecond',
						header: 'Стоимость за единицу, руб. с НДС',
						size: 300,
						columns: [
							{
								id: 'totalSumMaterialsSecond',
								accessorKey: 'totalSumMaterialsSecond',
								header: 'Материалы (МТР)',
								size: 150,
								cell: ({ cell, row }) =>
									!('materialName' in row.original)
										? RoundedNumber(
												'workTotal' in row.original
													? row.original.workTotal?.totalSumMaterialsSecond
													: cell.getValue()
										  )
										: ''
							},
							{
								id: 'totalSumServiceSecond',
								accessorKey: 'totalSumServiceSecond',
								header: 'СМР/услуги',
								size: 150,
								cell: ({ cell, row }) =>
									RoundedNumber(
										'workTotal' in row.original
											? row.original.workTotal?.totalSumServiceSecond
											: cell.getValue()
									)
							}
						]
					},
					{
						id: 'totalSumSecond',
						accessorKey: 'totalSumSecond',
						header: () => (
							<Space
								direction="vertical"
								size="small"
								style={{ rowGap: 0, lineHeight: '100% !important' }}
							>
								<p style={{ margin: 0 }}>Всего стоимость</p>
								<p style={{ margin: 0, fontSize: 11, lineHeight: 1.2 }}>руб. с НДС</p>
							</Space>
						),
						size: 150,
						cell: ({ cell, row }) =>
							RoundedNumber(
								'workTotal' in row.original
									? row.original.workTotal?.totalSumSecond
									: cell.getValue()
							)
					},
					{
						id: 'isNominatedSecond',
						accessorKey: 'isNominatedSecond',
						header: () => <Tooltip title="Признак номинации">НМТР</Tooltip>,
						size: 80,
						cell: ({ cell, row }) =>
							'isNominatedSecond' in row.original && <Checkbox disabled checked={cell.getValue()} />
					}
				]
			},
			{
				id: 'adjustmentExcludeSecondGroup',
				header: 'Исключить',
				size: 240,
				enableGrouping: true,
				columns: [
					{
						id: 'amountWithRateSecondExclude',
						accessorKey: 'amountWithRateSecondExclude',
						header: 'Кол-во с учетом к-та расхода',
						size: 120,
						cell: ({ row }) =>
							!('codifier' in row.original) && (
								<AdjustmentIndentCell
									type="exclude"
									subType="second"
									cellType="amountWithRate"
									row={row as Row<IAdjustmentPosition | IAdjustmentPositionMaterial>}
								/>
							)
					},
					{
						id: 'priceMaterialSecondExclude',
						accessorKey: 'priceMaterialSecondExclude',
						header: 'Цена за ед. МТР, руб. с НДС',
						size: 120,
						cell: ({ row }) =>
							!('codifier' in row.original) && (
								<AdjustmentIndentCell
									type="exclude"
									subType="second"
									cellType="priceMaterial"
									row={row as Row<IAdjustmentPosition | IAdjustmentPositionMaterial>}
								/>
							)
					},
					{
						id: 'priceServiceSecondExclude',
						accessorKey: 'priceServiceSecondExclude',
						header: 'Цена за ед. СМР, руб. с НДС',
						size: 120,
						cell: ({ row }) =>
							'workName' in row.original && (
								<AdjustmentIndentCell
									type="exclude"
									subType="second"
									cellType="priceService"
									row={row as Row<IAdjustmentPosition | IAdjustmentPositionMaterial>}
								/>
							)
					},
					{
						id: 'totalSumSecondExclude',
						accessorKey: 'totalSumSecondExclude',
						header: 'Всего стоимость, руб. с НДС',
						size: 120,
						cell: ({ row }) =>
							!('codifier' in row.original) && (
								<AdjustmentIndentCell
									type="exclude"
									subType="second"
									cellType="totalSum"
									row={row as Row<IAdjustmentPosition | IAdjustmentPositionMaterial>}
								/>
							)
					}
				]
			},
			{
				id: 'adjustmentIncludeSecondGroup',
				header: 'Добавить',
				size: 240,
				columns: [
					{
						id: 'amountWithRateSecondInclude',
						accessorKey: 'amountWithRateSecondInclude',
						header: 'Кол-во с учетом к-та расхода',
						size: 120,
						cell: ({ row }) =>
							!('codifier' in row.original) && (
								<AdjustmentIndentCell
									type="include"
									subType="second"
									cellType="amountWithRate"
									row={row as Row<IAdjustmentPosition | IAdjustmentPositionMaterial>}
								/>
							)
					},
					{
						id: 'priceMaterialSecondInclude',
						accessorKey: 'priceMaterialSecondInclude',
						header: 'Цена за ед. МТР, руб. с НДС',
						size: 120,
						cell: ({ row }) =>
							!('codifier' in row.original) && (
								<AdjustmentIndentCell
									type="include"
									subType="second"
									cellType="priceMaterial"
									row={row as Row<IAdjustmentPosition | IAdjustmentPositionMaterial>}
								/>
							)
					},
					{
						id: 'priceServiceSecondInclude',
						accessorKey: 'priceServiceSecondInclude',
						header: 'Цена за ед. СМР, руб. с НДС',
						size: 120,
						cell: ({ row }) =>
							'workName' in row.original && (
								<AdjustmentIndentCell
									type="include"
									subType="second"
									cellType="priceService"
									row={row as Row<IAdjustmentPosition | IAdjustmentPositionMaterial>}
								/>
							)
					},
					{
						id: 'totalSumSecondInclude',
						accessorKey: 'totalSumSecondInclude',
						header: 'Всего стоимость, руб. с НДС',
						size: 120,
						cell: ({ row }) =>
							!('codifier' in row.original) && (
								<AdjustmentIndentCell
									type="include"
									subType="second"
									cellType="totalSum"
									row={row as Row<IAdjustmentPosition | IAdjustmentPositionMaterial>}
								/>
							)
					}
				]
			},
			{
				id: 'sdu',
				header: 'Версия СДУ',
				columns: [
					{
						id: 'amountSdu',
						accessorKey: 'amountSdu',
						header: 'Кол-во',
						size: 140,
						cell: record =>
							!('codifier' in record.row.original) &&
							!record.row.original.isDeleted &&
							typeof container?.isLocalEstimate === 'undefined' &&
							('workName' in record.row.original ||
								('materialName' in record.row.original && record.row.original.isExpandable)) ? (
								<AdjustmentCell
									record={
										record as CellContext<IAdjustmentPosition | IAdjustmentPositionMaterial, any>
									}
									cellType="amountSdu"
									parents={record.row.getParentRows()}
									visaIssued={visaIssued && !user?.isAdmin}
								/>
							) : (
								RoundedNumber(record.cell.getValue())
							)
						// cell: ({ cell, row }) => RoundedNumber(cell.getValue())
					},
					{
						id: 'rateSdu',
						accessorKey: 'rateSdu',
						header: 'К-т нормы расхода',
						size: 130,
						cell: ({ cell, row }) => RoundedNumber(cell.getValue())
					},
					{
						id: 'amountWithRateSdu',
						accessorKey: 'amountWithRateSdu',
						header: 'Кол-во с учётом к-та расхода',
						size: 140,
						cell: ({ cell, row }) => RoundedNumber(cell.getValue())
					},
					{
						id: 'priceSdu',
						header: 'Цена за ед.изм., руб. с НДС',
						size: 240,
						columns: [
							{
								id: 'priceMaterialSdu',
								accessorKey: 'priceMaterialSdu',
								header: 'Материалы (МТР)',
								size: 120,
								cell: record =>
									(typeof container?.isLocalEstimate === 'undefined' &&
										'isClosed' in record.row.original &&
										record.row.original.isClosed &&
										'workName' in record.row.original &&
										!!record.row.original.children?.length) ||
									(typeof container?.isLocalEstimate !== 'undefined' &&
										'isClosed' in record.row.original &&
										record.row.original.isClosed &&
										record.row.original.amountSdu === 0 &&
										'workName' in record.row.original &&
										!!record.row.original.children?.length) ? (
										'n/a'
									) : ((typeof container?.isLocalEstimate === 'undefined' ||
											('isClosed' in record.row.original &&
												!record.row.original.isClosed &&
												record.row.original.isEditable &&
												container?.isLocalEstimate === false)) &&
											'workName' in record.row.original &&
											record.row.original.children?.length === 0 &&
											!record.row.original.isDeleted) ||
									  ('materialName' in record.row.original && !record.row.original.isDeleted) ? (
										<AdjustmentCell
											record={
												record as CellContext<
													IAdjustmentPosition | IAdjustmentPositionMaterial,
													any
												>
											}
											cellType="priceMaterialSdu"
											parents={record.row.getParentRows()}
											visaIssued={visaIssued && !user?.isAdmin}
										/>
									) : (
										RoundedNumber(record.cell.getValue())
									)
							},
							{
								id: 'priceServiceSdu',
								accessorKey: 'priceServiceSdu',
								header: 'СМР/услуги',
								size: 120,
								cell: record =>
									'workName' in record.row.original &&
									(typeof container?.isLocalEstimate === 'undefined' ||
										(!record.row.original.isClosed &&
											record.row.original.isEditable &&
											container?.isLocalEstimate === false)) &&
									!record.row.original.isDeleted ? (
										<AdjustmentCell
											record={record as CellContext<IAdjustmentPosition, any>}
											cellType="priceServiceSdu"
											parents={record.row.getParentRows()}
											visaIssued={visaIssued && !user?.isAdmin}
										/>
									) : (
										RoundedNumber(record.cell.getValue())
									)
							}
						]
					},
					{
						id: 'totalPriceSdu',
						accessorKey: 'totalPriceSdu',
						header: () => (
							<Space
								direction="vertical"
								size="small"
								style={{ rowGap: 0, lineHeight: '100% !important' }}
							>
								<p style={{ margin: 0 }}>Всего за единицу,</p>
								<p style={{ margin: 0, fontSize: 11, lineHeight: 1.2 }}>руб. с НДС</p>
							</Space>
						),
						size: 120,
						cell: ({ cell, row }) => RoundedNumber(cell.getValue())
					},
					{
						id: 'totalSdu',
						header: 'Стоимость за единицу, руб. с НДС',
						size: 300,
						columns: [
							{
								id: 'totalSumMaterialsSdu',
								accessorKey: 'totalSumMaterialsSdu',
								header: 'Материалы (МТР)',
								size: 150,
								cell: ({ cell, row }) =>
									!('materialName' in row.original)
										? RoundedNumber(
												'workTotal' in row.original
													? row.original.workTotal?.totalSumMaterialsSdu
													: cell.getValue()
										  )
										: ''
							},
							{
								id: 'totalSumServiceSdu',
								accessorKey: 'totalSumServiceSdu',
								header: 'СМР/услуги',
								size: 150,
								cell: ({ cell, row }) =>
									RoundedNumber(
										'workTotal' in row.original
											? row.original.workTotal?.totalSumServiceSdu
											: cell.getValue()
									)
							}
						]
					},
					{
						id: 'totalSumSdu',
						accessorKey: 'totalSumSdu',
						header: () => (
							<Space
								direction="vertical"
								size="small"
								style={{ rowGap: 0, lineHeight: '100% !important' }}
							>
								<p style={{ margin: 0 }}>Всего стоимость</p>
								<p style={{ margin: 0, fontSize: 11, lineHeight: 1.2 }}>руб. с НДС</p>
							</Space>
						),
						size: 150,
						cell: ({ cell, row }) =>
							RoundedNumber(
								'workTotal' in row.original ? row.original.workTotal?.totalSumSdu : cell.getValue()
							)
					},
					{
						id: 'isNominatedSdu',
						accessorKey: 'isNominatedSdu',
						header: () => <Tooltip title="Признак номинации">НМТР</Tooltip>,
						size: 80,
						cell: ({ row }) => {
							const { original } = row
							return (
								'isNominatedSdu' in original && (
									<AdjustmentNominatedCell
										record={original}
										visaIssued={visaIssued && !user?.isAdmin}
									/>
								)
							)
						}
					}
				]
			},
			{
				id: 'deviationGroup',
				header: 'Отклонение',
				size: 240,
				columns: [
					{
						id: 'deviationSduContractor',
						header: `${
							typeof container?.isLocalEstimate === 'undefined'
								? 'СДУ/Первая версия'
								: 'Подрядчик/СДУ'
						}`,
						columns: [
							{
								id: 'totalDeviationCurrencySdu',
								accessorKey: 'deviationCurrencySdu',
								header: 'руб',
								size: 120,
								cell: ({ cell, row }) =>
									RoundedNumber(
										'workTotal' in row.original
											? row.original.workTotal?.totalDeviationCurrencySdu
											: cell.getValue()
									)
							},
							{
								id: 'totalDeviationPercentageSdu',
								accessorKey: 'deviationPercentageSdu',
								header: '%',
								size: 120,
								cell: ({ cell, row }) =>
									RoundedNumber(
										'workTotal' in row.original
											? row.original.workTotal?.totalDeviationPercentageSdu
											: cell.getValue()
									)
							}
						]
					},
					{
						id: 'deviationContractorOrigin',
						header: 'Подрядчик/Первая версия',
						columns: [
							{
								id: 'totalDeviationCurrencyOrigin',
								accessorKey: 'deviationCurrencyOrigin',
								header: 'руб',
								size: 120,
								cell: ({ cell, row }) =>
									RoundedNumber(
										'workTotal' in row.original
											? row.original.workTotal?.totalDeviationCurrencyOrigin
											: cell.getValue()
									)
							},
							{
								id: 'totalDeviationPercentageOrigin',
								accessorKey: 'deviationPercentageOrigin',
								header: '%',
								size: 120,
								cell: ({ cell, row }) =>
									RoundedNumber(
										'workTotal' in row.original
											? row.original.workTotal?.totalDeviationPercentageOrigin
											: cell.getValue()
									)
							}
						]
					}
				]
			},
			{
				id: 'noteSdu',
				header: '',
				size: 180,
				columns: [
					{
						id: 'noteSdu',
						accessorKey: 'noteSdu',
						header: 'Примечание СДУ',
						size: 180,
						cell: ({ cell, row }) =>
							!('codifier' in row.original) && (
								<AdjustmentNoteCell
									row={row.original}
									type={'noteSdu'}
									disabled={
										row.original.isDeleted ||
										!(
											(checkPermissions(
												[PERMISSIONS.AdjustmentWbsChangeNoteSdu],
												container?.project?.id!
											) ||
												checkPermissions(
													[PERMISSIONS.AdjustmentEstimateChangeNoteSdu],
													container?.project?.id!
												)) &&
											!container?.ready &&
											(container?.status.name === CONTAINER_STATUS.priceApproval ||
												(user?.isAdmin && container?.status.name !== CONTAINER_STATUS.approved))
										)
									}
								/>
							)
					}
				]
			},
			{
				id: 'noteDz',
				header: '',
				size: 180,
				columns: [
					{
						id: 'noteDz',
						accessorKey: 'noteDz',
						header: 'Примечание ДЗ',
						size: 180,
						cell: ({ cell, row }) =>
							!('codifier' in row.original) && (
								<AdjustmentNoteCell
									row={row.original}
									type={'noteDz'}
									disabled={
										row.original.isDeleted ||
										!(
											(checkPermissions(
												[PERMISSIONS.AdjustmentWbsChangeNoteDz],
												container?.project?.id!
											) ||
												checkPermissions(
													[PERMISSIONS.AdjustmentEstimateChangeNoteDz],
													container?.project?.id!
												)) &&
											!container?.ready &&
											(container?.status.name === CONTAINER_STATUS.priceApproval ||
												(user?.isAdmin && container?.status.name !== CONTAINER_STATUS.approved))
										)
									}
								/>
							)
					}
				]
			},
			{
				id: 'noteDp',
				header: '',
				size: 180,
				columns: [
					{
						id: 'noteDp',
						accessorKey: 'noteDp',
						header: 'Примечание УПВОР',
						size: 180,
						cell: ({ cell, row }) =>
							!('codifier' in row.original) && (
								<AdjustmentNoteCell
									row={row.original}
									type={'noteDp'}
									disabled={
										row.original.isDeleted ||
										!(
											(checkPermissions(
												[PERMISSIONS.AdjustmentWbsChangeNoteDp],
												container?.project?.id!
											) ||
												checkPermissions(
													[PERMISSIONS.AdjustmentEstimateChangeNoteDp],
													container?.project?.id!
												)) &&
											!container?.ready &&
											(container?.status.name === CONTAINER_STATUS.preparation ||
												(user?.isAdmin && container?.status.name !== CONTAINER_STATUS.approved))
										)
									}
								/>
							)
					}
				]
			},
			{
				id: 'comments',
				header: 'Примечания',
				size: 240,
				columns: [
					{
						id: 'commentSdu',
						accessorKey: 'commentSdu',
						header: 'Обоснование',
						size: 180,
						cell: ({ cell, row }) =>
							!('codifier' in row.original) && (
								<AdjustmentNoteCell
									row={row.original}
									type={'commentSdu'}
									disabled={row.original.isDeleted}
								/>
							)
					},
					{
						id: 'justification',
						accessorKey: 'justification',
						header: 'Обоснование ЕР',
						size: 180,
						cell: ({ cell, row }) =>
							!('codifier' in row.original) && (
								<AdjustmentNoteCell
									row={row.original}
									type={'justification'}
									disabled={row.original.isDeleted}
								/>
							)
					}
				]
			}
		],
		[data, container]
	)
	const getEstimatePositions = useCallback(
		async (row: Row<IAdjustmentSection | IAdjustmentPosition | IAdjustmentPositionMaterial>) => {
			const record = row.original
			const parents = row.getParentRows()
			if (
				!row.getIsExpanded() &&
				'codifier' in record &&
				typeof record.children === 'undefined' &&
				record.hasPositions
			) {
				setEstimatePositions(record.id, parents)
			}

			return row.getToggleExpandedHandler()()
		},
		[]
	)
	return columns
}
