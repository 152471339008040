import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { Modal, notification } from 'antd'
import { AxiosError } from 'axios'
import { useAppDispatch, useAppSelector } from 'hooks/appReduxHook'
import { IErrorDetail } from 'interfaces/IBase'
import { ISignDto } from 'interfaces/IOrdinance'
import { deleteFile } from 'services/AdminService'
import { TEMPLATE_TYPE } from 'shared/constants/checklist-types'
import { TChecklistCreateDto, TChecklistUpdateDto } from '../model/checklist-card-schema'
import { useChecklistCardState } from '../model/checklist-card-state'
import { checklistCardApi } from './checklist-card-api'
import { useIssueCardState } from 'widgets/issue-card'
import { setEnvironmentProject } from 'store/environmentSlice'
import { useLocation, useNavigate } from 'react-router-dom'

export const useChecklist = (onClose?: () => void) => {
	const navigate = useNavigate()
	const { pathname } = useLocation()
	const dispatch = useAppDispatch()
	const { project, user } = useAppSelector(state => state.environment)
	const { currentId, templateType, prepare, openCard, unset } = useChecklistCardState()
	const closeHandler = () => {
		unset()
		onClose && onClose()
		if (typeof project?.id === 'undefined') navigate('/', { replace: true })
	}
	return useQuery({
		queryKey: ['checklist', 'card', currentId, prepare],
		refetchOnWindowFocus: false,
		queryFn: async () => {
			if (typeof templateType === 'number') {
				if (prepare && !currentId) {
					return await checklistCardApi.prepare(prepare)
				}
				if (!prepare && currentId) {
					return await checklistCardApi.get(currentId, templateType)
				}
			}
			return null
		},
		select: data => data,
		onSuccess: data => {
			if (data?.project && project?.id !== data?.project?.id) {
				if (user?.projects?.some(p => p.id === data?.project?.id)) {
					if (pathname.includes('checklist/')) {
						dispatch(
							setEnvironmentProject({
								option: 'project',
								data: user.projects.find(p => p.id === data?.project?.id)!
							})
						)
					}
					data && openCard()
				} else {
					Modal.error({
						title: 'Ошибка доступа',
						content:
							'Внимание! Чек-лист не доступен по ссылке, поскольку у Вас не хватает прав. Обратитесь к Администратору',
						onOk: closeHandler,
						onCancel: closeHandler
					})
				}
			} else if (!prepare && data?.project === null) {
				Modal.error({
					title: 'Ошибка доступа',
					content:
						'Внимание! Чек-лист не доступен по ссылке, поскольку у Вас не хватает прав. Обратитесь к Администратору',
					onOk: closeHandler,
					onCancel: closeHandler
				})
			} else {
				data && openCard()
			}
		},
		onError: (err: AxiosError<IErrorDetail>) => {
			if (err.response?.status === 403) {
				Modal.error({
					title: 'Ошибка доступа',
					content:
						'Внимание! Чек-лист не доступен по ссылке, поскольку у Вас не хватает прав. Обратитесь к Администратору',
					onOk: closeHandler,
					onCancel: closeHandler
				})
			}
		},
		retry: (_, error) => (error.response?.status === 403 ? false : true)
	})
}

export const useChecklistContractors = (contractorId?: string) => {
	return useQuery({
		queryKey: ['checklist', 'contractors'],
		refetchOnWindowFocus: false,
		queryFn: async () => {
			return await checklistCardApi.getContractors()
		}
	})
}

export const useChecklistSubContractors = (
	contractorId?: string | string[],
	projectId?: string
) => {
	const project = useAppSelector(state => state.environment.project)
	const currentProjectId = projectId ?? project?.id
	return useQuery({
		queryKey: ['checklist', 'subContractors', contractorId],
		refetchOnWindowFocus: false,
		queryFn: async () => {
			return contractorId
				? await checklistCardApi.getSubContractors(contractorId, currentProjectId)
				: []
		}
	})
}

export const useChecklistCommissioning = (
	canEdit: boolean,
	contractorId?: string,
	projectId?: string
) => {
	const project = useAppSelector(state => state.environment.project)
	const currentProjectId = projectId ?? project?.id
	return useQuery({
		queryKey: ['checklist', 'commissioning', contractorId],
		refetchOnWindowFocus: false,
		queryFn: async () => {
			return canEdit && contractorId
				? await checklistCardApi.getCommissioning(contractorId, currentProjectId)
				: []
		}
	})
}

export const useChecklistContracts = (contractorId?: string, projectId?: string) => {
	return useQuery({
		queryKey: ['checklist', 'contracts', contractorId],
		refetchOnWindowFocus: false,
		queryFn: async () => {
			return contractorId && projectId
				? await checklistCardApi.getContracts(contractorId, projectId)
				: []
		}
	})
}

export const useChecklistResponsibleUsers = (
	templateType?: TEMPLATE_TYPE,
	name?: string,
	projectId?: string
) => {
	const project = useAppSelector(state => state.environment.project)

	return useQuery({
		queryKey: ['checklist', 'responsibleUsers', templateType],
		refetchOnWindowFocus: false,
		queryFn: async () => {
			return typeof templateType === 'number'
				? await checklistCardApi.getResponsibleUsers(templateType, name, projectId ?? project?.id)
				: []
		}
	})
}

export const useCreateAct = () => {
	const queryClient = useQueryClient()
	return useMutation({
		mutationKey: ['checklist', 'createAct'],
		mutationFn: async (checklistId: string) => {
			return checklistCardApi.createAct(checklistId)
		},
		onSuccess: () => {
			notification.success({
				message: 'Акт успешно сформирован',
				placement: 'topLeft'
			})
			queryClient.invalidateQueries({ queryKey: ['checklist', 'card'] })
		},
		onError: (error: AxiosError<IErrorDetail>) => {
			notification.error({
				message: `Во время формирования акта произошла ошибка. Код ошибки: ${error.response?.status}`,
				description: error.response?.data?.detail,
				placement: 'topLeft'
			})
		}
	})
}

export const useRecreateAct = () => {
	const queryClient = useQueryClient()
	return useMutation({
		mutationKey: ['checklist', 'recreateAct'],
		mutationFn: async (checklistId: string) => {
			return checklistCardApi.recreateAct(checklistId)
		},
		onSuccess: () => {
			notification.success({
				message: 'Акт успешно сформирован',
				placement: 'topLeft'
			})
			queryClient.invalidateQueries({ queryKey: ['checklist', 'card'] })
		},
		onError: (error: AxiosError<IErrorDetail>) => {
			notification.error({
				message: `Во время переформирования акта произошла ошибка. Код ошибки: ${error.response?.status}`,
				description: error.response?.data?.detail,
				placement: 'topLeft'
			})
		}
	})
}

export const useActSignMutate = () => {
	const queryClient = useQueryClient()
	return useMutation({
		mutationKey: ['checklist', 'signAct'],
		mutationFn: async ({
			checklistId,
			fileId,
			signDto
		}: {
			checklistId: string
			fileId: string
			signDto: ISignDto['base64File']
		}) => {
			return checklistCardApi.signAct(checklistId, fileId, signDto)
		},
		onSuccess: () => {
			notification.success({
				message: 'Акт передачи успешно подписан',
				placement: 'topLeft'
			})
			queryClient.invalidateQueries({ queryKey: ['checklist', 'card'] })
		},
		onError: (error: AxiosError<IErrorDetail>) => {
			notification.error({
				message: `Во время подписания акта произошла ошибка. Код ошибки: ${error.response?.status}`,
				description: error.response?.data?.detail,
				placement: 'topLeft'
			})
		}
	})
}

export const useSetAnswerMutate = () => {
	const queryClient = useQueryClient()
	return useMutation({
		mutationKey: ['checklist', 'answer'],
		mutationFn: async ({ itemId, answer }: { itemId: string; answer: number | null }) => {
			return checklistCardApi.setAnswer(itemId, answer)
		},
		onSuccess: () => {
			notification.success({
				message: 'Ответ успешно установлен',
				placement: 'topLeft'
			})
			queryClient.invalidateQueries({ queryKey: ['checklist', 'card'] })
			queryClient.invalidateQueries({ queryKey: ['checklist', 'registry'] })
		},
		onError: (error: AxiosError<IErrorDetail>) => {
			notification.error({
				message: 'Произошла ошибка',
				description: error.response?.data?.detail,
				placement: 'topLeft'
			})
		}
	})
}

export const useChecklistCreate = () => {
	const queryClient = useQueryClient()
	const unset = useChecklistCardState(state => state.unset)
	return useMutation({
		mutationKey: ['checklist', 'card'],
		mutationFn: async (dto: TChecklistCreateDto) => {
			if (dto) return checklistCardApi.createChecklist(dto)
			return null
		},
		onSuccess: () => {
			unset()
			notification.success({
				message: 'Чек-лист успешно создан',
				placement: 'topLeft'
			})
			queryClient.invalidateQueries({ queryKey: ['checklist', 'registry'] })
		},
		onError: (error: AxiosError<IErrorDetail>) => {
			notification.error({
				message: error.response?.data?.detail,
				description: `Во время создания чек-листа произошла ошибка. Код ошибки: ${error.response?.status}`,
				placement: 'topLeft'
			})
		}
	})
}

export const useChecklistMutation = () => {
	const queryClient = useQueryClient()
	return useMutation({
		mutationKey: ['checklist', 'card'],
		mutationFn: async ({ id, dto }: { id: string; dto: TChecklistUpdateDto }) => {
			if (id && dto) return checklistCardApi.updateChecklist(id, dto)
			return null
		},
		onSuccess: () => {
			notification.success({
				message: 'Чек-лист успешно обновлён',
				placement: 'topLeft'
			})
			queryClient.invalidateQueries({ queryKey: ['checklist', 'card'] })
			queryClient.invalidateQueries({ queryKey: ['checklist', 'registry'] })
		},
		onError: (error: AxiosError<IErrorDetail>) => {
			notification.error({
				message: error.response?.data?.detail,
				description: `Во время обновления чек-листа произошла ошибка. Код ошибки: ${error.response?.status}`,
				placement: 'topLeft'
			})
		}
	})
}

export const useChecklistCanceledMutation = () => {
	const queryClient = useQueryClient()
	return useMutation({
		mutationKey: ['checklist', 'canceled'],
		mutationFn: async (id: string) => {
			return checklistCardApi.setChecklistCancel(id)
		},
		onSuccess: () => {
			notification.success({
				message: 'Чек-лист успешно обновлён',
				placement: 'topLeft'
			})
			queryClient.invalidateQueries({ queryKey: ['checklist', 'card'] })
			queryClient.invalidateQueries({ queryKey: ['checklist', 'registry'] })
		},
		onError: (error: AxiosError<IErrorDetail>) => {
			notification.error({
				message: error.response?.data?.detail,
				description: `Во время обновления чек-листа произошла ошибка. Код ошибки: ${error.response?.status}`,
				placement: 'topLeft'
			})
		}
	})
}

export const useChecklistUnderWarrantyMutation = () => {
	const queryClient = useQueryClient()
	return useMutation({
		mutationKey: ['checklist', 'warranty'],
		mutationFn: async ({ id, data }: { id: string; data: FormData }) => {
			return checklistCardApi.setUnderWarranty(id, data)
		},
		onSuccess: () => {
			notification.success({
				message: 'Чек-лист успешно обновлён',
				placement: 'topLeft'
			})
			queryClient.invalidateQueries({ queryKey: ['checklist', 'card'] })
			queryClient.invalidateQueries({ queryKey: ['checklist', 'registry'] })
		},
		onError: (error: AxiosError<IErrorDetail>) => {
			notification.error({
				message: error.response?.data?.detail,
				description: `Во время обновления чек-листа произошла ошибка. Код ошибки: ${error.response?.status}`,
				placement: 'topLeft'
			})
		}
	})
}

export const useChecklistIssueAssignMutation = () => {
	const queryClient = useQueryClient()
	const unsetIssue = useIssueCardState(state => state.unsetIssue)
	return useMutation({
		mutationKey: ['checklist', 'assign'],
		mutationFn: async ({ itemId, issueId }: { itemId: string; issueId: string[] }) => {
			return checklistCardApi.assignIssue(itemId, issueId)
		},
		onSuccess: () => {
			notification.success({
				message: 'Замечание успешно добавлено',
				placement: 'topLeft'
			})
			unsetIssue()
			queryClient.invalidateQueries({ queryKey: ['checklist', 'card'] })
			queryClient.invalidateQueries({ queryKey: ['checklist', 'registry'] })
		},
		onError: (error: AxiosError<IErrorDetail>) => {
			notification.error({
				message: error.response?.data?.detail,
				description: `Во время добавления замечания в чек-лист произошла ошибка. Код ошибки: ${error.response?.status}`,
				placement: 'topLeft'
			})
		}
	})
}

export const useChecklistDocLinkMutation = () => {
	const queryClient = useQueryClient()
	return useMutation({
		mutationKey: ['checklist', 'docLink'],
		mutationFn: async ({ itemId, documentation }: { itemId: string; documentation: string }) => {
			return checklistCardApi.addDocumentationLink(itemId, documentation)
		},
		onSuccess: () => {
			notification.success({
				message: 'Ссылка на документацию успешно добавлена',
				placement: 'topLeft'
			})
			queryClient.invalidateQueries({ queryKey: ['checklist', 'card'] })
		},
		onError: (error: AxiosError<IErrorDetail>) => {
			notification.error({
				message: error.response?.data?.detail,
				description: `Во время добавления ссылки на документацию произошла ошибка. Код ошибки: ${error.response?.status}`,
				placement: 'topLeft'
			})
		}
	})
}

export const useChecklistAddFileMutation = () => {
	const queryClient = useQueryClient()
	return useMutation({
		mutationKey: ['checklist', 'files'],
		mutationFn: async ({ itemId, data }: { itemId: string; data: FormData }) => {
			return checklistCardApi.addFiles(itemId, data)
		},
		onSuccess: () => {
			notification.success({
				message: 'Файлы успешно добавлены',
				placement: 'topLeft'
			})
			queryClient.invalidateQueries({ queryKey: ['checklist', 'card'] })
		},
		onError: (error: AxiosError<IErrorDetail>) => {
			notification.error({
				message: error.response?.data?.detail,
				description: `Во время добавления файлов произошла ошибка. Код ошибки: ${error.response?.status}`,
				placement: 'topLeft'
			})
		}
	})
}

export const useChecklistRemoveFileMutation = () => {
	const queryClient = useQueryClient()
	return useMutation({
		mutationKey: ['checklist', 'files'],
		mutationFn: async (id: string) => {
			return deleteFile(id)
		},
		onSuccess: () => {
			notification.success({
				message: 'Файл успешно удалён',
				placement: 'topLeft'
			})
			queryClient.invalidateQueries({ queryKey: ['checklist', 'card'] })
		},
		onError: (error: AxiosError<IErrorDetail>) => {
			notification.error({
				message: error.response?.data?.detail,
				description: `Во время удаления файла произошла ошибка. Код ошибки: ${error.response?.status}`,
				placement: 'topLeft'
			})
		}
	})
}
