import { Button, Checkbox, Col, DatePicker, Form, Row, Select, Switch, Tag } from 'antd'
import locale from 'antd/es/date-picker/locale/ru_RU'
import { Tooltip } from 'antd/lib'
import { Dayjs } from 'dayjs'
import dayjs from 'dayjs'
import { FC, useEffect, useMemo, useRef, useState } from 'react'
import { useContractors, useProjects1C, useProjectsData } from '../model/diagrams-query'
import { useDiagramState } from '../model/diagrams-state'
import { IContractor, IProject, IProject1C } from '../model/diagrams-types'
import { PERMISSIONS } from 'shared/constants'
import { useCheckPermissions } from 'shared/useCheckPermissions'
import { useAppSelector } from 'hooks/appReduxHook'
import { CalendarOutlined, CloseOutlined, DownOutlined } from '@ant-design/icons'

interface DiagramsFilterProps {}
interface IDiagramFilters {
	range: [Dayjs, Dayjs]
	project1CIds: string[]
	projectIds: string[]
	contractorIds: string[]
	allPeriod?: boolean
}

export const DiagramsFilter: FC<DiagramsFilterProps> = ({}) => {
	const [filtersForm] = Form.useForm<IDiagramFilters>()
	const formValues = Form.useWatch(values => values, filtersForm)
	const notCanUse =
		formValues?.contractorIds === undefined ||
		formValues?.project1CIds === undefined ||
		formValues?.projectIds === undefined ||
		formValues?.contractorIds === null ||
		formValues?.project1CIds === null ||
		formValues?.projectIds === null ||
		formValues?.contractorIds?.length === 0 ||
		formValues?.project1CIds?.length === 0 ||
		formValues?.projectIds?.length === 0 ||
		((formValues?.range === null || formValues?.range === undefined) &&
			(formValues?.allPeriod === false || formValues?.allPeriod === undefined))
	const user = useAppSelector(state => state?.environment?.user)
	const permissions = user?.projects?.flatMap(p => p?.permissions)
	const { setFilters, project1CIds, projectIds, contractorIds, allPeriod, to, from } =
		useDiagramState()

	const issuePermission = !!permissions?.find((p: any) => p?.name === PERMISSIONS.IssueView)

	const [projectIdsBlur, setProjectIdsBlur] = useState<string[] | null>(null)
	const [project1CIdsBlur, setProject1CIdsBlur] = useState<string[] | null>(null)
	const [contractorIdsBlur, setContractorIdsBlur] = useState<string[] | null>(null)
	const { data: projects1CData, isLoading: isProjects1CLoading } = useProjects1C(
		issuePermission ? projectIdsBlur! : undefined,
		issuePermission ? contractorIdsBlur! : undefined,
		issuePermission
	)

	const { data: projectsData, isLoading: isProjectsLoading } = useProjectsData(
		issuePermission ? project1CIdsBlur! : undefined,
		issuePermission ? contractorIdsBlur! : undefined
	)

	const { data: contractorsData, isLoading: isContractorsLoading } = useContractors(
		issuePermission ? projectIdsBlur! : undefined,
		issuePermission ? project1CIdsBlur! : undefined,
		issuePermission
	)

	// const [status, setStatus] = useState<string | null>(null)
	// const [isFirstEntry, setIsFirstEntry] = useState<boolean>(true)

	const [project1C, setProject1C] = useState<string | null>(null)
	const [selectedProjects, setSelectedProjects] = useState<string[]>([])
	const [contractor, setContractor] = useState<string | null>(null)

	const [valueProject1C, setValueProject1C] = useState<string[]>([])
	const [valueProject, setValueProject] = useState<string[]>([])
	const [valueContractor, setValueContractor] = useState<string[]>([])
	const [open, setOpen] = useState({ project1C: false, project: false, contractor: false })
	const selectProject1CRef = useRef<HTMLDivElement>(null)
	const selectProjectRef = useRef<HTMLDivElement>(null)
	const selectContractorRef = useRef<HTMLDivElement>(null)

	const onSelect = (type: 'project1C' | 'project' | 'contractor', value: string) => {
		if (type === 'project1C') {
			setValueProject1C(prev => [...prev, value])
			setOpen(prev => ({ ...prev, project1C: true }))
		} else if (type === 'project') {
			setValueProject(prev => [...prev, value])
		} else if (type === 'contractor') {
			setValueContractor(prev => [...prev, value])
		}
	}

	const onDeselect = (type: 'project1C' | 'project' | 'contractor', value: string) => {
		if (type === 'project1C') {
			if (valueProject1C?.length > 1) {
				const values = valueProject1C.filter(v => v !== value)
				setValueProject1C(values)
				setOpen(prev => ({ ...prev, project1C: true }))
			}
		} else if (type === 'project') {
			if (valueProject?.length > 1) {
				const values = valueProject.filter(v => v !== value)
				setValueProject(values)
			}
		} else if (type === 'contractor') {
			if (valueContractor?.length > 1) {
				const values = valueContractor.filter(v => v !== value)
				setValueContractor(values)
			}
		}
	}

	const filteredProjects: IProject[] = useMemo(() => {
		let result = projectsData || []

		return result
	}, [projectsData, project1C, contractor])

	const filteredContractors: IContractor[] = useMemo(() => {
		let result: IProject1C[] = contractorsData || []

		return result
	}, [contractorsData, project1C, selectedProjects])

	const onConfirmFilters = async (filters: IDiagramFilters) => {
		if (filters) {
			setFilters({
				to:
					formValues?.allPeriod === true ||
					formValues.range === null ||
					formValues.range === undefined
						? null
						: dayjs(filters.range?.[1]).format('YYYY-MM-DD'),
				from:
					formValues?.allPeriod === true ||
					formValues.range === null ||
					formValues.range === undefined
						? null
						: dayjs(filters.range?.[0]).format('YYYY-MM-DD'),
				project1CIds:
					formValues.project1CIds?.length === 0 || formValues.project1CIds === undefined
						? null
						: filtersForm.getFieldValue('project1CIds') ?? filters?.project1CIds,
				projectIds:
					formValues.projectIds?.length === 0 || formValues.projectIds === undefined
						? null
						: formValues?.projectIds !== undefined && !!formValues?.projectIds?.length
						? formValues?.projectIds
						: !!filters?.projectIds?.length
						? filters?.projectIds
						: null,
				contractorIds:
					formValues.contractorIds?.length === 0 || formValues.contractorIds === undefined
						? null
						: filtersForm.getFieldValue('contractorIds') ?? filters?.contractorIds,
				allPeriod: filtersForm.getFieldValue('allPeriod') ?? filters.allPeriod
			})
		}
	}

	// useEffect(() => {
	// 	if (status === 'success' && isFirstEntry) {
	// 		setFilters({
	// 			project1CIds: filtersForm.getFieldValue('project1CIds'),
	// 			projectIds: filtersForm.getFieldValue('projectIds'),
	// 			contractorIds: filtersForm.getFieldValue('contractorIds')
	// 		})
	// 	}
	// }, [status])

	useEffect(() => {
		if (
			filtersForm.getFieldValue('projectIds')?.length! !== projectsData?.length! &&
			(project1CIdsBlur !== null || contractorIdsBlur !== null || projectIdsBlur !== null) &&
			issuePermission
		) {
			filtersForm.setFieldValue(
				'projectIds',
				projectIdsBlur === null
					? undefined
					: projectsData?.filter(elem => projectIdsBlur?.includes(elem.id))?.map(p => p.id)
			)
			setValueProject(
				projectIdsBlur === null
					? []
					: projectsData?.filter(elem => projectIdsBlur?.includes(elem.id))?.map(p => p.id)!
			)
			// setStatus(statusProject)
		}
	}, [projectsData])

	useEffect(() => {
		if (
			filtersForm.getFieldValue('contractorIds')?.length! !== contractorsData?.length! &&
			(project1CIdsBlur !== null || contractorIdsBlur !== null || projectIdsBlur !== null) &&
			issuePermission
		) {
			filtersForm.setFieldValue(
				'contractorIds',
				contractorIdsBlur === null
					? undefined
					: contractorsData?.filter(elem => contractorIdsBlur?.includes(elem.id))?.map(c => c.id)
			)
			setValueContractor(
				contractorIdsBlur === null
					? []
					: contractorsData?.filter(elem => contractorIdsBlur?.includes(elem.id))?.map(c => c.id)!
			)
			// setStatus(statusContractor)
		}
	}, [contractorsData])

	useEffect(() => {
		if (
			filtersForm.getFieldValue('project1CIds')?.length! !== projects1CData?.length! &&
			(project1CIdsBlur !== null || contractorIdsBlur !== null || projectIdsBlur !== null) &&
			issuePermission
		) {
			filtersForm.setFieldValue(
				'project1CIds',
				project1CIdsBlur === null
					? undefined
					: projects1CData?.filter(elem => project1CIdsBlur?.includes(elem.id))?.map(p => p.id)
			)
			setValueProject1C(
				project1CIdsBlur === null
					? []
					: projects1CData?.filter(elem => project1CIdsBlur?.includes(elem.id))?.map(p => p.id)!
			)
			// setStatus(statusProject1C)
		}
	}, [projects1CData])

	const onBlur = (type: 'project1CIds' | 'projectIds' | 'contractorIds') => {
		// setOpen({ project1C: false, project: false, contractor: false })
		// setIsFirstEntry(false)
		if (type === 'project1CIds') {
			setProject1CIdsBlur(filtersForm.getFieldValue('project1CIds'))
		}
		if (type === 'projectIds') {
			setProjectIdsBlur(filtersForm.getFieldValue('projectIds'))
		}
		if (type === 'contractorIds') {
			setContractorIdsBlur(filtersForm.getFieldValue('contractorIds'))
		}
	}

	useEffect(() => {
		if (projectIdsBlur === null && contractorIdsBlur === null && project1CIdsBlur === null) {
			filtersForm.setFieldsValue({
				range: to && from ? [dayjs(from, 'YYYY-MM-DD'), dayjs(to, 'YYYY-MM-DD')] : undefined,
				project1CIds: project1CIds!,
				projectIds: projectIds!,
				contractorIds: contractorIds!,
				allPeriod: allPeriod
			})
			setFilters({
				project1CIds: filtersForm.getFieldValue('project1CIds'),
				projectIds: filtersForm.getFieldValue('projectIds'),
				contractorIds: filtersForm.getFieldValue('contractorIds'),
				to: allPeriod === true ? null : to ?? undefined,
				from: allPeriod === true ? null : from ?? undefined
			})
			if (project1CIds?.length! >= 0 && projectIds?.length! >= 0 && contractorIds?.length! >= 0) {
				setProject1CIdsBlur(project1CIds!)
				setProjectIdsBlur(projectIds!)
				setContractorIdsBlur(contractorIds!)
			}
		}
	}, [isProjects1CLoading, isProjectsLoading, isContractorsLoading])

	return (
		<Form
			form={filtersForm}
			name="filtersForm"
			onFinish={() => onConfirmFilters(filtersForm.getFieldsValue())}
		>
			<Row justify="start" gutter={8} align="bottom">
				<Col flex={1}>
					<Row style={{ justifyContent: 'space-between' }}>
						<Col style={{ marginLeft: '10px', alignContent: 'center' }}>
							<Row style={{ height: '20px' }}>Период</Row>
						</Col>
						<Col>
							<Row style={{ height: '31px' }} justify="start">
								<Form.Item name="allPeriod" valuePropName="checked">
									<Switch
										size="small"
										checked={formValues?.allPeriod}
										className="switch-period-main"
										onChange={value => {
											filtersForm.setFieldValue('allPeriod', value)
										}}
									/>
									<span style={{ marginRight: '8px', marginLeft: '5px' }}>За всё время</span>
								</Form.Item>
							</Row>
						</Col>
					</Row>
					<Col>
						<Form.Item name={'range'}>
							<DatePicker.RangePicker
								locale={locale}
								format="DD.MM.YYYY"
								disabled={formValues?.allPeriod === true}
								style={{
									// minWidth: '404px',
									// maxWidth: '404px',
									width: '100%',
									borderRadius: '8px',
									boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
									height: '37px',
									backgroundColor: 'transparent',
									color: '#02152b',
									borderColor: '#AFB3BB'
								}}
							/>
						</Form.Item>
					</Col>
				</Col>
				{issuePermission && (
					<Col flex={1}>
						<Col>
							<Row style={{ marginLeft: '5px', marginBottom: '4px' }}>Проекты</Row>
						</Col>
						<Col>
							<Form.Item name={'project1CIds'}>
								<Select
									loading={isProjects1CLoading}
									placeholder="Проекты"
									showSearch={true}
									filterOption={(input, option) =>
										(option?.label ?? '').toLowerCase().includes(input.toLowerCase())
									}
									filterSort={(optionA, optionB) =>
										(optionA?.label ?? '')
											.toLowerCase()
											.localeCompare((optionB?.label ?? '').toLowerCase())
									}
									// allowClear
									mode="multiple"
									value={project1C}
									onChange={value => {
										setProject1C(value)
										setOpen(prev => ({ ...prev, project1C: true }))
									}}
									className="filter-main"
									style={{
										// minWidth: '404px',
										// maxWidth: '404px',
										width: '100%',
										borderRadius: '8px',
										boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
										height: '37px'
									}}
									tagRender={props => (
										<div style={{ display: 'flex', width: '100%', justifyContent: 'end' }}>
											{props.isMaxTag === false && (
												<Tag
													style={{
														flexGrow: 1,
														width: '100%',
														maxWidth: '140px',
														fontSize: 14,
														marginTop: '2px',
														marginRight: '4px',
														padding: '4px 8px',
														overflow: 'hidden',
														whiteSpace: 'nowrap',
														textOverflow: 'ellipsis',
														backgroundColor: 'transparent'
													}}
													bordered={false}
												>
													{props.label}
												</Tag>
											)}
										</div>
									)}
									// onClick={() => {
									// 	setOpen(prev => ({ ...prev, project1C: !prev.project1C }))
									// }}
									open={open.project1C}
									onSelect={value => onSelect('project1C', value)}
									onDeselect={value => onDeselect('project1C', value)}
									ref={selectProject1CRef as any}
									onClick={e => {
										e.stopPropagation()
										setOpen(prev => ({ ...prev, project1C: true }))
									}}
									suffixIcon={
										<>
											<div
												style={{
													alignItems: 'center',
													justifyContent: 'center',
													display: 'flex',
													fontFamily: 'Segoe UI',
													fontWeight: 400,
													fontSize: '12px',
													// marginLeft: 'auto',
													backgroundColor: '#02152b',
													borderRadius: '50%',
													padding: '2px',
													color: '#FFFFFF',
													height: '20px',
													width: '20px'
												}}
											>
												{valueProject1C?.length}
											</div>
											<DownOutlined
												onClick={(e: any) => {
													e.stopPropagation()
													setOpen(prev => ({
														project: false,
														contractor: false,
														project1C: !prev.project1C
													}))
													open.project1C === false && selectProject1CRef?.current?.focus()
												}}
											/>
											{valueProject1C?.length > 0 && (
												<CloseOutlined
													onClick={e => {
														e.stopPropagation()
														setValueProject1C([])
														filtersForm.setFieldValue('project1CIds', [])
														setProject1CIdsBlur(filtersForm.getFieldValue('project1CIds'))
													}}
												/>
											)}
										</>
									}
									onBlur={() => {
										onBlur('project1CIds')
										setOpen(prev => ({ ...prev, project1C: false }))
									}}
									dropdownRender={menu => (
										<>
											{menu}
											<Button
												type="link"
												onMouseDown={e => {
													e.preventDefault()
													setOpen(prev => ({ ...prev, project1C: true }))
												}}
												onClick={() => {
													if (
														filtersForm.getFieldValue('project1CIds')?.length! ===
														projects1CData?.length!
													) {
														filtersForm.setFieldValue('project1CIds', [])
														setValueProject1C([])
													} else {
														filtersForm.setFieldValue(
															'project1CIds',
															projects1CData?.map(pc => pc.id)
														)
														setValueProject1C(projects1CData?.map(pc => pc.id)!)
													}
												}}
											>
												{filtersForm.getFieldValue('project1CIds')?.length! ===
												projects1CData?.length!
													? 'Снять всё'
													: 'Выбрать всё'}
											</Button>
										</>
									)}
									options={projects1CData?.map(p => ({
										label: p.name,
										value: p.id
									}))}
									maxTagCount={1}
								/>
							</Form.Item>
						</Col>
					</Col>
				)}
				<Col flex={1}>
					<Col>
						<Row style={{ marginLeft: '5px', marginBottom: '4px' }}>Объекты строительства</Row>
					</Col>
					<Col>
						<Form.Item name={'projectIds'}>
							<Select
								loading={isProjectsLoading}
								mode="multiple"
								placeholder="Объекты строительства"
								showSearch={true}
								// allowClear
								filterOption={(input, option) =>
									(option?.label ?? '').toLowerCase().includes(input.toLowerCase())
								}
								filterSort={(optionA, optionB) =>
									(optionA?.label ?? '')
										.toLowerCase()
										.localeCompare((optionB?.label ?? '').toLowerCase())
								}
								value={selectedProjects}
								onChange={values => {
									setSelectedProjects(values)
									setOpen(prev => ({ ...prev, project: true }))
								}}
								className="filter-main"
								tagRender={props => (
									<div style={{ display: 'flex', justifyContent: 'space-between' }}>
										{props.isMaxTag === false && (
											<Tag
												style={{
													width: '100%',
													maxWidth: '140px',
													fontSize: 14,
													marginTop: '2px',
													marginRight: '4px',
													padding: '4px 8px',
													overflow: 'hidden',
													whiteSpace: 'nowrap',
													textOverflow: 'ellipsis',
													backgroundColor: 'transparent'
												}}
												bordered={false}
											>
												{props.label}
											</Tag>
										)}
									</div>
								)}
								style={{
									// minWidth: '404px',
									// maxWidth: '404px',
									width: '100%',
									boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
									height: '37px'
								}}
								open={open.project}
								onSelect={value => onSelect('project', value)}
								onDeselect={value => onDeselect('project', value)}
								ref={selectProjectRef as any}
								onClick={e => {
									e.stopPropagation()
									setOpen(prev => ({ ...prev, project: true }))
								}}
								suffixIcon={
									<>
										<div
											style={{
												alignItems: 'center',
												justifyContent: 'center',
												display: 'flex',
												fontFamily: 'Segoe UI',
												fontWeight: 400,
												fontSize: '12px',
												// marginLeft: 'auto',
												backgroundColor: '#02152b',
												borderRadius: '50%',
												padding: '2px',
												color: '#FFFFFF',
												height: '20px',
												width: '20px'
											}}
										>
											{valueProject?.length}
										</div>
										<DownOutlined
											onClick={(e: any) => {
												e.stopPropagation()
												setOpen(prev => ({
													project1C: false,
													contractor: false,
													project: !prev.project
												}))
												open.project === false && selectProjectRef.current?.focus()
											}}
										/>
										{valueProject?.length > 0 && (
											<CloseOutlined
												onClick={e => {
													e.stopPropagation()
													setValueProject([])
													filtersForm.setFieldValue('projectIds', [])
													setProjectIdsBlur(filtersForm.getFieldValue('projectIds'))
												}}
											/>
										)}
									</>
								}
								options={filteredProjects?.map(p => ({
									label: p.name,
									value: p.id
								}))}
								onBlur={() => {
									onBlur('projectIds')
									setOpen(prev => ({ ...prev, project: false }))
								}}
								dropdownRender={menu => (
									<>
										{menu}
										<Button
											type="link"
											onMouseDown={e => {
												e.preventDefault()
												setOpen(prev => ({ ...prev, project: true }))
											}}
											onClick={() => {
												if (
													filtersForm.getFieldValue('projectIds')?.length! === projectsData?.length!
												) {
													filtersForm.setFieldValue('projectIds', [])
													setValueProject([])
												} else {
													filtersForm.setFieldValue(
														'projectIds',
														projectsData?.map(pc => pc.id)
													)
													setValueProject(projectsData?.map(p => p.id)!)
												}
											}}
										>
											{filtersForm.getFieldValue('projectIds')?.length! === projectsData?.length!
												? 'Снять всё'
												: 'Выбрать всё'}
										</Button>
									</>
								)}
								maxTagCount={1}
							/>
						</Form.Item>
					</Col>
				</Col>
				{issuePermission && (
					<Col flex={1}>
						<Col>
							<Row style={{ marginLeft: '5px', marginBottom: '4px' }}>Контрагенты</Row>
						</Col>
						<Col>
							<Form.Item name={'contractorIds'}>
								<Select
									loading={isContractorsLoading}
									placeholder="Контрагенты"
									showSearch={true}
									filterOption={(input, option) =>
										(option?.label ?? '').toLowerCase().includes(input.toLowerCase())
									}
									filterSort={(optionA, optionB) =>
										(optionA?.label ?? '')
											.toLowerCase()
											.localeCompare((optionB?.label ?? '').toLowerCase())
									}
									// allowClear
									mode="multiple"
									value={contractor}
									onChange={value => {
										setContractor(value)
										setOpen(prev => ({ ...prev, contractor: true }))
									}}
									className="filter-main"
									tagRender={props => (
										<div style={{ display: 'flex', justifyContent: 'space-between' }}>
											{props.isMaxTag === false && (
												<div>
													<Tag
														style={{
															width: '100%',
															maxWidth: '140px',
															fontSize: 14,
															padding: '4px 8px',
															marginTop: '2px',
															// marginRight: '4px',
															overflow: 'hidden',
															whiteSpace: 'nowrap',
															textOverflow: 'ellipsis',
															fontWeight: 400,
															fontFamily: 'Segoe UI',
															backgroundColor: 'transparent'
														}}
														bordered={false}
													>
														{props.label}
													</Tag>
												</div>
											)}
										</div>
									)}
									style={{
										// minWidth: '404px',
										// maxWidth: '404px',
										width: '100%',
										borderRadius: '8px',
										boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
										height: '37px'
									}}
									open={open.contractor}
									onSelect={value => onSelect('contractor', value)}
									onDeselect={value => onDeselect('contractor', value)}
									ref={selectContractorRef as any}
									onClick={e => {
										e.stopPropagation()
										setOpen(prev => ({ ...prev, contractor: true }))
									}}
									suffixIcon={
										<>
											<div
												style={{
													alignItems: 'center',
													justifyContent: 'center',
													display: 'flex',
													fontFamily: 'Segoe UI',
													fontWeight: 400,
													fontSize: '12px',
													// marginLeft: 'auto',
													backgroundColor: '#02152b',
													borderRadius: '50%',
													padding: '2px',
													color: '#FFFFFF',
													height: '20px',
													width: '20px'
												}}
											>
												{valueContractor?.length}
											</div>
											<DownOutlined
												onClick={(e: any) => {
													e.stopPropagation()
													setOpen(prev => ({
														project: false,
														project1C: false,
														contractor: !prev.contractor
													}))
													open.contractor === false && selectContractorRef.current?.focus()
												}}
											/>
											{valueContractor?.length > 0 && (
												<CloseOutlined
													onClick={e => {
														e.stopPropagation()
														setValueContractor([])
														filtersForm.setFieldValue('contractorIds', [])
														setContractorIdsBlur(filtersForm.getFieldValue('contractorIds'))
													}}
												/>
											)}
										</>
									}
									onBlur={() => {
										onBlur('contractorIds')
										setOpen(prev => ({ ...prev, contractor: false }))
									}}
									dropdownRender={menu => (
										<>
											{menu}
											<Button
												type="link"
												onMouseDown={e => {
													e.preventDefault()
													setOpen(prev => ({ ...prev, contractor: true }))
												}}
												onClick={() => {
													if (
														filtersForm.getFieldValue('contractorIds')?.length! ===
														contractorsData?.length!
													) {
														filtersForm.setFieldValue('contractorIds', [])
														setValueContractor([])
													} else {
														filtersForm.setFieldValue(
															'contractorIds',
															contractorsData?.map(c => c.id)
														)
														setValueContractor(contractorsData?.map(c => c.id)!)
													}
												}}
											>
												{filtersForm.getFieldValue('contractorIds')?.length! ===
												contractorsData?.length!
													? 'Снять всё'
													: 'Выбрать всё'}
											</Button>
										</>
									)}
									options={filteredContractors?.map(c => ({
										label: c.name,
										value: c.id
									}))}
									maxTagCount={1}
								/>
							</Form.Item>
						</Col>
					</Col>
				)}
				<Col>
					{/* <Tooltip
						title={notCanUse && issuePermission ? 'Для применения заполните все фильтры' : ''}
					> */}
					<Button
						type="primary"
						onClick={() => filtersForm.submit()}
						// disabled={notCanUse && issuePermission}
						style={{
							marginBottom: '24px',
							backgroundColor: '#02152b',
							borderColor: '#2C303D',
							color: '#FFFFFF',
							fontSize: 14,
							fontFamily: 'Segoe UI',
							fontWeight: 'normal',
							width: '109px',
							height: '36px'
						}}
					>
						Применить
					</Button>
					{/* </Tooltip> */}
				</Col>
				<Col>
					<Button
						type="primary"
						onClick={() => {
							filtersForm.resetFields()
							setProjectIdsBlur([])
							setProject1CIdsBlur([])
							setContractorIdsBlur([])
							setFilters({
								project1CIds: null,
								projectIds: null,
								contractorIds: null,
								to: null,
								from: null
							})
						}}
						style={{
							marginBottom: '24px',
							backgroundColor: 'transparent',
							borderColor: '#2C303D',
							color: '#02152b',
							fontSize: 14,
							fontFamily: 'Segoe UI',
							fontWeight: 'normal',
							width: '97px',
							height: '36px'
						}}
					>
						Сбросить
					</Button>
				</Col>
			</Row>
		</Form>
	)
}
