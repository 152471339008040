import { ColumnsType } from 'antd/es/table'
import { Table } from 'antd/lib'
import { Badge, Col, Empty, Row, Tooltip } from 'antd'
import { useCountIssues, useIssueSubTypesDashboard } from '../model/diagrams-query'
import { Chart as ChartJS, Legend, Title } from 'chart.js'
import 'chart.js/auto'

ChartJS.register(Legend, Title)

export const IssueTypeTableDashboard = () => {
	const { data: issueTypeData } = useIssueSubTypesDashboard()
	const { data: issue } = useCountIssues()

	const labels = ['Устранено', 'Не устранено']
	const values = [issue?.closeIssues ?? 0, issue?.openIssues ?? 0]
	const customLabels = labels.map((label, idx) => `${label}`)

	const columns: ColumnsType<any> = [
		{
			dataIndex: 'number',
			title: '',
			width: 38,
			render: (_, record) => record.code
		},
		{
			dataIndex: 'lable',
			title: '',
			width: 250
		},
		{
			dataIndex: 'fix',
			// title: 'Устранено',
			render: (_, record) =>
				record.issueFix > 0 ? (
					<span style={{ color: '#1677FF', fontSize: 14, fontWeight: 400, fontFamily: 'Segoe UI' }}>
						{record.issueFix}{' '}
					</span>
				) : (
					''
				),
			width: 46,
			align: 'center'
		},
		{
			dataIndex: 'nofix',
			// title: 'Не устранено',
			render: (_, record) =>
				record.issueNoFix > 0 ? (
					<span style={{ color: '#FF4D4F', fontSize: 14, fontWeight: 400, fontFamily: 'Segoe UI' }}>
						{record.issueNoFix}{' '}
					</span>
				) : (
					''
				),
			width: 46,
			align: 'center'
		},
		{
			dataIndex: 'sum',
			title: 'Сумма',
			width: 60,
			align: 'center',
			render: (_, record) => (
				<div
					className="issueTypeSum"
					style={{
						fontSize: 14,
						fontWeight: 400,
						fontFamily: 'Segoe UI'
					}}
				>
					{record.issueFix + record.issueNoFix}
				</div>
			)
		},
		{
			dataIndex: 'diagram',
			render: (_, record) => {
				const fix = record.issueFix || 0
				const noFix = record.issueNoFix || 0
				const total = fix + noFix

				return (
					<div style={{ display: 'flex', width: '100%', paddingLeft: '10px' }}>
						{total > 0 && (
							<Tooltip
								overlay={
									<p>
										{record.lable}
										<br />
										<Row align="middle">
											<div
												style={{
													width: '10px',
													height: '10px',
													backgroundColor: '#1677FF',
													marginRight: '5px'
												}}
											/>
											Устранено: {fix}
										</Row>
										<Row align="middle">
											<div
												style={{
													width: '10px',
													height: '10px',
													backgroundColor: '#FF4D4F',
													marginRight: '5px'
												}}
											/>
											Не устранено: {noFix}
										</Row>
									</p>
								}
							>
								<div
									style={{
										display: 'flex',
										width: '100%',
										height: '20px',
										borderRadius: '3px',
										overflow: 'hidden',
										paddingBottom: '0px'
									}}
								>
									{fix > 0 && (
										<div
											style={{
												width: `${(fix / total) * 100}%`,
												backgroundColor: '#1677FF',
												height: '20px',
												paddingBottom: '0px'
											}}
										/>
									)}
									{noFix > 0 && (
										<div
											style={{
												width: `${(noFix / total) * 100}%`,
												backgroundColor: '#FF4D4F',
												height: '20px',
												paddingBottom: '0px'
											}}
										/>
									)}
								</div>
							</Tooltip>
						)}
					</div>
				)
			}
		}
	]
	const hasData =
		issueTypeData &&
		issueTypeData.length > 0 &&
		issueTypeData.some(item => item.issueFix > 0 || item.issueNoFix > 0)

	return (
		<Row
			// gutter={12}
			style={{
				border: '1px solid #ddd',
				borderRadius: 8,
				padding: 20,
				background: 'white',
				height: '570px',
				position: 'relative'
			}}
		>
			<Col
				span={24}
				style={{
					// padding: '1rem',
					background: '#fefefe'
				}}
			>
				<div
					style={{
						fontFamily: 'Segoe UI',
						fontWeight: 'normal',
						fontSize: '22px',
						color: '#000000',
						paddingBottom: '20px'
					}}
				>
					Типы замечаний
				</div>
				{hasData ? (
					<Table
						rowKey={(record, index) => index!}
						pagination={false}
						className="main-issue-type-table"
						rowClassName="main-issue-type-table-row"
						columns={columns}
						dataSource={issueTypeData ?? []}
						size="small"
						bordered
						showHeader={false}
						scroll={{
							x: 500
							// y: 400
						}}
					/>
				) : (
					<div
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							height: '430px',
							marginTop: '-90px'
						}}
					>
						<Empty />
					</div>
				)}
				<Col span={24} style={{ position: 'absolute', top: '95%' }}>
					<Row justify="start">
						{issue?.totalIssues! > 0 && (
							<div style={{ display: 'flex' }}>
								{customLabels.map((label, index) => (
									<div
										key={index}
										style={{ display: 'flex', alignItems: 'center', margin: '0 10px 0 0' }}
									>
										<div
											style={{
												width: '12px',
												height: '12px',
												backgroundColor: index === 0 ? '#1677FF' : '#FF4D4F',
												marginRight: '5px'
											}}
										/>
										<span
											style={{
												fontFamily: 'Segoe UI',
												fontWeight: 'normal',
												fontSize: '14px',
												color: '#2C303D'
											}}
										>
											{label}
										</span>
									</div>
								))}
							</div>
						)}
					</Row>
				</Col>
			</Col>
		</Row>
	)
}
