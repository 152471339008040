import { useQuery } from '@tanstack/react-query'
import {
	Button,
	Checkbox,
	Col,
	DatePicker,
	Form,
	Modal,
	Row,
	Select,
	Typography,
	message
} from 'antd'
import type { Dayjs } from 'dayjs'
import dayjs from 'dayjs'
import { useAppDispatch, useAppSelector } from 'hooks/appReduxHook'
import { FC, useEffect, useState } from 'react'
import { reportService } from 'services/ReportService'
import { exportService } from 'services/exportService'
import { setHocReport } from 'store/lightReportSlice'
interface IProps {
	show: boolean
	download?: boolean
	onClose: () => void
}

interface IHOCForm {
	projects: string[]
	contractor: string
	range: [Dayjs, Dayjs] | undefined
	allPeriod: boolean | undefined
}
interface IHOCdto {
	projectIds: string[]
	contractorId: string
	from: string | null
	to: string | null
}

const { Title, Text } = Typography

export const HOCReportCreateModal: FC<IProps> = ({ show, download, onClose }) => {
	const dispatch = useAppDispatch()
	const [isBlocked, setIsBlocked] = useState(false)
	const [HOCForm] = Form.useForm<IHOCForm>()
	const allPeriod = Form.useWatch('allPeriod', HOCForm)
	const selectedProjects = Form.useWatch('projects', HOCForm)
	const projects = useAppSelector(state => state.environment.user?.projects)
	const project = useAppSelector(state => state.environment.project)
	const { data: contractors } = useQuery({
		queryKey: ['reportContractors', selectedProjects],
		queryFn: async () => {
			return selectedProjects && selectedProjects?.length > 0
				? await reportService.getContractors(selectedProjects)
				: []
		}
	})

	const onModalClose = () => {
		setIsBlocked(false)
		HOCForm.resetFields()
		HOCForm.setFieldsValue({ allPeriod: true, range: undefined })
		onClose()
	}

	useEffect(() => {
		HOCForm.validateFields()
	}, [allPeriod])

	useEffect(() => {
		if(project) {
			HOCForm.setFieldsValue({ allPeriod: true, range: undefined, projects: [project?.id] })
		}
	}, [show])
	const onGetHOCReport = async () => {
		setIsBlocked(true)
		const data = HOCForm.getFieldsValue()
		const dto: IHOCdto = {
			projectIds: data.projects,
			contractorId: data.contractor,
			from: data.allPeriod ? null : dayjs(data.range?.[0]).format('YYYY-MM-DD'),
			to: data.allPeriod ? null : dayjs(data.range?.[1]).format('YYYY-MM-DD')
		}
		download
			? await exportService
					.exportHOCToExcel(dto)
					.then(response => {
						const href = URL.createObjectURL(response.data)
						const link = document.createElement('a')
						link.href = href
						link.setAttribute('download', 'Отчёт РСО')
						document.body.appendChild(link)
						link.click()
						document.body.removeChild(link)
						URL.revokeObjectURL(href)
						onModalClose()
					})
					.catch(() => message.error('Во время загрузки файла произошла ошибка'))
			: await reportService.getHocReport(dto).then(response => {
					dispatch(setHocReport(response))
					onModalClose()
			  })
	}

	const setAllProjects = () => {
		HOCForm.setFieldValue(
			'projects',
			projects?.map(p => p.id)
		)
		HOCForm.validateFields()
	}

	return (
		<Form form={HOCForm} name="HOCForm" onFinish={onGetHOCReport} disabled={isBlocked}>
			<Modal
				destroyOnClose
				onCancel={onModalClose}
				centered
				open={show}
				title="Создать отчёт"
				okButtonProps={{ disabled: isBlocked }}
				footer={
					<Row justify="space-between">
						<Col>
							<Button danger disabled={isBlocked} onClick={() => HOCForm.resetFields()}>
								Сбросить
							</Button>
						</Col>
						<Col>
							<Button disabled={isBlocked} onClick={onModalClose}>
								Отмена
							</Button>
							,
							<Button disabled={isBlocked} type="primary" onClick={() => HOCForm.submit()}>
								{download ? 'Скачать' : 'Сформировать'}
							</Button>
						</Col>
					</Row>
				}
			>
				<Row justify="center">
					<Col
						style={{
							display: 'flex',
							flexDirection: 'column',
							gap: '.5rem',
							padding: '1rem',
							width: '100%'
						}}
					>
						<>
							<Col>
								<Row align="middle" justify="space-between">
									<Col>
										<Title level={5}>Проекты:</Title>
									</Col>
									<Col>
										<Button type="link" size="small" onClick={setAllProjects}>
											Выбрать все
										</Button>
									</Col>
								</Row>
								<Form.Item
									name="projects"
									rules={[
										{
											required: true,
											message: 'Выберете хотя бы один проект'
										}
									]}
								>
									<Select
										mode="multiple"
										maxTagCount="responsive"
										allowClear
										style={{ width: '100%' }}
										onClear={() => HOCForm.setFieldValue('contractor', undefined)}
										optionFilterProp="children"
										filterOption={(input, option) =>
											(option?.label ?? '').toLowerCase().includes(input.toLowerCase())
										}
										filterSort={(optionA, optionB) =>
											(optionA?.label ?? '')
												.toLowerCase()
												.localeCompare((optionB?.label ?? '').toLowerCase())
										}
										options={projects?.map(p => ({
											key: p.id,
											label: p.name,
											value: p.id
										}))}
									/>
								</Form.Item>
							</Col>
							<Col>
								<Title level={5}>Контрагент:</Title>
								<Form.Item name="contractor">
									<Select
										disabled={contractors?.length === 0 || selectedProjects?.length === 0}
										allowClear
										showSearch
										optionFilterProp="children"
										filterOption={(input, option) =>
											(option?.label ?? '').toLowerCase().includes(input.toLowerCase())
										}
										filterSort={(optionA, optionB) =>
											(optionA?.label ?? '')
												.toLowerCase()
												.localeCompare((optionB?.label ?? '').toLowerCase())
										}
										style={{ width: '100%' }}
										options={contractors?.map(p => ({
											key: p.id,
											label: p.name,
											value: p.id
										}))}
									/>
								</Form.Item>
							</Col>
							<Col>
								<Title level={5}>Период:</Title>
								<Form.Item
									name="range"
									rules={[
										{
											required: !allPeriod,
											message: 'Укажите период'
										}
									]}
								>
									<DatePicker.RangePicker
										disabled={allPeriod}
										format="DD.MM.YYYY"
										style={{ width: '100%' }}
									/>
								</Form.Item>
							</Col>
							<Row justify="end">
								<Text style={{ marginRight: '1rem' }}>За всё время</Text>
								<Form.Item name="allPeriod">
									<Checkbox
										checked={allPeriod}
										onChange={value => HOCForm.setFieldValue('allPeriod', value.target.checked)}
									/>
								</Form.Item>
							</Row>
						</>
					</Col>
				</Row>
			</Modal>
		</Form>
	)
}
