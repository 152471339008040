import { Button, Col, DatePicker, Drawer, Form, List, Row, Select, Typography } from 'antd'
import locale from 'antd/es/date-picker/locale/ru_RU'
import 'dayjs/locale/ru'
import { useAppDispatch, useAppSelector } from 'hooks/appReduxHook'
import { useResponsibleUsers } from 'hooks/useResponsibleUsers'
import { IAuthors, IAuthorsData, IChecklistFilters } from 'interfaces/IChecklist'
import { FC, useEffect, useState } from 'react'
import { getChecklistsOwners } from 'services/ChecklistsService'
import { getChecklistTemplatesOwners } from 'services/TemplatesService'
import { setCurrentChecklistFilters } from 'store/checklistsSlice'
import {
	useChecklistContractors,
	useChecklistSubContractors
} from 'widgets/checklist-card/api/checklist-card-queries'
import { useDiagramsProjectState } from 'widgets/diagrams-project/model/diagrams-project-state'

interface IFilterItem {
	title: string
	formItemName: string
	list: any[] | null | undefined
	date: boolean
}

interface IChecklistFiltersProps {
	open: boolean
	parentPage: 'checklists' | 'templates' | 'plannedDates'
	viewerStatus?: number | null
	onClose: () => void
	onFilters: (filters: IChecklistFilters, show: boolean) => void
}

const { Option } = Select
const { Text } = Typography

const ChecklistFilters: FC<IChecklistFiltersProps> = ({
	open,
	parentPage,
	viewerStatus,
	onClose,
	onFilters
}) => {
	const [isLoading, setIsLoading] = useState(false)
	const dispatch = useAppDispatch()
	const [filtersForm] = Form.useForm()
	const [checklistsOwners, setChecklistsOwners] = useState<IAuthorsData[]>([])
	const [checklistTemplatesOwners, setChecklistTemplatesOwners] = useState<IAuthors[]>([])
	const project = useAppSelector(state => state.environment.project?.id!)
	const initialStatuses = useAppSelector(state => state.checklists.init.initialStatuses)
	const templateType = Form.useWatch('templateType', filtersForm)
	const contractorForm = Form.useWatch('contractorIds', filtersForm)
	const { data: responsibleUsers, isSuccess } = useResponsibleUsers(templateType)
	const { data: contractors } = useChecklistContractors()
	const { data: subContractors } = useChecklistSubContractors(contractorForm)
	const { filters } = useAppSelector(state => state.checklists)
	const workPackages = useAppSelector(state => state.environment.workPackages)
	const { authors } = useDiagramsProjectState()
	const checklistFilters = useAppSelector(state => state.checklists.filters)
	const filterList: IFilterItem[] = [
		{
			title: 'Тип',
			formItemName: 'templateType',
			list: ['УКС НДК', 'УК КС'],
			date: false
		},
		{
			title: 'Статус',
			formItemName: 'status',
			list: initialStatuses,
			date: false
		},
		{
			title: 'Владелец',
			formItemName: 'ownerIds',
			list: parentPage === 'checklists' ? checklistsOwners : checklistTemplatesOwners,
			date: false
		},
		{
			title: 'Дата создания',
			formItemName: 'createdAt',
			list: null,
			date: true
		},
		{
			title: 'Наличие замечания',
			formItemName: 'checkIssue',
			list: ['Без замечаний', 'С замечаниями'],
			date: false
		},
		{
			title: 'Ответственный',
			formItemName: 'assignedTo',
			list: isSuccess ? responsibleUsers : null,
			date: false
		},
		{
			title: 'Плановая дата проверки',
			formItemName: 'plannedDate',
			list: null,
			date: true
		},
		{
			title: 'Фактическая дата проверки',
			formItemName: 'factDate',
			list: null,
			date: true
		},
		{
			title: 'Активный',
			formItemName: 'isActive',
			list: ['Не активные', 'Активные'],
			date: false
		},
		{
			title: 'Mobile',
			formItemName: 'isMobile',
			list: ['Нет', 'Да'],
			date: false
		},
		{
			title: 'Пакет работ',
			formItemName: 'workPackageId',
			list: workPackages,
			date: false
		},
		{
			title: 'Генподрядчики',
			formItemName: 'contractorIds',
			list: contractors,
			date: false
		},
		{
			title: 'Субподрядчики',
			formItemName: 'subContractorIds',
			list: subContractors,
			date: false
		}
	]

	const [filterItems, setFilterItems] = useState<IFilterItem[]>(filterList)

	useEffect(() => {
		if (
			!!authors &&
			!!checklistsOwners &&
			parentPage === 'checklists' &&
			!!checklistFilters?.ownerIds
		) {
			const ownerIds = checklistsOwners?.filter(o => authors.includes(o.id))?.map(o => o.id)
			const filters = { ...checklistFilters, ownerIds: ownerIds as any }
			dispatch(setCurrentChecklistFilters({ filters }))
			filtersForm.setFieldsValue(filters)
		}
	}, [authors, checklistsOwners])

	useEffect(() => {
		filtersForm.setFieldsValue(filters)
	}, [])

	useEffect(() => {
		getOwners(parentPage!)
	}, [parentPage, project])

	const getOwners = async (parentPage: string) => {
		setIsLoading(true)
		if (parentPage === 'checklists') {
			await getChecklistsOwners()
				.then(res => setChecklistsOwners(res.data))
				.finally(() => setTimeout(() => setIsLoading(false), 200))
		} else {
			await getChecklistTemplatesOwners()
				.then(res => setChecklistTemplatesOwners(res.data.data))
				.finally(() => setTimeout(() => setIsLoading(false), 200))
		}
	}

	useEffect(() => {
		setFilterItems(filterList)
	}, [
		project,
		checklistsOwners,
		checklistTemplatesOwners,
		templateType,
		isSuccess,
		workPackages,
		contractors,
		subContractors
	])

	const onResetFilters = () => {
		filtersForm.resetFields()
		filtersForm.submit()
		// parentPage === 'checklists' ? dispatch(resetCheckListsState()) : dispatch(resetTemplatesState())
		// onClose()
	}

	const onChangeFilter = (field: string, value: any) => {
		if (field === 'status' && !!value.length === false) {
			filtersForm.resetFields(['status'])
		}
		if (field === 'createdAt' && value === null) {
			filtersForm.resetFields(['createdAt'])
		}
		if (field === 'factDate' && value === null) {
			filtersForm.resetFields(['factDate'])
		}
		if (field === 'plannedDate' && value === null) {
			filtersForm.resetFields(['plannedDate'])
		}
		if (field === 'templateType') {
			filtersForm.resetFields(['assignedTo'])
		}
	}

	const onConfirmFilters = () => {
		onFilters(filtersForm.getFieldsValue(), true)
	}

	return (
		<Drawer
			open={open}
			onClose={onClose}
			placement="left"
			title="Фильтры"
			className="at-drawer-left"
			footer={
				<Row gutter={8} justify="end">
					<Col>
						<Button ghost danger onClick={onResetFilters}>
							Сбросить
						</Button>
					</Col>
					<Col>
						<Button type="primary" htmlType="submit" onClick={() => filtersForm.submit()}>
							Применить
						</Button>
					</Col>
				</Row>
			}
		>
			<Form name="filtersForm" form={filtersForm} onFinish={onConfirmFilters}>
				<List
					loading={isLoading}
					dataSource={filterItems.filter(item =>
						parentPage === 'templates'
							? item.formItemName !== 'status' &&
							  item.formItemName !== 'checkIssue' &&
							  item.formItemName !== 'assignedTo' &&
							  item.formItemName !== 'plannedDate' &&
							  item.formItemName !== 'factDate' &&
							  item.formItemName !== 'isMobile' &&
							  item.formItemName !== 'workPackageId'
							: item.title !== 'Активный'
					)}
					renderItem={(item, index) => (
						<Row align="middle" gutter={8}>
							<Col style={{ flex: '0 0 100%' }}>
								<Text strong>{item.title}:</Text>
							</Col>
							<Col style={{ flex: '1 0 100%' }}>
								<Form.Item
									key={index}
									name={item.formItemName}
									initialValue={
										item.formItemName === 'templateType' &&
										typeof viewerStatus !== 'undefined' &&
										(viewerStatus === 0 || viewerStatus === 1)
											? viewerStatus
											: undefined
									}
								>
									{!item.date ? (
										<Select
											loading={isLoading}
											mode={
												item.formItemName === 'status' ||
												item.formItemName === 'ownerIds' ||
												item.formItemName === 'contractorIds' ||
												item.formItemName === 'subContractorIds'
													? 'multiple'
													: undefined
											}
											showSearch
											allowClear
											optionFilterProp="children"
											filterSort={(optionA, optionB) =>
												(optionA?.label ?? '')
													.toString()
													.toLowerCase()
													.localeCompare((optionB?.label ?? '').toString().toLowerCase())
											}
											style={{ width: '100%' }}
											placeholder="Не выбран"
											onChange={value => onChangeFilter(item.formItemName, value)}
											disabled={
												(typeof templateType === 'undefined' &&
													item.formItemName === 'assignedTo') ||
												(item.formItemName === 'templateType' &&
													typeof viewerStatus !== 'undefined' &&
													(viewerStatus === 0 || viewerStatus === 1)) ||
												((typeof contractorForm === 'undefined' || subContractors?.length === 0) &&
													item.formItemName === 'subContractorIds')
											}
										>
											{item.formItemName === 'isMobile'
												? item.list! &&
												  item.list!?.map((option, index) => (
														<Option
															key={option.id ? option.id : index}
															value={option === 'Да' ? true : false}
														>
															{option}
														</Option>
												  ))
												: item.formItemName === 'checkIssue'
												? item.list! &&
												  item.list!?.map((option, index) => (
														<Option
															key={option.id ? option.id : index}
															value={option === 'С замечаниями' ? true : false}
														>
															{option}
														</Option>
												  ))
												: item.list! &&
												  item.list!?.map((option, index) => (
														<Option
															key={option.id ? option.id : index}
															value={option.id ? option.id : index}
														>
															{option.name || option.fullName
																? option.name || option.fullName
																: option}
														</Option>
												  ))}
										</Select>
									) : (
										<DatePicker
											locale={locale}
											style={{ width: '100%' }}
											format={'DD.MM.YYYY'}
											onChange={date => onChangeFilter(item.formItemName, date!)}
										/>
									)}
								</Form.Item>
							</Col>
						</Row>
					)}
				/>
			</Form>
		</Drawer>
	)
}
export default ChecklistFilters
