import { Steps } from 'antd'
import { useWbsStatuses } from 'pages/guidesPage/ui/wbs-status/useWbsStatuses'
import { memo, useMemo } from 'react'
import { useWbsStatusModalState } from 'widgets/wbs'
import { useAdjustmentState } from '../model/adjustment-state'

interface IProps {
	type?: 'wbs' | 'local'
}
export const AdjustmentStatusLine = memo(function (params: IProps) {
	const { type } = params
	const { data: statuses } = useWbsStatuses(type === 'wbs' ? false : true, true)
	const { container } = useAdjustmentState()
	const { setContainer } = useWbsStatusModalState()
	const currentStatus = useMemo(
		() => statuses?.findIndex(s => s.name === container?.status?.name) ?? 0,
		[container]
	)
	return (
		<div
			onClick={() => container && !container.isLocalEstimate && setContainer(container)}
			style={{ cursor: 'pointer' }}
		>
			<Steps
				style={{ overflow: 'hidden' }}
				className="app-steps"
				size="small"
				// type="navigation"
				current={currentStatus >= 0 ? currentStatus : 0}
				items={
					statuses
						?.sort((a, b) => a.order - b.order)
						?.map(status => ({
							title: status.description,
							className: 'step-status',
							style: { display: 'flex', alignItems: 'center' }
						})) ?? []
				}
			/>
		</div>
	)
})
