import { Button } from 'antd'
import { FC, useState } from 'react'
import { HOCReportCreateModal } from './hoc-report-create-modal'
import { DatabaseOutlined, DownloadOutlined } from '@ant-design/icons'
import { useAppSelector } from 'hooks/appReduxHook'

interface IProps {
	download?: boolean
}

export const HOCReportCreate: FC<IProps> = ({ download = false }) => {
	const [show, setShow] = useState(false)
	const currentProject = useAppSelector(state => state.environment.project)
	return (
		<>
			<HOCReportCreateModal show={show} onClose={() => setShow(false)} download={download} />
			<Button
				type={download ? 'default' : 'primary'}
				icon={download ? <DownloadOutlined /> : <DatabaseOutlined />}
				onClick={() => setShow(true)}
				// disabled={download}
			>
				{download ? 'Скачать отчёт' : 'Создать отчёт'}
			</Button>
		</>
	)
}
