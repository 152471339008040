import { AxiosError } from 'axios'
import { message } from 'antd'
import { setEnvironmentProject, setEnvironmentPermissions, setEnvironment } from 'store/environmentSlice'

import { getContractors, getIssueTypes, getIssueSubTypes, getContractsByProject1CId } from 'services/IssuesService'
import { getAppUserPermissions } from 'services/AuthService'
import { IErrorData } from 'UI/dashboard/EnvironmentConfig'
import { resetIssuesState } from 'store/issueSlice'
import { resetOrdinancesState } from 'store/ordinanceSlice'
import { resetCheckListsState } from 'store/checklistsSlice'
import { resetTemplatesState } from 'store/templatesSlice'
import { resetAsm } from 'store/asmSlice'
import { resetTrafficLight } from 'store/trafficLightSlice'
import { getBuildingPermitsList } from 'services/OrdinanceService'
import { QueryClient } from '@tanstack/react-query'
import { Dispatch } from '@reduxjs/toolkit'


export const changeCurrentProject = async (projectId: string, user: any, dispatch: Dispatch, queryClient: QueryClient) => {
  const userProject = user?.projects?.find((p: any) => p.id === projectId)
  dispatch(resetIssuesState())
  dispatch(resetOrdinancesState())
  dispatch(resetCheckListsState())
  dispatch(resetTemplatesState())
  dispatch(resetAsm())
  dispatch(resetTrafficLight())

  await getAppUserPermissions(userProject?.id ?? '').then(data => {
    dispatch(setEnvironmentPermissions(data))
  })

  dispatch(
    setEnvironmentProject({
      option: 'project',
      data: userProject!
    })
  )

  await getContractors().then(data => {
    dispatch(setEnvironment({ state: 'contractors', data }))
  })
  await getIssueTypes()
    .then(data => {
      dispatch(setEnvironment({ state: 'issueTypes', data }))
    })
    .catch((error: AxiosError) => {
      const { detail } = error!?.response!?.data! as IErrorData
      const { url } = error!?.config!
      message.error(`Ошибка при получении данных ${url}: ${detail}`, 5)
      return Promise.reject
    })

  await getIssueSubTypes()
    .then(data => {
      dispatch(setEnvironment({ state: 'issueSubTypes', data }))
    })
    .catch((error: AxiosError) => {
      const { detail } = error!?.response!?.data! as IErrorData
      const { url } = error!?.config!
      message.error(`Ошибка при получении данных ${url}: ${detail}`, 5)
      return Promise.reject
    })

  await getContractsByProject1CId(userProject?.project1C?.id!).then(data => {
    dispatch(setEnvironment({ state: 'contracts', data }))
  })

  await getBuildingPermitsList(userProject?.project1C?.id!).then(data => {
    dispatch(setEnvironment({ state: 'buildingPermits', data }))
  })

  queryClient.invalidateQueries({ queryKey: ['issueAnalytics'] })
  queryClient.invalidateQueries({ queryKey: ['ordinanceAnalytics'] })
  queryClient.invalidateQueries({ queryKey: ['violationTypesAnalytics'] })
  queryClient.invalidateQueries({ queryKey: ['useMyFilesAnalytics'] })
}