import { useQueryClient } from '@tanstack/react-query'
import {
	Checkbox,
	Col,
	Collapse,
	Form,
	Input,
	message,
	Modal,
	Select,
	TreeSelect,
	Typography
} from 'antd'
import { AxiosError } from 'axios'
import { useAppSelector } from 'hooks/appReduxHook'
import { IBaseConstructionObject, IErrorDetail } from 'interfaces/IBase'
import { IProject } from 'interfaces/IEnvironment'
import { FC, Key, useEffect, useState } from 'react'
import { getProjectList } from 'services/EnvironmentService'
import { IWbsCopyForm, useWbsRegistryState, WbsRegistryApi } from '..'
import { flattenTree, markDisabledNodes } from '../model/wbs-helper'
import { useConstructionObjects } from '../model/wbs-queries'

const { Title, Text } = Typography

interface IProps {}

export const WbsCopy: FC<IProps> = props => {
	const [hasError, setHasError] = useState(false)
	const [projects, setProjects] = useState<IProject[]>([])
	const [valueNoteDp, setValueNoteDp] = useState<boolean>(false)
	const [wbsCopyContainerForm] = Form.useForm<IWbsCopyForm>()
	const { containerToCopy, setContainerToCopy, onCopy } = useWbsRegistryState()
	const queryClient = useQueryClient()
	const [showModal, setShowModal] = useState(false)
	const { data: constructionObjects } = useConstructionObjects()
	const transformedData = markDisabledNodes(constructionObjects || [])

	// TODO Вынести отдельно для TreeSelect
	const [expandedKeys, setExpandedKeys] = useState<Key[]>(
		flattenTree(transformedData!)?.map((elem: any) => elem.id)
	)
	const [searchValue, setSearchValue] = useState()

	useEffect(() => {
		setExpandedKeys(flattenTree(transformedData!)?.map((elem: any) => elem.id))
	}, [searchValue, constructionObjects])

	const onSearch = (e: any) => {
		setSearchValue(e.target?.value)
		if (!e.target?.value) {
			setExpandedKeys(flattenTree(transformedData!)?.map((elem: any) => elem.id))
		}
	}
	const onExpand = (newKeys: Key[]) => {
		setExpandedKeys(newKeys)
	}
	//

	useEffect(() => {
		if (containerToCopy) {
			wbsCopyContainerForm.setFieldValue('name', `${containerToCopy.name}_копия`)
			wbsCopyContainerForm.setFieldValue('copyNoteDP', valueNoteDp)
			wbsCopyContainerForm.setFieldValue(
				'constructionObjectId',
				containerToCopy.constructionObject?.id
			)
			getProjectList(null, '49').then(data => {
				setProjects(data)
				if (data.some(d => d.id === containerToCopy?.project?.id)) {
					wbsCopyContainerForm.setFieldValue('destinationProjectId', containerToCopy?.project?.id)
					wbsCopyContainerForm.validateFields()
				}
			})
		}
	}, [containerToCopy])
	const handleFormChange = () => {
		setHasError(
			wbsCopyContainerForm.getFieldsError().filter(({ errors }) => errors.length).length > 0
		)
	}
	const onCopyHandler = async (formData: IWbsCopyForm) => {
		if (showModal) return
		if (containerToCopy) {
			const newFormData = {
				...formData,
				copyNoteDP: wbsCopyContainerForm.getFieldValue('copyNoteDP')
			}
			setShowModal(true)
			const containerCopy = async () => {
				await onCopy(containerToCopy.id, newFormData)
					.then(() => {
						queryClient.invalidateQueries(['wbsRegistry'])
						setContainerToCopy(undefined)
						message.success('ВОР успешно скопирован')
						setShowModal(false)
					})
					.catch((err: AxiosError<IErrorDetail>) => {
						Modal.error({
							zIndex: 999999,
							title: 'Ошибка копирования',
							content: err.response?.data.detail,
							centered: true,
							onOk: () => {
								setShowModal(false)
								setContainerToCopy(undefined)
								queryClient.invalidateQueries(['wbsRegistry'])
							}
						})
					})
			}
			await WbsRegistryApi.checkArchiveContainer(containerToCopy.id).then(data => {
				if (!!data?.length!) {
					Modal.confirm({
						zIndex: 999999,
						content: (
							<div>
								В новый ВОР не будут скопированы архивные разделы:
								<br />
								{data
									?.sort((a, b) =>
										a.codifier!?.localeCompare(b.codifier!, undefined, {
											numeric: true,
											sensitivity: 'base'
										})
									)
									?.map(elem => (
										<span>
											{elem.codifier[elem.codifier.length - 1] === '.'
												? elem.codifier.slice(0, -1)
												: elem.codifier}
											- {elem.name} <br />
										</span>
									))}
							</div>
						),
						centered: true,
						okText: 'Копировать',
						onCancel: () => {
							setShowModal(false)
							setContainerToCopy(undefined)
						},
						onOk: () => {
							containerCopy()
						}
					})
				} else {
					containerCopy()
				}
			})
		}
	}
	const findObject = (
		items: IBaseConstructionObject[] | undefined,
		id: string
	): IBaseConstructionObject | undefined => {
		for (const obj of items!) {
			if (obj.id === id) return obj
			if (obj.children?.length > 0) {
				const found = findObject(obj.children, id)
				if (found) return found
			}
		}
	}
	return (
		<Modal
			destroyOnClose={true}
			title={
				<Title type="success" level={5}>
					Копирование ВОР
				</Title>
			}
			centered
			open={!!containerToCopy}
			zIndex={1001}
			okText={'Копировать'}
			cancelText="Отмена"
			okButtonProps={{
				disabled: hasError
			}}
			onOk={async () => wbsCopyContainerForm.submit()}
			onCancel={() => setContainerToCopy(undefined)}
		>
			<Form
				form={wbsCopyContainerForm}
				name="wbsCopyContainerForm"
				layout="vertical"
				onFinish={onCopyHandler}
				onFieldsChange={handleFormChange}
			>
				<Form.Item
					shouldUpdate
					name="name"
					label="Введите наименование ВОР"
					rules={[
						{
							required: true,
							message: 'Введите наименование ВОР'
						},
						{ min: 3, message: 'Не менее 3х символов' }
					]}
				>
					<Input />
				</Form.Item>
				<Form.Item name="destinationProjectId" label="Выберите проект:">
					<Select
						showSearch
						placeholder="Не выбран"
						optionFilterProp="children"
						filterOption={(input, option) =>
							(option?.label ?? '').toLowerCase().includes(input.toLowerCase())
						}
						filterSort={(optionA, optionB) =>
							(optionA?.label ?? '')
								.toLowerCase()
								.localeCompare((optionB?.label ?? '').toLowerCase())
						}
						style={{ width: '100%' }}
						options={projects.map((item, index) => ({
							key: index,
							value: item.id,
							label: item.name
						}))}
					/>
				</Form.Item>
				<Form.Item
					name="constructionObjectId"
					label="Выберите объект строительства:"
					// rules={[
					// 	{
					// 		required: true,
					// 		message: 'Выберите объект строительства.'
					// 	},
					// 	() => ({
					// 		validator(_, value) {
					// 			const selectedObject = findObject(constructionObjects, value)
					// 			if (selectedObject && !selectedObject.isAccessible) {
					// 				return Promise.reject(
					// 					new Error(
					// 						'Объект строительства недоступен для выбора. Укажите другое значение.'
					// 					)
					// 				)
					// 			}
					// 			return Promise.resolve()
					// 		}
					// 	})
					// ]}
				>
					<TreeSelect
						fieldNames={{ label: 'name', value: 'id', children: 'children' }}
						treeData={transformedData}
						showSearch
						placeholder="Выберите объект строительства"
						allowClear
						treeDefaultExpandAll
						filterTreeNode={(input, treeNode) => {
							return treeNode.name?.toLowerCase()?.includes(input.toLowerCase())
						}}
						onSearch={onSearch}
						treeExpandedKeys={expandedKeys}
						onTreeExpand={onExpand}
						treeTitleRender={(node: any) => {
							if (!flattenTree(transformedData)?.find((t: any) => t.id === node.value)) {
								return node.name ?? containerToCopy?.constructionObject?.name
							} else {
								return node.name
							}
						}}
					/>
				</Form.Item>
				<Collapse
					items={[
						{
							key: 'false',
							label: 'Дополнительные параметры',
							children: (
								<>
									<Col>
										<Text type="secondary">Параметры позиции сметы</Text>
									</Col>
									<Form.Item
										name="copyNoteDP"
										valuePropName="checked"
										wrapperCol={{ offset: 1 }}
										style={{ margin: 0 }}
									>
										<Col style={{ marginTop: 5 }}>
											<Checkbox
												// defaultChecked={false}
												value={false}
												onChange={value => {
													wbsCopyContainerForm.setFieldValue('copyNoteDP', value.target.checked)
													setValueNoteDp(value.target.checked)
												}}
											>
												Примечания УПВОР
											</Checkbox>
										</Col>
									</Form.Item>
								</>
							)
						}
					]}
					bordered={false}
				/>
			</Form>
		</Modal>
	)
}
