import { CloseOutlined, PlusOutlined, SwapOutlined } from '@ant-design/icons'
import {
	Button,
	Card,
	Form,
	Input,
	Row,
	Col,
	InputNumber,
	Modal,
	Tree,
	TreeDataNode,
	Tooltip
} from 'antd'
import { FormInstance } from 'antd/es/form'
import { FC, useEffect, useState } from 'react'
import { Section } from './ChecklistTemplateSection'
import { TreeProps } from 'antd/lib'

interface IStageProps {
	form: FormInstance
	canEdit: boolean
}

export const Stage: FC<IStageProps> = ({ form, canEdit }) => {
	const checkListTemplateStages = Form.useWatch('checkListTemplateStages', form)
	const [isTreeModalVisible, setIsTreeModalVisible] = useState(false)
	const [stagesBeforeDrag, setStagesBeforeDrag] = useState<TreeDataNode[]>([])
	const [treeData, setTreeData] = useState<TreeDataNode[]>([])
	const { DirectoryTree } = Tree

	const handleTreeView = () => {
		setStagesBeforeDrag(treeData)
		setIsTreeModalVisible(true)
	}

	const handleTreeSubmit = () => {
		const newStages = treeData?.map((stageNode: TreeDataNode | any, stageIndex: number) => ({
			...stageNode.data,
			title: stageNode.title,
			order: stageIndex,
			sections: stageNode.children?.map((sectionNode: any, sectionIndex: number) => ({
				...sectionNode.data,
				title: sectionNode.title,
				order: sectionIndex,
				items: sectionNode.children?.map((itemNode: any, itemIndex: number) => ({
					...itemNode.data,
					title: itemNode.title,
					order: itemIndex
				}))
			}))
		}))

		form.setFieldsValue({ checkListTemplateStages: newStages })
		setIsTreeModalVisible(false)
	}

	const handleTreeCancel = () => {
		setTreeData(stagesBeforeDrag)
		setIsTreeModalVisible(false)
	}

	useEffect(() => {
		setTreeData(getTreeData())
	}, [checkListTemplateStages])

	const getTreeData = () => {
		return checkListTemplateStages?.map((stage: any, stageIndex: number) => ({
			key: `stage-${stageIndex}`,
			title: stage?.title || `Этап ${stageIndex + 1}`,
			data: stage,
			children: (stage?.sections || [])?.map((section: any, sectionIndex: number) => ({
				key: `stage-${stageIndex}-section-${sectionIndex}`,
				title: section?.title || `Раздел ${sectionIndex + 1}`,
				data: section,
				children: (section?.items || []).map((item: any, itemIndex: number) => ({
					key: `stage-${stageIndex}-section-${sectionIndex}-item-${itemIndex}`,
					title: item?.name || `Пункт ${itemIndex + 1}`,
					data: item
				}))
			}))
		}))
	}

	const getNodeType = (key: string): 'stage' | 'section' | 'item' | null => {
		const keyParts = key.split('-')
		if (keyParts.length === 2 && keyParts[0] === 'stage') {
			return 'stage'
		} else if (keyParts.length === 4 && keyParts[2] === 'section') {
			return 'section'
		} else if (keyParts.length === 6 && keyParts[4] === 'item') {
			return 'item'
		} else {
			return null
		}
	}

	const allowDrop: TreeProps['allowDrop'] = info => {
		const { dragNode, dropNode, dropPosition } = info
		const dragType = getNodeType(dragNode.key.toString())
		const dropType = getNodeType(dropNode.key.toString())

		if (dragType === 'item') {
			if (dropType === 'section') {
				return dropPosition === 0
			} else if (dropType === 'item') {
				return dropPosition !== 0
			} else {
				return false
			}
		} else if (dragType === 'section') {
			if (dropType === 'stage') {
				return dropPosition === 0
			} else if (dropType === 'section') {
				return dropPosition !== 0
			} else {
				return false
			}
		} else if (dragType === 'stage') {
			if (dropType === 'stage') {
				return dropPosition !== 0
			} else {
				return false
			}
		} else {
			return false
		}
	}

	const onTreeDrop: TreeProps['onDrop'] = info => {
		const dropKey = info.node.key
		const dragKey = info.dragNode.key
		const dropPos = info.node.pos.split('-')
		const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1])

		const data = [...treeData]
		let dragObj: TreeDataNode

		const removeNode = (
			data: TreeDataNode[],
			key: React.Key,
			callback: (
				node: TreeDataNode,
				index: number,
				arr: TreeDataNode[],
				parent: TreeDataNode | null
			) => void,
			parent: TreeDataNode | null = null
		) => {
			for (let i = 0; i < data.length; i++) {
				if (data[i].key === key) {
					return callback(data[i], i, data, parent)
				}
				if (data[i].children) {
					removeNode(data[i].children!, key, callback, data[i])
				}
			}
		}

		removeNode(data, dragKey, (item, index, arr) => {
			arr.splice(index, 1)
			dragObj = item
		})

		if (!dragObj!) {
			return
		}

		const dragType = getNodeType(dragKey.toString())
		const dropType = getNodeType(dropKey.toString())

		if (!info.dropToGap) {
			if (
				(dragType === 'item' && dropType === 'section') ||
				(dragType === 'section' && dropType === 'stage')
			) {
				const addNode = (data: TreeDataNode[], key: React.Key) => {
					for (let i = 0; i < data.length; i++) {
						if (data[i]?.key === key) {
							data[i].children = data[i].children || []
							data[i].children?.unshift(dragObj)
							return
						}
						if (data[i]?.children) {
							addNode(data[i].children!, key)
						}
					}
				}
				addNode(data, dropKey)
			}
		} else {
			const addNode = (
				data: TreeDataNode[],
				key: React.Key,
				node: TreeDataNode,
				dropPosition: number
			) => {
				for (let i = 0; i < data.length; i++) {
					if (data[i].key === key) {
						const parentArray = data
						let insertIndex = i
						if (dropPosition === 1) {
							insertIndex = i + 1
						}
						parentArray.splice(insertIndex, 0, node)
						return
					}
					if (data[i].children) {
						addNode(data[i].children!, key, node, dropPosition)
					}
				}
			}
			if (
				(dragType === 'item' && dropType === 'item') ||
				(dragType === 'section' && dropType === 'section') ||
				(dragType === 'stage' && dropType === 'stage')
			) {
				addNode(data, dropKey, dragObj, dropPosition)
			}
		}
		setTreeData(data)
	}

	const validateTotalPercent = () => {
		const totalPercent = checkListTemplateStages?.reduce(
			(sum: number, stage: any) => sum + stage?.percent,
			0
		)
		if (totalPercent > 100) {
			return 'Сумма процентов по всем этапам не должна превышать 100%'
		}
		return null
	}
	const percentError = validateTotalPercent()

	const canMove = () => {
		if (checkListTemplateStages?.length > 1) {
			return true
		}

		for (const stage of checkListTemplateStages || []) {
			if (stage?.sections && stage?.sections?.length > 1) {
				return true
			}
			for (const section of stage?.sections || []) {
				if (section?.items && section?.items?.length > 1) {
					return true
				}
			}
		}

		return false
	}

	return (
		<Form.List name={['checkListTemplateStages']}>
			{(fields, { add, remove }) => (
				<>
					{canEdit && (
						<Tooltip
							title={
								canMove() ? '' : 'Добавьте как минимум два элемента, чтобы их можно было перемещать'
							}
						>
							<Button
								type="primary"
								onClick={handleTreeView}
								block
								style={{ marginBottom: 10 }}
								disabled={!canMove()}
								icon={<SwapOutlined />}
							>
								Переместить элементы
							</Button>
						</Tooltip>
					)}

					<Modal
						open={isTreeModalVisible}
						onCancel={handleTreeCancel}
						onOk={handleTreeSubmit}
						title="Перемещение элементов"
						width={600}
					>
						<DirectoryTree
							treeData={treeData}
							draggable
							allowDrop={allowDrop}
							onDrop={onTreeDrop}
							blockNode
							defaultExpandAll
						/>
					</Modal>

					{fields.map((field, index) => (
						<Card
							key={field.key}
							className="app-work-card"
							style={{ position: 'relative', marginBottom: 16, padding: '16px' }}
						>
							{canEdit && (
								<CloseOutlined
									onClick={() => remove(field.name)}
									style={{
										fontSize: '16px',
										padding: '.5rem',
										fontWeight: 'bold',
										position: 'absolute',
										top: '-18px',
										right: '25px',
										cursor: 'pointer',
										zIndex: 1,
										background: '#f2f2f2',
										border: '1px solid #ddd',
										borderRadius: '50px'
									}}
								/>
							)}
							<Row gutter={16}>
								<Col span={24}>
									<Form.Item
										className="app-form-item"
										labelCol={{ span: 6 }}
										labelAlign="left"
										name={[field.name, 'title']}
										label={`Этап № ${index + 1}`}
										// rules={[
										//   { required: true, message: 'Укажите название этапа' },
										// ]}
									>
										<Input placeholder="Название этапа" disabled={!canEdit} />
									</Form.Item>
								</Col>
								<Col span={24}>
									<Form.Item
										className="app-form-item"
										labelCol={{ span: 6 }}
										labelAlign="left"
										name={[field.name, 'percent']}
										label="Оплата этапа"
										validateStatus={percentError ? 'error' : ''}
										help={percentError}
										rules={[
											{ required: true, message: 'Укажите оплату этапа' },
											{
												type: 'number',
												max: 100,
												message: 'Оплата этапа не может превышать 100%'
											}
										]}
									>
										<InputNumber
											placeholder="Проценты оплаты"
											min={0}
											max={100}
											step={0.01}
											style={{ width: '100%' }}
											disabled={!canEdit}
											formatter={value => `${value}%`}
											parser={(value: string | undefined) =>
												parseFloat(value?.replace('%', '') || '0')
											}
											controls={false}
										/>
									</Form.Item>
								</Col>
							</Row>
							<Section form={form} canEdit={canEdit} stageField={[field.name]} />
						</Card>
					))}

					{canEdit && (
						<Button type="primary" onClick={() => add()} block icon={<PlusOutlined />}>
							Добавить этап
						</Button>
					)}
				</>
			)}
		</Form.List>
	)
}
