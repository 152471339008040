import { arrayMove } from '@dnd-kit/sortable'
import { message } from 'antd'
import { IColumns } from 'components/columnConfig'
import { ITechnology } from 'interfaces/ITechnology'
import lodash, { findLastIndex } from 'lodash'
import { findNestedObj } from 'shared/helpers'
import { create } from 'zustand'
import { WbsContainerApi } from '../api/wbsContainerApi'
import {
	IEstimatePositionItemDto,
	IEstimatePositionOrder,
	INonActualSection,
	ISectionTechnologies,
	IWbsContainer,
	IWbsContainerSection,
	IWbsContainerSectionDto,
	IWbsContainerSectionItem,
	IWbsPermissions
} from './wbsContainerInterfaces'

interface IWbsContainerState {
	isActual?: boolean
	nonActualPositions: INonActualSection[]
	actualizeModal: boolean
	showHistory: boolean
	hasError: 'delete' | 'project' | null
	wbsPermissions: IWbsPermissions
	container: IWbsContainer | null
	sections: IWbsContainerSection[] | null
	sectionBlock?: string
	technologies: ISectionTechnologies[]
	showRemoveModal: boolean
	toRemove: string[]
	removeSectionId: string | null
	selectedEstimatesKeys: string[]
	expandedRowKeys: string[]
	expandedSectionsWithEstimatePositions: string[]
	collapsedSections: string[]
	scrollTo: string | null
	showContainerCommentsDrawer: boolean
	currentContainerId: string | undefined
	columnsWbs: IColumns[]
	showColumnsWbs: IColumns[]
	setShowColumnsWbs: (showColumnsWbs: IColumns[]) => void
	setColumnsWbs: (columnsWbs: IColumns[]) => void
	setCurrentContainerId: (currentContainerId: string | undefined) => void
	updateSection: (sectionId?: string) => void
	setDefaultExpanded: () => void
	setSelectedEstimatesKeys: (keys: string[]) => void
	setExpandedRowKeys: (keys: string[]) => void
	setExpandedSectionsWithEstimatePositions: (keys: string[]) => void
	setCollapsedSections: (keys: string[]) => void
	setTechnologies: (sectionId: string, data: ITechnology[]) => void
	setSectionBlocks: (id: string) => void
	setContainer: (container: IWbsContainer) => void
	setSections: (sections: IWbsContainerSectionDto[]) => void
	setSectionItems: (sectionId: string, sectionItems: IEstimatePositionItemDto[]) => void
	changeContainerReadyState: (state: boolean) => Promise<any>
	changePosition: (sectionId: string, ids: unknown[], direction: 'up' | 'down', type: 'wbs' | 'local') => void
	setScrollTo: (id: string | null) => void
	getCommentStatus: (isClosed: boolean, hasComments?: boolean) => string
	unsetContainer: () => void
	setHasError: (hasError: 'delete' | 'project' | null) => void
}

const initState = {
	isActual: undefined,
	nonActualPositions: [],
	actualizeModal: false,
	showHistory: false,
	hasError: null,
	container: null,
	sections: null,
	technologies: [],
	showRemoveModal: false,
	showStatusModal: false,
	toRemove: [],
	removeSectionId: null,
	selectedEstimatesKeys: [],
	expandedRowKeys: [],
	expandedSectionsWithEstimatePositions: [],
	collapsedSections: [],
	scrollTo: null,
	showContainerCommentsDrawer: false,
	currentContainerId: undefined,
	columnsWbs: [],
	showColumnsWbs: []
}

export const useWbsContainerState = create<IWbsContainerState>((set, get) => ({
	...initState,
	wbsPermissions: {
		admin: false,
		edit: false,
		delete: false,
		setDP: false,
		setSDU: false,
		setDZ: false,
		setDZSmr: false,
		outer: false,
		changeReady: false,
		exportWbsToExcel: false,
		importWbsFromExcel: false
	},
	setCurrentContainerId: currentContainerId => set(() => ({ currentContainerId })),
	setColumnsWbs: columnsWbs => set(() => ({ columnsWbs })),
	setShowColumnsWbs: showColumnsWbs => set(() => ({ showColumnsWbs })),
	setContainer: container => set(() => ({ container })),
	setHasError: hasError => set(() => ({ hasError })),
	setSections: dto => {
		const currentSections = get().sections

		const dataMap = (section: IWbsContainerSectionDto): IWbsContainerSection => {
			if (section.children && typeof section.children !== 'undefined') {
				return {
					...section,
					totalSumMaterials: section.workTotal?.totalSumMaterials ?? 0,
					totalSumService: section.workTotal?.totalSumService ?? 0,
					totalSum: section.workTotal?.totalSum ?? 0,
					children: section.children.map(c => dataMap(c)),
					isBox: false,
					hasPositions: section.hasPositions,
					hasTechnologies: section.hasTechnologies
				}
			} else {
				if (currentSections && !!currentSections.length) {
					return {
						id: section.id,
						sourceId: section.sourceId,
						name: section.name,
						codifier: section.codifier,
						totalSumMaterials: section.workTotal?.totalSumMaterials ?? 0,
						totalSumService: section.workTotal?.totalSumService ?? 0,
						totalSum: section.workTotal?.totalSum ?? 0,
						isBox: true,
						hasPositions: section.hasPositions,
						hasTechnologies: section.hasTechnologies,
						children: findNestedObj<IWbsContainerSection>(currentSections, 'id', section.id)
							?.children
					}
				} else {
					return {
						id: section.id,
						sourceId: section.sourceId,
						name: section.name,
						codifier: section.codifier,
						totalSumMaterials: section.workTotal?.totalSumMaterials ?? 0,
						totalSumService: section.workTotal?.totalSumService ?? 0,
						totalSum: section.workTotal?.totalSum ?? 0,
						isBox: true,
						hasPositions: section.hasPositions,
						hasTechnologies: section.hasTechnologies,
						children: []
					}
				}
			}
		}
		const data = dto.map(section => dataMap(section))
		set(() => ({ sections: data }))
	},
	setSectionBlocks: id => set(() => ({ sectionBlock: id })),
	setSectionItems: (sectionId, sectionItems) => {
		const sections = get().sections
		const items: IWbsContainerSectionItem[] =
			sectionItems?.flatMap(elem => {
				return {
					level: 1,
					id: elem.id,
					sectionId,
					name: elem.name,
					order: elem.order,
					technologyId: elem.technologyId,
					version: elem.version,
					isCopied: elem.isCopied,
					properties: elem.properties?.map(property => ({
						id: property.id,
						identityName: property.identityName,
						name: property.name,
						propertyId: property.propertyId,
						value: property.value,
						valueType: property.valueType
					})),
					children: elem.works.map(work => ({
						level: 2,
						version: elem.version,
						id: work.id,
						isCopied: work.isCopied,
						isExpandable: elem.technology.expandable,
						isFixedPriceMaterial: elem.technology.isFixedPriceMaterial,
						isActualRelationship: work.isActualRelationship,
						estimatePositionId: elem.id,
						sectionId,
						sectionCodifier: '',
						hasComments: elem.hasComments,
						isCommentsClosed: elem.isCommentsClosed,
						technologyId: elem.technologyId,
						name: work.name,
						rate: work.norma,
						measureUnit: work.measureUnit !== null ? work.measureUnit.name : '',
						measureUnitId: work.measureUnit?.id,
						amount: work.amount,
						amountWithRate: work.amountWithRate,
						order: elem.order,
						workId: work.workId,
						formula: work.formula,
						noteDP: work.noteDP,
						noteDZ: work.noteDZ,
						noteSDU: work.noteSDU,
						priceMaterial: work.priceMaterial,
						priceService: work.priceService,
						totalPrice: work.totalPrice,
						totalSum: work.totalSum,
						totalSumMaterials: work.totalSumMaterials,
						totalSumService: work.totalSumService,
						children: work.materials
							.sort((a, b) => a.order - b.order)
							.map(material => ({
								level: 3,
								version: elem.version,
								isExpandable: elem.technology.expandable,
								id: material.id,
								isCopied: material.isCopied,
								estimatePositionId: elem.id,
								technologyId: elem.technologyId,
								sectionId,
								name: material.materialName,
								rate: material.norma,
								measureUnit: material.measureUnit !== null ? material.measureUnit.name : '',
								materialComment: material.materialComment,
								formula: material.formula,
								amount: material.amount,
								amountWithRate: material.amountWithRate,
								key: material.key,
								materialId: material.materialId,
								measureUnitId: material.measureUnit?.id,
								order: material.order,
								typeMaterialId: material?.typeMaterial?.id ?? null,
								noteDP: material.noteDP,
								noteDZ: material.noteDZ,
								noteSDU: material.noteSDU,
								priceMaterial: material.priceMaterial,
								totalSumMaterials: material.totalSumMaterials,
								isNominated: material.isNominated,
								workId: work.id
							}))
					}))
				}
			}) ?? []

		const updSections = lodash.cloneDeepWith(sections, (value: IWbsContainerSection) => {
			return value && value.id === sectionId
				? {
						...value,
						children: items
							.sort((a, b) => a.order - b.order)
							.map(item => ({
								...item,
								children: item.children.map(w => ({ ...w, sectionCodifier: value.codifier }))
							}))
				  }
				: lodash.noop()
		})

		set(() => ({ sections: updSections }))
	},
	setDefaultExpanded: () => {
		const sectionId = get().sectionBlock
		const currentSection = get().sections
		const section = findNestedObj<IWbsContainerSection>(currentSection!, 'id', sectionId)
			?.children as IWbsContainerSectionItem[]
		if (sectionId && currentSection && section) {
			const workKeys = section
				.flatMap(i => i.children)
				.filter(c => !!c.children.length)
				.map(x => x.id)
			const currentExpandedKeys = get().expandedRowKeys
			let updateExpandedKeys = get().expandedRowKeys
			workKeys.forEach(w => {
				if (!currentExpandedKeys.includes(w)) updateExpandedKeys.push(w)
			})
			set(() => ({ expandedRowKeys: updateExpandedKeys }))
		}
	},
	changeContainerReadyState: async state => {
		const container = get().container
		if (container) {
			return await WbsContainerApi.setContainerReadyState(container?.id, state).catch(err => {
				message.error('Произошла ошибка! Обратитесь к администратору')
			})
		}
	},
	setTechnologies: (sectionId, data) => {
		let list: ISectionTechnologies[] = get().technologies
		if (!list.some(tech => tech.sectionId === sectionId)) {
			list.push({
				sectionId,
				technologies: data
			})
			set(() => ({ technologies: list }))
		}
	},
	setSelectedEstimatesKeys: keys => set(() => ({ selectedEstimatesKeys: keys })),
	setExpandedRowKeys: keys => set(() => ({ expandedRowKeys: keys })),
	setExpandedSectionsWithEstimatePositions: keys =>
		set(() => ({ expandedSectionsWithEstimatePositions: keys })),
	setCollapsedSections: keys => set(() => ({ collapsedSections: keys })),
	changePosition: (sectionId, ids, direction, type) => {
		const currentSection = get().sections
		const estimatePositions: IWbsContainerSectionItem[] = findNestedObj<IWbsContainerSection>(
			currentSection!,
			'id',
			sectionId
		)?.children as IWbsContainerSectionItem[]
		if (estimatePositions) {
			let ordersSet: IEstimatePositionOrder[] = []
			let moved = estimatePositions

			const sortedIds = [...ids].sort((a, b) => {
				const indexA = estimatePositions.findIndex(i => i.id === a)
				const indexB = estimatePositions.findIndex(i => i.id === b)
				return direction === 'down' ? indexB - indexA : indexA - indexB
			})

			sortedIds.forEach(id => {
				const item = estimatePositions.find(i => i.id === id)
				const currentIndex = estimatePositions.findIndex(i => i.id === id)
				const nearbyIndex = estimatePositions.findIndex(
					i =>
						i.level === 1 &&
						i.order === (direction === 'down' ? item!?.order + 1 : item!?.order - 1)
				)

				if (nearbyIndex !== -1) {
					moved = arrayMove(moved, currentIndex, nearbyIndex)
					moved.map((item, index) =>
						ordersSet.push({
							estimatePostionId: item.id,
							orderPosition: index + 1
						})
					)
				}
			})

			const lastOrderIndex = findLastIndex(ordersSet, o => {
				return o.orderPosition === 1
			})
			if (ordersSet.slice(lastOrderIndex).length > 0) {
				WbsContainerApi.setEstimatePositionOrder(ordersSet.slice(lastOrderIndex), type).then(() => {
					set(() => ({ sectionBlock: '' }))
					setTimeout(() => set(() => ({ sectionBlock: sectionId })), 200)
				})
			}
		}
	},
	updateSection: sectionId => {
		const sectionBlock = get().sectionBlock
		if (sectionBlock || sectionBlock !== sectionId) {
			set(() => ({ sectionBlock: '' }))
			setTimeout(() => set(() => ({ sectionBlock: sectionId })), 200)
			set(() => ({ sectionBlock: '' }))
		}
	},
	setScrollTo: id => set(() => ({ scrollTo: id })),
	getCommentStatus: (isClosed, hasComments = true) => {
		switch (true) {
			case hasComments && isClosed === false:
				return '#ff3300'
			case hasComments && isClosed === true:
				return '#4a8a48'
			default:
				return 'gray'
		}
	},
	unsetContainer: () => set(() => ({ ...initState }))
}))
