import { DeleteOutlined, UploadOutlined } from '@ant-design/icons'
import LoaderSpinner from 'UI/loaderSpinner'
import {
	Button,
	Col,
	Descriptions,
	Modal,
	Row,
	Upload,
	UploadFile,
	UploadProps,
	message
} from 'antd'
import { RcFile } from 'antd/es/upload'
import { AxiosError } from 'axios'
import dayjs from 'dayjs'
import { IOrdinance, ISignDto } from 'interfaces/IOrdinance'
import { FC, useEffect, useState } from 'react'
import { ordinanceSigning } from 'services/OrdinanceService'
interface ISignModalProps {
	ordinance: IOrdinance
	attachmentType: number
	signingFileId?: string
	show: boolean
	onClose: () => void
	onConfirm: () => void
}

interface ISignData {
	status: string
	message: string
}

const SignModal: FC<ISignModalProps> = ({
	show,
	ordinance,
	attachmentType,
	signingFileId,
	onClose,
	onConfirm
}) => {
	const [isLoading, setIsLoading] = useState(false)
	const [signFile, setSignFile] = useState<UploadFile | null>(null)
	const [base64File, setBase64File] = useState<string | null>(null)
	const [signType, setSignType] = useState<number>(2)
	const [fileId, setFileId] = useState<string | null>(null)
	const checkFile = ordinance!
		?.attachments!?.sort((a, b) => dayjs(b.uploadDate!).diff(dayjs(a.uploadDate!)))
		.find(file => file.typeId === attachmentType)
	const onlyManual =
		checkFile?.statusId === 5 && (checkFile?.typeId === 1 || checkFile?.typeId === 3)

	useEffect(() => {
		if (show) {
			const signId = ordinance!
				?.attachments!?.sort((a, b) => dayjs(b.uploadDate!).diff(dayjs(a.uploadDate!)))
				.find(file => file.typeId === attachmentType)?.id
			if (typeof signId !== 'undefined') setFileId(attachmentType > 1 ? signingFileId! : signId)
		}
		if (onlyManual) setSignType(2)
	}, [show])

	const onSignConfirm = async () => {
		setIsLoading(true)
		const extension = signFile!.name.substring(
			signFile!.name.lastIndexOf('.') + 1,
			signFile!.name.length
		)
		const signDto: ISignDto = {
			attachmentType: attachmentType,
			signType: signType,
			base64File: {
				base64File: base64File!,
				extension: extension,
				mimoType:
					signFile!.type!?.length === 0
						? extension === 'sig'
							? 'application/pgp-signature'
							: ''
						: signFile!.type!
			}
		}
		await ordinanceSigning(ordinance.id, fileId!, signDto)
			.then(response => {
				onSignModalClose()
				message.success(response!?.data!?.message!)
				onConfirm()
			})
			.catch((error: AxiosError) => {
				const response: ISignData | null = (error.response!?.data as ISignData) ?? null
				message.error(response!.message)
			})
			.finally(() => setTimeout(() => setIsLoading(false), 500))
	}

	const getBase64 = (file: RcFile): Promise<string> =>
		new Promise((resolve, reject) => {
			const reader = new FileReader()
			reader.readAsDataURL(file)
			reader.onload = () => resolve(reader.result as string)
			reader.onerror = error => reject(error)
		})

	const onSignModalClose = () => {
		// setSignType(1)
		setSignFile(null)
		setBase64File(null)
		onClose()
	}

	const props: UploadProps = {
		showUploadList: false,
		maxCount: 1,
		onRemove: () => {
			setSignFile(null)
		},
		beforeUpload: file => {
			const isSign =
				(signType === 1 &&
					(file.type === 'application/pkcs7-signature' ||
						file.type === 'application/x-pkcs7-signature' ||
						file.type === 'application/pgp-signature' ||
						file.name.includes('.sig'))) ||
				(signType === 2 && file.type === 'application/pdf')

			if (isSign) {
				setSignFile(file)
				getBase64(file).then(data => setBase64File(data.split(',')[1]))
			} else {
				message.error(`${file.name} не является подписью`)
			}
			return isSign || Upload.LIST_IGNORE
		}
	}

	// const onTypeChange = (value: number) => {
	// 	setSignType(value)
	// 	setSignFile(null)
	// }

	const signTitle = () => {
		switch (attachmentType) {
			case 2:
				return <>Подписание акта об устранении</>
			case 3:
				return <>Подписание акта о не устранении</>
			default:
				return <>Подписание предписания</>
		}
	}

	return (
		<Modal
			open={show}
			onCancel={onSignModalClose}
			onOk={onSignConfirm}
			okButtonProps={{ disabled: signFile === null || isLoading }}
			cancelButtonProps={{ disabled: isLoading }}
			title={signTitle()}
			zIndex={9999999999}
		>
			{isLoading && <LoaderSpinner />}
			<Descriptions
				layout="horizontal"
				column={12}
				bordered
				className="at-descriptions"
				style={{ marginTop: '1rem' }}
				labelStyle={{
					fontWeight: 'bold',
					width: '30%',
					alignItems: 'center',
					padding: '4px 8px'
				}}
				contentStyle={{
					width: '70%',
					padding: '4px 8px'
				}}
			>
				{/* <Descriptions.Item label="Тип подписи:" span={12}>
					<Radio.Group
						value={signType}
						buttonStyle="solid"
						onChange={e => onTypeChange(e.target.value)}
					>
						<Radio.Button disabled={onlyManual} value={1}>
							Электронная
						</Radio.Button>
						<Radio.Button value={2}>Ручная</Radio.Button>
					</Radio.Group>
				</Descriptions.Item> */}
				<Descriptions.Item label="Файл:" span={12}>
					{signFile === null ? (
						<Upload {...props}>
							<Button icon={<UploadOutlined rev={undefined} />}>Выберите файл</Button>
						</Upload>
					) : (
						<Row gutter={8} style={{ alignItems: 'center' }}>
							<Col flex={1}>Подпись загружена</Col>
							<Col>
								<Button
									danger
									icon={<DeleteOutlined rev={undefined} />}
									onClick={() => setSignFile(null)}
								/>
							</Col>
						</Row>
					)}
				</Descriptions.Item>
			</Descriptions>
			<div style={{ textAlign: 'right', width: '100%', marginTop: '.5rem' }}>
				<i>
					*Допустимые форматы файлов: <b>{signType === 1 ? '.sig, .p7s' : '.pdf'}</b>
				</i>
				<br />
				<i>**При смене типа подписи, выбранный файл очищается</i>
				{attachmentType === 2 && signType === 2 && (
					<>
						<br />
						<i>
							***Загружаемый документ должен быть подписан представителем подрядчика и
							представителем заказчика
						</i>
					</>
				)}
			</div>
		</Modal>
	)
}

export default SignModal
