import { useMutation, useQueryClient } from '@tanstack/react-query'
import { wbsTaskVisasApi } from './wbs-task-visas-api'
import { notification } from 'antd'
import { AxiosError } from 'axios'
import { IErrorDetail } from 'interfaces/IBase'

export const useWbsTaskRollupStatus = () => {
	const queryClient = useQueryClient()
	return useMutation({
		mutationKey: ['wbsTaskStatus', 'rollup'],
		mutationFn: async ({
			wbsTaskId,
			wbsTaskStatusId
		}: {
			wbsTaskId: string
			wbsTaskStatusId: string
		}) => wbsTaskVisasApi.statusRollup(wbsTaskId, wbsTaskStatusId),
		onSuccess: () => {
			notification.success({
				message: 'Статус изменён',
				placement: 'topLeft'
			})
			queryClient.invalidateQueries({
				predicate: query =>
					['wbsTasks', 'wbsTaskCard', 'wbsTaskVisas'].includes(query.queryKey[0] as string)
			})
		},
		onError: (error: AxiosError<IErrorDetail>) => {
			notification.error({
				message: `Непредвиденная ошибка. Обратитесь к администратору. Код ошибки: ${error.response?.status}`,
				description: error.response?.data?.detail,
				placement: 'topLeft'
			})
		}
	})
}
