import { useMutation, useQueryClient } from '@tanstack/react-query'
import {
	Col,
	Drawer,
	Form,
	message,
	Modal,
	notification,
	Select,
	Tabs,
	TabsProps,
	Tooltip
} from 'antd'
import { RcFile } from 'antd/es/upload'
import { UploadFile } from 'antd/lib'
import { AxiosError } from 'axios'
import dayjs from 'dayjs'
import { TWbsTaskForm } from 'entities/wbs-task/model/wbs-task-schema'
import { useAppDispatch, useAppSelector } from 'hooks/appReduxHook'
import { IErrorDetail } from 'interfaces/IBase'
import { useWbsTaskState } from 'pages/wbs-tasks-page/model/wbs-tasks-state'
import { FC, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { DATE_FORMAT, PERMISSIONS } from 'shared/constants'
import { WBS_TASK_STATUS } from 'shared/constants/wbs-task-types'
import { useCheckCommonPermissions, useCheckPermissions } from 'shared/useCheckPermissions'
import History from 'UI/history'
import { wbsTaskCardApi } from '../api/wbs-task-card-api'
import {
	useWbsTaskCard,
	useWbsTaskCardContainers,
	useWbsTaskCardEstimates,
	useWbsTaskSave,
	useWbsTaskUpdate
} from '../api/wbs-task-card-queries'
import { WbsTaskCardAttachments } from './wbs-task-attachments'
import { WbsTaskCardHeader } from './wbs-task-card-header'
import { WbsTaskCardDetails } from './wbs-task-details'
import { IErrorData } from 'UI/dashboard/EnvironmentConfig'
import { resetIssuesState } from 'store/issueSlice'
import { resetOrdinancesState } from 'store/ordinanceSlice'
import { resetCheckListsState } from 'store/checklistsSlice'
import { resetTemplatesState } from 'store/templatesSlice'
import { resetAsm } from 'store/asmSlice'
import { resetTrafficLight } from 'store/trafficLightSlice'
import { getAppUserPermissions } from 'services/AuthService'
import {
	setEnvironment,
	setEnvironmentPermissions,
	setEnvironmentProject
} from 'store/environmentSlice'
import {
	getContractors,
	getContractsByProject1CId,
	getIssueSubTypes,
	getIssueTypes
} from 'services/IssuesService'
import { getBuildingPermitsList } from 'services/OrdinanceService'
import { useWbsTaskCardState } from '../model/wbs-task-card-state'
import {
	useCanVisaApprove,
	useWbsTaskVisas
} from 'widgets/wbs-task-visas/api/wbs-task-visas-queries'

interface IProps {
	wbsDto?: any
	localDto?: any
}

const AdditionalRequest = [
	{
		name: 'Автор',
		id: 0
	},
	{ name: 'ДП', id: 1 },
	{ name: 'Автор + ДП', id: 2 }
]

export const WbsTaskCard: FC<IProps> = ({ wbsDto, localDto }) => {
	const { user } = useAppSelector(state => state.environment)
	const checkPermissions = useCheckPermissions()
	const checkPermissionsCommon = useCheckCommonPermissions()
	const { data, error, isSuccess, isFetching, isError } = useWbsTaskCard()
	const { currentTaskId, openType, initForm, setInitForm, unset, closeCard, setTask } =
		useWbsTaskState()
	const [wbsTaskCardForm] = Form.useForm<TWbsTaskForm>()
	const formValues = Form.useWatch(values => values, wbsTaskCardForm)
	const canEdit =
		checkPermissions([PERMISSIONS.WbsTasksCreate], data?.project?.id!) ||
		(!data && !currentTaskId && checkPermissionsCommon([PERMISSIONS.WbsTasksCreate]))
	const canView =
		checkPermissions([PERMISSIONS.WbsTasksView], data?.project?.id!) ||
		(!data && !currentTaskId && checkPermissionsCommon([PERMISSIONS.WbsTasksView]))
	const coordinatorDp =
		(!data && !currentTaskId && checkPermissionsCommon([PERMISSIONS.WbsTasksAssignedTo])) ||
		checkPermissions([PERMISSIONS.WbsTasksAssignedTo], data?.project?.id!)
	const coordinatorDes =
		(!data && !currentTaskId && checkPermissionsCommon([PERMISSIONS.WbsTasksAssignedToDES])) ||
		checkPermissions([PERMISSIONS.WbsTasksAssignedToDES], data?.project?.id!)
	const { data: canApprove } = useCanVisaApprove(currentTaskId!)
	const { data: wbsTaskVisas, isFetching: isFetchingVisas } = useWbsTaskVisas(currentTaskId!)
	const { setShowCard, showCard, estimateId } = useWbsTaskCardState()
	const canEditAttachment =
		(!data && !currentTaskId && checkPermissionsCommon([PERMISSIONS.WbsTasksAttachFiles])) ||
		(checkPermissions([PERMISSIONS.WbsTasksAttachFiles], data?.project?.id!) &&
			(user?.isAdmin ||
				(!data && !currentTaskId) ||
				!!(data?.createdBy.id === user?.id && data?.wbsTaskStatus.id === WBS_TASK_STATUS.new) ||
				!!(
					data?.wbsTaskStatus.id === WBS_TASK_STATUS.docs &&
					(checkPermissions([PERMISSIONS.WbsTasksAssignedTo], data?.project?.id!) ||
						data.assignedUsers?.find(a => a?.id === user?.id))
				) ||
				!!(
					data?.wbsTaskStatus.id === WBS_TASK_STATUS.inProgress &&
					(checkPermissions([PERMISSIONS.WbsTasksAssignedToDES], data?.project?.id!) ||
						data.assignedUsers?.find(a => a?.id === user?.id))
				)))
	const canDeleteAttachment =
		(!data && !currentTaskId && checkPermissionsCommon([PERMISSIONS.WbsTasksDeleteFiles])) ||
		checkPermissions([PERMISSIONS.WbsTasksDeleteFiles], data?.project?.id!)
	const hasVisa = wbsTaskVisas
		?.find((v: any) => v.name === 'DES')
		?.visas?.some((a: any) => a.visaState !== null)
	const dispatch = useAppDispatch()
	const navigate = useNavigate()
	const queryClient = useQueryClient()
	const { mutate: mutateSave, isLoading: isLoadingSave } = useWbsTaskSave()
	const { mutate: mutateUpdate, isLoading: isLoadingUpdate } = useWbsTaskUpdate()
	const [filesToUpload, setFilesToUpload] = useState<UploadFile[]>([])
	const [activeKey, setActiveKey] = useState('details')

	useEffect(() => {
		if (data && isSuccess) {
			const init: TWbsTaskForm = {
				wbsTaskTypeId: data.wbsTaskType.id ?? undefined,
				constructionObjectId: data.constructionObject.id ?? undefined,
				workPackageId: data.workPackage.id ?? undefined,
				typeSectionId: data.typeSection.id ?? undefined,
				title: data.title ?? '',
				instruction: data.instruction ?? '',
				projectId: data.project?.id ?? undefined,
				contractId: data.contract?.id ?? undefined,
				estimateId: data.estimate?.containerId ?? undefined,
				containerId: data.container?.id ?? undefined,
				containerLabel: data.container?.name ?? undefined,
				estimateLabel: data.estimate?.name ?? undefined
			}
			setInitForm(init)
			wbsTaskCardForm.setFieldsValue(init)
			useWbsTaskCardState.setState({ estimateId: data?.estimate?.id })
			// wbsTaskCardForm.validateFields()
		}
	}, [data])

	useEffect(() => {
		wbsTaskCardForm.setFieldsValue(initForm)
	}, [initForm])

	console.log('initForm', initForm)

	const tabItems: TabsProps['items'] = [
		{
			key: 'details',
			label: 'Детали',
			disabled: !canView,
			children: (
				<WbsTaskCardDetails
					form={wbsTaskCardForm}
					formValues={formValues}
					canEdit={
						canEdit && data?.wbsTaskStatus?.id !== WBS_TASK_STATUS.done && openType !== 'main'
					}
				/>
			)
		},
		{
			key: 'attachments',
			label: 'Файлы',
			disabled: !canView,
			children: (
				<WbsTaskCardAttachments
					formValues={formValues!}
					form={wbsTaskCardForm}
					setFilesToUpload={setFilesToUpload}
					filesToUpload={filesToUpload}
					canEditAttachment={canEditAttachment! && openType !== 'main'}
					canDeleteAttachment={canDeleteAttachment! && openType !== 'main'}
					status={data?.wbsTaskStatus?.id!}
				/>
			)
		},
		{
			label: 'История',
			key: 'history',
			disabled: currentTaskId === null || !canView,
			children: <History id={currentTaskId!} target={'wbsTask'} />
		}
	]

	const onCloseHandler = () => {
		queryClient.invalidateQueries(['wbsTasks'])
		queryClient.invalidateQueries(['wbsTasksDiagram'])
		closeCard()
		setShowCard(false)
		setActiveKey('details')
		wbsTaskCardForm.resetFields()
		unset()
		navigate(
			openType === 'wbs'
				? '/unmodel'
				: openType === 'local'
				? '/local'
				: openType === 'main'
				? '/'
				: '/wbs-tasks'
		)
		setFilesToUpload([])
	}

	const afterSaveCard = (id?: string) => {
		queryClient.invalidateQueries(['wbsTasks'])
		queryClient.invalidateQueries(['wbsTasksDiagram'])
		queryClient.invalidateQueries(['wbsTaskCard'])
		queryClient.invalidateQueries(['wbsTaskCard', 'attachments'])
		setFilesToUpload([])
		if (id !== null) {
			setTask(id!)
			navigate(`/wbs-tasks/${id}`)
		}
	}

	const onSave = (values: TWbsTaskForm) => {
		const dto = {
			...values,
			estimateId: estimateId ?? data?.estimate?.id
			// projectId: data?.project?.id ?? currentProject.id
		}
		if (data?.id) {
			mutateUpdate(
				{ id: data?.id!, dto: dto! },
				{
					onSuccess: answer => {
						notification.success({
							message: 'Заявка успешно обновлена',
							placement: 'topLeft'
						})
						addAttachments(data?.id ?? answer)
						afterSaveCard(data?.id ?? answer)
					},
					onError: (error: any) => {
						notification.error({
							message: 'Произошла ошибка во время сохранения',
							description: error.response?.data?.detail,
							placement: 'topLeft'
						})
					}
				}
			)
		} else {
			mutateSave(dto!, {
				onSuccess: answer => {
					notification.success({
						message: 'Заявка успешно сохранена',
						placement: 'topLeft'
					})
					addAttachments(answer)
					afterSaveCard(answer)
				},
				onError: (error: any) => {
					notification.error({
						message: 'Произошла ошибка во время сохранения',
						description: error.response?.data?.detail,
						placement: 'topLeft'
					})
				}
			})
		}
	}

	const { mutate: mutateAttachments, isLoading: isLoadingAttachments } = useMutation({
		mutationKey: ['wbsTask', 'attachments'],
		mutationFn: async ({
			wbsTaskId,
			fmData,
			config
		}: {
			wbsTaskId: string
			fmData: FormData
			config: any
		}) =>
			await wbsTaskCardApi.addAttachments(
				wbsTaskId!,
				fmData,
				config,
				!!coordinatorDp ? 'coordinatorDp' : !!coordinatorDes ? 'coordinatorDes' : undefined
			),
		onSuccess: () => {
			queryClient.invalidateQueries(['wbsTaskCard', 'attachments'])
			setFilesToUpload([])
		},
		onError: (error: AxiosError<IErrorDetail>) => {
			notification.error({
				message: 'Произошла ошибка',
				description: error.response?.data?.detail,
				placement: 'topLeft'
			})
		}
	})

	const { mutate: mutateAdditionalRequest, isLoading: isLoadingAdditionalRequest } = useMutation({
		mutationKey: ['wbsTask', 'additionalRequest'],
		mutationFn: async ({
			wbsTaskId,
			statusAdditionalRequest,
			visaId
		}: {
			wbsTaskId: string
			statusAdditionalRequest: number
			visaId?: string
		}) => await wbsTaskCardApi.additionalRequest(wbsTaskId!, statusAdditionalRequest),
		onSuccess: () => {
			queryClient.invalidateQueries(['wbsTasks'])
			queryClient.invalidateQueries(['wbsTasksDiagram'])
			queryClient.invalidateQueries(['wbsTaskCard'])
			setFilesToUpload([])
		},
		onError: (error: AxiosError<IErrorDetail>) => {
			notification.error({
				message: 'Произошла ошибка',
				description: error.response?.data?.detail,
				placement: 'topLeft'
			})
		}
	})

	const addAttachments = (wbsTaskId: string) => {
		if (filesToUpload.length > 0 && wbsTaskId) {
			const config = {
				headers: { 'content-type': 'multipart/form-data' }
			}
			const fmData = new FormData()
			filesToUpload?.map(file => fmData.append('files', file.originFileObj as RcFile))
			mutateAttachments({ wbsTaskId, fmData, config })
		}
	}

	const customizeRequiredMark = (label: React.ReactNode, { required }: { required: boolean }) => (
		<>
			{label}
			{required && <sup style={{ color: 'red' }}>*</sup>}
		</>
	)

	const onFailedIssue = () => {
		setActiveKey('details')
		const errors = wbsTaskCardForm.getFieldsError().filter(err => err.errors.length !== 0)
		errors.flatMap(err => message.error(err.errors))
		wbsTaskCardForm.scrollToField(errors[0].name, { behavior: 'smooth', block: 'center' })
	}

	const onTabChange = (key: string) => {
		setActiveKey(key)
	}

	const onValuesChangeHandler = (value: any) => {
		if (Object.keys(value)[0] === 'workPackageId') {
			wbsTaskCardForm.resetFields(['typeSectionId'])
		}
	}

	return (
		<Form
			name="wbsTaskCardForm"
			scrollToFirstError
			form={wbsTaskCardForm}
			labelAlign="left"
			labelCol={{ style: { width: '220px', minWidth: '220px' } }}
			colon={false}
			requiredMark={customizeRequiredMark}
			layout="inline"
			initialValues={{ statusId: WBS_TASK_STATUS.new }}
			onFinish={
				canEdit
					? () => onSave(wbsTaskCardForm.getFieldsValue(true))
					: () => addAttachments(data?.id!)
			}
			onFinishFailed={onFailedIssue}
			onValuesChange={onValuesChangeHandler}
		>
			<Drawer
				rootClassName="app-entity-card"
				classNames={{
					body: 'app-entity-card__body',
					header: `app-entity-card__header indicator--${data?.wbsTaskStatus.name ?? 'New'}`
				}}
				styles={{ body: { background: '#fafafa', padding: 0 } }}
				loading={isLoadingSave || isLoadingUpdate}
				onClose={onCloseHandler}
				destroyOnClose={true}
				open={showCard}
				width={window.outerWidth < 1300 ? '75vw' : '50vw'}
				title={
					data && currentTaskId
						? `Заявка №${data.number} от ${dayjs(data.createdAt).format(DATE_FORMAT.view)}`
						: 'Новая заявка'
				}
				extra={
					<WbsTaskCardHeader
						form={wbsTaskCardForm}
						isNew={data && currentTaskId ? false : true}
						canEdit={canEdit && openType !== 'main'}
						canEditAttachment={canEditAttachment}
						isLoading={isLoadingSave || isLoadingUpdate}
						status={data?.wbsTaskStatus!}
						wbsTaskId={data?.id!}
					/>
				}
				footer={
					<>
						{
							<Col>
								<>Дополнительный запрос </>
								<Tooltip
									title={`${
										data?.wbsTaskStatus.name !== 'DES' || hasVisa
											? 'Доступно в статусе ДЭС (в работе УПВОР), если ни одна виза согласования не проставлена'
											: ''
									}`}
								>
									<Select
										disabled={
											data?.wbsTaskStatus.name !== 'DES' ||
											!checkPermissions(
												[PERMISSIONS.WbsTasksAdditionalRequest],
												data?.project?.id!
											) ||
											hasVisa
											// wbsTaskVisas
											// 	?.find((v: any) => v.name === 'DES')
											// 	.visas?.some(
											// 		(a: any) =>
											// 			canApprove?.includes(a.id) &&
											// 			a.visaState !== null
											// 	)
										}
										placeholder="Выберите дополнительный запрос"
										options={AdditionalRequest}
										fieldNames={{ label: 'name', value: 'id' }}
										style={{ width: '250px', paddingLeft: '5px' }}
										onChange={value => {
											mutateAdditionalRequest({
												wbsTaskId: data?.id!,
												statusAdditionalRequest: value
											})
										}}
									/>
								</Tooltip>
							</Col>
						}
					</>
				}
			>
				<Tabs
					items={tabItems}
					className="app-tabs-sticky"
					size="large"
					tabBarGutter={16}
					onChange={onTabChange}
					activeKey={activeKey}
				/>
			</Drawer>
		</Form>
	)
}
