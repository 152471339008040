import { SaveOutlined } from '@ant-design/icons'
import { Button, Col, Form, FormInstance, notification, Row, Select } from 'antd'
import { useAppSelector } from 'hooks/appReduxHook'
import { FC, useCallback, useState } from 'react'
import { PERMISSIONS } from 'shared/constants'
import {
	CHECKLIST_STATUS,
	CHECKLIST_STATUSES,
	TEMPLATE_TYPE
} from 'shared/constants/checklist-types'
import { useCheckPermissions } from 'shared/useCheckPermissions'
import { useChecklistCanceledMutation } from '../api/checklist-card-queries'
import { useCheckDisabledStatuses, useChecklistCanSave } from '../model/checklist-card-helper'
import { TChecklistForm } from '../model/checklist-card-schema'
import { useChecklistCardState } from '../model/checklist-card-state'
import { ChecklistWarrantyModal } from './checklist-warranty-modal'

type TProps = {
	isNew: boolean
	form: FormInstance<TChecklistForm>
	formValues?: TChecklistForm
	isLoading: boolean
	disabled: boolean
}

export const ChecklistCardExtra: FC<TProps> = ({
	isNew,
	form,
	formValues,
	isLoading,
	disabled
}) => {
	const canSave = useChecklistCanSave()()
	const { currentId, templateType } = useChecklistCardState()
	const { user: currentUser, project } = useAppSelector(state => state.environment)
	const checkDisabledStatuses = useCheckDisabledStatuses(formValues?.status)
	const checkPermissions = useCheckPermissions()
	const [showWarrantyModal, setShowWarrantyModal] = useState(false)
	const { mutate: onCanceled } = useChecklistCanceledMutation()

	const canCreate = useCallback(
		() =>
			(templateType === TEMPLATE_TYPE.ndc &&
				checkPermissions([PERMISSIONS.ChecklistsCreateUksNdc], project?.id)) ||
			(templateType === TEMPLATE_TYPE.ukkc &&
				checkPermissions([PERMISSIONS.ChecklistsCreateUkKc], project?.id)),
		[currentId]
	)()

	const canProcess = useCallback(
		() =>
			(templateType === TEMPLATE_TYPE.ndc &&
				checkPermissions([PERMISSIONS.ChecklistsProcessingUksNdc], project?.id)) ||
			(templateType === TEMPLATE_TYPE.ukkc &&
				checkPermissions([PERMISSIONS.ChecklistsProcessingUkKc], project?.id)),
		[currentId]
	)()

	const canCanceled = useCallback(
		() =>
			(templateType === TEMPLATE_TYPE.ndc &&
				checkPermissions([PERMISSIONS.ChecklistsCancelUksNdc], project?.id)) ||
			(templateType === TEMPLATE_TYPE.ukkc &&
				checkPermissions([PERMISSIONS.ChecklistsCancelUkKc], project?.id)),
		[currentId]
	)()

	const onStatusChange = (status: CHECKLIST_STATUS) => {
		const prevStatus = formValues?.status

		switch (true) {
			case status === CHECKLIST_STATUS.Warranty &&
				checkPermissions([PERMISSIONS.ChecklistsAcceptedUnderWarranty], project?.id):
				setShowWarrantyModal(true)
				break

			case currentId && status === CHECKLIST_STATUS.Canceled:
				if (
					canCanceled ||
					(prevStatus === CHECKLIST_STATUS.New && (canCreate || canProcess)) ||
					(prevStatus === CHECKLIST_STATUS.InProgress && canProcess)
				) {
					onCanceled(currentId!)
				} else {
					notification.warning({
						message: 'Внимание!',
						description: 'У вас нет прав на установку этого статуса',
						placement: 'topLeft'
					})
				}
				break

			default:
				form.setFieldValue('status', status)
				form.submit()
				break
		}
	}

	console.log(
		'formValues?.assignedTo',
		formValues?.assignedTo?.some(user => user === currentUser?.id)
	)
	console.log('formValues', formValues)

	return (
		<>
			<ChecklistWarrantyModal
				show={showWarrantyModal}
				onCancel={() => setShowWarrantyModal(false)}
			/>
			<Row wrap={false} align="middle" gutter={16} justify="end">
				{formValues && (
					<Col flex={1}>
						<Row style={{ width: '320px' }} wrap={false} align="middle" gutter={16}>
							<Col style={{ color: 'white' }}>Статус:</Col>
							<Col flex={1}>
								<Form.Item name="status" style={{ display: 'none' }} hidden />
								<Select
									style={{ width: '100%' }}
									value={formValues.status ?? CHECKLIST_STATUS.New}
									options={Object.values(CHECKLIST_STATUSES).map((x, i) => ({
										label: x,
										value: i,
										disabled:
											isNew ||
											disabled ||
											checkDisabledStatuses(
												i,
												formValues?.assignedTo?.some(user => user === currentUser?.id) ?? false
											)
									}))}
									onChange={onStatusChange}
									className="issue-select-status"
								/>
							</Col>
						</Row>
					</Col>
				)}
				<Col>
					<Button
						htmlType="submit"
						icon={<SaveOutlined />}
						onClick={() => form.submit()}
						disabled={!canSave || disabled}
						loading={isLoading}
						ghost
						style={{ borderColor: '#fff', color: '#fff' }}
					>
						Сохранить
					</Button>
				</Col>
			</Row>
		</>
	)
}
