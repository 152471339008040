import { QuestionCircleOutlined } from '@ant-design/icons'
import { Button, Card, Col, Descriptions, Popover, Row, Space, Table } from 'antd'
import dayjs from 'dayjs'
import { IAppUser } from 'interfaces/IUser'
import { FC } from 'react'
import { UserRightsColumns } from './userMenu.columns'
interface IProps {
	user?: IAppUser
}
export const UserMenuDetails: FC<IProps> = ({ user }) => {
	const hasCert = user?.sertificate !== null
	const questionCircleContent = (
		<div>
			Если у Вас просрочен сертификат, пожалуйста, сделайте заявку на перевыпуск сертификата
		</div>
	)
	return (
		<Space direction="vertical" style={{ width: '100%' }}>
			<Card className="at-workcard">
				<Descriptions
					title="Основные параметры:"
					layout="horizontal"
					column={12}
					bordered
					className="at-descriptions"
					labelStyle={{
						fontWeight: 'bold',
						width: '20%',
						alignItems: 'center',
						padding: '2px 8px'
					}}
					contentStyle={{
						width: '80%',
						padding: '.5rem',
						alignItems: 'center'
					}}
				>
					<Descriptions.Item label="Фамилия" span={12}>
						{user!?.lastName!}
					</Descriptions.Item>
					<Descriptions.Item label="Имя" span={12}>
						{user!?.firstName!}
					</Descriptions.Item>
					<Descriptions.Item label="E-mail" span={12}>
						{user!?.email!}
					</Descriptions.Item>
					<Descriptions.Item label="Компания" span={12}>
						{user!?.contractorName!}
					</Descriptions.Item>

					{hasCert && (
						<Descriptions.Item
							label="Сертификат"
							span={12}
							style={{
								backgroundColor: hasCert
									? user!?.sertificate!?.signExpired!
										? '#ffe6e6'
										: '#edffe6'
									: '#e6f4ff'
							}}
						>
							<Row align="middle">
								<Col flex={1}>
									{hasCert
										? user!?.sertificate!?.durotation! &&
										  (user!?.sertificate!?.signExpired ? (
												<b style={{ color: 'red' }}>
													истёк {dayjs(user!?.sertificate!?.durotation!).format('DD.MM.YYYY')}
												</b>
										  ) : (
												`действителен до ${dayjs(user!?.sertificate!?.durotation!).format(
													'DD.MM.YYYY'
												)}`
										  ))
										: 'Необходимо выпустить сертификат'}
								</Col>
								{hasCert && user!?.sertificate!?.signExpired && (
									<Col>
										<Popover content={questionCircleContent}>
											<Button danger ghost type="text" icon={<QuestionCircleOutlined />} />
										</Popover>
									</Col>
								)}
							</Row>
						</Descriptions.Item>
					)}
				</Descriptions>
			</Card>
			<Card className="at-workcard">
				<h3 style={{ marginBottom: '1rem' }}>Проекты:</h3>
				<Table
					dataSource={user!?.projects!}
					rowKey={record => record.id!}
					scroll={{ y: '40dvh' }}
					size="small"
					bordered
					columns={UserRightsColumns}
					pagination={false}
				/>
			</Card>
		</Space>
	)
}
