import { useMutation, useQuery } from "@tanstack/react-query";
import { diagramApi } from "../api/diagrams-api";
import { useDiagramState } from "./diagrams-state";
import { useAppSelector } from "hooks/appReduxHook";
import { wbsTaskRegistryApi } from "widgets/wbs-task-registry/api/wbs-task-registry-api";
import { useWbsTaskState } from "pages/wbs-tasks-page/model/wbs-tasks-state";

export const useProjects1C = (projectIds?: string[], contractorIds?: string[], isNotWbsTask?: boolean) => {
  return useQuery({
    queryKey: ['project1C', 'diagrams', projectIds, contractorIds],
      refetchOnWindowFocus: false,
      queryFn: async () => {
        if (isNotWbsTask){
          return await diagramApi.getProjects1C(projectIds, contractorIds).then(data => data)
        } else return null
      }
    })
}

export const useProjectsData = (project1CIds?: string[], contractorIds?: string[]) => {
  return useQuery({
    queryKey: ['projects', 'diagrams', project1CIds, contractorIds],
      refetchOnWindowFocus: false,
      queryFn: async () => {
        return await diagramApi.getProjects(project1CIds, contractorIds).then(data => data)
      }
    })
}

export const useContractors = (projectIds?: string[], project1CIds?: string[], isNotWbsTask?: boolean) => {
  return useQuery({
    queryKey: ['contractors', projectIds, project1CIds],
      refetchOnWindowFocus: false,
      queryFn: async () => {
        if (isNotWbsTask) {
                return await diagramApi.getContractors(projectIds, project1CIds).then(data => data)
              }
              return null
      }
    })
}

export const useCountContractors = () => {
  const {to, from, project1CIds, projectIds, contractorIds} = useDiagramState()
  return useQuery({
    queryKey: ['countContractors', to, from, project1CIds, projectIds, contractorIds],
      refetchOnWindowFocus: false,
      queryFn: async () => {
        // if (to !== undefined && from !== undefined && project1CIds && projectIds && contractorIds){
                    return await diagramApi.getCountContractors({to, from, project1CIds, projectIds, contractorIds})
                    // }
                    // return null
      }
    })
}

export const useCountIssues = () => {
  const {to, from, project1CIds, projectIds, contractorIds} = useDiagramState()
  return useQuery({
    queryKey: ['countIssues', to, from, project1CIds, projectIds, contractorIds],
      refetchOnWindowFocus: false,
      queryFn: async () => {
        // if (to !== undefined && from !== undefined && project1CIds && projectIds && contractorIds){
                return await diagramApi.getCountIssues({to, from, project1CIds, projectIds, contractorIds})
              // }
              // return null
      }
    })
}

export const useRatingDashboard = () => {
  const {to, from} = useDiagramState()
  return useQuery({
    queryKey: ['ratingDashboard', to, from],
      refetchOnWindowFocus: false,
      queryFn: async () => {
        // if (to !== undefined && from !== undefined){
              return await diagramApi.getContractorsRating({to, from})
              // }
              // return null
      }
    })
}

export const useIssueSubTypesDashboard = () => {
  const {to, from, project1CIds, projectIds, contractorIds} = useDiagramState()
  return useQuery({
    queryKey: ['issueSubTypesDashboard', to, from, project1CIds, projectIds, contractorIds],
      refetchOnWindowFocus: false,
      queryFn: async () => {
        // if (to !== undefined && from !== undefined && project1CIds && projectIds && contractorIds){
          return await diagramApi.getIssueSubTypes({to, from, project1CIds, projectIds, contractorIds})
          // }
          // return null
      }
    })
}

export const useContainerDashboard = (isNotWbsTask?: boolean) => {
  const {to, from, project1CIds, projectIds, contractorIds} = useDiagramState()
  return useQuery({
    queryKey: ['containerDashboard', to, from, project1CIds, projectIds, contractorIds],
      refetchOnWindowFocus: false,
      queryFn: async () => {
        // if ((to !== undefined && from !== undefined) && ((isNotWbsTask && project1CIds !== undefined && projectIds !== undefined && contractorIds !== undefined) || (!isNotWbsTask && projectIds !== undefined))){
          return await diagramApi.getCountContainers({to, from, project1CIds, projectIds, contractorIds})
          // }
          // return null
      }
    })
}

export const useCountOrdinancesDashboard = () => {
  const {to, from, project1CIds, projectIds, contractorIds} = useDiagramState()
  return useQuery({
    queryKey: ['countOrdinancesDashboard', to, from, project1CIds, projectIds, contractorIds],
      refetchOnWindowFocus: false,
      queryFn: async () => {
        // if (to !== undefined && from !== undefined && project1CIds && projectIds && contractorIds){
          return await diagramApi.getCountOrdinances({to, from, project1CIds, projectIds, contractorIds})
          // }
          // return null
      }
    })
}

export const useCountChecklistsDashboard = () => {
  const {to, from, project1CIds, projectIds, contractorIds} = useDiagramState()
  return useQuery({
    queryKey: ['countChecklistsDashboard', to, from, project1CIds, projectIds, contractorIds],
      refetchOnWindowFocus: false,
      queryFn: async () => {
        // if (to !== undefined && from !== undefined && project1CIds && projectIds && contractorIds){
          return await diagramApi.getCountCheckLists({to, from, project1CIds, projectIds, contractorIds})
          // }
          // return null
      }
    })
}

export const useCountOrdinanceFinesDashboard = () => {
  const {to, from, project1CIds, projectIds, contractorIds} = useDiagramState()
  return useQuery({
    queryKey: ['countOrdinanceFinesDashboard', to, from, project1CIds, projectIds, contractorIds],
      refetchOnWindowFocus: false,
      queryFn: async () => {
        // if (to !== undefined && from !== undefined && project1CIds && projectIds && contractorIds){
          return await diagramApi.getCountOrdinanceFines({to, from, project1CIds, projectIds, contractorIds})
          // }
          // return null
      }
    })
}

export const useWbsTaskDiagram = () => {
    const { project } = useAppSelector(state => state.environment)
    const { currentPage, wbsTaskFilters, sortState, setTotalItems } = useWbsTaskState()
    const {projectIds, to, from} = useDiagramState()
  return useQuery({
    queryKey: ['wbsTasksDiagram', currentPage, wbsTaskFilters, sortState, project, projectIds, to, from],
    queryFn: async () => {
      // if ((to !== undefined && from !== undefined && projectIds !== undefined)){
        const registry =  await wbsTaskRegistryApi.get(currentPage, {projectId: projectIds!, to, from }, sortState).then(data => data)
              setTotalItems(registry.totalItems)
          return registry.data
      // } else return null
    },
    refetchOnWindowFocus: false
  })
}

export const useOrdinanceFinesPerMonth = () => {
  const {to, from, project1CIds, projectIds, contractorIds} = useDiagramState()
  return useQuery({
    queryKey: ['ordinanceFinesMonthDiagram', to, from, project1CIds, projectIds, contractorIds],
      refetchOnWindowFocus: false,
      queryFn: async () => {
        return await diagramApi.getOrdinanceFinesPerMonth({to, from, project1CIds, projectIds, contractorIds}).then(data => data)
      }
    })
}

export const useContractorRatingToExcel = () => {
  const { to, from } = useDiagramState()
  return useMutation({
    mutationFn: async () => await diagramApi.getContractorRatingToExcel({ to, from }).then(data => data)
  })
}