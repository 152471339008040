import { useQuery } from '@tanstack/react-query'
import { useAppSelector } from 'hooks/appReduxHook'
import { LocalEstimateRegistryApi, useLocalEstimateRegistryState } from '..'

export const useLocalEstimateRegistry = () => {
	const { project } = useAppSelector(state => state.environment)
	const { currentPage, filters, sortState, setTotalItems } = useLocalEstimateRegistryState()
	return useQuery({
		queryKey: ['localEstimateRegistry', currentPage, filters, sortState, project],
		queryFn: async () => {
			// if (project) {
				const registry = await LocalEstimateRegistryApi.getRegistry(currentPage, filters, sortState).then(
					data => data
				)
				setTotalItems(registry.totalItems)
				return registry.data
			// }
			// return null
		},
		refetchOnWindowFocus: false
	})
}

export const useLocalEstimateStatus = () => {
	// const { project } = useAppSelector(state => state.environment)
	return useQuery({
		queryKey: ['localEstimateStatus'],
		queryFn: async () => {
				return await LocalEstimateRegistryApi.getContainerEstimateStatus().then(data => data)
		},
		refetchOnWindowFocus: false
	})
}
