import { API } from 'services/ApiService'
import { TEstimateType } from 'shared/schema'
import { IAdjustmentComment } from '..'

const url = '/v1/adjustment'
enum ROUTE {
	wbs = 'container',
	local = 'estimate'
}

export const AdjustmentCommentsApi = {
	async getAll(
		containerId: string,
		type: TEstimateType,
		isCommentsClosed?: boolean,
		myComments?: boolean
	) {
		return await API<IAdjustmentComment[]>({
			url: `${url}/${ROUTE[type]}/${containerId}/estimatePositionsComments`,
			method: 'get',
			params: { isCommentsClosed, myComments, projectId: null }
		}).then(async response => response.data)
	},

	async getAuthors(id: string, type: TEstimateType) {
		return await API<boolean>({
			url: `${url}/estimatePosition/${id}/comments/authorCommentsStatus`,
			method: 'get',
			params: {
				projectId: null
			}
		}).then(async response => response.data)
	}
}
