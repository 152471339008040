import { IUserDto, IUserPermission } from "interfaces/IUser"
import { create } from "zustand"
import { createJSONStorage, persist } from "zustand/middleware"

interface IState {
    viewer?: IUserDto
}
interface IStateActions {
    setViewer: (viewer?: IUserDto) => void
}

const initialValues: IState = {
    viewer: undefined
}

export const useViewerState = create(
    persist<IState & IStateActions>(
        set => ({
            ...initialValues,
            setViewer: viewer => {
                set(() => ({ viewer }))
            },
            unset: () => {
                set(() => ({ ...initialValues }))
            }
        }),
        {
            name: 'viewer',
            storage: createJSONStorage(() => localStorage)
        }
    )
)