import { CalendarOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import { Button, Dropdown, Empty, Table, Tooltip, Typography } from 'antd'
import { ColumnsType } from 'antd/es/table'
import dayjs from 'dayjs'
import { useAppDispatch } from 'hooks/appReduxHook'
import { ITrafficLightInfo, ITrafficLightReport } from 'interfaces/ILightReport'
import OrdinanceDrawer from 'pages/ordinancePage/ordinanceDrawer'
import { FC, ReactNode, useEffect, useRef, useState } from 'react'
import { getChecklist } from 'services/ChecklistsService'
import { uniqueSet } from 'services/helperService'
import { TEMPLATE_TYPE } from 'shared/constants/checklist-types'
import { setCurrentChecklist, setCurrentChecklistId } from 'store/checklistsSlice'
import { ChecklistCard, useChecklistCardState } from 'widgets/checklist-card'

interface ITrafficLightTableProps {
	data: ITrafficLightReport[]
}

const { Title } = Typography

const TrafficLightTable: FC<ITrafficLightTableProps> = ({ data }) => {
	const openChecklist = useChecklistCardState(state => state.setCurrentChecklist)
	const reportTemplateRef = useRef(null)
	const [openProject, setOpenProject] = useState<string | null>(null)
	const [selectedChecklist, setSelectedChecklist] = useState<string | null>(null)
	const [showOrdinanceDetail, setShowOrdinanceDetail] = useState(false)
	const [selectedOrdinance, setSelectedOrdinance] = useState<string | null>(null)
	const dispatch = useAppDispatch()
	const trafficCircleOrdinance = (
		<div
			style={{
				width: 16,
				height: 16,
				borderRadius: 8,
				border: '1px solid rgba(0,0,0,0.25)',
				background: `radial-gradient(farthest-corner at 6px 6px, #000000, #000000)`
			}}
		/>
	)
	const columns: ColumnsType<ITrafficLightReport> = [
		{
			align: 'center',
			width: '64px',
			fixed: 'left',
			ellipsis: true,
			render: (_: any, record) => (
				<Tooltip
					title={`Степень критичности: ${record.template!?.checkBoxUk! ? 'Высокая' : 'Низкая'}`}
				>
					<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
						{record.template!?.checkBoxUk! ? (
							<ExclamationCircleOutlined
								style={{
									fontSize: '24px',
									color: '#ff4d4f',
									padding: '.3rem',
									cursor: 'help'
								}}
							/>
						) : (
							false
						)}
					</div>
				</Tooltip>
			)
		},
		{
			title: 'Системы / Испытания',
			width: '30vw',
			fixed: 'left',
			ellipsis: true,
			render: (_: any, record) => (
				<span style={{ wordBreak: 'break-word', textAlign: 'left' }}>
					{record!?.template!?.order! && `${record!?.template!?.order!}. `}
					{record!?.template!?.name!}
				</span>
			)
		}
	]

	const masterData: Array<{
		project: { id: string; name: string }
		data: ITrafficLightInfo[]
	}> = []

	data!?.forEach(item => {
		if (masterData.every(elem => elem.project?.id !== item.template.project?.id)) {
			const all = data!
				?.filter(prj => prj.template.project?.id === item.template.project?.id)
				.map(d => d.template!?.sections!)
			const sections = Math.max.apply(null, all)

			masterData.push({
				project: item.template.project,
				data: Array.from(Array(sections + 1).keys()).map(i => ({
					critical: undefined,
					checkState: undefined,
					dueDate: undefined,
					section: i,
					ordinances: item.total?.ordinances
				}))
			})
		}
	})

	useEffect(() => {
		const trafficProjects = new Set(data.flatMap(md => md.template.project?.id))
		if (trafficProjects.size === 1) {
			const id = [...trafficProjects][0]
			setOpenProject(id)
		}
	}, [data])

	const checkStateView = (state: number) => {
		switch (state) {
			case 1:
				return '#faad14, #b96b05'
			case 2:
				return '#52c41a, #076751'
			case 3:
				return '#1677ff, #093a7e'
			default:
				return '#ff0000, #990003'
		}
	}

	interface IChecklistsMenuProps {
		id: string
		title: string
	}

	const getChecklistItems = (
		templateId: string,
		projectId: string,
		section: number,
		checkState: number
	): ReactNode => {
		const items: IChecklistsMenuProps[] = []

		const currentTemplate = data!?.find(
			d => d!?.template!?.id! === templateId! && d?.template?.project?.id === projectId
		)!

		let checklists: ITrafficLightInfo[] = []

		if (currentTemplate) {
			if (currentTemplate && currentTemplate!?.data!)
				checklists =
					(uniqueSet(currentTemplate!?.data!, 'checkListId') as ITrafficLightInfo[]) || []
			if (section === 0 && currentTemplate.template.sections !== null) {
				checklists
					.filter(cl => cl.checkListId !== null)!
					?.sort((a, b) => a.section! - b.section!)
					.forEach(item =>
						items!.push({
							id: item.checkListId!,
							title:
								checklists!?.length! > 1
									? item.section! === 0
										? `Корпус: ${item.title!}`
										: `Секция ${currentTemplate.data
												.filter(d => d.checkListId === item.checkListId)
												.sort((a, b) => a.section! - b.section!)
												.flatMap(d => d.section)
												.join(', ')}: ${item.title!}`
									: item.title!
						})
					)
			} else if (!!currentTemplate.total.checkLists.length) {
				currentTemplate.total.checkLists.forEach(cl =>
					items!.push({
						id: cl.checkListId,
						title: cl.title
					})
				)
			} else {
				checklists!?.find(ch => ch.section === section) &&
					items!.push({
						id: checklists!?.find(ch => ch.section === section)!?.checkListId!,
						title: checklists!?.find(ch => ch.section === section)!?.title!
					})
			}
		}

		const trafficCircle = (
			<div
				style={{
					width: 16,
					height: 16,
					borderRadius: 8,
					border: '1px solid rgba(0,0,0,0.25)',
					background: `radial-gradient(farthest-corner at 6px 6px, ${checkStateView(checkState)})`
				}}
			/>
		)

		if (items!?.length! > 1 && (section === 0 || currentTemplate.template.sections === null)) {
			return (
				<Dropdown
					onOpenChange={open => !open}
					dropdownRender={() => (
						<div className="at-menu">
							{items.map(i => (
								<Button
									style={{ textAlign: 'left', whiteSpace: 'break-spaces' }}
									block
									type="link"
									onClick={() => openChecklist(i.id, TEMPLATE_TYPE.ukkc)}
								>
									{i.title}
								</Button>
							))}
						</div>
					)}
					arrow
				>
					<Button className="app-btn-cover" ghost>
						{trafficCircle}
					</Button>
				</Dropdown>
			)
		} else if (
			items!?.length! === 1 &&
			(section === 0 || currentTemplate.template.sections === null)
		) {
			return (
				<Button
					className="app-btn-cover"
					ghost
					onClick={() => openChecklist(items[0].id, TEMPLATE_TYPE.ukkc)}
				>
					{trafficCircle}
				</Button>
			)
		} else if (
			section !== 0 &&
			currentTemplate.data.find(c => c.section === section)?.checkListId !== null
		) {
			return (
				<Button
					className="app-btn-cover"
					ghost
					onClick={() => openChecklist(items[0].id, TEMPLATE_TYPE.ukkc)}
				>
					{trafficCircle}
				</Button>
			)
		} else return trafficCircle
	}

	const dynamic: ColumnsType<ITrafficLightReport> = masterData.map(item => ({
		title: (
			<div className="report-table-sticky">
				<Button
					type={openProject === item.project?.id ? 'primary' : 'default'}
					onClick={() =>
						setOpenProject(prev => (prev === item.project?.id ? null : item.project?.id))
					}
				>
					{item.project.name}
				</Button>
			</div>
		),
		width: '70%',
		children: [...item.data]
			.sort((a, b) => a.section! - b.section!)
			.flatMap(elem =>
				[
					{
						title: 'Неустраненные предписания',
						width: 220,
						ellipsis: true,
						align: 'center',
						visible: elem!?.section! === 0,
						className: elem!?.section! === 0 && 'at-traffic-column-total',
						render: (_: any, record: ITrafficLightReport) => (
							<span
								style={{
									wordBreak: 'break-word',
									textAlign: 'left'
								}}
							>
								{uniqueSet(
									data!?.find(
										d =>
											d!?.template!?.id! === record!?.template!?.id! &&
											d.template.project?.id === item!?.project!?.id!
									)!?.total.ordinances!,
									'id'
								)?.length === 1 &&
								data!?.find(
									d =>
										d!?.template!?.id! === record!?.template!?.id! &&
										d.template.project?.id === item!?.project!?.id!
								)!?.total.ordinances ? (
									// uniqueSet(
									// 	record.data?.filter(
									// 		o => !!o.ordinances?.length && record?.template?.project?.id === item.project?.id
									// 	),
									// 	'checkListId'
									// )?.length === 1
									<Button
										className="app-btn-cover"
										style={{ color: 'black' }}
										ghost
										onClick={() =>
											openOrdinance(
												uniqueSet(
													data!?.find(
														d =>
															d!?.template!?.id! === record!?.template!?.id! &&
															d.template.project?.id === item!?.project!?.id!
													)!?.total.ordinances!,
													'id'
												)?.length === 1
													? uniqueSet(
															data!?.find(
																d =>
																	d!?.template!?.id! === record!?.template!?.id! &&
																	d.template.project?.id === item!?.project!?.id!
															)!?.total.ordinances!,
															'id'
													  )[0]?.id!
													: null
											)
										}
									>
										1
									</Button>
								) : uniqueSet(
										data!?.find(
											d =>
												d!?.template!?.id! === record!?.template!?.id! &&
												d.template.project?.id === item!?.project!?.id!
										)!?.total.ordinances!,
										'id'
								  )?.length > 1 &&
								  data!?.find(
										d =>
											d!?.template!?.id! === record!?.template!?.id! &&
											d.template.project?.id === item!?.project!?.id!
								  )!?.total.ordinances ? (
									<Dropdown
										onOpenChange={open => !open}
										dropdownRender={() => (
											<div className="at-menu">
												{uniqueSet(
													data!?.find(
														d =>
															d!?.template!?.id! === record!?.template!?.id! &&
															d.template.project?.id === item!?.project!?.id!
													)!?.total.ordinances!,
													'id'
												)?.map(i => (
													<Button
														style={{ textAlign: 'left', whiteSpace: 'break-spaces' }}
														block
														type="link"
														onClick={() => openOrdinance(i?.id!)}
													>
														{`Предписание №${i?.name!}`}
													</Button>
												))}
											</div>
										)}
										arrow
									>
										<Button className="app-btn-cover" ghost style={{ color: 'black' }}>
											{
												uniqueSet(
													data!?.find(
														d =>
															d!?.template!?.id! === record!?.template!?.id! &&
															d.template.project?.id === item!?.project!?.id!
													)!?.total.ordinances!,
													'id'
												)?.length
											}
										</Button>
									</Dropdown>
								) : null}
							</span>
						)
					},
					{
						title: elem!?.section! > 0 ? `Секция ${elem!?.section!}` : 'Корпус',
						visible: elem!?.section! === 0 || openProject! === item.project?.id,
						className:
							elem!?.section! === 0
								? 'at-traffic-column-total'
								: elem!?.section! % 2 !== 0 && 'at-traffic-column-even',
						width: 100,
						align: 'center',
						ellipsis: true,
						render: (_: any, record: ITrafficLightReport) => {
							const checkState: number | undefined =
								elem!?.section! > 0
									? data!
											?.find(
												d =>
													d!?.template!?.id! === record!?.template!?.id! &&
													d.template.project?.id === item!?.project!?.id!
											)!
											?.data!?.find(dd => dd!?.section! === elem!?.section!)!?.checkState!
									: data!.find(
											d =>
												d!?.template!?.id! === record!?.template!?.id! &&
												d.template.project?.id === item!?.project!?.id!
									  )!?.total!?.checkState!

							const criticalLevel: number | undefined =
								elem!?.section! > 0
									? data!
											?.find(
												d =>
													d!?.template!?.id! === record!?.template!?.id! &&
													d.template.project?.id === item!?.project!?.id!
											)!
											?.data!?.find(dd => dd!?.section! === elem!?.section!)!?.critical!
									: data!.find(
											d =>
												d!?.template!?.id! === item!?.project!?.id! &&
												d.template.project?.id === record!?.template!?.project?.id
									  )!?.total!?.critical!

							// Проверка есть ли вообще информация о	ячейке
							return typeof checkState !== 'undefined' &&
								data.some(
									d =>
										d!?.template!?.id! === record!?.template!?.id! &&
										d!?.template!?.project!?.id! === item!?.project!?.id!
								) ? (
								<div
									style={{
										width: '100%',
										height: '100%',
										position: 'absolute',
										top: 0,
										left: 0,
										border: `3px solid ${
											checkState !== 2
												? criticalLevel! === 1
													? '#ff4d4f'
													: criticalLevel! === 0
													? '#8846b6'
													: 'transparent'
												: 'transparent'
										}`,
										display: 'flex',
										justifyContent: 'center',
										alignItems: 'center',
										whiteSpace: 'break-spaces'
									}}
								>
									{getChecklistItems(
										record!?.template!?.id!,
										item.project?.id,
										elem!?.section!,
										checkState!
									)}
								</div>
							) : (
								record!?.template.sections !== null && elem!?.section! === 0 && (
									<div
										style={{
											width: '100%',
											height: '100%',
											position: 'absolute',
											top: 0,
											left: 0,
											border: `3px solid transparent`,
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
											whiteSpace: 'break-spaces'
										}}
									>
										<div
											style={{
												width: 16,
												height: 16,
												borderRadius: 8,
												border: '1px solid rgba(0,0,0,0.25)',
												background: `radial-gradient(farthest-corner at 6px 6px, ${checkStateView(
													0
												)})`
											}}
										/>
									</div>
								)
							)
						}
					},
					{
						title: (
							<Tooltip title="Планируемая дата устранения">
								<CalendarOutlined />
							</Tooltip>
						),
						ellipsis: true,
						visible: elem!?.section! === 0 || openProject! === item.project?.id,
						className:
							elem!?.section! === 0
								? 'at-traffic-column-total'
								: elem!?.section! % 2 !== 0 && 'at-traffic-column-even',
						width: 100,
						align: 'center',
						render: (_: any, record: ITrafficLightReport) => {
							const checkState: number | undefined =
								elem!?.section! > 0
									? data!
											?.find(
												d =>
													d!?.template!?.id! === record!?.template!?.id! &&
													d.template.project?.id === item!?.project!?.id!
											)!
											?.data!?.find(dd => dd!?.section! === elem!?.section!)!?.checkState!
									: data!.find(
											d =>
												d!?.template!?.id! === record!?.template!?.id! &&
												d.template.project?.id === item!?.project!?.id!
									  )!?.total!?.checkState!

							const cell =
								elem!?.section! > 0
									? data!
											?.find(
												d =>
													d.template.project?.id === item.project?.id &&
													d.template.id === record.template.id
											)!
											?.data!?.find(s => s.section === elem.section)
									: data!?.find(
											d =>
												d.template.project?.id === item.project?.id &&
												d.template.id === record.template.id
									  )!?.total!

							const isBySections =
								data.find(d => d.template.id === record.template.id)?.template.sections !== null

							const checklists =
								data.find(d => d.template.id === record.template.id)?.total.checkLists.length ?? 0

							const dueDateInit = cell!?.dueDate?.init!
								? dayjs(cell?.dueDate.init!).format('DD.MM.YYYY')!
								: '-'
							const dueDateUpdated =
								cell!?.dueDate?.updated! && dayjs(cell?.dueDate?.updated!).format('DD.MM.YYYY')!
							const templatePlannedDate = data?.find(d => d.template.id === record.template.id)
								?.template.plannedDate!
							const plannedDate = dayjs(templatePlannedDate!).isValid()
								? dayjs(templatePlannedDate)
								: null
							return (
								<div
									style={{
										display: 'flex',
										flexDirection: 'column'
									}}
								>
									<span
										style={{
											color: cell!?.dueDate?.init!
												? (checkState === 0 || checkState === 1) &&
												  (dayjs(cell!?.dueDate?.init!).isBefore(dayjs()) ||
														(plannedDate?.isValid()
															? dayjs(cell!?.dueDate?.init!).isAfter(plannedDate)
															: dayjs(cell!?.dueDate?.init!).isBefore(dayjs())) ||
														(isBySections &&
															checklists === 0 &&
															dayjs(cell!?.dueDate?.init!).isBefore(dayjs())))
													? 'red'
													: 'black'
												: 'gray'
										}}
									>
										{dueDateInit}
									</span>
									<span
										style={{
											color: cell!?.dueDate?.updated!
												? (checkState === 0 || checkState === 1) &&
												  (dayjs(cell!?.dueDate?.updated!).isBefore(dayjs()) ||
														(plannedDate?.isValid()
															? dayjs(cell!?.dueDate?.updated!).isAfter(plannedDate)
															: dayjs(cell!?.dueDate?.updated!).isBefore(dayjs())) ||
														(isBySections &&
															checklists === 0 &&
															dayjs(cell!?.dueDate?.updated!).isBefore(dayjs())))
													? 'red'
													: 'black'
												: 'gray'
										}}
									>
										{dueDateUpdated}
									</span>
								</div>
							)
						}
					}
				].filter(item => item.visible)
			)
	}))

	if (Array.isArray(dynamic)) columns.push(...(dynamic as any[]))

	// const checkPermissions = (currentPermits: string[]) => {
	// 	for (let i = 0; i < currentPermits.length; i++) {
	// 		if (permissions?.some((el: IPermission) => el.name === currentPermits[i])) {
	// 			return true
	// 		}
	// 	}
	// 	return false
	// }
	const onRefreshData = () => {
		getChecklist(selectedChecklist!).then(res => {
			dispatch(
				setCurrentChecklist({
					...res,
					period: dayjs(res.period),
					plannedDate: res.plannedDate && dayjs(res.plannedDate)
				})
			)
		})
	}

	useEffect(() => {
		if (selectedChecklist !== null) {
			dispatch(setCurrentChecklistId(selectedChecklist))
			onRefreshData()
		}
	}, [selectedChecklist])

	const viewData: ITrafficLightReport[] = []
	data?.forEach(item => {
		if (viewData.every(x => x.template.id !== item.template.id) || viewData.length === 0)
			viewData.push(item)
	})

	const openOrdinance = (ordinanceId: string) => {
		setSelectedOrdinance(ordinanceId)
		setShowOrdinanceDetail(true)
	}

	const closeOrdinance = () => {
		setSelectedOrdinance(null)
		setShowOrdinanceDetail(false)
	}

	return (
		<>
			{Array.isArray(dynamic) ? (
				<>
					{showOrdinanceDetail && selectedOrdinance !== null && (
						<OrdinanceDrawer
							ordinanceId={selectedOrdinance}
							open={showOrdinanceDetail}
							onClose={closeOrdinance}
						/>
					)}
					<ChecklistCard />
					{/* <ChecklistsDrawer
						open={selectedChecklist !== null}
						onClose={() => setSelectedChecklist(null)}
						// onRefreshData={onRefreshData}
						// checkPermissions={checkPermissions}
					/> */}
					<Table
						id="reportTemplate"
						ref={reportTemplateRef}
						className="report-table"
						size="small"
						pagination={false}
						columns={columns}
						dataSource={[...viewData].sort((a, b) => {
							if (a.template?.order === null) return 1
							if (b.template?.order === null) return -1
							if (a.template?.order === b.template?.order) return 0
							return a < b ? -1 : 1
						})}
						bordered
						scroll={{ x: '100%', y: 480 }}
					/>
				</>
			) : (
				<Empty
					description={
						<Title level={4} style={{ color: 'red' }}>
							Отчет невозможно сформировать по выбранным проектам.
							<br />
							Отсутствуют шаблоны чек-листов.
						</Title>
					}
				/>
			)}
		</>
	)
}
export default TrafficLightTable
