import { Tabs } from 'antd'
import { useAppSelector } from 'hooks/appReduxHook'
import { FC, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { RegulatoryRequest } from 'widgets/regulatory-request'
import { WbsStatusModal, useWbsPermits } from 'widgets/wbs'
import { LocalEstimatePage } from './LocalEstimatePage'
import { WbsPage } from './WbsPage'
import { useLocalEstimatePageState, useWbsPageState } from './model/estimate-page-state'

type TTabState = 'unmodel' | 'local'

interface IProps {
	init: TTabState
}

export const EstimatesPage: FC<IProps> = props => {
	const navigate = useNavigate()
	const user = useAppSelector(state => state.environment.user)
	const [activeTab, setActiveTab] = useState<TTabState>(props.init)
	const wbsPageState = useWbsPageState()
	const localEstimatePageState = useLocalEstimatePageState()
	const {
		canLocalView,
		canLocalEdit,
		canLocalExternal,
		canLocalExport,
		canLocalImport,
		canLocalDzMTR,
		canLocalDzSMR,
		canLocalSdu,
		...wbsPermits
	} = useWbsPermits()

	const onTabChange = (value: TTabState) => {
		setActiveTab(value)
		navigate(`/${value}`)
	}

	useEffect(() => {
		if (!Object.values(wbsPermits).some(p => p === true)) {
			onTabChange('local')
		}
	}, [])

	return (
		<>
			<WbsStatusModal type={activeTab === 'unmodel' ? 'wbs' : 'local'} />
			<RegulatoryRequest />
			<Tabs
				style={{ width: '100%' }}
				activeKey={activeTab}
				tabBarGutter={16}
				onChange={key => onTabChange(key as TTabState)}
				// destroyInactiveTabPane={true}
				items={[
					{
						label: 'ВОР',
						key: 'unmodel',
						children: (
							<WbsPage state={wbsPageState} type={activeTab === 'unmodel' ? 'wbs' : 'local'} />
						),
						disabled: !Object.values(wbsPermits).some(p => p === true)
					},
					{
						label: 'Локальные сметы',
						key: 'local',
						children: (
							<LocalEstimatePage
								state={localEstimatePageState}
								type={activeTab === 'unmodel' ? 'wbs' : 'local'}
							/>
						),
						disabled:
							!canLocalEdit &&
							!canLocalView &&
							!user?.isAdmin &&
							!canLocalExternal &&
							!canLocalDzMTR &&
							!canLocalDzSMR &&
							!canLocalSdu
					}
				]}
			/>
		</>
	)
}
