import { IPagination } from 'interfaces/IPagination'
import { IRole } from 'interfaces/IPermissions'
import { API } from 'services/ApiService'

interface IWbsStatusRegistryDto {
	id: string
	key: string
	name: string | null
	description: string | null
	color: string | null
	isDeleted: boolean
	order: number
	visas: IWbsVisa[]
}
export interface IWbsStatusRegistry extends Omit<IWbsStatusRegistryDto, 'visas'> {
	children: IWbsVisaWithParent[]
}

export interface IWbsVisaWithParent extends IWbsVisa {
	parentId: string
}

export interface IWbsVisa {
	id: string
	key: string
	name: string | null
	isDeleted: boolean
	roles: IRole[] | null
}

interface IVisaDto {
	name: string
	rolesId: string[]
}

interface IWbsStatusDto {
	name: string
	description: string | null
	color: string | null
	order: number
	visasId: string[] | null
}

export interface IWbsRole {
	id: string
	name: string | null
	description: string | null
	permissions: IWbsRolePermission[] | null
}
interface IWbsRolePermission {
	id: number
	name: string | null
	synonym: string | null
	group: string | null
}

export const WbsStatusApi = {
	async getRegistry(isLocal?: boolean, isAdjustment?: boolean) {
		const url = isLocal ? 'containerEstimateStatus' : 'containerStatus'
		const adjustmentUrl = isLocal ? 'adjustmentEstimateStatus' : 'adjustmentContainerStatus'
		return await API<IPagination<IWbsStatusRegistryDto[]>>({
			url: `/v1/${isAdjustment ? adjustmentUrl : url}`,
			method: 'get',
			params: {
				projectId: null
			}
		}).then(response => {
			const registry: IPagination<IWbsStatusRegistry[]> = {
				data: response.data.data.map(item => ({
					id: item.id,
					key: item.key,
					name: item.name,
					description: item.description,
					color: item.color,
					isDeleted: item.isDeleted,
					order: item.order,
					children: item.visas.map(v => ({
						...v,
						parentId: item.id
					}))
				})),
				totalItems: response.data.totalItems,
				totalPages: response.data.totalPages
			}
			return registry
		})
	},
	async getStatus() {},
	async addStatus() {},
	async updateStatus(id: string, dto: IWbsStatusDto, isLocal?: boolean, isAdjustment?: boolean) {
		const url = isLocal ? 'containerEstimateStatus' : 'containerStatus'
		const adjustmentUrl = isLocal ? 'adjustmentEstimateStatus' : 'adjustmentContainerStatus'
		return await API({
			url: `/v1/${isAdjustment ? adjustmentUrl : url}/${id}`,
			method: 'patch',
			params: {
				projectId: null
			},
			data: dto
		}).then(response => response.data)
	},
	async removeStatus() {},
	async getVisaRegistry(ExceptVisasWithContainerStatusId?: string) {
		return await API<IPagination<IWbsVisa[]>>({
			url: `/v1/visa`,
			method: 'get',
			params: { ExceptVisasWithContainerStatusId }
		}).then(response => response.data)
	},
	async addVisa(dto: IVisaDto) {
		return await API<string>({
			url: `/v1/visa`,
			method: 'post',
			data: dto
		}).then(response => response.data)
	},
	async updateVisa(visaId: string, dto: IVisaDto) {
		return await API<string>({
			url: `/v1/visa/${visaId}`,
			method: 'patch',
			data: dto
		}).then(response => response.data)
	},
	async removeVisa(visaId: string) {
		return await API({
			url: `/v1/visa/${visaId}`,
			method: 'delete'
		}).then(response => response.status)
	},
	async getRoles() {
		return await API<IWbsRole[]>({
			url: `/v1/roles`,
			method: 'get',
			// params: { PermissionsId: [45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 56, 57] }
		}).then(response => response.data)
	}
}
