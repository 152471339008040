import dayjs from 'dayjs'
import { IProject } from 'interfaces/IEnvironment'
import { IOrdinance } from 'interfaces/IOrdinance'
import { IFileAttachment } from 'interfaces/IPluginFiles'
import { IAppUser } from 'interfaces/IUser'
import { getNearestWorkingDate } from 'shared/helpers'
import { ordinanceFineApi } from 'widgets/fine/api/fine-api'
import { ICreateOrdinanceFine, IFineForm } from 'widgets/fine/model/fine-interface'
import { create } from 'zustand'
import { IFine, TFineCardType } from './fine-card-interface'

interface IFineState {
	create?: IFine | string | undefined
	edit?: IFine | string | undefined
	inner?: IFine | string | undefined
	cancelationModalView: boolean
	attachment?: IFileAttachment
	setCancelationModalView: (state: boolean) => void
	setId: (type: TFineCardType, id: string) => void
	setData: (type: TFineCardType, data: IFine) => void
	setToCreate: (ordinance?: IOrdinance, project?: IProject, user?: IAppUser) => void
	unset: (type: TFineCardType) => void
	onSave: (form: IFineForm, fine: IFine, id?: string) => Promise<boolean>
	setAttachment:(attachment?:IFileAttachment) => void
}

export const useFineState = create<IFineState>((set, get) => ({
	cancelationModalView: false,
	setCancelationModalView: state => set(() => ({ cancelationModalView: state })),
	setId: (type, id) => {
		const obj = get()[type]
		if (typeof obj !== 'object') set(() => ({ [type]: id }))
	},
	setData: (type, data) => set(() => ({ [type]: data })),
	setToCreate: (ordinance, project, user) => {
		if (ordinance && project && user) {
			const prep: IFine = {
				ordinance: {
					id: ordinance.id,
					name: ordinance.name ?? '',
					number: ordinance.number,
					createdAt: ordinance.createdAt
				},
				project: project,
				createdBy: {
					id: ordinance.createdBy.id!,
					name: `${ordinance.createdBy.name}`
				},
				contractor: ordinance.contractor,
				subContractor: ordinance.subContractor,
				contract: ordinance.contract,
				responsibleManager: project.responsibleManager!,
				phase: project.phase ?? '',
				building: project.building ?? '',
				sum:
					ordinance.ordinanceFinesCount > 0
						? ordinance.contract.ordinanceFineRepeatSum
						: ordinance.contract.ordinanceFineFirstSum,
				isRepeated: false,
				isAutoCreated: false,
				name: null,
				cancelationReason: '',
				number: 0,
				dateTo: dayjs().add((dayjs(ordinance.dateTo).startOf('day')).diff((dayjs(ordinance.createdAt).startOf('day')), 'day'), 'day').format('DD-MM-YYYY')
			}
			set(() => ({ create: prep }))
		}
	},
	onSave: async (form, fine, id) => {
		if (!id) {
			const createDto: ICreateOrdinanceFine = {
				ordinanceId: fine.ordinance.id,
				dateTo: getNearestWorkingDate('dto'),
				sum: fine.sum
			}
			const req = await ordinanceFineApi.create(createDto)
			return req === 200
		} else {
			await ordinanceFineApi.updateCard(id, { ordinanceFineStatusId: form.status, dateTo: fine.dateTo,
			sum: fine.sum })
		}
		return false
	},
	unset: type => {
		set(() => ({ [type]: undefined }))
	},
	setAttachment: (attachment) => set(()=>({attachment: attachment}))
}))
