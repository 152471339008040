import { Form, Input, Modal } from 'antd'
import modal from 'antd/es/modal'
import { AxiosError } from 'axios'
import { useAppSelector } from 'hooks/appReduxHook'
import { IErrorDetail } from 'interfaces/IBase'
import { FC, memo, useEffect, useMemo } from 'react'
import { CONTAINER_STATUS, PERMISSIONS } from 'shared/constants'
import { useCheckPermissions } from 'shared/useCheckPermissions'
import { IAdjustmentNote, IAdjustmentPositionCommentsUpdate, adjustmentApi } from '..'
import { AdjustmentCol } from '../model/adjustment-helper'
import { useAdjustmentState } from '../model/adjustment-state'

interface IProps {
	visaIssued: boolean
}

export const AdjustmentNote: FC<IProps> = memo(function ({ visaIssued }) {
	const checkPermissions = useCheckPermissions()
	const { user } = useAppSelector(state => state.environment)
	const { isLoading, container, note, unSetNote, updateSections } = useAdjustmentState()
	const [estimateNoteForm] = Form.useForm()
	const onClose = () => {
		estimateNoteForm.resetFields()
		unSetNote()
	}
	enum NOTE_TYPE {
		commentSdu = 'Обоснование',
		justification = 'Обоснование ЕР',
		noteSdu = 'Примечание СДУ',
		noteDz = 'Примечание ДЗ',
		noteDp = 'Примечание УПВОР'
	}

	useEffect(() => {
		note?.main.value && estimateNoteForm.setFieldValue('value', note.main.value)
	}, [note])

	const getPosition = () => {
		switch (true) {
			case note && 'materialName' in note.row && note.main.type === 'commentSdu':
				return AdjustmentCol.EstimatePositionAdjustmentMaterialComment
			case note && 'materialName' in note.row && note.main.type === 'justification':
				return AdjustmentCol.EstimatePositionAdjustmentMaterialJustification
			case note && 'workName' in note.row && note.main.type === 'justification':
				return AdjustmentCol.EstimatePositionAdjustmentJustification
			default:
				return AdjustmentCol.EstimatePositionAdjustmentComment
		}
	}

	const onSaveNote = async (values: any) => {
		if (note) {
			if (note.main.type === 'commentSdu' || note.main.type === 'justification') {
				const dto: IAdjustmentPositionCommentsUpdate = {
					estimatePositionId:
						'materialName' in note.row ? note.row.estimatePositionId : note.row.id,
					estimatePositionAdjustmentProps: getPosition(),
					estimatePositionMaterialId: 'materialName' in note.row ? note.row.id : null,
					value: values.value
				}
				await adjustmentApi
					.saveStatementComments(
						dto,
						typeof container?.isLocalEstimate === 'undefined' ? 'wbs' : 'local',
						note.main.type
					)
					.then(() => {
						updateSections(note.row.containerSectionId)
						onClose()
					})
					.catch((err: AxiosError<IErrorDetail>) =>
						modal.error({
							title: 'Произошла ошибка',
							content:
								err.response?.data.detail ?? 'Неизвестная ошибка. Обратитесь в службу поддержки'
						})
					)
			} else {
				const dto: IAdjustmentNote = {
					estimatePositionId:
						'materialName' in note.row ? note.row.estimatePositionId : note.row.id,
					materialId: 'materialName' in note.row ? note.row.id : null,
					value: values.value
				}
				await adjustmentApi
					.saveNote(
						dto,
						typeof container?.isLocalEstimate === 'undefined' ? 'wbs' : 'local',
						note.main.type
					)
					.then(() => {
						updateSections(note.row.containerSectionId)
						onClose()
					})
					.catch((err: AxiosError<IErrorDetail>) =>
						modal.error({
							title: 'Произошла ошибка',
							content:
								err.response?.data.detail ?? 'Неизвестная ошибка. Обратитесь в службу поддержки'
						})
					)
			}
		}
	}

	const canEdit = useMemo<boolean>(
		() =>
			!container?.ready &&
			(user?.isAdmin ||
				(checkPermissions(
					[PERMISSIONS.AdjustmentContainerChangeNoteVOR],
					container?.project?.id!
				) &&
					typeof container?.isLocalEstimate === 'undefined' &&
					note?.main.type === 'commentSdu' &&
					container?.status.name === CONTAINER_STATUS.preparation) ||
				(checkPermissions([PERMISSIONS.AdjustmentContainerChangeNoteLS], container?.project?.id!) &&
					container?.isLocalEstimate === false &&
					note?.main.type === 'commentSdu' &&
					container?.status.name === CONTAINER_STATUS.preparation) ||
				(checkPermissions(
					[PERMISSIONS.AdjustmentContainerChangeNoteVOREP],
					container?.project?.id!
				) &&
					typeof container?.isLocalEstimate === 'undefined' &&
					note?.main.type === 'justification' &&
					container?.status.name === CONTAINER_STATUS.priceApproval) ||
				(checkPermissions(
					[PERMISSIONS.AdjustmentContainerChangeNoteLSEP],
					container?.project?.id!
				) &&
					container?.isLocalEstimate === false &&
					note?.main.type === 'justification' &&
					container?.status.name === CONTAINER_STATUS.priceApproval)),
		[note]
	)

	return (
		<Modal
			open={typeof note !== 'undefined'}
			width={768}
			destroyOnClose
			centered
			title={`${note && NOTE_TYPE[note.main.type]} - ${
				note?.main.isWork ? 'Работа' : 'Материал'
			}: ${note?.main.name}`}
			onCancel={onClose}
			onOk={() =>
				!container?.ready &&
				(((note?.main.type === 'commentSdu' || note?.main.type === 'justification') && canEdit) ||
					(note?.main.type !== 'commentSdu' &&
						note?.main.type !== 'justification' &&
						!note?.main.disabled)) &&
				!visaIssued
					? estimateNoteForm.submit()
					: onClose()
			}
			okButtonProps={{ htmlType: 'submit', loading: isLoading }}
		>
			<Form form={estimateNoteForm} name="estimateNoteForm" onFinish={onSaveNote}>
				<Form.Item name="value">
					<Input.TextArea
						disabled={
							container?.ready ||
							(note?.main.type !== 'commentSdu' &&
								note?.main.type !== 'justification' &&
								note?.main.disabled) ||
							((note?.main.type === 'commentSdu' || note?.main.type === 'justification') &&
								!canEdit) ||
							visaIssued
						}
						allowClear
						autoSize
						showCount
						maxLength={5000}
						placeholder="Примечание"
						style={{ marginBottom: '1rem' }}
						styles={{ textarea: { minHeight: 256 } }}
					/>
				</Form.Item>
			</Form>
		</Modal>
	)
})
