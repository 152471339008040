import { FormInstance, Checkbox, InputNumber, Form } from 'antd'
import { TContractGuide, TContractsGuideForm } from '../model/contracts-guide-schema'

type TContractsGuideCellProps = React.HTMLAttributes<HTMLElement> & {
	editing: boolean
	dataIndex: string
	title: any
	record: TContractGuide
	index: number
	children: React.ReactNode
	contractsForm: FormInstance<TContractsGuideForm>
}

export const ContractsGuideCell = ({
	editing,
	dataIndex,
	title,
	record,
	index,
	children,
	contractsForm,
	...restProps
}: TContractsGuideCellProps) => {
	const inputNode =
		dataIndex === 'ordinanceFineFirstSum' ||
		dataIndex === 'ordinanceFineRepeatSum' ||
		dataIndex === 'isNotOrdinanceFine' ? (
			dataIndex === 'isNotOrdinanceFine' ? (
				<Checkbox
					defaultChecked={record.isNotOrdinanceFine}
					onChange={value =>
						contractsForm.setFieldValue('isNotOrdinanceFine', value.target.checked)
					}
				/>
			) : (
				<InputNumber
					keyboard={false}
					controls={false}
					style={{ width: '100%' }}
					parser={value => value!.replace(',', '.')}
				/>
			)
		) : (
			''
		)

	return (
		<td {...restProps}>
			{editing ? (
				<Form.Item
					name={dataIndex}
					rules={[
						{
							required: dataIndex !== 'isNotOrdinanceFine',
							message: `Укажите ${title.toLowerCase()}`
						}
					]}
				>
					{inputNode}
				</Form.Item>
			) : (
				children
			)}
		</td>
	)
}
