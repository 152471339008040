import { Col, Row, Select } from 'antd'
import { useAppDispatch, useAppSelector } from 'hooks/appReduxHook'
import { FC, useEffect, useState } from 'react'
import { setPageTitle } from 'store/rootSlice'
import { ContractsGuide } from 'widgets/contracts-guide'
import { YandexTrackerButton } from 'widgets/yandex-tracker/ui/yandex-tracker-button'
import {
	BuildingPermitsGuide,
	ContactPersons,
	ContractorsGuide,
	NormativeActsGuide,
	ProjectsGuide,
	ViolationTypesGuide,
	ViolationsGuide,
	WbsStatusGuide,
	WorkPackagesGuide
} from './ui'
import { WbsTaskStatusRegistry } from './ui/wbs-task-status/ui/wbs-task-status-registry'

interface IGuidesPageProps {}

const guideItems = [
	{
		label: 'Пакеты СМР',
		key: 'workPackages',
		children: <WorkPackagesGuide />
	},
	{
		label: 'Типы нарушения',
		key: 'violationTypes',
		children: <ViolationTypesGuide />
	},
	{
		label: 'Нарушения',
		key: 'violations',
		children: <ViolationsGuide />
	},
	{
		label: 'Нормативные акты',
		key: 'normativeActs',
		children: <NormativeActsGuide />
	},
	{
		label: 'Разрешения на строительство',
		key: 'buildingPermits',
		children: <BuildingPermitsGuide />
	},
	{
		label: 'Договоры',
		key: 'contracts',
		children: <ContractsGuide />
	},
	{
		label: 'Контактные лица',
		key: 'contactPersons',
		children: <ContactPersons />
	},
	{
		label: 'Контрагенты',
		key: 'contractors',
		children: <ContractorsGuide />
	},
	{
		label: 'Проекты',
		key: 'projects',
		children: <ProjectsGuide />
	},
	{
		label: 'Статусы ВОР',
		key: 'wbsStatus',
		children: <WbsStatusGuide isLocal={false} />
	},
	{
		label: 'Статусы Локальных смет',
		key: 'localEstimatesStatus',
		children: <WbsStatusGuide isLocal={true} />
	},
	{
		label: 'Статусы "Было-Стало" ВОР',
		key: 'adjustmentWbsStatus',
		children: <WbsStatusGuide isLocal={false} isAdjustment={true} />
	},
	{
		label: 'Статусы "Было-Стало" Локальных смет',
		key: 'adjustmentEstimatesStatus',
		children: <WbsStatusGuide isLocal={true} isAdjustment={true} />
	},
	{
		label: 'Статусы Заявки на ВОР',
		key: 'wbsTaskStatus',
		children: <WbsTaskStatusRegistry />
	}
]

const GuidesPage: FC<IGuidesPageProps> = () => {
	const dispatch = useAppDispatch()
	const isAdmin = useAppSelector(state => state.environment.user)?.isAdmin
	const [selectedPage, setSelectedPage] = useState<string>('workPackages')
	useEffect(() => {
		dispatch(setPageTitle({ mainTitle: 'Справочники' }))
	}, [])

	const selectedChange = (value: string) => {
		setSelectedPage(value)
	}
	return (
		<>
			<div className="dashboard__block">
				<Row justify="space-between">
					{isAdmin ? (
						<Row gutter={8}>
							<Col style={{ alignContent: 'center', marginLeft: 18, fontSize: 16 }}>Раздел:</Col>
							<Col>
								<Select
									showSearch
									placeholder="Справочники"
									optionFilterProp="children"
									filterOption={(input, option) =>
										(option?.label ?? '').toLowerCase().includes(input.toLowerCase())
									}
									style={{ width: 500 }}
									value={selectedPage}
									onChange={selectedChange}
									options={guideItems.map((item, index) => ({
										key: index,
										value: item.key,
										label: item.label
									}))}
								/>
							</Col>
						</Row>
					) : (
						<Col>
							<h3>Раздел: Пакеты СМР</h3>
						</Col>
					)}

					<Row gutter={8}>
						<Col style={{ alignContent: 'center', marginRight: 18 }}>
							<YandexTrackerButton text={'Сообщить об ошибке'} />
						</Col>
					</Row>
				</Row>
				{guideItems?.map(g => g.key === selectedPage && <div key={g.key}>{g.children}</div>)}
			</div>
		</>
	)
}
export default GuidesPage
