import { LoadingOutlined } from '@ant-design/icons'
import { Card, Col, Row, Spin } from 'antd'
import React from 'react'
import { PERMISSIONS } from 'shared/constants'
import { useCheckPermissions } from 'shared/useCheckPermissions'
import {
	useContainerDashboard,
	useCountChecklistsDashboard,
	useCountContractors,
	useCountIssues,
	useCountOrdinanceFinesDashboard,
	useCountOrdinancesDashboard
} from '../model/diagrams-query'
import { useDiagramState } from '../model/diagrams-state'
import { useAppSelector } from 'hooks/appReduxHook'

export const SummaryDashboard: React.FC = () => {
	const user = useAppSelector(state => state?.environment?.user)
	const permissions = user?.projects?.flatMap(p => p?.permissions)
	const issuePermission = !!permissions?.find((p: any) => p?.name === PERMISSIONS.IssueView)

	const { data: contractorsData, isLoading: isLoadingContractors } = useCountContractors()
	const { data: ordinanceFinesValues, isLoading: isLoadingOrdinanceFines } =
		useCountOrdinanceFinesDashboard()
	const { data: containerValues, isLoading: isLoadingContainer } =
		useContainerDashboard(issuePermission)
	const { data: issue, isLoading: isLoadingIssue } = useCountIssues()
	const { data: ordinance, isLoading: isLoadingOrdinance } = useCountOrdinancesDashboard()
	const { data: checklistData, isLoading: isLoadingChecklist } = useCountChecklistsDashboard()
	const loader = <LoadingOutlined style={{ fontSize: '48px', color: 'white' }} spin />

	// const cardStyle = {
	// 	background: '#ffffff',
	// 	textAlign: 'center' as const,
	// 	borderRadius: '8px',
	// 	boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
	// 	padding: '8px',
	// 	height: '155px',
	// 	display: 'flex',
	// 	flexDirection: 'column' as const,
	// 	justifyContent: 'center'
	// }

	const cardStyle = {
		border: '1px solid #ddd',
		borderRadius: 8,
		padding: 8,
		background: '#1677FF',
		boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
		textAlign: 'center' as const,
		height: '180px',
		// width: '131px',
		display: 'flex',
		flexDirection: 'column' as const,
		justifyContent: 'center',
		alignItems: 'center'
	}

	const numberStyle = {
		fontSize: '1.9vw',
		// fontWeight: 'bold' as const,
		color: 'white',
		margin: '5px',
		fontWeight: 600
	}

	const labelStyle = {
		fontSize: '0.8vw',
		color: 'white',
		fontWeight: 400
	}

	return (
		<Row gutter={[14, 14]} style={{ height: '100%' }}>
			{
				<Col span={12}>
					<Card style={cardStyle}>
						{isLoadingContractors ? (
							<Spin indicator={loader} />
						) : (
							<>
								<p style={numberStyle}>{contractorsData?.count!}</p>
								<p style={labelStyle}>Контрагенты</p>
							</>
						)}
					</Card>
				</Col>
			}
			{
				<Col span={12}>
					<Card style={cardStyle}>
						{isLoadingIssue ? (
							<Spin indicator={loader} />
						) : (
							<>
								<p style={numberStyle}>{issue?.totalIssues!}</p>
								<p style={labelStyle}>Замечания</p>
							</>
						)}
					</Card>
				</Col>
			}
			{
				<Col span={12}>
					<Card style={cardStyle}>
						{isLoadingOrdinance ? (
							<Spin indicator={loader} />
						) : (
							<>
								<p style={numberStyle}>{ordinance?.totalOrdinances}</p>
								<p style={labelStyle}>Предписания</p>
							</>
						)}
					</Card>
				</Col>
			}
			{
				<Col span={12}>
					<Card style={cardStyle}>
						{isLoadingOrdinanceFines ? (
							<Spin indicator={loader} />
						) : (
							<>
								<p style={numberStyle}>{ordinanceFinesValues?.totalOrdinanceFines}</p>
								<p style={labelStyle}>Штрафы</p>
							</>
						)}
					</Card>
				</Col>
			}
			{
				<Col span={12}>
					<Card style={cardStyle}>
						{isLoadingChecklist ? (
							<Spin indicator={loader} />
						) : (
							<>
								<p style={numberStyle}>{checklistData?.totalCheckLists}</p>
								<p style={labelStyle}>Чек-листы</p>
							</>
						)}
					</Card>
				</Col>
			}
			{
				<Col span={12}>
					<Card style={cardStyle}>
						{isLoadingContainer ? (
							<Spin indicator={loader} />
						) : (
							<>
								<p style={numberStyle}>{containerValues?.totalContainers}</p>
								<p style={labelStyle}>ВОР</p>
							</>
						)}
					</Card>
				</Col>
			}
		</Row>
	)
}
