import { FilterOutlined, SyncOutlined } from '@ant-design/icons'
import { useQueryClient } from '@tanstack/react-query'
import { Button, Card, Col, Flex, Form, Input, Row, Table, Tooltip } from 'antd'
import { ColumnsType } from 'antd/es/table'
import ColumnConfig, { IColumns } from 'components/columnConfig'
import { useEffect, useState } from 'react'
import { useContractsGuide } from '../api/contracts-guide-query'
import { TContractGuide, TContractsGuideForm } from '../model/contracts-guide-schema'
import { useContractsGuideState } from '../model/contracts-guide-state'
import { ContractsGuideCell } from './contracts-guide-cell'
import { useContractsGuideColumns } from './contracts-guide-columns'
import { ContractsGuideFilters } from './contracts-guide-filters'

export const ContractsGuide = () => {
	const queryClient = useQueryClient()
	const conf = localStorage.getItem('ContractsGuideColumns')
	const [contractsForm] = Form.useForm<TContractsGuideForm>()
	const { data, isFetching } = useContractsGuide()
	const { currentPage, totalItems, filters, setFilters, setCurrentPage, setShowFilters } =
		useContractsGuideState()
	const columns = useContractsGuideColumns(contractsForm)
	const [showColumns, setShowColumns] = useState<IColumns[]>([])
	const initColumns: IColumns[] = columns.map((item, index) => ({
		key: index.toString()!,
		title: item.title?.toString()!,
		fixed: typeof item.fixed !== 'undefined'
	}))

	const mergeColumns: ColumnsType<TContractGuide> = columns?.filter(
		col => showColumns!?.find(i => i.title === col.title) !== undefined
	)

	const onColumnsConfig = (cols: IColumns[]) => {
		setShowColumns([...initColumns.filter(col => col.fixed === true), ...cols])
	}

	useEffect(() => {
		if (conf === null) {
			setShowColumns(initColumns)
			localStorage.setItem(
				'ContractsGuideColumns',
				JSON.stringify(
					initColumns.filter(col => !col.fixed && !col.title.toString().includes('object'))
				)
			)
		} else {
			const cols = [...initColumns.filter(col => col.fixed === true), ...JSON.parse(conf!)]
			setShowColumns(cols)
		}
	}, [])

	console.log('filters', filters)

	return (
		<Card style={{ marginTop: '1rem' }}>
			<ContractsGuideFilters />
			<Flex vertical gap={24}>
				<Row gutter={8}>
					<Col>
						<ColumnConfig
							onChange={onColumnsConfig}
							columns={initColumns}
							localstorage={'ContractsGuideColumns'}
						/>
					</Col>
					<Col>
						<Tooltip title="Обновить">
							<Button
								icon={<SyncOutlined spin={isFetching} />}
								onClick={() => queryClient.invalidateQueries({ queryKey: ['contractsGuide'] })}
							/>
						</Tooltip>
					</Col>
					<Col>
						<Tooltip title="Фильтры">
							<Button
								type={
									Object.entries(filters!).some(x => x[0] !== 'name' && typeof x[1] !== 'undefined')
										? 'primary'
										: 'default'
								}
								icon={<FilterOutlined />}
								onClick={() => setShowFilters(true)}
							/>
						</Tooltip>
					</Col>
					<Col>
						<Input.Search
							allowClear
							addonAfter={false}
							placeholder="Поиск..."
							defaultValue={filters.name}
							onSearch={value => setFilters({ ...filters, name: value })}
							style={{ width: 300 }}
						/>
					</Col>
				</Row>
				<Form form={contractsForm} component={false}>
					<Table
						loading={isFetching}
						size="small"
						rowKey="id"
						dataSource={data}
						sticky={{ offsetHeader: -16 }}
						components={{
							body: {
								cell: ContractsGuideCell
							}
						}}
						className="app-table-types"
						columns={mergeColumns}
						pagination={{
							current: currentPage,
							total: totalItems,
							pageSize: 20,
							showSizeChanger: false,
							onChange: setCurrentPage
						}}
						bordered
						scroll={{ x: '100%' }}
						expandable={{
							childrenColumnName: 'childrens',
							rowExpandable: record => record?.childrens?.length > 0,
							expandedRowRender: () => <></>,
							columnWidth: 24
						}}
					/>
				</Form>
			</Flex>
		</Card>
	)
}
