import { Col, Form, Row, Skeleton, TreeSelect } from 'antd'
import { FormInstance, Rule } from 'antd/es/form'
import { DefaultOptionType } from 'antd/es/select'
import { AxiosError } from 'axios'
import { CopyToClipboard } from 'components/CopyToClipboard'
import { TWbsTaskForm } from 'entities/wbs-task/model/wbs-task-schema'
import { FC, Key, useEffect, useState } from 'react'
import { useWbsTaskCardConstructionObjects } from 'widgets/wbs-task-card/api/wbs-task-card-queries'
import { flattenTree } from 'widgets/wbs/model/wbs-helper'

interface IProps {
	canEdit: boolean
	dataSourceValue?: string | null
	disabled?: boolean
	error?: any
	form: FormInstance
	isLoading: boolean
	label: string
	name: string
	options?: DefaultOptionType[]
	rules?: Rule[]
	selectSingle?: boolean
	isConstructionObject?: boolean
	formValues?: TWbsTaskForm
}

export const AbsFormTreeSelect: FC<IProps> = props => {
	const {
		canEdit,
		dataSourceValue,
		disabled,
		error,
		form,
		isLoading,
		label,
		name,
		options,
		rules,
		selectSingle = true,
		isConstructionObject,
		formValues
	} = props

	const [expandedKeys, setExpandedKeys] = useState<Key[]>(
		flattenTree(options!)?.map((elem: any) => elem.id)
	)
	const [searchValue, setSearchValue] = useState()

	useEffect(() => {
		setExpandedKeys(flattenTree(options!)?.map((elem: any) => elem.id))
	}, [searchValue, options])

	const onSearch = (e: any) => {
		setSearchValue(e.target?.value)
		if (!e.target?.value) {
			setExpandedKeys(flattenTree(options!)?.map((elem: any) => elem.id))
		}
	}
	const onExpand = (newKeys: Key[]) => {
		setExpandedKeys(newKeys)
	}
	const { data: constructionObjects } = useWbsTaskCardConstructionObjects()

	return canEdit ? (
		<Row align="middle" style={{ width: '100%' }}>
			<Col flex={1}>
				<Form.Item label={label} name={name} rules={rules}>
					{isLoading ? (
						<Skeleton.Button block active />
					) : error instanceof AxiosError ? (
						<span className="app-form-text danger">
							{error.response?.status === 403
								? 'У вас недостаточно прав для редактирования этого поля'
								: 'Ошибка получения данных'}
						</span>
					) : (
						<>
							<TreeSelect
								showSearch={true}
								onSearch={onSearch}
								treeDefaultExpandAll
								filterTreeNode={(input, option) =>
									(option?.name ?? '').toLowerCase().includes(input.toLowerCase())
								}
								loading={isLoading}
								disabled={disabled || options?.length === 0}
								treeData={options}
								fieldNames={{ label: 'name', value: 'id' }}
								allowClear
								variant="borderless"
								treeExpandedKeys={expandedKeys}
								onTreeExpand={onExpand}
								treeTitleRender={(node: any) => {
									if (isConstructionObject) {
										if (!flattenTree(options)?.find((t: any) => t.id === node.value)) {
											return node.name ?? dataSourceValue
										} else {
											return node.name
										}
									} else return node.name
								}}
							/>
						</>
					)}
				</Form.Item>
			</Col>
			<Col>
				{isConstructionObject && (
					<CopyToClipboard
						text={
							flattenTree(constructionObjects)?.find(
								(co: any) => co.id === formValues?.constructionObjectId
							)?.name!
						}
						style={{ marginRight: '5px' }}
						tooltip="Скопировать название объекта строительства"
					/>
				)}
			</Col>
		</Row>
	) : (
		<Form.Item label={label}>
			<span className="app-form-text" style={{ width: '100%' }}>
				{dataSourceValue ?? '-'}
			</span>
			{isConstructionObject && (
				<CopyToClipboard
					text={dataSourceValue!}
					style={{ marginRight: '5px' }}
					tooltip="Скопировать название объекта строительства"
				/>
			)}
		</Form.Item>
	)
}
