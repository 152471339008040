import dayjs from 'dayjs'
import { IBase, IBasePotentialRedactors } from 'interfaces/IBase'
import { IProject } from 'interfaces/IEnvironment'
import { IPagination } from 'interfaces/IPagination'
import {
	IEstimatePositionItemDto,
	IStatusRouteItem,
	IWbsContainerSectionDto
} from 'pages/unmodelPage/wbsContainer'
import { API } from 'services/ApiService'
import { TEstimateType } from 'shared/schema'
import { ISort } from 'store/asmSlice'
import {
	IContainerCopyDto,
	IContainerUpdateDto,
	ILocalEstimate,
	ILocalEstimateContainer,
	ILocalEstimateFilters
} from '../model/localEstimateInterfaces'

export const getLocalEstimates = async ({
	// project,
	filters,
	limit = 50,
	offset = 0,
	sortState,
	projectId
}: {
	// project?: IProject
	filters?: ILocalEstimateFilters
	limit?: number
	offset?: number
	sortState?: ISort
	projectId?: string
}) => {
	if (filters?.createdDate !== undefined && filters?.createdDate !== null) {
		filters = {
			...filters,
			createdDate: dayjs(filters?.createdDate).format('YYYY-MM-DD')
		}
	}
	return await API<IPagination<ILocalEstimate[]>>({
		url: `/v1/containerEstimates`,
		method: 'get',
		params: { limit, offset, ...filters, sortState, projectId }
	}).then(async response => response)
}

export const LocalEstimateContainerApi = {
	async getContainer(id: string) {
		return await API<ILocalEstimateContainer>({
			url: `/v1/containerEstimates/${id}`,
			method: 'get',
			params: {
				projectId: null
			}
		}).then(response => response.data)
	},
	async getAuthors(projects: string[]) {
		return await API({
			url: `/v1/containerEstimates/get-authors`,
			method: 'post',
			params: {
				projectId: null
			},
			data: { projectId: projects }
		}).then(response => response)
	},
	async getSections(containerId: string) {
		return await API<IWbsContainerSectionDto[]>({
			url: `/v1/containerEstimates/${containerId}/sections`,
			method: 'get'
		}).then(response => response.data)
	},
	async createCorrectionLocalEstimate(containerId: string, name: string) {
		return await API({
			url: `/v1/containerEstimates/${containerId}/correctionContainerEstimate`,
			method: 'post',
			params: {projectId: null},
			data: { name }
		}).then(response => response.data)
	},
	async getSectionItems(sectionId: string, containerId: string) {
		return await API<IEstimatePositionItemDto[]>({
			url: `/v1/containerEstimates/${containerId}/sections/${sectionId}/estimatePositions`,
			method: 'get',
			params: {
				projectId: null
			}
		}).then(response => response.data)
	},
	async updContainer(containerId: string, dto: IContainerUpdateDto) {
		return await API({
			url: `/v1/containerEstimates/${containerId}`,
			method: 'patch',
			params: {
				projectId: null
			},
			data: dto
		}).then(async response => response.data)
	},
	async getPotentialRedactors(projectId: string) {
		return await API<IBasePotentialRedactors[]>({
			url: `/v1/containerEstimates/potentialRedactors`,
			method: 'get',
			params: {
				projectId
			}
		}).then(async response => response.data)
	},
	async deleteLocalEstimate(containerId: string) {
		return await API({
			url: `/v1/containerEstimates/${containerId}`,
			method: 'delete',
			params: {
				projectId: null
			}
		}).then(async response => response.data)
	},
	async copyContainer(
		containerId: string,
		container: IContainerCopyDto,
		destinationProjectId: string,
		copyNoteDp: boolean = false
	) {
		return await API({
			url: `/v1/containerEstimates/${containerId}/copy`,
			method: 'post',
			params: { name: container.name, destinationProjectId, copyNoteDp, projectId: null }
		}).then(async response => response.data)
	},
	async setContainerReadyState(id: string, state: boolean) {
		return await API({
			url: `/v1/containerEstimates/${id}/ready`,
			method: 'patch',
			params: { ready: state, projectId: null }
		}).then(response => response.status)
	},
	async getContainersConstructionObjects() {
		return await API<IBase[]>({
			url: `/v1/containerEstimates/constructionObjects`,
			method: 'get',
			params: {
				projectId: null
			}
		}).then(async response => response.data)
	},
	async getContainerVisas(containerId: string) {
		return await API<IStatusRouteItem[]>({
			url: `v1/containerEstimates/${containerId}/visas`,
			method: 'get',
			params: {
				projectId: null
			}
		}).then(response => response.data)
	},
	async unsetVisaApprove(containerId: string, type: TEstimateType, visaId: string) {
		return await API({
			url: `/v1/containerEstimates/${containerId}/unapprove`,
			method: 'post',
			params: { projectId: null },
			data: { visaId }
		}).then(response => response.data)
	},
	async setVisaApprove(containerId: string, type: TEstimateType, visaId?: string) {
		return await API({
			url: `/v1/containerEstimates/${containerId}/approve`,
			method: 'post',
			params: { visaId, projectId: null }
		}).then(response => response.data)
	},
	async getCanVisaApprove(containerId: string) {
		return await API<string[]>({
			url: `/v1/containerEstimates/${containerId}/approvalAvailable`,
			method: 'get',
			params: {
				projectId: null
			}
		}).then(response => response.data)
	},
	async clearIsCopied(containerId: string) {
		return await API({
			url: `/v1/containerEstimates/${containerId}/clearIsCopied`,
			method: 'patch',
			params: {
				projectId: null
			}
		}).then(async response => response.status)
	}
}
