import {
	Flex,
	Form,
	FormInstance,
	GetProp,
	Image,
	message,
	Typography,
	Upload,
	UploadFile,
	UploadProps
} from 'antd'
import Dragger from 'antd/es/upload/Dragger'
import { IIssueCardAttachment, TIssueDetailForm } from 'entities/issues/model/issue-card-schema'
import { useAppSelector } from 'hooks/appReduxHook'
import { FC, useState } from 'react'
import { PERMISSIONS } from 'shared/constants'
import { useCheckPermissions } from 'shared/useCheckPermissions'
const { Text } = Typography
interface IProps {
	form: FormInstance<TIssueDetailForm>
	attachments: IIssueCardAttachment[]
	filesToUpload: UploadFile[]
	setFilesToUpload: (x: UploadFile[]) => void
	canEdit: boolean
}

type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0]

const getBase64 = (file: FileType): Promise<string> =>
	new Promise((resolve, reject) => {
		const reader = new FileReader()
		reader.readAsDataURL(file)
		reader.onload = () => resolve(reader.result as string)
		reader.onerror = error => reject(error)
	})

export const IssueCardAttachmentsUploader: FC<IProps> = ({
	form,
	attachments,
	filesToUpload,
	canEdit,
	setFilesToUpload
}) => {
	const { project } = useAppSelector(state => state.environment)
	const canAddAttachments = useCheckPermissions()([PERMISSIONS.AttachFilesForIssue], project?.id)
	// const { uploadList, setUploadList } = useIssueCardState(
	// 	useShallow(state => ({
	// 		uploadList: state.uploadList,
	// 		setUploadList: state.setUploadList
	// 	}))
	// )
	// const [uploadList, setUploadList] = useState<UploadFile[]>([])
	// const [count, setCount] = useState<number>(attachments.length)
	const [previewOpen, setPreviewOpen] = useState(false)
	const [previewImage, setPreviewImage] = useState('')
	const [filesLimit, setFilesLimit] = useState(0)
	// useEffect(() => {
	// 	setCount(attachments.length + filesToUpload.length)
	// }, [filesToUpload])
	const handleChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
		const totalAttachments = attachments.length + newFileList.length
		setFilesLimit(totalAttachments)
		if (totalAttachments > 50) {
			form.validateFields(['attachments']).catch(() => setFilesLimit(0))
		} else {
			// setUploadList(newFileList)
			setFilesToUpload(newFileList)
			form.resetFields(['attachments'])
		}
	}

	const handlePreview = async (file: UploadFile) => {
		if (!file.url && !file.preview) {
			file.preview = await getBase64(file.originFileObj as FileType)
		}

		setPreviewImage(file.url || (file.preview as string))
		setPreviewOpen(true)
	}

	return (
		<Form.Item
			name="attachments"
			rules={[
				() => ({
					validator(_) {
						if (filesLimit > 50) {
							return Promise.reject(
								new Error(
									'Превышен лимит загрузки файлов. Необходимо изменить количество загружаемых файлов или удалить неактуальные файлы из замечания'
								)
							)
						}
						return Promise.resolve()
					}
				})
			]}
		>
			<Dragger
				openFileDialogOnClick={50 - (attachments.length + filesToUpload.length) > 0}
				disabled={!canAddAttachments || !canEdit}
				accept="image/*,.pdf"
				multiple={true}
				listType="picture-card"
				fileList={filesToUpload}
				onChange={handleChange}
				onPreview={handlePreview}
				beforeUpload={file => {
					const canUpload =
						!file.name.includes('jfif') &&
						(file.type === 'image/jpeg' ||
							file.type === 'image/jpg' ||
							file.type === 'image/png' ||
							file.type === 'application/pdf')
					if (!canUpload) {
						message.error(`${file.name} - тип файла не поддерживается`)
					}
					return canUpload ? false : Upload.LIST_IGNORE
				}}
				style={{
					marginBottom: '1rem',
					display: !canAddAttachments ? 'none' : 'block',
					cursor: !(attachments.length + filesToUpload.length >= 50) ? 'pointer' : 'not-allowed'
				}}
			>
				<Flex vertical>
					<Text>Добавить файлы для загрузки</Text>
					<Text type="secondary">
						<span>Доступно:</span>
						<span
							style={{
								color: 50 - (attachments.length + filesToUpload.length) < 0 ? 'red' : 'inherit',
								marginLeft: 5
							}}
						>
							{50 - (attachments.length + filesToUpload.length)}/50
						</span>
					</Text>
				</Flex>
			</Dragger>
			{previewImage && (
				<Image
					wrapperStyle={{ display: 'none' }}
					preview={{
						visible: previewOpen,
						onVisibleChange: visible => setPreviewOpen(visible),
						afterOpenChange: visible => !visible && setPreviewImage('')
					}}
					src={previewImage}
				/>
			)}
		</Form.Item>
	)
}
