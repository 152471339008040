import { AppstoreFilled, AppstoreTwoTone, BuildTwoTone } from '@ant-design/icons'
import { message, Modal, Space, Table, Typography } from 'antd'
import { uniq } from 'lodash'
import { all, create } from 'mathjs'
import {
	IWbsContainerSection,
	IWbsContainerSectionItem,
	IWbsContainerSectionItemWork,
	IWbsContainerSectionItemWorkMaterial,
	WbsContainerApi
} from 'pages/unmodelPage/wbsContainer'
import { ColumnsType } from 'rc-table'
import { FC } from 'react'
import { ROUND_VALUE } from 'shared/constants'
import { findNestedObj } from 'shared/helpers'
import { adjustmentApi, IAdjustmentPosition } from 'widgets/adjustment'
import { useAdjustmentState } from 'widgets/adjustment/model/adjustment-state'
import { useEstimateState } from '../model/estimate-state'

const config = {}
const math = create(all, config)
const { Title } = Typography

interface IProps {
	type: 'wbs' | 'local'
	isAdjustment: boolean
}

export const EstimateRemoveModal: FC<IProps> = props => {
	const { isAdjustment, type } = props
	const state = isAdjustment === false ? useEstimateState : useAdjustmentState
	const { showRemoveModal, toRemove, sections, removeSectionId, updateSections } = state()

	const data = findNestedObj<IWbsContainerSection>(sections!, 'id', removeSectionId!)?.children

	const source = uniq(
		data
			?.flatMap((d: any) => [...data, d.children])
			.filter((x: any) => Object.keys(toRemove! ?? '').includes(x.id))
	)

	const onClose = () => {
		state.setState({ showRemoveModal: false, toRemove: undefined })
	}

	const onDelete = async () => {
		const removable = source?.map((s: any) => s.id)

		await (isAdjustment
			? adjustmentApi.deleteEstimatePosition(uniq(removable))
			: WbsContainerApi.deleteEstimatePositions(uniq(removable), type)
		)
			.then(async () => {
				message.success('Позиции успешно удалены')
				updateSections(removeSectionId!)
				state.setState({ selectedRows: { parentId: undefined, rows: {} } })
				onClose()
			})
			.catch(() => message.error('Во время удаления произошла ошибка. Обратитесь к администратору'))
	}
	const removeColumns: ColumnsType<IWbsContainerSection | IWbsContainerSectionItem> = [
		{
			title: 'Наименование',
			dataIndex: 'name',
			render: (value, record) =>
				'workName' in record
					? `${record.order} - ${record.workName}`
					: 'materialName' in record
					? `${record.order} - ${record.materialName}`
					: value
		},
		{
			dataIndex: 'measureUnit',
			title: 'Ед.изм',
			width: 80,
			align: 'center',
			render: (_, record) => record.measureUnit?.name
		},
		{
			key: 'amount',
			dataIndex: isAdjustment === false ? 'amount' : 'amountSecond',
			title: 'Кол-во',
			width: 128,
			align: 'center'
		},
		{
			dataIndex: isAdjustment === false ? 'rate' : 'rateSecond',
			title: 'Коэффициент нормы расхода',
			width: 120,
			align: 'center',
			render: value =>
				!Number.isNaN(Number(value!))
					? math.round(Number(value!), ROUND_VALUE).toLocaleString()
					: ''
		},
		{
			title: (
				<Space
					direction="vertical"
					size="small"
					style={{ rowGap: 0, lineHeight: '100% !important' }}
				>
					<p style={{ margin: 0 }}>Кол-во</p>
					<p style={{ margin: 0, fontSize: 11, lineHeight: 1.2 }}>с учётом коэффициента расхода</p>
				</Space>
			),
			render: (_, record) => {
				const row = record as unknown as
					| IWbsContainerSectionItemWork
					| IWbsContainerSectionItemWorkMaterial
					| IAdjustmentPosition
				return isAdjustment === false
					? row.amount && row.rate
						? math.round(Number(row.amount! * row.rate!), ROUND_VALUE).toLocaleString()
						: ''
					: 'amountSecond' in row &&
							(row.amountSecond && row.rateSecond
								? math
										.round(Number(row.amountSecond! * row.rateSecond!), ROUND_VALUE)
										.toLocaleString()
								: '')
			},
			width: 128,
			align: 'center'
		}
	]
	return (
		<Modal
			title={
				<Title level={4} type="danger">
					Внимание! Следующие позиции будут удалены:
				</Title>
			}
			centered
			destroyOnClose
			width={window.innerWidth - (window.innerWidth * 50) / 100}
			open={showRemoveModal}
			okButtonProps={{ danger: true }}
			okText="Удалить"
			onOk={onDelete}
			onCancel={onClose}
		>
			<Table
				size="small"
				bordered
				className="app-wbs-table"
				rowKey={row => row.id}
				scroll={{ y: '50vh' }}
				pagination={false}
				dataSource={(source as any) ?? []}
				columns={removeColumns as any}
				onRow={record => ({
					style: record.level !== 3 ? { cursor: 'pointer' } : {}
				})}
				expandable={{
					expandRowByClick: true,
					expandIcon: ({ expanded, onExpand, record }) =>
						record.level !== 3 ? (
							'children' in record && !!record.children?.length ? (
								expanded ? (
									<AppstoreTwoTone twoToneColor="#6fa9fa" onClick={e => onExpand(record, e)} />
								) : (
									<AppstoreFilled style={{ color: '#6fa9fa' }} onClick={e => onExpand(record, e)} />
								)
							) : (
								<AppstoreTwoTone twoToneColor="#9e9e9e" />
							)
						) : (
							<BuildTwoTone twoToneColor="#5d9f6c" />
						)
				}}
			/>
		</Modal>
	)
}
