import { DeleteOutlined, FileAddOutlined, ProfileOutlined } from '@ant-design/icons'
import { useQueryClient } from '@tanstack/react-query'
import { Button, Col, notification, Row, Select, Tag, Tooltip } from 'antd'
import { ColumnsType } from 'antd/es/table'
import dayjs from 'dayjs'
import { useWbsTaskState } from 'pages/wbs-tasks-page/model/wbs-tasks-state'
import { NavLink, useNavigate } from 'react-router-dom'
import { PERMISSIONS } from 'shared/constants'
import { WBS_TASK_STATUS } from 'shared/constants/wbs-task-types'
import { CheckDeclension } from 'shared/helpers'
import { useCheckPermissions } from 'shared/useCheckPermissions'
import { WBS_TASK_TYPE, WBS_TASK_TYPE_ENUM } from 'widgets/wbs-task-card/model/wbs-task-card-mock'
import { useWbsTaskCreateDocument, useWbsTaskDelete } from '../api/wbs-task-registry-queries'
import { IWbsTask, IWbsTaskCreateDocument } from '../model/wbs-task-registry-interface'
import { useWbsTaskVisas } from 'widgets/wbs-task-visas/api/wbs-task-visas-queries'
import { useAppSelector } from 'hooks/appReduxHook'
import { useState } from 'react'

const StatusCell = ({ value, status }: { value: string; status: string }) => {
	return (
		<Row gutter={8} wrap={false} align="middle">
			<Col>
				<div className={`indicator indicator--${status}`} />
			</Col>
			<Col flex={1}>{value}</Col>
		</Row>
	)
}
export const useWbsRegistryColumns = () => {
	const queryClient = useQueryClient()
	const { mutate } = useWbsTaskDelete()
	const { mutate: mutateCreateDocument } = useWbsTaskCreateDocument()
	const { currentTaskId, openType } = useWbsTaskState()
	const [recordId, setRecordId] = useState<string>()
	const { data: wbsTaskVisas } = useWbsTaskVisas(recordId!)
	const { user } = useAppSelector(state => state.environment)
	const checkPermissions = useCheckPermissions()
	const canDelete = (record: IWbsTask) =>
		checkPermissions([PERMISSIONS.WbsTasksDelete], record?.project?.id!)
	const canCreate = (record: IWbsTask) => {
		setRecordId(record?.id!)
		if (recordId) {
			return (
				record?.wbsTaskStatus?.id === WBS_TASK_STATUS.inProgress &&
				wbsTaskVisas?.find(
					(v: any) =>
						v.name === 'DES' &&
						v.visas?.find((visa: any) => visa.name === 'Координатор ДЭС' && visa.visaState !== null)
				) &&
				record?.resultDocument === null &&
				(record.assignedUsers?.find(au => au?.id === user?.id) || user?.isAdmin) && // checkPermissions([PERMISSIONS.AdjustmentWbsCorrectionCreate], record?.project?.id!) &&
				((record?.wbsTaskType.id === WBS_TASK_TYPE.wbsAdjustment.id &&
					record?.container &&
					checkPermissions([PERMISSIONS.AdjustmentCreate], record?.project?.id!)) ||
					// checkPermissions([PERMISSIONS.AdjustmentEstimateCorrectionCreate], record?.project?.id!) &&
					(record?.wbsTaskType.id === WBS_TASK_TYPE.estimateAdjustment.id &&
						record?.estimate &&
						checkPermissions([PERMISSIONS.AdjustmentCreate], record?.project?.id!)) ||
					(record?.wbsTaskType.id === WBS_TASK_TYPE.new.id &&
						checkPermissions([PERMISSIONS.WbsCreator], record?.project?.id!)))
			)
		}
		return false
	}
	const navigate = useNavigate()
	const truncate = (text: string, maxLength: number) =>
		text.length > maxLength ? `${text.slice(0, maxLength)}...` : text
	const columns: ColumnsType<IWbsTask> = [
		{
			key: 'wbsTaskStatus',
			dataIndex: ['wbsTaskStatus', 'description'],
			title: 'Статус',
			width: 220,
			sorter: true,
			fixed: 'left',
			render: (value, record) => <StatusCell value={value} status={record.wbsTaskStatus.name} />
		},
		{
			key: 'projectId',
			dataIndex: ['project', 'name'],
			title: 'Проект',
			width: 200,
			fixed: 'left'
		},
		{
			key: 'wbsTaskType',
			dataIndex: ['wbsTaskType', 'name'],
			title: 'Тип заявки',
			sorter: true,
			fixed: 'left',
			width: 220
		},
		{
			key: 'typeSection',
			dataIndex: ['typeSection', 'name'],
			title: 'Раздел НСИ',
			fixed: 'left',
			width: 220
		},
		{
			key: 'workPackage',
			dataIndex: ['workPackage', 'name'],
			title: 'Пакет работ AbsPlan',
			fixed: 'left',
			width: 220
		},
		{
			key: 'number',
			dataIndex: ['number'],
			title: 'Номер заявки',
			width: 130,
			sorter: true,
			align: 'center'
		},
		{
			dataIndex: ['title'],
			title: 'Наименование',
			align: 'center',
			width: 200
		},
		{
			key: 'createdAt',
			dataIndex: ['createdAt'],
			title: 'Дата создания',
			width: 192,
			sorter: true,
			align: 'center',
			render: value => dayjs(value).format('DD.MM.YY HH:mm')
		},
		{
			dataIndex: ['createdBy', 'name'],
			title: 'Автор',
			align: 'center',
			width: 240
		},
		{
			dataIndex: ['assignedUsers'],
			title: 'Исполнитель',
			align: 'center',
			width: 240,
			render: (_, record) =>
				record.assignedUsers &&
				(record.assignedUsers.length > 1 ? (
					<Select
						popupClassName="app-selector-no-flow"
						showSearch={false}
						value={record
							?.assignedUsers!?.sort(
								(a, b) => +b?.isDeleted! - +a?.isDeleted! || a?.name!?.localeCompare(b?.name!)
							)
							.map(x => x?.id)}
						tagRender={props => (
							<Tag style={{ fontSize: 14 }} title={props.label as string} bordered={false}>
								{truncate(props.label as string, 25)}
							</Tag>
						)}
						maxTagCount={1}
						mode="multiple"
						style={{ width: '100%' }}
						options={record?.assignedUsers?.map(x => ({
							key: x?.id,
							value: x?.id,
							label: x?.name
						}))}
					/>
				) : (
					record.assignedUsers.map(assignedUser => (
						<Tag key={assignedUser?.id} style={{ fontSize: 14 }} bordered={false} color="blue">
							{assignedUser?.name}
						</Tag>
					))
				))
		},
		{
			dataIndex: ['contract', 'name'],
			title: 'Договор',
			align: 'center',
			width: 200
		},
		{
			dataIndex: ['container', 'name'],
			title: 'ВОР-основание',
			align: 'center',
			width: 120,
			render: (_, record) =>
				record.container && (
					<Tooltip title={record.container.name}>
						<NavLink to={`/unmodel/${record.container.id}`} target="_blank">
							{<ProfileOutlined style={{ fontSize: 20 }} />}
						</NavLink>
					</Tooltip>
				)
		},
		{
			dataIndex: ['estimate', 'name'],
			title: 'Смета-основание',
			align: 'center',
			width: 120,
			render: (_, record) =>
				record.estimate && (
					<Tooltip title={record.estimate.name}>
						<NavLink to={`/local/${record.estimate.containerId}`} target="_blank">
							{<ProfileOutlined style={{ fontSize: 20 }} />}
						</NavLink>
					</Tooltip>
				)
		},
		{
			key: 'duration',
			dataIndex: ['duration'],
			title: 'Общее время',
			width: 140,
			align: 'center',
			render: value =>
				value!?.slice(0, 5).includes('.')
					? `${value!?.split('.')[0]} ${CheckDeclension(+value!?.split('.')[0], [
							'день',
							'дня',
							'дней'
					  ])} ${value!.split('.')[1]?.slice(0, 2)} ${CheckDeclension(
							+value!.split('.')[1]?.slice(0, 2),
							['час', 'часа', 'часов']
					  )}`
					: value!?.slice(0, 2) !== '00'
					? `${value!?.slice(0, 2)} ${CheckDeclension(+value!?.slice(0, 2), [
							'час',
							'часа',
							'часов'
					  ])}`
					: 'менее часа'
		},
		{
			dataIndex: ['resultDocument', 'name'],
			title: 'Ссылка на документ',
			align: 'center',
			width: 120,
			render: (_, record) =>
				record?.resultDocument && (
					<Tooltip title={record?.resultDocument?.name}>
						<NavLink
							to={`/${
								record?.resultDocument?.wbsTaskType === WBS_TASK_TYPE_ENUM.wbsAdjustment
									? 'unmodel/adjustment/'
									: record?.resultDocument?.wbsTaskType === WBS_TASK_TYPE_ENUM.estimateAdjustment
									? 'local/adjustment/'
									: 'unmodel/'
							}${record?.resultDocument?.id}`}
							target="_blank"
							style={{ marginLeft: 10 }}
						>
							{<ProfileOutlined style={{ fontSize: 20 }} />}
						</NavLink>
					</Tooltip>
				)
		},
		{
			width: 90,
			align: 'center',
			render: (_, record) => (
				<Row gutter={2}>
					<Col>
						<Tooltip title="Создать документ">
							<Button
								icon={<FileAddOutlined />}
								disabled={!canCreate(record)}
								onClick={() => {
									const dto: IWbsTaskCreateDocument = {
										wbsTaskTypeId: record?.container?.id ?? record?.estimate?.id ?? null,
										type:
											record?.wbsTaskType?.id === WBS_TASK_TYPE.wbsAdjustment?.id
												? 'container'
												: record?.wbsTaskType?.id === WBS_TASK_TYPE.estimateAdjustment?.id
												? 'estimate'
												: 'newWbs',
										wbsTaskId: currentTaskId ?? record?.id!,
										name: record?.title!,
										typeSectionId: record?.typeSection?.id!,
										constructionObjectId: record?.constructionObject?.id!,
										projectId: record?.project?.id
									}
									mutateCreateDocument(
										{ dto },
										{
											onSuccess: () => {
												notification.success({
													message: 'Документ успешно создан',
													placement: 'topLeft'
												})
												queryClient.invalidateQueries({ queryKey: ['wbsTasks'] })
												queryClient.invalidateQueries(['wbsTasksDiagram'])
											},
											onError: (error: any) => {
												notification.error({
													message: 'Произошла ошибка во время создания',
													description: error?.response?.data?.detail,
													placement: 'topLeft'
												})
											}
										}
									)
								}}
							/>
						</Tooltip>
					</Col>

					<Col>
						<Tooltip title="Удалить">
							<Button
								danger
								icon={<DeleteOutlined />}
								disabled={!canDelete(record)}
								onClick={() => {
									mutate(
										{ wbsTaskId: record?.id! },
										{
											onSuccess: () => {
												notification.success({
													message: 'Заявка успешно удалена',
													placement: 'topLeft'
												})
												queryClient.invalidateQueries({ queryKey: ['wbsTasks'] })
												queryClient.invalidateQueries(['wbsTasksDiagram'])
											},
											onError: (error: any) => {
												notification.error({
													message: 'Произошла ошибка во время удаления',
													description: error?.response?.data?.detail,
													placement: 'topLeft'
												})
											}
										}
									)
								}}
							/>
						</Tooltip>
					</Col>
				</Row>
			)
		}
	]
	return columns
}
