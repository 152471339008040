import { Button, Col, Drawer, Form, List, Row, Select, Typography } from 'antd'
import { useEffect, useMemo } from 'react'
import { PERMISSIONS } from 'shared/constants'
import { useCheckPermissions } from 'shared/useCheckPermissions'
import { useContractsGuideState } from '../model/contracts-guide-state'
import { useOrgs } from '../../../entities/org/api/org-query'
import {
	useGuideConstructionObjects,
	useGuideContractors,
	useGuideProjects,
	useGuideProjects1c,
	useGuideSubContractors
} from '../api/contracts-guide-query'
import { TContractsGuideFilters } from '../model/contracts-guide-schema'
import { useAppSelector } from 'hooks/appReduxHook'

const { Text } = Typography

type TFilterItem = {
	title: string | React.ReactNode
	formItemName: string
	list: any[] | null
	fieldNames?: { label: string; value: string }
	loading: boolean
	disabled: boolean
}

export const ContractsGuideFilters = () => {
	const checkPermissions = useCheckPermissions()
	const { project } = useAppSelector(state => state.environment)
	const { showFilters, setShowFilters, setFilters } = useContractsGuideState()
	const [filterForm] = Form.useForm<TContractsGuideFilters>()
	const formValues = Form.useWatch(values => values, filterForm)
	const { data: orgs, isFetching: isOrgsFetching } = useOrgs()
	const { data: contractors, isFetching: isContractorsFetching } = useGuideContractors()
	const { data: subContractors, isFetching: isSubContractorsFetching } = useGuideSubContractors(
		formValues?.contractorId
	)
	const { data: projects, isFetching: isProjectsFetching } = useGuideProjects()
	const { data: projects1c, isFetching: isProjects1cFetching } = useGuideProjects1c()
	const { data: constructionObjects, isFetching: isConstructionObjectsFetching } =
		useGuideConstructionObjects()

	const flattenConstructionObjects = (items: any[]): any[] => {
		return items?.reduce((acc, item) => {
			acc.push({
				id: item.id,
				name: item.name
			})
			if (item.children?.length) {
				acc.push(...flattenConstructionObjects(item.children))
			}
			return acc
		}, [])
	}

	const filterList = useMemo<TFilterItem[]>(
		() => [
			{
				title: 'Организация',
				formItemName: 'orgId',
				list: orgs ?? [],
				loading: isOrgsFetching,
				disabled: false
			},
			{
				title: 'Генподрядчик',
				formItemName: 'contractorId',
				list: contractors ?? [],
				loading: isContractorsFetching,
				disabled: !checkPermissions([PERMISSIONS.AllCompanies], project?.id)
			},
			{
				title: 'Субподрядчик',
				formItemName: 'subContractorId',
				list: subContractors ?? [],
				loading: isSubContractorsFetching,
				disabled: false
			},
			{
				title: 'Проект',
				formItemName: 'projectId',
				list: projects ?? [],
				loading: isProjectsFetching,
				disabled: false
			},
			{
				title: 'Проект 1С',
				formItemName: 'project1CId',
				list: projects1c ?? [],
				loading: isProjects1cFetching,
				disabled: false
			},
			{
				title: 'Объект строительства',
				formItemName: 'constructionObjectId',
				list: constructionObjects ? flattenConstructionObjects(constructionObjects) : [],
				loading: isConstructionObjectsFetching,
				disabled: false
			},
			{
				title: 'Не выставлять штраф',
				formItemName: 'isNotOrdinanceFine',
				list: [
					{ id: '1', name: 'Да' },
					{ id: '0', name: 'Нет' }
				],
				loading: false,
				disabled: false
			}
		],
		[orgs, subContractors, projects, projects1c, constructionObjects]
	)

	const onResetFilters = () => {
		filterForm.resetFields()
		filterForm.submit()
	}

	const onFormFinishHandler = (value: TContractsGuideFilters) => {
		const filters = {
			...value,
			isNotOrdinanceFine: value.isNotOrdinanceFine
				? Boolean(Number(value.isNotOrdinanceFine))
				: undefined
		}
		setFilters(filters)
		setShowFilters(false)
	}

	useEffect(() => {
		if (formValues && !Object.keys(formValues!)?.length) {
			setFilters(formValues)
		}
	}, [formValues])

	return (
		<Drawer
			title="Фильтры"
			open={showFilters}
			onClose={() => setShowFilters(false)}
			placement="left"
			className="at-drawer-left"
			footer={
				<Row gutter={8} justify="end">
					<Col>
						<Button ghost danger onClick={onResetFilters}>
							Сбросить
						</Button>
					</Col>
					<Col>
						<Button type="primary" htmlType="submit" onClick={() => filterForm.submit()}>
							Применить
						</Button>
					</Col>
				</Row>
			}
		>
			<Form name="contractsGuideFilters" form={filterForm} onFinish={onFormFinishHandler}>
				<List
					dataSource={filterList}
					renderItem={(item, index) => (
						<Row align="middle" gutter={8}>
							<Col style={{ flex: '0 0 100%' }}>
								<Text strong>{item.title}:</Text>
							</Col>
							<Col style={{ flex: '1 0 100%' }}>
								<Form.Item key={index} name={item.formItemName}>
									<Select
										mode={item.formItemName === 'status' ? 'multiple' : undefined}
										showSearch
										allowClear
										optionFilterProp="children"
										options={item.list ?? []}
										fieldNames={item.fieldNames ?? { label: 'name', value: 'id' }}
										disabled={item.disabled || item.list!?.length === 0}
										filterOption={(input, option) =>
											(option?.[item.fieldNames?.label ?? 'name'] ?? '')
												.toLowerCase()
												.includes(input.toLowerCase())
										}
										filterSort={(optionA, optionB) =>
											(optionA?.[item.fieldNames?.label ?? 'name'] ?? '')
												.toString()
												.toLowerCase()
												.localeCompare(
													(optionB?.[item.fieldNames?.label ?? 'name'] ?? '')
														.toString()
														.toLowerCase()
												)
										}
										style={{ width: '100%' }}
										placeholder="Не выбран"
									/>
								</Form.Item>
							</Col>
						</Row>
					)}
				/>
			</Form>
		</Drawer>
	)
}
