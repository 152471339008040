import { API } from 'services/ApiService'
import { TQuartzSettings, TQuartzSettingsUpdateDto } from '../model/quartz-settings-schema'

export const quartzSettingsApi = {
	async getAll(projectId?: string) {
		return await API<TQuartzSettings[]>({
			url: '/v1/quartzSettings',
			method: 'get',
			params: { projectId }
		}).then(response => response.data)
	},
	async update(id: string, data: TQuartzSettingsUpdateDto) {
		return await API<TQuartzSettings[]>({
			url: `/v1/quartzSettings/${id}`,
			method: 'patch',
			data
		}).then(response => response.data)
	}
}
