import { useQueryClient } from '@tanstack/react-query'
import { Form, Input, Modal } from 'antd'
import { ASM_POSITION_PROPS } from 'enums'
import { FC, memo, useEffect, useMemo } from 'react'
import { useWbsPermits } from 'widgets/wbs'
import { useEstimateState } from '..'
import { EstimateApi } from '../api/estimate-api'
import { CONTAINER_STATUS, PERMISSIONS } from 'shared/constants'
import { useAppSelector } from 'hooks/appReduxHook'
import { useCheckPermissions } from 'shared/useCheckPermissions'
import { useContainerVisas } from 'widgets/wbs/model/wbs-queries'
import { IStatusRouteItemVisa } from 'pages/unmodelPage/wbsContainer'
import { IBase } from 'interfaces/IBase'
import { useContainerLocalEstimateVisas } from 'pages/unmodelPage/localEstimate/model/localEstimateQuery'

interface IProps {
	visaIssued: boolean
}

export const EstimateNote: FC<IProps> = memo(function ({ visaIssued }) {
	const { user } = useAppSelector(state => state.environment)
	const checkPermissions = useCheckPermissions()
	const queryClient = useQueryClient()
	const { isLoading, container, note, unSetNote, setEstimatePositions, updateSections } =
		useEstimateState()
	const wbsPermissions = useWbsPermits(container?.project?.id)
	const [estimateNoteForm] = Form.useForm()
	const onClose = () => {
		estimateNoteForm.resetFields()
		unSetNote()
	}

	enum NOTE_TYPE {
		noteDP = 'Примечание УПВОР',
		noteSDU = 'Примечание СДУ',
		noteDZ = 'Примечание ДЗ'
	}

	enum TYPE {
		noteDP = 'noteDp',
		noteSDU = 'noteSdu',
		noteDZ = 'noteDz'
	}

	const canEdit = (): boolean => {
		switch (note?.main?.type) {
			case 'noteDP':
				return (
					(typeof container?.isLocalEstimate === 'undefined' &&
						(wbsPermissions.canSetDP ||
							wbsPermissions.isOuter ||
							wbsPermissions.canLocalExternal)) ||
					(container?.isLocalEstimate === false &&
						checkPermissions(
							[PERMISSIONS.CorrectionEstimateChangeNoteDp],
							container?.project?.id!
						) &&
						!container.ready &&
						((user?.isAdmin && container.status.name !== CONTAINER_STATUS.approved) ||
							container.status.name === CONTAINER_STATUS.preparation ||
							container.status.name === CONTAINER_STATUS.structureApproval))
				)
			case 'noteSDU':
				return (
					(typeof container?.isLocalEstimate === 'undefined' &&
						(wbsPermissions.canSetSDU || wbsPermissions.canLocalSdu)) ||
					(container?.isLocalEstimate === false &&
						checkPermissions(
							[PERMISSIONS.CorrectionEstimateChangeNoteSdu],
							container?.project?.id!
						) &&
						!container.ready &&
						((user?.isAdmin && container.status.name !== CONTAINER_STATUS.approved) ||
							container.status.name === CONTAINER_STATUS.structureApproval))
				)
			case 'noteDZ':
				return (
					(typeof container?.isLocalEstimate === 'undefined' &&
						(wbsPermissions.canSetDZ ||
							wbsPermissions.canSetDZSmr ||
							wbsPermissions.canLocalDzMTR ||
							wbsPermissions.canLocalDzSMR)) ||
					(container?.isLocalEstimate === false &&
						checkPermissions(
							[PERMISSIONS.CorrectionEstimateChangeNoteDz],
							container?.project?.id!
						) &&
						!container.ready &&
						((user?.isAdmin && container.status.name !== CONTAINER_STATUS.approved) ||
							container.status.name === CONTAINER_STATUS.structureApproval ||
							container.status.name === CONTAINER_STATUS.priceApproval))
				)
			default:
				return false
		}
	}

	useEffect(() => {
		note?.main.value && estimateNoteForm.setFieldValue('value', note.main.value)
	}, [note])

	const onSaveNote = async (values: any) => {
		if (note) {
			if (typeof container?.isLocalEstimate === 'undefined') {
				const dto = {
					id: 'materialName' in note.row ? note.row.estimatePositionId : note.row.id,
					workId: note.row.estimatePositionTechnologyWorkId,
					materialId: 'materialName' in note.row ? note.row.id : null,
					estimatePositionProps: ASM_POSITION_PROPS[note.main.type],
					value: values.value,
					version: note.row.versionId
				}
				await EstimateApi.setEstimatePositionNote(
					dto,
					typeof container?.isLocalEstimate === 'undefined' ? 'wbs' : 'local'
				).then(() => {
					updateSections(note.row.containerSectionId!)
					// setEstimatePositions(note.row.containerSectionId)
					onClose()
				})
			} else {
				const dto = {
					estimatePositionId:
						'materialName' in note.row ? note.row.estimatePositionId : note.row.id,
					materialId: 'materialName' in note.row ? note.row.id : null,
					value: values.value
				}
				await EstimateApi.setNote(dto, TYPE[note.main.type]).then(() => {
					updateSections(note.row.containerSectionId!)
					onClose()
				})
			}
		}
	}

	return (
		<Modal
			open={typeof note !== 'undefined'}
			width={768}
			destroyOnClose
			centered
			title={`${note && NOTE_TYPE[note.main.type]} - ${
				note?.main.isWork ? 'Работа' : 'Материал'
			}: ${note?.main.name}`}
			onCancel={onClose}
			onOk={() =>
				!container?.ready && canEdit() && !visaIssued ? estimateNoteForm.submit() : onClose()
			}
			okButtonProps={{ htmlType: 'submit', loading: isLoading }}
		>
			<Form form={estimateNoteForm} name="estimateNoteForm" onFinish={onSaveNote}>
				<Form.Item name="value">
					<Input.TextArea
						disabled={container?.ready || !canEdit() || visaIssued}
						allowClear
						autoSize
						showCount
						maxLength={5000}
						placeholder="Примечание"
						style={{ marginBottom: '1rem' }}
						styles={{ textarea: { minHeight: 256 } }}
					/>
				</Form.Item>
			</Form>
		</Modal>
	)
})
