import { SettingOutlined } from '@ant-design/icons'
import type { MenuProps } from 'antd'
import { Button, Card, Checkbox, Col, Dropdown, Row, Tooltip, Typography } from 'antd'
import { FC, useState } from 'react'
interface IColumnConfigProps {
	columns: IColumns[]
	localstorage: string
	onChange: (col: IColumns[]) => void
}

export interface IColumns {
	key: string
	title: string | JSX.Element
	fixed: boolean
}

const { Text } = Typography

const ColumnConfig: FC<IColumnConfigProps> = ({ columns, localstorage, onChange }) => {
	const cols = localStorage.getItem(localstorage)
	const active: IColumns[] = cols && JSON.parse(cols)
	const [visible, setVisible] = useState(false)
	const onListItemClick = (title: string) => {
		let list = active
		if (active.some(item => item.title === title)) {
			list = list.filter(col => col.title?.toString() !== title)
		} else {
			list.push(columns!?.find(item => item.title === title)!)
		}

		localStorage.setItem(localstorage, JSON.stringify(list))
		onChange(list)
	}

	const handleVisibleChange = () => {
		setVisible(!visible)
	}

	const items: MenuProps['items'] = columns!
		.filter(col => col.fixed === false && col.title !== '[object Object]')
		.map(item => ({
			key: item.key,
			label: (
				<Row
					onClick={() => onListItemClick(item.title as string)}
					style={{ cursor: 'pointer', flexWrap: 'inherit' }}
				>
					<Col span={3}>
						<Checkbox checked={active!?.some(i => i.title === item.title)} />
					</Col>
					<Col flex={1}>
						<Text>{item.title}</Text>
					</Col>
				</Row>
			)
		}))

	return (
		<Dropdown
			overlayStyle={{ width: 300 }}
			menu={{ items }}
			placement="bottomLeft"
			arrow={{ pointAtCenter: true }}
			trigger={['click']}
			open={visible}
			onOpenChange={handleVisibleChange}
			dropdownRender={menu => (
				<Card
					size="small"
					title="Колонки"
					className="app-column-config-card"
					classNames={{
						header: 'app-column-config-card__header',
						body: 'app-column-config-card__body'
					}}
				>
					{menu}
				</Card>
			)}
		>
			<Tooltip title="Колонки">
				<Button
					size="middle"
					onClick={handleVisibleChange}
					icon={<SettingOutlined rev={undefined} />}
					type={
						columns!?.filter(col => !col.fixed && col.title !== '[object Object]')!?.length !==
						active!?.filter(col => !col.fixed && col.title !== '[object Object]')!?.length
							? 'primary'
							: 'default'
					}
				/>
			</Tooltip>
		</Dropdown>
	)
}
export default ColumnConfig
