import { Card, Form, FormInstance, Select } from 'antd'
import Descriptions, { DescriptionsItemType } from 'antd/es/descriptions'
import { IIssueCard } from 'entities/issues'
import { TIssueDetailForm } from 'entities/issues/model/issue-card-schema'
import { useAppSelector } from 'hooks/appReduxHook'
import { FC } from 'react'
import { useBuildingPermits, usePotentialAuthors } from '../model/issue-query'
import { IssueFormSelectorItem } from './IssueFormSelectorItem'
import { IssueFormItem } from './IssueFormItem'
import { useViewerState } from 'viewer-state-new'

interface IProps {
	dataSource?: IIssueCard | null
	canEdit: boolean
	form: FormInstance<TIssueDetailForm>
}

export const IssueCardDetailsMain: FC<IProps> = ({ dataSource, canEdit, form }) => {
	const { project: currentProject } = useAppSelector(state => state.environment)
	const { viewer } = useViewerState()
	const { data: buildingPermits, isFetching } = useBuildingPermits(currentProject.id)
	const { data: potentialAuthors, isFetching: isAuthorsFetching } = usePotentialAuthors(
		currentProject?.id
	)
	const items: DescriptionsItemType[] = [
		{
			key: 'phase',
			children: (
				<Form.Item label="Фаза" name="phase">
					<span className="app-form-text">{dataSource?.phase ?? currentProject.phase}</span>
				</Form.Item>
			)
		},
		{
			key: 'building',
			children: (
				<Form.Item label="Корпус" name="building">
					<span className="app-form-text">{dataSource?.building ?? currentProject.building}</span>
				</Form.Item>
			)
		},
		{
			key: 'responsibleManager',
			children: (
				<Form.Item label="Руководитель строительства" name="responsibleManagerId">
					<span className="app-form-text">
						{dataSource?.responsibleManager?.name ?? currentProject.responsibleManager?.name}
					</span>
				</Form.Item>
			)
		},
		{
			key: 'owner',
			children:
				viewer?.isAdmin && !dataSource?.ordinance ? (
					<IssueFormSelectorItem
						canEdit={viewer?.isAdmin && !dataSource?.ordinance}
						options={potentialAuthors}
						name={'ownerId'}
						label={'Автор'}
						rules={[{ required: true, message: 'Автор является обязательным полем' }]}
						dataSourceValue={dataSource?.owner?.name ?? `${viewer?.firstName} ${viewer?.lastName}`}
						isLoading={isAuthorsFetching}
						form={form}
					/>
				) : (
					<Form.Item label="Автор" name="ownerId">
						<span className="app-form-text">
							{dataSource?.owner?.name ?? `${viewer?.firstName} ${viewer?.lastName}`}
						</span>
					</Form.Item>
				)
		},
		{
			key: 'buildingPermit',
			children: (
				<IssueFormSelectorItem
					canEdit={canEdit}
					options={buildingPermits}
					name={'buildingPermitId'}
					label={'Разрешение на строительство'}
					rules={[{ required: true, message: 'Выберите разрешение на строительство' }]}
					dataSourceValue={dataSource?.buildingPermit?.name}
					isLoading={isFetching}
					form={form}
				/>
			)
		}
	]
	return (
		<>
			<Card
				title="Основные параметры"
				className="app-work-card"
				classNames={{ body: 'app-work-card__body' }}
			>
				<Descriptions
					items={items}
					size="small"
					column={1}
					colon={false}
					labelStyle={{ display: 'none' }}
					className={'app-drawer-descriptions'}
					bordered
				/>
			</Card>
		</>
	)
}
