import { TWbsTaskForm } from 'entities/wbs-task/model/wbs-task-schema'
import { removeEmpty } from 'shared/helpers'
import { create } from 'zustand'
import { IWbsTaskFilters } from './wbs-tasks-interfaces'

interface IWbsTaskState {
	wbsTaskFilters: IWbsTaskFilters
	currentPage: number
	showFilters: boolean
	totalItems?: number
	showCard: boolean
	currentTaskId: string | null
	initForm: Partial<TWbsTaskForm>
	sortState?: number
	openType: 'wbsTask' | 'wbs' | 'local' | 'main' | null
	setType: (type: 'wbsTask' | 'wbs' | 'local' | 'main' | null) => void
	setWbsTaskFilters: (filters: IWbsTaskFilters) => void
	onPagination: (currentPage: number) => void
	setSearch: (search: string) => void
	setShowFilters: (state: boolean) => void
	setTotalItems: (totalItems: number) => void
	setTask: (id: string) => void
	openCard: (openType: 'wbsTask' | 'wbs' | 'local' | 'main' | null) => void
	closeCard: () => void
	setInitForm: (form: TWbsTaskForm) => void
	unset: () => void
	setSortState: (sortState?: number) => void
}

export const useWbsTaskState = create<IWbsTaskState>((set, get) => ({
	showCard: false,
	currentTaskId: null,
	initForm: {},
	wbsTaskFilters: {},
	currentPage: 1,
	showFilters: false,
	totalItems: undefined,
	sortState: undefined,
	openType: null,
	setType: type => set(() => ({ openType: type })),
	setTask: id => set(() => ({ currentTaskId: id })),
	openCard: openType => set(() => ({ showCard: true, openType: openType })),
	closeCard: () => set(() => ({ showCard: false })),
	setInitForm: form => set(() => ({ initForm: removeEmpty(form) })),
	unset: () => set(() => ({ showCard: false, currentTaskId: null, initForm: {} })),
	setWbsTaskFilters: filters => set(() => ({ wbsTaskFilters: { ...filters } })),
	onPagination: currentPage => set(() => ({ currentPage })),
	setSearch: search =>
		set(() => ({
			filters: { ...get().wbsTaskFilters, search: search.length ? search : undefined },
			currentPage: 1
		})),
	setShowFilters: state => set(() => ({ showFilters: state })),
	setTotalItems: totalItems => set(() => ({ totalItems })),
	setSortState: sortState => {
		set(() => ({ sortState }))
	}
}))
