import { DragEndEvent } from '@dnd-kit/core'
import { arrayMove } from '@dnd-kit/sortable'
import {
	IEsimatePositionMeasureUnit,
	IEsimatePositionProperty,
	IEstimatePosition,
	IEstimatePositionDto,
	IPropertyFilters
} from 'interfaces/IEstimatePosition'
import { IConstructionType, ITechnology, IWork } from 'interfaces/ITechnology'
import { IWorkTree } from 'interfaces/IWorktree'
import { IWbsContainerSection } from 'pages/unmodelPage/wbsContainer'
import { v4 as uuidv4 } from 'uuid'
import { create } from 'zustand'
import { AsmCardApi } from '../api/asm-card-api'

export interface IAsmPermissions {
	admin: boolean
	edit: boolean
	setDP: boolean
	setSDU: boolean
	setDZ: boolean
	outer: boolean
}
export interface IDataSource {
	sourceData: IDataType[]
	work: IWork
}

export interface IDataType {
	amount: number | null
	availableComment: boolean
	isNominated: boolean
	comment: string | null
	formula: string
	id: string
	key: string
	materialId: string | null
	measureUnit: IEsimatePositionMeasureUnit | null
	number: number
	order: number
	rate: number | null
	typeMaterial: IConstructionType | null
	workId: string
	technologyWorkId?: string
	noteSDU: string | null
	noteDZ: string | null
	noteDP: string | null
	priceMaterial: number | null
}
export type TWorkNote = 'noteDP' | 'noteDZ' | 'noteSDU'
interface IAsmCardState {
	commentRecord: IDataType | undefined
	commentCanEdit: boolean
	containerId: string | undefined
	currentTechnology: ITechnology | undefined
	dto: IEstimatePosition | undefined
	estimatePositionId: string | null
	propertyFilters: IPropertyFilters[] | undefined
	section: Partial<IWorkTree> | Partial<IWbsContainerSection> | undefined
	show: boolean
	technologyId: string | undefined
	workData: IDataSource[] | undefined
	workProperties: IEsimatePositionProperty[] | undefined
	propertyValue: string | undefined
	setPropertyValue: (value: string) => void
	onAddRow: (record: IDataType, workId: string) => void
	onDragEnd: ({ active, over }: DragEndEvent, workId: string) => void
	onRemoveRow: (record: IDataType, workId: string) => void
	saveEstimatePosition: (containerId: string, sectionId: string, type: 'wbs' | 'local') => Promise<string>
	validateDto: () => boolean
	setEditableWorks: () => void
	setToEdit: (id: string, section: Partial<IWorkTree>, containerId: string) => void
	setWorkAmount: (workId: string, value: string | undefined) => void
	setWorkNote: (workId: string, note: TWorkNote, value: string | undefined) => void
	setWorkData: (technology: ITechnology) => void
	setWorkDataValue: (
		id: string,
		prop: string,
		value: any,
		workId: string,
		record?: IDataType
	) => void
	setWorkEditableProperties: (property: IEsimatePositionProperty[]) => void
	setWorkProperties: (technology: ITechnology) => void
	setWorkPropertyValue: (propertyId: string, value: string | number | null) => void
	unsetCard: () => void
	updateWorkData: (data: IDataSource) => void
}

const initState = {
	commentRecord: undefined,
	commentCanEdit: false,
	containerId: undefined,
	currentTechnology: undefined,
	dto: undefined,
	estimatePositionId: null,
	propertyFilters: undefined,
	section: undefined,
	show: false,
	technologyId: undefined,
	workData: undefined,
	workProperties: undefined,
	propertyValue: undefined
}

export const useAsmCardState = create<IAsmCardState>((set, get) => ({
	...initState,
	setWorkData: technology =>
		set(() => ({
			workData: technology.works.map(w => ({
				work: w,
				sourceData: w.typeMaterials.map((item, index) => ({
					id: uuidv4(),
					workId: w.id,
					key: index.toString(),
					order: item.order,
					number: index + 1,
					typeMaterial: { ...item.typeMaterial, isOptional: item.isOptional },
					materialId: item.isOptional === true ? null :
						item.typeMaterial.materials.filter(m => !m.isDeleted).length === 1
							? item.typeMaterial.materials.filter(m => !m.isDeleted)[0].id
							: null,
					amount: null,
					formula: item.formula,
					rate: item.rate,
					availableComment: item.typeMaterial.availableComment ?? false,
					comment: null,
					measureUnit:
						item.typeMaterial.materials.filter(m => !m.isDeleted).length === 1
							? (get().currentTechnology?.works.find(w => w.work.id === w?.work.id)?.typeMaterials.find(tm => tm.typeMaterial.id === item.typeMaterial?.id)?.measureUnit ? get().currentTechnology?.works.find(w => w.work.id === w?.work.id)?.typeMaterials.find(tm => tm.typeMaterial.id === item.typeMaterial?.id)?.measureUnit! : (item.typeMaterial.materials.filter(m => !m.isDeleted)[0]
									.measureUnit as unknown as IEsimatePositionMeasureUnit))
							: null,
					noteSDU: null,
					noteDZ: null,
					noteDP: null,
					priceMaterial: null,
					isNominated:
						item.typeMaterial.materials.filter(m => !m.isDeleted).length === 1
							? item.typeMaterial.materials.filter(m => !m.isDeleted)[0].isNominated
							: false
				}))
			}))
		})),
	setWorkProperties: technology =>
		set(() => ({
			workProperties: technology.properties
				.filter(p => !p.property.isDeleted)
				.map(prop => ({
					id: prop.id,
					property: {
						id: prop.property.id,
						name: prop.property.name,
						measureUnit: prop.property.measureUnit?.id ?? null,
						isRounded: prop.property.isRounded
					},
					value: null,
					name: prop.property.name,
					identityName: prop.property.identityName,
					valueType: prop.property.valueType,
					baseQuantity: prop.baseQuantity,
					required: prop.property.required,
					measureUnitName: prop.property.measureUnit?.name ?? null,
					isDeleted: prop.property.isDeleted,
					commentAdditionalProperty: prop.commentAdditionalProperty,
					order: prop.order
				}))
		})),
	setWorkEditableProperties: property =>
		set(() => ({
			workProperties: property?.map(p => ({
				...p,
				measureUnitName: p.property?.measureUnit ?? null
			}))
		})),
	setWorkAmount: (workId, value) => {
		const workData = get().workData
		set(() => ({
			workData: workData!.map(w => ({
				work:
					w.work.id === workId || w.work.work.id === workId
						? {
								...w.work,
								amount: +value!
						  }
						: w.work,
				sourceData: w.sourceData
			}))
		}))
	},
	setWorkNote: (workId, note, value) => {
		const workData = get().workData
		set(() => ({
			workData: workData!.map(w => ({
				work:
					w.work.id === workId || w.work.work.id === workId
						? {
								...w.work,
								[note]: value!
						  }
						: w.work,
				sourceData: w.sourceData
			}))
		}))
	},
	setWorkPropertyValue: (propertyId, value) => {
		set(() => ({
			workProperties: get().workProperties?.map(prop => {
				if (prop.property.id === propertyId) {
					return { ...prop, value }
				}
				return prop
			})
		}))
	},
	updateWorkData: data => {
		set(() => ({
			workData: get().workData?.map(wd => {
				if (wd.work.id === data.work.id) {
					return data
				}
				return wd
			})
		}))
	},
	setWorkDataValue: (id, prop, value, workId, record) => {
		const data = get().workData
		const work = data?.find(w => w.work.id === workId)
		const dto = get().dto
		const newData = work?.sourceData?.map((p, i) => {
			if (p.id === id) {
				if (prop === 'materialId') {
					return {
						...p,
						[prop]: value,
						comment: null,
						amount: value !== undefined ? p.amount : null,
						isNominated:
							record?.typeMaterial?.materials.find(m => m.id === value)?.isNominated ?? false
					}
				} else {
					return {
						...p,
						[prop]: value
					}
				}
			}
			return p
		})
		if (record) {
			const materialId = newData?.find(
				d => d.id === record.id && d.typeMaterial?.id === record.typeMaterial?.id
			)?.materialId
			const measureUnit = dto?.works.find(w => w.id === record.workId)?.materials.find(m => m.id === id)?.material.id === value ? dto?.works.find(w => w.id === record.workId)?.materials.find(m => m.id === id)?.measureUnit : get().currentTechnology?.works.find(w => w.work.id === work?.work.work.id)?.typeMaterials.find(tm => tm.typeMaterial.id === record.typeMaterial?.id)?.measureUnit ? get().currentTechnology?.works.find(w => w.work.id === work?.work.work.id)?.typeMaterials.find(tm => tm.typeMaterial.id === record.typeMaterial?.id)?.measureUnit : record.typeMaterial?.materials.find(m => m.id === materialId)?.measureUnit
			const dataWithUnit = newData?.map(nd =>
				nd.id === record.id
					? {
							...nd,
							measureUnit: measureUnit
								? {
										id: measureUnit?.id,
										name: measureUnit?.name,
										fullName: measureUnit?.name,
										isDeleted: false
								  }
								: null
					  }
					: nd
			)
			get().updateWorkData({ work: work?.work!, sourceData: dataWithUnit! })
		} else {
			get().updateWorkData({ work: work?.work!, sourceData: newData! })
		}
	},

	onDragEnd: ({ active, over }, workId) => {
		const data = get().workData?.find(w => w.work.id === workId)
		if (active.id !== over?.id) {
			const activeIndex = data?.sourceData.findIndex(i => i.key === active.id)
			const overIndex = data?.sourceData.findIndex(i => i.key === over?.id)
			const moved = arrayMove(data?.sourceData!, activeIndex!, overIndex!)
			get().updateWorkData({
				work: data?.work!,
				sourceData: moved.map((m, i) => ({ ...m, key: i.toString(), order: i, number: i + 1 }))
			})
		}
	},
	onAddRow: (record, workId) => {
		const data = get().workData?.find(w => w.work.id === workId)
		const newRow = {
			id: uuidv4(),
			workId: record.workId,
			key: '0',
			order: 0,
			number: 0,
			typeMaterial: record.typeMaterial,
			materialId:
				record.typeMaterial?.materials.filter(m => !m.isDeleted).length === 1
					? record.typeMaterial.materials.filter(m => !m.isDeleted)[0].id
					: null,
			isNominated:
				record.typeMaterial?.materials.filter(m => !m.isDeleted).length === 1
					? record.typeMaterial.materials.filter(m => !m.isDeleted)[0].isNominated
					: false,
			amount: null,
			formula: record.formula,
			rate: record.rate,
			availableComment: record.availableComment ?? false,
			comment: null,
			measureUnit:
				record.typeMaterial?.materials.filter(m => !m.isDeleted).length === 1
					? record.measureUnit
					: null,
			noteSDU: null,
			noteDZ: null,
			noteDP: null,
			priceMaterial: null
		}
		const idx = data?.sourceData?.findIndex(i => i.id === record.id)! + 1
		const newData = data?.sourceData!
		newData.splice(idx, 0, newRow)
		const ordered = newData.map((m, i) => ({
			...m,
			key: i.toString(),
			order: i + 1,
			number: i + 1
		}))
		get().updateWorkData({ work: data?.work!, sourceData: ordered })
	},
	onRemoveRow: (record, workId) => {
		const data = get().workData?.find(w => w.work.id === workId)
		const newData = data
			?.sourceData!.filter(s => s.id !== record.id)
			.map((m, i) => ({ ...m, key: i.toString(), order: i, number: i + 1 }))
		get().updateWorkData({ work: data?.work!, sourceData: newData! })
	},
	setToEdit: async (id, section, containerId) => {
		const dto = await AsmCardApi.getStatement(id).then(data => ({
			...data,
			properties: data?.properties?.filter(
				p => p.isDeleted === false || (p.isDeleted === true && p.value?.toString().length)
			)
		}))
		set(() => ({
			containerId,
			dto,
			estimatePositionId: dto.id,
			section,
			show: true,
			technologyId: dto.technology.id
		}))
	},
	validateDto: () => {
		const technology = get().currentTechnology
		const dto = get().dto
		return (
			dto !== undefined &&
			(!!technology?.works.length ? dto.works
				.map(w => technology?.works.find(x => x.work.id === w.work?.id))
				.some(x => x === undefined) : dto.works
				.some(x => x === undefined))
		)
	},
	setEditableWorks: () => {
		const technology = get().currentTechnology
		const dto = get().dto
		if (technology !== undefined && dto !== undefined) {
			const workData = dto.works.map(w => ({
				work: {
					...technology.works.find(x => x.work.id === w.work?.id)!,
					id: w.id,
					amount: w.amount!,
					noteSDU: w.noteSdu ?? null,
					noteDZ: w.noteDz ?? null,
					noteDP: w.noteDp ?? null,
					priceMaterial: w.priceMaterial ?? null,
					priceService: w.priceService ?? null,
					rate: w.norma!,
					order: w.order!,
					work: w.work!,
					measureUnit: w.measureUnit!,
					formula: w.formula!
				},
				sourceData: w.materials
					.sort((a, b) => a.order! - b.order!)
					.map((item, index) => ({
						id: item.id,
						workId: w.id,
						technologyWorkId: w.work?.id,
						key: index.toString(),
						order: item.order!,
						number: index + 1,
						typeMaterial:
							technology.works
								.find(x => x.work.id === w.work?.id)
								?.typeMaterials.find(tm => tm.typeMaterial.id === item.typeMaterial.id)
								?.typeMaterial
								?? null,
						materialId: item.material.id!,
						amount: item.amount!,
						formula: item.formula!,
						rate: item.norma!,
						isNominated: item.isNominated,
						availableComment: item.typeMaterial.availableComment!,
						comment: item.materialComment ?? null,
						measureUnit: item?.measureUnit ?? null,
						noteSDU: item.noteSdu ?? null,
						noteDZ: item.noteDz ?? null,
						noteDP: item.noteDp ?? null,
						priceMaterial: item.priceMaterial ?? null
					}))
			}))
			set(() => ({
				workData: workData
			}))
			get().setWorkEditableProperties(dto.properties)
		}
	},
	async saveEstimatePosition(containerId, sectionId, type) {
		const workData = get().workData
		const dto: IEstimatePositionDto = {
			id: get().estimatePositionId ?? null,
			containerId: containerId,
			containerSectionId: sectionId,
			version: get().dto?.version ?? '',
			technologyId: get().currentTechnology?.id!,
			works: workData!.map(data => ({
				id: data.work.id,
				workId: data.work.work.id,
				amount: data.work.amount ?? 0,
				order: data.work.order,
				formula: data.work.formula,
				norma: data.work.rate,
				measureUnitId: data.work.measureUnit?.id ?? null,
				noteSDU: data.work.noteSDU,
				noteDZ: data.work.noteDZ,
				noteDP: get().currentTechnology?.works.find(w => w.id === data.work.id)?.noteDP ?? data.work.noteDP,
				priceMaterial: data.work.priceMaterial ?? 0,
				priceService: data.work.priceService ?? 0,
				materials: data.sourceData
					.filter(m => m.materialId)
					.map(mat => ({
						id: mat.id,
						key: mat.key,
						materialId: mat.materialId!,
						typeMaterialId: mat.typeMaterial?.id!,
						measureUnitId: null,
						amount: mat.amount ?? 0,
						order: data.sourceData.find(el => el.order === 0) ? mat.order + 1 : mat.order,
						formula: mat.formula,
						norma: mat.rate!,
						materialComment: mat.comment!,
						noteSDU: mat.noteSDU,
						noteDZ: mat.noteDZ,
						noteDP: get().currentTechnology?.works.find(w => w.id === data.work.id)?.typeMaterials.find(tm => tm.typeMaterial.id === mat.typeMaterial?.id)?.noteDP ?? mat.noteDP,
						isNominated: mat.isNominated,
						priceMaterial: mat.priceMaterial ?? 0
					}))
			})),
			properties:
				get().workProperties?.map(prop => ({
					id: prop.id,
					identityName: prop.identityName,
					name: prop.name,
					propertyId: prop.property.id,
					value: prop.value,
					valueType: prop.valueType
				})) ?? []
		}
		return await AsmCardApi.saveStatement(dto, get().estimatePositionId ? 'patch' : 'post', type)
	},
	setPropertyValue: (propertyValue) => set(() => ({propertyValue})) ,
	unsetCard: () => set(() => ({ ...initState }))
}))
