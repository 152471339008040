export enum WBS_TASK_STATUS {
	new = 'e3aa4f34-9052-4292-ad6d-3ac3496c06cd',
	docs = 'a85786ce-3486-4799-8def-8e0a04703977',
	inProgress = '6be52899-4e54-41c7-99c4-2e7ca549beb5',
	done = '2f460356-1f2b-4918-ac68-93298cc38dc7',
	additionalRequestAuthor = '04c13887-aade-4537-8935-0f3b9d53050e',
	additionalRequestDP = 'bb40d0cd-b9a6-4ca2-9e57-498ee97a6ef0',
	additionalRequestAuthorDP = '56d3131e-eaae-4c92-af3f-958525a26e9f',
	waiting = 'd6cb7bee-92c5-4a7b-89c1-5559e8eac2b3'
}
