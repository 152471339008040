import { create } from "zustand";
import { IWbsContainersByProjects, IWbsStatusReport, IWbsStatusReportFilters } from "./wbs-status-report-interface";

interface IWbsStatusReportState {
    filters: IWbsStatusReportFilters
    WbsStatusReportData: IWbsStatusReport[]
    projectReport?: IWbsContainersByProjects[]
    currentPage: number
	totalItems: number
    totalPages: number | undefined
    download: boolean
    type?: string
    setType: (type?: string) => void
    onPagination: (page: number) => void
	setTotalItems: (items: number) => void
	setTotalPages: (pages: number) => void
    setProjectReport: (projectReport: IWbsContainersByProjects[]) => void
    setData: (data: IWbsStatusReport[]) => void
    setFilters: (filters:IWbsStatusReportFilters ) => void
    resetData: () => void
}


export const useWbsStatusReportState = create<IWbsStatusReportState>(set => ({
    WbsStatusReportData: [],
    filters: {},
    projectReport: undefined,
    currentPage: 1,
	totalItems: 1,
	totalPages: undefined,
    download: false,
    type: undefined,
    setType: type => set(() => ({type})),
    onPagination: page => set(() => ({ currentPage: page })),
	setTotalItems: items => set(() => ({ totalItems: items })),
	setTotalPages: pages => set(() => ({ totalPages: pages })),
    setProjectReport: (projectReport) => set(() => ({projectReport: projectReport})) ,
    setData: (data) => set(() => ({ WbsStatusReportData: data })),
    setFilters: (filters) => set(() => ({ filters: filters })),
    resetData: () => set(() => ({ WbsStatusReportData: [] }))
}))