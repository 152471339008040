import { SaveOutlined } from '@ant-design/icons'
import {
	Button,
	Card,
	Col,
	DatePicker,
	Descriptions,
	Drawer,
	Flex,
	Form,
	Input,
	Modal,
	notification,
	Row,
	Select,
	Switch
} from 'antd'
import { DescriptionsItemType } from 'antd/es/descriptions'
import dayjs from 'dayjs'

import { useAppSelector } from 'hooks/appReduxHook'
import { useWorkPackages } from 'hooks/useWorkPackage'
import { isEqual } from 'lodash'
import { useEffect, useState } from 'react'
import { IWorkPackage } from 'services/workPackageService'
import { DATE_FORMAT, PERMISSIONS } from 'shared/constants'
import { removeEmpty } from 'shared/helpers'
import { useCheckPermissions } from 'shared/useCheckPermissions'
import { useShallow } from 'zustand/react/shallow'
import { getTemplatesOrders } from '../api/checklist-template-card-api'
import { useSaveChecklistTemplate } from '../api/checklist-template-card-mutate'
import { useChecklistTemplateCard } from '../api/checklist-template-card-query'
import {
	TChecklistTemplateForm,
	TChecklistTemplateRequestDto,
	TTemplateTypeSchema
} from '../model/checklist-template-card-schema'
import { useChecklistTemplateCardState } from '../model/checklist-template-card-state'
import { Section } from './ChecklistTemplateSection'
import { Stage } from './ChecklistTemplateStages'

import { AbsFormSelect, absRequiredMark } from 'features/abs-form'
import { TEMPLATE_TYPE } from 'shared/constants/checklist-types'
import { validateChecklistTemplate } from '../model/checklist-card-helper'

export const ChecklistTemplateCard = () => {
	const [initType, setInitType] = useState<TEMPLATE_TYPE | undefined>()
	const [templateForm] = Form.useForm<TChecklistTemplateForm>()
	const { project: currentProject, user } = useAppSelector(state => state.environment)
	const currentTemplateTypeCard = Form.useWatch('templateType', templateForm)
	const currentIsCommon = Form.useWatch('isCommon', templateForm)
	const [currentTemplateFields, setCurrentTemplateFields] = useState<DescriptionsItemType[]>([])
	const [checkedOrders, setCheckedOrders] = useState<number[]>([])
	const { data: checklistTemplateData, isFetching } = useChecklistTemplateCard()
	const { data: workPackageData } = useWorkPackages()
	const checkPermissions = useCheckPermissions()
	const canEdit =
		checkPermissions([PERMISSIONS.ChecklistsTemplatesEditUksNdc], currentProject?.id) ||
		checkPermissions([PERMISSIONS.ChecklistsTemplatesEditUkKc], currentProject?.id)
	const { currentTemplateId, isNew, initForm, setInitForm, unset } = useChecklistTemplateCardState(
		useShallow(state => ({
			currentTemplateId: state.currentTemplateId,
			initForm: state.initForm,
			isNew: state.showCard,
			setInitForm: state.setInitForm,
			unset: state.unset
		}))
	)

	const templateTypeList: Record<TTemplateTypeSchema, { name: string; id: number }> = {
		[TEMPLATE_TYPE.ndc]: { name: 'УКС НДК', id: 0 },
		[TEMPLATE_TYPE.ukkc]: { name: 'УК КС', id: 1 }
	}

	const templateTypeFormFields: Record<TTemplateTypeSchema, string[]> = {
		[TEMPLATE_TYPE.ndc]: [
			'workPackage',
			'isCommon',
			'createdAt',
			'createdBy',
			'description',
			'location',
			'updatedAt',
			'isActive'
		],
		[TEMPLATE_TYPE.ukkc]: [
			'isCommon',
			'createdAt',
			'createdBy',
			'quarantyPeriod',
			'assignedByElimination',
			'description',
			'updatedAt',
			'checkboxUk',
			'bySection',
			'isActive'
		]
	}

	const canCreateUkKc = checkPermissions(
		[PERMISSIONS.ChecklistsTemplatesEditUkKc],
		currentProject?.id
	)
	const canCreateUksNdc = checkPermissions(
		[PERMISSIONS.ChecklistsTemplatesEditUksNdc],
		currentProject?.id
	)

	const templateTypeOptions = Object.values(templateTypeList)

	let filteredTemplateTypeOptions = templateTypeOptions
	if (canCreateUkKc && !canCreateUksNdc) {
		filteredTemplateTypeOptions = templateTypeOptions.filter(opt => opt.id === TEMPLATE_TYPE.ukkc)
	} else if (!canCreateUkKc && canCreateUksNdc) {
		filteredTemplateTypeOptions = templateTypeOptions.filter(opt => opt.id === TEMPLATE_TYPE.ndc)
	}

	const onSave = (values: TChecklistTemplateForm) => {
		const error = validateChecklistTemplate(values, currentTemplateTypeCard!)
		if (error) {
			return notification.error({
				message: error,
				placement: 'topLeft'
			})
		}
		let updatedStages
		let updatedSections

		if (currentTemplateTypeCard === TEMPLATE_TYPE.ndc && values.checkListTemplateStages) {
			updatedStages = values.checkListTemplateStages.map((stage, index) => ({
				...stage,
				title: stage.title || `Этап ${index + 1}`,
				order: index,
				sections: stage.sections?.map((section, secIndex) => ({
					...section,
					order: secIndex,
					items: section.items?.map((item, itemIndex) => ({
						...item,
						order: itemIndex
					}))
				}))
			}))
		}

		if (currentTemplateTypeCard === TEMPLATE_TYPE.ukkc && values.sections) {
			updatedSections = values.sections.map((section, secIndex) => ({
				...section,
				order: secIndex,
				items: section.items?.map((item, itemIndex) => ({
					...item,
					order: itemIndex
				}))
			}))
		}

		const dto: TChecklistTemplateRequestDto = {
			...values,
			id: currentTemplateId!,
			period: dayjs(checklistTemplateData?.period).format(DATE_FORMAT.dto),
			quarantyPeriod: values.quarantyPeriod
				? dayjs(values.quarantyPeriod).format(DATE_FORMAT.dto)
				: null,
			projectId: checklistTemplateData?.projectId ?? currentProject.id,
			plannedDate: values.plannedDate ? dayjs(values.plannedDate).format(DATE_FORMAT.dto) : null,
			checkListTemplateStages: updatedStages,
			sections: updatedSections
		}
		mutate([dto] as TChecklistTemplateRequestDto[])
	}

	const onCloseHandler = () => {
		const testForm = removeEmpty(initForm)
		const currentForm = removeEmpty(templateForm.getFieldsValue(true))

		if (!isEqual(currentForm, testForm)) {
			Modal.confirm({
				title: 'Внимание',
				content: 'Есть не сохранённые данные. Вы уверены, что хотите выйти без сохранения?',
				onOk: () => {
					templateForm.resetFields()
					setInitType(undefined)
					setCurrentTemplateFields([])
					unset()
				}
			})
		} else {
			templateForm.resetFields()
			setInitType(undefined)
			setCurrentTemplateFields([])
			unset()
		}
	}

	const onCardClose = () => {
		templateForm.resetFields()
		setInitType(undefined)
		setCurrentTemplateFields([])
		unset()
	}

	const { mutate, isLoading: mutateIsLoading } = useSaveChecklistTemplate(
		currentTemplateTypeCard,
		onCardClose
	)

	useEffect(() => {
		const init = async () => {
			await getTemplatesOrders().then(data => setCheckedOrders(data.checkedOrders))
		}
		init()
	}, [])

	useEffect(() => {
		if (checklistTemplateData) {
			const init: TChecklistTemplateForm = {
				assignedByElimination: checklistTemplateData.assignedByElimination,
				title: checklistTemplateData.title,
				templateType: checklistTemplateData.templateType,
				bySection: checklistTemplateData.bySection,
				sections: checklistTemplateData.sections,
				checkListTemplateStages: checklistTemplateData.checkListTemplateStages,
				cipher: checklistTemplateData.cipher,
				workPackageId: checklistTemplateData.workPackageId,
				description: checklistTemplateData.description,
				location: checklistTemplateData.location,
				isActive: checklistTemplateData.isActive,
				projectId: checklistTemplateData.projectId,
				checkboxUk: checklistTemplateData.checkboxUk,
				period:
					checklistTemplateData.period && dayjs(checklistTemplateData.period).isValid()
						? dayjs(checklistTemplateData.period)
						: null,
				plannedDate:
					checklistTemplateData.plannedDate && dayjs(checklistTemplateData.plannedDate).isValid()
						? dayjs(checklistTemplateData.plannedDate)
						: null,
				quarantyPeriod:
					checklistTemplateData.quarantyPeriod &&
					dayjs(checklistTemplateData.quarantyPeriod).isValid()
						? dayjs(checklistTemplateData.quarantyPeriod)
						: null,
				isCommon: checklistTemplateData.isCommon,
				order: checklistTemplateData.order
			}
			setInitType(checklistTemplateData.templateType)
			setInitForm(init)
			templateForm.setFieldsValue(init)
		}
	}, [checklistTemplateData])

	useEffect(() => {
		if (typeof currentTemplateTypeCard !== 'undefined') {
			const fields: DescriptionsItemType[] = templateTypeFormFields[currentTemplateTypeCard]?.map(
				c => checklistTemplateFields.find(cf => cf.key === c)!
			)
			setCurrentTemplateFields(fields)
		}
	}, [currentTemplateTypeCard])

	const commonFields: DescriptionsItemType[] = [
		{
			key: 'templateType',
			children: (
				<AbsFormSelect
					canEdit={true}
					rules={[{ required: true }]}
					name={'templateType'}
					label={'Тип чек-листа'}
					isLoading={false}
					form={templateForm}
					options={filteredTemplateTypeOptions}
				/>
			)
		},
		{
			key: 'title',
			children: (
				<Form.Item label="Название" name="title" rules={[{ required: true }]} style={{ margin: 0 }}>
					<Input variant="borderless" />
				</Form.Item>
			)
		}
	]
	const checklistTemplateFields: DescriptionsItemType[] = [
		{
			key: 'isCommon',
			children: (
				<Form.Item label="Общий для всех проектов" name="isCommon" style={{ margin: 0 }}>
					<Switch size="small" style={{ marginLeft: '1rem' }} />
				</Form.Item>
			)
		},
		{
			key: 'description',
			children: (
				<Form.Item label="Описание" name="description" style={{ margin: 0 }}>
					<Input.TextArea variant="borderless" />
				</Form.Item>
			)
		},
		{
			key: 'location',
			children: (
				<Form.Item label="Локация" name="location" style={{ margin: 0 }}>
					<Input variant="borderless" />
				</Form.Item>
			)
		},
		{
			key: 'isActive',
			children: (
				<Form.Item label="Активный" name="isActive" style={{ margin: 0 }}>
					<Switch size="small" style={{ marginLeft: '1rem' }} />
				</Form.Item>
			)
		},
		{
			key: 'workPackage',
			children: (
				<Form.Item label="Пакет работ" name="workPackageId" style={{ margin: 0 }}>
					<Select
						variant="borderless"
						options={workPackageData?.map((item: IWorkPackage) => ({
							label: item.name,
							value: item.id
						}))}
						allowClear
					/>
				</Form.Item>
			)
		},
		{
			key: 'quarantyPeriod',
			children: (
				<Form.Item label="Срок гарантий по договору застройщика" name="quarantyPeriod">
					<DatePicker
						variant="borderless"
						format="DD.MM.YYYY"
						style={{ width: '100%' }}
						disabledDate={current => {
							let pastDate = dayjs().format('YYYY-MM-DD')
							return current && current < dayjs(pastDate, 'YYYY-MM-DD')
						}}
					/>
				</Form.Item>
			)
		},
		{
			key: 'assignedByElimination',
			children: (
				<Form.Item
					label="Ответственный за устранение застройщика"
					name="assignedByElimination"
					style={{ margin: 0 }}
				>
					<Input variant="borderless" />
				</Form.Item>
			)
		},
		{
			key: 'checkboxUk',
			children: (
				<Form.Item
					label="Критичен для управляющей компании"
					name="checkboxUk"
					valuePropName="checked"
					style={{ margin: 0 }}
				>
					<Switch size="small" style={{ marginLeft: '1rem' }} />
				</Form.Item>
			)
		},
		{
			key: 'bySection',
			children: (
				<Form.Item
					label="Посекционно"
					name="bySection"
					valuePropName="checked"
					style={{ margin: 0 }}
				>
					<Switch size="small" style={{ marginLeft: '1rem' }} />
				</Form.Item>
			)
		},
		{
			key: 'createdAt',
			children: checklistTemplateData?.id && (
				<Form.Item name="createdAt" label="Дата создания">
					<span style={{ marginLeft: '12px' }}>
						{dayjs(checklistTemplateData?.createdAt).format(DATE_FORMAT.view)}
					</span>
				</Form.Item>
			)
		},
		{
			key: 'updatedAt',
			children: checklistTemplateData?.id && (
				<Form.Item name="updatedAt" label="Дата обновления">
					<span style={{ marginLeft: '1rem' }}>
						{dayjs(checklistTemplateData?.updatedAt).format(DATE_FORMAT.view)}
					</span>
				</Form.Item>
			)
		},
		{
			key: 'createdBy',
			children: checklistTemplateData?.id && (
				<Form.Item name="createdBy" label="Автор">
					<span style={{ marginLeft: '12px' }}>{checklistTemplateData?.createdBy ?? '—'}</span>
				</Form.Item>
			)
		}
	]

	const onFormChange = (value: any) => {
		if (Object.keys(value)[0] === 'templateType') {
			if (typeof initType !== 'undefined' && Object.values(value)[0] !== initType) {
				Modal.confirm({
					title: 'Внимание!',
					content: 'Все изменения будут удалены',
					onOk: () => {
						templateForm.setFieldValue('sections', undefined)
						templateForm.setFieldValue('isCommon', false)
					},
					onCancel: () => {
						setInitType(initType)
						templateForm.setFieldValue('templateType', initType)
					}
				})
			}
			setInitType(Object.values(value)[0] as TEMPLATE_TYPE)
		}
	}

	return (
		<Form
			name="templateForm"
			form={templateForm}
			scrollToFirstError={{
				block: 'center'
			}}
			colon={false}
			labelAlign="left"
			labelCol={{ span: 6 }}
			layout="inline"
			onFinish={onSave}
			onValuesChange={onFormChange}
			requiredMark={absRequiredMark}
		>
			<Drawer
				classNames={{
					body: 'app-entity-card__body',
					header: `app-entity-card__header app-entity-card__header--void`
				}}
				styles={{ body: { background: '#fafafa' } }}
				loading={isFetching}
				onClose={onCloseHandler}
				destroyOnClose={true}
				open={currentTemplateId !== null || isNew}
				width={window.outerWidth < 1300 ? '75vw' : '50vw'}
				title={
					checklistTemplateData
						? `Шаблон чек-листа ${checklistTemplateData.title} от ${dayjs(
								checklistTemplateData.createdAt
						  ).format(DATE_FORMAT.view)}`
						: 'Создание шаблона чек-листа'
				}
				extra={
					<Button
						style={{ borderColor: '#fff', color: '#fff' }}
						ghost
						onClick={() => templateForm.submit()}
						icon={<SaveOutlined />}
						loading={mutateIsLoading}
					>
						Сохранить
					</Button>
				}
			>
				<Flex vertical gap={24}>
					<Card
						title={
							<Row justify="space-between" align="middle">
								<Col style={{ fontSize: '16px' }}>Основные параметры:</Col>
								{currentTemplateTypeCard === TEMPLATE_TYPE.ndc && (
									<Col>
										<Form.Item
											name="cipher"
											label="Шифр"
											labelCol={{
												style: {
													top: '8px'
												}
											}}
											style={{ marginBottom: 0 }}
										>
											<Input placeholder="Введите шифр" variant="borderless" disabled={!canEdit} />
										</Form.Item>
									</Col>
								)}
								{currentTemplateTypeCard === TEMPLATE_TYPE.ukkc && currentIsCommon && (
									<Col span={7}>
										<Row align="middle">
											<Col>Порядковый номер: </Col>
											<Col flex={1}>
												<Form.Item
													name="order"
													rules={[{ required: true, message: 'Укажите порядковый номер' }]}
												>
													<Select
														variant="borderless"
														allowClear
														disabled={!canEdit}
														showSearch
														style={{ width: '100%', marginTop: '23px' }}
														options={Array.from(Array(100).keys()).map(item => ({
															value: item + 1,
															label: item + 1,
															disabled:
																checklistTemplateData?.order !== item + 1 &&
																checkedOrders.some(x => x === item + 1)
														}))}
													/>
												</Form.Item>
											</Col>
										</Row>
									</Col>
								)}
							</Row>
						}
						className="app-work-card"
						classNames={{ body: 'app-work-card__body' }}
					>
						<Descriptions
							items={[...commonFields, ...currentTemplateFields!]}
							size="small"
							column={1}
							colon={false}
							labelStyle={{ display: 'none' }}
							className={'app-drawer-descriptions'}
							bordered
						/>
					</Card>
					{typeof currentTemplateTypeCard !== 'undefined' && (
						<Card className="app-work-card" classNames={{ body: 'app-work-card__body' }}>
							{currentTemplateTypeCard === TEMPLATE_TYPE.ndc && (
								<Stage form={templateForm} canEdit={canEdit} />
							)}
							{currentTemplateTypeCard === TEMPLATE_TYPE.ukkc && (
								<Section form={templateForm} canEdit={canEdit} />
							)}
						</Card>
					)}
				</Flex>
			</Drawer>
		</Form>
	)
}
