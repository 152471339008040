import { Row } from '@tanstack/table-core'
import { Row as AntRow, Col, Tooltip } from 'antd'
import { memo, useCallback, useMemo } from 'react'
import { findNestedObj } from 'shared/helpers'
import { useShallow } from 'zustand/react/shallow'

import { useAppSelector } from 'hooks/appReduxHook'
import { useSearchParams } from 'react-router-dom'
import { CONTAINER_STATUS, PERMISSIONS } from 'shared/constants'
import { useCheckPermissions } from 'shared/useCheckPermissions'
import { IAdjustmentPosition, IAdjustmentSection } from '../model/adjustment-schema'
import { useAdjustmentState } from '../model/adjustment-state'
import { AdjustmentPositionChat } from './AdjustmentPositionChat'
import { AdjustmentPositionMenu } from './AdjustmentPositionMenu'
import { AdjustmentPositionMove } from './AdjustmentPositionMove'

interface IProps {
	row: IAdjustmentPosition
	callBack?: () => void
	mainRow?: Row<IAdjustmentPosition>
	visaIssued: boolean
}

export const AdjustmentPositionTitle = memo(function (params: IProps) {
	const [_, setSearchParams] = useSearchParams()
	const { row, callBack, mainRow, visaIssued } = params
	const parent: Row<IAdjustmentSection> | undefined = useMemo(
		() => mainRow?.getParentRow() as unknown as Row<IAdjustmentSection>,
		[]
	)
	const checkPermissions = useCheckPermissions()
	const { container, sections } = useAdjustmentState(
		useShallow(state => ({
			container: state.container,
			sections: state.sections
		}))
	)
	const { user: currentUser } = useAppSelector(state => state.environment)

	const currentSection = useCallback(() => {
		return (
			(sections &&
				findNestedObj<IAdjustmentSection>(sections, 'id', row.containerSectionId)?.children
					?.length) ??
			1
		)
	}, [row])

	const canEdit =
		checkPermissions([PERMISSIONS.AdjustmentCreate], container?.project?.id!) &&
		(currentUser?.isAdmin ||
			container?.status.name === CONTAINER_STATUS.new ||
			container?.status.name === CONTAINER_STATUS.preparation) &&
		!visaIssued

	const isAuthor =
		(currentUser?.isAdmin ||
			container?.author.id === currentUser?.id ||
			container?.redactors.some(r => r.id === currentUser?.id)) ??
		false

	const canCreate = useMemo(
		() => canEdit && isAuthor && parent?.original.hasTechnologies === true && !container?.ready,
		[container]
	)

	const canClose = useMemo(
		() =>
			(canEdit &&
				isAuthor &&
				(container?.status.name === CONTAINER_STATUS.new ||
					container?.status.name === CONTAINER_STATUS.preparation ||
					currentUser?.isAdmin) &&
				container?.isLocalEstimate === false &&
				!container?.ready) ??
			false,
		[container]
	)

	const canDelete = useMemo(
		() =>
			canEdit &&
			((typeof container?.isLocalEstimate === 'undefined' && !row.isClosed) ||
				container?.isLocalEstimate === false) &&
			isAuthor &&
			((row.isUnrelated && container?.isLocalEstimate === false) ||
				typeof container?.isLocalEstimate === 'undefined') &&
			!container?.ready,
		[container]
	)

	const canMove = useMemo(
		() =>
			currentSection() > 1 &&
			((typeof container?.isLocalEstimate === 'undefined' && !row.isClosed) ||
				container?.isLocalEstimate === false) &&
			checkPermissions([PERMISSIONS.AdjustmentCreate], container?.project?.id!) &&
			(currentUser?.isAdmin ||
				container?.status.name === CONTAINER_STATUS.new ||
				container?.status.name === CONTAINER_STATUS.preparation) &&
			(container?.isLocalEstimate === false ||
				(typeof container?.isLocalEstimate === 'undefined' &&
					isAuthor &&
					(currentUser?.isAdmin ||
						container?.status.name === CONTAINER_STATUS.new ||
						container?.status.name === CONTAINER_STATUS.preparation))) &&
			!visaIssued,
		[container]
	)

	const title = useMemo(
		() => (
			<Tooltip
				rootClassName="tooltip-wide"
				title={`${row.order} - ${row.workName} ${row.technologyIsDeleted ? 'АРХИВ' : ''}`}
			>
				<div className="ellipsis-block">
					{`${row.order} - ${row.workName}`}
					{row.technologyIsDeleted && <span style={{ marginLeft: '8px' }}>АРХИВ</span>}
				</div>
			</Tooltip>
		),
		[row]
	)
	const toggleRow = useCallback(() => (callBack ? callBack() : null), [container])
	const setToEdit = useCallback(() => {
		container && setSearchParams({ estimatePosition: row.id })
	}, [container])

	return (
		<AntRow style={{ width: '100%' }} align="middle" justify="space-between" wrap={false}>
			<Col flex={1} onClick={toggleRow} onDoubleClick={setToEdit}>
				{title}
			</Col>
			<Col>
				<AntRow gutter={8} align="middle" wrap={false}>
					{!container?.ready && !row.isDeleted && (
						<Col>
							<AntRow gutter={8} align="middle" wrap={false} className="app-wbs-move-controls">
								{canMove && (
									<Col>
										<AdjustmentPositionMove
											row={row}
											section={parent}
											isMultiply={false}
											isAuthor={isAuthor}
										/>
									</Col>
								)}
							</AntRow>
						</Col>
					)}
					{!row.isDeleted && (
						<Col>
							<AdjustmentPositionChat row={row} />
						</Col>
					)}
					{mainRow && (
						<Col>
							<AdjustmentPositionMenu
								row={row}
								mainRow={mainRow}
								canCreate={canCreate}
								canClose={canClose}
								canDelete={canDelete}
							/>
						</Col>
					)}
				</AntRow>
			</Col>
		</AntRow>
	)
})
