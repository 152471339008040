import { useQuery } from "@tanstack/react-query"
import { diagramsProjectApi } from "../api/diagrams-project-api"
import { useDiagramsProjectState } from "./diagrams-project-state"

export const useProjectsData = () => {
  return useQuery({
    queryKey: ['projectsProject'],
      refetchOnWindowFocus: false,
      queryFn: async () => {
        return await diagramsProjectApi.getProjects().then(data => data)
      }
    })
}

export const useProjectContractors = (projectIds?: string) => {
  const { projectId } = useDiagramsProjectState()

  return useQuery({
    queryKey: ['contractorsProject', projectId, projectIds],
      refetchOnWindowFocus: false,
      queryFn: async () => {
        if (projectIds) return await diagramsProjectApi.getContractors([projectIds]).then(data => data)
      return null
      }
    })
}

export const useProjectAuthors = (projectId: string, contractorIds: string[]) => {
  return useQuery({ 
    queryKey: ['authorsProject', projectId, contractorIds],
    refetchOnWindowFocus: false,
    queryFn: async () => {
    if (projectId && contractorIds)  return await diagramsProjectApi.getAuthors(projectId, contractorIds).then(data => data)
      return null
    }
    }
  )
}

export const useCheckListsPlannedDate = () => {
  const { projectId, plannedDate, contractorIds, authors } = useDiagramsProjectState()
  return useQuery({
    queryKey: ['checkListsPlannedDateProject', projectId, plannedDate, contractorIds, authors],
      refetchOnWindowFocus: false,
      queryFn: async () => {
        if (projectId && contractorIds && authors) return await diagramsProjectApi.getCheckListsPlannedDate({ projectId: projectId!, plannedDate: plannedDate?.toISOString()!, createdByIds: authors!,
          contractorIds: contractorIds! }).then(data => data)
          return null
      }
    })
}

export const useIssues = () => {
  const { projectId, contractorIds, authors } = useDiagramsProjectState()
  return useQuery({
    queryKey: ['issuesProject', projectId, contractorIds, authors],
      refetchOnWindowFocus: false,
      queryFn: async () => {
        if (projectId && contractorIds && authors)  return await diagramsProjectApi.getCountIssues({
          projectIds: [projectId!],
          createdByIds: authors!,
          contractorIds: contractorIds!
        }).then(data => data)
        return null
      }
    })
}

// export const useOrdinanceFinesPerMonth = () => {
//   const { projectId, contractorIds, authors } = useDiagramsProjectState()
//   return useQuery({
//     queryKey: ['ordinanceFinesProject', projectId, contractorIds, authors],
//       refetchOnWindowFocus: false,
//       queryFn: async () => {
//         if (projectId && contractorIds && authors) return await diagramsProjectApi.getOrdinanceFinesPerMonth({
//           projectId: projectId!,
//           createdByIds: authors!,
//           contractorIds: contractorIds!
//         }).then(data => data)
//         return null
//       }
//     })
// }

export const useCountOrdinances = () => {
  const { projectId, contractorIds, authors } = useDiagramsProjectState()
  return useQuery({
    queryKey: ['ordinancesProject', projectId, contractorIds, authors],
      refetchOnWindowFocus: false,
      queryFn: async () => {
        if (projectId && contractorIds && authors) return await diagramsProjectApi.getCountOrdinances({
          projectIds: [projectId!],
          createdByIds:  authors!,
          contractorIds: contractorIds!
        }).then(data => data)
        return null
      }
    })
}

export const useCountCheckLists = () => {
  const { projectId, contractorIds, authors } = useDiagramsProjectState()
  return useQuery({
    queryKey: ['checklistsProject', projectId, contractorIds, authors],
      refetchOnWindowFocus: false,
      queryFn: async () => {
        if (projectId && contractorIds && authors) return await diagramsProjectApi.getCountCheckLists({
          projectIds: [projectId!],
          createdByIds: authors!,
          contractorIds: contractorIds!
        }).then(data => data)
        return null
      }
    })
}

export const useIssueSubTypesDashboard = () => {
  const { projectId, contractorIds, authors } = useDiagramsProjectState()
  return useQuery({
    queryKey: ['issueSubTypesProject', projectId, contractorIds, authors],
      refetchOnWindowFocus: false,
      queryFn: async () => {
        if (projectId && contractorIds && authors){
        return await diagramsProjectApi.getIssueSubTypes({
          projectIds: [projectId],
          createdByIds: authors!,
          contractorIds: contractorIds!
        }).then(data => data)
      }
      return null
      }
    })
}

export const useCountOrdinanceFinesDashboard = () => {
  const { projectId, contractorIds, authors } = useDiagramsProjectState()
  return useQuery({
    queryKey: ['countOrdinanceFinesProject', projectId, authors, contractorIds],
      refetchOnWindowFocus: false,
      queryFn: async () => {
          return await diagramsProjectApi.getCountOrdinanceFines({projectIds: [projectId!],
            createdByIds: authors!,
            contractorIds: contractorIds!})
      }
    })
}