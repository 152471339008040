import { API } from 'services/ApiService'
import {
	IEstimatePositionComments,
	IEstimatePositionCommentsUser,
	IWbsCommentDto
} from '../model/estimate-position-comments-state'

export const EstimatePositionCommentsApi = {
	async getEPComments(epId: string, type: 'wbs' | 'local') {
		return await API<IEstimatePositionComments>({
			url: `v1/estimatePositions/${type === 'wbs' ? 'container' : 'estimate'}/${epId}/comments`,
			method: 'get',
			params: {
				projectId: null
			}
		}).then(response => response.data)
	},
	async addEPComments(epId: string, dto: IWbsCommentDto, type: 'wbs' | 'local') {
		return await API<IEstimatePositionComments>({
			url: `v1/estimatePositions/${type === 'wbs' ? 'container' : 'estimate'}/${epId}/comments`,
			method: 'post',
			params: {
				projectId: null
			},
			data: { ...dto }
		}).then(response => response.data)
	},
	async getEPCommentsUsers(epId: string, type: 'wbs' | 'local') {
		return await API<IEstimatePositionCommentsUser[]>({
			url: `v1/estimatePositions/${type === 'wbs' ? 'container' : 'estimate'}/${epId}/responsible-users-comments`,
			method: 'get',
			params: {
				projectId: null
			}
		}).then(response => response.data)
	},
	async toggleEPChatState(epId: string) {
		return await API<IEstimatePositionCommentsUser[]>({
			url: `v1/estimatePositions/${epId}/comments/state`,
			method: 'patch',
			params: {
				projectId: null
			}
		}).then(response => response.data)
	}
}
