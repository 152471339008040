import { FC } from 'react'
import { Row, Col, Card, Modal } from 'antd'
import { useDiagramsProjectState } from 'widgets/diagrams-project/model/diagrams-project-state'
import {
	useIssues,
	useCountOrdinances,
	useCountCheckLists,
	useCountOrdinanceFinesDashboard
} from '../model/diagrams-project-query'
import { PERMISSIONS } from 'shared/constants'
import { useCheckPermissions } from 'shared/useCheckPermissions'
import { useNavigate } from 'react-router-dom'
import { useOrdinanceFineState } from 'widgets/fine/model/fine-state'
import { useAppDispatch, useAppSelector } from 'hooks/appReduxHook'
import { useQueryClient } from '@tanstack/react-query'
import { changeCurrentProject } from '../model/diagrams-project-helpers'
import { setIssueFilters } from 'store/issueSlice'
import { setFilterOrdinance } from 'store/ordinanceSlice'
import { setCurrentChecklistFilters } from 'store/checklistsSlice'
import dayjs from 'dayjs'

export const DiagramsProjectCharacteristics: FC = () => {
	const queryClient = useQueryClient()
	const dispatch = useAppDispatch()
	const { project: currentProject, user } = useAppSelector(state => state.environment)
	const checkPermissions = useCheckPermissions()
	const navigate = useNavigate()
	const { authors, projectId, contractorIds, setFilters } = useDiagramsProjectState()
	const { setOrdinanceFineFilters } = useOrdinanceFineState()
	const { data: ordinanceFinesValues } = useCountOrdinanceFinesDashboard()

	const { data: ordinance } = useCountOrdinances()
	const { data: issuesData } = useIssues()
	// const { data: finesData } = useOrdinanceFinesPerMonth()
	const { data: checklistData } = useCountCheckLists()

	const totalIssues = issuesData?.totalIssues ?? 0
	const readyForControlIssues = issuesData?.readyForControlIssues ?? 0
	const fixedOutOpen = issuesData?.fixedOutOpenIssues ?? 0

	// const totalOrdinanceFines = finesData?.totalOrdinanceFines ?? 0
	const countOrdinanceNonEliminated = ordinance?.countOrdinanceNonEliminated ?? 0
	const totalCheckLists = checklistData?.totalCheckLists ?? 0

	const cardStyle = {
		border: '1px solid #ddd',
		borderRadius: 10,
		// padding: 8,
		background: '#f2f2f2',
		boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
		textAlign: 'center' as const,
		height: '140px',
		display: 'flex',
		flexDirection: 'column' as const,
		justifyContent: 'start',
		alignItems: 'center',
		cursor: 'pointer'
	}

	const handleIssuesClick = () => {
		if (checkPermissions([PERMISSIONS.IssueView], projectId!)) {
			setFilters({ authors, projectId, contractorIds })
			changeCurrentProject(projectId!, user, dispatch, queryClient)
			dispatch(
				setIssueFilters({
					filters: {
						contractorIds: contractorIds!,
						ownerIds: authors!
					}
				})
			)
			navigate('/control/issues')
		} else {
			Modal.error({
				title: 'Ошибка доступа',
				content:
					'Внимание! Раздел не доступен, поскольку у Вас не хватает прав. Обратитесь к администратору.'
			})
		}
	}

	const handleIssuesOnControlClick = () => {
		if (checkPermissions([PERMISSIONS.IssueView], projectId!)) {
			changeCurrentProject(projectId!, user, dispatch, queryClient)
			dispatch(
				setIssueFilters({
					filters: {
						contractorIds: contractorIds!,
						ownerIds: authors!,
						status: ['5758e9f3-b35b-4d97-8616-aa3bbb96131f']
					}
				})
			)
			navigate('/control/issues')
		} else {
			Modal.error({
				title: 'Ошибка доступа',
				content:
					'Внимание! Раздел не доступен, поскольку у Вас не хватает прав. Обратитесь к администратору.'
			})
		}
	}

	const handleOverdueIssuesClick = () => {
		if (checkPermissions([PERMISSIONS.IssueView], projectId!)) {
			changeCurrentProject(projectId!, user, dispatch, queryClient)
			dispatch(
				setIssueFilters({
					filters: {
						contractorIds: contractorIds!,
						ownerIds: authors!,
						status: ['4ae3200c-d2df-4c70-922a-780ea1575a35'],
						fixedOut: true
					}
				})
			)
			navigate('/control/issues')
		} else {
			Modal.error({
				title: 'Ошибка доступа',
				content:
					'Внимание! Раздел не доступен, поскольку у Вас не хватает прав. Обратитесь к администратору.'
			})
		}
	}
	const handleIssueTomorrowClick = () => {
		if (checkPermissions([PERMISSIONS.IssueView], projectId!)) {
			changeCurrentProject(projectId!, user, dispatch, queryClient)
			dispatch(
				setIssueFilters({
					filters: {
						contractorIds: contractorIds!,
						ownerIds: authors!,
						dueDate: dayjs(new Date()).add(1, 'day').isValid()
							? (dayjs(new Date()).add(1, 'day') as any)
							: null
					}
				})
			)
			navigate('/control/issues')
		} else {
			Modal.error({
				title: 'Ошибка доступа',
				content:
					'Внимание! Раздел не доступен, поскольку у Вас не хватает прав. Обратитесь к администратору.'
			})
		}
	}

	const handleOrdinanceClick = () => {
		if (checkPermissions([PERMISSIONS.OrdinanceView], projectId!)) {
			changeCurrentProject(projectId!, user, dispatch, queryClient)
			dispatch(
				setFilterOrdinance({
					filters: {
						contractorIds: contractorIds!,
						createdByIds: authors!,
						ordinanceStatusId: '9d793d38-c719-41b0-8a7d-d61e641f5da4'
					}
				})
			)
			navigate('/control/ordinance')
		} else {
			Modal.error({
				title: 'Ошибка доступа',
				content:
					'Внимание! Раздел не доступен, поскольку у Вас не хватает прав. Обратитесь к администратору.'
			})
		}
	}
	const handleOrdinanceTomorrowClick = () => {
		if (checkPermissions([PERMISSIONS.OrdinanceView], projectId!)) {
			changeCurrentProject(projectId!, user, dispatch, queryClient)
			dispatch(
				setFilterOrdinance({
					filters: {
						contractorIds: contractorIds!,
						createdByIds: authors!,
						dateTo: dayjs(new Date()).add(1, 'day').isValid()
							? (dayjs(new Date()).add(1, 'day') as any)
							: null
					}
				})
			)
			navigate('/control/ordinance')
		} else {
			Modal.error({
				title: 'Ошибка доступа',
				content:
					'Внимание! Раздел не доступен, поскольку у Вас не хватает прав. Обратитесь к администратору.'
			})
		}
	}

	const handleFinesClick = () => {
		console.log(checkPermissions([PERMISSIONS.OrdinanceFine], projectId!))
		if (!checkPermissions([PERMISSIONS.OrdinanceFine], projectId!)) {
			Modal.error({
				title: 'Ошибка доступа',
				content:
					'Внимание! Раздел не доступен, поскольку у Вас не хватает прав. Обратитесь к администратору.'
			})
		} else {
			changeCurrentProject(projectId!, user, dispatch, queryClient)
			setOrdinanceFineFilters({
				contractorIds: contractorIds!,
				createdByIds: authors!
			})
			navigate('/control/fines')
		}
	}

	const handleChecklistsClick = () => {
		if (checkPermissions([PERMISSIONS.ChecklistsUkKc, PERMISSIONS.ChecklistsUksNdc], projectId!)) {
			changeCurrentProject(projectId!, user, dispatch, queryClient)
			setFilters({ authors, projectId, contractorIds })
			dispatch(
				setCurrentChecklistFilters({
					filters: {
						ownerIds: authors!,
						contractorIds: contractorIds!
					}
				})
			)
			navigate('/checklist')
		} else {
			Modal.error({
				title: 'Ошибка доступа',
				content:
					'Внимание! Раздел не доступен, поскольку у Вас не хватает прав. Обратитесь к администратору.'
			})
		}
	}

	return (
		<>
			<Row gutter={[16, 16]} style={{ paddingBottom: '12px' }}>
				<Col span={24}>
					<Card style={{ padding: '0px' }} className="main-card">
						<span style={{ fontSize: 16, bottom: '10px' }}>Замечания</span>
						<Row gutter={[16, 16]}>
							<Col span={6}>
								<Card
									style={{ ...cardStyle, backgroundColor: '#1677FF' }}
									onClick={handleIssuesClick}
								>
									<div
										style={{
											fontSize: '1.8vw',
											color: 'white',
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'center'
										}}
									>
										{totalIssues}
									</div>
									<span
										style={{
											fontSize: '0.65vw',
											marginBottom: '5px',
											color: 'white',
											alignItems: 'center',
											justifyContent: 'center'
										}}
									>
										Всего
									</span>
								</Card>
							</Col>
							<Col span={6}>
								<Card
									style={{ ...cardStyle, backgroundColor: '#C00205' }}
									onClick={handleOverdueIssuesClick}
								>
									<div
										style={{
											fontSize: '1.8vw',
											color: 'white',
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'center'
										}}
									>
										{fixedOutOpen}
									</div>
									<span
										style={{
											fontSize: '0.65vw',
											marginBottom: '5px',
											color: 'white',
											alignItems: 'center',
											justifyContent: 'center'
										}}
									>
										Просроченных открытых
									</span>
								</Card>
							</Col>
							<Col span={6}>
								<Card
									style={{ ...cardStyle, backgroundColor: '#7A77D9' }}
									onClick={handleIssueTomorrowClick}
								>
									<div
										style={{
											fontSize: '1.8vw',
											color: 'white',
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'center'
										}}
									>
										{issuesData?.tomorrowExpireIssues}
									</div>
									<span
										style={{
											fontSize: '0.65vw',
											marginBottom: '5px',
											color: 'white',
											alignItems: 'center',
											justifyContent: 'center'
										}}
									>
										Будут просрочены завтра
									</span>
								</Card>
							</Col>
							<Col span={6}>
								<Card
									style={{ ...cardStyle, backgroundColor: '#8099A8' }}
									onClick={handleIssuesOnControlClick}
								>
									<div
										style={{
											fontSize: '1.8vw',
											color: 'white',
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'center'
										}}
									>
										{readyForControlIssues}
									</div>
									<span
										style={{
											fontSize: '0.65vw',
											marginBottom: '5px',
											color: 'white',
											alignItems: 'center',
											justifyContent: 'center'
										}}
									>
										На проверке
									</span>
								</Card>
							</Col>
						</Row>
					</Card>
				</Col>
			</Row>
			<Row gutter={[16, 16]}>
				<Col span={12} style={{ paddingRight: '12px', paddingLeft: '8px' }}>
					<Card style={{ padding: '0px' }} className="main-card">
						<span style={{ fontSize: 16, bottom: '10px' }}>Предписания</span>
						<Row gutter={[16, 16]}>
							<Col span={12}>
								<Card
									style={{ ...cardStyle, backgroundColor: '#1677FF' }}
									onClick={handleOrdinanceClick}
								>
									<div
										style={{
											fontSize: '1.8vw',
											color: 'white',
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'center'
										}}
									>
										{countOrdinanceNonEliminated}
									</div>
									<span
										style={{
											fontSize: '0.65vw',
											marginBottom: '5px',
											color: 'white',
											alignItems: 'center',
											justifyContent: 'center'
										}}
									>
										Не устранённых предписаний
									</span>
								</Card>
							</Col>
							<Col span={12}>
								<Card
									style={{ ...cardStyle, backgroundColor: '#C00205' }}
									onClick={handleOrdinanceTomorrowClick}
								>
									<div
										style={{
											fontSize: '1.8vw',
											color: 'white',
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'center'
										}}
									>
										{ordinance?.countOrdinanceTomorrowExpire}
									</div>
									<span
										style={{
											fontSize: '0.65vw',
											marginBottom: '5px',
											color: 'white',
											alignItems: 'center',
											justifyContent: 'center'
										}}
									>
										Будут просрочены завтра
									</span>
								</Card>
							</Col>
						</Row>
					</Card>
				</Col>
				<Col span={6} style={{ paddingRight: '12px', paddingLeft: '0px' }}>
					<Card style={{ padding: '0px' }} className="main-card">
						<span style={{ fontSize: 16, bottom: '10px' }}>Штрафы</span>
						<Card
							style={{
								...cardStyle,
								backgroundColor: '#C00205',
								justifyContent: 'center'
							}}
							onClick={handleFinesClick}
						>
							<div
								style={{
									fontSize: '1.8vw',
									color: 'white'
								}}
							>
								{ordinanceFinesValues?.totalOrdinanceFines}
							</div>
						</Card>
					</Card>
				</Col>
				<Col span={6} style={{ paddingLeft: '0px' }}>
					<Card style={{ padding: '0px' }} className="main-card">
						<span style={{ fontSize: 16, bottom: '10px' }}>Чек-листы</span>
						<Card
							style={{
								...cardStyle,
								backgroundColor: '#1677FF',
								justifyContent: 'center'
							}}
							onClick={handleChecklistsClick}
						>
							<div
								style={{
									fontSize: '1.8vw',
									color: 'white'
								}}
							>
								{totalCheckLists}
							</div>
						</Card>
					</Card>
				</Col>
			</Row>
		</>
	)
}
