import { Row } from '@tanstack/react-table'
import { Row as AntRow, Col } from 'antd'
import { useAppSelector } from 'hooks/appReduxHook'
import { memo, useMemo } from 'react'
import { useWbsPermits } from 'widgets/wbs'
import { useShallow } from 'zustand/react/shallow'
import {
	EstimatePositionSectionMenu,
	EstimatePositionMove,
	IEstimateSection /* useEstimateState */,
	useEstimateState
} from '..'
import { useContainerVisas } from 'widgets/wbs/model/wbs-queries'
import { IStatusRouteItemVisa } from 'pages/unmodelPage/wbsContainer'
import { IBase } from 'interfaces/IBase'

interface IProps {
	row: Row<IEstimateSection>
	callBack?: () => void
	visaIssued: boolean
}

export const EstimateSectionTitle = memo(function (params: IProps) {
	const { row, callBack, visaIssued } = params
	const { selectedRows } = useEstimateState()
	const { user } = useAppSelector(state => state.environment)
	const { container } = useEstimateState(
		useShallow(state => ({
			container: state.container
		}))
	)
	const permits = useWbsPermits(container?.project?.id)
	const isAuthor =
		(user?.isAdmin ||
			container?.author.id === user?.id ||
			container?.redactors.some(r => r.id === user?.id)) ??
		false

	const isLocalEstimate = typeof container?.isLocalEstimate !== 'undefined'
	const canEditCorrectLocalEstimate = container?.isLocalEstimate === false && permits.canLocalEdit
	return (
		<AntRow
			wrap={false}
			gutter={16}
			align="middle"
			justify="space-between"
			style={{
				width: '100%',
				height: '100%',
				color: row.original.hasPositions ? '#0F8BFD' : '#444'
			}}
		>
			<Col onClick={() => (callBack ? callBack() : null)} style={{ width: '100%' }}>
				{row.original.codifier} - {row.original.name}{' '}
				<span style={{ color: 'black' }}>
					{'isDeleted' in row.original && row.original.isDeleted === true ? 'АРХИВ' : ''}
				</span>
			</Col>
			{row.getIsSomeSelected() &&
				selectedRows.parentId === row.id &&
				((!isLocalEstimate && permits.canEdit && isAuthor) ||
					(canEditCorrectLocalEstimate && isAuthor)) &&
				!visaIssued && (
					<Col>
						<EstimatePositionMove
							row={row.original}
							section={row}
							isMultiply={true}
							isAuthor={isAuthor}
						/>
					</Col>
				)}
			<Col>
				<AntRow>
					<Col>
						<EstimatePositionSectionMenu row={row} visaIssued={visaIssued} />
					</Col>
				</AntRow>
			</Col>
		</AntRow>
	)
})
