import { API } from "services/ApiService"
import { Author, ICheckListItem, IIssueCount, IIssueSubTypes, IOrdinanceFineCount } from "../model/diagrams-project-types"
import { ICheckListCount, IContractor, IOrdinanceCount, IProject } from "widgets/diagrams/model/diagrams-types"


export const diagramsProjectApi = {

  async getProjects(project1CIds?: string[], contractorIds?: string[]) {
      return await API<IProject[]>({
        url: `/v1/diagrams/projects`,
        method: 'post',
        data: {project1CIds: project1CIds, contractorIds: contractorIds}
      }).then((response) => response.data)
    },

  async getAuthors(projectId: string, contractorIds?: string[]) {
    return await API<Author[]>({
      url: '/v1/diagrams/authors',
      method: 'post',
      data: { projectId, contractorIds }
    }).then(res => res.data)
  },

   async getContractors(projectIds: string[]) {
     return await API<IContractor[]>({
        url: '/v1/diagrams/contractors',
        method: 'post',
        data: {projectIds: projectIds}
      }).then(response => response.data)
    },

  async getCheckListsPlannedDate(payload: {
    projectId: string
    plannedDate: string | null
    createdByIds?: string[]
    contractorIds?: string[]
  }) {
    return await API<ICheckListItem[]>({
      url: '/v1/diagrams/checkListsPlannedDate',
      method: 'post',
      data: payload
    }).then(res => res.data)
  },

  async getCountIssues(payload: {projectIds: string[], createdByIds?: string[], contractorIds?: string[]}) {
    return await API<IIssueCount>({
      url: "/v1/diagrams/countIssues",
      method: "post",
      data: payload
    }).then((response) => response.data)
  },

  // async getOrdinanceFinesPerMonth(payload: {
  //   projectId: string
  //   createdByIds?: string[]
  //   contractorIds?: string[]
  // }) {
  //   return await API<IOrdinanceFinePerMonthResponse>({
  //     url: "/v1/diagrams/countOrdinanceFinesPerMonth",
  //     method: "post",
  //     data: payload
  //   }).then((response) => response.data)
  // },

  async getCountOrdinances(payload: {
    projectIds: string[]
    createdByIds?: string[]
    contractorIds?: string[]
  }) {
    return await API<IOrdinanceCount>({
      url: '/v1/diagrams/CountOrdinances',
      method: 'post',
      data: payload
    }).then(res => res.data)
  },

  async getCountCheckLists(payload: {
    projectIds: string[]
    createdByIds?: string[]
    contractorIds?: string[]
  }) {
    return await API<ICheckListCount>({
      url: '/v1/diagrams/CountCheckLists',
      method: 'post',
      data: payload
    }).then(res => res.data)
  },

  async getIssueSubTypes(payload: {
    projectIds: string[]
    createdByIds?: string[]
    contractorIds?: string[]
  }) {
    return await API<IIssueSubTypes[]>({
      url: '/v1/diagrams/IssueSubTypes',
      method: 'post',
      data: payload
    }).then(res => res.data)
  },
  async getCountOrdinanceFines(payload: {
    projectIds: string[]
    createdByIds?: string[]
    contractorIds?: string[]
  }) {
      return await API<IOrdinanceFineCount>({
        url: "/v1/diagrams/countOrdinanceFines",
        method: "post",
        data: payload
      }).then((response) => response.data)
    }
}