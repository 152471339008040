import { useQuery } from '@tanstack/react-query'
import { WbsStatusApi } from './wbs-status-api'
import { WbsStatusState } from './wbsStatusState'

export const useWbsStatuses = (isLocal?: boolean, isAdjustment?: boolean) => {
	const key = isLocal ? 'estimateStatusGuide' : 'wbsStatusGuide'
	const adjustmentKey = isLocal ? 'adjustmentEstimateStatusGuide' : 'adjustmentWbsStatusGuide'
	return useQuery({
		queryKey: [isAdjustment ? adjustmentKey : key],
		queryFn: async () => {
			return await WbsStatusApi.getRegistry(isLocal, isAdjustment).then(
				res =>
					res.data
						?.filter(x => !x.isDeleted)
						.map(d => ({ ...d, children: d.children.filter(x => !x.isDeleted) }))
						?.sort((a, b) => a.order - b.order) ?? []
			)
		},
		refetchOnWindowFocus: false
	})
}

export const useWbsVisas = () => {
	const { addVisaContainer } = WbsStatusState()
	return useQuery({
		queryKey: ['wbsVisas', addVisaContainer?.id],
		queryFn: async () => {
			return await WbsStatusApi.getVisaRegistry(addVisaContainer?.id).then(res =>
				res.data.filter(d => !d.isDeleted)
			)
		},
		refetchOnWindowFocus: false
	})
}

export const useWbsRoles = () => {
	return useQuery({
		queryKey: ['wbsRoles'],
		queryFn: async () => {
			return await WbsStatusApi.getRoles()
		},
		refetchOnWindowFocus: false
	})
}
