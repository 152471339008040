import { EditOutlined, SaveOutlined, StopOutlined } from '@ant-design/icons'
import { Button, Checkbox, Flex, Tag, Tooltip } from 'antd'
import { ColumnsType } from 'antd/es/table'
import { FormInstance } from 'antd/lib'
import { useAppSelector } from 'hooks/appReduxHook'
import { useMemo } from 'react'
import { useContractsGuideUpdate } from '../api/contracts-guide-mutate'
import { TContractGuide, TContractsGuideForm } from '../model/contracts-guide-schema'
import { useContractsGuideState } from '../model/contracts-guide-state'

export const useContractsGuideColumns = (form: FormInstance<TContractsGuideForm>) => {
	const user = useAppSelector(state => state.environment.user)
	const { editedRowId, setEditedRowId, unsetEditedRowId } = useContractsGuideState()
	const { mutate } = useContractsGuideUpdate()
	const editHandler = (record: Partial<TContractGuide>) => {
		form.setFieldsValue({
			ordinanceFineFirstSum: record.ordinanceFineFirstSum,
			ordinanceFineRepeatSum: record.ordinanceFineRepeatSum,
			isNotOrdinanceFine: record.isNotOrdinanceFine
		})
		setEditedRowId(record.id!)
	}

	const onSaveHandler = async (id: string) => {
		await form
			.validateFields()
			.then(() => {
				mutate({ id, dto: form.getFieldsValue() }, { onSuccess: unsetEditedRowId })
			})
			.catch(error => console.log('error', error))
	}

	return useMemo<ColumnsType<TContractGuide>>(
		() => [
			{
				title: 'Наименование',
				fixed: 'left',
				dataIndex: 'name',
				width: 200
			},
			{
				title: 'Организация',
				dataIndex: ['org', 'name'],
				width: 100
			},
			{
				title: 'Подрядчик',
				dataIndex: ['contractor', 'name'],
				width: 100
			},
			{
				title: 'Субподрядчик',
				dataIndex: ['subContractor', 'name'],
				width: 100
			},
			{
				title: 'Проект',
				render: (_, record) => record.project?.map(p => <div>{p.name}</div>),
				width: 100
			},
			{
				title: 'Проект 1С',
				dataIndex: ['project1C', 'name'],
				width: 100
			},
			{
				title: 'Объекты строительства',
				width: 200,
				render: (_, record) =>
					record.constructionObjects.map(item => (
						<Tooltip title={item.name}>
							<Tag
								style={{
									fontSize: 14,
									padding: 4,
									margin: 4,
									maxWidth: '100%',
									overflow: 'hidden',
									textOverflow: 'ellipsis'
								}}
							>
								{item.name}
							</Tag>
						</Tooltip>
					))
			},
			{
				title: 'Сумма первого штрафа',
				dataIndex: 'ordinanceFineFirstSum',
				width: 100,
				onCell: record => ({
					record,
					dataIndex: 'ordinanceFineFirstSum',
					title: 'Сумму первого штрафа',
					editing: editedRowId === record.id,
					contractsForm: form
				})
			},
			{
				title: 'Сумма повторного штрафа',
				dataIndex: 'ordinanceFineRepeatSum',
				width: 120,
				onCell: record => ({
					record,
					dataIndex: 'ordinanceFineRepeatSum',
					title: 'Сумму повторного штрафа',
					editing: editedRowId === record.id,
					contractsForm: form
				})
			},
			{
				title: 'Не выставлять штраф',
				dataIndex: 'isNotOrdinanceFine',
				width: 120,
				onCell: record => ({
					record,
					dataIndex: 'isNotOrdinanceFine',
					title: 'Не выставлять штраф',
					editing: editedRowId === record.id,
					contractsForm: form
				}),
				render: (_, record) => <Checkbox checked={record.isNotOrdinanceFine} />
			},
			{
				width: 60,
				align: 'center',
				fixed: 'right',
				render: (_, record) => {
					return (
						<Flex justify="center" gap={2} wrap={false}>
							{user?.isAdmin && editedRowId === record.id ? (
								<>
									<Tooltip title="Сохранить">
										<Button
											icon={<SaveOutlined />}
											type="primary"
											onClick={() => onSaveHandler(record.id)}
											style={{ marginRight: 8 }}
										/>
									</Tooltip>
									<Tooltip title="Отмена">
										<Button
											icon={<StopOutlined />}
											onClick={unsetEditedRowId}
											style={{ marginRight: 8 }}
										/>
									</Tooltip>
								</>
							) : (
								!('mainContract' in record) && (
									<Tooltip title="Редактировать">
										<Button
											icon={<EditOutlined />}
											disabled={!!editedRowId}
											onClick={() => editHandler(record)}
										/>
									</Tooltip>
								)
							)}
						</Flex>
					)
				}
			}
		],
		[editedRowId]
	)
}
