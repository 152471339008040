import { Input } from 'antd'
import { memo, useCallback, useMemo } from 'react'
import { IAdjustmentPosition, IAdjustmentPositionMaterial } from '../model/adjustment-schema'
import { useAdjustmentState } from '../model/adjustment-state'

interface IProps {
	row: IAdjustmentPosition | IAdjustmentPositionMaterial
	type: 'commentSdu' | 'justification' | 'noteSdu' | 'noteDz' | 'noteDp'
	disabled?: boolean
}
export const AdjustmentNoteCell = memo(function (params: IProps) {
	const { type, row } = params
	const { setNote } = useAdjustmentState()
	const isArea = useMemo(() => type in row && row[type] && (row[type]! as string).length > 0, [row])

	const attrQa = useMemo(
		() => `${row.id}_input${type}${'workName' in row ? 'Work' : 'Material'}`,
		[row]
	)

	const openAreaHandler = useCallback(
		(
			row: IAdjustmentPosition | IAdjustmentPositionMaterial,
			type: 'commentSdu' | 'justification' | 'noteSdu' | 'noteDz' | 'noteDp',
			disabled?: boolean
		) => {
			console.log('row', row)
			setNote(row, type, disabled)
		},
		[row]
	)

	return isArea ? (
		<Input.TextArea
			onFocus={e => e.currentTarget.blur()}
			data-attr={attrQa}
			autoSize={false}
			style={{
				borderRadius: 0,
				maxHeight: 32,
				height: '100%',
				background: 'transparent',
				color: '#444',
				cursor: 'pointer',
				resize: 'none'
			}}
			value={row[type] ?? ''}
			onClick={() => openAreaHandler(row, type, params.disabled)}
		/>
	) : (
		<p
			data-attr={attrQa}
			style={{
				textOverflow: 'ellipsis',
				overflow: 'hidden',
				margin: 0,
				cursor: 'pointer'
			}}
			onClick={() => openAreaHandler(row, type, params.disabled)}
		>
			{'-'}
		</p>
	)
})
