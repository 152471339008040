import { Table } from 'antd'
import { ColumnsType } from 'antd/es/table'
import { NavLink } from 'react-router-dom'
import { useReadyEstimatesButNotApprovedMonitoring } from '../api/monitoring-queries'
import { MonitoringReadyEstimatesButNotApproved } from '../api/monitoring-schema'

export const ReadyEstimatesButNotApprovedMonitoring = () => {
	const { data, isFetching } = useReadyEstimatesButNotApprovedMonitoring()
	const columns: ColumnsType<MonitoringReadyEstimatesButNotApproved> = [
		{
			dataIndex: 'containerId',
			title: 'Id контейнера',
			render: (_, record) => (
				<NavLink to={`/local/${record.containerId}`} target="_blank">
					{record.containerId}
				</NavLink>
			)
		},
		{
			dataIndex: 'typeRu',
			title: 'Тип документа'
		},
		{
			dataIndex: 'containerStatusRu',
			title: 'Статус'
		},
		{
			dataIndex: '',
			title: 'Признак ready'
		}
	]

	return (
		<div className="dashboard__block" style={{ borderRadius: '0 0 .8rem .8rem' }}>
			<Table
				loading={isFetching}
				className="app-table-types"
				rowKey={record => record.containerId}
				size="small"
				pagination={false}
				bordered
				dataSource={data}
				columns={columns as any}
			/>
		</div>
	)
}
