import { Col, Empty, Row } from 'antd'
import {
	BarElement,
	CategoryScale,
	Chart as ChartJS,
	Legend,
	LinearScale,
	Title,
	Tooltip
} from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { FC } from 'react'
import { Bar } from 'react-chartjs-2'
import { useCountChecklistsDashboard } from '../model/diagrams-query'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ChartDataLabels)
interface IProps {}

export const ChecklistsDashboard: FC<IProps> = props => {
	const { data: checklistData } = useCountChecklistsDashboard()

	const newStatus = [
		checklistData!?.countCheckListsUksNdcNew,
		checklistData!?.countCheckListsUkKcNdcNew
	]
	const inProgressStatus = [
		checklistData!?.countCheckListsUksNdcInProgress,
		checklistData!?.countCheckListsUkKcInProgress
	]
	const warrantyStatus = [
		checklistData!?.countCheckListsUksNdcWarranty,
		checklistData!?.countCheckListsUkKcWarranty
	]
	const completedStatus = [
		checklistData!?.countCheckListsUksNdcCompleted,
		checklistData!?.countCheckListsUkKcCompleted
	]

	const uksNdc =
		checklistData!?.countCheckListsUksNdcNew +
		checklistData!?.countCheckListsUksNdcInProgress +
		checklistData!?.countCheckListsUksNdcWarranty +
		checklistData!?.countCheckListsUksNdcCompleted
	const ukKc =
		checklistData!?.countCheckListsUkKcNdcNew +
		checklistData!?.countCheckListsUkKcInProgress +
		checklistData!?.countCheckListsUkKcWarranty +
		checklistData!?.countCheckListsUkKcCompleted

	const labels = ['Приемка НДК', 'Приемка УК']
	const datasets = [
		{
			label: 'Новый   ',
			data: newStatus,
			backgroundColor: '#FFAD14',
			borderWidth: 0,
			hoverBackgroundColor: '#FFAD14',
			borderColor: '#FFAD14'
		},
		{
			label: 'В работе   ',
			data: inProgressStatus,
			backgroundColor: '#8099A8',
			borderWidth: 0,
			hoverBackgroundColor: '#8099A8',
			borderColor: '#8099A8'
		},
		{
			label: 'Принято под гарантийные обязательства   ',
			data: warrantyStatus,
			backgroundColor: '#7A77D9',
			borderWidth: 0,
			hoverBackgroundColor: '#7A77D9',
			borderColor: '#7A77D9'
		},
		{
			label: 'Завершен   ',
			data: completedStatus,
			backgroundColor: '#1677FF',
			borderWidth: 0,
			hoverBackgroundColor: '#1677FF',
			borderColor: '#1677FF'
		}
	]

	const datasetsNew: any[] = datasets.map(dataPoint => ({
		...dataPoint
	}))

	datasets[0].data?.forEach((_: any, datasetIndex: any) => {
		const all_i = datasets
			.map((d, dataIndex) => [d.data[datasetIndex], dataIndex])
			.sort(([data1], [data2]) => data1 - data2)
		all_i.forEach(([data, datasetIdx]) => {
			datasetsNew[datasetIdx].data[datasetIndex] = [0, data]
		})
	})

	const data = {
		labels: labels,
		datasets: datasets.map((d, i) => ({
			...d,
			borderSkipped: false,
			datalabels: {
				anchor: 'end' as const,
				align: 'top' as const,
				offset: 3,
				formatter: (value: any[]) => (value?.[1]! > 0 ? value![1] : ''),
				color: '#000000',
				font: {
					size: 14,
					weight: 400,
					family: 'Segoe UI'
				}
			}
		}))
	}

	const config = {
		type: 'bar',
		data,
		width: '500px',
		height: '250px',
		options: {
			layout: {
				padding: {
					top: 20
				}
			},
			responsive: true,
			plugins: {
				legend: {
					labels: {
						boxWidth: 12,
						boxHeight: 12,
						padding: 8,
						fontFamily: 'Segoe UI',
						fontWeight: 'normal',
						fontSize: '14px',
						color: '#2C303D',
						borderWidth: 3
					},
					position: 'bottom' as const,
					display: true,
					onClick: (e: any) => null,
					title: {
						display: true,
						color: 'black'
					}
				},
				tooltip: {
					callbacks: {
						labelColor: function (context: any) {
							return {
								borderWidth: 3,
								borderColor: context.dataset.backgroundColor,
								backgroundColor: context.dataset.backgroundColor
							}
						},
						label: function (context: any) {
							let label = context.dataset.label.trim() || ''

							if (label) {
								label += ': '
							}
							if (context.parsed.y !== null) {
								label += context.parsed.y
							}
							return ' ' + label
						}
					}
				}
			},
			scales: {
				y: {
					// suggestedMax: Object.values(checklistData!).sort((a, b) => b - a)[1] + 22,
					grid: {
						drawBorder: true,
						drawTicks: false
					}
				},
				x: {
					grid: {
						drawBorder: true,
						drawTicks: false
					},
					ticks: {
						// callback: (value: any) => {
						// 	console.log('value :>> ', value)
						// 	const words = labels[value].split(/ |-/gm)
						// 	console.log('words :>> ', words)
						// 	let aux: string[] = []
						// 	let concat: string[] = []
						// 	for (let i = 0; i < words.length; i++) {
						// 		concat.push(words[i])
						// 		let join = concat.join(' ')
						// 		if (join.length > 5) {
						// 			aux.push(join)
						// 			concat = []
						// 		}
						// 	}
						// 	if (concat.length) {
						// 		aux.push(concat.join(' ').trim())
						// 	}
						// 	return labels.length < 11 ? aux : aux[0].slice(0, 1) + '...'
						// }
						drawTicks: false,
						padding: 20,
						callback: function (value: any, index: any, values: any) {
							return (
								labels[value] + ': ' + `${(labels[value] === 'Приемка УК' ? ukKc : uksNdc) ?? 0}`
							)
							//  +
							// ` ${(
							// 	<span
							// 		style={{
							// 			fontFamily: 'Segoe UI',
							// 			fontWeight: 600,
							// 			fontSize: '14px',
							// 			marginLeft: '5px',
							// 			backgroundColor: '#EEEEEE',
							// 			borderRadius: '5px',
							// 			padding: '2px',
							// 			color: '#2C303'
							// 		}}
							// 	>
							// 		{(labels[value] === 'Приемка УК' ? ukKc : uksNdc) ?? 0}
							// 	</span>
							// )}`
						}
					}
				}
			}
		}
	}

	return (
		<Row
			wrap={false}
			// gutter={12}
			style={{
				border: '1px solid #ddd',
				borderRadius: 8,
				padding: 8,
				background: 'white',
				height: '350px'
			}}
		>
			<Col
				span={24}
				style={{
					padding: '1rem',
					background: !(checklistData?.totalCheckLists! > 0) ? '#ffffff' : '#fefefe'
				}}
			>
				<Row>
					<Col>
						<span
							style={{
								fontFamily: 'Segoe UI',
								fontWeight: 'normal',
								fontSize: '22px',
								color: '#000000',
								marginRight: '8px'
							}}
						>
							Чек-листы
						</span>
					</Col>
					<Col style={{ alignContent: 'center', marginTop: 5 }}>
						<span
							style={{
								fontFamily: 'Segoe UI',
								fontWeight: 400,
								fontSize: '14px',
								marginLeft: '5px',
								backgroundColor: '#EEEEEE',
								borderRadius: '21px',
								padding: '4px',
								color: '#2C303',
								width: '43px',
								height: '20px'
							}}
						>
							{checklistData?.totalCheckLists ?? 0}
						</span>
					</Col>
				</Row>
				<div style={{ height: 250, marginTop: 20 }}>
					{checklistData?.totalCheckLists! > 0 ? (
						<Bar {...config} />
					) : (
						<span
							style={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								height: '100%'
							}}
						>
							<Empty />
						</span>
					)}
				</div>
			</Col>
		</Row>
	)
}
