import { useMutation, useQueryClient } from '@tanstack/react-query'
import { MonitoringApi } from './monitoring-api'
import { notification } from 'antd'
import { AxiosError } from 'axios'
import { IErrorDetail } from 'interfaces/IBase'

export const useForwardingFile = () => {
	const qc = useQueryClient()
	return useMutation({
		mutationKey: ['filesStatuses', 'forwarding'],
		mutationFn: async ({
			ordinanceId,
			attachmentId
		}: {
			ordinanceId: string
			attachmentId: string
		}) => await MonitoringApi.forwardingOrdinanceFile(ordinanceId, attachmentId),
		onSuccess: () => {
			notification.success({
				message: 'Файл успешно отправлен',
				placement: 'topLeft'
			})
			qc.invalidateQueries({ queryKey: ['filesStatuses'] })
		},
		onError: (error: AxiosError<IErrorDetail>) => {
			notification.error({
				message: `Во время отправки произошла ошибка. Код ошибки: ${error.response?.status}`,
				description: error.response?.data?.detail,
				placement: 'topLeft'
			})
		}
	})
}
