import { Button, Col, DatePicker, Drawer, Form, List, Row, Select, Typography } from 'antd'
import locale from 'antd/es/date-picker/locale/ru_RU'
import { useWbsTaskStatuses } from 'pages/guidesPage/ui/wbs-task-status/model/wbs-task-status-queries'
import { FC, useEffect, useState } from 'react'
import {
	useWbsTaskCardContracts,
	useWbsTaskCardPotentialAssignUsers,
	useWbsTaskCardType,
	useWbsTaskWorkPackages
} from 'widgets/wbs-task-card/api/wbs-task-card-queries'
import { IWbsTaskFilters } from '../model/wbs-tasks-interfaces'
import { useWbsTaskState } from '../model/wbs-tasks-state'
import dayjs from 'dayjs'
import { DATE_FORMAT } from 'shared/constants'
import { useUserAllInfo } from 'widgets/wbs/model/wbs-notification-query'
import { useAppSelector } from 'hooks/appReduxHook'

interface IFilterItem {
	title: string
	formItemName: string
	list: any[] | null | undefined
	date: boolean
}

interface IWbsTasksFiltersProps {
	open: boolean
}

const { Option } = Select
const { Text } = Typography

const WbsTasksFilters: FC<IWbsTasksFiltersProps> = ({ open }) => {
	const [filtersForm] = Form.useForm<IWbsTaskFilters>()
	const wbsTaskStatusField = Form.useWatch('wbsTaskStatusId', filtersForm)
	const formValues = Form.useWatch(state => state, filtersForm)
	const { data: wbsStatuses, isFetching: wbsStatusesFetching } = useWbsTaskStatuses()
	const { data: contracts, isFetching: contractsFetching } = useWbsTaskCardContracts()
	const { data: wbsTaskType, isFetching: wbsTaskTypeFetching } = useWbsTaskCardType()
	const { data: workPackages, isFetching: workPackagesFetching } = useWbsTaskWorkPackages(
		formValues?.projectId!
	)
	const { data: potentialAssignUsers, isFetching: potentialAssignUsersFetching } =
		useWbsTaskCardPotentialAssignUsers(wbsTaskStatusField!, formValues?.projectId!)
	const { wbsTaskFilters, setWbsTaskFilters, setShowFilters, onPagination } = useWbsTaskState()
	const [isLoading, setIsLoading] = useState(false)
	const { user } = useAppSelector(state => state.environment)
	// const { data: projects, isSuccess: isSuccessProjects } = useUserAllInfo()

	const filterList: IFilterItem[] = [
		{
			title: 'Проект',
			formItemName: 'projectId',
			list: user?.projects,
			date: false
		},
		{
			title: 'Статус',
			formItemName: 'wbsTaskStatusId',
			list: wbsStatuses,
			date: false
		},
		{
			title: 'Тип заявки',
			formItemName: 'wbsTaskTypeId',
			list: wbsTaskType,
			date: false
		},
		{
			title: 'Пакет работ AbsPlan',
			formItemName: 'workPackageId',
			list: workPackages,
			date: false
		},
		{
			title: 'Ответственный',
			formItemName: 'assignUserId',
			list: potentialAssignUsers,
			date: false
		},
		{
			title: 'Договор',
			formItemName: 'contractId',
			list: contracts,
			date: false
		},
		{
			title: 'Дата создания',
			formItemName: 'createdAt',
			list: null,
			date: true
		}
	]

	const [filterItems, setFilterItems] = useState<IFilterItem[]>(filterList)

	useEffect(() => {
		setFilterItems(filterList)
	}, [
		wbsStatusesFetching,
		contractsFetching,
		wbsTaskTypeFetching,
		workPackagesFetching,
		potentialAssignUsersFetching
		// ,
		// isSuccessProjects
	])

	const onResetFilters = () => {
		filtersForm.resetFields()
		filtersForm.submit()
	}

	const onChangeFilter = (field: string, value: any) => {
		if (field === 'assignUserId' && !!value?.length === false) {
			filtersForm.resetFields(['assignUserId'])
		}
		if (field === 'contractId' && !!value?.length === false) {
			filtersForm.resetFields(['contractId'])
		}
		if (field === 'wbsTaskTypeId' && !!value?.length === false) {
			filtersForm.resetFields(['wbsTaskTypeId'])
		}
		if (field === 'createdAt' && value === null) {
			filtersForm.resetFields(['createdAt'])
		}
		if (field === 'wbsTaskStatusId' && value === null) {
			filtersForm.resetFields(['wbsTaskStatusId'])
		}
		if (field === 'projectId') {
			filtersForm.resetFields(['assignUserId'])
			filtersForm.resetFields(['workPackageId'])
		}
	}

	const onSetFilters = (filters: IWbsTaskFilters, show: boolean) => {
		if (filters) {
			const filtersWithSearch = {
				...wbsTaskFilters,
				contractId: filters.contractId,
				createdAt:
					filters?.createdAt && dayjs(filters?.createdAt).isValid()
						? dayjs(filters?.createdAt).format(DATE_FORMAT.dto)
						: undefined,
				wbsTaskTypeId: filters.wbsTaskTypeId,
				assignUserId: filters.assignUserId,
				wbsTaskStatusId: filters.wbsTaskStatusId,
				workPackageId: filters.workPackageId,
				// projectId: [project.id]
				projectId: filters.projectId
			}
			setWbsTaskFilters(filtersWithSearch)
			if (show) {
				setShowFilters(false)
			}
		}
		onPagination(1)
	}

	const onConfirmFilters = () => {
		onSetFilters(filtersForm.getFieldsValue(), true)
	}

	return (
		<Drawer
			open={open}
			onClose={() => setShowFilters(false)}
			placement="left"
			title="Фильтры"
			className="at-drawer-left"
			footer={
				<Row gutter={8} justify="end">
					<Col>
						<Button ghost danger onClick={onResetFilters}>
							Сбросить
						</Button>
					</Col>
					<Col>
						<Button type="primary" htmlType="submit" onClick={() => filtersForm.submit()}>
							Применить
						</Button>
					</Col>
				</Row>
			}
		>
			<Form name="filtersForm" form={filtersForm} onFinish={onConfirmFilters}>
				<List
					loading={isLoading}
					dataSource={filterItems}
					renderItem={(item, index) => (
						<Row align="middle" gutter={8}>
							<Col style={{ flex: '0 0 100%' }}>
								<Text strong>{item.title}:</Text>
							</Col>
							<Col style={{ flex: '1 0 100%' }}>
								<Form.Item key={index} name={item.formItemName}>
									{!item.date ? (
										<Select
											loading={isLoading}
											showSearch
											allowClear
											optionFilterProp="children"
											mode={item.formItemName === 'projectId' ? 'multiple' : undefined}
											filterSort={(optionA, optionB) =>
												(optionA?.label ?? '')
													.toString()
													.toLowerCase()
													.localeCompare((optionB?.label ?? '').toString().toLowerCase())
											}
											disabled={
												item.formItemName === 'assignUserId' &&
												typeof wbsTaskStatusField === 'undefined'
											}
											style={{ width: '100%' }}
											placeholder="Не выбран"
											onChange={value => onChangeFilter(item.formItemName, value)}
										>
											{item.list! &&
												item.list!?.map((option, index) => (
													<Option
														key={option.id ? option.id : option.authorId ? option.authorId : index}
														value={
															option.id ? option.id : option.authorId ? option.authorId : index
														}
													>
														{item.formItemName === 'wbsTaskStatusId'
															? option.description
															: option.name
															? option.name
															: option}
													</Option>
												))}
										</Select>
									) : (
										<DatePicker
											locale={locale}
											style={{ width: '100%' }}
											format={'DD.MM.YYYY'}
											onChange={date => onChangeFilter(item.formItemName, date!)}
										/>
									)}
								</Form.Item>
							</Col>
						</Row>
					)}
				/>
			</Form>
		</Drawer>
	)
}

export default WbsTasksFilters
