import { useQuery, useQueryClient } from '@tanstack/react-query'
import { useAppSelector } from 'hooks/appReduxHook'
import { TEstimateType } from 'shared/schema'
import { adjustmentApi } from 'widgets/adjustment'
import { useAdjustmentState } from 'widgets/adjustment/model/adjustment-state'
import { useWbsContainerState } from '..'
import { WbsContainerApi } from '../api/wbsContainerApi'

// export const useWbsContainer = (containerId?: string) => {
// 	const queryClient = useQueryClient()
// 	const dispatch = useAppDispatch()
// 	const project = useAppSelector(state => state.environment.project)
// 	const { setContainer, setHasError, currentContainerId } = useWbsContainerState()

// 	const { user } = useAppSelector(state => state.environment)
// 	const navigate = useNavigate()
// 	return useQuery({
// 		queryKey: ['wbsContainer', currentContainerId],
// 		queryFn: async () => {
// 			// setCurrentContainerId(undefined)
// 			if (currentContainerId) {
// 				const container = await WbsContainerApi.getContainer(currentContainerId, 'wbs').then(
// 					data => data
// 				)
// 				useWbsStatusModalState.setState({ container })
// 				const userProject = user?.projects?.find(p => p.id === container.projectId)
// 				if (container.isDeleted) {
// 					setHasError('delete')
// 					navigate('/unmodel')
// 				}
// 				if (project?.id === container.projectId) {
// 					// dispatch(
// 					// 	setPageTitle({
// 					// 		mainTitle: 'Список ВОР',
// 					// 		subTitle: `ВОР: ${container?.name}, Вид раздела: ${container?.typeSections?.name}`
// 					// 	})
// 					// )
// 					setContainer(container)
// 					return container
// 				}
// 				if (project?.id !== container.projectId && userProject && !container.isDeleted) {
// 					const onProjectSelect = async () => {
// 						dispatch(resetIssuesState())
// 						dispatch(resetOrdinancesState())
// 						dispatch(resetCheckListsState())
// 						dispatch(resetTemplatesState())
// 						dispatch(resetAsm())
// 						dispatch(resetTrafficLight())

// 						// dispatch(setFilterOrdinance({ currentPage: 1, filters: {} }))

// 						await getAppUserPermissions(userProject?.id!).then(data => {
// 							dispatch(setEnvironmentPermissions(data))
// 						})
// 						dispatch(
// 							setEnvironmentProject({
// 								option: 'project',
// 								data: userProject!
// 							})
// 						)
// 						await getContractors().then(data => {
// 							dispatch(setEnvironment({ state: 'contractors', data }))
// 						})
// 						await getIssueTypes()
// 							.then(data => {
// 								dispatch(setEnvironment({ state: 'issueTypes', data }))
// 							})
// 							.catch((error: AxiosError) => {
// 								const { detail } = error!?.response!?.data! as IErrorData
// 								const { url } = error!?.config!
// 								message.error(`Ошибка при получении данных ${url}: ${detail}`, 5)
// 								return Promise.reject
// 							})
// 						await getIssueSubTypes()
// 							.then(data => {
// 								dispatch(setEnvironment({ state: 'issueSubTypes', data }))
// 							})
// 							.catch((error: AxiosError) => {
// 								const { detail } = error!?.response!?.data! as IErrorData
// 								const { url } = error!?.config!
// 								message.error(`Ошибка при получении данных ${url}: ${detail}`, 5)
// 								return Promise.reject
// 							})
// 						await getContractsByProject1CId(userProject!?.project1C?.id!).then(data => {
// 							dispatch(setEnvironment({ state: 'contracts', data }))
// 						})
// 						await getBuildingPermitsList(userProject!?.project1C?.id!).then(data => {
// 							dispatch(setEnvironment({ state: 'buildingPermits', data }))
// 						})
// 						queryClient.invalidateQueries({ queryKey: ['issueAnalytics'] })
// 						queryClient.invalidateQueries({ queryKey: ['ordinanceAnalytics'] })
// 						queryClient.invalidateQueries({ queryKey: ['violationTypesAnalytics'] })
// 						queryClient.invalidateQueries({ queryKey: ['useMyFilesAnalytics'] })
// 					}
// 					onProjectSelect()
// 				} else {
// 					setHasError('project')
// 					navigate('/unmodel')
// 				}
// 				dispatch(
// 					setPageTitle({
// 						mainTitle: 'Список ВОР',
// 						subTitle: `ВОР: ${container?.name}, Вид раздела: ${container?.typeSections?.name}`
// 					})
// 				)
// 				setContainer(container)
// 				return container
// 			}
// 			return null
// 		},
// 		refetchOnWindowFocus: false
// 	})
// }

export const useWbsSections = () => {
	const queryClient = useQueryClient()
	const { container, isActual, setSections } = useWbsContainerState()
	return useQuery({
		queryKey: ['wbsSections', container, isActual],
		queryFn: async () => {
			if (container) {
				const data = await WbsContainerApi.getSections(container.id)
				setSections(data)
				if (container.isCopied) queryClient.invalidateQueries(['wbsContainer'])
				return data
			}
			return null
		},
		refetchOnWindowFocus: false
	})
}

export const useWbsSectionItems = () => {
	const { container, sectionBlock, setTechnologies, setSectionItems } = useWbsContainerState()
	return useQuery({
		queryKey: [`wbsSectionItems-${sectionBlock}`, container, sectionBlock],
		queryFn: async () => {
			if (container && sectionBlock) {
				const data = await WbsContainerApi.getSectionItems(sectionBlock, container.id)
				await WbsContainerApi.getSectionTechnologies(sectionBlock).then(data =>
					setTechnologies(sectionBlock, data)
				)
				setSectionItems(sectionBlock, data)
				return null
			}
			return null
		},
		refetchOnWindowFocus: false
	})
}

export const useWbsPotentialRedactors = (projectId?: string, type?: 'wbs') => {
	// const { project } = useAppSelector(state => state.environment)
	return useQuery({
		queryKey: ['wbsPotentialRedactors', projectId],
		queryFn: async () => {
			if (projectId && type === 'wbs') {
				return await WbsContainerApi.getPotentialRedactors(projectId!)
			}
			return null
		},
		refetchOnWindowFocus: false
	})
}

export const useCurrentConstructionObjects = () => {
	// const { project } = useAppSelector(state => state.environment)
	return useQuery({
		queryKey: ['wbsCurrentConstructionObjects'],
		queryFn: async () => {
			// if (project) {
				return await WbsContainerApi.getContainersConstructionObjects()
			// }
			// return null
		},
		refetchOnWindowFocus: false
	})
}

export const useCanVisaApprove = (id?: string, isAdjustment?: boolean, type?: TEstimateType) => {
	const state = isAdjustment ? useAdjustmentState : useWbsContainerState
	const { container } = state()
	const api = isAdjustment ? adjustmentApi : WbsContainerApi
	const containerId = id ? id : container?.id
	return useQuery({
		queryKey: ['canVisaApprove', containerId, id],
		queryFn: async () => {
			if (containerId && type === 'wbs') {
				return await api.getCanVisaApprove(containerId, type)
			}
			return null
		},
		refetchOnWindowFocus: false
	})
}

export const useWbsIsActual = () => {
	const { container } = useWbsContainerState()
	return useQuery({
		queryKey: ['wbsIsActual', container],
		queryFn: async () => {
			if (container) {
				return await WbsContainerApi.checkIsActual(container.id)
			}
			return null
		},
		refetchOnWindowFocus: false
	})
}

export const useWbsNonActualPositions = () => {
	const { container } = useWbsContainerState()
	return useQuery({
		queryKey: ['wbsNonActual', container],
		queryFn: async () => {
			if (container) {
				const data = await WbsContainerApi.wbsNonActualPositions(container.id)
				useWbsContainerState.setState({ nonActualPositions: data })
				return data
			}
			return null
		},
		refetchOnWindowFocus: false
	})
}
