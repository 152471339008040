import { EditOutlined, SaveOutlined, StopOutlined } from '@ant-design/icons'
import { useQueryClient } from '@tanstack/react-query'
import {
	Button,
	Checkbox,
	Col,
	Form,
	FormInstance,
	Input,
	InputNumber,
	Modal,
	Row,
	Select,
	Table,
	Tooltip,
	message
} from 'antd'
import { ColumnsType } from 'antd/es/table'
import { AxiosError } from 'axios'
import { IResponsibleManager } from 'interfaces/IChecklist'
import { IProject } from 'interfaces/IEnvironment'
import { FC, useEffect, useState } from 'react'
import { getResponsibleManagers } from 'services/ChecklistsService'
import { getProjectList, setSectionCount } from 'services/EnvironmentService'

interface IProjectsGuideProps {}

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
	editing: boolean
	dataIndex:
		| 'sectionCount'
		| 'responsibleManager'
		| 'phase'
		| 'building'
		| 'isArchived'
		| 'printFormNameOrg'
	title: any
	record: IProject
	index: number
	children: React.ReactNode
	defaultSortOrder: 'ascend' | 'descend'
	responsibleManagers: IResponsibleManager[]
	sorter: (a: IProject, b: IProject) => void
	projectForm: FormInstance
}

const EditableCell: FC<EditableCellProps> = ({
	editing,
	dataIndex,
	title,
	record,
	index,
	children,
	responsibleManagers,
	projectForm,
	...restProps
}) => {
	const editNode = (
		dataIndex:
			| 'sectionCount'
			| 'responsibleManager'
			| 'phase'
			| 'building'
			| 'isArchived'
			| 'printFormNameOrg'
	) => {
		switch (dataIndex) {
			case 'sectionCount':
				return (
					<InputNumber
						keyboard={false}
						controls={false}
						style={{ width: '100%' }}
						onKeyDown={event => {
							if (
								!/[0-9]|Backspace|Delete|ArrowLeft|ArrowDown|ArrowRight|ArrowUp/.test(event.key)
							) {
								event.preventDefault()
							}
						}}
					/>
				)
			case 'responsibleManager':
				return (
					<Select
						allowClear
						labelRender={props => {
							return projectForm.getFieldValue('responsibleManager') ===
								record.responsibleManager?.id && !record.responsibleManager?.isActive ? (
								<span style={{ color: 'red' }}>Пользователь был удален</span>
							) : (
								props.label
							)
						}}
						showSearch
						placeholder="Не выбран"
						optionFilterProp="children"
						filterOption={(input, option) =>
							(option?.label ?? '').toLowerCase().includes(input.toLowerCase())
						}
						filterSort={(a, b) =>
							(a?.label ?? '').toLowerCase().localeCompare((b?.label ?? '').toLowerCase())
						}
						options={responsibleManagers.map(m => ({
							key: m.id,
							value: m.id,
							label: m.fullName
						}))}
					/>
				)
			case 'phase':
			case 'building':
			case 'printFormNameOrg':
				return <Input />
			case 'isArchived':
				return <Checkbox />
		}
	}

	return (
		<td {...restProps}>
			{editing ? (
				<Form.Item
					name={dataIndex}
					valuePropName={dataIndex === 'isArchived' ? 'checked' : undefined}
					rules={[
						() => ({
							validator(_, value) {
								if (
									dataIndex === 'responsibleManager' &&
									record.responsibleManager?.isActive === false &&
									value === record.responsibleManager?.id
								) {
									return Promise.reject(new Error('Укажите актуального руководителя строительства'))
								}
								return Promise.resolve()
							}
						})
					]}
				>
					{editNode(dataIndex)}
				</Form.Item>
			) : (
				children
			)}
		</td>
	)
}

export const ProjectsGuide: FC<IProjectsGuideProps> = () => {
	const queryClient = useQueryClient()
	const [isLoading, setIsLoading] = useState(true)
	const [projectForm] = Form.useForm()
	const [projects, setProjects] = useState<IProject[]>([])
	const [responsibleManagers, setResponsibleManagers] = useState<IResponsibleManager[]>([])
	const [editingKey, setEditingKey] = useState('')
	const isEditing = (record: IProject) => record.id === editingKey
	const [dto, setDto] = useState<IProject>()

	useEffect(() => {
		getProjects()
	}, [])

	const getProjects = async () => {
		setIsLoading(true)
		await getProjectList(null)
			.then(data => setProjects(data))
			.finally(() => setIsLoading(false))
	}

	const onEdit = (record: Partial<IProject>) => {
		getResponsibleManagers(record.id!).then(data => setResponsibleManagers(data))
		projectForm.setFieldsValue({
			name: record.name,
			project1C: record!?.project1C!?.name!,
			sectionCount: record.sectionCount,
			phase: record.phase,
			building: record.building,
			responsibleManager: record.responsibleManager?.id,
			isArchived: record.isArchived,
			printFormNameOrg: record?.printFormNameOrg
		})
		setEditingKey(record.id!)
	}

	const onSave = async (record: IProject) => {
		const dtoProject = {
			id: record.id,
			name: record.name,
			project1CId: record.project1C?.id,
			sectionCount: projectForm.getFieldValue('sectionCount'),
			phase: projectForm.getFieldValue('phase').toString(),
			building: projectForm.getFieldValue('building').toString(),
			responsibleManager: projectForm.getFieldValue('responsibleManager'),
			isArchived: projectForm.getFieldValue('isArchived'),
			printFormNameOrg: projectForm.getFieldValue('printFormNameOrg')
		}
		setDto(dtoProject!)
	}

	const onConfirmFields = async () => {
		projectForm.validateFields(['responsibleManager'])
		await setSectionCount(dto!)
			.then(async () => {
				await getProjects()
				queryClient.invalidateQueries(['appShared'])
				message.success('Проект успешно обновлён')
				onCancel()
			})
			.catch((err: AxiosError) => {
				Modal.error({
					centered: true,
					title: 'Произошла ошибка',
					content: err.response?.data ? String(err.response.data) : 'Обратитесь к администратору'
				})
			})
	}

	const onCancel = () => {
		setEditingKey('')
		projectForm.resetFields()
	}

	const columns: ColumnsType<IProject> = [
		{
			width: 150,
			title: 'Наименование',
			dataIndex: 'name',
			sorter: (a, b) => a?.name?.localeCompare(b?.name)
		},
		{
			title: 'Проект 1С',
			width: 200,
			dataIndex: 'project1C',
			defaultSortOrder: 'ascend',
			sorter: (a, b) => a!?.project1C!?.name!.localeCompare(b!?.project1C!?.name!),
			render: (_, record) => record!?.project1C!?.name!
		},
		{
			width: 200,
			title: 'Для печатной формы',
			dataIndex: 'printFormNameOrg',
			render: (_, record) => record!?.printFormNameOrg!,
			onCell: record => ({
				record,
				dataIndex: 'printFormNameOrg',
				title: 'Для печатной формы',
				editing: isEditing(record)
			})
		},
		{
			width: 128,
			title: 'Фаза',
			dataIndex: 'phase',
			render: (_, record) => record!?.phase!,
			onCell: record => ({
				record,
				dataIndex: 'phase',
				title: 'Фаза',
				editing: isEditing(record)
			})
		},
		{
			width: 128,
			title: 'Корпус',
			dataIndex: 'building',
			render: (_, record) => record!?.building!,
			onCell: record => ({
				record,
				dataIndex: 'building',
				title: 'Корпус',
				editing: isEditing(record)
			})
		},
		{
			width: 200,
			title: 'Количество секций',
			dataIndex: 'sectionCount',
			render: (_, record) => record!?.sectionCount!,
			onCell: record => ({
				record,
				dataIndex: 'sectionCount',
				title: 'Количество секций',
				editing: isEditing(record)
			})
		},
		{
			width: 256,
			title: 'Руководитель строительства',
			dataIndex: 'responsibleManager',
			render: (_, record) =>
				record.responsibleManager?.isActive ? (
					record.responsibleManager?.name
				) : (
					<span style={{ color: 'red' }}>{record.responsibleManager?.name}</span>
				),
			onCell: record => ({
				record,
				dataIndex: 'responsibleManager',
				title: 'Руководитель строительства',
				editing: isEditing(record),
				responsibleManagers,
				projectForm
			})
		},
		{
			width: 80,
			title: 'Архив',
			dataIndex: 'isArchived',
			align: 'center',
			render: value => <Checkbox checked={value} />,
			sorter: (a, b) => (a === b ? 0 : a ? -1 : 1),
			onCell: (record: IProject) => ({
				record,
				dataIndex: 'isArchived',
				title: 'Архив',
				editing: isEditing(record)
			})
		},
		{
			width: 128,
			fixed: 'right',
			render: (_, record) => {
				const editable = isEditing(record)
				return editable ? (
					<Row gutter={2} wrap={false}>
						<Col>
							<Tooltip title="Сохранить">
								<Button
									icon={<SaveOutlined />}
									type="primary"
									onClick={() => {
										onSave(record)
										projectForm.submit()
									}}
									style={{ marginRight: 8 }}
								/>
							</Tooltip>
						</Col>
						<Col>
							<Tooltip title="Отмена">
								<Button
									icon={<StopOutlined />}
									onClick={() => onCancel()}
									style={{ marginRight: 8 }}
								/>
							</Tooltip>
						</Col>
					</Row>
				) : (
					<Tooltip title="Редактировать">
						<Button icon={<EditOutlined />} onClick={() => onEdit(record)} />
					</Tooltip>
				)
			}
		}
	]

	return (
		<div className="dashboard__block" style={{ borderRadius: '0 0 .8rem .8rem' }}>
			<Form form={projectForm} component={false} onFinish={onConfirmFields}>
				<Table
					loading={isLoading}
					className="app-table-types"
					components={{
						body: {
							cell: EditableCell
						}
					}}
					size="small"
					sticky={{ offsetHeader: -16 }}
					bordered
					dataSource={projects}
					columns={columns}
					pagination={false}
					scroll={{ x: '100%' }}
				/>
			</Form>
		</div>
	)
}
