import { Button, Col, Form, Row, Select, Tag, Tooltip } from 'antd'
import { FC, useEffect, useRef, useState } from 'react'
import { useWatch } from 'antd/es/form/Form'

import {
	useProjectContractors,
	useProjectAuthors,
	useProjectsData
} from '../model/diagrams-project-query'
import { useDiagramsProjectState } from '../model/diagrams-project-state'
import { PERMISSIONS } from 'shared/constants'
import { useCheckPermissions } from 'shared/useCheckPermissions'
import { CloseOutlined, DownOutlined } from '@ant-design/icons'

interface IDiagramProjectFilters {
	authors: string[] | null
	contractorIds: string[] | null
	projectId: string | null
}

export const DiagramsProjectFilters: FC = () => {
	const [filtersProjectForm] = Form.useForm<IDiagramProjectFilters>()
	const checkPermissions = useCheckPermissions()
	const currentProjectId = useWatch('projectId', filtersProjectForm)
	const currentContractors = useWatch('contractorIds', filtersProjectForm)

	const { authors, projectId, contractorIds, setFilters } = useDiagramsProjectState()
	const allCompanies = checkPermissions([PERMISSIONS.AllCompanies], projectId!)

	const { data: projectsData, isLoading: isProjectsLoading } = useProjectsData()

	const { data: contractorsData, isLoading: isContractorsLoading } = useProjectContractors(
		currentProjectId ?? filtersProjectForm.getFieldValue('projectId')
	)

	const { data: authorsData, isLoading: isAuthorsLoading } = useProjectAuthors(
		currentProjectId ?? filtersProjectForm.getFieldValue('projectId'),
		currentContractors ?? filtersProjectForm.getFieldValue('contractorIds') ?? contractorIds
	)
	const formValues = useWatch(values => values, filtersProjectForm)
	const [isFirstEntry, setIsFirstEntry] = useState<boolean>(true)

	const [valueAuthor, setValueAuthor] = useState<string[]>([])
	const [valueContractor, setValueContractor] = useState<string[]>([])
	const [open, setOpen] = useState({ author: false, contractor: false })
	const selectAuthorRef = useRef<HTMLDivElement>(null)
	const selectContractorRef = useRef<HTMLDivElement>(null)
	const projectField = Form.useWatch('projectId', filtersProjectForm)
	const onSelect = (type: 'author' | 'contractor', value: string) => {
		if (type === 'author') {
			setValueAuthor(prev => [...prev, value])
		} else if (type === 'contractor') {
			setValueContractor(prev => [...prev, value])
		}
	}

	const onDeselect = (type: 'author' | 'contractor', value: string) => {
		if (type === 'author') {
			if (valueAuthor?.length > 1) {
				const values = valueAuthor.filter(v => v !== value)
				setValueAuthor(values)
			}
		} else if (type === 'contractor') {
			if (valueContractor?.length > 1) {
				const values = valueContractor.filter(v => v !== value)
				setValueContractor(values)
			}
		}
	}

	const notCanUse =
		!formValues?.projectId?.length! ||
		!formValues?.contractorIds?.length! ||
		!formValues?.authors?.length!

	useEffect(() => {
		if (!isProjectsLoading && !isContractorsLoading && !isAuthorsLoading && !authors?.length) {
			filtersProjectForm.setFieldsValue({
				projectId: projectId ?? projectsData?.length! ? projectsData![0]!?.id : undefined,
				contractorIds: contractorIds ?? contractorsData?.map(c => c.id),
				authors: authors ?? authorsData?.map(a => a.id)
			})
			setValueContractor(contractorIds ?? contractorsData?.map(c => c.id)! ?? [])
			setValueAuthor(authors ?? authorsData?.map(a => a.id)! ?? [])
			// setFilters({
			// 	projectId: filtersProjectForm.getFieldValue('projectId'),
			// 	contractorIds: filtersProjectForm.getFieldValue('contractorIds'),
			// 	authors: filtersProjectForm.getFieldValue('authors')
			// })
		}
	}, [isProjectsLoading, isContractorsLoading, isAuthorsLoading])

	useEffect(() => {
		if (formValues?.authors && formValues?.contractorIds && formValues?.projectId && isFirstEntry) {
			setFilters({
				projectId: filtersProjectForm.getFieldValue('projectId'),
				contractorIds: filtersProjectForm.getFieldValue('contractorIds'),
				authors: filtersProjectForm.getFieldValue('authors')
			})
		}
	}, [formValues])

	const onApplyFilters = () => {
		const { projectId, contractorIds, authors } = filtersProjectForm.getFieldsValue()
		setFilters({
			projectId,
			contractorIds,
			authors
		})
	}

	const onResetFilters = () => {
		filtersProjectForm.resetFields()
		setFilters({
			projectId: null,
			contractorIds: null,
			authors: null
		})
		setValueContractor([])
		setValueAuthor([])
	}

	useEffect(() => {
		filtersProjectForm.setFieldsValue({
			projectId: formValues?.projectId ?? projectId,
			contractorIds: contractorIds!,
			authors: authors!
		})
	}, [])

	useEffect(() => {
		if (
			!contractorIds ||
			contractorIds?.length! === 0 ||
			!contractorIds?.every(c => contractorsData?.map(c => c.id)!?.includes(c)) ||
			projectId !== currentProjectId
		) {
			filtersProjectForm.setFieldValue(
				'contractorIds',
				contractorsData?.map(c => c.id)
			)
			setValueContractor(contractorsData?.map(c => c.id)! ?? [])
		} else {
			filtersProjectForm.setFieldValue('contractorIds', contractorIds)
			setValueContractor(contractorIds ?? [])
		}
	}, [contractorsData])

	useEffect(() => {
		if (
			!authors ||
			authors?.length! === 0 ||
			!authors?.every(a => authorsData?.map(a => a.id)!?.includes(a)) ||
			projectId !== currentProjectId
		) {
			filtersProjectForm.setFieldValue(
				'authors',
				authorsData?.map(a => a.id)
			)
			setValueAuthor(authorsData?.map(a => a.id)! ?? [])
		} else {
			filtersProjectForm.setFieldValue('authors', authors)
			setValueAuthor(authors ?? [])
		}
	}, [authorsData])

	return (
		<Form form={filtersProjectForm} name="filtersProjectForm" onFinish={onApplyFilters}>
			<Row justify="start" gutter={8} align="bottom">
				<Col flex={1}>
					<Col>
						<Row style={{ marginLeft: '5px', marginBottom: '4px' }}>Проект</Row>
					</Col>
					<Col>
						<Form.Item name={'projectId'}>
							<Select
								loading={isProjectsLoading}
								placeholder="Проект"
								showSearch={true}
								allowClear
								onChange={value => {
									if (!value) onResetFilters()
									else {
										filtersProjectForm.setFieldValue('projectId', value)
										setIsFirstEntry(false)
									}
								}}
								filterOption={(input, option) =>
									(option?.label ?? '').toLowerCase().includes(input.toLowerCase())
								}
								filterSort={(optionA, optionB) =>
									(optionA?.label ?? '')
										.toLowerCase()
										.localeCompare((optionB?.label ?? '').toLowerCase())
								}
								className="filter-main"
								tagRender={props => (
									<Tag
										style={{
											width: '100%',
											maxWidth: '140px',
											fontSize: 14,
											marginTop: '2px',
											marginRight: '4px',
											padding: '4px 8px',
											overflow: 'hidden',
											whiteSpace: 'nowrap',
											textOverflow: 'ellipsis'
										}}
									>
										{props.label}
									</Tag>
								)}
								style={{
									width: '100%',
									borderRadius: '8px',
									boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
									height: '37px'
								}}
								maxTagCount={1}
								options={projectsData?.map(p => ({
									label: p.name,
									value: p.id
								}))}
							/>
						</Form.Item>
					</Col>
				</Col>
				<Col flex={1}>
					<Col>
						<Row style={{ marginLeft: '5px', marginBottom: '4px' }}>Контрагенты</Row>
					</Col>
					<Col>
						<Form.Item name={'contractorIds'}>
							<Select
								loading={isContractorsLoading}
								placeholder="Контрагенты"
								showSearch={true}
								filterOption={(input, option) =>
									(option?.label ?? '').toLowerCase().includes(input.toLowerCase())
								}
								filterSort={(optionA, optionB) =>
									(optionA?.label ?? '')
										.toLowerCase()
										.localeCompare((optionB?.label ?? '').toLowerCase())
								}
								onChange={value => {
									filtersProjectForm.setFieldValue('contractorIds', value)
									setIsFirstEntry(false)
									setOpen(prev => ({ ...prev, contractor: true }))
								}}
								// allowClear
								mode="multiple"
								className="filter-main"
								onBlur={() => {
									setOpen(prev => ({ ...prev, contractor: false }))
								}}
								tagRender={props => (
									<div style={{ display: 'flex', justifyContent: 'space-between' }}>
										{props.isMaxTag === false && (
											<Tag
												bordered={false}
												style={{
													width: '100%',
													maxWidth: '140px',
													fontSize: 14,
													padding: '4px 8px',
													marginTop: '0px',
													// marginRight: '4px',
													overflow: 'hidden',
													whiteSpace: 'nowrap',
													textOverflow: 'ellipsis',
													fontWeight: 400,
													fontFamily: 'Segoe UI',
													backgroundColor: 'transparent'
												}}
											>
												{props.label}
											</Tag>
										)}
									</div>
								)}
								style={{
									width: '100%',
									borderRadius: '8px',
									boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
									height: '37px'
								}}
								open={open.contractor}
								onSelect={value => onSelect('contractor', value)}
								onDeselect={value => onDeselect('contractor', value)}
								ref={selectContractorRef as any}
								onClick={e => {
									e.stopPropagation()
									setOpen(prev => ({ ...prev, contractor: true }))
								}}
								suffixIcon={
									<>
										<div
											style={{
												alignItems: 'center',
												justifyContent: 'center',
												display: 'flex',
												fontFamily: 'Segoe UI',
												fontWeight: 400,
												fontSize: '12px',
												// marginLeft: 'auto',
												backgroundColor: '#02152b',
												borderRadius: '50%',
												padding: '2px',
												color: '#FFFFFF',
												height: '20px',
												width: '20px'
											}}
										>
											{valueContractor?.length}
										</div>
										<DownOutlined
											onClick={(e: any) => {
												e.stopPropagation()
												setOpen(prev => ({
													author: false,
													contractor: !prev.contractor
												}))
												open.contractor === false && selectContractorRef.current?.focus()
											}}
										/>
										{valueContractor?.length > 0 && (
											<CloseOutlined
												onClick={e => {
													e.stopPropagation()
													setValueContractor([])
													filtersProjectForm.setFieldValue('contractorIds', [])
												}}
											/>
										)}
									</>
								}
								dropdownRender={menu => (
									<>
										{menu}
										<Button
											type="link"
											onMouseDown={e => {
												e.preventDefault()
												setOpen(prev => ({ ...prev, contractor: true }))
											}}
											onClick={() => {
												const selected = filtersProjectForm.getFieldValue('contractorIds')
												if (selected?.length === contractorsData?.length) {
													filtersProjectForm.setFieldValue('contractorIds', [])
													setValueContractor([])
												} else {
													filtersProjectForm.setFieldValue(
														'contractorIds',
														contractorsData?.map(c => c.id)
													)
													setValueContractor(contractorsData?.map(p => p.id)!)
												}
												setIsFirstEntry(false)
											}}
										>
											{filtersProjectForm.getFieldValue('contractorIds')?.length ===
											contractorsData?.length
												? 'Снять всё'
												: 'Выбрать всё'}
										</Button>
									</>
								)}
								options={contractorsData?.map(c => ({
									label: c.name,
									value: c.id
								}))}
								maxTagCount={1}
							/>
						</Form.Item>
					</Col>
				</Col>
				<Col flex={1}>
					<Col>
						<Row style={{ marginLeft: '5px', marginBottom: '4px' }}>Автор</Row>
					</Col>
					<Col>
						<Form.Item name="authors">
							<Select
								loading={isAuthorsLoading}
								placeholder="Автор"
								showSearch
								// allowClear
								mode="multiple"
								onChange={() => {
									setIsFirstEntry(false)
									setOpen(prev => ({ ...prev, author: true }))
								}}
								className="filter-main"
								onBlur={() => {
									setOpen(prev => ({ ...prev, author: false }))
								}}
								tagRender={props => (
									<div style={{ display: 'flex', justifyContent: 'space-between' }}>
										{props.isMaxTag === false && (
											<Tag
												bordered={false}
												style={{
													width: '100%',
													maxWidth: '140px',
													fontSize: 14,
													padding: '4px 8px',
													marginTop: '0px',
													// marginRight: '4px',
													overflow: 'hidden',
													whiteSpace: 'nowrap',
													textOverflow: 'ellipsis',
													fontWeight: 400,
													fontFamily: 'Segoe UI',
													backgroundColor: 'transparent'
												}}
											>
												{props.label}
											</Tag>
										)}
									</div>
								)}
								open={open.author}
								onSelect={value => onSelect('author', value)}
								onDeselect={value => onDeselect('author', value)}
								ref={selectAuthorRef as any}
								onClick={e => {
									e.stopPropagation()
									setOpen(prev => ({ ...prev, author: true }))
								}}
								suffixIcon={
									<>
										<div
											style={{
												alignItems: 'center',
												justifyContent: 'center',
												display: 'flex',
												fontFamily: 'Segoe UI',
												fontWeight: 400,
												fontSize: '12px',
												// marginLeft: 'auto',
												backgroundColor: '#02152b',
												borderRadius: '50%',
												padding: '2px',
												color: '#FFFFFF',
												height: '20px',
												width: '20px'
											}}
										>
											{valueAuthor?.length}
										</div>
										<DownOutlined
											onClick={(e: any) => {
												e.stopPropagation()
												setOpen(prev => ({
													contractor: false,
													author: !prev.author
												}))
												open.author === false && selectAuthorRef.current?.focus()
											}}
										/>
										{valueAuthor?.length > 0 && (
											<CloseOutlined
												onClick={e => {
													e.stopPropagation()
													setValueAuthor([])
													filtersProjectForm.setFieldValue('authors', [])
												}}
											/>
										)}
									</>
								}
								filterOption={(input, option) =>
									(option?.label ?? '').toLowerCase().includes(input.toLowerCase())
								}
								filterSort={(optionA, optionB) =>
									(optionA?.label ?? '')
										.toLowerCase()
										.localeCompare((optionB?.label ?? '').toLowerCase())
								}
								style={{
									width: '100%',
									borderRadius: '8px',
									boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
									height: '37px'
								}}
								dropdownRender={menu => (
									<>
										{menu}
										<Button
											type="link"
											onMouseDown={e => {
												e.preventDefault()
												setOpen(prev => ({ ...prev, author: true }))
											}}
											onClick={() => {
												const currentVal = filtersProjectForm.getFieldValue('authors')
												if (currentVal?.length === authorsData?.length) {
													filtersProjectForm.setFieldValue('authors', [])
													setValueAuthor([])
												} else {
													filtersProjectForm.setFieldValue(
														'authors',
														authorsData?.map(a => a.id)
													)
													setValueAuthor(authorsData?.map(c => c.id)!)
												}
												setIsFirstEntry(false)
											}}
										>
											{filtersProjectForm.getFieldValue('authors')?.length === authorsData?.length
												? 'Снять всё'
												: 'Выбрать всё'}
										</Button>
									</>
								)}
								options={authorsData?.map(a => ({
									label: `${a.firstName} ${a.lastName}`.trim(),
									value: a.id
								}))}
								maxTagCount={1}
							/>
						</Form.Item>
					</Col>
				</Col>
				<Col>
					<Tooltip title={notCanUse ? 'Для применения заполните все фильтры' : ''}>
						<Button
							type="primary"
							disabled={notCanUse}
							onClick={() => filtersProjectForm.submit()}
							style={{
								marginBottom: '24px',
								backgroundColor: '#02152b',
								borderColor: '#2C303D',
								color: '#FFFFFF',
								fontSize: 14,
								fontFamily: 'Segoe UI',
								fontWeight: 'normal',
								width: '109px',
								height: '36px'
							}}
						>
							Применить
						</Button>
					</Tooltip>
				</Col>
				<Col>
					<Button
						type="primary"
						onClick={onResetFilters}
						style={{
							marginBottom: '24px',
							backgroundColor: 'transparent',
							borderColor: '#2C303D',
							color: '#02152b',
							fontSize: 14,
							fontFamily: 'Segoe UI',
							fontWeight: 'normal',
							width: '97px',
							height: '36px'
						}}
					>
						Сбросить
					</Button>
				</Col>
			</Row>
		</Form>
	)
}
