import { FC, useState, useEffect } from 'react'
import {
	Button,
	Space,
	Table,
	Modal,
	Form,
	Select,
	Input,
	message,
	Col,
	Row,
	Popconfirm,
	Tooltip
} from 'antd'
import {
	PlusSquareOutlined,
	DeleteOutlined,
	EditOutlined,
	SaveOutlined,
	StopOutlined
} from '@ant-design/icons'
import { IBuildingPermit, IBuildingPermitDto } from 'interfaces/IIssue'
import { getBuildingPermitsList } from 'services/OrdinanceService'
import { useAppSelector } from 'hooks/appReduxHook'
import { addBuildingPermits, removeBuildingPermit } from 'services/AdminService'
import { useQueryClient } from '@tanstack/react-query'
import { IProject } from 'interfaces/IEnvironment'
import { AxiosError } from 'axios'

interface IBuildingPermitsGuideProps {}

interface IAddPermitModalProps {
	show: boolean
	projectsList: IProject[]
	onClose: (update?: boolean) => void
}
interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
	editing: boolean
	dataIndex: string
	title: any
	inputType: 'select' | 'text'
	options?: IProject[]
	onSectionChange: (id: string) => void
	record: IBuildingPermit
	index: number
	children: React.ReactNode
}

const EditableCell: FC<EditableCellProps> = ({
	editing,
	dataIndex,
	title,
	inputType,
	onSectionChange,
	options,
	record,
	index,
	children,
	...restProps
}) => {
	const inputNode =
		inputType === 'select' ? (
			<Select onChange={value => onSectionChange(value)}>
				{[...options!]
					.filter(p => p.project1C)
					?.sort((a, b) => a.name.localeCompare(b.name))
					.map((project, index) => (
						<Select.Option key={index} value={project?.id}>
							{project.name}
						</Select.Option>
					))}
			</Select>
		) : (
			<Input.TextArea autoSize disabled={dataIndex === 'project1C'} />
		)

	return (
		<td {...restProps}>
			{editing ? (
				<Form.Item
					name={dataIndex}
					style={{ margin: 0 }}
					rules={[
						{
							required: true,
							message: `Укажите ${title.toLowerCase()}`
						}
					]}
				>
					{inputNode}
				</Form.Item>
			) : (
				children
			)}
		</td>
	)
}

export const BuildingPermitsGuide: FC<IBuildingPermitsGuideProps> = () => {
	const queryClient = useQueryClient()
	const projectsList = useAppSelector(state => state.environment.projectsList)
	const [buildingPermits, setBuildingPermits] = useState<IBuildingPermit[]>([])
	const [buildingPermitsForm] = Form.useForm()
	const [editingKey, setEditingKey] = useState('')
	const [showAddModal, setShowAddModal] = useState(false)
	const isEditing = (record: IBuildingPermit) => record.id === editingKey

	const edit = (record: Partial<IBuildingPermit>) => {
		buildingPermitsForm.setFieldsValue({
			name: record.name,
			project: record.project?.id,
			project1C: record.project1C?.name
		})
		setEditingKey(record.id!)
	}

	const cancel = () => {
		setEditingKey('')
	}

	const save = async (data: IBuildingPermit) => {
		const project = buildingPermitsForm.getFieldValue('project')
		const dto: IBuildingPermitDto = {
			id: data.id,
			name: buildingPermitsForm.getFieldValue('name'),
			projectId: project,
			project1CId: projectsList.find(p => p.id === project)?.project1C!?.id!
		}
		await addBuildingPermits(dto).then(async () => {
			await getPermits()
		})

		cancel()
	}

	const onSectionChange = (id: string) => {
		const item = projectsList.find(p => p.id === id)?.project1C
		item! && buildingPermitsForm.setFieldValue('project1C', item?.name!)
	}

	useEffect(() => {
		const init = async () => {
			getPermits()
		}
		init()
	}, [])

	const getPermits = async () => {
		await getBuildingPermitsList(null!).then(data => setBuildingPermits(data))
	}

	const deletePermit = async (id: string) => {
		cancel()
		await removeBuildingPermit(id)
			.then(() => {
				queryClient.invalidateQueries({ queryKey: ['appShared'] })
				getPermits()
			})
			.catch((err: AxiosError) =>
				err.response!?.status! === 409
					? message.error(err.response!?.data! as String)
					: message.error('Произошла непредвиденная ошибка на сервере!')
			)
	}

	const columns = [
		{
			title: 'Наименование',
			dataIndex: 'name',
			editable: true
		},
		{
			title: 'Проект',
			dataIndex: 'project',
			width: 300,
			editable: true,
			render: (_: any, record: IBuildingPermit) => record.project?.name!
		},
		{
			title: 'Проект 1С',
			dataIndex: 'project1C',
			width: 300,
			editable: true,
			render: (_: any, record: IBuildingPermit) => record.project1C?.name!
		},
		{
			width: 100,
			render: (_: any, record: IBuildingPermit) => {
				const editable = isEditing(record)
				return editable ? (
					<Row gutter={2} wrap={false}>
						<Col>
							<Tooltip title="Сохранить">
								<Button
									icon={<SaveOutlined />}
									type="primary"
									onClick={() => save(record)}
									style={{ marginRight: 8 }}
								/>
							</Tooltip>
						</Col>
						<Col>
							<Tooltip title="Отмена">
								<Button
									icon={<StopOutlined />}
									onClick={() => cancel()}
									style={{ marginRight: 8 }}
								/>
							</Tooltip>
						</Col>
						<Col>
							<Popconfirm
								title="Удалить разрешение?"
								placement="topRight"
								onConfirm={() => deletePermit(record.id)}
								onCancel={cancel}
								okButtonProps={{ danger: true }}
								okText="Да"
								cancelText="Отмена"
							>
								<Button icon={<DeleteOutlined />} danger style={{ marginRight: 8 }} />
							</Popconfirm>
						</Col>
					</Row>
				) : (
					<Tooltip title="Редактировать">
						<Button
							icon={<EditOutlined />}
							disabled={editingKey !== ''}
							onClick={() => edit(record)}
						/>
					</Tooltip>
				)
			}
		}
	]

	const mergedColumns = columns.map(col => {
		if (!col.editable) {
			return col
		}
		return {
			...col,
			onCell: (record: IBuildingPermit) => ({
				record,
				inputType: col.dataIndex === 'project' ? 'select' : 'text',
				options: col.dataIndex === 'project' && projectsList,
				onSectionChange,
				dataIndex: col.dataIndex,
				title: col.title,
				editing: isEditing(record)
			})
		}
	})

	return (
		<div className="dashboard__block" style={{ borderRadius: '0 0 .8rem .8rem' }}>
			<AddPermitModal
				projectsList={projectsList!}
				show={showAddModal}
				onClose={update => {
					setShowAddModal(false)
					update && getPermits()
				}}
			/>
			<Space style={{ width: '100%', marginBottom: '1rem' }}>
				<Button icon={<PlusSquareOutlined />} type="primary" onClick={() => setShowAddModal(true)}>
					Добавить
				</Button>
			</Space>
			<Form form={buildingPermitsForm} component={false}>
				<Table
					components={{
						body: {
							cell: EditableCell
						}
					}}
					dataSource={buildingPermits}
					columns={mergedColumns}
					pagination={false}
					size={'small'}
				/>
			</Form>
		</div>
	)
}

const AddPermitModal: FC<IAddPermitModalProps> = ({ show, projectsList, onClose }) => {
	const [permitForm] = Form.useForm<IBuildingPermitDto>()

	const onPermitFormConfirm = async () => {
		const dto = permitForm.getFieldsValue()
		await addBuildingPermits(dto).then(() => {
			permitForm.resetFields()
			onClose(true)
		})
	}

	const onPermitModalClose = () => {
		permitForm.resetFields()
		onClose()
	}

	return (
		<Modal
			okButtonProps={{
				onClick: () => permitForm.submit()
			}}
			title="Добавить"
			destroyOnClose={true}
			open={show}
			onCancel={onPermitModalClose}
			okText="Сохранить"
			cancelText="Отмена"
		>
			<Form
				form={permitForm}
				onFinish={onPermitFormConfirm}
				labelCol={{ span: 6 }}
				labelAlign="left"
			>
				<Form.Item
					name="projectId"
					label="Проект"
					rules={[{ required: true, message: 'Укажите проект' }]}
				>
					<Select
						onChange={value => {
							permitForm.setFieldValue(
								'project1CId',
								projectsList.find(p => p.id === value)?.project1C?.id!
							)
							permitForm.validateFields()
						}}
					>
						{[...projectsList]
							?.sort((a, b) => a.name.localeCompare(b.name))
							.map((project, index) => (
								<Select.Option key={index} value={project?.id}>
									{project.name}
								</Select.Option>
							))}
					</Select>
				</Form.Item>
				<Form.Item
					name="name"
					label="Наименование"
					rules={[
						{ required: true, message: 'Укажите наименование' },
						{ min: 3, message: 'Минимальная длина 3 символа' }
					]}
				>
					<Input />
				</Form.Item>
				<Form.Item
					name="project1CId"
					hidden
					rules={[{ required: true, message: 'У выбранного проекта не указан Проект1С' }]}
				/>
			</Form>
		</Modal>
	)
}
