import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IBase } from 'interfaces/IBase'
import { IContract } from 'interfaces/IContract'
import { IContractor, IProject } from 'interfaces/IEnvironment'
import { IEstimate } from 'interfaces/IEstimate'
import {
	IBuildingPermit,
	IIssueStatus,
	IIssueSubType,
	IIssueType,
	IIssueViolationType
} from 'interfaces/IIssue'
import { IStatuses } from 'interfaces/IOrdinance'
import { IAppUser, IUser, IUserPermission } from 'interfaces/IUser'
import { IViolation } from 'services/violationsService'
import { IWorkPackage } from 'services/workPackageService'

interface IEnvironmentOption<DataType> {
	option: string
	data: DataType
}

interface IEnvironment {
	state: string
	data:
		| null
		| string
		| IProject[]
		| IIssueStatus[]
		| IIssueType[]
		| IIssueSubType[]
		| IIssueViolationType[]
		| IContract[]
		| IContractor[]
		| IStatuses[]
		| IBuildingPermit[]
		| IBase[]
		| IWorkPackage[]
		| IViolation[]
		| []
}

export interface IState {
	user: IAppUser | null
	project: IProject
	contractor: IContractor | null
	estimate: IEstimate | null
	projectsList: IProject[] | []
	allProjects: IProject[] | []
	permissions: IUserPermission[] | [] | null
	issueStatuses: IIssueStatus[] | [] | null
	issueTypes: IIssueType[] | [] | null
	issueSubTypes: IIssueSubType[] | [] | null
	violationTypes: IIssueViolationType[] | [] | null
	contracts: IContract[] | [] | null
	contractors: IContractor[] | [] | null
	ordinanceStatuses: IStatuses[] | [] | null
	buildingPermits: IBuildingPermit[] | [] | null
	contactPersons: IBase[] | [] | null
	users: IUser[] | null
	diagramsContractor: string | undefined
	workPackages: IWorkPackage[] | null
	violations: IViolation[] | null
}

const project = localStorage.getItem('project')
const parsedProject: IProject = project ? JSON.parse(project) : null

const contractor = localStorage.getItem('contractor')
const parsedContractor: IContractor = contractor ? JSON.parse(contractor) : null

const estimate = localStorage.getItem('estimate')
const parsedEstimate: IEstimate = estimate ? JSON.parse(estimate) : null

const user = localStorage.getItem('viewer')
const parsedUser: IAppUser = JSON.parse(user!)?.state?.viewer !== undefined && JSON.parse(user!)?.state?.viewer !== null ? JSON.parse(user!)?.state?.viewer : null

// const parsedPermissions: IUserPermission[] =
// JSON.parse(user!)?.state?.permissions !== undefined && JSON.parse(user!)?.state?.permissions !== null ? JSON.parse(user!)?.state?.permissions : null

const initialState: IState = {
	user: parsedUser,
	project: parsedProject,
	contractor: parsedContractor,
	estimate: parsedEstimate,
	projectsList: parsedUser!?.projects!,
	allProjects: [],
	permissions: null,
	issueStatuses: [],
	issueTypes: [],
	issueSubTypes: [],
	violationTypes: [],
	contracts: [],
	contractors: [],
	ordinanceStatuses: [],
	buildingPermits: [],
	contactPersons: [],
	users: [],
	diagramsContractor: undefined,
	workPackages: [],
	violations: []
}

const environmentSlice = createSlice({
	name: 'root',
	initialState,
	reducers: {
		setEnvironmentProject(state, action: PayloadAction<IEnvironmentOption<IProject>>) {
			state.project = action.payload.data
			localStorage.setItem('project', JSON.stringify(action.payload.data))
			state.contractor = null
			localStorage.removeItem('contractor')
			state.estimate = null
			localStorage.removeItem('estimate')
		},
		setEnvironmentContractor(state, action: PayloadAction<IEnvironmentOption<IContractor>>) {
			state.contractor = action.payload.data
			localStorage.setItem('contractor', JSON.stringify(action.payload.data))
			state.estimate = null
			localStorage.removeItem('estimate')
		},
		setEnvironmentEstimate(state, action: PayloadAction<IEnvironmentOption<IEstimate>>) {
			state.estimate = action.payload.data
			localStorage.setItem('estimate', JSON.stringify(action.payload.data))
		},
		setEnvironmentPermissions(state, action: PayloadAction<IUserPermission[]>) {
			if (typeof action.payload !== 'undefined') {
				state.permissions = action.payload
				localStorage.setItem('permissions', JSON.stringify(action.payload))
			}
		},
		setEnvironment(state, action: PayloadAction<IEnvironment>) {
			if (action.payload.state === 'issueStatuses')
				state.issueStatuses = action.payload.data as IIssueStatus[]
			if (action.payload.state === 'issueTypes')
				state.issueTypes = action.payload.data as IIssueType[]
			if (action.payload.state === 'issueSubTypes')
				state.issueSubTypes = action.payload.data as IIssueSubType[]
			if (action.payload.state === 'violationTypes')
				state.violationTypes = action.payload.data as IIssueViolationType[]
			if (action.payload.state === 'contracts') state.contracts = action.payload.data as IContract[]
			if (action.payload.state === 'contractors')
				state.contractors = action.payload.data as IContractor[]
			if (action.payload.state === 'ordinanceStatuses')
				state.ordinanceStatuses = action.payload.data as IStatuses[]
			if (action.payload.state === 'buildingPermits')
				state.buildingPermits = action.payload.data as IBuildingPermit[]
			if (action.payload.state === 'contactPersons')
				state.contactPersons = action.payload.data as IBase[]
			if (action.payload.state === 'allProjects')
				state.allProjects = action.payload.data as IProject[]
			if (action.payload.state === 'workPackages')
				state.workPackages = action.payload.data as IWorkPackage[]
			if (action.payload.state === 'violations')
				state.violations = action.payload.data as IViolation[]
		},
		setDiagramsContractor(state, action: PayloadAction<string | undefined>) {
			state.diagramsContractor = action.payload
		},
		resetEnvironment(state) {
			state.issueStatuses = []
			state.issueTypes = []
			state.issueSubTypes = []
			state.violationTypes = []
			state.contracts = []
			state.contractors = []
			state.ordinanceStatuses = []
			state.buildingPermits = []
			state.contactPersons = []
			state.allProjects = []
			state.workPackages = []
			state.violations = []
		}
	}
})

export const {
	setEnvironmentProject,
	setEnvironmentContractor,
	setEnvironmentEstimate,
	setEnvironmentPermissions,
	setEnvironment,
	setDiagramsContractor,
	resetEnvironment
} = environmentSlice.actions

export default environmentSlice.reducer
