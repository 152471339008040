import { Col, Empty, Modal, Row } from 'antd'
import {
	BarElement,
	CategoryScale,
	Chart as ChartJS,
	Legend,
	LinearScale,
	Title,
	Tooltip
} from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { FC } from 'react'
import { Doughnut } from 'react-chartjs-2'
import { useDiagramsProjectState } from '../model/diagrams-project-state'
import { useNavigate } from 'react-router-dom'
import { useQueryClient } from '@tanstack/react-query'
import { useAppDispatch, useAppSelector } from 'hooks/appReduxHook'
import { changeCurrentProject } from '../model/diagrams-project-helpers'
import { useCheckPermissions } from 'shared/useCheckPermissions'
import { PERMISSIONS } from 'shared/constants'
import { getRelativePosition } from 'chart.js/helpers'
import { Chart } from 'chart.js/dist'
import { useOrdinanceFineState } from 'widgets/fine/model/fine-state'
import { useCountOrdinanceFinesDashboard } from '../model/diagrams-project-query'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ChartDataLabels)

export interface IDoughnutValues {
	eliminated: number
	fixedOut: number
	notEliminated: number
	total: number
}

export enum FineStatuses {
	new = '10a2dcec-9ad0-4c1b-b38f-293a097b0c63',
	canceled = 'c3d93cdb-103e-4219-9771-af00ce8fdf5b',
	collected = '9dd3fa1f-f182-47be-bd63-3d2e7f5fcd0b',
	exhibited = 'dd2b7a56-4e21-4f4f-a59b-e983efbf61cd'
}

export const DiagramsProjectOrdinanceFines: FC = () => {
	const { data: ordinanceFinesValues } = useCountOrdinanceFinesDashboard()
	const checkPermissions = useCheckPermissions()
	const queryClient = useQueryClient()
	const dispatch = useAppDispatch()
	const { project: currentProject, user } = useAppSelector(state => state.environment)
	const { projectId, contractorIds, authors } = useDiagramsProjectState()
	const navigate = useNavigate()
	const { setOrdinanceFineFilters } = useOrdinanceFineState()

	const values = [
		ordinanceFinesValues?.countOrdinanceFinesNew || null,
		ordinanceFinesValues?.countOrdinanceFinesCanceled || null,
		ordinanceFinesValues?.countOrdinanceFinesExhibited || null,
		ordinanceFinesValues?.countOrdinanceFinesCollected || null
	]
	const labels = [`Новый`, 'Снят', 'Выставлен', 'Удержан']
	const customLabels = labels.map((label, index) => `${label}: ${values[index] ?? 0}`)
	const colors = ['#FFAD14', '#8099A8', '#1677FF', '#7A77D9']
	const data = {
		data: {
			labels: customLabels,
			datasets: [
				{
					data: values,
					backgroundColor: colors,
					hoverBackgroundColor: colors,
					borderColor: ['white'],
					borderWidth: 4,
					datalabels: {
						color: '#FFFFFF',
						font: {
							size: 14,
							weight: 400,
							family: 'Segoe UI'
						},
						formatter: function (value: any) {
							let percentage = (value / values?.reduce((sum, value) => sum! + value!, 0)!) * 100
							if (percentage < 5) {
								return ''
							}
							return value
						}
					}
				}
			]
		},
		options: {
			onHover: (event: any, elements: any) => {
				const canvasPosition: any = getRelativePosition(event, elements)
				const chart: Chart = canvasPosition.chart
				const points = chart.getElementsAtEventForMode(event, 'nearest', { intersect: true }, true)
				event.native.target.style.cursor = points[0] ? 'pointer' : 'default'
			},
			onClick: (event: any, elements: any) => {
				const canvasPosition: any = getRelativePosition(event, elements)
				const chart: Chart = canvasPosition.chart
				const points = chart.getElementsAtEventForMode(event, 'nearest', { intersect: true }, true)
				if (points[0]) {
					const point = points[0]
					if (!checkPermissions([PERMISSIONS.OrdinanceFine], projectId!)) {
						Modal.error({
							title: 'Ошибка доступа',
							content:
								'Внимание! Раздел не доступен, поскольку у Вас не хватает прав. Обратитесь к администратору.'
						})
						return
					}
					if (point.index === 2) {
						changeCurrentProject(projectId!, user, dispatch, queryClient)
						setOrdinanceFineFilters({
							contractorIds: contractorIds!,
							ordinanceFineStatusId: FineStatuses.collected,
							createdByIds: authors!
						})
					} else if (point.index === 3) {
						changeCurrentProject(projectId!, user, dispatch, queryClient)
						setOrdinanceFineFilters({
							contractorIds: contractorIds!,
							ordinanceFineStatusId: FineStatuses.exhibited,
							createdByIds: authors!
						})
					} else if (point.index === 1) {
						changeCurrentProject(projectId!, user, dispatch, queryClient)
						setOrdinanceFineFilters({
							contractorIds: contractorIds!,
							ordinanceFineStatusId: FineStatuses.canceled,
							createdByIds: authors!
						})
					} else if (point.index === 0) {
						changeCurrentProject(projectId!, user, dispatch, queryClient)
						setOrdinanceFineFilters({
							contractorIds: contractorIds!,
							ordinanceFineStatusId: FineStatuses.new,
							createdByIds: authors!
						})
					}
					navigate('/control/fines')
				}
			},
			circumference: 180,
			rotation: -90,
			maintainAspectRatio: false,
			plugins: {
				tooltip: {
					callbacks: {
						labelColor: function (context: any) {
							return {
								borderWidth: 3,
								borderColor: colors[context.dataIndex],
								backgroundColor: colors[context.dataIndex]
							}
						},
						label: function (context: any) {
							let label = context.formattedValue?.trim() || ''
							return ' ' + label
						}
					}
				},
				legend: {
					labels: {
						boxWidth: 15,
						boxHeight: 15,
						fontFamily: 'Segoe UI',
						fontWeight: 'normal',
						fontSize: '14px',
						color: '#2C303D'
					},
					responsive: true,
					position: 'right' as const,
					title: {
						display: true,
						color: 'black'
					}
				}
			}
		}
	}

	return (
		<Row
			// wrap={false}
			// gutter={12}
			style={{
				border: '1px solid #ddd',
				borderRadius: 8,
				padding: 8,
				background: 'white',
				height: '350px'
			}}
		>
			<Col
				span={24}
				style={{
					padding: '1rem',
					background: !(ordinanceFinesValues?.totalOrdinanceFines! > 0) ? '#ffffff' : '#fefefe'
				}}
			>
				<Row>
					<Col>
						<span
							style={{
								fontFamily: 'Segoe UI',
								fontWeight: 'normal',
								fontSize: '22px',
								color: '#000000',
								marginRight: '8px'
							}}
						>
							Штрафы
						</span>
					</Col>
					<Col style={{ alignContent: 'center', marginTop: 5 }}>
						<span
							style={{
								fontFamily: 'Segoe UI',
								fontWeight: 400,
								fontSize: '14px',
								marginLeft: '5px',
								backgroundColor: '#EEEEEE',
								borderRadius: '21px',
								padding: '4px',
								color: '#2C303',
								width: '43px',
								height: '20px'
							}}
						>
							{ordinanceFinesValues?.totalOrdinanceFines ?? 0}
						</span>
					</Col>
				</Row>
				<div style={{ height: 250, marginTop: 20 }}>
					{ordinanceFinesValues?.totalOrdinanceFines! > 0 ? (
						<Doughnut {...data} />
					) : (
						<span
							style={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								height: '100%'
							}}
						>
							<Empty />
						</span>
					)}
				</div>
			</Col>
		</Row>
	)
}
